import $ from 'jquery';

export default class DoughnutChart {
  constructor(element, legendContainer, config) {
    this.data = config.data;
    this.showTotal = config.showTotal;
    this.chart = new Chart($(element), {
      type: 'doughnut',
      data: this.data,
      options: this.getChartOptions(config),
    });
    $(legendContainer).html(this.chart.generateLegend());
  }

  getChartOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      legend: false,
      legendCallback: this.customLegend(),
      tooltips: {
        callbacks: {
          label: tooltipItems => this.data.datasets[0].data[tooltipItems.index], // show quantity when mouse hover items

        },
      },
      cutoutPercentage: 80,
    };
  }

  customLegend() {
    return () => { // create custom legend
      const ul = document.createElement('ul');
      ul.className = 'chart__legend__list';

      for (let i = 0; i < this.data.datasets[0].data.length; i += 1) {
        ul.innerHTML = `
                <li class="chart__legend__item">
                  <span class="chart__legend__tag" style="background-color: ${this.data.datasets[0].backgroundColor[i]}"></span>
                  <span class="chart__legend__label">
                    <b class="chart__legend__value">${this.data.datasets[0].data[i]}</b>${this.data.labels[i]}
                  </span>
                </li>
              ${ul.innerHTML}`;
      }

      if (this.showTotal) {
        const total = this.data.datasets[0].data.reduce((a, b) => a + b, 0);

        ul.innerHTML += '<li><hr class="divider divider--spaced-s divider--s divider--left divider--spaced-bottom"></li>';
        ul.innerHTML += `<li class="chart__legend__item"><b class="chart__legend__value">${total}</b>Total</li>`;
      }
      return ul.outerHTML;
    };
  }
}
