import { InlineOperations } from '@stock/legacy/components/modals/offer-list-actions/inline-steps';
import { findOfferByUuid } from '@stock/services/find-offer-by-uuid';

export const setupOfferInlineActions = ($container) => {
  const validate = () => {
    if ($container.length > 1) throw new Error('element is not unique');
    if ($container.length === 0) throw new Error('element not found');
  };

  const setupMenuItem = ({ selector, method, options }) => {
    $container.find(selector).on('click', async (event) => {
      event.preventDefault();
      event.stopPropagation();
      const uuid = $container.data('offer-uuid');
      const offer = await findOfferByUuid({ uuid });
      method(offer, options);
    });
  };

  const selectedWidget = $('.js-selected-widget').val();
  const defaultOptions = { selectedWidget };

  validate();

  [
    {
      selector: '.js-inline-restock',
      method: InlineOperations.showRestockModal,
      options: defaultOptions,
    },
    {
      selector: '.js-inline-discount',
      method: InlineOperations.showDiscountChangeModal,
      options: defaultOptions,
    },
    {
      selector: '.js-inline-pricing',
      method: InlineOperations.showPricingModal,
      options: defaultOptions,
    },
    {
      selector: '.js-inline-disable',
      method: InlineOperations.showDisablementModal,
      options: {
        ...defaultOptions,
        universityOfferId: $container.find('.js-inline-disable').data('university-offer-id'),
      },
    },
    {
      selector: '.js-recommended-restock',
      method: InlineOperations.showRestockModal,
      options: {
        ...defaultOptions,
        recommendedValue: $container.find('.js-recommended-restock').data('recommended-value'),
      },
    },
    {
      selector: '.js-optimize-offer',
      method: InlineOperations.showOptimizeOfferModal,
      options: {
        ...defaultOptions,
        recommendedPrice: $container.find('.js-optimize-offer').data('recommended-price'),
      },
    },
  ].forEach(menuItem => setupMenuItem(menuItem));
};
