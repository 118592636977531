import ConfirmationModal from '@stock/legacy/components/modals/offer-list-actions/batch/confirmation';
import template from '@stock/legacy/components/modals/offer-list-actions/batch/confirmation-template.html.ejs';
import { TEMPLATE_CONFIRMATION_PROPS }
  from '@stock/legacy/components/modals/offer-list-actions/batch/pricing/template-props';
import NumberFormatter from '@shared/utils/number_format';

const CHANGE_DISCOUNT_URL = '/ui/ofertas/batch/alterar-preco';

export default (modal, alert) => {
  $(modal).on('next', (_evt, params) => {
    const numberFormatter = new NumberFormatter();
    const {
      oldDiscount,
      batchOperationRequest,
      totalOffersCount,
      totalOffersTargetCount,
      newDiscount,
    } = params;
    const confirmationModal = new ConfirmationModal(
      CHANGE_DISCOUNT_URL,
      {
        ...TEMPLATE_CONFIRMATION_PROPS,
        ...params,
        newValue: numberFormatter.toPercentage(oldDiscount, { precision: 2 }),
        previousValue: numberFormatter.toPercentage(oldDiscount, { precision: 2 }),
        filter: batchOperationRequest.filters,
        totalOffers: totalOffersCount,
        totalOffersTarget: totalOffersTargetCount,
        sumOffersTarget: numberFormatter.toPercentage(newDiscount, { precision: 2 }),
      },
      alert,
      template,
    );
    confirmationModal.show();
  });
};
