module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!-- this dumb mapping is neccesary to handle hifen -->\n';
 NO_VALUE = { value: null } ;
__p += '\n\n';
 course = data.course || NO_VALUE ;
__p += '\n';
 campus = data.campus || NO_VALUE ;
__p += '\n';
 kind = data.kind || NO_VALUE ;
__p += '\n';
 level = data.level || NO_VALUE ;
__p += '\n';
 shift = data.shift || NO_VALUE ;
__p += '\n';
 semester = data["enrollment-semester"] || NO_VALUE ;
__p += '\n';
 discount = data.discount || NO_VALUE ;
__p += '\n';
 stockStatus = data["stock-status"] || NO_VALUE ;
__p += '\n';
 discountGroups = data["discount-groups"] || NO_VALUE ;
__p += '\n';
 saleableSeats = data["saleable-seats"] || NO_VALUE ;
__p += '\n\n';
 if(course.value !== null && course.value !== "") { ;
__p += '\n  <div class="row  pgc-table__cell__row--vertical-align">\n    <div class="col-sm-3">\n      <label class="control-label">Curso</label>\n    </div>\n    <div class="col-sm-9 readonly">\n      <select name="curso" class="js-filter-multi-select" multiple="multiple">\n        <option value="' +
((__t = ( course.value )) == null ? '' : __t) +
'" selected>' +
((__t = ( course.value )) == null ? '' : __t) +
'</option>\n      </select>\n    </div>\n  </div>\n';
 } ;
__p += '\n';
 if(campus.value !== null) { ;
__p += '\n  <div class="row  pgc-table__cell__row--vertical-align">\n    <div class="col-sm-3">\n      <label class="control-label">Campus</label>\n    </div>\n    <div class="col-sm-9 readonly">\n      <select name="campus" class="js-filter-multi-select" multiple="multiple">\n        ';
 for (var i = 0; i < campus.options.length; i++) { ;
__p += '\n          ';
 var option = campus.options[i] ;
__p += '\n          ';
 if(option.selected) { ;
__p += '\n            <option value="' +
((__t = ( option.value )) == null ? '' : __t) +
'" selected>' +
((__t = ( option.text )) == null ? '' : __t) +
'</option>\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      </select>\n    </div>\n</div>\n';
 } ;
__p += '\n';
 if(kind.value !== null) { ;
__p += '\n  <div class="row  pgc-table__cell__row--vertical-align">\n    <div class="col-sm-3">\n      <label class="control-label">Modalidade</label>\n    </div>\n    <div class="col-sm-9 readonly">\n      <select name="kind" class="js-filter-multi-select" multiple="multiple">\n        ';
 for (var i = 0, len = kind.options.length; i < len; i++) { ;
__p += '\n          ';
 var option = kind.options[i] ;
__p += '\n          ';
 if(option.selected) { ;
__p += '\n            <option value="' +
((__t = ( option.value )) == null ? '' : __t) +
'" selected>' +
((__t = ( option.text )) == null ? '' : __t) +
'</option>\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      </select>\n    </div>\n  </div>\n';
 } ;
__p += '\n';
 if(level.value !== null) { ;
__p += '\n  <div class="row  pgc-table__cell__row--vertical-align">\n    <div class="col-sm-3">\n      <label class="control-label">Nível</label>\n    </div>\n    <div class="col-sm-9 readonly">\n      <select name="level" class="js-filter-multi-select" multiple="multiple">\n        ';
 for (var i = 0, len = level.options.length; i < len; i++) { ;
__p += '\n          ';
 var option = level.options[i] ;
__p += '\n          ';
 if(option.selected) { ;
__p += '\n            <option value="' +
((__t = ( option.value )) == null ? '' : __t) +
'" selected>' +
((__t = ( option.text )) == null ? '' : __t) +
'</option>\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      </select>\n    </div>\n  </div>\n';
 } ;
__p += '\n';
 if(shift.value !== null) { ;
__p += '\n  <div class="row  pgc-table__cell__row--vertical-align">\n    <div class="col-sm-3">\n      <label class="control-label">Turno</label>\n    </div>\n    <div class="col-sm-9 readonly">\n      <select name="shift" class="js-filter-multi-select" multiple="multiple">\n        ';
 for (var i = 0, len = shift.options.length; i < len; i++) { ;
__p += '\n          ';
 var option = shift.options[i] ;
__p += '\n          ';
 if(option.selected) { ;
__p += '\n            <option value="' +
((__t = ( option.value )) == null ? '' : __t) +
'" selected>' +
((__t = ( option.text )) == null ? '' : __t) +
'</option>\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      </select>\n    </div>\n  </div>\n';
 } ;
__p += '\n';
 if(semester.value !== null) { ;
__p += '\n  <div class="row  pgc-table__cell__row--vertical-align">\n    <div class="col-sm-3">\n      <label class="control-label">Captação</label>\n    </div>\n    <div class="col-sm-9 readonly">\n      <select name="semester" class="js-filter-multi-select" multiple="multiple">\n        ';
 for (var i = 0, len = semester.options.length; i < len; i++) { ;
__p += '\n          ';
 var option = semester.options[i] ;
__p += '\n          ';
 if(option.selected) { ;
__p += '\n            <option value="' +
((__t = ( option.value )) == null ? '' : __t) +
'" selected>' +
((__t = ( option.text )) == null ? '' : __t) +
'</option>\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      </select>\n    </div>\n  </div>\n';
 } ;
__p += '\n';
 if(discount.value !== null && discount.value !== "") { ;
__p += '\n  <div class="row  pgc-table__cell__row--vertical-align">\n    <div class="col-sm-3">\n      <label class="control-label">Desconto</label>\n    </div>\n    <div class="col-sm-9 readonly">\n      <select name="discount" class="js-filter-multi-select" multiple="multiple">\n        <option value="' +
((__t = ( discount.value )) == null ? '' : __t) +
'" selected>' +
((__t = ( discount.value )) == null ? '' : __t) +
'</option>\n      </select>\n    </div>\n  </div>\n';
 } ;
__p += '\n';
 if(stockStatus.value !== null) { ;
__p += '\n  <div class="row  pgc-table__cell__row--vertical-align">\n    <div class="col-sm-3">\n      <label class="control-label">Status</label>\n    </div>\n    <div class="col-sm-9 readonly">\n      <select name="stockStatus" class="js-filter-multi-select" multiple="multiple">\n        ';
 for (var i = 0, len = stockStatus.options.length; i < len; i++) { ;
__p += '\n          ';
 var option = stockStatus.options[i] ;
__p += '\n          ';
 if(option.selected) { ;
__p += '\n            <option value="' +
((__t = ( option.value )) == null ? '' : __t) +
'" selected>' +
((__t = ( option.text )) == null ? '' : __t) +
'</option>\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      </select>\n    </div>\n  </div>\n';
 } ;
__p += '\n';
 if(discountGroups.value !== null) { ;
__p += '\n  <div class="row  pgc-table__cell__row--vertical-align">\n    <div class="col-sm-3">\n      <label class="control-label">Grupo de desconto</label>\n    </div>\n    <div class="col-sm-9 readonly">\n      <select name="discountGroups" class="js-filter-multi-select" multiple="multiple">\n        ';
 for (var i = 0, len = discountGroups.options.length; i < len; i++) { ;
__p += '\n          ';
 var option = discountGroups.options[i] ;
__p += '\n          ';
 if(option.selected) { ;
__p += '\n            <option value="' +
((__t = ( option.value )) == null ? '' : __t) +
'" selected>' +
((__t = ( option.text )) == null ? '' : __t) +
'</option>\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      </select>\n    </div>\n  </div>\n';
 } ;
__p += '\n';
 if(saleableSeats.value !== null && saleableSeats.value !== "") { ;
__p += '\n  <div class="row  pgc-table__cell__row--vertical-align">\n    <div class="col-sm-3">\n      <label class="control-label">Bolsas Disponíveis</label>\n    </div>\n    <div class="col-sm-9 readonly">\n      <select name="curso" class="js-filter-multi-select" multiple="multiple">\n        <option value="' +
((__t = ( saleableSeats.value )) == null ? '' : __t) +
'" selected>' +
((__t = ( saleableSeats.value )) == null ? '' : __t) +
'</option>\n      </select>\n    </div>\n  </div>\n';
 } ;
__p += '\n';

}
return __p
}