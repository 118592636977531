<template>
  <z-input-field :label="label">
    <z-autocomplete
      :internal-search="false"
      :multiple="true"
      :options="universitiesList"
      :value="universitiesSelected"
      @input="universitiesInputHandle($event)"
      @remove="universitiesRemoveHandle($event)"
      @select="universitiesSelectHandle($event)"
      hide-selected
      label-by="text"
    />

    <input
      :value="JSON.stringify(this.universitiesSelected)"
      type="hidden"
      v-bind="$attrs"
    >
  </z-input-field>
</template>

<script>
import { searchUniversity } from '@shared/services/autocompletes/university';

export default {
  props: {
    label: {
      type: String,
      default: 'Universidade(s)',
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      universitiesList: [],
    };
  },
  computed: {
    universitiesSelected() {
      return this.value;
    },
  },
  methods: {
    universitiesInputHandle($event) {
      this.requestSearchUniversity($event);
    },
    universitiesSelectHandle($event) {
      if (!$event) return;

      this.$emit('select', $event);
    },
    universitiesRemoveHandle($event) {
      this.$emit('remove', $event);
    },
    requestSearchUniversity(term) {
      searchUniversity(term).then(({ results }) => {
        this.universitiesList = results;
      });
    },
  },
  mounted() {
    this.requestSearchUniversity('');
  },
};
</script>
