import { Session } from './session';
import { Microsoft } from './microsoft';
import { Permission } from './permission';

const V1 = {
  Session,
  Microsoft,
  Permission,
};

export { V1 };
