import { Stepper } from '@stock/legacy/components/stepper';
import { ConfirmationStep } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/discountChange/steps/confirmation';
import { EditStep } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/discountChange/steps/edit';
import { ResponseStep } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/shared-steps/response';
import { ValidateStep } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/discountChange/steps/validate';

export class DiscountChangeStepper extends Stepper {
  constructor({ offer }) {
    super();
    this.offer = offer;
    this._validate();
    this._setup();
  }

  createSteps() {
    if (this.offer.regressive_discount) return [this._createValidateStep()];
    return [
      this._createEditStep(),
      this._createConfirmationStep(),
      this._createResponseStep(),
    ];
  }

  _validate() {
    if (!this.offer) throw Error('offer is a required parameter');
  }

  _setup() {
    return this.createSteps().forEach(step => this.addStep(step));
  }

  _createValidateStep() {
    const validateStep = new ValidateStep();
    return this._setupFirstStep(validateStep);
  }

  _createEditStep() {
    const editStep = new EditStep(this.offer);
    this._setupFirstStep(editStep);
    editStep.onHook('next', () => this.next());
    return editStep;
  }

  _createConfirmationStep() {
    const confirmationStep = new ConfirmationStep(this.offer);
    confirmationStep.onHook('previous', () => this.previous());
    confirmationStep.onHook('next', () => this.next());
    return confirmationStep;
  }

  _createResponseStep() { return new ResponseStep(); }

  _setupFirstStep(step) {
    step.onHook('previous', () => {
      if (step.previous) {
        return this.previous();
      }
      return this.event.emit('finished');
    });
    return step;
  }
}
