export class LocalStorageAPI {
  static getInstance() {
    if (!LocalStorageAPI.instance) {
      LocalStorageAPI.instance = new LocalStorageAPI();
    }
    return LocalStorageAPI.instance;
  }

  set(property, value, namespace, timeout = null) {
    if (!this._checkIfBrowserHasSupport()) return undefined;
    if (!(property && value && namespace)) return this._callErrorMessage();

    const item = { value, expiryTime: this._getExpiryTime(timeout) };

    const propertyName = this._setupName(namespace, property);
    return window.localStorage.setItem(propertyName, JSON.stringify(item));
  }

  get(property, namespace) {
    if (!this._checkIfBrowserHasSupport()) return undefined;
    if (!(property && namespace)) return this._callErrorMessage();
    const propertyName = this._setupName(namespace, property);

    let item = null;

    try {
      item = JSON.parse(window.localStorage.getItem(propertyName));
    } catch (_error) {
      this.remove(property, namespace);
      return null;
    }

    if (!item) return null;
    if (item.expiryTime === null) return item.value;

    const now = new Date();

    if (now.getTime() > item.expiryTime) {
      window.localStorage.removeItem(propertyName);
      return null;
    }

    return item.value;
  }

  remove(property, namespace) {
    if (!this._checkIfBrowserHasSupport()) return undefined;
    if (!(property && namespace)) return this._callErrorMessage();
    const propertyName = this._setupName(namespace, property);

    return window.localStorage.removeItem(propertyName);
  }

  _getExpiryTime(timeout) {
    if (timeout === null) return null;

    const now = new Date();

    return now.getTime() + timeout;
  }

  _checkIfBrowserHasSupport() {
    return !(typeof (Storage) === 'undefined');
  }

  _setupName(namespace, property) {
    return `${namespace}_${property}`;
  }

  _callErrorMessage() {
    console.error('[LocalStorageAPI] Missing required params');
    return undefined;
  }
}
