<template>
  <div class="upa-helper-modal">
    <z-title
      size="medium"
      class="upa-helper-modal__title"
      v-if="title"
    >
      {{ title }}
    </z-title>

    <UPAModalFooter
      @right-button-click="$emit('right-button-click')"
      @left-button-click="$emit('left-button-click')"
    />
  </div>
</template>

<script>
import { UPAModalFooter } from '@shared/components/upa-modal';

export default {
  props: {
    title: {
      type: String,
      default: 'Deseja confirmar?',
    },
  },
  components: {
    UPAModalFooter,
  },
};
</script>

<style>
.upa-helper-modal__title {
  margin-bottom: var(--space-medium);
}
</style>
