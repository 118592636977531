module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div\n  class="modal modal-dark modal-dark--l fade"\n  tabindex="-1"\n  role="dialog"\n>\n';
 var modalStyle = (maxWidth ? 'style="max-width: ' + maxWidth + 'px"' : '') ;
__p += '\n<div class="modal-dialog modal-dark--l" ' +
((__t = ( modalStyle )) == null ? '' : __t) +
' >\n  <div class="modal-content madal-dark--l">\n    <div class="modal-header modal-dark__header">\n\n      <button\n        type="button"\n        class="close modal-dark__close"\n        data-dismiss="modal"\n        aria-label="Close"\n      >\n        <span aria-hidden="true">&times;</span>\n      </button>\n      Criação de ofertas vitrine\n    </div>\n    <div class="modal-body modal-dark__body modal-dark__body--text-left">\n      <div class="modal-dark__subtitle modal-dark__subtitle--spaced-bottom">\n        Digite abaixo o acréscimo de desconto e a quantidade de bolsas por oferta que deseja adicionar.\n      </div>\n\n      <form class="js-batch-stock-form">\n\n        <div class="row">\n          <div class="col-md-6 col-sm-12">\n            <div class="form-group">\n              <label class="control-label">ACRÉSCIMO DESCONTO (%)</label>\n              <div class="input-group prefix">\n                <span class="input-group-addon">+</span>\n                <input\n                  class="form-control js-window-offers-creation-discount-increment"\n                  min="1"\n                  max="' +
((__t = ( maxDiscountIncrement )) == null ? '' : __t) +
'"\n                  placeholder="Ex.: +10"\n                />\n              </div>\n            </div>\n          </div>\n\n          <div class="col-md-6 col-sm-12">\n            <div class="form-group">\n              <label class="control-label">QTD. DE BOLSAS</label>\n              <input\n                class="form-control js-window-offers-creation-seats-count"\n                type="number"\n                min="1"\n                max="5"\n                placeholder="Ex.: 5"\n              />\n            </div>\n          </div>\n        </div>\n\n        <p>\n          <strong>Exemplo:</strong>\n          Acrescentando 10% de desconto em uma bolsa de 40%, seu desconto vitrine será de 50%.\n        </p>\n\n        <div class="filter-details">\n          <div class="form-group filter-details__box">\n            <div class="box box__center-content box--small-height box--s">\n              <div class="js-offer-count">\n                <h4>\n                  <b>' +
((__t = ( totalOffersTargetCount )) == null ? '' : __t) +
'</b> de <b>' +
((__t = ( totalOffersCount )) == null ? '' : __t) +
'*</b>\n                </h4>\n              </div>\n              <p class="filter-details__text js-filter-details">ofertas serão afetadas</p>\n            </div>\n          </div>\n          <div class="form-group filter-details__box">\n            <div class="box box__center-content box--small-height box--s">\n              <h4>\n                <b>' +
((__t = ( (totalOffersCount - totalOffersTargetCount) )) == null ? '' : __t) +
'</b>\n              </h4>\n              <p class="filter-details__text">ofertas bloqueadas por ações em lote</p>\n            </div>\n          </div>\n        </div>\n\n        <ul class="filter-details__disclaimer">\n          <li class="filter-details__disclaimer-item">\n            Ofertas vitrine podem ser criadas com no <strong>máximo 5 bolsas.</strong>\n          </li>\n          <li class="filter-details__disclaimer-item">\n            O <strong>acréscimo de desconto máximo</strong> permitido será de <strong>20%</strong>.\n          </li>\n          <li class="filter-details__disclaimer-item">\n            O <strong>desconto máximo</strong> permitido será de <strong>80%</strong>.\n            Caso queira dar um desconto maior, fale com seu key account.\n          </li>\n          <li class="filter-details__disclaimer-item">\n            Não é possível criar ofertas vitrine em <strong>vagas compartilhadas.</strong>\n          </li>\n          <li class="filter-details__disclaimer-item">\n            Não é possível criar ofertas vitrine de <strong>ofertas com desconto regressivo.</strong>\n          </li>\n        </ul>\n\n        <div class="form-group">\n          <label class="control-label"><b>Filtros ativos</b></label>\n          <div class="active-filters__body js-active-filters"></div>\n        </div>\n      </form>\n    </div>\n    <div class="modal-footer modal-dark__footer">\n      <button\n        type="button"\n        class="btn btn-secondary reverse modal-dark__wide-button js-cancel-modal"\n        data-dismiss="modal"\n      >\n        Cancelar\n      </button>\n      <button\n        type="button"\n        class="js-next-button btn btn-primary modal-dark__wide-button"\n        disabled\n      >\n        Avançar\n      </button>\n    </div>\n  </div>\n</div>\n';

}
return __p
}