import wNumb from 'wnumb/wNumb';

class CurrencyFormatter {
  constructor() {
    const config = {
      decimals: 2,
      mark: ',',
      prefix: 'R$ ',
      thousand: '.',
    };
    this.formatter = wNumb(config);
  }

  format(value) {
    let parsedValue = value;
    if (CurrencyFormatter.isStringValue(value)) {
      parsedValue = parseFloat(value);
    }
    return this.formatter.to(parsedValue);
  }

  static isStringValue(value) {
    return typeof value === 'string';
  }
}

export default new CurrencyFormatter();
