module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="modal modal-dark modal-dark--l fade"\n  id="restock-confirmation"\n  tabindex="-1" role="dialog"\n  aria-labelledby="restock-modal-label"\n  style="display: none">\n  <div class="modal-dialog modal-dark--l">\n    <div class="modal-content modal-dark--l">\n      <div class="modal-header modal-dark__header modal-dark__header--bottom-l">\n        <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n          <span aria-hidden="true">&times;</span>\n        </button>\n        <span id="restock-modal-label">Confirmar criação de ofertas vitrine</span>\n      </div>\n      <div class="modal-body modal-dark__body restock-modal__body">\n        Confira abaixo as mudanças que você fez em suas ofertas vitrine.\n        Se estiver de acordo, clique em &ldquo;<strong>Confirmar</strong>&rdquo;.\n\n        <div class="table-responsive restock-modal__table">\n          <table class="pgc-table">\n            <thead>\n              <tr class="pgc-table__row">\n                <th class="pgc-table__head-header">Acréscimo no desconto</th>\n                <th class="pgc-table__head-header">Quantidade de bolsas</th>\n                <th class="pgc-table__head-header">Quantidade de ofertas vitrine criadas*</th>\n              </tr>\n            </thead>\n            <tbody class="pgc-table__body pgc-table__body--hoverable">\n                <tr class="pgc-table__row">\n                  <td class="pgc-table__cell js-discount-increment">\n                    +' +
((__t = ( discountIncrement )) == null ? '' : __t) +
'%\n                  </td>\n                  <td class="pgc-table__cell js-seats-count">\n                    ' +
((__t = ( seatsCount )) == null ? '' : __t) +
'\n                  </td>\n                  <td class="pgc-table__cell js-offers-count">\n                    ' +
((__t = ( offersCount )) == null ? '' : __t) +
'\n                  </td>\n                </tr>\n            </tbody>\n          </table>\n\n          <ul class="filter-details__disclaimer">\n            <li class="filter-details__disclaimer-item">Desde que as ofertas não ultrapassem 80% de desconto.</li>\n          </ul>\n        </div>\n      </div>\n      <div class="modal-footer modal-dark__footer">\n        <button type="button" class="btn btn-secondary modal-dark__wide-button reverse" data-dismiss="modal">\n          Cancelar\n        </button>\n        <button type="button" class="js-confirm-button btn btn-primary modal-dark__wide-button" data-dismiss="modal">\n          Confirmar\n        </button>\n      </div>\n    </div>\n  </div>\n</div>\n';

}
return __p
}