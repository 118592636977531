import { StepsNavigationSetup, CreateAdmissionConfigModal } from '@quero-turbo/modules/admission-components';
import { getField, updateField } from 'vuex-map-fields';
import { QueroDealsServices } from '@quero-turbo/services';
import { ZToast } from '@shared/legacy/components/zilla/z-toast';
import { sidebarSectionsBuilder } from './sidebar-sections-builder';

const FIRST_STEP = 'step01';
const REVIEW_STEP = 'step04';

const getDefaultState = () => ({
  studentDocuments: [
    { value: 'rg', selected: false },
    { value: 'cnh', selected: false },
    { value: 'comprovante_de_residencia', selected: false },
    { value: 'foto_3x4', selected: false },
    { value: 'comprovante_de_voto', selected: false },
    { value: 'titulo_de_eleitor', selected: false },
    { value: 'certidao_de_nascimento_ou_casamento', selected: false },
    { value: 'exame_de_sangue', selected: false },
    { value: 'diario_oficial', selected: false },
    { value: 'certificado_de_conclusao_do_ensino_medio', selected: false },
    { value: 'historico_escolar', selected: false },
    { value: 'selfie_com_documento', selected: false },
    { value: 'certificado_de_reservista', selected: false },
  ],
  parentDocuments: [
    { value: 'rg_do_guardiao', selected: false },
    { value: 'cnh_do_guardiao', selected: false },
    { value: 'comprovante_de_residencia_do_guardiao', selected: false },
  ],
  graduateDocuments: [
    { value: 'certificado_graduacao', selected: false },
    { value: 'historico_graduacao', selected: false },
  ],
  customFields: [
    { value: 'pne', selected: false },
    { value: 'color_or_race', selected: false },
    { value: 'civil_status', selected: false },
    { value: 'spouse_name', selected: false },
    { value: 'name_of_father', selected: false },
    { value: 'name_of_mother', selected: false },
    { value: 'naturalized_from_uf', selected: false },
    { value: 'naturalized_from', selected: false },
    { value: 'naturalized_from_city_ibge', selected: false },
    { value: 'nationality', selected: false },
    { value: 'type_of_school_completed_high_school', selected: false },
    { value: 'high_school_completion_year', selected: false },
    { value: 'graduation_course_name', selected: false },
    { value: 'graduation_year', selected: false },
    { value: 'graduation_ies_name', selected: false },
    { value: 'graduation_ies_city', selected: false },
    { value: 'graduation_ies_uf', selected: false },
    { value: 'enade', selected: false },
    { value: 'family_income', selected: false },
    { value: 'not_completed_another_undergraduate', selected: false },
    { value: 'parents_have_higher_education', selected: false },
    { value: 'main_education_kind', selected: false },
    { value: 'main_transport_method', selected: false },
    { value: 'has_computer_at_home', selected: false },
    { value: 'will_work_while_studying', selected: false },
    { value: 'work_on_course_area', selected: false },
    { value: 'mobile_monthly_spents', selected: false },
    { value: 'home_internet_speed', selected: false },
  ],
  currentStepName: FIRST_STEP,
  digitalAdmissionCutScore: null,
  enemCutScore: null,
  enemCutYear: null,
  essayCutYear: null,
  hasVestibular: true,
  clicksignTemplateId: null,
  disableNextButtonStep01: true,
  disableNextButtonStep03: false,
  configStatus: null,
  hasDirectEnrollment: false,
  directEnrollmentBillingIntegration: null,
  directEnrollmentMinSeats: null,
  directEnrollmentLabel: null,
  businessRuleGroupId: null,
  productConfigId: null,
});

const state = getDefaultState();

const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setBusinessRuleGroupId(state, newBusinessRuleGroupId) {
    state.businessRuleGroupId = newBusinessRuleGroupId;
  },
  updateStepConfigStatus(state, newStatus) {
    state.configStatus = newStatus;
  },
  setInitialState(state, initialState) {
    Object.assign(state, initialState);
  },
  updateStudentDocuments(state, index) {
    state.studentDocuments[index].selected = !state.studentDocuments[index].selected;
  },
  updateDisableNextButtonStep01(state, disableNextButton) {
    state.disableNextButtonStep01 = disableNextButton;
  },
  updateDisableNextButtonStep03(state, disableNextButton) {
    state.disableNextButtonStep03 = disableNextButton;
  },
  updateCustomFields(state, index) {
    state.customFields[index].selected = !state.customFields[index].selected;
  },
  updateParentDocuments(state, index) {
    state.parentDocuments[index].selected = !state.parentDocuments[index].selected;
  },
  updateGraduateDocuments(state, index) {
    state.graduateDocuments[index].selected = !state.graduateDocuments[index].selected;
  },
  setCurrentStepName(state, newName) {
    state.currentStepName = newName;
  },
};

const getters = {
  getField,
  currentStep(state) {
    return StepsNavigationSetup[state.currentStepName];
  },
  currentComponent(_state, getter) {
    return getter.currentStep.component;
  },
  currentStepTitle(_state, getter) {
    return getter.currentStep.title;
  },
  currentStepHasSidebar(_state, getter) {
    return getter.currentStep.sidebar;
  },
  rightButtonText(state) {
    if (state.configStatus !== 'creating') return 'Concluído';
    if (state.currentStepName === REVIEW_STEP) return 'Criar';
    return 'Avançar';
  },
  leftButtonText(state) {
    if (state.currentStepName === FIRST_STEP) return 'Fechar';
    if (state.configStatus === 'creating') return 'Voltar';
    if (state.configStatus === 'active' || state.configStatus === 'editing') return 'Desativar';

    return null;
  },
  sidebarSectionsItems(state) {
    return sidebarSectionsBuilder(state);
  },
  formattedAdmissionConfig(state) {
    const userCustomFields = getFieldValues(state.customFields);
    const requiredDocuments = getAllSelectedDocuments(state);

    let config = {
      digitalAdmissionCutScore: state.digitalAdmissionCutScore,
      enemCutScore: state.enemCutScore,
      enemCutYear: state.enemCutYear === 0 ? null : state.enemCutYear,
      essayCutYear: state.essayCutYear,
      skipVestibular: !state.hasVestibular,
      userCustomFields,
      requiredDocuments,
      clicksignTemplateId: state.clicksignTemplateId === null ? null : state.clicksignTemplateId.toLowerCase(),
    };

    if (state.hasDirectEnrollment) {
      config = {
        ...config,
        directEnrollment: {
          label: state.directEnrollmentLabel,
          billingIntegration: state.directEnrollmentBillingIntegration,
          minSeats: state.directEnrollmentMinSeats,
        },
      };
    }

    return config;
  },
  selectedStudentDocuments(state) {
    return state.studentDocuments.filter(getSelectedFields);
  },
  selectedParentDocuments(state) {
    return state.parentDocuments.filter(getSelectedFields);
  },
  selectedGraduateDocuments(state) {
    return state.graduateDocuments.filter(getSelectedFields);
  },
  selectedCustomFields(state) {
    return state.customFields.filter(getSelectedFields);
  },
  disableNextButton(state) {
    const stepDisableNextButton = {
      step01: state.disableNextButtonStep01,
      step02: false,
      step03: state.disableNextButtonStep03,
      step04: false,
    };
    return stepDisableNextButton[state.currentStepName];
  },
};

const actions = {
  async initializeStateModule({ commit, state }, initialState) {
    if (initialState) { // Only for Tests
      await commit('setInitialState', initialState);
    }
    return state;
  },
  resetAdmissionConfigState({ commit }) {
    commit('resetState');
  },
  setStudentDocuments({ commit }, index) {
    commit('updateStudentDocuments', index);
  },
  setParentDocuments({ commit }, index) {
    commit('updateParentDocuments', index);
  },
  setGraduateDocuments({ commit }, index) {
    commit('updateGraduateDocuments', index);
  },
  setCustomFields({ commit }, index) {
    commit('updateCustomFields', index);
  },
  setDisableNextButtonStep01({ commit }, disableNextButton) {
    commit('updateDisableNextButtonStep01', disableNextButton);
  },
  setDisableNextButtonStep03({ commit }, disableNextButton) {
    commit('updateDisableNextButtonStep03', disableNextButton);
  },
  async submitCreateRequest({ dispatch, state, getters: getter }) {
    await QueroDealsServices.V1.AdmissionConfig
      .create(state.businessRuleGroupId, getter.formattedAdmissionConfig)
      .then(() => {
        ZToast.showAlert('Sua solicitação está sendo processada', { type: 'success' });
      })
      .catch(() => {
        ZToast.showError('Falha ao criar configuração de admissão');
      });
    dispatch(
      'ShowBRGPageModule/requestFindBRGWithProductConfigs', { id: state.businessRuleGroupId }, { root: true },
    )
      .then(() => {
        this._vm.$upaHelperModal.close();
      });
  },
  async submitDisactiveRequest({ state, dispatch }) {
    await QueroDealsServices.V1.ProductConfig
      .update(state.productConfigId, { event: 'request_deactivation' })
      .then(() => {
        ZToast.showAlert('Sua solicitação está sendo processada', { type: 'success' });
      })
      .catch(() => {
        ZToast.showError('Falha ao desativar configuração de admissão');
      });
    dispatch(
      'ShowBRGPageModule/requestFindBRGWithProductConfigs', { id: state.businessRuleGroupId }, { root: true },
    )
      .then(() => {
        this._vm.$upaHelperModal.close();
      });
  },
  async handleNext({ commit, getters: getter }) {
    commit('setCurrentStepName', getter.currentStep.nextStepName);
  },
  async handlePrev({ commit, getters: getter }) {
    commit('setCurrentStepName', getter.currentStep.prevStepName);
  },
  async handleOpenActivationModal({ commit }, { businessRuleGroupId }) {
    await commit('resetState');
    commit('setBusinessRuleGroupId', businessRuleGroupId);
    commit('updateStepConfigStatus', 'creating');
    await this._vm.$upaHelperModal.show({
      mode: 'custom',
      size: 'medium',
      options: { component: CreateAdmissionConfigModal },
    });

    await this._vm.$upaHelperModal.nextAction();

    this._vm.$upaHelperModal.close();
  },
  async handleOpenShowModal({ commit, state }, { data, businessRuleGroupId }) {
    await commit('resetState');
    const newState = getShowModalState(state, data);
    commit('setBusinessRuleGroupId', businessRuleGroupId);
    commit('setInitialState', newState);

    this._vm.$upaHelperModal.show({
      mode: 'custom',
      size: 'medium',
      options: { component: CreateAdmissionConfigModal },
    });

    await this._vm.$upaHelperModal.nextAction();

    this._vm.$upaHelperModal.close();
  },
  closeModal() {
    this._vm.$upaHelperModal.close();
  },
};

function getShowModalState(state, { payload, id, status }) {
  let newState = {
    studentDocuments: getFormattedFields(state.studentDocuments, payload.requiredDocuments),
    parentDocuments: getFormattedFields(state.parentDocuments, payload.requiredDocuments),
    graduateDocuments: getFormattedFields(state.graduateDocuments, payload.requiredDocuments),
    customFields: getFormattedFields(state.customFields, payload.userCustomFields),
    currentStepName: REVIEW_STEP,
    digitalAdmissionCutScore: payload.digitalAdmissionCutScore,
    enemCutScore: payload.enemCutScore,
    enemCutYear: payload.enemCutYear,
    essayCutYear: payload.essayCutYear,
    hasVestibular: !payload.skipVestibular,
    clicksignTemplateId: payload.clicksignTemplateId,
    configStatus: status,
    productConfigId: id,
  };

  if (payload.directEnrollment) {
    newState = {
      ...newState,
      hasDirectEnrollment: Boolean(payload.directEnrollment),
      directEnrollmentBillingIntegration: payload.directEnrollment.billingIntegration,
      directEnrollmentMinSeats: payload.directEnrollment.minSeats,
      directEnrollmentLabel: payload.directEnrollment.label,
    };
  }

  return newState;
}

function getSelectedFields(field) { return field.selected; }

function getAllSelectedDocuments(state) {
  const studentDocumentsValues = getFieldValues(state.studentDocuments);
  const graduateDocumentsValues = getFieldValues(state.graduateDocuments);
  const parentDocumentsValues = getFieldValues(state.parentDocuments);

  return [
    ...studentDocumentsValues,
    ...graduateDocumentsValues,
    ...parentDocumentsValues,
  ];
}

function getFieldValues(fields) {
  const values = [];
  fields.filter(getSelectedFields).forEach((field) => {
    values.push(field.value);
  });

  return values;
}

function getFormattedFields(fields, selectedFields) {
  const values = [];
  fields.filter(field => selectedFields.includes(field.value)).forEach((field) => {
    values.push({ ...field, selected: true });
  });

  return values;
}

const CreateAdmissionConfigModalModule = {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};

export { CreateAdmissionConfigModalModule };
