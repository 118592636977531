<template>
  <z-modal
    :open="open"
    @closed="closed"
    class="operation-log-details"
  >
    <div
      v-if="!stockOperation"
      class="operation-log-details__flex-item-error"
    >
      <ZIcon
        :icon="['fad', 'times-circle']"
        title="error"
        size="giant"
        color="danger"
      />
      <ZText class="operation-log-details__text-error">
        Falha ao buscar os detalhes da operação no estoque, tente novamente mais tarde.
      </ZText>
    </div>

    <div v-else>
      <div class="operation-log-details__header">
        <ZTitle
          size="medium"
          color="info"
        >
          Operação de {{ stockOperation.name }}
        </ZTitle>
      </div>

      <div class="operation-log-details__flex-item">
        <div class="operation-log-details__fragment">
          <ZText class="operation-log-details__text">
            Responsável:
          </ZText>
          <ZText> {{ stockOperation.system_user_id }} </ZText>
        </div>

        <div class="operation-log-details__fragment">
          <ZText class="operation-log-details__text">
            Criada em:
          </ZText>
          <ZText> {{ stockOperation.created_at }} </ZText>
        </div>

        <div class="operation-log-details__fragment">
          <ZText class="operation-log-details__text">
            UUID:
          </ZText>
          <ZText> {{ stockOperation.uuid }} </ZText>
        </div>
      </div>

      <div class="operation-log-details__flex-item">
        <div class="operation-log-details__fragment">
          <ZText class="operation-log-details__text">
            Status:
          </ZText>
          <ZText
            :class="{
              'operation-log-details__text-danger': stockOperation.status === 'Concluído Com Erros',
            }"
          >
            {{ stockOperation.status }}
          </ZText>
        </div>

        <div class="operation-log-details__fragment">
          <ZText class="operation-log-details__text">
            Total de itens:
          </ZText>
          <ZText> {{ stockOperation.total_rows }} </ZText>
        </div>

        <div class="operation-log-details__fragment">
          <ZText class="operation-log-details__text">
            Itens com sucesso:
          </ZText>
          <ZText> {{ stockOperation.success_count }} </ZText>
        </div>

        <div class="operation-log-details__fragment">
          <ZText class="operation-log-details__text">
            Itens com erros de validação:
          </ZText>
          <ZText> {{ stockOperation.validation_error_count }} </ZText>
        </div>

        <div class="operation-log-details__fragment">
          <ZText class="operation-log-details__text">
            Itens com erros inesperados:
          </ZText>
          <ZText> {{ stockOperation.runtime_error_count }} </ZText>
        </div>
      </div>

      <div class="operation-log-details__flex-item">
        <div v-if="errorDetails">
          <ZTitle
            align="center"
            size="small"
            color="info"
          >
            Erros da Operação
          </ZTitle>
          <div>
            <div class="operation-log-errors-details">
              <div
                v-for="(item, index) in this.operationErrors.data"
                :key="index"
              >
                <ZText align="center">
                  {{ (item.runtime_errors || item.validation_errors).join(',') }}
                </ZText>
                <ZDivider variant="light" />
              </div>
            </div>

            <z-pagination
              :total-pages="this.getTotalPages()"
              aria-label="Paginação de operações"
              @change-page="onPageChange"
              :current-page="currentPage"
            />

            <z-button
              v-if="stockOperation.name === 'Criação de Campus'"
              @click="exportErrors"
              :is-loading="isLoading"
              :disabled="isLoading"
              class="button"
            >
              Exportar Erros
            </z-button>
          </div>
        </div>
      </div>
    </div>
  </z-modal>
</template>

<script>
import {
  ZTitle,
  ZText,
  ZDivider,
  ZIcon,
} from '@quero/zilla-vue';
import { downloadStockOperationErrors, getStockOperationErrorDetails } from '@shared/services/stock/get-operations/get-stock-operations';

export default {
  name: 'OperationLogDetails',
  components: {
    ZTitle,
    ZText,
    ZDivider,
    ZIcon,
  },
  data() {
    return {
      currentPage: 1,
      operationErrors: [],
      isLoading: false,
    };
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    stockOperation: {
      type: Object,
      required: true,
    },
    errorDetails: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  mounted() {
    if (this.errorDetails) { this.getErrorDetails(); }
  },
  methods: {
    closed() {
      this.$emit('closed');
    },
    onPageChange(page) {
      this.currentPage = page;

      this.getErrorDetails();
    },
    getTotalPages() {
      const totalErrorsCount = this.operationErrors.total_count;
      const pageSize = this.operationErrors.page_size;

      const totalPages = Math.ceil(totalErrorsCount / pageSize);

      return totalPages;
    },
    getErrorDetails() {
      const { uuid } = this.stockOperation;

      getStockOperationErrorDetails(uuid, this.currentPage).then((response) => { this.operationErrors = response; });
    },
    exportErrors() {
      this.isLoading = true;
      const { uuid } = this.stockOperation;
      const totalErrors = this.operationErrors.total_count;
      downloadStockOperationErrors(uuid, totalErrors).finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@quero/zilla-core/src/utils/_index.scss";

.operation-log-details {
  .z-modal__wrapper {
    transition: max-width 250ms ease;
    display: flex;
    z-index: 1;

    @media (min-width: $screen-desktop) {
      display: flex;
      z-index: 1;
      width: 100%; // TODO: Remove this when zilla updates to customize width
    }
  }

  .z-modal__close-icon {
    height: calc(var(--size-extra-large));
    width: calc(var(--size-extra-large));

    @media (min-width: $screen-desktop) {
      height: calc(var(--size-extra-large));
      width: calc(var(--size-extra-large));
    }
  }
}

.operation-log-details__header {
  margin-bottom: 30px;
}

.operation-log-details__flex-item {
  margin-top: 10px;
}

.operation-log-details__fragment {
  display: flex;
}

.operation-log-details__text {
  font-weight: 500;
  padding-right: 10px;
}

.operation-log-errors-details {
  margin-top: 10px;
  height: 100px;
  overflow-y: scroll;
  margin-bottom: var(--space-medium);
}

.operation-log-details__flex-item-error {
  display: flex;
  padding: 30px;
}

.operation-log-details__text-error {
  align-self: center;
  font-weight: 500;
}

.button {
  float: right;
}

.operation-log-details__text-danger {
  font-weight: var(--font-weight-medium);
  color: red;
}
</style>
