import $ from 'jquery';
import 'confirm-bootstrap';

class FollowUpUpdateActions {
  constructor(page) {
    if (page.length) {
      this.page = page;
      this.url = '/busca-pre-matriculas/';
      this.listingUpdate();
      this.initRegistrationModal();
      this.initEnrollmentModal();
      this.initApproveModal();
      this.initDisapproveModal();
      this.initApproveDocsModal();
      this.initRejectDocsModal();
    }
  }

  listingUpdate() {
    const listen = this.$('.js-filter-form');
    if (listen.length) {
      listen.bind('updateListen', () => {
        this.initRegistrationModal();
        this.initEnrollmentModal();
        this.initApproveModal();
        this.initDisapproveModal();
        this.initApproveDocsModal();
        this.initRejectDocsModal();
      });
    }
  }

  confirmationModal(selector, options) {
    this.$(selector).confirmModal(options);
  }

  initRegistrationModal() {
    this.confirmationModal('.js-follow-up-update-register', {
      confirmTitle: 'Confirmar ação',
      confirmMessage: 'Deseja informar que o vestibular foi agendado na instituição?',
      confirmOk: 'Informar agendamento de vestibular',
      confirmCancel: 'Cancelar',
      confirmStyle: 'primary',
      confirmCallback: this.updateRequest,
      confirmDismiss: true,
      confirmAutoOpen: false,
      classWrapper: this,
      updateType: 'register',
    });
  }

  initEnrollmentModal() {
    this.confirmationModal('.js-follow-up-update-enrollment', {
      confirmTitle: 'Você tem certeza?',
      confirmMessage: 'Esta ação informa ao Quero Bolsa que o aluno foi admitido na sua Instituição com a etapa de matrícula completa. Tem certeza que deseja informar a matrícula?',
      confirmOk: 'Tenho certeza',
      confirmCancel: 'Cancelar',
      confirmStyle: 'primary',
      confirmCallback: this.updateRequest,
      confirmDismiss: true,
      confirmAutoOpen: false,
      classWrapper: this,
      updateType: 'enrollment',
    });
  }

  initApproveModal() {
    this.confirmationModal('.js-follow-up-update-approve', {
      confirmTitle: 'Confirmar ação',
      confirmMessage: 'Deseja informar que o aluno foi aprovado no vestibular da instituição?',
      confirmOk: 'Informar aprovação do aluno no vestibular',
      confirmCancel: 'Cancelar',
      confirmStyle: 'primary',
      confirmCallback: this.updateRequest,
      confirmDismiss: true,
      confirmAutoOpen: false,
      classWrapper: this,
      updateType: 'approve',
    });
  }

  initDisapproveModal() {
    this.confirmationModal('.js-follow-up-update-disapprove', {
      confirmTitle: 'Confirmar ação',
      confirmMessage: 'Deseja informar que o aluno foi reprovado no vestibular da instituição?',
      confirmOk: 'Informar reprovação do aluno no vestibular',
      confirmCancel: 'Cancelar',
      confirmStyle: 'primary',
      confirmCallback: this.updateRequest,
      confirmDismiss: true,
      confirmAutoOpen: false,
      classWrapper: this,
      updateType: 'disapprove',
    });
  }

  initApproveDocsModal() {
    this.confirmationModal('.js-follow-up-update-approve-documents', {
      confirmTitle: 'Você tem certeza?',
      confirmMessage: 'Este botão informa ao Quero Bolsa que os documentos entregues pelo aluno foram aceitos. Tem certeza que deseja aceitar os documentos?',
      confirmOk: 'Sim',
      confirmCancel: 'Cancelar',
      confirmStyle: 'primary',
      confirmCallback: this.updateRequest,
      confirmDismiss: true,
      confirmAutoOpen: false,
      classWrapper: this,
      updateType: 'enrollment',
    });
  }

  initRejectDocsModal() {
    this.confirmationModal('.js-follow-up-update-reject-documents', {
      confirmTitle: 'Você tem certeza?',
      confirmMessage: 'Este botão informa ao Quero Bolsa que os documentos entregues pelo aluno foram rejeitados. Tem certeza que deseja rejeitar os documentos?',
      confirmOk: 'Sim',
      confirmCancel: 'Cancelar',
      confirmStyle: 'primary',
      confirmCallback: this.updateRequest,
      confirmDismiss: true,
      confirmAutoOpen: false,
      classWrapper: this,
      updateType: 'reject_documents',
    });
  }

  updateRequest(el) {
    const type = this.updateType;

    $.ajax({
      url: `${this.classWrapper.url + el.data('followUpId')}/${type}`,
      method: 'POST',
      data: {},
    }).done(() => {
      $('#js-modal-message-ok-update').modal('show');
      const $ul = el.closest('ul');
      el.closest('li').remove();

      const $dropdown = $ul.closest('.actions').find('.dropdown-toggle');

      if ($ul.find('li').length === 0) {
        this.classWrapper.disableDropdown($dropdown);
      }
    }).fail(() => {
      $('#js-modal-message-error-update').modal('show');
    });
  }

  disableDropdown(dropdown) {
    dropdown.addClass('disabled');
  }

  $(selector) {
    return $(selector, this.page);
  }
}

export default FollowUpUpdateActions;
