<template>
  <grid-container>
    <grid-item>
      <HeaderBox title="Gestão de ofertas em lote" />
    </grid-item>

    <grid-item>
      <OfferCreationBatchMenu
        @create-sheet="handleCreateSheetClick"
        :disable="disableCreateSheetButton"
        class="offer-creation-batch__menu"
      />
    </grid-item>

    <grid-item>
      <OfferCreationBatchSheets
        :offer-creation-sheets="offerCreationSheets"
        @edit-sheet="handleOpenEditSheetClick"
        @view-sheet="handleOpenViewSheetClick"
        @created-sheet="handleCreatedSheetStatus"
        @create-sheet-error="handleCreatedSheetStatusError"
      />
    </grid-item>

    <OfferCreationBatchFullscreenSheet
      :open="fullScreenOpen"
      :edit-mode="editMode"
      :sheet-url="selectedSheet.sheetUrl"
      @save-and-close="closeAndSaveSheet"
      @close-sheet-view="closeSheetView"
      @submit="handleSubmitSheetClick"
      v-if="selectedSheet"
    />

    <FeedbackModal
      v-if="selectedSheet && processingStatus"
      :config="feedbackCardsConfig"
      :open="feedbackModalOpened"
      :status="processingStatus"
      :closable="false"
      @closed="closeFeedbackModal"
      @success-click="handleSuccessClick"
      @error-click="handleErrorClick"
    />

    <z-toast
      v-if="toastItems"
      :items="toastItems"
    />
  </grid-container>
</template>

<script>
import {
  OfferCreationBatchMenu,
  OfferCreationBatchSheets,
  SheetsModel,
  OfferCreationBatchFullscreenSheet,
} from '@stock/modules/offer-creation-batch';
import { FeedbackModal } from '@stock/components/feedback-components';
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { HeaderBox } from '@shared/components/header-box';
import { createOfferCreationSpreadsheet } from '@stock/services/create-offer-creation-spreadsheet';
import { showOfferCreationSpreadsheet } from '@stock/services/show-offer-creation-spreadsheet';
import { indexOfferCreationSpreadsheet } from '@stock/services/index-offer-creation-spreadsheets';
import { publishOfferCreationSpreadsheet } from '@stock/services/publish-offer-creation-spreadsheet';
import { offerCreationBatchTracking } from '@stock/metrics/offer-creation-batch-tracking';
import { validateType } from 'vue-types';

export default {
  components: {
    GridContainer,
    GridItem,
    HeaderBox,
    OfferCreationBatchMenu,
    OfferCreationBatchSheets,
    OfferCreationBatchFullscreenSheet,
    FeedbackModal,
  },
  computed: {
    feedbackCardsConfig() {
      return {
        success: {
          title: 'Suas Ofertas foram enviadas com sucesso!',
          buttonText: 'Visualizar planilhas',
        },
        error: {
          title: 'Ops! Encontramos alguns erros na planilha',
          text: 'Por favor, corrija os erros e tente publicar as ofertas novamente.',
          buttonText: 'Ok, entendi',
        },
        loading: {
          title: 'Aguarde, estamos analisando as ofertas',
        },
      };
    },
  },
  data() {
    return {
      selectedSheet: null,
      processingStatus: null,
      feedbackModalOpened: false,
      fullScreenOpen: false,
      offerCreationSheets: [],
      toastItems: null,
      disableCreateSheetButton: false,
      editMode: null,
    };
  },
  methods: {
    _requestCreatingSheet() {
      createOfferCreationSpreadsheet()
        .then((data) => {
          showOfferCreationSpreadsheet({ id: data.offerCreationSheetId })
            .then(({ offerCreationSheet }) => {
              this.offerCreationSheets.unshift(offerCreationSheet);
            });
        })
        .catch(() => {
          this.handleCreatedSheetStatusError();
        });
    },
    _requestIndexSheets() {
      indexOfferCreationSpreadsheet()
        .then(({ offerCreationSheets }) => {
          this.offerCreationSheets = offerCreationSheets;
          validateType(SheetsModel, this.offerCreationSheets);
        });
    },
    _requestPublishSheet() {
      return publishOfferCreationSpreadsheet({ id: this.selectedSheet.id });
    },
    handleErrorClick() {
      this.closeFeedbackModal();
    },
    handleSuccessClick() {
      this.closeFeedbackModal();
      this.closeSheet();
      this._requestIndexSheets();
    },
    openFeedbackModal() {
      this.feedbackModalOpened = true;
    },
    closeFeedbackModal() {
      this.feedbackModalOpened = false;
      this.processingStatus = null;
    },
    openSheet() {
      this.fullScreenOpen = true;
    },
    closeSheetView() {
      offerCreationBatchTracking.closeSpreadsheetView();
      this.fullScreenOpen = false;
      this.selectedSheet = null;
    },
    closeAndSaveSheet() {
      offerCreationBatchTracking.savedAndClose();
      this.fullScreenOpen = false;
      this.selectedSheet = null;
    },
    handleSubmitSheetClick() {
      offerCreationBatchTracking.publishedOffers();
      this.processingStatus = 'loading';
      this.openFeedbackModal();

      this._requestPublishSheet()
        .then(() => {
          const timer = setTimeout(() => {
            this.processingStatus = 'success';
            clearInterval(timer);
          }, 4000);
        })
        .catch(() => {
          this.processingStatus = 'error';
        });
    },
    handleCreateSheetClick() {
      offerCreationBatchTracking.createdSpreadsheet();
      this._requestCreatingSheet();
      this.disableCreateSheetButton = true;
    },
    handleOpenEditSheetClick(sheet) {
      this.editMode = true;
      offerCreationBatchTracking.editedSpreadsheet();
      this.selectedSheet = sheet;
      this.openSheet();
    },
    handleOpenViewSheetClick(sheet) {
      this.editMode = false;
      offerCreationBatchTracking.openSpreadsheetView();
      this.selectedSheet = sheet;
      this.openSheet();
    },
    handleCreatedSheetStatusError() {
      this.disableCreateSheetButton = false;
      this.toastItems = [{ text: 'Ocorreu um problema ao criar a planilha', variant: 'danger', isOpen: true }];
      const timer = setInterval(() => {
        this.toastItems = null;
        clearInterval(timer);
      }, 4000);
    },
    handleCreatedSheetStatus() {
      this.disableCreateSheetButton = false;
      this.toastItems = [{ text: 'Planilha criada', variant: 'success', isOpen: true }];
      const timer = setInterval(() => {
        this.toastItems = null;
        clearInterval(timer);
      }, 4000);
    },
  },
  mounted() {
    this._requestIndexSheets();
    hj('trigger', 'opened-offer-creation-page');
    offerCreationBatchTracking.viewedThePage();
  },
};
</script>
