module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="js-enrollment-wizard-' +
((__t = ( data.followUpId )) == null ? '' : __t) +
'">\n  <div class="modal fade modal-clean" role="dialog" id="js-enrollment-wizard-' +
((__t = ( data.followUpId )) == null ? '' : __t) +
'-1">\n    <div class="modal-clean__dialog" role="document">\n      <div class="modal-clean__content">\n        <div class="modal-clean__body">\n          <div class="modal-clean__loader"></div>\n\n          <div class="modal-clean__header">Aprovar documentos e informar matrícula</div>\n\n          <div class="modal-clean__info modal-clean__info--align-left">\n            ';
 if(data.universityId === 5 || data.followUpStep == "partially_submitted_docs") { ;
__p += '\n              Ao aprovar a documentação e informar a matrícula, a ' +
((__t = ( data.universityName )) == null ? '' : __t) +
' se responsabiliza inteiramente por colher os documentos faltantes para o processo de admissão.\n            ';
 } else { ;
__p += '\n              <p>\n                Ao informar a matrícula, você confirma que todos os documentos necessários foram fornecidos pelo aluno e validados pela faculdade.\n              </p>\n\n              <p>\n                Dessa forma, o aluno será considerado matriculado.\n              </p>\n            ';
 } ;
__p += '\n          </div>\n\n          <div class="modal-clean__actions">\n            <div class="row">\n              <div class="col-sm-6">\n                <z-button\n                  type="button"\n                  id="js-enrollment-cancel-button"\n                  variant="secondary"\n                  behavior="block"\n                >\n                  Cancelar\n                </z-button>\n              </div>\n\n              <div class="col-sm-6">\n                <z-button\n                  behavior="block"\n                  type="button"\n                  id="js-approve-docs-button"\n                  data-enrollment-wizard="true"\n                  data-follow-up-id="' +
((__t = ( data.followUpId )) == null ? '' : __t) +
'"\n                  data-university-name="' +
((__t = ( data.universityName )) == null ? '' : __t) +
'"\n                  data-university-id="' +
((__t = ( data.universityId )) == null ? '' : __t) +
'"\n                >\n                  Aprovar documentos\n                </z-button>\n              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n\n  <div class="modal fade modal-clean" role="dialog" id="js-enrollment-wizard-' +
((__t = ( data.followUpId )) == null ? '' : __t) +
'-2">\n    <div class="modal-clean__dialog" role="document">\n      <div class="modal-clean__content">\n        <div class="modal-clean__body">\n          <div class="modal-clean__loader"></div>\n\n          <form method="post" action="/busca-pre-matriculas/' +
((__t = ( data.followUpId )) == null ? '' : __t) +
'/enrollment" data-remote="true" class="js-enroll-form">\n            <div class="modal-clean__header">Já existe turma formada para o início das aulas?</div>\n\n            <div class="modal-clean__actions">\n              <div class="row">\n                <div class="col-sm-6">\n                  <z-button\n                    type="button"\n                    id="js-await-enrollment-button"\n                    variant="secondary"\n                    behavior="block"\n                  >\n                    Não, aguardando formação\n                  </z-button>\n                </div>\n\n                <div class="col-sm-6">\n                  <z-button\n                    type="submit"\n                    id="js-enroll-button"\n                    behavior="block"\n                  >\n                    Sim, matricule o aluno\n                  </z-button>\n                </div>\n              </div>\n            </div>\n          </form>\n        </div>\n      </div>\n    </div>\n  </div>\n\n  <div class="modal fade modal-clean" role="dialog" id="js-enrollment-wizard-' +
((__t = ( data.followUpId )) == null ? '' : __t) +
'-3">\n    <div class="modal-clean__dialog" role="document">\n      <div class="modal-clean__content">\n        <div class="modal-clean__body">\n          <div class="modal-clean__loader"></div>\n\n          <form action="/busca-pre-matriculas/' +
((__t = ( data.followUpId )) == null ? '' : __t) +
'/await_enrollment" method="post" data-remote="true" class="js-awaiting-enrollment-form">\n            <div class="modal-clean__header">Previsão de dias para formação de turma</div>\n\n            <div class="enrollment-wizard__input-wrapper">\n              <select name="days_to_enroll">\n                ';
 for (var i = 1; i <= 100; i++) { ;
__p += '\n                  <option value="' +
((__t = ( i )) == null ? '' : __t) +
'">' +
((__t = ( i )) == null ? '' : __t) +
'</option>\n                ';
 } ;
__p += '\n              </select>\n\n              <div class="enrollment-wizard__days-text">dia(s).</div>\n            </div>\n\n            <div class="modal-clean__actions">\n              <z-button\n                type="submit"\n                behavior="block"\n              >\n                Matricular, aguardando formação de turma\n              </z-button>\n            </div>\n          </form>\n        </div>\n      </div>\n    </div>\n  </div>\n\n  <div class="modal fade modal-clean" role="dialog" id="js-enrollment-wizard-' +
((__t = ( data.followUpId )) == null ? '' : __t) +
'-4-success">\n    <div class="modal-clean__dialog" role="document">\n      <div class="modal-clean__content">\n        <div class="modal-clean__body">\n          <div class="modal-clean__info">\n            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="modal-clean__info-icon"><title>i-success-filled-reverse</title><g fill="none" fill-rule="evenodd"><circle fill="#198038" cx="11.867" cy="11.867" r="11.867"/><path d="M10.337 12.755l-1.984-1.984a1.39 1.39 0 0 0-1.966 0l-.065.066a1.39 1.39 0 0 0 0 1.966l2.485 2.486.547.547a1.39 1.39 0 0 0 1.966 0l6.026-6.026c.58-.58.58-1.519 0-2.098l.066.066a1.483 1.483 0 0 0-2.098 0l-4.977 4.977z" fill="#FFF" fill-rule="nonzero"/></g></svg>\n\n            <div class="modal-clean_info-message">Informação enviada com sucesso!</div>\n          </div>\n\n          <div class="modal-clean__actions">\n            <z-button\n              id="js-enrollment-wizard-success-' +
((__t = ( data.followUpId )) == null ? '' : __t) +
'"\n              type="button"\n              variant="secondary"\n              behavior="block"\n            >\n              Voltar\n            </z-button>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n\n  <div class="modal fade modal-clean" role="dialog" id="js-enrollment-wizard-' +
((__t = ( data.followUpId )) == null ? '' : __t) +
'-4-fail">\n    <div class="modal-clean__dialog" role="document">\n      <div class="modal-clean__content">\n        <div class="modal-clean__body">\n          <div class="modal-clean__info">\n            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="modal-clean__info-icon"><title>i-erro-filled-reverse</title><g fill="none" fill-rule="evenodd"><circle fill="#DA1E28" cx="11.867" cy="11.867" r="11.867"/><path d="M10.337 12.755l-1.984-1.984a1.39 1.39 0 0 0-1.966 0l-.065.066a1.39 1.39 0 0 0 0 1.966l2.485 2.486.547.547a1.39 1.39 0 0 0 1.966 0l6.026-6.026c.58-.58.58-1.519 0-2.098l.066.066a1.483 1.483 0 0 0-2.098 0l-4.977 4.977z" fill="#FFF" fill-rule="nonzero"/></g></svg>\n\n            <div class="modal-clean_info-message">Erro ao enviar informação!</div>\n          </div>\n\n          <div class="modal-clean__actions">\n            <z-button\n              id="js-enrollment-wizard-fail-' +
((__t = ( data.followUpId )) == null ? '' : __t) +
'"\n              type="button"\n              variant="secondary"\n              behavior="block"\n            >\n              Voltar\n            </z-button>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n';

}
return __p
}