function _normalizeEmail() {
  const $email = $('.js-form-email');
  const newValue = $email.val().toLocaleLowerCase().trim();
  $email.val(newValue);
}

function ResetPasswordNew() {
  $('.js-reset-password-form').on('submit', _normalizeEmail);
}

export { ResetPasswordNew };
