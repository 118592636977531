import { Stepper } from '@stock/legacy/components/stepper';
import { ResponseStep } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/shared-steps/response';
import { ConfirmationStep } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/disablement/steps/confirmation';

export class DisablementStepper extends Stepper {
  constructor({ offer, universityOfferId }) {
    super();
    this.offer = offer;
    this.universityOfferId = universityOfferId;
    this._validate();
    this._setup();
  }

  _validate() {
    if (!this.offer) throw Error('offer is a required parameter');
  }

  createSteps() {
    return [
      this._createConfirmationStep(),
      this._createResponseStep(),
    ];
  }

  _setup() {
    return this.createSteps().forEach(step => this.addStep(step));
  }

  _createConfirmationStep() {
    const confirmationStep = new ConfirmationStep({
      offer: this.offer,
      universityOfferId: this.universityOfferId,
    });
    confirmationStep.onHook('next', () => this.next());
    this._setupFirstStep(confirmationStep);

    return confirmationStep;
  }

  _createResponseStep() { return new ResponseStep(); }

  _setupFirstStep(step) {
    step.onHook('previous', () => {
      if (step.previous) {
        return this.previous();
      }
      return this.event.emit('finished');
    });
    return step;
  }
}
