<template>
  <z-card class="operation-logs-table">
    <div class="operation-logs-table__item">
      <div class="operation-logs-table__fragment">
        <z-title class="operation-logs-table__fragment-header">
          Ação
        </z-title>
      </div>

      <div class="operation-logs-table__fragment">
        <z-title class="operation-logs-table__fragment-header">
          Status
        </z-title>
      </div>

      <div class="operation-logs-table__fragment">
        <z-title class="operation-logs-table__fragment-header">
          Usuário
        </z-title>
      </div>

      <div class="operation-logs-table__fragment">
        <z-title class="operation-logs-table__fragment-header">
          Data da Operação
        </z-title>
      </div>

      <div class="operation-logs-table__fragment">
        <z-title class="operation-logs-table__fragment-header">
          Detalhes da Operação
        </z-title>
      </div>
    </div>

    <div
      class="operation-logs-table__item"
      v-for="(item, index) in items"
      :key="index"
    >
      <div class="operation-logs-table__fragment">
        <z-text>
          {{ item.action }}
        </z-text>
      </div>

      <div class="operation-logs-table__fragment">
        <z-text
          :class="{
            'operation-logs-table__fragment-status-danger': item.status === 'Concluído Com Erros',
          }"
        >
          {{ item.status }}
        </z-text>
      </div>

      <div class="operation-logs-table__fragment">
        <z-text>
          {{ item.university_user_name }}
        </z-text>
      </div>

      <div class="operation-logs-table__fragment">
        <z-text>
          {{ item.created_at }}
        </z-text>
      </div>

      <div class="operation-logs-table__fragment">
        <ZButton
          @click="operationDetails(item.uuid)"
          class="button"
        >
          ver detalhes
        </ZButton>
      </div>
    </div>

    <div
      v-if="!items.length"
      class="operation-logs-table__item"
    >
      <div class="operation-logs-table__fragment">
        <z-text align="center">
          Nenhuma operação encontrada. <z-icon :icon="['fas', 'exclamation-circle']" />
        </z-text>
      </div>
    </div>
  </z-card>
</template>

<script>
import { ZButton } from '@quero/zilla-vue';

export default {
  name: 'OperationLogTable',
  components: {
    ZButton,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    operationDetails(uuid) {
      this.$emit('open-modal-operations-details', uuid);
    },
  },
};
</script>

<style>
.operation-logs-table__item {
  align-items: center;
  border-bottom: var(--size-border-width-small) solid var(--color-neutral-lighter-gray);
  display: flex;
  margin-left: calc(var(--space-large) * -1);
  margin-right: calc(var(--space-large) * -1);
  padding: var(--space-large);
}

.operation-logs-table__item--centered {
  display: flex;
  justify-content: center;
}

.operation-logs-table__item-icon {
  margin-right: var(--space-medium);
}

.operation-logs-table__fragment-header {
  font-weight: var(--font-weight-medium);
}

.operation-logs-table__fragment {
  flex: 1;
}

.operation-logs-table__fragment-status-danger {
  font-weight: var(--font-weight-medium);
  color: red;
}
</style>
