<template>
  <div class="campus-create-inline">
    <z-modal
      :open="open"
      @closed="closed"
      class="campus-create-modal"
      aligm="bottom"
    >
      <div class="campus-import__modal__header">
        <div class="campus-import__modal__header__title">
          <z-title color="minor">
            <z-icon
              :icon="['far', 'file-alt']"
              size="large"
            />
            Criação de Campus
          </z-title>
        </div>
      </div>

      <div
        v-if="step === 1"
        class="campus-creation__content"
      >
        <z-card class="z-card">
          <div class="cc-selection">
            <form action="#">
              <section class="cc-selection__section">
                <z-input-field label="Nome">
                  <z-tooltip
                    tag="span"
                    position="bottom-center"
                  >
                    <template #default>
                      (?)
                    </template>
                    <template #content>
                      Nome original do campus
                    </template>
                  </z-tooltip>
                  <input
                    type="text"
                    v-model="nameFromUniversity"
                    class="form-control validate"
                    placeholder="Nome do Polo"
                  >
                </z-input-field>

                <z-input-field label="Nome de Exibição">
                  <z-tooltip
                    tag="span"
                    position="bottom-center"
                  >
                    <template #default>
                      (?)
                    </template>
                    <template #content>
                      Nome que será exibido no Quero Bolsa
                    </template>
                  </z-tooltip>
                  <input
                    type="text"
                    v-model="name"
                    class="form-control validate"
                    placeholder="Nome do Polo para exibição"
                  >
                </z-input-field>

                <div class="section_form_checkbox">
                  <input
                    data-testid="checkbox-virtual"
                    type="checkbox"
                    v-model="isVirtual"
                    @change="onChangeIsVirtualInput"
                  >
                  <z-text>
                    Campus Virtual
                  </z-text>
                </div>

                <z-input-field label="CEP">
                  <input
                    type="text"
                    id="zipcode"
                    v-model="zipcode"
                    class="form-control validate"
                    ref="refCep"
                    placeholder="00000-000"
                    @blur="onBlurInputZipcode"
                    :disabled="isVirtual === true"
                  >
                  <z-text
                    class="color-text"
                  >
                    {{ this.errorCep }}
                  </z-text>
                </z-input-field>

                <z-input-field label="Endereço">
                  <input
                    v-model="address"
                    ref="address"
                    placeholder="Rua/Avenida"
                    class="form-control validate"
                    :disabled="shouldDisableAddressFields"
                  >
                </z-input-field>

                <div class="section_form_checkbox">
                  <input
                    data-testid="checkbox-AdressNumber"
                    type="checkbox"
                    v-model="isAddressWithoutNumber"
                  >
                  <z-text>Endereço sem número</z-text>
                </div>

                <div class="section_control_double">
                  <z-input-field label="Número">
                    <input
                      type="number"
                      v-model="addressNumber"
                      class="form-control validate"
                      data-testid="address-number"
                      :disabled="
                        isVirtual === true || isAddressWithoutNumber === true
                      "
                    >
                  </z-input-field>
                  <z-input-field label="Complemento">
                    <input
                      v-model="addressAdjunct"
                      data-testid="address-adjunct"
                      ref="addressAdjunct"
                      class="form-control validate"
                      :disabled="isVirtual === true"
                    >
                  </z-input-field>
                </div>

                <z-input-field label="Bairro">
                  <input
                    type="neighborhood"
                    v-model="neighborhood"
                    placeholder="bairro"
                    class="form-control validate"
                    :disabled="shouldDisableAddressFields"
                  >
                </z-input-field>

                <div class="section_control_double">
                  <z-input-field label="Estado">
                    <input
                      v-model="state"
                      class="form-control validate"
                      placeholder="SP"
                      :disabled="shouldDisableAddressFields"
                    >
                  </z-input-field>
                  <z-input-field label="Cidade">
                    <input
                      v-model="city"
                      class="form-control validate"
                      placeholder="São José dos Campos"
                      :disabled="shouldDisableAddressFields"
                    >
                  </z-input-field>
                </div>

                <z-input-field label="Telefone">
                  <input
                    type="phone"
                    v-model="phone"
                    placeholder="(00) 0000-0000"
                    class="form-control validate"
                    :disabled="isVirtual === true"
                  >
                </z-input-field>

                <z-input-field label="Multi brand (Polos Quero)">
                  <input
                    v-model="multiBrandCampus"
                    class="form-control validate"
                    placeholder="sao paulo"
                    :disabled="isVirtual === true"
                  >
                </z-input-field>

                <div class="section_control_double">
                  <z-input-field label="Metadata Code">
                    <input
                      v-model="metadataCode"
                      class="form-control validate"
                      placeholder="code:00"
                    >
                  </z-input-field>
                  <z-input-field label="Metadata Regional">
                    <input
                      v-model="metadataRegional"
                      class="form-control validate"
                      placeholder="code:SP"
                    >
                  </z-input-field>
                </div>
              </section>
            </form>
            <footer class="cc-selection__footer">
              <z-button
                @click="sendToCreate"
                :is-disabled="isSaveDisabled"
                :is-loading="loading"
              >
                Salvar
              </z-button>
            </footer>
          </div>
        </z-card>
      </div>

      <div
        v-if="step === 2"
        class="campus-creation__content"
      >
        <div class="campus-creation__feedback-error">
          <z-card class="z-card">
            <div class="feedback-card-error">
              <z-icon
                :icon="['fad', 'times-circle']"
                title="error"
                size="giant"
                color="danger"
                class="feedback-card-error__icon"
              />
              <div class="feedback-card-error__content">
                <z-text
                  class="feedback-card-error__text"
                  size="medium"
                  :key="index"
                  v-for="(failureItem, index) in failureMessages"
                >
                  {{ failureItem }}
                </z-text>
              </div>
            </div>
          </z-card>
          <div class="feedback-card-error__section">
            <z-button
              class="feedback-card-error__button"
              @click="goBackStep"
              variant="secondary"
            >
              Voltar
            </z-button>
            <z-button
              class="feedback-card-error__button"
              @click="closed"
              variant="secondary"
            >
              Fechar
            </z-button>
          </div>
        </div>
      </div>

      <div
        v-if="step === 3"
        class="campus-creation__content"
      >
        <div class="campus-creation__feedback-sucess">
          <z-card class="z-card">
            <div class="feedback-card-sucess">
              <z-icon
                :icon="['fas', 'check-circle']"
                title="Operação Finalizada"
                size="giant"
                color="success"
                class="feedback-card-sucess__icon"
              />
              <div class="feedback-card-sucess__content">
                <z-text class="feedback-card-sucess__text">
                  {{ this.successMessage }}
                </z-text>
              </div>
            </div>
          </z-card>
          <div class="feedback-card-sucess__section">
            <z-button
              class="feedback-card-sucess__button"
              @click="closed"
            >
              Fechar
            </z-button>
          </div>
        </div>
      </div>
    </z-modal>
  </div>
</template>

<script>
import { notifierMixin } from '@stock/utils/mixins/notifier';
import { queroAlunosAPI } from '@stock/api/quero-alunos-api';
import { getAddressByCep } from '@geolocator/api/geolocator-api';
import { ZTooltip } from '@quero/zilla-vue';

export default {
  name: 'CampusCreationModalInline',
  mixins: [notifierMixin],
  components: {
    ZTooltip,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      name: null,
      nameFromUniversity: null,
      address: null,
      addressNumber: null,
      addressAdjunct: null,
      neighborhood: null,
      city: null,
      state: null,
      zipcode: '',
      phone: null,
      metadataCode: null,
      metadataRegional: null,
      multiBrandCampus: null,
      isVirtual: false,
      isAddressWithoutNumber: false,
      shouldDisableAddressFields: true,
      step: 1,
      feedbackError: null,
      errorCep: null,
      successMessage: null,
      failureMessages: null,
      loading: false,
    };
  },

  computed: {
    hasName() {
      return Boolean(this.name) && this.name.length > 3;
    },
    hasNameFromUniversity() {
      return Boolean(this.nameFromUniversity) && this.nameFromUniversity.length > 3;
    },
    hasAddress() {
      return Boolean(this.address) && this.address.length > 3;
    },
    hasNeighborhood() {
      return Boolean(this.neighborhood) && this.neighborhood.length > 3;
    },
    hasZipcode() {
      return Boolean(this.zipcode) && this.zipcode.length > 8;
    },
    hasState() {
      return Boolean(this.state);
    },
    hasCity() {
      return Boolean(this.city);
    },
    hasAddressNumber() {
      return Boolean(this.addressNumber);
    },
    isSaveDisabled() {
      if (this.isVirtual) {
        return Boolean(!(this.hasName && this.hasNameFromUniversity));
      }

      if (this.isAddressWithoutNumber === true) {
        return Boolean(
          !(
            this.hasName
            && this.hasNameFromUniversity
            && this.hasAddress
            && this.hasNeighborhood
            && this.hasZipcode
            && this.hasState
            && this.hasCity
          ),
        );
      }

      return Boolean(
        !(
          this.hasName
          && this.hasNameFromUniversity
          && this.hasAddress
          && this.hasNeighborhood
          && this.hasZipcode
          && this.hasState
          && this.hasCity
          && this.hasAddressNumber
        ),
      );
    },
  },

  methods: {
    goBackStep() {
      this.step -= 1;
    },

    goToFailureStep() {
      this.step = 2;
    },

    goToSuccessStep() {
      this.step = 3;
    },

    closed() {
      this.$emit('closed');
      this.clearForms();
    },

    onChangeIsVirtualInput(event) {
      const isChecked = event.target.checked;
      const shouldClearAddressInputs = isChecked;

      if (!shouldClearAddressInputs) return;

      this.clearAddress();
      this.multiBrandCampus = null;
      this.phone = null;
      this.zipcode = null;
    },

    autocompleteCep() {
      const isZipcodeEmpty = this.zipcode === '';

      if (isZipcodeEmpty) {
        this.clearAddress();
        this.errorCep = '';
        return;
      }

      getAddressByCep(this.zipcode)
        .then((response) => {
          switch (response) {
            case 'ZERO_RESULTS':
              this.clearForms();
              this.errorCep = 'Nenhum endereço encontrado para o CEP inserido';
              return;
            case 'Incorrect zip code format':
              this.clearForms();
              this.errorCep = 'CEP inserido inválido';
              return;
            default:
              break;
          }

          const {
            street, city, state, neigborhood,
          } = response;

          this.address = street;
          this.neighborhood = neigborhood;
          this.city = city;
          this.state = state;

          const hasStreet = Boolean(street);
          const hasNeighborhood = Boolean(neigborhood);
          const hasCity = Boolean(city);
          const hasState = Boolean(state);

          this.shouldDisableAddressFields = (hasStreet && hasNeighborhood && hasCity && hasState);

          this.errorCep = '';
        })
        .catch(() => {
          this.errorCep = 'Erro ao consultar endereço via CEP';
        });
    },

    onBlurInputZipcode() {
      this.autocompleteCep();
    },

    clearForms() {
      this.address = null;
      this.addressNumber = null;
      this.name = null;
      this.metadataCode = null;
      this.metadataRegional = null;
      this.phone = null;
      this.neighborhood = null;
      this.city = null;
      this.state = null;
      this.zipcode = '';
      this.multiBrandCampus = null;
      this.shouldDisableAddressFields = true;
      this.step = 1;
      this.errorCep = null;
      this.addressAdjunct = null;
      this.nameFromUniversity = null;
    },

    clearAddress() {
      this.address = null;
      this.neighborhood = null;
      this.city = null;
      this.state = null;
      this.addressNumber = null;
      this.shouldDisableAddressFields = true;
      this.errorCep = null;
      this.addressAdjunct = null;
    },

    sendToCreate() {
      this.loading = true;
      queroAlunosAPI
        .campusCreationInline(this.$data)
        .then(() => {
          this.handleSuccessfullyRequest();
        })
        .catch((error) => {
          this.handleRequestFailure(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleSuccessfullyRequest() {
      this.successMessage = 'Operação enviada com sucesso. Verifique-a no registro de atividades.';
      this.goToSuccessStep();
    },

    handleRequestFailure(error) {
      const defaultErrorMessage = 'Ocorreu um erro inesperado, contate o responsável.';

      if (
        error.response
        && error.response.data
        && error.response.data.messages
      ) {
        this.failureMessages = error.response.data.messages;
      } else {
        this.failureMessages = [defaultErrorMessage];
      }

      this.goToFailureStep();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@quero/zilla-core/src/utils/variables.scss";

.color-text {
  color: red;
}

.campus-create-inline {
  display: flex;
}

.campus-create-modal {
  flex-direction: column;
  padding-top: 80px;
}

.campus-import__modal__header {
  flex-direction: column;
  margin-bottom: var(--space-large);
}

.campus-import__modal__header__title {
  vertical-align: middle;
  display: flex;
}

.campus-creation__content {
  display: flex;

  &>.z-card {
    width: 500px;
  }
}

.cc-selection {
  margin-bottom: var(--space-small);
}

.cc-selection__section {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.cc-selection__footer {
  display: flex;
  justify-content: flex-end;
}

.campus-creation__feedback-error {
  display: flex;
  flex-direction: column;
}

.feedback-card-error {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feedback-card-error__icon {
  margin-bottom: var(--space-large);
  display: flex;
}

.feedback-card-error__text {
  display: flex;
  margin-bottom: var(--space-medium);
}

.feedback-card-error__section {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.feedback-card-error__button {
  margin-bottom: 10px;
}

.campus-creation__feedback-sucess {
  display: flex;
  flex-direction: column;
}

.feedback-card-sucess {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feedback-card-sucess__icon {
  margin-bottom: var(--space-large);
}

.feedback-card-sucess__text {
  width: 100%;
  margin-bottom: var(--space-medium);

  &:last-child {
    margin-bottom: var(--space-extra-large);
  }
}

.feedback-card-sucess__section {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.feedback-card-sucess__button {
  margin-bottom: 10px;
}

.section_control_double {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section_form_checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &>.z-text {
    margin: auto;
    margin-left: 5px;
    margin-top: 5px;
  }
}
</style>
