import $ from 'jquery';
import '@shared/utils/select2';

class CpfHashPage {
  constructor(page) {
    this.page = page;
    this.initRelationSelect();
    this.initLevelsSelect();
    this.initKindsSelect();
    this.initButton();
    this.initCpfActionToggle();
  }

  initRelationSelect() {
    this.updateIesSelect();
    this.$('.js-sheet-mode-input').on('change', (event) => {
      this.updateIesSelect(event.target.value);
    });
  }

  updateIesSelect(value) {
    const target = this.selectIesInfoByValue(value);
    const $select = $(target.selector).select2(this.extendSelectOptionsDefault({
      ajax: {
        url: `/util/autocomplete/${target.path}`,
        delay: 250,
      },
      minimumInputLength: 3,
    }));
    $('.js-toggle').find('select').prop('disabled', true);
    $('.js-toggle').addClass('hide');
    $select.prop('disabled', false);
    $select.parent('.js-toggle').removeClass('hide');
  }

  selectIesInfoByValue(value) {
    const educationGroup = {
      path: 'education_groups',
      selector: '.education-group-select',
    };
    const university = {
      path: 'universities',
      selector: '.university-select',
    };
    return value === 'education-group' ? educationGroup : university;
  }

  initLevelsSelect() {
    const $select = this.$('.js-level-select');
    $select.select2(this.extendSelectOptionsDefault({
      placeholder: 'Nível do curso',
    }));
  }

  initKindsSelect() {
    const $select = this.$('.js-kind-select');
    $select.select2(this.extendSelectOptionsDefault({
      placeholder: 'Modalidade do curso',
    }));
  }

  initButton() {
    this.$('.js-btn-file').on('change', ':file', () => {
      this.$('form').submit();
    });
  }

  initCpfActionToggle() {
    this.$fade = $('.js-fade');
    this.$fadeFormGroups = $('.js-fade .js-fade-group');
    this.updateFormFields('unlock');

    this.$('.js-cpf-action-input').on('change', (event) => {
      this.updateFormFields(event.target.value);
    });
  }

  updateFormFields(value) {
    this.$fade.fadeOut(() => {
      if (value === 'block') {
        this.$fadeFormGroups.removeClass('hidden');
      } else {
        this.$fadeFormGroups.addClass('hidden');
      }
      this.$fade.fadeIn();
    });
  }

  extendSelectOptionsDefault(options) {
    return Object.assign({
      theme: 'bootstrap',
      language: 'pt-BR',
    }, options);
  }

  $(selector) {
    return $(selector, this.page);
  }
}

export { CpfHashPage };
