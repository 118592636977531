import { UniversityPanelSharedAPI } from '@shared/api/university-panel-shared-api';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Search city by specific term
 *
 * @param {Object} params
 * @property {number} params.term The term to be searched
 *
 * @typedef Success
 * @property {string} message
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function searchCity(term) {
  return UniversityPanelSharedAPI.Autocompletes.searchCity({ term })
    .then(({ data }) => {
      const results = data.results.map(city => ({ ...city, text: `${city.text} - ${city.hint}` }));
      return { results };
    })
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[searchCity] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

export {
  searchCity,
};
