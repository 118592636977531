import template from '@university-panel/legacy/components/templates/show-documentation-modal.html.ejs';
import BaseModal from '@shared/legacy/components/modals/base-modal';

export default class ShowDocumentationModal extends BaseModal {
  constructor({ docPreviewUrl, docDownloadUrl } = {}) {
    super({ docPreviewUrl, docDownloadUrl }, template);
  }

  onRequest(event, callback) {
    this.request.on(event, callback);
    return this;
  }

  onSubmit(evt) {
    super.onSubmit();
    evt.preventDefault();
    this.request.postJson({
      restock: this.offers,
      university_id: this.universityId,
    });
  }
}
