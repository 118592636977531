import { routes } from '@university-panel/utils/routes';
import axios from 'axios';

export async function findOfferByUuid({ uuid }) {
  const normalizeOfferResponse = offer => ({
    id: offer.id,
    courseName: offer.course,
    campus: offer.campus,
    level: offer.level,
    kind: offer.kind,
    shift: offer.shift,
    enrollmentSemester: offer.enrollment_semester,
    discount: offer.discount_percentage,
    fullPrice: offer.full_price,
    offeredPrice: offer.offered_price,
    currentTotalSeats: offer.seats_balance.virtual_total_seats,
    currentAvailableSeats: offer.seats_balance.virtual_seats,
    sharedSeats: offer.seats_balance.shared_seats,
    limited: offer.seats_balance.limited,
    regressive_discount: offer.regressive_discount,
  });

  const response = await axios.get(routes.offerDetailPath(uuid));
  return normalizeOfferResponse(response.data);
}
