import { LocalStorageAPI } from '@shared/utils/local-storage';

const store = LocalStorageAPI.getInstance();
const NAMESPACE = 'queroalunos_stock';

function set(property, value, timeout = null) {
  return store.set(property, value, NAMESPACE, timeout);
}

function get(property) {
  return store.get(property, NAMESPACE);
}

function remove(property) {
  return store.remove(property, NAMESPACE);
}

function oneTimeMessage(property) {
  if (!store.get(property, NAMESPACE)) {
    store.set(property, true, NAMESPACE);
    return false;
  }
  return true;
}

const LocalStorageStock = {
  set,
  get,
  oneTimeMessage,
  remove,
};

export { LocalStorageStock };
