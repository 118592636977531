import {
  Step01IdentifyConfig,
  Step02PaymentModel,
  Step03PaymentTrigger,
  Step04CommissionType,
} from './steps';

const StepsNavigationSetup = {
  step01: {
    component: Step01IdentifyConfig,
    position: 1,
    nextStepName: 'step02',
    title: 'Identifique a configuração',
  },
  step02: {
    component: Step02PaymentModel,
    position: 2,
    prevStepName: 'step01',
    nextStepName: 'step03',
    title: 'Qual o forma de pagamento?',
    sidebar: true,
  },
  step03: {
    component: Step03PaymentTrigger,
    position: 3,
    prevStepName: 'step02',
    nextStepName: 'step04',
    title: 'Por qual aluno vamos cobrar?',
    sidebar: true,
  },
  step04: {
    component: Step04CommissionType,
    position: 4,
    prevStepName: 'step03',
    title: 'Qual o tipo e valor da comissão?',
    subtitle: 'Defina o valor de comissionamento recebido pela Quero. Quanto maior a comissão, melhores posicionadas ficam as ofertas.',
    sidebar: true,
  },
};

export { StepsNavigationSetup };
