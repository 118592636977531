export function initializeCookieConsent() {
  skipCookieCreationForTestEnvironment();
}

function skipCookieCreationForTestEnvironment() {
  const shouldSetCookie = process.env.SHOULD_SET_COOKIE_CONSENT === 'true';

  if (!shouldSetCookie) return;

  createCss();
  createScript();
  createWindowConfig();
}

function getCookieDomain() {
  return process.env.COOKIE_CONSENT_DOMAIN;
}

function createCss() {
  const cookieDomain = getCookieDomain();
  const style = document.createElement('link');

  style.setAttribute('rel', 'stylesheet');
  style.setAttribute('href', `${cookieDomain}/cookie-consent.css`);

  const head = document.querySelector('head');
  head.appendChild(style);
}

function createScript() {
  const cookieDomain = getCookieDomain();
  const script = document.createElement('script');

  script.setAttribute('defer', true);
  script.setAttribute('async', true);
  script.setAttribute('src', `${cookieDomain}/cookie-consent.cjs.js`);

  const head = document.querySelector('head');
  head.appendChild(script);
}

function createWindowConfig() {
  const systemCode = process.env.GAMBIT_SYSTEM_CODE;

  window.CookieConsentConfig = {
    systemCode,
    trackable: true,
    primaryColor: '#304FFE',
    customClassNames: {
      button: 'z-button z-button--primary',
    },
  };
}
