import build from '@shared/legacy/components/zilla/z-input-field/build';
import validate from '@shared/legacy/components/zilla/z-input-field/validate';
import { OPTIONS } from '@shared/legacy/components/zilla/z-input-field/options';

export default class ZInputField {
  constructor(identifier, options) {
    validate(identifier, options);
    this.$target = build(identifier, options);
    this.$input = this.$target.find('.js-input');
    this.$inputField = this.$target.find('.js-input-field');
    this.$errorMessage = this.$target.find('.js-error-message');
    if (this.hasError() || options.errorText) {
      this.showError(options.errorText);
    }
  }

  showError(message = 'Valor inválido') {
    this.$errorMessage.text(message);
    this.$input.addClass(OPTIONS.state.error);
    this.$inputField.focus();
  }

  removeError() {
    this.$errorMessage.empty();
    this.$input.removeClass(OPTIONS.state.error);
  }

  reset() {
    this.removeError();
    this.value = null;
  }

  isEmpty() {
    return this.value === '';
  }

  hasError() {
    return this.$input.hasClass(OPTIONS.state.error);
  }

  on(event, callback) {
    this.$inputField.on(event, callback);
    return this;
  }

  get value() {
    return this.$inputField.val();
  }

  set value(value) {
    return this.$inputField.val(value);
  }
}
