import { UniversityPanelSharedAPI } from '@shared/api/university-panel-shared-api';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Get stock operations by filters
 *
 * @param {Object} params
 * @property {Object} params Filter to be search
 *
 * @typedef Success
 * @property {string} message
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function getStockOperations(params) {
  return UniversityPanelSharedAPI.getStockOperations(params)
    .then(({ data }) => data)
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[getStockOperations] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

async function getStockOperationDetails(uuid) {
  return UniversityPanelSharedAPI.getStockOperationDetails(uuid)
    .then(({ data }) => data)
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[getStockOperationsDetails] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

async function getStockOperationErrorDetails(uuid, cursor) {
  return UniversityPanelSharedAPI.getStockOperationErrorDetails(uuid, cursor)
    .then(({ data }) => data)
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[getStockOperationErrorDetails] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

async function downloadStockOperationErrors(uuid, totalErrors) {
  return UniversityPanelSharedAPI.downloadStockOperationErrors(uuid, totalErrors)
    .then((response) => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.setAttribute('type', 'hidden');
      link.setAttribute('download', 'campus-errors.xlsx');
      link.setAttribute('href', url);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[downloadStockOperationErrors] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

export {
  getStockOperations,
  getStockOperationDetails,
  getStockOperationErrorDetails,
  downloadStockOperationErrors,
};
