const shiftMap = {
  morning: {
    label: 'Manhã',
    icon: 'sunrise',
  },
  afternoon: {
    label: 'Tarde',
    icon: 'sun',
  },
  night: {
    label: 'Noite',
    icon: 'moon-stars',
  },
  fullTime: {
    label: 'Integral',
    icon: 'eclipse',
  },
  virtual: {
    label: 'Virtual',
    icon: 'globe-americas',
  },
};

const shiftTranslate = shiftKey => shiftMap[shiftKey].label;
const shiftIcon = shiftKey => shiftMap[shiftKey].icon;

export const shiftRules = {
  shiftIcon,
  shiftTranslate,
};
