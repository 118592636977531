
/**
  * @desc allows you to fetch elements in the DOM at a defined scope
  * @param {String} scopeSelector - jQuery selector that represents your scope
  * @return {Function} JQuery element within that scope
*/
export function scope(scopeSelector) {
  return (selector) => {
    if (selector === scopeSelector) return $(scopeSelector);
    return $(selector, scopeSelector);
  };
}
