<template>
  <z-card>
    <z-title
      class="show-brg-with-data-bennefits__title"
    >
      Resumo de benefícios
    </z-title>

    <div class="show-brg-with-data-bennefits__list">
      <z-tooltip
        v-if="hasCommission"
        position="right-center"
      >
        <template>
          <z-icon
            :icon="['fas', 'rocket']"
            color="primary"
          />
          QB Turbo
        </template>

        <template #content>
          Essa configuração possuí o Quero Turbo para acelerar a captação de alunos.
        </template>
      </z-tooltip>

      <z-tooltip
        v-if="hasBilling"
        position="right-center"
      >
        <template>
          <z-icon
            :icon="['fas', 'file-invoice-dollar']"
            color="primary"
          />
          QueroPago
        </template>

        <template #content>
          Essa configuração possuí o Quero Pago para gerenciar o financiamento da captação.
        </template>
      </z-tooltip>

      <z-tooltip
        v-if="hasAdmission"
        position="right-center"
      >
        <template>
          <z-icon
            :icon="['fas', 'user-plus']"
            color="primary"
          />
          Admissão digital
        </template>

        <template #content>
          Essa configuração possuí a Admissão Digital para acelerar a imersão de alunos na captação.
        </template>
      </z-tooltip>
    </div>
  </z-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('ShowBRGPageModule', [
      'offerFiltersItems',
      'hasCommission',
      'hasAdmission',
      'hasBilling',
    ]),
  },
};
</script>


<style>
.show-brg-with-data-bennefits__title {
  display: inline-flex;
  margin-bottom: var(--space-medium);
}

.show-brg-with-data-bennefits__list {
  display: flex;
  flex-direction: column;
}

.z-tooltip__box {
  z-index: 100;
}
</style>
