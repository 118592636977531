import Vue from 'vue';
import Vuex from 'vuex';
import {
  QueroTurboActivationModalModule,
} from './quero-turbo-activation-modal-module';
import { CreateAdmissionConfigModalModule } from './create-admission-config-modal-module';
import { CreateBillingConfigModalModule } from './create-billing-config-modal-module';
import { ShowBRGPageModule } from './show-brg-page-module';

Vue.use(Vuex);

const modules = {
  QueroTurboActivationModalModule,
  CreateAdmissionConfigModalModule,
  CreateBillingConfigModalModule,
  ShowBRGPageModule,
};

const getDefaultStore = () => ({
  modules,
  strict: process.env.NODE_ENV !== 'production',
});

const store = new Vuex.Store(getDefaultStore());

export { store, modules, getDefaultStore };
