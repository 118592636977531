var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'switch-button': true,
    'switch-button--checked': _vm.checked,
    'switch-button--disabled': _vm.disabled,
  }},[(_vm.type === 'checkbox')?_c('z-checkbox',{class:{
      'switch-button__label': true,
      'switch-button__label--disabled': _vm.disabled,
    },attrs:{"value":_vm.value,"checked":_vm.checked,"disabled":_vm.disabled},on:{"change":_vm.handlerComponentChanged}},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.type === 'radio')?_c('z-radio',{class:{
      'switch-button__label': true,
      'switch-button__label--disabled': _vm.disabled,
    },attrs:{"name":_vm.name,"label":_vm.label,"value":_vm.value,"checked":_vm.checked},on:{"change":_vm.handlerComponentChanged}},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e()],1)}
var staticRenderFns = []
export { render, staticRenderFns }