import BaseModal from '@shared/legacy/components/modals/base-modal';
import template from './template.html.ejs';

export default class ResponseModal extends BaseModal {
  constructor({
    iconClass,
    title,
    content,
    submitText,
  }) {
    super({
      iconClass,
      title,
      content,
      submitText,
    }, template);
  }
}
