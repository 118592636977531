<template>
  <aside class="oc-step-sidebar">
    <z-title
      size="extra-small"
      class="oc-step-sidebar__title"
      tag="h3"
    >
      Resumo da Oferta
    </z-title>

    <ul
      class="oc-step-sidebar__list"
      v-for="(section, sectionIndex) in availableSections"
      :key="sectionIndex"
    >
      <z-text
        tag="li"
        :class="[
          'oc-step-sidebar__item',
          'oc-step-sidebar__item--subtitle'
        ]"
      >
        {{ section.title }}
      </z-text>

      <li
        class="oc-step-sidebar__item"
        v-for="(item, itemIndex) in section.items"
        :key="itemIndex"
      >
        <z-icon
          :icon="['fal', item.icon]"
          class="oc-step-sidebar__icon"
        />

        <z-text
          size="small"
          tag="span"
        >
          {{ item.text }}
        </z-text>
      </li>
    </ul>
  </aside>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { currencyFormat, decimalFormat } from '@shared/utils/number_format';
import { period, shiftRules } from '@stock/business';

export default {
  methods: {
    mapsToASectionWithOnlyAvailableItems(section) {
      const items = section.items.filter(({ text }) => Boolean(text));
      return { ...section, items };
    },
  },
  computed: {
    ...mapState('OfferCreationModule', [
      'sharedCourse',
      'sharedCampus',
      'sharedOffer',
      'universityOffersByShift',
    ]),
    ...mapGetters('OfferCreationModule', {
      availableShifts: 'shifts',
    }),
    sidebarSections() {
      return [
        {
          title: this.sharedCourse.name,
          items: [
            {
              text: this.sharedCourse.level,
              icon: 'file-certificate',
            },
            {
              text: this.sharedCourse.kind,
              icon: 'chalkboard-teacher',
            },
            ...this.availableShiftItems,
            {
              text: this.maxPeriodsLabel,
              icon: 'hourglass-half',
            },
            {
              text: this.totalHoursLabel,
              icon: 'clock',
            },
          ],
        },
        {
          title: this.sharedCampus.name,
          items: [
            {
              text: this.sharedCampus.fullAddress,
              icon: 'map-marker-alt',
            },
          ],
        },
        {
          title: 'Preço sem desconto',
          items: this.fullPricesWithShift,
        },
      ];
    },
    availableShiftItems() {
      return this.availableShifts.map(shift => ({
        text: shiftRules.shiftTranslate(shift),
        icon: shiftRules.shiftIcon(shift),
      }));
    },
    fullPricesWithShift() {
      return this.availableShifts
        .filter((shift) => {
          const { fullPrice } = this.universityOffersByShift[shift];
          return fullPrice;
        })
        .map((shift) => {
          const { fullPrice } = this.universityOffersByShift[shift];
          const text = shiftRules.shiftTranslate(shift);
          return {
            text: `${currencyFormat(fullPrice)} (${text})`,
            icon: 'usd-circle',
          };
        });
    },
    totalHoursLabel() {
      const { totalHours } = this.sharedOffer;
      return totalHours ? `${decimalFormat(totalHours)} horas` : null;
    },
    maxPeriodsLabel() {
      const { maxPeriods, periodKind } = this.sharedCourse;
      if (maxPeriods && periodKind) {
        return period.maxPeriodsLabel({ maxPeriods, periodKind });
      }
      return null;
    },
    availableSections() {
      return this.sidebarSections
        .filter(section => Boolean(section.title))
        .map(this.mapsToASectionWithOnlyAvailableItems)
        .filter(section => section.items.length > 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@quero/zilla-core/src/utils/_index.scss";

.oc-step-sidebar {
  background-color: var(--color-gray-000);
  border-left: var(--size-micro) solid var(--color-gray-050);
}

.oc-step-sidebar__title {
  margin-top: 0;
  margin-bottom: 0;
}

// TODO: Zilla Override
.oc-step-sidebar__item--subtitle {
  font-weight: 500;
}

.oc-step-sidebar__list {
  @extend %reset-list;

  margin-top: var(--space-large);
}

.oc-step-sidebar__item {
  display: flex;
  margin-bottom: var(--space-small);

  &:last-child {
    margin-bottom: 0;
  }
}

.oc-step-sidebar__icon {
  margin-right: var(--space-small);
}

%reset-list {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}
</style>
