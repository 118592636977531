import template from '@stock/legacy/components/modals/offer-list-actions/inline-steps/pricing/steps/edit/template.ejs';
import Step from '@stock/legacy/components/stepper/step';
import validator from '@shared/utils/validator';
import between from '@shared/utils/validators/between';
import isNumber from '@shared/utils/validators/is-number';
import NumberFormatter from '@shared/utils/number_format';
import { scope } from '@shared/utils/jquery';

const $ = scope('.js-edit-step-wrapper');

const MIN_DISCOUNT = 0.05;
const MAX_DISCOUNT = 0.80;

export class EditStep extends Step {
  constructor({ fullPrice, offeredPrice }) {
    const numberFormatter = new NumberFormatter();
    super({
      template,
      attributes: {
        fullPrice: numberFormatter.toPrice(fullPrice),
        offeredPrice: numberFormatter.toPrice(offeredPrice),
      },
    });
    this.fullPrice = fullPrice;
  }

  afterRender() {
    this.$nextButton = $('.js-next-button');

    this.setupButtons();
    this.setupInput();
    this.setupBackButton();
  }

  setupButtons() {
    this.$nextButton.click(() => super.next());
  }

  setupInput() {
    const $offeredPriceInput = $('.js-offered-price-input');

    const minOfferedPrice = this.fullPrice * (1 - MAX_DISCOUNT);
    const maxOfferedPrice = this.fullPrice * (1 - MIN_DISCOUNT);

    validator($offeredPriceInput, {
      validations: [
        isNumber,
        between(minOfferedPrice, maxOfferedPrice),
      ],
      whenValid: el => this.onInputValid(el),
      whenInvalid: el => this.onInputInvalid(el),
    });

    $offeredPriceInput.on('input', ({ target: { value } }) => {
      super.setAttribute({ newOfferedPrice: value });
    });
  }

  onInputValid(el) {
    this.$nextButton.prop('disabled', false);
    $(el).closest('.form-group').removeClass('has-error');
  }

  onInputInvalid(el) {
    this.$nextButton.prop('disabled', true);
    $(el).closest('.form-group').addClass('has-error');
  }

  setupBackButton() {
    $('.js-back-button').on('click', () => {
      this.event.emit('previous');
    });
  }
}
