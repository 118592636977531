import BatchStockActions from '@stock/legacy/components/modals/offer-list-actions/batch';
import { batchOperationsTracking } from '@stock/metrics';
import validationErrorModal from './validation-error-modal';

export default class OfferBatchActionsMenu {
  constructor(searchForm, alert) {
    this.searchForm = searchForm;
    this.alert = alert;
    this.batchStockActions = new BatchStockActions(searchForm, alert);
  }

  renderBatchRestockModal(seatsCount) {
    return this.batchStockActions.showBatchRestockModal(seatsCount);
  }

  renderBatchStockDiscountChangeModal() {
    if (!this.useDiscountFilter()) {
      return validationErrorModal(this.searchForm);
    }
    return this.batchStockActions.showBatchStockDiscountChangeModal();
  }

  renderBatchCreateWindowOffersModal() {
    batchOperationsTracking.trackShowCreateWindowOffersModal();
    return this.batchStockActions.showBatchWindowOffersModal();
  }

  renderBatchDisableOffersModal() {
    batchOperationsTracking.trackShowDisableOffersModal();
    return this.batchStockActions.showBatchDisableOffersModal();
  }

  renderBatchEndChangeModal() {
    batchOperationsTracking.trackShowChangeEndOffersModal();
    return this.batchStockActions.showBatchEndChangeModal();
  }

  useDiscountFilter() {
    const discounts = this.getDiscountFilters();

    if (!discounts || discounts.length > 1) {
      this.searchForm.clearValidation('discount');
      return false;
    }
    return true;
  }

  getDiscountFilters() {
    const { discount } = this.searchForm.toObject();
    if (!discount.value || discount.value === '') {
      return null;
    }
    return discount.value.split('-');
  }
}
