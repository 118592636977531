const head = Symbol('head');
const tail = Symbol('tail');

export default class DoubleLinkedList {
  constructor() {
    this[head] = null;
    this[tail] = null;
    this.currentNode = null;
  }

  /**
   * @param {DoubleLinkedNode} node
   *
   * @returns void
  */
  addNode(node) {
    if (!this[head]) {
      this[head] = node;
      this[tail] = node;
      this.currentNode = node;
    } else {
      node.setPreviousNode(this[tail]);
      this[tail].setNextNode(node);
      this[tail] = node;
    }
  }

  /**
   * @returns void
  */
  removeTailNode() {
    if (this[head] === this[tail]) {
      this[head] = null;
      this[tail] = null;
      this.currentNode = null;
    } else {
      if (this[tail] === this.currentNode) {
        this.currentNode = this[tail].previous;
      }

      this[tail] = this[tail].previous;
      this[tail].setNextNode(null);
    }
  }

  /**
   * @returns {DoubleLinkedNode}
   */
  next() {
    if (this.currentNode.next !== null) {
      this.currentNode = this.currentNode.next;
    }
    return this.currentNode;
  }

  /**
   * @returns {DoubleLinkedNode}
   */
  previous() {
    if (this.currentNode.previous !== null) {
      this.currentNode = this.currentNode.previous;
    }
    return this.currentNode;
  }

  /**
   * @returns {DoubleLinkedNode}
   */
  getCurrentNode() {
    return this.currentNode;
  }
}
