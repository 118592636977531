<template>
  <z-card>
    <z-title size="extra-small">
      Filtrar histórico
    </z-title>

    <grid-container no-padding>
      <grid-item :column="3">
        <z-input-field label="Ações">
          <z-dropdown-input
            v-model="actionType"
            :options="actionTypeList"
          />
        </z-input-field>
      </grid-item>

      <grid-item :column="2">
        <z-input-field label="Status">
          <z-dropdown-input
            v-model="status"
            :options="actionStatusList"
          />
        </z-input-field>
      </grid-item>

      <grid-item :column="3">
        <UniversityUserEmailAutocomplete
          @select="handleUniversityUserChange"
          @remove="handleUniversityUserRemove"
          :value="university_user"
        />
      </grid-item>

      <grid-item :column="2">
        <z-input-field
          label="Início do período"
        >
          <z-input
            type="date"
            v-model="min_created_at"
            :max="actionMaxDate"
            :has-error="!isValidDate(min_created_at)"
          />
        </z-input-field>
      </grid-item>

      <grid-item :column="2">
        <z-input-field label="Fim do período">
          <z-input
            type="date"
            v-model="max_created_at"
            :max="actionMaxDate"
            :has-error="!isValidDate(max_created_at)"
          />
        </z-input-field>
      </grid-item>

      <grid-item class="filter">
        <z-button
          @click="handleClick"
          class="button"
        >
          Aplicar
        </z-button>
      </grid-item>
    </grid-container>
  </z-card>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { UniversityUserEmailAutocomplete } from '@shared/components/autocompletes/university-user-email-autocomplete';

export default {
  components: {
    GridContainer,
    GridItem,
    UniversityUserEmailAutocomplete,
  },
  data() {
    return {
      actionType: 'Todas',
      status: 'Todos',
      min_created_at: '',
      max_created_at: '',
      university_user: {},
    };
  },
  computed: {
    actionTypeList() {
      return [
        'Todas',
        'Alteração de quantidade de bolsa',
        'Alteração de desconto',
        'Criação de oferta',
        'Despublicação de oferta',
        'Alteração de curso',
        'Criação de campus',
      ];
    },
    actionStatusList() {
      return [
        'Todos',
        'Concluido',
        'Processando',
        'Em espera',
      ];
    },
    actionMaxDate() {
      return new Date().toISOString().split('T')[0];
    },
    actionMinDate() {
      const today = new Date();
      today.setDate(today.getDate() - 30);
      return today.toISOString().split('T')[0];
    },
  },
  methods: {
    handleClick() {
      this.handleSubmit();
    },
    handleSubmit() {
      const form = {
        name: this.actionType,
        status: this.status,
        min_created_at: this.min_created_at,
        max_created_at: this.max_created_at,
        university_user: this.university_user,
        page: 1,
        hasErrors: this.status === 'Erro',
      };
      this.$emit('submit-filters', form);
    },
    handleUniversityUserChange(user) {
      this.university_user = user;
    },
    handleUniversityUserRemove(user) {
      this.university_user = this.university_user.filter(element => element.id !== user.id);
    },
    isValidDate(date) {
      const validatedDate = Date.parse(date);
      return !Number.isNaN(Number(validatedDate));
    },
  },
  mounted() {
    this.min_created_at = this.actionMinDate;
    this.max_created_at = this.actionMaxDate;
  },
};
</script>

<style>
.filter{
  justify-self: end;
}
</style>
