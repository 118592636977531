<template>
  <section>
    <z-title
      size="extra-small"
      class="create-offer-filter-painel-deal__title"
    >
      Configurações do filtro
    </z-title>

    <GridContainer class="create-offer-filter-painel-deal__row">
      <GridItem :column="3">
        <z-input-field label="Este filtro está ativo?">
          <z-toggle-switch
            @change="$emit('offer-filter-enabled-change', !offerFilterEnabled)"
            :value="offerFilterEnabled"
          />
        </z-input-field>
      </GridItem>
    </GridContainer>
  </section>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';

export default {
  components: {
    GridContainer,
    GridItem,
  },
  props: {
    offerFilterEnabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    brgId() {
      return { id: this.$route.params.id };
    },
  },
};
</script>

<style>
.create-offer-filter-painel-deal__title {
  margin-bottom: var(--space-medium);
}

.create-offer-filter-painel-deal__row {
  --grid-padding: 0;
}

.z-input-field__label {
  display: block;
}
</style>
