const campusesAutoCompletePath = () => (
  '/util/autocomplete/campuses'
);
const coursesDictionaryEntriesAutoCompletePath = () => (
  '/util/autocomplete/course_dictionary_entries'
);
const offerDetailPath = offerUuid => (
  `/ui/ofertas/${offerUuid}`
);
const selectableCaptationsPath = () => (
  '/ui/ofertas/create-offer/selectable-captations'
);

export const routes = {
  coursesDictionaryEntriesAutoCompletePath,
  campusesAutoCompletePath,
  selectableCaptationsPath,
  offerDetailPath,
};
