<template>
  <section>
    <div class="section-config-brg-contract__header">
      <z-title class="section-config-brg-contract__title">
        <z-icon
          :icon="['far', 'file-alt']"
          color="primary"
        /> Contrato
      </z-title>

      <z-text
        size="small"
        color="minor"
      >
        Adicionar a URL com o contrato da negociação entre Quero e IES assinado pela insituição
      </z-text>
    </div>

    <z-input
      type="text"
      v-model="contractUrl"
      size="small"
      placeholder="www.url-do-contrato.com"
    />
  </section>
</template>

<script>
export default {
  computed: {
    contractUrl: {
      get() {
        return this.$store.state.QueroTurboActivationModalModule.businessRuleGroup.contractUrl;
      },
      set(value) {
        this.$store.commit('QueroTurboActivationModalModule/setBRGContractUrl', value);
      },
    },
  },
};
</script>

<style>
.section-config-brg-contract__header {
  max-width: 230px;
  margin-bottom: var(--space-medium);
}

.section-config-brg-contract__title {
  font-weight: 500;
  margin-bottom: var(--space-small);
}
</style>
