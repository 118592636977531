module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="inline-stock-action-confirmation__body modal-body">\n    <header class="inline-stock-action-confirmation__header">\n      <h2 class="inline-stock-action-confirmation__title gq-title">\n        Confirmar alteração de valor da oferta\n      </h2>\n      <p class="inline-stock-action-confirmation__description gq-text">\n        Antes de confirmar, verifique o resumo da alteração que você fará no seu estoque\n      </p>\n    </header>\n\n    <section>\n      <h3 class="inline-stock-action-confirmation__subtitle gq-title gq-title--micro gq-title--soft">\n        Oferta selecionada\n      </h3>\n      <ul class="inline-stock-action-confirmation__fiters--medium key-value-list key-value-list--column">\n        <li class="key-value-list__item">\n          <h4 class="key-value-list__key gq-text gq-text--bold">Curso</h4>\n          <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.courseName )) == null ? '' : __t) +
'</p>\n        </li>\n\n        <li class="key-value-list__item">\n          <h4 class="key-value-list__key gq-text gq-text--bold">Campus</h4>\n          <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.campus )) == null ? '' : __t) +
'</p>\n        </li>\n\n        <li class="key-value-list__item">\n          <h4 class="key-value-list__key gq-text gq-text--bold">Nível</h4>\n          <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.level )) == null ? '' : __t) +
'</p>\n        </li>\n\n        <li class="key-value-list__item">\n          <h4 class="key-value-list__key gq-text gq-text--bold">Modalidade</h4>\n          <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.kind )) == null ? '' : __t) +
'</p>\n        </li>\n\n        <li class="key-value-list__item">\n          <h4 class="key-value-list__key gq-text gq-text--bold">Turno</h4>\n          <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.shift )) == null ? '' : __t) +
'</p>\n        </li>\n\n        <li class="key-value-list__item">\n          <h4 class="key-value-list__key gq-text gq-text--bold">Captação</h4>\n          <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.enrollmentSemester )) == null ? '' : __t) +
'</p>\n        </li>\n\n        <li class="key-value-list__item">\n          <h4 class="key-value-list__key gq-text gq-text--bold">Desconto</h4>\n          <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.discount )) == null ? '' : __t) +
'%</p>\n        </li>\n\n        <li class="key-value-list__item">\n          <h4 class="key-value-list__key gq-text gq-text--bold">Valor total</h4>\n          <p class="key-value-list__value gq-text gq-text--small">R$ ' +
((__t = ( offer.fullPrice )) == null ? '' : __t) +
'</p>\n        </li>\n\n        <li class="key-value-list__item">\n          <h4 class="key-value-list__key gq-text gq-text--bold">Valor com desconto</h4>\n          <p class="key-value-list__value gq-text gq-text--small">R$ ' +
((__t = ( offer.offeredPrice )) == null ? '' : __t) +
'</p>\n        </li>\n      </ul>\n    </section>\n    <section>\n      <h3 class="inline-stock-action-confirmation__changes-title gq-title gq-title--micro">\n        Resumo da alteração\n      </h3>\n\n      <ul class="inline-stock-action-confirmation__changes-list key-value-list key-value-list--clean">\n        <li class="key-value-list__item">\n          <h4 class="key-value-list__key gq-text gq-text--strong  gq-text--small">\n            Percentual de desconto atual\n          </h4>\n          <p class="key-value-list__value gq-text gq-text--strong gq-text--large">\n            ' +
((__t = ( currentDiscount )) == null ? '' : __t) +
'\n          </p>\n        </li>\n        <li class="key-value-list__item">\n          <h4 class="key-value-list__key gq-text gq-text--strong  gq-text--small gq-text--bold">\n            Novo percentual de desconto*\n          </h4>\n          <p class="key-value-list__value gq-text gq-text--strong gq-text--large gq-text--bold">\n            ' +
((__t = ( newDiscount )) == null ? '' : __t) +
'\n          </p>\n        </li>\n        <li class="key-value-list__item">\n          <h4 class="key-value-list__key gq-text gq-text--strong  gq-text--small">\n            Valor atual da oferta (com desconto)\n          </h4>\n          <p class="key-value-list__value gq-text gq-text--strong gq-text--large">\n            ' +
((__t = ( currentOfferedPrice )) == null ? '' : __t) +
'\n          </p>\n        </li>\n        <li class="key-value-list__item">\n          <h4 class="key-value-list__key gq-text gq-text--strong  gq-text--small gq-text--bold">\n            Novo valor da oferta (com desconto)*\n          </h4>\n          <p class="key-value-list__value gq-text gq-text--strong gq-text--large gq-text--bold">\n            ' +
((__t = ( newOfferedPrice )) == null ? '' : __t) +
'\n          </p>\n        </li>\n      </ul>\n      <p class="gq-text gq-text--strong gq-text--small">\n        * O percentual de desconto está atrelado ao valor da oferta (com desconto)\n          e deverá ficar <strong>entre 5% e 80%</strong>.\n          Ao alterar o desconto, o valor da oferta é alterado proporcionalmente e vice-versa.\n      </p>\n    </section>\n  </div>\n\n  <footer class="inline-stock-action-confirmation__footer modal-footer">\n    <button\n      type="button"\n      class="inline-stock-action-confirmation__footer-action btn btn-secondary reverse js-back-button"\n      data-dismiss="modal"\n    >\n      Voltar\n    </button>\n    <form class="js-confirm-form">\n      <button\n        type="submit"\n        class="inline-stock-action-confirmation__footer-action btn btn-primary"\n      >\n        Confirmar alteração\n      </button>\n    </form>\n  </footer>\n';

}
return __p
}