<template>
  <GridContainer no-padding>
    <GridItem>
      <z-title size="small">
        Benefícios e configurações
      </z-title>
    </GridItem>

    <GridItem
      :column="4"
      v-if="readyToEdit || hasOfferFilter"
    >
      <OfferFilterList />
    </GridItem>

    <GridItem
      :column="4"
      v-if="readyToEdit || hasCommission"
    >
      <CommissionConfigList />
    </GridItem>

    <GridItem
      :column="4"
      v-if="readyToEdit || hasAdmission"
    >
      <AdmissionConfigList />
    </GridItem>

    <GridItem
      :column="4"
      v-if="readyToEdit || hasBilling"
    >
      <BillingConfigList />
    </GridItem>
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { mapGetters } from 'vuex';
import {
  CommissionConfigList,
  AdmissionConfigList,
  OfferFilterList,
  BillingConfigList,
} from './bennefits';

export default {
  components: {
    AdmissionConfigList,
    BillingConfigList,
    CommissionConfigList,
    GridContainer,
    GridItem,
    OfferFilterList,
  },
  computed: {
    ...mapGetters('ShowBRGPageModule', [
      'readyToEdit',
      'hasOfferFilter',
      'hasCommission',
      'hasBilling',
      'hasAdmission',
    ]),
  },
};
</script>

<style>

</style>
