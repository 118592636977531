import { queroAlunosAPI } from '@stock/api/quero-alunos-api.js';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Publish offer creation spreadsheet from university
 *
 * @param {Object} params
 * @property {number} params.id id of offer creation spreadsheet to be published
 *
 * @typedef Success
 * @property {string} message
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function publishOfferCreationSpreadsheet({ id }) {
  return queroAlunosAPI.publishOfferCreationSpreadsheet(id)
    .then(({ data }) => (data))
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[createOffers] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

export {
  publishOfferCreationSpreadsheet,
};
