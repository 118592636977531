const DOCUMENTS = [
  { label: 'RG e CPF', key: 'rg' },
  { label: 'CNH', key: 'cnh' },
  { label: 'Comprovante de residência', key: 'comprovante_de_residencia' },
  { label: 'Foto 3x4', key: 'foto_3x4' },
  { label: 'Comprovante de voto', key: 'comprovante_de_voto' },
  { label: 'Título de eleitor', key: 'titulo_de_eleitor' },
  { label: 'Certidão de nascimento ou casamento', key: 'certidao_de_nascimento_ou_casamento' },
  { label: 'Exame de sangue', key: 'exame_de_sangue' },
  { label: 'Diário Oficial', key: 'diario_oficial' },
  { label: 'Certidão de conclusão de Ensino médio', key: 'certificado_de_conclusao_do_ensino_medio' },
  { label: 'Histórico escolar', key: 'historico_escolar' },
  { label: 'Selfie com documento', key: 'selfie_com_documento' },
  { label: 'Certificado de reservista', key: 'certificado_de_reservista' },
  { label: 'RG e CPF', key: 'rg_do_guardiao' },
  { label: 'CNH', key: 'cnh_do_guardiao' },
  { label: 'Comprovante de residência', key: 'comprovante_de_residencia_do_guardiao' },
  { label: 'Certificado de Graduação', key: 'certificado_graduacao' },
  { label: 'Histórico de Graduação', key: 'historico_graduacao' },
];

const IDENTIFICATION_DOCS = ['rg', 'cnh'];

const getLabel = key => DOCUMENTS.find(
  document => document.key === key,
).label;

const getIdentificationDocs = () => IDENTIFICATION_DOCS;

export const AdmissionDocumentBusiness = {
  getLabel,
  getIdentificationDocs,
};
