<template>
  <div v-frag>
    <slot :permitted="permitted" />
  </div>
</template>

<script>
import frag from 'vue-frag';

export default {
  directives: {
    frag,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
    subject: {
      type: String,
      required: true,
    },
  },
  data() {
    return { permitted: false };
  },
  created() {
    this.$upaAbility.can(this.action, this.subject).then((result) => {
      this.permitted = result;
      this.$emit('loaded', result);
    }).catch(() => {
      this.$emit('loaded', false);
    });
  },
};
</script>
