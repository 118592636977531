<template>
  <div class="oc-processing-request">
    <div v-if="isLoading">
      <z-loading
        class="oc-processing-request__loader"
        variant="spinner"
        size="medium"
      />

      <z-title size="medium">
        Aguarde
      </z-title>
    </div>

    <template v-else>
      <z-icon
        :icon="['fad', 'clock']"
        title="relógio"
        size="giant"
        color="primary"
      />

      <z-title
        class="oc-processing-request__title"
        size="medium"
        align="center"
      >
        Sua solicitação está sendo processada.
      </z-title>

      <z-text
        class="oc-processing-request__message"
        align="center"
        behavior="block"
        size="large"
        color="minor"
      >
        Verifique o status de sua operação no registro de atividades.
      </z-text>
      <z-button
        @click="finished"
        class="oc-processing-request__button"
      >
        Voltar para a Lista de ofertas
      </z-button>
    </template>
  </div>
</template>

<script>
import { queroAlunosAPI } from '@stock/api/quero-alunos-api';
import { mapState } from 'vuex';
import { shiftRules } from '@stock/business';
import { RollbarService } from '@shared/utils/rollbar-service';

export default {
  data() {
    return {
      createOfferRequestStatus: 'loading',
    };
  },
  computed: {
    ...mapState('SessionModule', ['university']),
    ...mapState('OfferCreationModule', [
      'sharedCampus',
      'sharedCourse',
      'sharedOffer',
      'universityOffersByShift',
    ]),
    isLoading() {
      return this.createOfferRequestStatus === 'loading';
    },
    requestSharedValues() {
      const sharedValues = {
        course: {
          name: this.sharedCourse.name,
          level: this.sharedCourse.level,
          kind: this.sharedCourse.kind,
          periodKind: this.transformPeriodKind(this.sharedCourse.periodKind),
          maxPeriods: this.sharedCourse.maxPeriods,
        },
        enrollmentSemesters: this.sharedOffer.enrollmentSemesters,
        offer: {
          offerSpecialConditions: this.sharedOffer.offerSpecialConditions,
          metadata: { totalHours: this.sharedOffer.totalHours },
        },
      };

      if (sharedValues.offer.offerSpecialConditions.length === 0) {
        delete sharedValues.offer.offerSpecialConditions;
      }

      if (sharedValues.offer.metadata.totalHours.length === 0) {
        delete sharedValues.offer.metadata.totalHours;
      }

      if (Object.keys(sharedValues.offer.metadata).length === 0) {
        delete sharedValues.offer.metadata;
      }

      return sharedValues;
    },
    requestCourseKeyValues() {
      return Object.entries(this.universityOffersByShift).map(([shift, uOffer]) => ({
        ...this.requestSharedValues.course,
        shift: shiftRules.shiftTranslate(shift),
        universityOffers: this.getRequestOfferData(uOffer),
      }));
    },
    requestParams() {
      return {
        universityId: this.university.id,
        campusId: this.sharedCampus.id,
        courses: this.requestCourseKeyValues,
      };
    },
  },
  methods: {
    finished() {
      this.$emit('finished');
    },
    transformPeriodKind(periodKind = '') {
      if (periodKind === 'Mês') {
        return 'mes';
      }
      return periodKind.toLowerCase();
    },
    getRequestOfferData(universityOffer) {
      return this.requestSharedValues.enrollmentSemesters.map(({ semester, endDate }) => ({
        fullPrice: universityOffer.fullPrice,
        commercialDiscount: universityOffer.commercialDiscount,
        enrollmentSemester: semester,
        maxPayments: universityOffer.maxPayments,
        offers: [{
          ...this.requestSharedValues.offer,
          limited: true, // For now we will support only limited offers
          totalSeats: universityOffer.totalSeats,
          offeredPrice: universityOffer.offeredPrice,
          end: endDate,
        }],
      }));
    },
  },
  mounted() {
    queroAlunosAPI.createInlineOffer(this.requestParams)
      .then((_response) => {
        this.createOfferRequestStatus = 'done';
      })
      .catch((error) => {
        this.createOfferRequestStatus = 'done';

        let extraArgs = {};
        if (error.response) {
          extraArgs = { response: error.response };
        } else if (error.request) {
          extraArgs = { request: error.request };
        }
        const errMsg = `[createInlineOffer] message: ${error.message}`;
        RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      });
  },
};
</script>

<style lang="scss" scoped>
.oc-processing-request {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.oc-processing-request__loader {
  text-align: center;
  margin-bottom: var(--space-large);
}

.oc-processing-request__title {
  margin-top: var(--space-large);
}

.oc-processing-request__message {
  margin-top: var(--space-medium);
}

.oc-processing-request__button {
  margin-top: var(--space-extra-jumbo);
}
</style>
