import Vue from 'vue';
import { ZillaPlugin } from '@shared/utils/vue/plugins/zilla-plugin';
import { ValidatePlugin } from '@shared/utils/vue/plugins/validate-plugin';
import { UPAAbilityPlugin } from '@shared/plugins/upa-ability-plugin';
import HomeOverview from './home-overview-page.vue';

export function HomeOverviewPage() {
  return new Promise((resolve) => {
    Vue.use(ValidatePlugin);
    Vue.use(ZillaPlugin);
    Vue.use(UPAAbilityPlugin);

    new Vue({
      el: '#js-home-overview-page',
      components: {
        HomeOverview,
      },
      mounted() {
        resolve();
      },
    });
  });
}
