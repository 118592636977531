<template>
  <z-card>
    <transition
      name="component-fade"
      mode="out-in"
    >
      <component
        v-if="currentFeedcard"
        :is="currentFeedcard"
        v-bind="currentFeedcardConfig"
        @click="handleButtonClick"
      />
    </transition>
  </z-card>
</template>

<script>
import { shouldBeOneOf } from 'vue-prop-validation-helper';
import { validateType } from 'vue-types';
import FeedbackCardSuccess from './feedback-card-success';
import FeedbackCardError from './feedback-card-error';
import FeedbackCardLoading from './feedback-card-loading';
import { FeedbackCardsModel, FeedbackCardModel } from './feedback-card-models';

export default {
  components: {
    FeedbackCardSuccess,
    FeedbackCardError,
    FeedbackCardLoading,
  },
  props: {
    status: {
      type: String,
      required: true,
      validator: shouldBeOneOf(['loading', 'error', 'success']),
    },
    config: FeedbackCardsModel,
  },
  computed: {
    currentFeedcard() {
      if (this.status === 'success') return FeedbackCardSuccess;

      if (this.status === 'error') return FeedbackCardError;

      if (this.status === 'loading') return FeedbackCardLoading;

      return null;
    },
    currentFeedcardConfig() {
      const feedcardProps = this.config[this.status];
      validateType(FeedbackCardModel, feedcardProps);
      return feedcardProps;
    },
  },
  methods: {
    handleButtonClick() {
      if (this.status === 'success') return this.$emit('success-click');

      if (this.status === 'error') return this.$emit('error-click');

      if (this.status === 'loading') return this.$emit('loading-click');

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}

.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}
</style>
