export default class NoDataFeedback {
  constructor(config = {}) {
    this.createComponent(config.container);
  }

  createComponent(elementContainer) {
    this.container = document.createElement('div');
    const description = document.createElement('span');
    this.container.classList.add('box__no-data', 'hidden');
    description.classList.add('box__no-data__description');
    description.textContent = 'Sem dados para exibição.';
    this.container.appendChild(description);
    document.querySelector(elementContainer).appendChild(this.container);
  }

  show() {
    this.container.classList.remove('hidden');
  }

  hide() {
    this.container.classList.add('hidden');
  }
}
