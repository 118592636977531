const getDictionaryKeyByText = (dictionaries, text) => {
  const elementFound = dictionaries
    .find(element => (element.text === text));
  return elementFound.key || null;
};

const getDictionaryTextByKey = (dictionaries, key) => {
  const elementFound = dictionaries
    .find(element => (element.key === key));
  return (elementFound && elementFound.text) || null;
};

const getDictionaryTextList = (dictionaries) => {
  const list = dictionaries
    .filter(dictionary => (dictionary.key !== undefined))
    .map(dictionary => dictionary.text);
  return list;
};

export { getDictionaryKeyByText, getDictionaryTextByKey, getDictionaryTextList };
