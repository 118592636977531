import $ from 'jquery';
import Vue from 'vue';
import enrollmentWizard from '@admission/legacy/components/templates/enrollment-wizard.html.ejs';

export function setupEnrollmentWizard() {
  $(document).ready(() => {
    $('body').on('click', '[data-approve-docs-wizard=true]', function openApproveDocsWizard() {
      const $this = $(this);
      const $body = $('body');
      const data = {
        universityName: $this.data('universityName'),
        universityId: $this.data('universityId'),
        followUpId: $this.data('followUpId'),
        followUpStep: $this.data('followUpStep'),
      };
      const wrapperId = `#js-enrollment-wizard-${data.followUpId}`;
      let wrapper = $(wrapperId);

      if (wrapper.length === 0) {
        $body.append(enrollmentWizard({ data }));
        new Vue({ el: $(wrapperId).selector });
      }

      wrapper = $(wrapperId); // reload query to get wrapper created

      if (wrapper.length === 1) {
        const approveDocsStep = $(`#js-enrollment-wizard-${data.followUpId}-1`);
        const approveDocsBtn = $('#js-approve-docs-button');
        const cancelBtn = $('#js-enrollment-cancel-button');

        cancelBtn.click(() => {
          approveDocsStep.modal('hide');
        });

        approveDocsBtn.click(() => {
          approveDocsStep.modal('hide');
        });

        approveDocsStep.modal('show');
      }
    });

    $('body').on('click', '[data-enrollment-wizard=true]', function openApproveDocsWizard() {
      const $this = $(this);
      const $body = $('body');
      const data = {
        universityName: $this.data('universityName'),
        universityId: $this.data('universityId'),
        followUpId: $this.data('followUpId'),
      };
      const wrapperId = `#js-enrollment-wizard-${data.followUpId}`;
      let wrapper = $(wrapperId);

      if (wrapper.length === 0) {
        $body.append(enrollmentWizard({ data }));
        new Vue({ el: $(wrapperId).selector });
      }

      wrapper = $(wrapperId); // reload query to get wrapper created

      if (wrapper.length === 1) {
        const delayToBootstrapWork = 400;

        const readyClassStep = $(`#js-enrollment-wizard-${data.followUpId}-2`);
        const daysToEnrollStep = $(`#js-enrollment-wizard-${data.followUpId}-3`);
        const successStep = $(`#js-enrollment-wizard-${data.followUpId}-4-success`);
        const failStep = $(`#js-enrollment-wizard-${data.followUpId}-4-fail`);
        const finishBtn = $(`#js-enrollment-wizard-success-${data.followUpId}, #js-enrollment-wizard-fail-${data.followUpId}`);
        const awaitEnrollmentBtn = $('#js-await-enrollment-button');

        finishBtn.click(() => {
          window.location.reload();
        });

        awaitEnrollmentBtn.click(() => {
          readyClassStep.modal('hide');
          daysToEnrollStep.modal('show');
        });

        $('.js-enroll-form').submit(() => {
          $('.modal-clean__loader', readyClassStep).show();
        }).on('ajax:success', () => {
          readyClassStep.modal('hide');
          setTimeout(() => successStep.modal('show'), delayToBootstrapWork);
        }).on('ajax:error', () => {
          readyClassStep.modal('hide');
          setTimeout(() => failStep.modal('show'), delayToBootstrapWork);
        });

        $('.js-awaiting-enrollment-form').submit(() => {
          $('.modal-clean__loader', daysToEnrollStep).show();
        }).on('ajax:success', () => {
          daysToEnrollStep.modal('hide');
          setTimeout(() => successStep.modal('show'), delayToBootstrapWork);
        }).on('ajax:error', () => {
          daysToEnrollStep.modal('hide');
          setTimeout(() => failStep.modal('show'), delayToBootstrapWork);
        });

        readyClassStep.modal('show');
      }
    });
  });
}
