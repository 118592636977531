import EJSHelpers from '@shared/utils/templates/ejs';

export const TEMPLATE_EDIT_PROPS = Object.freeze({
  title: 'Alteração de quantidade de bolsas em lote',
  description: `
    Informe se você quer aumentar, diminuir ou definir uma nova quantidade de
    <strong>bolsas disponíveis</strong> para as ofertas filtradas
  `,
  radioinc_label: 'Aumentar',
  radioinc_title: 'Aumentar a quantidade de bolsas',
  radiodec_label: 'Diminuir*',
  radiodec_title: 'Diminuir a quantidade de bolsas',
  radioabs_label: 'Definir nova quantidade',
  radioabs_title: 'Definir nova quantidade de bolsas',
  formSelector: 'js-batch-stock-form',
  label: 'Quantidade de bolsas',
  input: Object.freeze({
    selector: 'js-restock-seats-count',
    min: '1',
    placeholder: 'Informe a quantidade',
  }),
  disclaimerInc: `
    Informe o quanto você quer <strong>somar</strong>
    à quantidade de bolsas disponíveis atualmente para cada oferta
  `,
  disclaimerDec: `
    Informe o quanto você quer <strong>subtrair</strong>
    à quantidade de bolsas disponíveis atualmente para cada oferta
  `,
  disclaimerRep: `
    Informe a <strong>nova quantidade</strong> de
    <strong>bolsas disponíveis</strong> para cada uma das ofertas filtradas
  `,
  rules: Object.freeze([
    `
      A quantidade de bolsas final de uma oferta não pode ser menor que zero.
      O lote selecionado possui uma ou mais ofertas que <strong>não serão alteradas</strong>,
      de acordo com essa regra.
    `,
  ]),
  attr: EJSHelpers.attr,
  initialSeatsCount: '',
  button_confirm: 'Conferir prévia',
  button_cancel: 'Cancelar',
  mode: 'restock',
  feedback: seatsCount => `Cada oferta passará a ter <strong>${seatsCount} bolsas disponíveis</strong>`,
});

export const TEMPLATE_CONFIRMATION_PROPS = Object.freeze({
  title: 'Confirmar alteração de quantidade de bolsas disponíveis em lote',
  description: 'Antes de confirmar, verifique o resumo das alteracões que você fará no seu estoque',
  newValue: '',
  newValueMessage: {
    '+': 'Quantidade de bolsas adicionadas para cada oferta',
    '-': 'Quantidade de bolsas excluídas para cada oferta',
    '=': 'Nova quantidade de bolsas para cada oferta',
  },
  totalTargetMessage: {
    '+': 'Total de bolsas adicionadas',
    '-': 'Total de bolsas subtraídas',
    '=': 'Nova quantidade de bolsas disponíveis para as ofertas liberadas ',
  },
  button_confirm: 'Confirmar alteração',
  button_cancel: 'Voltar',
  rules: Object.freeze([
    'Não serão feitas alterações em ofertas ilimitadas ou com vagas compartilhadas',
    `
      Para <strong>diminuição de vagas</strong>, o decréscimo deve ser <strong>menor ou igual</strong>
      ao número de bolsas disponíveis
    `,
  ]),
  canShowTotalTarget: restockType => restockType !== '=',
});
