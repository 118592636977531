import EventEmitter from 'events';

/*
* basic markup needed to use this class
*
* <div class="js-more-actions-dropdown">
*   <button class="js-more-actions-btn"></button>
*   <ul class="js-more-actions-menu"></ul>
* </div>
*/

export class MoreActionsDropdowns {
  constructor(container) {
    this._event = new EventEmitter();
    this._$container = $(container);
    this._$allDropdownButtons = this._$container.find('.js-more-actions-btn');
    this._$allDropdowns = this._getDropdownByButton(this._$allDropdownButtons);
    this._setup();
  }

  closeAll() {
    this._$container.find('.js-more-actions-menu').hide();
  }

  on(eventName, callback) {
    this._event.on(eventName, options => callback(options));
  }

  _setup() {
    this._$allDropdownButtons.on('click', this._handlerClick.bind(this));
    $(document).on('click', () => this.closeAll());
  }

  _handlerClick(event) {
    event.preventDefault();
    event.stopPropagation();
    const $dropdown = this._getDropdownByButton($(event.currentTarget));
    this._toggleDropdown($dropdown);
  }

  _getDropdownByButton($dropdownButton) {
    return $dropdownButton.parent().find('.js-more-actions-menu');
  }

  _toggleDropdown($dropdown) {
    this._closeOthersDropdown($dropdown);
    $dropdown.toggle();
    if ($dropdown.is(':visible')) {
      this._event.emit('dropdown.show', { $dropdown });
    }
  }

  _closeOthersDropdown($dropdown) {
    const $othersDropdowns = this._$allDropdowns.not($dropdown);
    $othersDropdowns.hide();
  }
}
