import {
  Step01Documents, Step02CustomFields, Step03ContractAndEssay, Step04Review,
} from './steps';

const StepsNavigationSetup = {
  step01: {
    component: Step01Documents,
    position: 1,
    nextStepName: 'step02',
    title: 'Configure sua Admissão Digital',
    sidebar: true,
  },
  step02: {
    component: Step02CustomFields,
    position: 2,
    prevStepName: 'step01',
    nextStepName: 'step03',
    title: 'Informações adicionais',
    sidebar: true,
  },
  step03: {
    component: Step03ContractAndEssay,
    position: 3,
    prevStepName: 'step02',
    nextStepName: 'step04',
    title: 'Informações do processo seletivo',
    sidebar: true,
  },
  step04: {
    component: Step04Review,
    position: 4,
    prevStepName: 'step03',
    title: 'Confira as configurações de seu contrato',
    sidebar: false,
  },
};

export { StepsNavigationSetup };
