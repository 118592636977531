import $ from 'jquery';
import { RollbarService } from '@shared/utils/rollbar-service.js';

/**
 * Class for handling common behavior of Ajax requests, such as the request formatting and response handling.
 * It also isolates the application code from the Ajax library used.
 */
export default class {
  constructor(url) {
    this.url = url;
    this.events = {};
  }

  on(event, callback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(callback);
    return this;
  }

  trigger(event, response) {
    if (this.events[event]) {
      this.events[event].forEach((callback) => {
        callback(response);
      });
    }
  }

  postJson(data) {
    return this.post(data, 'application/json; charset=utf-8');
  }

  post(data, contentType = 'application/x-www-form-urlencoded; charset=UTF-8') {
    this.trigger('start', data);

    $.ajax({
      type: 'POST',
      url: this.url,
      dataType: 'json',
      contentType,
      data: (typeof data === 'string' ? data : JSON.stringify(data)),
    }).then(this.handleRequestSuccess.bind(this), this.handleRequestError.bind(this));
    return this;
  }

  get(params) {
    this.trigger('start', params);
    $.ajax({
      url: this.url,
      type: 'GET',
      data: params,
    }).then(this.handleRequestSuccess.bind(this), this.handleRequestError.bind(this));
    return this;
  }

  getJson(params) {
    this.trigger('start', params);
    $.ajax({
      url: this.url,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      data: JSON.stringify(params),
    }).then(this.handleRequestSuccess.bind(this), this.handleRequestError.bind(this));
    return this;
  }

  handleRequestSuccess(response) {
    this.trigger('success', response);
    this.trigger('complete', response);
  }

  handleRequestError(jqXHR) {
    const responseJson = this.parseJson(jqXHR);
    this.trigger('error', responseJson);
    this.triggerSpecificErrorEvent(jqXHR, responseJson);
    this.trigger('complete', responseJson);
  }

  parseJson(jqXHR) {
    try {
      return JSON.parse(jqXHR.responseText);
    } catch (error) {
      RollbarService
        .getInstance()
        .notifyError(`${error} when parse response. Response: ${jqXHR.responseText}`);
      return undefined;
    }
  }

  triggerSpecificErrorEvent(jqXHR, responseJson) {
    const eventName = this.statusCodeToEvent(jqXHR.status);
    if (eventName) {
      this.trigger(eventName, responseJson);
    }
  }

  statusCodeToEvent(statusCode) {
    return {
      400: 'validationError',
      500: 'serverError',
    }[`${statusCode}`];
  }
}
