<template>
  <GridContainer>
    <GridItem>
      <HeaderBox title="Criando regras de comissionamento da negociação" />
    </GridItem>

    <GridItem>
      <z-card>
        <GridContainer no-padding>
          <GridItem>
            <z-title size="small">
              Criando regras de comissionamento da negociação
            </z-title>
          </GridItem>

          <GridItem>
            <CreateCommissionConfigPainel
              ref="commissionConfigPainel"
              :brg-id="brgId"
            />
          </GridItem>

          <GridItem
            class="create-commission-config-page__action-section"
            behavior="horizontal"
          >
            <z-button
              tag="router-link"
              :to="`/brgs/${brgId}`"
              variant="secondary"
              class="create-commission-config-page__action-section__button"
            >
              Voltar
            </z-button>

            <z-button
              @click="handleFormSubmit"
              class="create-commission-config-page__action-section__button"
            >
              Criar comissionamento
            </z-button>
          </GridItem>
        </GridContainer>
      </z-card>
    </GridItem>
  </GridContainer>
</template>

<script>
import { CreateCommissionConfigPainel } from '@quero-turbo/modules/commission-components';
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { HeaderBox } from '@shared/components/header-box';
import { QueroDealsServices } from '@quero-turbo/services';

export default {
  components: {
    CreateCommissionConfigPainel,
    GridContainer,
    GridItem,
    HeaderBox,
  },
  computed: {
    brgId() {
      return Number(this.$route.params.id);
    },
  },
  methods: {
    async handleFormSubmit() {
      this.$upaHelperModal.show({ mode: 'loading' });
      const commissionConfigs = this.$refs.commissionConfigPainel.getCommissionConfigs();
      const productConfig = { businessRuleGroupId: this.brgId, commissionConfigs };
      const params = await QueroDealsServices.V1.CommissionConfig.parseRequestData(productConfig);

      QueroDealsServices.V1.CommissionConfig
        .create(params)
        .then(async ({ _result }) => {
          await this.$upaHelperModal.fire({ mode: 'success' });
          this.$router.push(`/brgs/${this.brgId}`);
        })
        .catch(async (_error) => {
          await this.$upaHelperModal.fire({ mode: 'error' });
        });
    },
  },
};
</script>

<style scoped>
.create-commission-config-page__action-section {
  justify-content: flex-end;
}

.create-commission-config-page__action-section__button:not(:first-child) {
  margin-left: var(--space-medium);
}
</style>
