import { QueroDealsV1API } from '@quero-turbo/api/quero-deals-v1-api';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Create offer filter
 *
 * @param {Object} params
 *
 * @typedef Success
 * @property {string} message
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function create({
  campusIds,
  universityIds,
  educationGroupIds,
  canonicalCourseIds,
  courseIds,
  kindIds,
  levelIds,
  maxPayments,
  origins,
  shiftIds,
  enrollmentSemesters,
  cityIds,
  stateIds,
  businessRuleGroupId,
  enabled,
  rawFilters,
}) {
  if (!isValidRequest(businessRuleGroupId, universityIds, campusIds, educationGroupIds)) {
    throw new Error('Ops! Encontramos alguns erros no formulário');
  }

  return QueroDealsV1API.OfferFilter.createOfferFilter({
    campusIds,
    universityIds,
    educationGroupIds,
    canonicalCourseIds,
    courseIds,
    kindIds,
    levelIds,
    maxPayments,
    origins,
    shiftIds,
    enrollmentSemesters,
    cityIds,
    stateIds,
    businessRuleGroupId,
    enabled,
    rawFilters,
  })
    .then(({ data }) => data)
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[createOfferFilter] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

const isValidRequest = (businessRuleGroupId, universityIds, campusIds, educationGroupIds) => {
  if (businessRuleGroupId) {
    if (universityIds.length !== 0) return true;

    if (campusIds.length !== 0) return true;

    if (educationGroupIds.length !== 0) return true;
  }

  return false;
};

export {
  create,
};
