<template>
  <GridContainer no-padding>
    <GridItem :column="6">
      <GridContainer no-padding>
        <GridItem>
          <z-input-field
            label="Responsável na instituição"
            class="step01-instituition__input-field"
          >
            <z-input
              type="text"
              placeholder="Nome do responsável"
              v-model="accountName"
            />
          </z-input-field>
        </GridItem>

        <GridItem>
          <z-input-field
            label="CNPJ vinculado a conta bancária?"
            class="step01-instituition__input-field"
          >
            <z-input
              type="text"
              placeholder="Ex.: 000.000.000/0000-00"
              v-mask="'##.###.###/####-##'"
              v-model="document"
              :has-error="hasWhiteSpaces(document) || !isCNPJValid(document)"
            />
          </z-input-field>
        </GridItem>

        <GridItem>
          <z-input-field
            label="Razão Social vinculada a conta bancária?"
            class="step01-instituition__input-field"
          >
            <z-input
              type="text"
              placeholder="Razão Social"
              v-model="nameOfRecipient"
            />
          </z-input-field>
        </GridItem>
      </GridContainer>
    </GridItem>

    <GridItem :column="6">
      <GridContainer no-padding>
        <GridItem>
          <z-input-field
            label="Selecione o banco*"
            class="step01-instituition__input-field"
          >
            <z-autocomplete
              clear-button-aria-label="Limpar banco selecionado"
              :value="bankSelected"
              @clear="bankSelected = null"
              @select="bankSelected = $event"
              :internal-search="true"
              :hide-selected="true"
              :options="BankBusiness.getBankList()"
              :show-no-result="bankNotSelected"
              label-by="name"
            />
          </z-input-field>
        </GridItem>

        <GridItem :column="7">
          <z-input-field
            label="Número da agência*"
            class="step01-instituition__input-field"
          >
            <z-input
              type="text"
              placeholder="Ex: 00000"
              :has-error="hasWhiteSpaces(agencyCode)"
              v-model="agencyCode"
            />
          </z-input-field>
        </GridItem>

        <GridItem :column="5">
          <z-input-field
            label="Dígito da agência"
            class="step01-instituition__input-field"
          >
            <z-input
              type="text"
              placeholder="Ex.: 0"
              :has-error="hasWhiteSpaces(agencyDigit)"
              v-model="agencyDigit"
            />
          </z-input-field>
        </GridItem>

        <GridItem>
          <z-input-field
            label="Tipo de conta*"
            class="step01-instituition__input-field"
          >
            <z-dropdown-input
              placeholder="Selecione um tipo de conta"
              :options="BankBusiness.getBankAccountTypeTextList()"
              v-model="bankAccountType"
            />
          </z-input-field>
        </GridItem>

        <GridItem :column="7">
          <z-input-field
            label="Número da conta*"
            class="step01-instituition__input-field"
          >
            <z-input
              type="text"
              placeholder="Ex: 00000"
              v-model="accountNumber"
              :has-error="hasWhiteSpaces(accountNumber)"
            />
          </z-input-field>
        </GridItem>

        <GridItem :column="5">
          <z-input-field
            label="Dígito da conta"
            class="step01-instituition__input-field"
          >
            <z-input
              type="text"
              placeholder="Ex.: 0"
              v-model="accountDigit"
              :has-error="hasWhiteSpaces(accountDigit)"
            />
          </z-input-field>
        </GridItem>
      </GridContainer>
    </GridItem>
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { createHelpers } from 'vuex-map-fields';
import { BankBusiness } from '@quero-turbo/business';
import { isCNPJ } from 'validation-br';

const { mapFields } = createHelpers({
  getterType: 'getStep01Field',
  mutationType: 'updateStep01Field',
});

export default {
  components: {
    GridContainer,
    GridItem,
  },
  computed: {
    BankBusiness: () => BankBusiness,
    ...mapFields('CreateBillingConfigModalModule', [
      'bankAccountParams.nameOfRecipient',
      'bankAccountParams.document',
      'bankAccountParams.bankSelected',
      'bankAccountParams.accountName',
      'bankAccountParams.agencyCode',
      'bankAccountParams.agencyDigit',
      'bankAccountParams.accountNumber',
      'bankAccountParams.accountDigit',
      'bankAccountParams.bankAccountType',
      'stepConfig.validStep01',
    ]),
    bankNotSelected() {
      return Boolean(!this.bankSelected);
    },
    canIAdvanceStep() {
      return Boolean(
        this.nameOfRecipient
          && this.document && !this.hasWhiteSpaces(this.document) && this.isCNPJValid()
          && (this.bankSelected && this.bankSelected.code)
          && this.accountName
          && !this.hasWhiteSpaces(this.agencyDigit)
          && this.agencyCode && !this.hasWhiteSpaces(this.agencyCode)
          && this.accountNumber && !this.hasWhiteSpaces(this.accountNumber)
          && this.accountDigit && !this.hasWhiteSpaces(this.accountDigit)
          && this.bankAccountType,
      );
    },
  },
  methods: {
    hasWhiteSpaces(value) {
      return (/\s/g.test(value));
    },
    isCNPJValid() {
      if (!this.document) return true;

      return isCNPJ(this.document);
    },
  },
  watch: {
    canIAdvanceStep(newValue) {
      this.validStep01 = newValue;
    },
  },
};
</script>

<style>
.step01-instituition__input-field {
  margin-bottom: 0;
}
</style>
