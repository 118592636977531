import { MoreActionsDropdowns } from '@stock/legacy/components/more-actions-dropdowns';
import { InlineOperations } from '@stock/legacy/components/modals/offer-list-actions/inline-steps';
import { setupOfferInlineActions } from './offer-inline-actions-menu';
import {
  initializePopoverStockAvailability,
  closeAllPopoverStockAvailability,
} from './popover-stock-availability';

const DROPDOWN_ACTIONS_BUTTON_SELECTOR = '.js-dropdown-actions__button';
const EXPORT_OFFERS_SELECTOR = '.js-export-offers';
const FILTERED_MESSAGE_SELECTOR = '.js-filtered-message';
const FOOTER_TABLE_SELECTOR = '.js-footer-table';
const OFFERS_TABLE_SELECTOR = '.js-offers-table';
const TEXT_CONTAINER_SELECTOR = '.js-text-container';
const TEXT_HEADER_SELECTOR = '.js-text-header';

const hideElements = selectors => selectors.forEach(selector => $(selector).hide());
const showElements = selectors => selectors.forEach(selector => $(selector).show());

export class OffersTable {
  setupEntriesCount(entriesCount, offersCount) {
    $('.js-filtered-message').empty();
    if (entriesCount === 0) {
      this.hideOfferTableControls();
    } else {
      this.showOfferTableControls(entriesCount, offersCount);
    }
  }

  showOfferTableControls(entriesCount, offersCount) {
    showElements([
      DROPDOWN_ACTIONS_BUTTON_SELECTOR,
      EXPORT_OFFERS_SELECTOR,
      FOOTER_TABLE_SELECTOR,
      TEXT_HEADER_SELECTOR,
    ]);
    $(OFFERS_TABLE_SELECTOR).removeClass('box__center-content');
    $(TEXT_CONTAINER_SELECTOR).removeClass('active-offers__list-header--no-result');
    $(FILTERED_MESSAGE_SELECTOR).append(buildMessage(entriesCount, offersCount));
  }

  hideOfferTableControls() {
    hideElements([
      DROPDOWN_ACTIONS_BUTTON_SELECTOR,
      EXPORT_OFFERS_SELECTOR,
      FOOTER_TABLE_SELECTOR,
      TEXT_HEADER_SELECTOR,
    ]);
    $(OFFERS_TABLE_SELECTOR).addClass('box__center-content');
    $(TEXT_CONTAINER_SELECTOR).addClass('active-offers__list-header--no-result');
    $(FILTERED_MESSAGE_SELECTOR).append('Não foram encontradas ofertas');
    $(FILTERED_MESSAGE_SELECTOR).removeClass('gq-text--small');
  }

  setupInlineActions() {
    const $offersLine = $('.js-offer-table-line');
    $offersLine.each((_, offerLine) => {
      const $offerLine = $(offerLine);
      setupOfferInlineActions($offerLine);
      this.setupMoreDetailsModal($offerLine);
    });
  }

  setupMoreDetailsModal($offerLine) {
    const $target = $offerLine.find('.js-course-data-section');

    $target.on('click', () => {
      const { offerTableItem, permissions } = $offerLine.data();
      InlineOperations.showStockActionModal({ offerTableItem, permissions }, { target: 'course-name' });
    });
  }

  setupPopovers() {
    const moreActionsDropdowns = new MoreActionsDropdowns('.js-offer-table-line');
    moreActionsDropdowns.on('dropdown.show', () => {
      closeAllPopoverStockAvailability();
    });
    initializePopoverStockAvailability().on('popover.show', () => {
      moreActionsDropdowns.closeAll();
    });
  }

  reloadTable(entriesCount, offersCount) {
    this.setupEntriesCount(entriesCount, offersCount);
    this.setupInlineActions();
    this.setupPopovers();
  }
}

function buildMessage(entriesCount, offersCount) {
  if (entriesCount > 10000) {
    return 'Exibindo <strong>mais de 10000</strong> cursos';
  }
  return `Exibindo ${countText(entriesCount, 'curso')} com ${countText(offersCount, 'oferta')}`;
}

function countText(count, text) {
  const message = `<strong>${count}</strong> ${text}`;
  return (count > 1) ? `${message}s` : message;
}
