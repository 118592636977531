import MessageModal from '@stock/legacy/components/modals/message-modal';
import ParagraphTemplate from '@university-panel/legacy/components/templates/paragraphs.html.ejs';

export default (searchForm) => {
  const paragraphs = [
    `
      Filtre um desconto específico para realizar uma alteração em lote.
      As ofertas que estiverem neste filtro terão o novo desconto aplicado.
    `,
    'Você também pode combinar com os outros filtros disponíveis.',
  ];

  const messageModal = new MessageModal({
    title: 'Alteração de desconto em lote',
    content: ParagraphTemplate({ paragraphs }),
    submitText: 'Filtrar desconto',
  });

  messageModal.show();
  messageModal.$container.on('click', '.js-confirm-button', () => {
    searchForm.setWarning('discount');
    searchForm.setFocus('discount');
  });

  return messageModal;
};
