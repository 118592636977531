module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="inline-stock-action-confirmation-with-recommendation__body modal-body">\n    <header class="inline-stock-action-confirmation-with-recommendation__header">\n      <h2 class="inline-stock-action-confirmation-with-recommendation__title gq-title">\n        Otimização de oferta\n      </h2>\n\n      <p class="inline-stock-action-confirmation-with-recommendation__description gq-text">\n        Com base no desempenho em sua praça recomendamos otimizações para potencializar as vendas da sua oferta.\n      </p>\n    </header>\n\n    <section>\n      <h3 class="inline-stock-action-confirmation-with-recommendation__subtitle gq-title gq-title--micro">\n        Oferta selecionada\n      </h3>\n\n      <ul class="inline-stock-action-confirmation-with-recommendation__fiters--medium key-value-list key-value-list--column">\n        <li class="key-value-list__item">\n          <h4 class="key-value-list__key gq-text gq-text--bold">Curso</h4>\n\n          <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.courseName )) == null ? '' : __t) +
'</p>\n        </li>\n      </ul>\n    </section>\n    <section>\n      <h3 class="inline-stock-action-confirmation-with-recommendation__changes-title gq-title gq-title--micro">\n        Resumo da alteração\n      </h3>\n\n      <div class="inline-stock-action-confirmation-with-recommendation__offers-section">\n        <ul class="inline-stock-action-confirmation-with-recommendation__changes-list key-value-list key-value-list--clean">\n          <div class="\n            inline-stock-action-confirmation-with-recommendation__changes-list-title\n            inline-stock-action-confirmation-with-recommendation__changes-list-title--current-offer\n            gq-text\n            gq-text--nano\n            gq-text--bold\n          ">\n            Atual\n          </div>\n\n          <h3 class="\n            inline-stock-action-confirmation-with-recommendation__changes-list-text\n            gq-text\n            gq-text--small\n            gq-text--strong\n          ">Valor com desconto</h3>\n\n          <h3 class="\n            inline-stock-action-confirmation-with-recommendation__changes-list-text\n            gq-text\n            gq-text--extra-large\n            gq-text--strong\n          ">' +
((__t = ( currentOfferedPrice )) == null ? '' : __t) +
'</h3>\n\n          <h3 class="\n            inline-stock-action-confirmation-with-recommendation__changes-list-text\n            gq-text\n            gq-text--small\n            gq-text--strong\n          ">Percentual de desconto</h3>\n\n          <h3 class="\n            inline-stock-action-confirmation-with-recommendation__changes-list-text\n            inline-stock-action-confirmation-with-recommendation__changes-list-text--margin-bottom\n            gq-text\n            gq-text--extra-large\n            gq-text--strong\n          ">' +
((__t = ( currentDiscount )) == null ? '' : __t) +
'</h3>\n        </ul>\n\n        <i class="fal fa-arrow-right"></i>\n\n        <ul class="inline-stock-action-confirmation-with-recommendation__changes-list key-value-list key-value-list--clean">\n          <div class="\n            inline-stock-action-confirmation-with-recommendation__changes-list-title\n            inline-stock-action-confirmation-with-recommendation__changes-list-title--new-offer\n            gq-text\n            gq-text--nano\n            gq-text--bold\n          ">\n            Novo\n          </div>\n\n          <h3 class="\n            inline-stock-action-confirmation-with-recommendation__changes-list-text\n            gq-text\n            gq-text--bold\n            gq-text--small\n            gq-text--strong\n          ">Valor com desconto</h3>\n\n          <h3 class="\n            inline-stock-action-confirmation-with-recommendation__changes-list-text\n            gq-text\n            gq-text--extra-large\n            gq-text--bold\n            gq-text--strong\n          ">' +
((__t = ( newOfferedPrice )) == null ? '' : __t) +
'</h3>\n\n          <h3 class="\n            inline-stock-action-confirmation-with-recommendation__changes-list-text\n            gq-text\n            gq-text--bold\n            gq-text--small\n            gq-text--strong\n          ">Percentual de desconto</h3>\n\n          <h3 class="\n            inline-stock-action-confirmation-with-recommendation__changes-list-text\n            inline-stock-action-confirmation-with-recommendation__changes-list-text--margin-bottom\n            gq-text\n            gq-text--extra-large\n            gq-text--bold\n            gq-text--strong\n          ">' +
((__t = ( newDiscount )) == null ? '' : __t) +
'</h3>\n        </ul>\n      </div>\n    </section>\n  </div>\n\n  <footer class="inline-stock-action-confirmation-with-recommendation__footer modal-footer">\n    <button\n      type="button"\n      class="inline-stock-action-confirmation-with-recommendation__footer-action btn btn-secondary reverse js-back-button"\n      data-dismiss="modal"\n    >\n      Voltar\n    </button>\n    <form class="js-confirm-form">\n      <button\n        type="submit"\n        class="inline-stock-action-confirmation-with-recommendation__footer-action btn btn-primary"\n      >\n        Confirmar alteração\n      </button>\n    </form>\n  </footer>\n';

}
return __p
}