<template>
  <disclaimer-card
    @click="emitDisclaimerClosedEvent"
    :color="selectedInfo.color"
    :icon="selectedInfo.icon"
    :title="selectedInfo.title"
    :button-text="selectedInfo.buttonText"
  />
</template>

<script>
import { DisclaimerCard } from '@stock/components/disclaimer-card';
import { shouldBeOneOf } from 'vue-prop-validation-helper';

export default {
  components: {
    DisclaimerCard,
  },
  props: {
    status: {
      type: String,
      validator: shouldBeOneOf(['stockOptimized', 'fewSeats', 'stockoutAndFewSeats']),
      required: true,
    },
  },
  computed: {
    stockOptimized() {
      return {
        color: 'info',
        icon: 'bolt',
        title: 'Ofertas com otimizações disponíveis',
        buttonText: 'Ver todas as ofertas',
      };
    },
    fewSeats() {
      return {
        color: 'warning',
        icon: 'bolt',
        title: 'Ofertas com poucas vagas',
        buttonText: 'Ver todas as ofertas',
      };
    },
    stockoutAndFewSeats() {
      return {
        color: 'danger',
        icon: 'exclamation',
        title: 'Ofertas sem estoque e com poucas vagas',
        buttonText: 'Ver todas as ofertas',
      };
    },
    selectedInfo() {
      return this[this.status];
    },
  },
  methods: {
    emitDisclaimerClosedEvent() {
      this.$emit('closed-disclaimer-offers');
    },
  },
};
</script>
