<template>
  <div class="oc-marketplace-details">
    <Header
      name="Detalhes da oferta para o marketplace"
      :current-step-number="4"
      :last-step-number="5"
    />

    <z-card>
      <header class="oc-marketplace-details__card-marketplace">
        <img
          src="https://assets.guiadacarreira.com.br/wp-content/uploadedfiles/uploads/2019/01/Logo-QueroBolsa-Gradua%C3%A7%C3%A3o-RGB-Colorido_Positivo.png"
          width="150"
          alt="Quero Bolsa"
        >
      </header>

      <div class="oc-marketplace-details__card-discount-title">
        <z-icon
          :icon="['far','exclamation-circle']"
          class="oc-marketplace-details__card-discount-title-icon"
        />

        <z-text
          tag="span"
        >
          <strong>Porcentagem de desconto no QueroBolsa:</strong> este desconto deve ser pelo menos 5% maior que o desconto da faculdade
        </z-text>
      </div>

      <div
        class="oc-marketplace-details__card-table"
        ref="$formTable"
      >
        <offer-marketplace-form-table-header v-if="havePriceSuggestion" />

        <form-table
          :headers="tableHeaders"
          :rows="tableRows"
          @valueInput="formTableHandler"
        />
      </div>

      <footer class="oc-marketplace-details__card-footer">
        <z-text
          class="z-ovr-text--fw-medium"
        >
          Benefícios adicionais para os alunos:
        </z-text>

        <ul class="oc-marketplace-details__card-footer-list">
          <li
            class="oc-marketplace-details__card-footer-list-item"
            v-for="(osc, index) in offerSpecialConditionsOptions"
            :key="index"
          >
            <z-tag
              :value="osc.label"
              :is-selected="osc.selected"
              :has-icon="true"
              class="oc-marketplace-details__tag"
              @click="handleOSCSelect(index)"
            />
          </li>
        </ul>

        <z-text
          class="z-ovr-text--fw-medium"
        >
          Oferta(s) válida(s) a partir de:
        </z-text>

        <ul class="oc-marketplace-details__card-footer-list">
          <li
            class="oc-marketplace-details__card-footer-list-item"
            v-for="(semester, index) in semestersOptions"
            :key="index"
          >
            <z-tag
              :value="semester.description"
              :is-selected="semester.selected"
              :has-icon="true"
              class="oc-marketplace-details__tag"
              @click="handleSemesterSelect(index)"
            />
          </li>
        </ul>
      </footer>
    </z-card>

    <footer class="oc-marketplace-details__footer">
      <z-button
        @click="prev"
        variant="tertiary"
      >
        Voltar
      </z-button>

      <z-button
        :is-disabled="isNextStepDisabled"
        @click="next"
      >
        Próximo
      </z-button>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapGetters, mapActions } from 'vuex';
import { routes } from '@university-panel/utils/routes';
import FormTable from '@stock/modules/offer-creation/form-table';
import {
  price, offer, shiftRules, enrollmentSemester, seats,
} from '@stock/business';
import { offerCreationTracking } from '@stock/metrics';
import { competitivenessMixin } from '@stock/modules/offer-creation/mixins/competitiveness';
import { notifierMixin } from '@stock/utils/mixins/notifier';
import OfferMarketplaceFormTableHeader from
  '../offer-marketplace-form-table-header';
import Header from '../step-header';

export default {
  components: {
    Header,
    OfferMarketplaceFormTableHeader,
    FormTable,
  },
  mixins: [competitivenessMixin, notifierMixin],
  data() {
    return {
      offerSpecialConditionsOptions: [],
      semestersOptions: [],
      tableRows: [],
      suggestedByShift: {},
    };
  },
  mounted() {
    this.setupSemestersOptions();
    this.setupTableRows();
    this.setupOfferSpecialConditionsOptions();
    this.focusOnTheFirstInput();
  },
  methods: {
    ...mapActions('OfferCreationModule', [
      'updateUniversityOfferData',
      'updateSharedOffer',
    ]),
    async focusOnTheFirstInput() {
      await this.$nextTick();
      const $input = this.$el.querySelector('input');
      if ($input) $input.focus();
    },
    setupOfferSpecialConditionsOptions() {
      this.offerSpecialConditionsOptions = offer.specialConditions.map(osc => ({
        ...osc,
        selected: this.sharedOffer.offerSpecialConditions.some(
          key => key === osc.key,
        ),
      }));
    },
    updateCompetitivenessLabel(shiftKey) {
      if (!this.suggestedByShift[shiftKey]) return;
      const targetRow = this.getRowByShift(shiftKey);
      targetRow.competitiveness = this.calculateCompetitivenessByRow(shiftKey);
    },
    calculateCompetitivenessByRow(shiftKey) {
      const [startRange, finalRange] = this.suggestedByShift[shiftKey].range;
      const targetRow = this.getRowByShift(shiftKey);
      const convertedOfferedPrice = Number(targetRow.offeredPrice.value);
      if (!this.isValidRowOfferedPrice(shiftKey)) {
        return this.withoutCompetitiveInformation;
      }
      if (convertedOfferedPrice < startRange) {
        return this.veryCompetitive;
      }
      if (convertedOfferedPrice > finalRange) {
        return this.uncompetitive;
      }
      return this.competitive;
    },
    getCompetitivenessKeyByRow(shiftKey) {
      if (this.suggestedByShift[shiftKey]) {
        return this.calculateCompetitivenessByRow(shiftKey).storeKey;
      }
      return this.withoutCompetitiveInformation.storeKey;
    },
    formTableHandler({ value, key, field }) {
      const shiftKey = key;
      switch (field) {
        case 'totalSeats':
          this.handleTotalSeats(shiftKey, Number(value));
          break;
        case 'discountPercentage':
          this.handleDiscountPercentage(shiftKey, Number(value));
          break;
        case 'offeredPrice':
          this.handleOfferedPrice(shiftKey, Number(value));
          break;
        default:
      }
    },
    handleTotalSeats(shiftKey, totalSeats) {
      const targetRow = this.getRowByShift(shiftKey);
      if (!totalSeats) {
        targetRow.totalSeats.value = '';
      }
      if (seats.isValidPositiveSeats({ totalSeats })) {
        targetRow.totalSeats.value = String(totalSeats);
      }
    },
    handleOfferedPrice(shiftKey, offeredPrice) {
      const targetRow = this.getRowByShift(shiftKey);
      if (!offeredPrice) return this.resetRowPriceByShift(shiftKey);
      targetRow.offeredPrice.value = String(offeredPrice);
      this.autoCompleteDiscountPercentage(shiftKey);
      return this.updateCompetitivenessLabel(shiftKey);
    },
    handleDiscountPercentage(shiftKey, discountPercentage) {
      const targetRow = this.getRowByShift(shiftKey);
      if (!discountPercentage) return this.resetRowPriceByShift(shiftKey);
      targetRow.discountPercentage.value = String(discountPercentage);
      this.autoCompleteOfferedPrice(shiftKey);
      return this.updateCompetitivenessLabel(shiftKey);
    },
    autoCompleteOfferedPrice(shiftKey) {
      const targetRow = this.getRowByShift(shiftKey);
      const discountPercentage = targetRow.discountPercentage.value;
      const {
        fullPrice,
        commercialDiscount,
      } = this.universityOffersByShift[shiftKey];

      if (price.isValidDiscountPercentage({
        discountPercentage,
        commercialDiscount,
      })) {
        const offeredPrice = price.calculateOfferedPrice({
          fullPrice,
          discountPercentage,
          commercialDiscount,
        });
        targetRow.offeredPrice.hasError = false;
        targetRow.discountPercentage.hasError = false;
        targetRow.offeredPrice.value = String(offeredPrice);
      } else {
        targetRow.discountPercentage.hasError = true;
        targetRow.offeredPrice.value = '';
      }
    },
    autoCompleteDiscountPercentage(shiftKey) {
      const targetRow = this.getRowByShift(shiftKey);
      const offeredPrice = targetRow.offeredPrice.value;
      const {
        fullPrice,
        commercialDiscount,
      } = this.universityOffersByShift[shiftKey];

      if (this.isValidRowOfferedPrice(shiftKey)) {
        const discountPercentage = price.calculateDiscountPercentage({
          fullPrice,
          offeredPrice,
          commercialDiscount,
        });
        targetRow.offeredPrice.hasError = false;
        targetRow.discountPercentage.hasError = false;
        targetRow.discountPercentage.value = String(discountPercentage);
      } else {
        targetRow.offeredPrice.hasError = true;
        targetRow.discountPercentage.value = '';
      }
    },
    isValidRowOfferedPrice(shiftKey) {
      const targetRow = this.getRowByShift(shiftKey);
      const offeredPrice = targetRow.offeredPrice.value;
      return this.isValidOfferedPrice(shiftKey, offeredPrice);
    },
    isValidOfferedPrice(shiftKey, offeredPrice) {
      const {
        fullPrice,
        commercialDiscount,
      } = this.universityOffersByShift[shiftKey];
      return price.isValidOfferedPrice({
        offeredPrice,
        fullPrice,
        commercialDiscount,
      });
    },
    resetRowPriceByShift(shiftKey) {
      const targetRow = this.getRowByShift(shiftKey);
      targetRow.discountPercentage.value = '';
      targetRow.offeredPrice.value = '';
    },
    getRowByShift(shiftKey) {
      return this.tableRows.find(row => row.key === shiftKey);
    },
    setupTableRows() {
      this.tableRows = this.availableShifts.map((shiftKey) => {
        const {
          totalSeats,
          discountPercentage,
          offeredPrice,
        } = this.universityOffersByShift[shiftKey];
        return {
          key: shiftKey,
          shift: shiftRules.shiftTranslate(shiftKey),
          totalSeats: {
            value: totalSeats ? String(totalSeats) : null,
            type: 'number',
          },
          discountPercentage: {
            value: discountPercentage ? String(discountPercentage) : null,
            type: 'number',
          },
          offeredPrice: {
            value: offeredPrice ? String(offeredPrice) : null,
            type: 'number',
          },
          competitiveness: this.withoutCompetitiveInformation,
        };
      });
    },
    handleSemesterSelect(index) {
      this.$set(this.semestersOptions, index, {
        ...this.semestersOptions[index],
        selected: !this.semestersOptions[index].selected,
      });
    },
    handleOSCSelect(index) {
      this.$set(this.offerSpecialConditionsOptions, index, {
        ...this.offerSpecialConditionsOptions[index],
        selected: !this.offerSpecialConditionsOptions[index].selected,
      });
    },
    requestCaptations() {
      return axios.get(routes.selectableCaptationsPath())
        .then(({ data }) => data.data)
        .catch(props => console.log(props));
    },
    prev() {
      this.$emit('prev');
    },
    next() {
      if (this.isNextStepDisabled) return;

      if (navigator.onLine || navigator.onLine === undefined) {
        this.trackAdvance();
        this.updateSharedOfferStore();
        this.updateUniversityOfferStore();
        this.$emit('next');
      } else {
        this.notifyOfflineMessage();
      }
    },
    trackAdvance() {
      offerCreationTracking.advancedOfferMarketplaceDetailStep({
        havePriceSuggestion: this.havePriceSuggestion,
        usedPriceSuggestion: this.shiftsUsingSuggestedPrice,
      });
    },
    updateSharedOfferStore() {
      const offerSpecialConditions = this.offerSpecialConditionsOptions
        .filter(osc => osc.selected)
        .map(osc => osc.key);

      const enrollmentSemesters = this.semestersOptions
        .filter(semester => semester.selected)
        .map(semester => ({
          endDate: semester.endDate,
          semester: semester.key,
        }));

      this.updateSharedOffer({
        offerSpecialConditions,
        enrollmentSemesters,
      });
    },
    updateUniversityOfferStore() {
      this.availableShifts.forEach((shiftKey) => {
        const targetRow = this.getRowByShift(shiftKey);
        this.updateUniversityOfferData({
          shift: shiftKey,
          totalSeats: Number(targetRow.totalSeats.value),
          discountPercentage: Number(targetRow.discountPercentage.value),
          offeredPrice: Number(targetRow.offeredPrice.value),
          competitiveness: this.getCompetitivenessKeyByRow(shiftKey),
        });
      });
    },
    async setupSemestersOptions() {
      const semesterList = await this.requestCaptations();
      this.semestersOptions = semesterList.map((semester, index) => ({
        key: semester.captation,
        description: enrollmentSemester.getLabel({
          enrollmentSemester: semester.captation,
        }),
        endDate: semester.captation_end,
        selected: this.getInitialSelectedStateOfTheSemester(semester, index),
      }));
    },
    getInitialSelectedStateOfTheSemester(semester, index) {
      if (this.storeListSemestersKey.length === 0) {
        return index === 0;
      }
      return this.storeListSemestersKey.includes(semester.captation);
    },
  },
  computed: {
    ...mapState('SessionModule', ['university']),
    ...mapState('OfferCreationModule', [
      'universityOffersByShift',
      'sharedOffer',
      'sharedCampus',
      'sharedCourse',
    ]),
    ...mapGetters('OfferCreationModule', {
      availableShifts: 'shifts',
    }),
    havePriceSuggestion() {
      return Object.keys(this.suggestedByShift).length > 0;
    },
    shiftsUsingSuggestedPrice() {
      return this.availableShifts
        .filter((shiftKey) => {
          if (!this.suggestedByShift[shiftKey]) return false;
          const [, highestSuggestedPrice] = this.suggestedByShift[shiftKey].range;
          const targetRow = this.getRowByShift(shiftKey);
          const parsedOfferedPrice = Number(targetRow.offeredPrice.value);
          return parsedOfferedPrice <= highestSuggestedPrice;
        }).map(shiftRules.shiftTranslate);
    },
    storeListSemestersKey() {
      return this.sharedOffer.enrollmentSemesters.map(
        semesterDict => semesterDict.semester,
      );
    },
    tableHeaders() {
      return [
        {
          text: 'Turno',
          value: 'shift',
          type: 'text',
          align: 'left',
        },
        {
          text: 'Quantidades de vagas no QueroBolsa',
          value: 'totalSeats',
          type: 'form',
          align: 'left',
        },
        {
          text: 'Desconto do QueroBolsa (%)',
          value: 'discountPercentage',
          type: 'form',
          align: 'left',
        },
        {
          text: 'Valor com Desconto no QueroBolsa',
          value: 'offeredPrice',
          type: 'form',
          align: 'left',
        },
      ];
    },
    commercialDiscountList() {
      return this.availableShifts
        .map(shiftKey => this.universityOffersByShift[shiftKey].commercialDiscount);
    },
    isNextStepDisabled() {
      return !(this.isValidSemester && this.isValidTable);
    },
    isValidSemester() {
      return this.semestersOptions.some(semester => semester.selected);
    },
    isValidTable() {
      return this.availableShifts.every((shiftKey) => {
        const row = this.getRowByShift(shiftKey);
        const {
          fullPrice,
          commercialDiscount,
        } = this.universityOffersByShift[shiftKey];
        const isValidSeats = seats.isValidPositiveSeats({
          totalSeats: row.totalSeats.value,
        });
        const isValidDiscountPercentage = price.isValidDiscountPercentage({
          discountPercentage: row.discountPercentage.value,
          commercialDiscount,
        });
        const isValidOfferedPrice = price.isValidOfferedPrice({
          offeredPrice: row.offeredPrice.value,
          fullPrice,
          commercialDiscount,
        });
        return isValidSeats && isValidDiscountPercentage && isValidOfferedPrice;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@quero/zilla-core/src/utils/_index.scss";

.oc-marketplace-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 616px;
}

.oc-marketplace-details__card-marketplace {
  margin-bottom: var(--space-large);
}

.oc-marketplace-details__card-discount-title {
  margin-bottom: var(--space-medium);
  display: flex;
  align-items: baseline;
}

.oc-marketplace-details__card-discount-title-icon {
  margin-right: var(--space-small);
}

.oc-marketplace-details__card-table {
  --very-competitive-icon-color: var(--color-green-400);
  --uncompetitive-icon-color: var(--color-orange-500);
  --competitive-icon-color: var(--color-cyan-500);
  --without-competitive-information-color: var(--color-gray-300);
  margin-right: calc(-1 * var(--space-large));
  margin-left: calc(-1 * var(--space-large));
  margin-bottom: var(--space-large);
}

.oc-marketplace-details__card-footer-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: none;
  margin-bottom: var(--space-medium);
  margin-top: calc(var(--space-medium) * -1);

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $screen-desktop) {
    flex-direction: row;
    flex-flow: wrap;
  }
}

.oc-marketplace-details__card-footer-list-item {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-medium);
  margin-top: var(--space-medium);

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $screen-desktop) {
    margin-bottom: 0;
    margin-right: var(--space-medium);

    &:last-child {
      margin-right: 0;
    }
  }
}

.oc-marketplace-details__footer {
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-jumbo);
}
</style>
