<template>
  <z-input-field :label="label">
    <z-autocomplete
      :internal-search="false"
      :multiple="true"
      :options="educationGroupList"
      :value="educationGroupsSelected"
      @input="educationGroupInputHandle($event)"
      @remove="educationGroupRemoveHandle($event)"
      @select="educationGroupSelectHandle($event)"
      hide-selected
      label-by="text"
    />

    <input
      type="hidden"
      :value="JSON.stringify(this.educationGroupsSelected)"
      v-bind="$attrs"
    >
  </z-input-field>
</template>

<script>
import { searchEducationGroup } from '@shared/services/autocompletes/educational-group';

export default {
  props: {
    label: {
      type: String,
      default: 'Grupo educacional',
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      educationGroupList: [],
    };
  },
  computed: {
    educationGroupsSelected() {
      return this.value;
    },
  },
  methods: {
    educationGroupInputHandle($event) {
      this.requestSearchEducationGroup($event);
    },
    educationGroupSelectHandle($event) {
      if (!$event) return;

      this.$emit('select', $event);
    },
    educationGroupRemoveHandle($event) {
      this.$emit('remove', $event);
    },
    requestSearchEducationGroup(term) {
      searchEducationGroup(term).then(({ results }) => {
        this.educationGroupList = results;
      });
    },
  },
  mounted() {
    this.requestSearchEducationGroup('');
  },
};
</script>
