import $ from 'jquery';

export default class {
  constructor($parent, formData, template) {
    const isEmptyFilters = this.isEmptyFilters(formData);
    const data = { isEmptyFilters, ...formData };
    $parent.append(template({ data }));
    this.setupMultipleSelect();
  }

  isEmptyFilters(filters) {
    return Object.keys(filters).every((key) => {
      const field = filters[key];
      if (field.name === 'page') return true;
      return field.value === '' || field.value == null;
    });
  }

  setupMultipleSelect() {
    // multiple-select style setup
    $('.js-filter-multi-select').select2({
      theme: 'bootstrap',
      language: 'pt-BR',
    });
    $('.js-filter-multi-select').prop('disabled', true);
  }
}
