import AmplitudeService from '@shared/utils/amplitude-service';

const amplitude = AmplitudeService.getInstance();

const _logEvent = (eventName, options) => {
  const eventFullName = `[b2b_offer_creation_batch] ${eventName}`;
  amplitude.logEvent(eventFullName, options);
};

const viewedThePage = () => {
  _logEvent('viewed_the_page');
};

const createdSpreadsheet = () => {
  _logEvent('created_spreadsheet');
};

const publishedOffers = () => {
  _logEvent('published_offers');
};

const savedAndClose = () => {
  _logEvent('saved_and_close');
};

const closeSpreadsheetView = () => {
  _logEvent('close_spreadsheet_view');
};

const openSpreadsheetView = () => {
  _logEvent('open_spreadsheet_view');
};

const editedSpreadsheet = () => {
  _logEvent('edited_spreadsheet');
};

export const offerCreationBatchTracking = {
  viewedThePage,
  createdSpreadsheet,
  publishedOffers,
  savedAndClose,
  closeSpreadsheetView,
  openSpreadsheetView,
  editedSpreadsheet,
};
