import { Stepper } from '@stock/legacy/components/stepper';
import { ActionDetailStep } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/stock-action/steps/action-detail';
import { allValuesMustBePresent } from '@stock/utils/validator';

export class StockActionStepper extends Stepper {
  constructor({ offerTableItem, universityOfferId, permissions }) {
    super();
    allValuesMustBePresent()([universityOfferId]);
    this._validate({ offerTableItem });
    this.offerTableItem = offerTableItem;
    this.universityOfferId = universityOfferId;
    this.permissions = permissions;
    this._setup();
  }

  _validate({ offerTableItem }) {
    if (!offerTableItem) throw Error('offerTableItem is a required parameter');
  }

  _setup() {
    const step = new ActionDetailStep({
      offerTableItem: this.offerTableItem,
      permissions: this.permissions,
    });

    step.onHook('step.action', ({ params, stepperClass }) => {
      this._addStepsFromStepper(stepperClass, params);
      this.next();
    });

    this.addStep(step);
  }

  _addStepsFromStepper(TargetStepper, params) {
    const { universityOfferId } = this;
    const stepper = new TargetStepper({ ...params, universityOfferId });
    stepper.next = this.next.bind(this);
    stepper.previous = this.previous.bind(this);
    const steps = stepper.createSteps();
    steps.forEach(step => this.addStep(step));
    this._setupRemoveTargetStepsOnBack(steps);
  }

  _setupRemoveTargetStepsOnBack(steps) {
    const [firstStep] = steps;
    firstStep.onHook('previous', () => {
      steps.forEach(() => this.removeStep());
    });
  }
}
