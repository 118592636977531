import $ from 'jquery';
import Pagination from '@shared/legacy/components/pagination';
import Alert from '@shared/legacy/components/alert';
import SearchForm from '@shared/legacy/components/search-form';

const DECIMAL_BASE = 10;

class CourseInformationList {
  constructor(page) {
    this.page = page;
    this.loading = false;
    this.alert = new Alert();
    this.initCampusSelect();
    this.initShiftSelect();
    this.initKindSelect();
    this.initLevelSelect();
    this.initForm();
    this.initPagination();
    this.pagination.setPagination({
      total_pages: parseInt($('.js-total-pages').val(), DECIMAL_BASE),
      page_number: parseInt($('#search-page').val(), DECIMAL_BASE),
    });

    // load the courses async
    this.searchForm.submit();
  }

  showLoader() {
    $('.js-cog').show();
  }

  hideLoader() {
    this.loading = false;
    $('.js-cog').hide();
  }

  initShiftSelect() {
    this.initSelectInput($('.js-shifts-multi-select'), {
      placeholder: 'Turno do curso',
    });
  }

  initKindSelect() {
    this.initSelectInput($('.js-kinds-multi-select'), {
      placeholder: 'Modalidade do curso',
    });
  }

  initLevelSelect() {
    this.initSelectInput($('.js-levels-multi-select'), {
      placeholder: 'Nível do curso',
    });
  }

  initCampusSelect() {
    this.initSelectInput($('.js-campuses-multi-select'), {
      placeholder: 'Campus',
    });
  }

  initSelectInput($select, properties) {
    const baseProperties = { theme: 'bootstrap', language: 'pt-BR' };
    const allProperties = _.assign({}, properties, baseProperties);
    $select.select2(allProperties);
  }

  initForm() {
    this.searchForm = new SearchForm($('.js-course-form'));

    $(this.searchForm).on('submitting', () => {
      $('.js-course-table').html('');
      this.showLoader();
      this.alert.hide();
    });

    $(this.searchForm).on('submit:success', ({ html, page, pages }) => {
      this.hideLoader();

      $('.js-course-table').html(html);
      this.pagination.setPagination({ page_number: page, total_pages: pages });
    });

    $(this.searchForm).on('submit:error', () => {
      this.hideLoader();
      $('.js-course-table').html('');

      this.alert.showError('Cursos não encontrados');
    });
  }

  initPagination() {
    this.pagination = new Pagination({
      controlsContainer: '.js-controls-navigation',
      infoContainer: '.js-info-pagination',
      onChangePage: this.submitPage.bind(this),
    });
  }

  submitPage(newPage) {
    this.searchForm.val('page', newPage);
    this.searchForm.submit();
  }

  // global jQuery and $
  $(selector) {
    return $(selector, this.page);
  }
}

export default CourseInformationList;
