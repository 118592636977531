module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += 'Pág <span class="semi-bold">' +
((__t = ( pagination.page_number )) == null ? '' : __t) +
'</span> de ' +
((__t = ( pagination.total_pages )) == null ? '' : __t) +
'\n<span class="divider">|</span>\nResultados <span class="semi-bold">' +
((__t = ( pagination.entries_range )) == null ? '' : __t) +
'</span> de ' +
((__t = ( pagination.total_entries )) == null ? '' : __t) +
'\n';

}
return __p
}