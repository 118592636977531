<template>
  <div class="disclaimer-icon">
    <span
      :class="[
        'disclaimer-icon__wrapper',
        'fa-stack',
      ]"
    >
      <i
        :class="[
          'fas',
          'fa-circle',
          'fa-stack-2x',
          'disclaimer-icon__container'
        ]"
      />

      <i
        :class="[
          'fas',
          `fa-${name}`,
          'fa-stack-1x',
          'disclaimer-icon__main'
        ]"
      />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.disclaimer-icon {
  height: var(--size-extra-jumbo);
  position: relative;
  width: var(--size-extra-jumbo);
}

.disclaimer-icon__wrapper {
  font-size: var(--size-large);
  margin-top: var(--size-micro);
  margin-left: calc(-1 * var(--size-extra-small));
}

.disclaimer-icon__container {
  opacity: 0.4;
}

.disclaimer-icon__main {
  color: var(--color-white);
}
</style>
