<template>
  <div class="upa-modal-header">
    <div class="upa-modal-header__index-section">
      <z-title color="minor">
        {{ name }}
      </z-title>

      <div v-if="currentStepNumber && maxStepNumber">
        <z-text
          weight="bold"
          tag="span"
        >
          Passo {{ currentStepNumber }}
        </z-text>

        <z-text
          tag="span"
          color="minor"
        >
          de {{ maxStepNumber }}
        </z-text>
      </div>
    </div>

    <div class="upa-modal-header__description-section">
      <z-title size="medium">
        {{ title }}
      </z-title>

      <z-text
        color="minor"
        size="small"
        v-if="subtitle"
      >
        {{ subtitle }}
      </z-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    currentStepNumber: {
      type: Number,
      default: null,
    },
    maxStepNumber: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.upa-modal-header {
  margin-bottom: var(--space-jumbo);
}

.upa-modal-header__index-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-small);
}
</style>
