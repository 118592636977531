export default class DoubleLinkedNode {
  /**
   * @param {any} data
   */
  constructor(data) {
    this.data = data;
    this.next = null;
    this.previous = null;
  }

  /**
   * @param {DoubleLinkedNode} node
   *
   * @returns void
   */
  setNextNode(node) {
    this.next = node;
  }

  /**
   * @param {DoubleLinkedNode} node
   *
   * @returns void
   */
  setPreviousNode(node) {
    this.previous = node;
  }
}
