<template>
  <z-button
    variant="tertiary"
    class="chevron collapsed"
    data-toggle="collapse"
    :data-target="target"
    size="small"
  >
    <span class="chevron__text">
      <slot />
    </span>
    <z-icon
      class="chevron__icon"
      :icon="['far', 'chevron-up']"
      size="small"
    />
  </z-button>
</template>

<script>
export default {
  name: 'ButtonChevron',
  props: {
    target: {
      type: String,
      required: true,
    },
  },
};
</script>
