<template>
  <z-card
    class="payment-trigger-card"
    :class="{
      [`payment-trigger-card--selected`]: selected
    }"
    :shadow="isShadow"
    @mouseover.native="hover = true"
    @mouseleave.native="hover = false"
    @click.native="$emit('click')"
  >
    <z-icon
      :icon="icon"
      size="extra-large"
      class="payment-trigger-card__icon"
    />

    <div>
      <z-title
        size="extra-small"
        class="payment-trigger-card__title"
      >
        {{ title }}
      </z-title>

      <z-text
        size="small"
        color="minor"
        class="payment-trigger-card__description"
      >
        {{ description }}
      </z-text>
    </div>
  </z-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: Array,
      default() {
        return ['fas', 'check'];
      },
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { hover: false };
  },
  computed: {
    isShadow() {
      if (this.selected) return 'higher';
      return this.hover ? 'high' : null;
    },
  },
};
</script>

<style lang="scss">
.payment-trigger-card {
  cursor: pointer;
  display: flex;

  &:hover {
    border: var(--size-border-width-small) var(--color-blue-200) solid;
  }

  &--selected {
    border: var(--size-border-width-small) var(--color-blue-500) solid;
  }
}

.payment-trigger-card__icon {
  margin-right: var(--space-large);
}

.payment-trigger-card__title {
  margin-bottom: var(--space-small);
}

.payment-trigger-card__description {
  margin-bottom: 0;
}
</style>
