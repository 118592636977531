<template>
  <GridContainer class="step-03-payment-trigger">
    <GridItem
      :column="4"
      :row="2"
      v-for="(element, index) in items"
      :key="index"
    >
      <PaymentTriggerCard
        :description="element.description"
        :icon="element.icon"
        :title="element.title"
        :selected="paymentTriggerSelected === element.value"
        @click="paymentTriggerSelected = element.value"
        class="step-03-payment-trigger__card"
      />
    </GridItem>
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import PaymentTriggerCard from './payment-trigger-card.vue';

export default {
  components: {
    PaymentTriggerCard,
    GridContainer,
    GridItem,
  },
  computed: {
    items() {
      return [
        {
          title: 'Captado',
          description: 'Candidato que pagou a pré-matrícula.',
          icon: ['fal', 'file-invoice-dollar'],
          value: 'pre_enrollment_fee_paid',
        },
        {
          title: 'Admitido',
          description: 'Candidato no fluxo da Admissão Digital pronto para a matrícula na IES. ',
          icon: ['fal', 'user-check'],
          value: 'pre_enrolled',
        },
        {
          title: 'Matriculado',
          description: 'Aluno matriculado na IES e pronto para estudar.',
          icon: ['fal', 'university'],
          value: 'enrolled',
        },
        {
          title: 'Mensalidade',
          description: 'Comissão na mensalidade paga pelo aluno. Válido para Quero Pago ou Taxa de Matrícula.',
          icon: ['fal', 'calendar-day'],
          value: 'first_installment_paid',
        },
        {
          title: 'Rematriculado',
          description: 'Aluno que realizou a rematricula.',
          icon: ['fal fa-flip-horizontal', 'undo'],
          value: 'reenrolled',
        },
      ];
    },
    paymentTriggerSelected: {
      get() {
        return this.$store.state.QueroTurboActivationModalModule.commissionModelRule.paymentTrigger;
      },
      set(value) {
        this.$store.commit('QueroTurboActivationModalModule/setCommissionPaymentTrigger', value);
      },
    },
  },
};
</script>

<style>
.step-03-payment-trigger {
  --grid-padding: 0;
  margin-bottom: 100px;
}

.step-03-payment-trigger__card {
  max-height: 175px;
  height: 100%;
}
</style>
