module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<form accept-charset="UTF-8" action="/avaliacao-dos-alunos/replies/' +
((__t = ( reply.id )) == null ? '' : __t) +
'" data-remote="true" enctype="multipart/form-data" method="post">\n  <div class="form-group">\n    <label class="control-label" for="reply_text">EDITAR A RESPOSTA</label>\n    <input id="reply_survey_form_id" name="reply[survey_form_id]" type="hidden" value="' +
((__t = ( reply.form_id )) == null ? '' : __t) +
'">\n    <input id="reply_reply_id" name="reply[reply_id]" type="hidden" value="' +
((__t = ( reply.id )) == null ? '' : __t) +
'">\n    <input type="hidden" class="original-text" value="' +
__e( reply.text ) +
'">\n    <textarea class="form-control reply-text" id="reply_text" name="reply[text]" placeholder="Escreva sua resposta...">' +
__e( reply.text ) +
'</textarea>\n    <span class="error-message hidden">Erro ao enviar resposta. Tente novamente.</span>\n  </div>\n  <div class="footer-buttons">\n    <button type="button" class="btn btn-secondary reverse cancel-button">Cancelar</button>\n    <button type="submit" class="btn btn-primary btn-save-reply" disabled="true">Salvar resposta</button>\n  </div>\n</form>\n';

}
return __p
}