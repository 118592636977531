import { QueroAlunosServices } from '@university-panel/services';

const UPAAbilityAPI = (_Render, _globalOptions) => ({
  async can(actionName, subjectName) {
    return QueroAlunosServices
      .V1
      .Permission
      .can({ actionName, subjectName });
  },
});

export { UPAAbilityAPI };
