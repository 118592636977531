<template>
  <z-modal
    :open="open"
    :closable="closable"
    @closed="$emit('closed')"
    class="upa-helper-modal"
    :class="{
      [`upa-helper-modal--size-${size}`]: size,
    }"
  >
    <transition
      name="upa.upa-helper-modal__animation"
      mode="out-in"
    >
      <component
        :is="currentComponent"
        v-bind="options"
        @right-button-click="$emit('action', 'accept')"
        @left-button-click="$emit('action', 'cancel')"
        @click="$emit('action', $event)"
        @closed="$emit('closed')"
        @action="$emit('action', $event)"
      />
    </transition>
  </z-modal>
</template>

<script>
import {
  FeedbackCardSuccess,
  FeedbackCardError,
  FeedbackCardLoading,
} from '@stock/components/feedback-components';
import UPAHelperModalConfirmation from './upa-helper-modal-confirmation.vue';

export default {
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: 'confirmation',
    },
    size: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentOptions() {
      return {
        confirmation: UPAHelperModalConfirmation,
        success: FeedbackCardSuccess,
        error: FeedbackCardError,
        loading: FeedbackCardLoading,
        custom: this.options.component,
      };
    },
    currentComponent() {
      return this.componentOptions[this.mode];
    },
  },
};
</script>

<style lang="scss">
@import "@quero/zilla-core/src/utils/_index.scss";

.upa-helper-modal {
  --modal-index: 1001;
}

.upa-helper-modal--size-medium {
  .z-modal__wrapper {
    @media (min-width: $screen-desktop) {
      --modal-width: 1216px;
      width: 100%; // TODO: Remove this when zilla updates to customize width
    }
  }
}


.upa-helper-modal__animation,
.upa-helper-modal__animation-leave-active {
  transition: opacity .3s ease;
}

.upa-helper-modal__animation-enter,
.upa-helper-modal__animation-leave-to {
  opacity: 0;
}
</style>
