<template>
  <GridContainer>
    <GridItem>
      <HeaderBox title="Nova negociação" />
    </GridItem>

    <GridItem>
      <CreateDealPainel />
    </GridItem>
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { HeaderBox } from '@shared/components/header-box';
import { CreateDealPainel } from '@quero-turbo/modules/deal-components';

export default {
  components: {
    GridContainer,
    GridItem,
    HeaderBox,
    CreateDealPainel,
  },
};
</script>
