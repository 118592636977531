import EventEmitter from 'events';
import DoubleLinkedList from '@shared/utils/structs/double-linked-list';
import Step from './step';

export default class Stepper {
  constructor() {
    this.linkedList = new DoubleLinkedList();
    this.event = new EventEmitter();
  }

  /**
   * @returns {String}
   */
  renderCurrentStep() {
    const previousAttributes = this.getAttributesFromPreviousSteps();
    return this.getCurrentStep().render(previousAttributes);
  }

  /**
   * @returns void
   */
  validateStep(step) {
    const isValid = step instanceof Step;
    if (!isValid) {
      throw new Error('Should use Class::Step for step');
    }
  }

  /**
   * @returns {Stepper}
   */
  addStep(step) {
    this.validateStep(step);
    this.linkedList.addNode(step);
    return this;
  }

  /**
   * @returns void
   */
  removeStep() {
    this.linkedList.removeTailNode();
  }

  /**
   * @returns {Step}
   */
  getCurrentStep() {
    return this.linkedList.getCurrentNode();
  }

  /**
   * @returns {Step}
   */
  next() {
    const node = this.linkedList.next();
    this.event.emit('step.changed');
    return node;
  }

  /**
   * @returns {Step}
   */
  previous() {
    const node = this.linkedList.previous();
    this.event.emit('step.changed');
    return node;
  }

  /**
   * @returns {Object}
   */
  getAttributesFromPreviousSteps() {
    let attributes = {};
    let currentNode = this.linkedList.getCurrentNode().previous;
    while (currentNode !== null) {
      attributes = {
        ...currentNode.getAttributes(),
        ...attributes,
      };
      currentNode = currentNode.previous;
    }
    return attributes;
  }

  /**
   * @returns {Stepper}
   */
  on(eventName, callback) {
    this.event.on(eventName, callback);
    return this;
  }
}
