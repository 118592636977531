<template>
  <div class="oc-step-header">
    <div class="oc-step-header__step">
      <z-title color="minor">
        Criação de ofertas
      </z-title>

      <div>
        <z-text
          weight="bold"
          tag="span"
        >
          Passo {{ currentStepNumber }}
        </z-text>

        <z-text
          tag="span"
          color="minor"
        >
          de {{ lastStepNumber }}
        </z-text>
      </div>
    </div>

    <z-title size="medium">
      {{ name }}
    </z-title>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    currentStepNumber: {
      type: Number,
      required: true,
    },
    lastStepNumber: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.oc-step-header {
  // TODO: Move this color style to the <body> tag
  color: var(--body-color);
  margin-bottom: var(--space-jumbo);
}

.oc-step-header__step {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-small);
}
</style>
