var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"disclaimer-icon"},[_c('span',{class:[
      'disclaimer-icon__wrapper',
      'fa-stack' ]},[_c('i',{class:[
        'fas',
        'fa-circle',
        'fa-stack-2x',
        'disclaimer-icon__container'
      ]}),_vm._v(" "),_c('i',{class:[
        'fas',
        ("fa-" + _vm.name),
        'fa-stack-1x',
        'disclaimer-icon__main'
      ]})])])}
var staticRenderFns = []
export { render, staticRenderFns }