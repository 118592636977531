import '@shared/utils/select2';
import { isNil } from 'lodash';
import $ from 'jquery';
import queryString from 'query-string';

import { LocalStorageStock } from '@stock/utils/local-storage-stock';
import { OfferListPage } from '@stock/pages';
import Alert from '@shared/legacy/components/alert';
import OfferBatchActionsMenu from '@stock/legacy/components/offer-batch-actions-menu';
import Pagination from '@shared/legacy/components/pagination';
import SearchForm from '@shared/legacy/components/search-form';
import { OffersTable } from '@stock/legacy/modules/offers-table';
import {
  setupDiscountField,
  fixDiscountFieldValue,
  Select2Fields,
} from '@stock/legacy/modules/university-offer-list';
import { ZToast } from '@shared/legacy/components/zilla/z-toast';
import AmplitudeService from '@shared/utils/amplitude-service';

const DECIMAL_BASE = 10;
const EXPORT_OFFERS_URL = 'ofertas/exportar';
const FILTERS_COLLAPSE_STORAGE = '[offer_list_page] filters_collapse_state';
const EXPORT_OFFERS_LIMIT = 5000;
const AMPLITUDE = AmplitudeService.getInstance();

class UniversityOfferList {
  constructor(page) {
    if (page.length === 0) return null;
    this.initialize = this.initialize.bind(this, page);
    return new OfferListPage({ pageInstance: this });
  }

  initialize(page) {
    this.page = page;
    this.loading = false;
    this.alert = new Alert();
    this.offersTable = new OffersTable();
    Select2Fields.build();
    this.initForm();
    this.initPagination();
    this.pagination.setPagination({
      total_pages: parseInt($('.js-total-pages').val(), DECIMAL_BASE),
      page_number: parseInt($('#search-page').val(), DECIMAL_BASE),
    });
    this.startRestockFromUrl();
    this.searchForm.submit();

    this.setupFiltersCollapse();
    this.initOfferBatchActionsMenu();
    this.bindClearFiltersButton();
    this.setupExportOffers();
  }

  startRestockFromUrl() {
    const params = queryString.parse(window.location.search);
    const restockValue = parseInt(params.restock, DECIMAL_BASE);
    if (!Number.isNaN(restockValue)) {
      this.offerBatchActionsMenu.renderBatchRestockModal(restockValue);
    }
  }

  initOfferBatchActionsMenu() {
    this.offerBatchActionsMenu = new OfferBatchActionsMenu(this.searchForm, this.alert);
    $('.js-change-discount').on('click', () => this.offerBatchActionsMenu.renderBatchStockDiscountChangeModal());
    $('.js-batch-restock').on('click', () => this.offerBatchActionsMenu.renderBatchRestockModal());
    $('.js-create-window-offer').on('click', () => this.offerBatchActionsMenu.renderBatchCreateWindowOffersModal());
    $('.js-disable-offer').on('click', () => this.offerBatchActionsMenu.renderBatchDisableOffersModal());
    $('.js-change-end-offer').on('click', () => this.offerBatchActionsMenu.renderBatchEndChangeModal());
  }

  setupFiltersCollapse() {
    this.setInitialFiltersCollapse();

    $('.js-filter-collapse-button').on('click', () => {
      if (this.isFilterExpanded()) {
        LocalStorageStock.set(FILTERS_COLLAPSE_STORAGE, 'hide');
      } else {
        LocalStorageStock.set(FILTERS_COLLAPSE_STORAGE, 'show');
      }
    });
  }

  setInitialFiltersCollapse() {
    const initialState = LocalStorageStock.get(FILTERS_COLLAPSE_STORAGE);

    if (initialState) {
      $('.js-collapse-filters').collapse(initialState);
    }
  }

  isFilterExpanded() {
    return $('.js-collapse-filters').hasClass('in');
  }

  showLoader() {
    $('.js-cog').show();
  }

  hideLoader() {
    this.loading = false;
    $('.js-cog').hide();
  }

  initForm() {
    this.searchForm = new SearchForm($('#js-universityoffer-form'));
    this.setupDiscountField();
    this.searchFormHandle();
  }

  searchFormHandle() {
    $(this.searchForm).on('submitting', () => {
      this.searchFormSubmittingHandle();
    });
    $(this.searchForm).on('submit:success', (data) => {
      this.searchFormSubmitSuccessHandle(data);
    });
    $(this.searchForm).on('submit:error', () => {
      this.searchFormSubmitErrorHandle();
    });
  }

  searchFormSubmittingHandle() {
    this.showLoader();
    this.alert.hide();
    this.pushHistory(this.searchForm.serializeArray());
  }

  searchFormSubmitSuccessHandle({
    html, page, pages, entriesCount, offersCount,
  }) {
    this.hideLoader();
    $('#js-offer-table').html(html);
    this.pagination.setPagination({ page_number: page, total_pages: pages });
    this.offersTable.reloadTable(entriesCount, offersCount);
    this.offersCount = offersCount;
  }

  searchFormSubmitErrorHandle() {
    this.hideLoader();
    $('#js-offer-table').html('');
    this.alert.showError('Erro ao filtrar ofertas');
  }

  setupDiscountField() {
    const $discount = this.searchForm.getFieldByLabel('discount');
    setupDiscountField($discount);
    this.searchForm.$form.on('submit', () => fixDiscountFieldValue($discount));
  }

  setupExportOffers() {
    $('.js-export-offers').on('click', (event) => {
      event.preventDefault();
      if (this.offersCount > EXPORT_OFFERS_LIMIT) {
        ZToast.showAlert(`A quantidade de ofertas filtradas ultrapassa o limite de ${EXPORT_OFFERS_LIMIT}`, {
          type: 'danger',
        });
        AMPLITUDE.logEvent('Export offers exceed limit', { offersCount: this.offersCount });
      } else {
        AMPLITUDE.logEvent('Export offers', { offersCount: this.offersCount });
        window.location.assign(`${EXPORT_OFFERS_URL}?${this.searchForm.serialize()}`);
      }
    });
  }

  initPagination() {
    this.pagination = new Pagination({
      controlsContainer: '.js-controls-navigation',
      infoContainer: '.js-info-pagination',
      onChangePage: this.submitPage.bind(this),
    });
  }

  submitPage(newPage) {
    this.searchForm.val('page', newPage);
    this.searchForm.submit();
  }

  // global jQuery and $
  $(selector) {
    return $(selector, this.page);
  }

  pushHistory(serializedArray) {
    const formString = _(serializedArray)
      .reject(e => e.value === '' || isNil(e.value) || e.name === 'sl[partial]')
      .sortBy(e => !!e.name.match(/utf8|partial|continue/)) // No, I don't get it either
      .map(({ name, value }) => `${name}=${value}`)
      .join('&');

    if (window.history && window.history.pushState) {
      window.history.pushState({}, '', encodeURI(`/ofertas?${formString}`));
    }
  }

  bindClearFiltersButton() {
    $('.js-clear-filters').on('click', (event) => {
      event.preventDefault();
      this.clearFilter();
      this.submitSearchForm();
    });
  }

  clearFilter() {
    this.searchForm.$fields.val('');
    this.searchForm.val('page', 1);
    Select2Fields.clear();
  }

  submitSearchForm() {
    this.searchForm.submit();
  }
}

export default UniversityOfferList;
