import { QueroDealsV1API } from '@quero-turbo/api/quero-deals-v1-api';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Create a BRG
 *
 * @param {Object} params
 * @property {string} params.name The name of brg
 * @property {number} params.dealId Id of deal
 * @property {number} params.priority Number of priority
 * @property {boolean} params.pilot Pilot
 *
 * @typedef Success
 * @property {string} message
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function create({
  name, dealId, priority, pilot,
}) {
  return QueroDealsV1API.BRG.createBRG({
    name,
    dealId,
    priority,
    pilot,
  })
    .then(({ data }) => data)
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[createBRG] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

export {
  create,
};
