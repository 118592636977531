<template>
  <z-input-field :label="label">
    <z-autocomplete
      :internal-search="false"
      :multiple="true"
      :options="canonicalCoursesList"
      :value="canonicalCoursesSelected"
      @input="canonicalCourseInputHandle($event)"
      @remove="canonicalCourseRemoveHandle($event)"
      @select="canonicalCourseSelectHandle($event)"
      hide-selected
      label-by="text"
    />

    <input
      :value="JSON.stringify(canonicalCoursesSelected)"
      type="hidden"
      v-bind="$attrs"
    >
  </z-input-field>
</template>

<script>
import { searchCanonicalCourse } from '@shared/services/autocompletes/cannonical-course';

export default {
  props: {
    label: {
      type: String,
      default: 'Curso(s) Canonico(s)',
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      canonicalCoursesList: [],
    };
  },
  computed: {
    canonicalCoursesSelected() {
      return this.value;
    },
  },
  methods: {
    canonicalCourseInputHandle($event) {
      this.requestSearchCanonicalCourse($event);
    },
    canonicalCourseSelectHandle($event) {
      if (!$event) return;

      this.$emit('select', $event);
    },
    canonicalCourseRemoveHandle($event) {
      this.$emit('remove', $event);
    },
    requestSearchCanonicalCourse(term) {
      searchCanonicalCourse(term).then(({ results }) => {
        this.canonicalCoursesList = results;
      });
    },
  },
  mounted() {
    this.requestSearchCanonicalCourse('');
  },
};
</script>
