const SELECT2_INPUTS = [
  {
    selector: '.js-canonical-courses-multi-select',
    placeholder: 'Digite ou selecione o curso',
    theme: 'bootstrap',
    ajax: {
      url: '/util/autocomplete/canonical_courses',
      delay: 250,
      data: params => ({
        term: params.term,
      }),
    },
  },
  {
    selector: '.js-campuses-multi-select',
    placeholder: 'Selecione o campus',
  },
  {
    selector: '.js-levels-multi-select',
    placeholder: 'Selecione o nível',
  },
  {
    selector: '.js-kinds-multi-select',
    placeholder: 'Selecione a modalidade',
  },
  {
    selector: '.js-shifts-multi-select',
    placeholder: 'Selecione o turno',
  },
  {
    selector: '.js-semesters-multi-select',
    placeholder: 'Selecione a captação',
  },
  {
    selector: '.js-statuses-multi-select',
    placeholder: 'Selecione o status',
    maximumSelectionLength: 1,
  },
  {
    selector: '.js-discount-groups',
    placeholder: 'Selecione o grupo de desconto',
    maximumSelectionLength: 1,
  },
];

const build = () => {
  SELECT2_INPUTS.forEach(({ selector, ...properties }) => {
    const baseProperties = { theme: 'bootstrap', language: 'pt-BR' };
    const allProperties = { ...baseProperties, ...properties };
    $(selector).select2(allProperties);
  });
};

const clear = () => {
  SELECT2_INPUTS.forEach(({ selector }) => {
    $(selector).val('').trigger('change');
  });
};

export default {
  build,
  clear,
};
