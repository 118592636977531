<template>
  <z-input-field :label="label">
    <z-autocomplete
      :internal-search="false"
      :multiple="true"
      :options="citiesList"
      :value="citiesSelected"
      @input="citiesInputHandle($event)"
      @remove="citiesRemoveHandle($event)"
      @select="citiesSelectHandle($event)"
      hide-selected
      label-by="text"
    />

    <input
      :value="JSON.stringify(citiesSelected)"
      type="hidden"
      v-bind="$attrs"
    >
  </z-input-field>
</template>

<script>
import { searchCity } from '@shared/services/autocompletes/city';

export default {
  props: {
    label: {
      type: String,
      default: 'Cidade(s)',
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      citiesList: [],
    };
  },
  computed: {
    citiesSelected() {
      return this.value;
    },
  },
  methods: {
    citiesInputHandle($event) {
      this.requestSearchCity($event);
    },
    citiesSelectHandle($event) {
      if (!$event) return;

      this.$emit('select', $event);
    },
    citiesRemoveHandle($event) {
      this.$emit('remove', $event);
    },
    requestSearchCity(term) {
      searchCity(term).then(({ results }) => {
        this.citiesList = results;
      });
    },
  },
  mounted() {
    this.requestSearchCity('');
  },
};
</script>
