import { InputField } from '@shared/legacy/components/form/input-field';
import { emailValidation } from '@shared/utils/validators/email';

export class EmailInputField extends InputField {
  constructor($inputField) {
    super($inputField);
    this.setupKeyUpEvent($inputField);
    this.setupChangeEvent($inputField);
  }

  setupKeyUpEvent($emailTextField) {
    $emailTextField.on('input', () => {
      super.removeError();
      $(this).trigger('valid');
    });
  }

  setupChangeEvent($emailTextField) {
    $emailTextField.change(() => {
      const isValid = emailValidation($emailTextField.val());

      if (isValid) {
        super.removeError();
        $(this).trigger('valid');
      } else {
        super.showError('Insira um e-mail válido');
        $(this).trigger('error');
      }
    });
  }
}
