
function tryMatch(regex, text) {
  const match = regex.exec(text);
  if (match && match.length > 2) {
    return match[2];
  }
  return null;
}

function tryMatchMulti(obj, param) {
  const match = tryMatch(/(.*?)\[(.*?)\]\[\]/g, param.name);
  if (match) {
    if (obj[match]) {
      return [match, [...obj[match], param.value]];
    }
    return [match, [param.value]];
  }
  return null;
}

function tryMatchSingle(obj, param) {
  const match = tryMatch(/(.*?)\[(.*?)\]/g, param.name);
  if (match) {
    return [match, param.value];
  }
  return null;
}

function matchValue(obj, param) {
  return [param.name, param.value];
}


export default function formToJson(form) {
  const obj = {};
  form.serializeArray().forEach((param) => {
    const [name, val] = tryMatchMulti(obj, param) || tryMatchSingle(obj, param) || matchValue(obj, param);
    obj[name] = val;
  });
  return obj;
}
