<template>
  <div
    class="datastudio-dashboard"
  >
    <iframe
      class="datastudio-dashboard__iframe"
      :src="finalUrl"
      frameborder="0"
      allowfullscreen
      v-on="$listeners"
    />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    params: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    finalUrl() {
      if (!this.params) return this.url;

      if (typeof this.params === 'string') return `${this.url}?params=${this.params}`;

      // https://developers.google.com/datastudio/connector/data-source-parameters
      return `${this.url}?config=${encodeURIComponent(JSON.stringify(this.params))}`;
    },
  },
};
</script>

<style>
.datastudio-dashboard {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.datastudio-dashboard__iframe {
  border: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
</style>
