import $ from 'jquery';
import Vue from 'vue';
import { debounce } from 'lodash';
import listingTemplate from '@university-panel/legacy/components/templates/user_search.html.ejs';
import paginationInfoTemplate from '@university-panel/legacy/components/templates/user_search_pagination.html.ejs';

class UserSearch {
  constructor(page) {
    this.page = page;
    this.loading = false;
    this.initForm();
    this.initPagination();
    this.initSort();
    this.initUniversitySelect();
  }

  getSearchForm() {
    return this.$('form#user-search-form');
  }

  getUniversitiesSelect() {
    return this.$('.js-university-select');
  }

  initForm() {
    const form = this.getSearchForm();
    const universitySelect = this.getUniversitiesSelect();

    form.submit((event, { keepPagination } = {}) => {
      this.$('.cog').show();
      if (!keepPagination) {
        this.$('#university_user_search_page', form).val(1);
      }
    });

    form.on('ajax:success', (_event, {
      university_users, pagination, sort, sort_desc, // eslint-disable-line
    }) => {
      if (this.vueInstanceTable) {
        this.vueInstanceTable.$destroy();
      }
      this.vueInstanceTable = new Vue({
        el: this.$('table').selector,
        template: listingTemplate({ university_users, sort, sort_desc }),
        mounted: () => {
          this.$('.pagination-info .dynamic').html(paginationInfoTemplate({ pagination }));
          this.$('.pagination .current-page').html(pagination.page_number);
          this.paginationControl(pagination.page_number, pagination.total_pages);
          this.loading = false;
          this.$('.cog').hide();
        },
      });
    });

    form.on('reset', debounce(() => {
      universitySelect.val('').trigger('change');
      universitySelect.select2('data', null);

      form.submit();
    }));
  }

  initPagination() {
    const pagination = this.$('.pagination');
    const form = this.getSearchForm();
    const pageInput = this.$('#university_user_search_page', form);

    function submitPage(sum) {
      const newPage = parseInt(pageInput.val(), 10) + sum;
      pageInput.val(newPage);

      form.trigger('submit', { keepPagination: true });
    }

    pagination.on('click', '.next:not(.disabled)', () => {
      if (this.loading === false) {
        this.loading = true;
        submitPage(1);
      }
    });

    pagination.on('click', '.prev:not(.disabled)', () => {
      if (this.loading === false) {
        this.loading = true;
        submitPage(-1);
      }
    });
  }

  paginationControl(currentPage, totalPages) {
    const pagination = this.$('.pagination');

    function disableButton(query) {
      const button = $(query, pagination);
      button.addClass('disabled');
    }

    function enabledButton(query) {
      const button = $(query, pagination);
      button.removeClass('disabled');
    }

    if (currentPage === 1) {
      disableButton('.prev');

      if (totalPages > 1) {
        enabledButton('.next');
      } else {
        disableButton('.next');
      }
    } else if (currentPage >= totalPages) {
      disableButton('.next');
      enabledButton('.prev');
    } else {
      enabledButton('.next, .prev');
    }
  }

  initSort() {
    const sorting = this.$('.table.table-hover');
    const form = this.getSearchForm();
    const sortInput = this.$('#university_user_search_sort', form);
    const sortDescInput = this.$('#university_user_search_sort_desc', form);

    function submitSort(sort, sortDesc) {
      sortInput.val(sort);
      sortDescInput.val(sortDesc);
      form.trigger('submit', { keepPagination: false });
    }

    sorting.on('click', '.sort', (element) => {
      if (this.loading === false) {
        this.loading = true;
        const newValue = $(element.target).attr('data-sort');
        const desc = !$(element.target).hasClass('desc');
        submitSort(newValue, desc);
      }
    });
  }

  initUniversitySelect() {
    const universitySelect = this.getUniversitiesSelect();

    universitySelect.select2({
      theme: 'bootstrap',
      ajax: {
        url: '/util/autocomplete/universities',
        delay: 250,
      },
      language: 'pt-BR',
      templateResult: this.formatSelect2Result,
      placeholder: 'Universidades',
    });
  }

  formatSelect2Result(data) {
    if (!data.id) { return data.text; }
    let $result;
    if (data.text !== data.hint) {
      $result = $(`<span>${data.text}</span><span class=hint> - ${data.hint}</span>`);
    } else {
      $result = $(`<span>${data.text}</span>`);
    }
    return $result;
  }

  $(selector) {
    return $(selector, this.page);
  }
}

export default UserSearch;
