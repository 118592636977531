<template>
  <z-input-field :label="label">
    <z-autocomplete
      :internal-search="false"
      :multiple="true"
      :options="enrollmentSemesterList"
      :value="enrollmentSemesterSelected"
      @remove="enrollmentSemesterRemoveHandle($event)"
      @select="enrollmentSemesterSelectHandle($event)"
      hide-selected
    />

    <input
      :value="enrollmentSemesterSelected"
      type="hidden"
      v-bind="$attrs"
    >
  </z-input-field>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Semestre',
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      enrollmentSemesterList: ['2021.1', '2021.2'],
    };
  },
  computed: {
    enrollmentSemesterSelected() {
      return this.value;
    },
  },
  methods: {
    enrollmentSemesterSelectHandle($event) {
      if (!$event) return;

      this.$emit('select', $event);
    },
    enrollmentSemesterRemoveHandle($event) {
      this.$emit('remove', $event);
    },
  },
};
</script>
