const customFields = [
  { label: 'Portador de necessidades especiais', key: 'pne' },
  { label: 'Cor/Raça', key: 'color_or_race' },
  { label: 'Estado civil', key: 'civil_status' },
  { label: 'Nome do cônjuge', key: 'spouse_name' },
  { label: 'Nome do pai', key: 'name_of_father' },
  { label: 'Nome da mãe', key: 'name_of_mother' },
  { label: 'Naturalidade/UF', key: 'naturalized_from_uf' },
  { label: 'Naturalidade (cidade onde nasceu)', key: 'naturalized_from' },
  { label: 'Naturalidade Código Ibge (código ibge da cidade)', key: 'naturalized_from_city_ibge' },
  { label: 'Nacionalidade', key: 'nationality' },
  { label: 'Tipo de escola que concluiu o Ensino Médio', key: 'type_of_school_completed_high_school' },
  { label: 'Ano de conclusão do 2º grau', key: 'high_school_completion_year' },
  { label: 'Curso de graduação concluído', key: 'graduation_course_name' },
  { label: 'Ano de conclusão da graduação', key: 'graduation_year' },
  { label: 'Instituição em que se graduou', key: 'graduation_ies_name' },
  { label: 'Cidade da instituição que se graduou', key: 'graduation_ies_city' },
  { label: 'Estado da instituição que se graduou', key: 'graduation_ies_uf' },
  { label: 'Enade', key: 'enade' },
  { label: 'Qual a faixa de renda da sua família', key: 'family_income' },
  { label: 'Você já começou alguma outra graduação e não concluiu', key: 'not_completed_another_undergraduate' },
  { label: 'Seus pais possuem ensino superior completo', key: 'parents_have_higher_education' },
  { label: 'Em qual tipo de instituição você estudou a maior parte da sua vida', key: 'main_education_kind' },
  { label: 'Qual o seu principal meio de transporte', key: 'main_transport_method' },
  { label: 'Você possui computador em casa', key: 'has_computer_at_home' },
  { label: 'Você pretende trabalhar enquanto faz a graduação', key: 'will_work_while_studying' },
  { label: 'Você trabalha atualmente na área de escolha do seu curso', key: 'work_on_course_area' },
  { label: 'Quanto, em média, você gasta com celular por mês', key: 'mobile_monthly_spents' },
  { label: 'Qual a velocidade da internet na sua casa', key: 'home_internet_speed' },
];

const getLabel = key => customFields.find(
  customField => customField.key === key,
).label;

export const AdmissionCustomFieldsBusiness = {
  getLabel,
};
