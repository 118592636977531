import { UniversityPanelSharedAPI } from '@shared/api/university-panel-shared-api';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Search canonical course with specific term
 *
 * @param {Object} params
 * @property {number} params.term The term to be searched
 *
 * @typedef Success
 * @property {string} message
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function searchCanonicalCourse(term) {
  return UniversityPanelSharedAPI.Autocompletes.searchCanonicalCourse({ term })
    .then(({ data }) => (data))
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[searchCanonicalCourse] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

export {
  searchCanonicalCourse,
};
