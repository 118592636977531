const statusDictionary = {
  editing: {
    variant: 'info',
    text: 'Editando',
  },
  pending: {
    variant: 'warning',
    text: 'Configurando',
  },
  active: {
    variant: 'success',
    text: 'Ativo',
  },
  finished: {
    variant: 'neutral',
    text: 'Finalizado',
  },
  canceled: {
    variant: 'danger',
    text: 'Cancelado',
  },
  failed: {
    variant: 'danger',
    text: 'Erro',
  },
  deactivating: {
    variant: 'warning',
    text: 'Desabilitando',
  },
  undefined: {
    variant: 'neutral',
    text: 'Indefinido',
  },
};

const getStatusDictionary = value => statusDictionary[value] || statusDictionary.undefined;

export const ProductConfigBusiness = {
  getStatusDictionary,
};
