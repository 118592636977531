import { getField, updateField } from 'vuex-map-fields';
import { QueroDealsServices } from '@quero-turbo/services';
import PromiseRetry from 'promise-retry';
import { ZToast } from '@shared/legacy/components/zilla/z-toast';

const MAX_STEP_COUNT = 3;

const state = () => ({
  status: 'loading',
  businessRuleGroup: null,
  productConfigs: null,
  dealSelected: null,
});

const mutations = {
  updateField,
  setStatus(state, newState) {
    state.status = newState;
  },
  setBusinessRuleGroup(state, newState) {
    state.businessRuleGroup = newState;
  },
  setProductConfig(state, newState) {
    state.productConfigs = newState;
  },
  setDealSelected(state, newState) {
    state.dealSelected = newState;
  },
};

const getters = {
  getField,
  offerFiltersItems(state) {
    return state.businessRuleGroup.offerFilters;
  },
  commissionConfigItems(state) {
    return state.productConfigs && state.productConfigs.commission;
  },
  billingConfigItems(state) {
    return state.productConfigs && state.productConfigs.queropago;
  },
  admissionConfigItems(state) {
    return state.productConfigs && state.productConfigs.admission;
  },
  hasOfferFilter(_state, getter) {
    return Boolean(getter.offerFiltersItems && (getter.offerFiltersItems.length > 0));
  },
  hasCommission(_state, getter) {
    return Boolean(getter.commissionConfigItems && (getter.commissionConfigItems.length > 0));
  },
  hasBilling(_state, getter) {
    return Boolean(getter.billingConfigItems && (getter.billingConfigItems.length > 0));
  },
  hasAdmission(_state, getter) {
    return Boolean(getter.admissionConfigItems && (getter.admissionConfigItems.length > 0));
  },
  hasAnyBenefit(_state, getter) {
    return Boolean(getter.hasCommission || getter.hasBilling || getter.hasAdmission);
  },
  activationStepCount(_state, getter) {
    let count = 0;
    if (getter.readyToEdit) count += 1;
    if (getter.hasOfferFilter) count += 1;
    if (getter.hasAnyBenefit) count += 1;
    return count;
  },
  readyToEdit(state) {
    return state.status && state.status === 'editing';
  },
  readyToActiveBenefits(_state, getter) {
    return getter.readyToEdit && (getter.activationStepCount === MAX_STEP_COUNT);
  },
  readyToActiveBRG(state) {
    return state.status && state.status === 'pending';
  },
  readyToFinishBRG(state) {
    return state.status && state.status === 'active';
  },
  readyToCancelBRG(state) {
    return state.status && state.status !== 'canceled';
  },
  readyToOperateConfigs(state) {
    return state.status && state.status !== 'canceled' && state.status !== 'finished' && state.status !== 'pending';
  },
};

const actions = {
  handleBRGRequestStateChange({ state, dispatch }, { event }) {
    const { id } = state.businessRuleGroup;

    const eventsDictionary = [
      'request_activation',
      'activate',
      'request_deactivation',
      'request_canceling',
    ];

    if (!eventsDictionary.includes(event)) throw new Error('Ação não existente');

    this._vm.$upaHelperModal.fire().then((result) => {
      if (result) dispatch('requestUpdateBRG', { event, id });
    });
  },
  requestFindBRGWithProductConfigs({ commit }, { id }) {
    PromiseRetry(async (retry) => {
      const result = await QueroDealsServices.V1.BRG.findById({ id })
        .then(({ businessRuleGroup }) => {
          commit('setBusinessRuleGroup', businessRuleGroup);
          commit('setStatus', businessRuleGroup.status);
          commit('setProductConfig', businessRuleGroup.productConfigs);
          return businessRuleGroup.waitingConfig;
        })
        .catch(() => {
          commit('setStatus', 'error');
        });
      if (result) retry();
    },
    { minTimeout: 1000, factor: 1 })
      .catch(() => { /* ignore */ });
  },
  requestUpdateBRG({ dispatch, commit }, { event, id }) {
    return QueroDealsServices.V1.BRG.update(id, { event })
      .then(() => {
        commit('setStatus', 'loading');
        dispatch('requestFindBRGWithProductConfigs', { id });
      })
      .catch(() => {
        ZToast.showError('Falha ao ativar BRG');
        commit('setStatus', 'error');
      });
  },
  requestFindBRG({ commit }, { id }) {
    return QueroDealsServices.V1.BRG.findById({ id })
      .then(({ businessRuleGroup }) => {
        commit('setBusinessRuleGroup', businessRuleGroup);
        commit('setStatus', businessRuleGroup.status);
        return businessRuleGroup;
      })
      .catch(() => {
        commit('setStatus', 'error');
      });
  },
  requestIndexProductConfig({ commit }, { id }) {
    return QueroDealsServices.V1.ProductConfig.getAll(id)
      .then(({ productConfigs }) => {
        commit('setProductConfig', productConfigs);
        return productConfigs;
      })
      .catch(() => {
        commit('setStatus', 'error');
      });
  },
};

const ShowBRGPageModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

export { ShowBRGPageModule };
