import { UniversityPanelCoreAPI } from '@university-panel/api/university-panel-core-api';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Check user permission for action and subject
 *
 * @typedef Success
 * @property {string} message
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function can({ actionName, subjectName }) {
  return UniversityPanelCoreAPI.Permission.getPermission({
    action: actionName,
    subject: subjectName,
  })
    .then(({ data }) => data.result)
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[verifyPermission] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

export {
  can,
};
