import Vue from 'vue';
import { ZillaPlugin } from '@shared/utils/vue/plugins/zilla-plugin';
import { ValidatePlugin } from '@shared/utils/vue/plugins/validate-plugin';
import OfferCreationBatch from './offer-creation-batch.vue';

export function OfferCreationBatchPage() {
  return new Promise((resolve) => {
    Vue.use(ValidatePlugin);
    Vue.use(ZillaPlugin);

    new Vue({
      el: '#js-offer-creation-batch-page',
      components: {
        OfferCreationBatch,
      },
      mounted() {
        resolve();
      },
    });
  });
}
