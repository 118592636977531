<template>
  <z-input-field :label="label">
    <z-autocomplete
      :internal-search="false"
      :multiple="true"
      :options="statesList"
      :value="statesSelected"
      @input="statesInputHandle($event)"
      @remove="statesRemoveHandle($event)"
      @select="statesSelectHandle($event)"
      hide-selected
      label-by="name"
    />

    <input
      :value="JSON.stringify(statesSelected)"
      type="hidden"
      v-bind="$attrs"
    >
  </z-input-field>
</template>

<script>
import { searchState } from '@shared/services/autocompletes/state';

export default {
  props: {
    label: {
      type: String,
      default: 'Estado(s)',
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      statesList: [],
    };
  },
  computed: {
    statesSelected() {
      return this.value;
    },
  },
  methods: {
    statesInputHandle($event) {
      this.requestSearchState($event);
    },
    statesSelectHandle($event) {
      if (!$event) return;

      this.$emit('select', $event);
    },
    statesRemoveHandle($event) {
      this.$emit('remove', $event);
    },
    requestSearchState(term) {
      searchState(term).then(({ results }) => {
        this.statesList = results;
      });
    },
  },
  mounted() {
    this.requestSearchState('');
  },
};
</script>
