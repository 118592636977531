class FlexFont {
  constructor(options) {
    this.class = options.class;
    this.ratio = options.ratio || 0.10;
    this.max = options.max;
  }

  init() {
    window.onload = () => this.resizer();
    window.onresize = () => this.resizer();
  }

  resizer() {
    const divs = document.getElementsByClassName(this.class);

    for (let i = 0; i < divs.length; i += 1) {
      const relFontsize = divs[i].offsetWidth * this.ratio;

      if (this.max === undefined || relFontsize < this.max) {
        divs[i].style.fontSize = `${relFontsize}px`;
      }
    }
  }
}

export default FlexFont;
