<template>
  <GridContainer
    class="step04-review"
    no-padding
  >
    <grid-item
      :column="3"
      class="step04-review__section"
    >
      <UPAList
        class="step04-review__list"
        v-bind="responsibleDataItem"
      />

      <UPAList
        class="step04-review__list"
        v-bind="accountDataItem"
      />

      <UPAList
        class="step04-review__list"
        v-bind="bankDataItem"
      />
    </grid-item>

    <grid-item
      :column="3"
      class="step04-review__section"
    >
      <UPAList
        class="step04-review__list"
        v-bind="billingDataItem"
      />
    </grid-item>

    <grid-item
      :column="3"
      class="step04-review__section"
      v-if="this.admissionPaymentRule.amountType"
    >
      <UPAList
        class="step04-review__list"
        v-bind="enrollmentDataItem"
      />
    </grid-item>

    <grid-item
      :column="3"
      class="step04-review__section"
    />
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { UPAList } from '@shared/components/upa-list';
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'getStep04Field',
  mutationType: 'updateStep04Field',
});

export default {
  components: {
    UPAList,
    GridContainer,
    GridItem,
  },
  computed: {
    ...mapFields('CreateBillingConfigModalModule', [
      'bankAccountParams',
      'splitRules',
      'contractDischargeRules',
      'contractCreationRule',
      'paymentMethodRules',
      'admissionPaymentRule',
      'scholarshipLosingRule',
      'dueDayRule',
      'subsidy',
    ]),
    responsibleDataItem() {
      return {
        title: 'RESPONSÁVEL',
        items: [
          `<b>${this.bankAccountParams.accountName}</b>`,
        ],
      };
    },
    accountDataItem() {
      return {
        title: 'DADOS DA CONTA',
        items: [
          `<b>${this.bankAccountParams.nameOfRecipient}</b>
          <span>CNPJ ${this.bankAccountParams.document}</span>`,
        ],
      };
    },
    bankDataItem() {
      return {
        title: 'DADOS BANCÁRIOS',
        items: [
          `<b>${this.bankAccountParams.bankSelected.name}
          Agência ${this.bankAccountParams.agencyCode} ${this.bankAccountParams.agencyDigit ? '-'.concat(this.bankAccountParams.agencyDigit) : ''}
          C/${this.bankAccountParams.bankAccountType} ${this.bankAccountParams.accountNumber}-${this.bankAccountParams.accountDigit}</b>`,
        ],
      };
    },
    billingDataItem() {
      return {
        title: 'CONFIGURAÇÕES DE PAGAMENTO',
        items: [
          `Porcentagem de repasse QP: <b>${this.splitRules.transferQpPercentage}%</b>`,
          `Porcentagem de repasse Instituição: <b>${this.splitRules.transferInstitutionPercentage}%</b>`,
          `Subsídio: <b>${this.subsidy}%</b>`,
          `Repasses de dias: <b>${this.bankAccountParams.transferDays}</b>`,
          `Vencimento das mensalidades dia: <b>${this.dueDayRule.dueDays}</b>`,
          `Permite troca da data de vencimento de boletos: <b>${this.dueDayRule.studentCanChangeDueDay ? 'SIM' : 'NÃO'}</b>`,
          `Forma de pagamento
          Mensalidades:
          <b>${this.paymentMethodRules.methods.join(', ')}</b>`,
          `Quitar curso:
          <b>${this.contractDischargeRules.methods.join(', ')}</b>`,
          `Início da cobrança dos alunos:
          <b>${this.contractCreationRule.startRule}</b>`,
          `Duração do contrato:
          <b>${this.contractCreationRule.duration}</b>`,
        ],
      };
    },
    enrollmentDataItem() {
      const isPercentage = this.admissionPaymentRule.amountType.includes('Porcentagem');
      const isAmount = this.admissionPaymentRule.amountType.includes('Valor');
      const { amountType } = this.admissionPaymentRule;
      const hasAmount = ['Porcentagem sem desconto', 'Porcentagem com desconto', 'Valor fixo'].includes(amountType);
      const { amount } = this.admissionPaymentRule;
      const amountSymbol = (isPercentage && '%') || (isAmount && 'R$');
      const amountText = (isPercentage && `${amount} ${amountSymbol}`) || (isAmount && `${amountSymbol} ${amount}`);
      return {
        title: 'MATRÍCULA',
        items: [
          `Modelo de matrícula: <b>${this.admissionPaymentRule.amountType}</b>`,
          `Valor de matrícula: <b>${hasAmount ? amountText : '00.00'}`,
          `O aluno perde a bolsa em caso de atraso da mensalidade: <b>${this.scholarshipLosingRule.canLoseScholarship ? 'SIM' : 'NÃO'}</b>`,
          this.scholarshipLosingRule.canLoseScholarship && `O aluno perde a bolsa em ${this.scholarshipLosingRule.lossDelay} ${this.scholarshipLosingRule.lossDelayType.toLowerCase()}.`,
        ].filter(Boolean),
      };
    },
  },
};
</script>

<style lang="scss">
.step04-review__section:not(:nth-child(4n)) {
  border-right: var(--size-border-width-small) solid var(--color-gray-050);
  padding-right: var(--space-large);
}

.step04-review__list:not(:last-child) {
  margin-bottom: var(--space-large);
}
</style>
