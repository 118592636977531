<template>
  <DescriberCard
    :title="'Configurações de Admissão'"
    :description="'Aqui você vai configurar como será a configuração de admissão.'"
    :icon="['fas', 'plus']"
    class="admission-config-list"
  >
    <template v-if="canCreate">
      <z-button
        @click="handleOpenAdmissionModal"
        behavior="block"
        :icon="['fas', 'plus']"
        class="admission-config-list__create-button"
      >
        Criar configuração de admissão
      </z-button>
    </template>

    <template v-if="hasAdmission">
      <z-button-group behavior="vertical">
        <z-button
          @click="handleOpenShowAdmissionModal(item)"
          v-for="(item, index) in admissionConfigItems"
          :key="index"
        >
          <z-loading
            variant="ellipsis"
            color="primary"
            v-if="item.status === 'pending'"
          />

          <z-badge-text
            class="admission-config-list__badge"
            :variant="ProductConfigBusiness.getStatusDictionary(item.status).variant"
            :value="ProductConfigBusiness.getStatusDictionary(item.status).text"
          />

          <div class="admission-config-list__text">
            ID: {{ item.id }}
          </div>
        </z-button>
      </z-button-group>
    </template>
  </DescriberCard>
</template>

<script>
import { DescriberCard } from '@quero-turbo/components/describer-card';
import { ProductConfigBusiness } from '@quero-turbo/business';
import { mapFields } from 'vuex-map-fields';
import { mapGetters } from 'vuex';

export default {
  components: { DescriberCard },
  computed: {
    ProductConfigBusiness: () => ProductConfigBusiness,
    ...mapFields('ShowBRGPageModule', ['businessRuleGroup']),
    ...mapGetters('ShowBRGPageModule', [
      'admissionConfigItems',
      'readyToOperateConfigs',
      'hasAdmission',
    ]),
    canCreate() {
      if (!this.readyToOperateConfigs) return false;

      return (this.hasAdmission && this.admissionConfigItems.every(element => element.status === 'finished')) || (!this.hasAdmission);
    },
  },
  methods: {
    handleOpenAdmissionModal() {
      this.$store.dispatch('CreateAdmissionConfigModalModule/handleOpenActivationModal', {
        businessRuleGroupId: this.businessRuleGroup.id,
      });
    },
    handleOpenShowAdmissionModal($event) {
      this.$store.dispatch('CreateAdmissionConfigModalModule/handleOpenShowModal', {
        data: $event,
        businessRuleGroupId: this.businessRuleGroup.id,
      });
    },
  },
};
</script>

<style>
.admission-config-list {
  min-height: 100%;
}

.admission-config-list__create-button:not(:last-child) {
  margin-bottom: var(--space-medium);
}

.admission-config-list__badge {
  min-width: fit-content;
}

.admission-config-list__text {
  margin-left: var(--space-small);
}

.admission-config-list__action-text {
  margin-left: var(--space-small);
}
</style>
