<template>
  <aside class="upa-modal-sidebar">
    <z-title
      size="extra-small"
      class="upa-modal-sidebar__title"
      tag="h3"
    >
      {{ title }}
    </z-title>

    <ul
      class="upa-modal-sidebar__list"
      v-for="(section, sectionIndex) in sections"
      :key="sectionIndex"
    >
      <z-text
        tag="li"
        :class="[
          'upa-modal-sidebar__item',
          'upa-modal-sidebar__item--subtitle'
        ]"
        v-html="section.title"
      />

      <li
        class="upa-modal-sidebar__item"
        v-for="(value, valueIndex) in section.values"
        :key="valueIndex"
      >
        <z-icon
          :icon="['fal', value.icon]"
          v-if="value.icon"
          class="upa-modal-sidebar__icon"
        />

        <z-text
          size="small"
          tag="span"
          v-html="value.text"
          class="upa-modal-sidebar__text"
        />
      </li>
    </ul>
  </aside>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    sections: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@quero/zilla-core/src/utils/_index.scss";

.upa-modal-sidebar {
  background-color: var(--color-gray-000);
  border-left: var(--size-micro) solid var(--color-gray-050);
  flex: 1;
  max-height: 676px;
  overflow: auto;
  padding: var(--space-jumbo);
}

.upa-modal-sidebar__title {
  margin-top: 0;
  margin-bottom: 0;
}

.upa-modal-sidebar__list {
  @extend %reset-list;

  margin-top: var(--space-large);
}

.upa-modal-sidebar__item {
  display: flex;
  margin-bottom: var(--space-small);

  &:last-child {
    margin-bottom: 0;
  }
}
// TODO: Zilla Override
.upa-modal-sidebar__item--subtitle {
  font-weight: 500;
  white-space: pre-line;
}

// TODO: Zilla Override
.upa-modal-sidebar__item--subtitle {
  font-weight: 500;
  white-space: pre-line;
}

.upa-modal-sidebar__icon {
  margin-right: var(--space-small);
}

.upa-modal-sidebar__text {
  white-space: pre-line;
}

%reset-list {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}
</style>
