import {
  CreateBRGPage,
  IndexBRGPage,
  ShowBRGPage,
  CreateCommissionConfigPage,
  CreateOfferFilterPage,
} from '@quero-turbo/pages';
import PassThrough from './pass-through.vue';

const brgRoutes = [
  {
    path: '/brgs',
    component: PassThrough,
    children: [
      {
        path: '',
        name: 'index-brg',
        component: IndexBRGPage,
        meta: {
          breadcrumb: [
            { text: 'Quero Turbo', url: '/quero-turbo' },
            { text: 'Listagem de configurações da negociação' },
          ],
        },
      },
      {
        path: 'create',
        name: 'create-brg',
        component: CreateBRGPage,
        meta: {
          breadcrumb: [
            { text: 'Quero Turbo', url: '/quero-turbo' },
            { text: 'Listagem de configurações da negociação', url: '/quero-turbo/brgs' },
            { text: 'Criando uma nova BRG' },
          ],
        },
      },
      {
        path: ':id',
        component: PassThrough,
        children: [
          {
            path: '',
            name: 'show-brg',
            component: ShowBRGPage,
            meta: {
              breadcrumb: [
                { text: 'Quero Turbo', url: '/quero-turbo' },
                { text: 'Listagem de configurações da negociação', url: '/quero-turbo/brgs' },
                { text: 'Painel da configuração' },
              ],
            },
          },
          {
            path: 'create-offer-filter',
            name: 'create-offer-filter',
            component: CreateOfferFilterPage,
            meta: {
              breadcrumb: [
                { text: 'Quero Turbo', url: '/quero-turbo' },
                { text: 'Criando filtro de ofertas' },
              ],
            },
          },
          {
            path: 'create-commission-config',
            name: 'create-commission-config',
            component: CreateCommissionConfigPage,
            meta: {
              breadcrumb: [
                { text: 'Quero Turbo', url: '/quero-turbo' },
                { text: 'Criando novas regras de comissão' },
              ],
            },
          },
        ],
      },
    ],
  },
];

export { brgRoutes };
