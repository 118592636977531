import AmplitudeService from '@shared/utils/amplitude-service';

const amplitude = AmplitudeService.getInstance();

const _logEvent = (eventName, options) => {
  const eventFullName = `[home_overview] ${eventName}`;
  amplitude.logEvent(eventFullName, options);
};

const viewedPowerBIDashboard = () => {
  _logEvent('Viewed Home Overview page with PowerBI dashboard');
};

export const homeOverviewTracking = {
  viewedPowerBIDashboard,
};
