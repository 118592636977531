import $ from 'jquery';

class RejectDocs {
  constructor(page) {
    this.page = page;
    this.actionButton = $('#js-documentation-rejection');
    this.checkArea = $('.js-docs-check-area');
    this.componentsToHide = $('.js-hide-on-reject');
    this.selectAllButton = $('.js-reject-select-all');
    this.checks = $('.js-doc-checkbox');
    this.rejectModal = $('.js-reject-docs-modal');
    this.docsList = $('.js-reject-doc-list');
    this.form = $('.js-reject-documents-form');
    this.active = false;
    this.setupComponents();
  }

  setupComponents() {
    this.setupActionButton();
    this.setupDocumentSelect();
    this.setupSelectAll();
  }

  setupActionButton() {
    this.actionButton.click(() => {
      this.checkArea.toggle();
      this.componentsToHide.toggle();
      this.selectAllButton.toggle();

      if (!this.active) {
        this.actionButton.text('Cancelar');
        this.active = true;
        this.resetChecks();
      } else {
        this.actionButton.text('Rejeitar documentos');
        this.active = false;
        this.openModal();
      }
    });
  }

  setupDocumentSelect() {
    this.checkArea.click((event) => {
      const checkBox = $(event.target).find('input');
      const checked = checkBox.is(':checked');
      checkBox.prop('checked', !checked);
      checkBox.trigger('change');
    });

    this.checks.change(() => {
      if (this.inputsChecked().length > 0) {
        this.actionButton.text(`Rejeitar (${this.inputsChecked().length})`);
      } else {
        this.actionButton.text('Cancelar');
      }
    });
  }

  setupSelectAll() {
    this.selectAllButton.click(() => {
      this.checks.prop('checked', true);
      this.checks.trigger('change');
    });
  }

  resetChecks() {
    this.checks.prop('checked', false);
  }

  inputsChecked() {
    return $('.js-doc-checkbox:checked');
  }

  openModal() {
    const checkeds = this.inputsChecked();

    if (checkeds.length > 0) {
      this.updateForm();
      const descriptions = checkeds.closest('.js-follow-up-docs').find('.scholarship-panel__docs-description');
      const texts = descriptions.map((_, item) => item.innerText).toArray();

      this.docsList.text(texts.join(', '));
      this.rejectModal.modal();
    }
  }

  updateForm() {
    const checkeds = this.inputsChecked();
    this.form.find('.js-reject-doc-input').remove();

    checkeds.each((_, item) => {
      const input = $(`<input type="hidden" name="rejection[docs][]" value="${item.name}" class="js-reject-doc-input">`);
      this.form.append(input);
    });
  }
}

export default RejectDocs;
