<template>
  <z-card
    :class="{
      ['disclaimer-card']: true,
      [`disclaimer-card--${color}`]: color
    }"
  >
    <slot name="icon">
      <disclaimer-card-icon
        class="disclaimer-card__icon"
        :name="icon"
      />
    </slot>

    <slot>
      <z-title
        size="small"
        color="inverted"
        class="disclaimer-card__text"
      >
        {{ title }}
      </z-title>
    </slot>

    <slot name="buttonClose">
      <button
        class="disclaimer-card__button"
        v-on="$listeners"
      >
        <z-text
          color="inverted"
          tag="span"
          class="disclaimer-card__button-text"
        >
          <strong>
            {{ buttonText }}
          </strong>
        </z-text>

        <z-icon
          :icon="[
            'fas',
            'times'
          ]"
          color="inverted"
        />
      </button>
    </slot>
  </z-card>
</template>

<script>
import { shouldBeOneOf } from 'vue-prop-validation-helper';
import DisclaimerCardIcon from './disclaimer-card-icon';

export default {
  components: {
    DisclaimerCardIcon,
  },
  props: {
    color: {
      required: true,
      type: String,
      validator: shouldBeOneOf(['info', 'danger', 'warning', 'success']),
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
@import 'css/utils/_reset.sass';

.disclaimer-card {
  --card-background-color: var(--color-background-minor);
  align-items: center;
  margin-bottom: var(--space-large);
  max-height: 70px;
  display: flex;
}

.disclaimer-card--danger {
  --card-background-color: var(--color-support-danger-pure);
}
.disclaimer-card--warning {
  --card-background-color: var(--color-support-warning-pure);
}
.disclaimer-card--info {
  --card-background-color: var(--color-support-info-pure);
}
.disclaimer-card--success {
  --card-background-color: var(--color-support-success-pure);
}

.disclaimer-card__icon {
  margin-right: var(--space-medium);
  margin-bottom: calc(-1 * var(--size-small));
  margin-top: calc(-1 * var(--size-small));
}

.disclaimer-card__text {
  flex: 1;
}

.disclaimer-card__button {
  @extend %reset-button;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  transition: opacity 250ms;

  &:hover {
    opacity: 0.8;
  }

  &:active, &:focus {
    opacity: 0.6;
  }
}

.disclaimer-card__button-text {
  margin-right: var(--space-medium);
}
</style>
