<template>
  <GridContainer no-padding>
    <GridItem
      v-for="(commissionConfig, index) in commissionConfigs"
      :key="index"
      :column="6"
    >
      <CommissionTypeCard
        @add-commission-type="handleAddCommissionType"
        @remove-commission-type="handleRemoveCommissionType"
        @change="handleChangeCommissionType"
        @click-create-turbo-account="handleCreateTurboAccount"
        :account-list="accountList"
        :commission-config="commissionConfig"
        :index="index"
        :is-last="index === (commissionConfigs.length - 1)"
      />
    </GridItem>
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import uniqueId from 'lodash/uniqueId';
import { QueroDealsServices } from '@quero-turbo/services';
import CommissionTypeCard from './commission-type-card.vue';
import CreateTurboAccountModal from './create-turbo-account-modal.vue';

export default {
  components: {
    CommissionTypeCard,
    GridContainer,
    GridItem,
  },
  props: {
    brgId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      commissionConfigs: [],
      accountList: [],
    };
  },
  methods: {
    handleAddCommissionType() {
      this.commissionConfigs.push({
        commissionFeeTypeLabel: null,
        commissionFee: null,
        subsidy: null,
        id: uniqueId(),
        paymentModel: null,
        paymentTrigger: null,
        lowerLimitRule: null,
        step: null,
        turboCommissionRuleCountId: null,
        upperLimitRule: null,
        accountSelected: null,
      });
    },
    handleRemoveCommissionType($event) {
      const id = $event;
      const index = this.commissionConfigs.findIndex(element => (element.id === id));
      this.commissionConfigs.splice(index, 1);
    },
    handleChangeCommissionType($event) {
      const { id, key, value } = $event;
      const index = this.commissionConfigs.findIndex(element => (element.id === id));
      this.commissionConfigs[index][key] = value;
    },
    async handleCreateTurboAccount() {
      const result = await this.$upaHelperModal.fire({
        mode: 'custom',
        options: { component: CreateTurboAccountModal },
      });

      if (result) {
        this.$upaHelperModal.fire({
          mode: 'success',
        });

        this.requestIndexTurboAccount();
      }
    },
    requestIndexTurboAccount() {
      QueroDealsServices.V1.TurboAccount.getAll().then(({ turboAccounts }) => {
        this.accountList = turboAccounts;
      });
    },
    getCommissionConfigs() {
      return this.commissionConfigs;
    },
  },
  mounted() {
    this.handleAddCommissionType();
    this.requestIndexTurboAccount();
  },
};
</script>
