const state = () => ({
  university: {
    logoUrl: '',
    id: null,
  },
});

const mutations = {
  setUniversity(state, { id, logoUrl }) {
    state.university = {
      ...state.university,
      id,
      logoUrl,
    };
  },
};

const actions = {
  setup({ commit }, { universityId, universityLogoUrl }) {
    commit('setUniversity', {
      id: universityId,
      logoUrl: universityLogoUrl,
    });
  },
};

export const SessionModule = {
  namespaced: true,
  state,
  mutations,
  actions,
};
