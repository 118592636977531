import $ from 'jquery';

import { RollbarService } from '@shared/utils/rollbar-service';
import { basicZillaSetup } from '@shared/utils/vue/basic-zilla-setup';

// Admission section
import {
  AdmissionsDashboard,
  AnalyzeDocumentation,
  CpfHashPage,
  ScholarshipPage,
  ScholarshipSearchContainer,
} from '@admission/legacy/pages';

// Attraction section
import {
  CampusInformationList,
  CollegeReviews,
  CourseInformationList,
  UniversityContent,
} from '@attraction/legacy/pages';

// Quero Turbo
import {
  QueroTurboBoot,
} from '@quero-turbo/boot';

// Stock section
import {
  OfferCreationBatchPage,
  OneClickRestockPage,
  OperationLogsPage,
} from '@stock/pages';
import {
  StockDashboard,
  UniversityOfferList,
} from '@stock/legacy/pages';

// University-panel section
import {
  LoginPage,
  ResetPasswordForm,
  ResetPasswordNew,
  UniversityUser,
  UserSearch,
} from '@university-panel/legacy/pages';
import {
  HomeOverviewPage,
} from '@university-panel/pages';

/**
 * @typedef Page
 * @type {Object}
 * @property {Boolean} useCustomSetup - tells you if the page already has a manual zilla setup
 * @property {Object} ClassComponent - optional page builder class
 * @property {String} selector - unique selector in the DOM that identifies the page
 */

/** @type Page[] */
const pages = [
  {
    selector: '.js-scholarship-search',
    ClassComponent: ScholarshipSearchContainer,
    useCustomSetup: false,
  },
  {
    selector: '#js-scholarship-page',
    ClassComponent: ScholarshipPage,
    useCustomSetup: false,
  },
  {
    selector: '#offer-list-page',
    ClassComponent: UniversityOfferList,
    useCustomSetup: true,
  },
  {
    selector: '#js-one-click-restock-page',
    ClassComponent: OneClickRestockPage,
    useCustomSetup: true,
  },
  {
    selector: '#js-offer-creation-batch-page',
    ClassComponent: OfferCreationBatchPage,
    useCustomSetup: true,
  },
  {
    selector: '#js-operation-logs-page',
    ClassComponent: OperationLogsPage,
    useCustomSetup: true,
  },
  {
    selector: '#js-home-overview-page',
    ClassComponent: HomeOverviewPage,
    useCustomSetup: true,
  },
  {
    selector: '#js-quero-turbo-boot',
    ClassComponent: QueroTurboBoot,
    useCustomSetup: true,
  },
  {
    selector: '#university_user_search',
    ClassComponent: UserSearch,
    useCustomSetup: false,
  },
  {
    selector: '#admissions-dashboard',
    ClassComponent: AdmissionsDashboard,
    useCustomSetup: false,
  },
  {
    selector: '#cpf-hash-import',
    ClassComponent: CpfHashPage,
    useCustomSetup: false,
  },
  {
    selector: '#college_reviews_form',
    ClassComponent: CollegeReviews,
    useCustomSetup: false,
  },
  {
    selector: '#js-login-page',
    ClassComponent: LoginPage,
    useCustomSetup: false,
  },
  {
    selector: '#js-reset-password-new-page',
    ClassComponent: ResetPasswordNew,
    useCustomSetup: false,
  },
  {
    selector: '#js-reset-password',
    ClassComponent: ResetPasswordForm,
    useCustomSetup: false,
  },
  {
    selector: '#js-stock-dashboard',
    ClassComponent: StockDashboard,
    useCustomSetup: false,
  },
  {
    selector: '.js-university-content',
    ClassComponent: UniversityContent,
    useCustomSetup: false,
  },
  {
    selector: '.js-university-user',
    ClassComponent: UniversityUser,
    useCustomSetup: false,
  },
  {
    selector: '.js-analyze-docs',
    ClassComponent: AnalyzeDocumentation,
    useCustomSetup: false,
  },
  {
    selector: '#js-my-courses-page',
    ClassComponent: CourseInformationList,
    useCustomSetup: false,
  },
  {
    selector: '#js-my-campuses-page',
    ClassComponent: CampusInformationList,
    useCustomSetup: true,
  },
];

/**
 * Makes zilla components available on pages
 *
 * @returns {Promise<void>[]} when zilla components are ready
 */
export function setupCurrentPage() {
  if (_isOnTheBlacklistPage()) return [];
  const promises = pages
    .filter(_checkIfIsCurrentPage)
    .map(_setupPage);
  if (promises.length > 0) return promises;
  return [basicZillaSetup({ selector: '.js-page-content' })];
}

// -- private

/**
 * says if the current page is a page where we should not initialize the vue
 * @returns {boolean}
 * @private
 */
function _isOnTheBlacklistPage() {
  return [
    '/matricula-digital',
    '/quero-pago',
    '/matricula-antecipada',
    '/parcelamento',
  ].some(url => window.location.pathname.startsWith(url));
}

/**
 * checks if it matches the DOM
 * @param {Page} page
 * @returns {boolean} has match?
 * @private
 */
function _checkIfIsCurrentPage({ selector }) {
  return $(selector).length > 0;
}

/**
 *
 * @param {Page} page
 * @returns {Promise<void>}
 * @private
 */
function _setupPage({ selector, ClassComponent, useCustomSetup }) {
  if (useCustomSetup && ClassComponent) {
    return _customSetup({ ClassComponent, selector, useCustomSetup });
  }
  return basicZillaSetup({ selector }).then(() => {
    if (ClassComponent) new ClassComponent($(selector));
  });
}

/**
 *
 * @param {Page} page
 * @returns {Promise<void>}
 * @private
 */
function _customSetup({ ClassComponent, selector }) {
  const promise = new ClassComponent($(selector));
  if (promise instanceof Promise) return promise;
  RollbarService.getInstance().notifyError(
    'the page should return a promise when vue is mounted',
  );
  return Promise.resolve();
}
