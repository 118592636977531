<template>
  <div>
    <transition
      name="quero-turbo-app__router-fade"
      mode="out-in"
    >
      <z-breadcrumb
        :items="breadcrumbList "
        class="quero-turbo-app__breadcrumb"
        v-if="breadcrumbList"
      />
    </transition>

    <transition
      name="quero-turbo-app__router-fade"
      mode="out-in"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbList: [],
    };
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  mounted() {
    this.breadcrumbList = this.$route.meta.breadcrumb;
  },
};
</script>

<style>
.quero-turbo-app__breadcrumb {
  padding-bottom: 0;
  padding-left: var(--space-medium);
  padding-right: var(--space-medium);
  padding-top: var(--space-medium);
}

.quero-turbo-app__breadcrumb > ol {
  margin-bottom: 0;
}

.quero-turbo-app__router-fade-enter-active,
.quero-turbo-app__router-fade-leave-active {
  transition: opacity 250ms;
}
.quero-turbo-app__router-fade-enter,
.quero-turbo-app__router-fade-leave-to {
  opacity: 0;
}
</style>
