import $ from 'jquery';

export default class BaseModal {
  constructor(props = {}, template) {
    this.props = props;
    this.cssClass = `_${Math.random()}`.replace('.', '');
    this.template = template;
  }

  onSubmit() {
    $(this).trigger('submit');
  }

  onCancel() {
    $(this).trigger('cancel');
  }

  onShow() {}

  show() {
    if ($(`.${this.cssClass}`).length) {
      return this;
    }

    this.$container = $(this.template(this.props));
    this.setupEvents(this.$container);

    this.$container
      .addClass(this.cssClass)
      .appendTo('body')
      .modal('show');

    this.onShow();
    return this;
  }

  destroy() {
    if (this.$container == null) {
      throw new Error('Modal not shown');
    }
    this.$container.remove();
    $('.modal-backdrop.fade.in').remove();
    $('body').removeClass('modal-open');
    return this;
  }

  hide() {
    if (this.$container == null) {
      throw new Error('Modal not shown');
    }

    this.$container.modal('hide');
    return this;
  }

  setupEvents($modal) {
    $modal.one('click', '.js-confirm-button', this.onSubmit.bind(this));
    $modal.one('click', '.js-cancel-modal', this.onCancel.bind(this));
    $modal.on('hide.bs.modal', this.onCancel.bind(this));
    $modal.on('hidden.bs.modal', () => this.destroy());
  }
}
