import { queroAlunosAPI } from '@stock/api/quero-alunos-api.js';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Index all offer creation spreadsheets from university
 *
 * @typedef Success
 * @property {object<OfferCreationSheet>[]} OfferCreationSheets
 *
 * @typedef OfferCreationSheet
 * @property {Object} OfferCreationSheet
 * @property {string} OfferCreationSheet.createdAt
 * @property {number} OfferCreationSheet.universityUserId
 * @property {string} OfferCreationSheet.universityUserName
 * @property {string} OfferCreationSheet.name
 * @property {string} OfferCreationSheet.status ['created', 'in_progress', 'error', 'published']
 * @property {string} OfferCreationSheet.uuid
 * @property {string} OfferCreationSheet.sheetUrl
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function indexOfferCreationSpreadsheet() {
  return queroAlunosAPI.indexOfferCreationSpreadsheet()
    .then(({ data }) => ({ offerCreationSheets: data.offerCreationSheets }))
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[createOffers] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

export {
  indexOfferCreationSpreadsheet,
};
