import MessageModal from '@stock/legacy/components/modals/message-modal';
import ParagraphTemplate from '@university-panel/legacy/components/templates/paragraphs.html.ejs';

export const validationRegressiveDiscountModal = ({ operationType }) => {
  const messageDictionary = {
    pricingChange: `
    Todas as ofertas filtradas possuem descontos regressivos.
    Caso queira alterar o valor dos descontos essas ofertas, fale conosco.
    `,
    createWindowOffer: `
    Todas as ofertas filtradas possuem descontos regressivos.
    Caso queira criar ofertas vitrines para essas bolsas, fale conosco.
    `,
  };

  const paragraphs = [
    messageDictionary[operationType],
  ];

  const messageModal = new MessageModal({
    title: operationType === 'pricingChange' ? 'Alteração de desconto em lote' : 'Criação de ofertas vitrine',
    content: ParagraphTemplate({ paragraphs }),
    submitText: 'Fale conosco',
  });

  $(messageModal).on('submit', (event) => {
    event.preventDefault();
    messageModal.hide();
    window.open('mailto:suporteQP@queropago.com.br?subject=[QA%20-%20Outros]%20-%20Assunto&body=', '_blank');
  });

  return messageModal;
};
