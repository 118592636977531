import { UPAHelperModalApi } from './upa-helper-modal-api';

class UPAHelperModalPlugin {
  static install(Vue, globalOptions = {}) {
    const defaultInstanceLocal = Vue.prototype;
    const defaultTagId = 'upa-helper-modal';

    if (!globalOptions.InstanceLocal) Object.defineProperty(globalOptions, 'InstanceLocal', { value: defaultInstanceLocal });
    if (!globalOptions.tagId) Object.defineProperty(globalOptions, 'tagId', { value: defaultTagId });
    const API = UPAHelperModalApi(Vue, globalOptions);
    Object.defineProperty(globalOptions.InstanceLocal, '$upaHelperModal', { value: API });
    Object.defineProperty(Vue, '$upaHelperModal', { value: API });
  }
}

export { UPAHelperModalPlugin };
