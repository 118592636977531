import $ from 'jquery';
import template from '@stock/legacy/components/modals/offer-list-actions/batch/edit-template.html.ejs';
import isInteger from '@shared/utils/validators/is-integer';
import { BatchOperationModal, BatchOperationRequest } from '@stock/legacy/modules/batch-operation';
import { TEMPLATE_EDIT_PROPS } from '@stock/legacy/components/modals/offer-list-actions/batch/restock/template-props';
import setupConfirmationStep from '@stock/legacy/components/modals/offer-list-actions/batch/restock/confirmation';
import validator from '@shared/utils/validator';
import { batchOperationsTracking } from '@stock/metrics';

const TYPE_ACTION_INPUT = '.js-typeaction-input';
export default class BatchRestockModal extends BatchOperationModal {
  constructor(initialSeatsCount, searchForm, alert, smallestSeats) {
    super({ ...TEMPLATE_EDIT_PROPS, initialSeatsCount }, searchForm, alert, template);
    this.smallestSeats = smallestSeats;
    this.searchForm = searchForm;
    setupConfirmationStep(this, alert);
  }

  onShow() {
    this.$seatsCountInput = $('.js-restock-seats-count');
    super.onShow();
    this.setupSeatsAmountInput();
    batchOperationsTracking.trackShowBatchRestockModal();
    this.setupRadioButton();
  }

  get seatsCount() {
    return parseInt(this.$seatsCountInput.val(), 10) || 0;
  }

  setupBatchOperation() {
    const amountSeats = this.getAppliedValueFormated(this.seatsCount);
    const batchOperationRequest = new BatchOperationRequest(this.searchForm, {
      restock_type: $(`${TYPE_ACTION_INPUT}:checked`).val(),
      amount_seats: amountSeats,
      constraints: this.getRequestConstraints(amountSeats),
    });

    return batchOperationRequest;
  }

  getRequestConstraints(amountSeats) {
    if (amountSeats < 0) {
      return {
        limited: true,
        shared_seats: false,
        saleable_seats: this.getSeatsRange(amountSeats),
      };
    }

    return { limited: true, shared_seats: false };
  }

  getSeatsRange(amountSeats) {
    const [rangeStart, rangeEnd] = this.searchForm.toDataTransferObject().saleable_seats.split('-');
    if (!rangeStart && !rangeEnd) {
      return [Math.abs(amountSeats)];
    }
    if (!rangeEnd) {
      return [
        Math.max(
          Math.abs(amountSeats),
          parseInt(rangeStart, 10),
        ),
        parseInt(rangeStart, 10),
      ];
    }
    return [
      Math.max(
        Math.abs(amountSeats),
        parseInt(rangeStart, 10),
      ),
      parseInt(rangeEnd, 10),
    ];
  }

  setupSeatsAmountInput() {
    this.applyValidator(this.$seatsCountInput, [isInteger, this.validateSeatsError.bind(this)]);
    this.applyWarningValidator();
    this.$seatsCountInput.on('input', () => {
      this.normalizeSeatsAmountInput(this.$seatsCountInput);
      this.updateFeedback();
    });
    this.triggerSeatsValidation();
  }

  normalizeSeatsAmountInput($seatsCountInput) {
    const nonstandard = /[^\d]/g;
    const value = $seatsCountInput.val().replace(nonstandard, '');
    $seatsCountInput.val(value);
  }

  applyWarningValidator() {
    validator(this.$seatsCountInput, {
      validations: [this.validateSeatsWarning.bind(this)],
      whenValid: () => this.onInputValid(),
      whenInvalid: () => this.onInputInvalid(),
    });
  }

  onInputValid() {
    this.hideRules();
    this.$seatsCountInput.closest('.form-group').removeClass('has-warning');
  }

  onInputInvalid() {
    this.showRules();
    this.$seatsCountInput.closest('.form-group').addClass('has-warning');
  }

  showRules() {
    $('.js-batch-edit-rules').removeClass('u-display-none');
  }

  hideRules() {
    $('.js-batch-edit-rules').addClass('u-display-none');
  }

  validateSeatsError() {
    if (this.action !== '=' && this.seatsCount === 0) {
      return false;
    }
    return true;
  }

  validateSeatsWarning() {
    if (this.action === '-' && this.seatsCount > Math.max(0, this.smallestSeats)) {
      return false;
    }
    return true;
  }

  setupRadioButton() {
    this.hideRules();
    $(TYPE_ACTION_INPUT).on('change', (event) => {
      this.action = event.target.value;
      this.triggerSeatsValidation();
      this.updateDisclaimer();
      this.updateFeedback();
    });
    super.setupRadioButton();
  }

  updateDisclaimer() {
    const $disclaimer = $('.js-type-disclaimer');
    $disclaimer.empty();
    $disclaimer.append(this.getMessageDisclaimer(this.action));
  }

  updateFeedback() {
    const $target = $('.js-feedback');
    if (this.action === '=') {
      const text = TEMPLATE_EDIT_PROPS.feedback(this.seatsCount);
      return $target.html(text);
    }
    return $target.text('');
  }

  triggerSeatsValidation() {
    if (this.$seatsCountInput.val() !== '') {
      this.$seatsCountInput.trigger('change');
    }
  }

  getMessageDisclaimer(action) {
    if (action === '+') {
      return TEMPLATE_EDIT_PROPS.disclaimerInc;
    }
    if (action === '-') {
      return TEMPLATE_EDIT_PROPS.disclaimerDec;
    }
    return TEMPLATE_EDIT_PROPS.disclaimerRep;
  }

  getConfirmationData() {
    return {
      appliedValue: this.getAppliedValueFormated(this.seatsCount),
      restockType: $(`${TYPE_ACTION_INPUT}:checked`).val(),
      seatsCount: this.getAppliedValueFormated(this.seatsCount),
    };
  }
}
