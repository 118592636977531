function getPositionInRanking(rank) {
  const { position } = rank;
  return position ? `${position}º` : '-';
}

function getPageOfRanking(rank) {
  const { page } = rank;
  return page || '-';
}

function getColorOfRanking(rank) {
  const { positionDelta } = rank;
  if (positionDelta > 0) return 'up';
  if (positionDelta < 0) return 'down';
  return 'minus';
}

function getIconOfRanking(rank) {
  const { positionDelta } = rank;
  if (positionDelta > 0) return 'arrow-up';
  if (positionDelta < 0) return 'arrow-down';
  return 'minus';
}

export const ranking = {
  getPageOfRanking,
  getIconOfRanking,
  getColorOfRanking,
  getPositionInRanking,
};
