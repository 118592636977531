const competitivenessDictionary = {
  high: {
    classModifier: 's-competitiveness-data__icon--high-competitive',
    label: 'Muito competitivo',
    priceLabel: 'Preço muito competitivo',
    text: 'Seu preço é favorável em relação aos concorrentes da praça',

  },
  medium: {
    classModifier: 's-competitiveness-data__icon--medium-competitive',
    label: 'Competitivo',
    priceLabel: 'Preço competitivo',
    text: 'Seu preço está na média em relação aos concorrentes da praça',
  },
  low: {
    classModifier: 's-competitiveness-data__icon--low-competitive',
    label: 'Pouco competitivo',
    priceLabel: 'Preço pouco competitivo',
    text: 'Seu preço é desfavorável em relação aos concorrentes da praça',
  },
  wip: {
    classModifier: 's-competitiveness-data__icon--wip-competitive',
    label: 'Em análise',
    priceLabel: 'Em análise',
    text: 'Estamos avaliando seu preço em relação aos concorrentes da praça',
  },
};

const getIconClassModifier = ({ label }) => {
  const option = label || 'wip';
  return competitivenessDictionary[option].classModifier;
};

const getPriceLabel = ({ label }) => {
  const option = label || 'wip';
  return competitivenessDictionary[option].priceLabel;
};

const getLabel = ({ label }) => {
  const option = label || 'wip';
  return competitivenessDictionary[option].label;
};

const getText = ({ label }) => {
  const option = label || 'wip';
  return competitivenessDictionary[option].text;
};

export const competitiveness = {
  getIconClassModifier,
  getPriceLabel,
  getLabel,
  getText,
};
