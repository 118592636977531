import { RollbarService } from '@shared/utils/rollbar-service';

const rollbar = RollbarService.getInstance();

export function hasOnlyValidKeys(validKeys) {
  return (obj) => {
    const invalidKeys = Object.keys(obj).filter(key => !validKeys.includes(key));
    if (invalidKeys.length) {
      rollbar.notifyError(
        `The keys: ${JSON.stringify(invalidKeys)} aren't mapped in ${JSON.stringify(validKeys)}`,
      );
      return false;
    }
    return true;
  };
}

export function shouldBeOneOf(validValues) {
  return (value) => {
    if (validValues.includes(value)) {
      return true;
    }
    rollbar.notifyError(
      `The value: '${value}' isn't mapped in ${JSON.stringify(validValues)}`,
    );
    return false;
  };
}

export function allValuesMustBePresent() {
  return (valueList) => {
    const isValid = valueList.every(value => value !== null && value !== undefined);
    if (isValid) {
      return true;
    }
    rollbar.notifyError(
      `required value is not present in the list: ${JSON.stringify(valueList)}`,
    );
    return false;
  };
}

export function matchAllKeys(keys) {
  const requiredKeys = [...new Set(keys)];
  return (objectToBeValidated) => {
    const keysToBeValidated = Object.keys(objectToBeValidated);

    const isValid = () => {
      if (requiredKeys.length !== keysToBeValidated.length) return false;
      return requiredKeys.every(
        requiredKey => keysToBeValidated.includes(requiredKey),
      );
    };

    const logError = () => {
      const requiredKeysStr = JSON.stringify(requiredKeys);
      const keysToBeValidatedStr = JSON.stringify(keysToBeValidated);
      rollbar.notifyError(
        `the required keys ${requiredKeysStr} don't match ${keysToBeValidatedStr}`,
      );
    };

    if (isValid()) return true;
    logError();
    return false;
  };
}
