<template>
  <DescriberCard
    :title="'Configurações de Quero Pago'"
    :description="'Aqui você vai configurar como será a configuração de QueroPago.'"
    :icon="['fas', 'file-invoice-dollar']"
    class="billing-config-list"
  >
    <template v-if="readyToEdit && !hasBilling">
      <z-button
        @click="handleOpenCreateBillingModal"
        behavior="block"
        :icon="['fas', 'plus']"
      >
        Criar configuração de QueroPago
      </z-button>
    </template>

    <template v-else-if="hasBilling">
      <z-button-group behavior="vertical">
        <z-button
          @click="handleOpenShowBillingModal(item)"
          v-for="(item, index) in billingConfigItems"
          :key="index"
        >
          <z-loading
            variant="ellipsis"
            color="primary"
            v-if="item.status === 'pending'"
          />

          <z-badge-text
            class="billing-config-list__badge"
            :variant="ProductConfigBusiness.getStatusDictionary(item.status).variant"
            :value="ProductConfigBusiness.getStatusDictionary(item.status).text"
          />

          <div class="billing-config-list__text">
            ID: {{ item.id }}
          </div>
        </z-button>
      </z-button-group>
    </template>
  </DescriberCard>
</template>

<script>
import { DescriberCard } from '@quero-turbo/components/describer-card';
import { ProductConfigBusiness } from '@quero-turbo/business';
import { mapFields } from 'vuex-map-fields';
import { mapGetters } from 'vuex';

export default {
  components: { DescriberCard },
  computed: {
    ProductConfigBusiness: () => ProductConfigBusiness,
    ...mapFields('ShowBRGPageModule', ['businessRuleGroup']),
    ...mapGetters('ShowBRGPageModule', [
      'billingConfigItems',
      'readyToEdit',
      'hasBilling',
    ]),
  },
  methods: {
    handleOpenCreateBillingModal() {
      this.$store.dispatch('CreateBillingConfigModalModule/handleOpenActivationModal', {
        businessRuleGroupId: this.businessRuleGroup.id,
      });
    },
    handleOpenShowBillingModal($event) {
      this.$store.dispatch('CreateBillingConfigModalModule/handleOpenShowModal', {
        data: $event,
      });
    },
  },
};
</script>

<style>
.billing-config-list {
  min-height: 100%;
}

.billing-config-list__badge {
  min-width: fit-content;
}

.billing-config-list__text {
  margin-left: var(--space-small);
}

.billing-config-list__action-text {
  margin-left: var(--space-small);
}
</style>
