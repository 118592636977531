<template>
  <div class="create-admission-config-modal-documents__container">
    <div class="create-admission-config-modal-documents__student-documents">
      <z-card>
        <z-text
          color="major"
          size="micro"
          weight="bold"
        >
          DOCUMENTOS DO ALUNO
        </z-text>

        <ul class="create-admission-config-modal-documents__list">
          <li
            class="create-admission-config-modal-documents__list-item"
            v-for="(studentDocument, index) in studentDocuments"
            :key="index"
          >
            <z-tag
              :value="getDocumentText(studentDocument.value)"
              :is-selected="studentDocument.selected"
              :has-icon="true"
              @click="handleStudentDocumentsSelect(index)"
            />
          </li>
        </ul>
      </z-card>
    </div>

    <div>
      <z-card
        class="create-admission-config-modal-documents__parents-documents-card"
      >
        <z-text
          color="major"
          size="micro"
          weight="bold"
        >
          DOCUMENTOS DO RESPONSAVÉL LEGAL
        </z-text>

        <ul class="create-admission-config-modal-documents__list">
          <li
            class="create-admission-config-modal-documents__list-item"
            v-for="(parentDocument, index) in parentDocuments"
            :key="index"
          >
            <z-tag
              :value="getDocumentText(parentDocument.value)"
              :is-selected="parentDocument.selected"
              :has-icon="true"
              @click="handleParentDocumentsSelect(index)"
            />
          </li>
        </ul>
      </z-card>

      <z-card>
        <z-text
          color="major"
          size="micro"
          weight="bold"
        >
          PÓS-GRADUAÇÃO
        </z-text>

        <ul class="create-admission-config-modal-documents__list">
          <li
            class="create-admission-config-modal-documents__list-item"
            v-for="(graduateDocument, index) in graduateDocuments"
            :key="index"
          >
            <z-tag
              :value="getDocumentText(graduateDocument.value)"
              :is-selected="graduateDocument.selected"
              :has-icon="true"
              @click="handleGraduateDocumentsSelect(index)"
            />
          </li>
        </ul>
      </z-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { AdmissionDocumentBusiness } from '@quero-turbo/business';

export default {
  computed: {
    ...mapFields('CreateAdmissionConfigModalModule', [
      'studentDocuments',
      'parentDocuments',
      'graduateDocuments',
    ]),
    ...mapGetters('CreateAdmissionConfigModalModule', [
      'selectedStudentDocuments',
    ]),
  },
  watch: {
    selectedStudentDocuments(selectedStudentDocuments) {
      if (this.hasRequiredDocuments(selectedStudentDocuments)) {
        this.setDisableNextButtonStep01(false);
      } else {
        this.setDisableNextButtonStep01(true);
      }
    },
  },
  methods: {
    ...mapActions('CreateAdmissionConfigModalModule', [
      'setStudentDocuments',
      'setParentDocuments',
      'setGraduateDocuments',
      'setDisableNextButtonStep01',
    ]),
    handleStudentDocumentsSelect(index) {
      this.setStudentDocuments(index);
    },
    handleParentDocumentsSelect(index) {
      this.setParentDocuments(index);
    },
    handleGraduateDocumentsSelect(index) {
      this.setGraduateDocuments(index);
    },
    getDocumentText(key) {
      return AdmissionDocumentBusiness.getLabel(key);
    },
    hasRequiredDocuments(selectedDocuments) {
      const selectedDocsKeys = selectedDocuments.map(document => document.value);
      return AdmissionDocumentBusiness.getIdentificationDocs().some(requiredDocument => selectedDocsKeys.includes(requiredDocument));
    },
  },
};
</script>

<style lang="scss">
@import "@quero/zilla-core/src/utils/_index.scss";

.create-admission-config-modal-documents__container {
  display: flex;
}

.create-admission-config-modal-documents__student-documents {
  margin-right: var(--space-medium);
  max-width: 456px;
}

.create-admission-config-modal-documents__parents-documents-card {
  margin-bottom: var(--space-medium);
}

.create-admission-config-modal-documents__list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-bottom: 0;
  margin-right: 0;
  padding: 0;
}

.create-admission-config-modal-documents__list-item {
  margin-bottom: var(--space-small);
  margin-right: var(--space-small);
}

.create-admission-config-modal-documents__list-item:last-child {
  margin-bottom: 0;
}
</style>
