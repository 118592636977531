<template>
  <div>
    <z-title
      size="medium"
      class="create-turbo-account-modal__title"
    >
      Criar conta turbo
    </z-title>

    <div>
      <z-input-field label="Nome">
        <z-input
          type="text"
          v-model="name"
        />
      </z-input-field>
    </div>

    <footer class="create-turbo-account-modal__footer">
      <z-button
        @click="$emit('action', false)"
        variant="secondary"
        class="create-turbo-account-modal__button"
      >
        Cancelar
      </z-button>

      <z-button
        @click="handleCreateTurboAccount"
        :disabled="!name"
        class="create-turbo-account-modal__button"
      >
        Criar conta
      </z-button>
    </footer>
  </div>
</template>

<script>
import { QueroDealsServices } from '@quero-turbo/services';

export default {
  data() {
    return {
      name: null,
    };
  },
  methods: {
    handleCreateTurboAccount() {
      if (!this.name) return;

      QueroDealsServices.V1.TurboAccount.create({ name: this.name }).then(() => {
        this.$emit('action', true);
      });
    },
  },
};
</script>

<style>
.create-turbo-account-modal__title {
  margin-bottom: var(--space-medium);
}
.create-turbo-account-modal__footer {
  display: flex;
  justify-content: center;
  margin-top: var(--space-large);
}

.create-turbo-account-modal__button:not(:last-child) {
  margin-right: var(--space-medium);
}
</style>
