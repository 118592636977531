import $ from 'jquery';

export default class HeaderDropdown {
  constructor(type, onSelect) {
    this.type = type;
    this.onSelect = onSelect;
    this.setupDropdown();
  }

  setupDropdown() {
    $(`.js-${this.type}-filter-dropdown`).find('.js-selected-value').click((event) => {
      event.preventDefault();
      const selectedFilter = $(event.currentTarget);
      this.selectedValue = selectedFilter;
      if (typeof this.onSelect === 'function') {
        this.onSelect();
      }
    });
  }

  get selectedValue() {
    const selected = this.element.attr(`data-${this.type}`) || null;

    return selected;
  }

  get element() {
    if (!this._element) {
      this._element = $(`.js-selected-${this.type}`);
    }
    return this._element;
  }

  set selectedValue(selectedFilter) {
    const newValue = selectedFilter.attr(`data-${this.type}`);
    this.element.attr(`data-${this.type}`, newValue);
    this.element.find('.js-selected-label').text(newValue);
  }
}
