import { queroAlunosAPI } from '@stock/api/quero-alunos-api.js';
import { RollbarService } from '@shared/utils/rollbar-service';
import { shouldBeOneOf } from '@stock/utils/validator';

/**
 * @deprecated since version 2.0
 * Restock offer by ids
 *
 * @param {Object} params
 * @param {number[]} params.offerIds
 * @param {number} params.amountSeats
 * @param {boolean} params.withRecommendedValue
 * @param {'one-click-actions'} params.source
 * @param {'+' | '-' | '='} params.restockType
 *
 * @typedef Success
 * @property {string} message
 * @property {string} redirect
 *
 * @returns {Promise<Success|Error>}
 *
 */
async function updateRestock({
  offerIds, amountSeats, withRecommendedValue, source, restockType,
}) {
  try {
    shouldBeOneOf(['one-click'])(source);
    shouldBeOneOf(['+', '-', '='])(restockType);

    const { data } = await queroAlunosAPI.updateRestockInline({
      sl: {
        offer_ids: offerIds,
      },
      amount_seats: amountSeats,
      with_recommended_value: withRecommendedValue,
      source,
      restock_type: restockType,
    });

    return {
      message: data.messages,
      redirect: data.redirect,
    };
  } catch (error) {
    RollbarService.getInstance().notifyError(error, {
      offerIds, amountSeats, withRecommendedValue, source, restockType,
    });
    return Promise.reject(new Error('Erro ao fazer a ação de restock'));
  }
}

export const restockService = {
  updateRestock,
};
