import { getDictionaryKeyByText, getDictionaryTextByKey, getDictionaryTextList } from '@shared/utils/dictionary';

const bankList = [
  { code: '001', name: 'BANCO DO BRASIL SA (001)' },
  { code: '003', name: 'BANCO DA AMAZONIA SA (003)' },
  { code: '004', name: 'BANCO DO NORDESTE DO BRASIL SA (004)' },
  { code: '010', name: 'CREDICOAMO CREDITO RURAL COOPERATIVA (010)' },
  { code: '012', name: 'BANCO STANDARD DE INVESTIMENTOS SA (012)' },
  { code: '017', name: 'BNY MELLON BANCO S/A (017)' },
  { code: '018', name: 'BANCO TRICURY S.A. (018)' },
  { code: '021', name: 'BANCO DO ESTADO DO ESPIRITO SANTO SA (021)' },
  { code: '025', name: 'BANCO ALFA S/A (025)' },
  { code: '027', name: 'BANCO DO ESTADO DE SANTA CATARINA SA (027)' },
  { code: '033', name: 'BANCO SANTANDER BANESPA S.A (033)' },
  { code: '036', name: 'BANCO BEM S.A.. (036)' },
  { code: '037', name: 'BANCO DO ESTADO DO PARA SA (037)' },
  { code: '040', name: 'BANCO CARGILL S/A (040)' },
  { code: '041', name: 'BANCO DO ESTADO DO RIO GRANDE DO SUL SA (041)' },
  { code: '047', name: 'BANCO DO ESTADO DE SERGIPE SA (047)' },
  { code: '063', name: 'BANCO BRADESCARD S.A. (063)' },
  { code: '065', name: 'ANDBANK (065)' },
  { code: '069', name: 'BPN BRASIL BANCO MULTIPLO S/A (069)' },
  { code: '070', name: 'BANCO DE BRASILIA SA (070)' },
  { code: '074', name: 'BANCO J. SAFRA S/A (074)' },
  { code: '076', name: 'KDB DO BRASIL (076)' },
  { code: '077', name: 'BANCO INTERMEDIUM S.A. (077)' },
  { code: '079', name: 'BANCO ORIGINAL DO AGRONEGOCIO S/A (079)' },
  { code: '082', name: 'TOPAZIO (082)' },
  { code: '083', name: 'BANCO DA CHINA BRASIL S.A (083)' },
  { code: '084', name: 'UNIPRIME NORTE DO PARANA (084)' },
  { code: '085', name: 'COOPERATIVA CENTRAL DE CREDITO URBANO (085)' },
  { code: '087', name: 'CC UNICRED SANTA CATARINA (087)' },
  { code: '089', name: 'COOPERATIVA DE CREDITO RURAL DA REGIAO D (089)' },
  { code: '090', name: 'COOPERATIVA CENTRAL DE ECONOMIA E CREDIT (090)' },
  { code: '091', name: 'CENTRAL DE COOPERATIVAS DE ECONOMIA E CR (091)' },
  { code: '094', name: 'BANCO PETRA S.A. (094)' },
  { code: '096', name: 'BANCO BMFBOVESPA (096)' },
  { code: '097', name: 'CC CENTRALCREDI (097)' },
  { code: '098', name: 'CERDIALINCA COOPERATIVA CREDITO RURAL (098)' },
  { code: '099', name: 'COOPERATIVA CENTRAL ECON. CRED. MUTUO UN (099)' },
  { code: '104', name: 'CAIXA ECONOMICA FEDERAL SA (104)' },
  { code: '107', name: 'BANCO BBM S.A (107)' },
  { code: '119', name: 'WESTERN UNION (119)' },
  { code: '120', name: 'RODOBENS (120)' },
  { code: '121', name: 'GERADOR (121)' },
  { code: '122', name: 'BANCO BERJ S.A. (122)' },
  { code: '124', name: 'WOORI BANK (124)' },
  { code: '125', name: 'BRASIL PLURAL S.A (125)' },
  { code: '132', name: 'ICBC DO BRASIL (132)' },
  { code: '133', name: 'CC CONFESOL (133)' },
  { code: '136', name: 'CC UNICRED DO BRASIL (136)' },
  { code: '151', name: 'BANCO NOSSA CAIXA (151)' },
  { code: '197', name: 'STONE PAGAMENTOS S.A (197)' },
  { code: '208', name: 'BANCO PACTUAL SA (208)' },
  { code: '212', name: 'BANCO ORIGINAL S/A (212)' },
  { code: '213', name: 'BANCO ARBI SA (213)' },
  { code: '217', name: 'BANCO JOH DEERE S/A (217)' },
  { code: '218', name: 'BANCO BONSUCESSO S.A (218)' },
  { code: '222', name: 'BCO CREDIT AGRICOLE BRASIL SA (222)' },
  { code: '224', name: 'BANCO FIBRA SA (224)' },
  { code: '233', name: 'BANCO CIFRA S.A. (233)' },
  { code: '237', name: 'BANCO BRADESCO S.A (237)' },
  { code: '243', name: 'BANCO STOCK MAXIMA S.A. (243)' },
  { code: '246', name: 'BANCO ABC-BRASIL S.A (246)' },
  { code: '250', name: 'BCV BANCO DE CREDITO E VAREJO S.A. (250)' },
  { code: '254', name: 'BANCO PARANA BANCO SA (254)' },
  { code: '260', name: 'NU PAGAMENTOS (260)' },
  { code: '263', name: 'BANCO CACIQUE SA (263)' },
  { code: '265', name: 'BANCO FATOR SA (265)' },
  { code: '266', name: 'BANCO CEDULA SA (266)' },
  { code: '271', name: 'IB CCTVM LTDA (271)' },
  { code: '274', name: 'MONEY PLUS SCMEPP LTDA' },
  { code: '290', name: 'PAGSEGURO INTERNET S.A (290)' },
  { code: '300', name: 'BANCO DE LA NACION ARGENTINA SA (300)' },
  { code: '318', name: 'BANCO BMG COMERCIAL S.A. (318)' },
  { code: '320', name: 'CCB BRASIL (320)' },
  { code: '323', name: 'Mercado Pago (323)' },
  { code: '336', name: 'BANCO C6 S.A - C6 BANK (336)' },
  { code: '341', name: 'ITAÚ UNIBANCO S.A. (341)' },
  { code: '356', name: 'BANCO ABN AMRO REAL S.A. (356)' },
  { code: '366', name: 'BANCO SOCIETE GENERALE BRASIL S.A (366)' },
  { code: '370', name: 'MIZUHO (370)' },
  { code: '376', name: 'BANCO JP MORGAN S.A. (376)' },
  { code: '389', name: 'BANCO MERCANTIL DO BRASIL SA (389)' },
  { code: '394', name: 'BANCO FINASA BMC S.A (394)' },
  { code: '399', name: 'HSBC BANK BRASIL S.A. - BANCO MULTIPLO (399)' },
  { code: '403', name: 'CORA SOCIEDADE DE CRÉDITO DIRETO S.A. (403)' },
  { code: '412', name: 'BANCO CAPITAL S.A (412)' },
  { code: '422', name: 'BANCO SAFRA SA (422)' },
  { code: '456', name: 'BANCO TOKYO-MITSUBISHI UFJ BRASIL (456)' },
  { code: '464', name: 'BANCO SUMITOMO MITSUI BRASILEIRO S/A (464)' },
  { code: '473', name: 'BANCO CAIXA GERAL BRASIL SA (473)' },
  { code: '487', name: 'BANCO DEUTSCHE BANK SA (487)' },
  { code: '494', name: 'BANCO LA REPUBL.ORIENTAL DEL URUGUAY SA (494)' },
  { code: '495', name: 'BANCO LA PROVINCIA DE BUENOS AIRES SA (495)' },
  { code: '505', name: 'BANCO CREDIT SUISSE (BRASIL) S/A (505)' },
  { code: '600', name: 'BANCO LUSO BRASILEIRO SA (600)' },
  { code: '604', name: 'BANCO INDUSTRIAL DO BRASIL S/A (604)' },
  { code: '610', name: 'BANCO VR S.A (610)' },
  { code: '611', name: 'BANCO PAULISTA S.A. (611)' },
  { code: '612', name: 'BANCO GUANABARA SA (612)' },
  { code: '613', name: 'BANCO PECUNIA S.A. (613)' },
  { code: '623', name: 'PAN (623)' },
  { code: '626', name: 'BANCO FICSA SA (626)' },
  { code: '633', name: 'BANCO RENDIMENTO S.A. (633)' },
  { code: '634', name: 'BANCO TRIANGULO SA (634)' },
  { code: '637', name: 'BANCO SOFISA SA (637)' },
  { code: '643', name: 'BANCO PINE S.A. (643)' },
  { code: '653', name: 'BANCO INDUSVAL SA (653)' },
  { code: '654', name: 'BANCO A.J. RENNER SA (654)' },
  { code: '655', name: 'BANCO VOTORANTIM S.A. SA (655)' },
  { code: '707', name: 'BANCO DAYCOVAL SA (707)' },
  { code: '719', name: 'BANIF-BCO INTERNAC DO FUNCHAL(BR) S.A (719)' },
  { code: '735', name: 'BANCO POTTENCIAL S.A. (735)' },
  { code: '739', name: 'CETELEM (739)' },
  { code: '741', name: 'BANCO RIBEIRAO PRETO SA (741)' },
  { code: '743', name: 'BANCO SEMEAR SA (743)' },
  { code: '745', name: 'BANCO CITIBANK S.A. (745)' },
  { code: '746', name: 'BANCO MODAL SA (746)' },
  { code: '747', name: 'BANCO RABOBANK INTERNATIONAL BR S.A. (747)' },
  { code: '748', name: 'BANCO COOPERATIVO SICREDI S.A (748)' },
  { code: '751', name: 'SCOTIABANK BRASIL S.A. BANCO MULTIPLO (751)' },
  { code: '752', name: 'BANCO BNP PARIBAS BRASIL S/A (752)' },
  { code: '753', name: 'NBC BANK BRASIL SA-BANCO MULTIPLO (753)' },
  { code: '755', name: 'BANCO MERRILL LYNCH S.A. (755)' },
  { code: '756', name: 'BANCO COOPERATIVO DO BRASIL S.A. (756)' },
  { code: '757', name: 'KEB HANA DO BRASIL (757)' },
];

const getBankList = () => Object.freeze(bankList);

const getBankByCode = code => (bankList.find(bank => bank.code === code));

const bankAccountTypeDictionary = [
  {
    text: null,
    key: undefined,
  },
  {
    text: 'Corrente',
    key: 'checking_account',
  },
  {
    text: 'Conjunta',
    key: 'checking_account_joint',
  },
  {
    text: 'Poupança',
    key: 'savings_account',
  },
  {
    text: 'Poupança/Conjunta',
    key: 'savings_account_joint',
  },
];

const getBankAccountTypeKeyByText = text => getDictionaryKeyByText(bankAccountTypeDictionary, text);
const getBankAccountTypeTextList = () => getDictionaryTextList(bankAccountTypeDictionary);
const getBankAccountTypeTextByKey = key => getDictionaryTextByKey(bankAccountTypeDictionary, key);

export const BankBusiness = {
  getBankList,
  getBankByCode,
  getBankAccountTypeKeyByText,
  getBankAccountTypeTextList,
  getBankAccountTypeTextByKey,
};
