import { QueroDealsV1API } from '@quero-turbo/api/quero-deals-v1-api';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Update BRG
 *
 * @param {Object} params
 * @property {text} params.term The term to be searched
 * @property {Array[number]} params.universityIds Ids to be searched
 *
 * @typedef Success
 * @property {string} message
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function update(id, {
  event,
}) {
  return QueroDealsV1API.BRG.updateBRG(id, {
    event,
  })
    .then(({ data }) => data)
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[updateBRG] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

export {
  update,
};
