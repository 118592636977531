import AjaxRequest from '@shared/utils/ajax-request';
import { Step } from '@stock/legacy/components/stepper';
import template from './template.ejs';

const DISABLEMENT_URL = 'ofertas/desabilitar';

export class ConfirmationStep extends Step {
  constructor({ offer, universityOfferId }) {
    super({ template, attributes: { offer, universityOfferId, isDisableAllOffersSelected: false } });
  }

  afterRender() {
    $('.js-back-button').click(() => this.event.emit('previous'));
    $('.js-disablement-mode').change((event) => {
      const isDisableAllOffersSelected = event.target.value === 'disableAllOffers';
      super.setAttribute({ isDisableAllOffersSelected });
      $('.js-alert-disablement').toggleClass('hidden');
    });
    this.$submitButton = $('.js-submit-button');
    this.$submitButton.one('click', (event) => {
      event.preventDefault();
      this.disableOffers();
    });
  }

  disableOffers() {
    const { offer, isDisableAllOffersSelected, universityOfferId } = this.getAttributes();
    const request = new AjaxRequest(DISABLEMENT_URL)
      .on('success', (response) => {
        const { redirect } = response.data;
        super.setAttribute({ success: true, redirect });
      })
      .on('error', () => super.setAttribute({ success: false }))
      .on('complete', () => super.next());
    request.postJson({
      offer_ids: [offer.id],
      with_unlisted_offers: isDisableAllOffersSelected,
      university_offer_id: universityOfferId,
    });
  }
}
