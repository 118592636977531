<template>
  <z-modal
    :class="[
      'offer-creation',
      `offer-creation--${currentStep.size}`
    ]"
    :style="modalCssVars"
    :open="open"
    @closed="closed"
  >
    <div
      class="offer-creation__content"
    >
      <component
        :is="currentStep.component"
        @prev="prev"
        @next="next"
        @finished="closed"
        class="offer-creation__body"
      />
      <Sidebar
        v-if="currentStep.hasSidebar"
        class="offer-creation__sidebar"
      />
    </div>
  </z-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { offerCreationTracking } from '@stock/metrics';
import { LocalStorageStock } from '@stock/utils/local-storage-stock';
import Sidebar from './step-sidebar';
import {
  CourseDetails,
  CourseSelection,
  Introduction,
  OfferDetails,
  OfferMarketplaceDetails,
  ProcessingRequest,
  ReviewOfferRequest,
} from './steps';

export default {
  components: {
    Sidebar,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentStepIndex: 1,
    };
  },
  watch: {
    open() {
      if (this.open === true) {
        offerCreationTracking.viewedModal();
        this.updateTheInitialStep();
      }
    },
  },
  methods: {
    ...mapActions('OfferCreationModule', ['resetOfferCreationState']),
    prev() {
      const newIndex = this.currentStepIndex - 1;
      if (newIndex >= 0) {
        this.trackReturn(newIndex);
        this.currentStepIndex = newIndex;
      }
    },
    next() {
      const newIndex = this.currentStepIndex + 1;
      if (newIndex < this.steps.length) {
        this.currentStepIndex = newIndex;
      }
    },
    closed() {
      this.$emit('closed');
      this.currentStepIndex = 1;
      this.resetOfferCreationState();
    },
    trackReturn(newIndex) {
      offerCreationTracking.steppedBack({
        current: this.currentStep.name,
        returnedTo: this.getStepByIndex(newIndex).name,
      });
    },
    getStepByIndex(index) {
      return this.steps[index];
    },
    canStartFromStepIntro() {
      return !LocalStorageStock.oneTimeMessage('[offer_creation] viewedIntroStep');
    },
    updateTheInitialStep() {
      if (this.canStartFromStepIntro()) {
        this.currentStepIndex = 0;
      }
    },
  },
  computed: {
    currentStep() {
      return this.getStepByIndex(this.currentStepIndex);
    },
    modalCssVars() {
      return {
        '--oc-modal-overflow': this.currentStep.modalOverflow,
      };
    },
    steps() {
      return [
        {
          component: Introduction,
          size: 'normal',
          name: 'Introduction',
          modalOverflow: 'auto',
        },
        {
          component: CourseSelection,
          size: 'normal',
          name: 'CourseSelection',
          modalOverflow: 'initial',
        },
        {
          component: CourseDetails,
          size: 'normal',
          hasSidebar: true,
          name: 'CourseDetails',
          modalOverflow: 'auto',
        },
        {
          component: OfferDetails,
          size: 'normal',
          hasSidebar: true,
          name: 'OfferDetails',
          modalOverflow: 'auto',
        },
        {
          component: OfferMarketplaceDetails,
          size: 'normal',
          hasSidebar: true,
          name: 'OfferMarketplaceDetails',
          modalOverflow: 'auto',
        },
        {
          component: ReviewOfferRequest,
          size: 'normal',
          name: 'ReviewOfferRequest',
          modalOverflow: 'auto',
        },
        {
          component: ProcessingRequest,
          size: 'small',
          name: 'ProcessingRequest',
          modalOverflow: 'auto',
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import "@quero/zilla-core/src/utils/_index.scss";

.offer-creation {
  &.z-modal {
    z-index: 1001;
  }

  .z-modal__dialog {
    @media (min-width: $screen-desktop) {
      overflow-y: var(--oc-modal-overflow);
    }
  }

  .z-modal__wrapper {
    transition: max-width 250ms ease;
  }

  .z-modal__close-icon {
    height: calc(var(--size-extra-large));
    width: calc(var(--size-extra-large));

    @media (min-width: $screen-desktop) {
      height: calc(var(--size-extra-large) * 1.25);
      width: calc(var(--size-extra-large) * 1.25);
    }

    & > .z-icon__svg {
      font-size: var(--size-large);
      height: var(--size-large);

      @media (min-width: $screen-desktop) {
        font-size: var(--size-extra-large);
        height: var(--size-extra-large);
      }
    }
  }
}
.offer-creation--normal {
  .z-modal__wrapper {
    @media (min-width: $screen-desktop) {
      --modal-width: 1216px;
      width: 100%; // TODO: Remove this when zilla updates to customize width
    }
  }
}

.offer-creation--small {
  .z-modal__wrapper {
    @media (min-width: $screen-desktop) {
      --modal-width: 384px;
      width: 100%; // TODO: Remove this when zilla updates to customize width
    }
  }
}

.offer-creation__content {
  display: flex;
}

.offer-creation__body{
  flex: 1;
}

.offer-creation__sidebar{
  flex: 0 0 280px;
  margin-bottom: calc(-1 * var(--space-extra-large));
  margin-left: var(--space-extra-large);
  margin-right: calc(-1 * var(--space-extra-large));
  margin-top: calc(-1 * var(--space-extra-large));
  padding: var(--space-jumbo);
  @media (min-width: $screen-desktop) {
    margin-top: calc(-1 * var(--space-jumbo));
    margin-right: calc(-1 * var(--space-jumbo));
    margin-bottom: calc(-1 * var(--space-jumbo));
    margin-left: var(--space-jumbo);
  }
}
</style>
