import EventEmitter from 'events';
import { setupPopover } from '@stock/legacy/components/popover';
import { InlineOperations } from '@stock/legacy/components/modals/offer-list-actions/inline-steps';
import template from './popover-stock-availability-template.ejs';

const targetPopoverSelector = '.js-target-popover-stock-availability';
const offerTableLineSelector = '.js-offer-table-line';
const popoverSelector = '.js-popover';
const popoverBtnSelector = '.js-course-data-section';

export function initializePopoverStockAvailability() {
  const event = new EventEmitter();
  const utils = {
    getAvailabilitySeatsMessage,
    getCompetitiveLabel,
    getIconClassModifier,
    getPaidSeatsMessage,
    getTotalSeatsMessage,
  };

  $(offerTableLineSelector).each((_, $offerTableLine) => {
    const $targetPopover = $($offerTableLine).find(targetPopoverSelector);
    const { offerTableItem, permissions } = $($offerTableLine).data();
    const { offers } = $targetPopover.data();

    setupPopover({
      container: '#js-offer-table',
      html: true,
      placement: 'bottom',
      targetPopover: $targetPopover,
      template: template({ offers, ...utils }),
      closeWhenClickOutside: true,
      displayOnlyOneAtATime: true,
    }).on('before.showing', () => {
      $(targetPopoverSelector).not($targetPopover).popover('hide');
      event.emit('popover.show', { $targetPopover });
    }).on('after.showing', () => {
      $(popoverSelector).find(popoverBtnSelector).on('click', () => {
        InlineOperations.showStockActionModal({ offerTableItem, permissions }, { target: 'popover' });
      });
    }).on('before.hiding', () => {
      $(popoverSelector).find(popoverBtnSelector).off('click');
    });
  });
  return event;
}

export function closeAllPopoverStockAvailability() {
  $(offerTableLineSelector).find(targetPopoverSelector).popover('hide');
}

const labelDictionary = {
  high: {
    color: 's-popover-availability__icon--high',
    competitiveLabel: 'Preço muito competitivo',
  },
  medium: {
    color: 's-popover-availability__icon--medium',
    competitiveLabel: 'Preço competitivo',
  },
  low: {
    color: 's-popover-availability__icon--low',
    competitiveLabel: 'Preço pouco competitivo',
  },
  wip: {
    color: 's-popover-availability__icon--wip',
    competitiveLabel: 'Em análise',
  },
};

function getIconClassModifier(offer) {
  let option = 'wip';
  if (offer.competitiveAnalyses) {
    const { label } = offer.competitiveAnalyses;
    option = label;
  }
  return labelDictionary[option].color;
}

function getCompetitiveLabel(offer) {
  let option = 'wip';
  if (offer.competitiveAnalyses) {
    const { label } = offer.competitiveAnalyses;
    option = label;
  }
  return labelDictionary[option].competitiveLabel;
}

function getAvailabilitySeatsMessage(offer) {
  const { availableSeats, totalSeats } = offer.seatsBalance;
  const suffix = totalSeats > 1 ? 's' : '';
  return `${availableSeats} de ${totalSeats} vaga${suffix}`;
}

function getPaidSeatsMessage(offer) {
  const { paidSeats } = offer.seatsBalance;
  const suffix = paidSeats !== 1 ? 's' : '';
  return `${paidSeats} vaga${suffix} vendida${suffix}`;
}

function getTotalSeatsMessage(offer) {
  const { totalSeats, limited } = offer.seatsBalance;
  if (limited) {
    const suffix = totalSeats !== 1 ? 's' : '';
    return `${totalSeats} vaga${suffix} ofertada${suffix}`;
  }
  return 'Vagas ilimitadas';
}
