<template>
  <div class="upa-list">
    <z-text
      class="upa-list__title"
      size="small"
      weight="bold"
      v-html="title"
      v-if="title"
    />

    <div
      v-for="(element, index) in items"
      :key="index"
      v-html="element"
      class="upa-list__content"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.upa-list__title {
  margin-bottom: var(--space-small);
}

.upa-list__content {
  white-space: pre-line;
  font-size: var(--size-font-small);

  &:not(:last-child) {
    margin-bottom: var(--space-medium);
  }
}
</style>
