import Vue from 'vue';
import VueTour from 'vue-tour';
import { notifierMixin } from '@stock/utils/mixins/notifier';
import { store } from '@stock/store';
import { ValidatePlugin } from '@shared/utils/vue/plugins/validate-plugin';
import { FiltersPlugin } from '@shared/utils/vue/plugins/filters-plugin';
import { ZillaPlugin } from '@shared/utils/vue/plugins/zilla-plugin';
import { ComponentsPlugin } from '@shared/utils/vue/plugins/components-plugin';
import { TrackerPlugin } from '@shared/utils/vue/plugins/tracker-plugin';
import { mapActions } from 'vuex';

import { CampusCreationModalInline } from '@stock/modules/campus-creation/index';
import { CampusCreationModalBatch } from '@stock/modules/campus-creation-batch/index';
import CardCreateCampus from './card-create-campus';

export function CampusPage() {
  return new Promise((resolve) => {
    Vue.use(TrackerPlugin);
    Vue.use(ValidatePlugin);
    Vue.use(VueTour);
    Vue.use(FiltersPlugin);
    Vue.use(ZillaPlugin);
    Vue.use(ComponentsPlugin);


    window.vm = new Vue({
      el: '#js-my-campuses-page',
      store,
      components: {
        CardCreateCampus,
        CampusCreationModalInline,
        CampusCreationModalBatch,
      },
      mixins: [notifierMixin],
      data: {
        visibilityOfTheCampusCreationInline: false,
        visibilityCampusCreationBatch: false,
      },

      mounted() {
        resolve();
      },

      methods: {
        ...mapActions('SessionModule', ['setup']),
        openCampusCreationModal() {
          hj('trigger', 'opened-campus-creation-modal-inline');
          this.visibilityOfTheCampusCreationInline = true;
        },
        closeCampusCreationModalInline() {
          this.visibilityOfTheCampusCreationInline = false;
        },
        setupOfflineMode() {
          window.addEventListener('offline', (_event) => {
            this.notifyOfflineMessage();
          });
        },
        openOfferCreationBatchPage() {
          hj('trigger', 'opened-campus-creation-modal-batch');
          this.visibilityCampusCreationBatch = true;
        },
        closeCampusCreationModalBatch() {
          this.visibilityCampusCreationBatch = false;
        },
      },
    });
  });
}
