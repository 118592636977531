import { UniversityPanelCoreAPI } from '@university-panel/api/university-panel-core-api';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Destroy current session
 *
 * @typedef Success
 * @property {string} message
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function destroy() {
  return UniversityPanelCoreAPI.Session.destroySession()
    .then(({ data }) => data)
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[destroySession] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

export {
  destroy,
};
