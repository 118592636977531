import { AdmissionConfig } from './admission-config';
import { BillingConfig } from './billing-config';
import { BRG } from './brg';
import { CommissionConfig } from './commission-config';
import { Deal } from './deal';
import { OfferFilter } from './offer-filter';
import { ProductConfig } from './product-config';
import { TurboAccount } from './turbo-account';

const V1 = {
  AdmissionConfig,
  BillingConfig,
  BRG,
  CommissionConfig,
  Deal,
  OfferFilter,
  ProductConfig,
  TurboAccount,
};

export { V1 };
