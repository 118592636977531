import DoubleLinkedNode from '@shared/utils/structs/double-linked-node';
import EventEmitter from 'events';


export default class Step extends DoubleLinkedNode {
  constructor({ template, attributes = {} }) {
    super({ attributes });
    this.template = template;
    this.event = new EventEmitter();
  }

  render(additionalAttr = {}) {
    return this.template({ step: additionalAttr, ...this.getAttributes() });
  }

  /**
   * @param {Object} value
   *
   * @returns void
   */
  setAttribute(value) {
    this.data.attributes = { ...this.data.attributes, ...value };
  }

  /**
   * returns {Object}
   */
  getAttributes() {
    if (typeof this.data.attributes === 'function') {
      return this.data.attributes();
    }
    return this.data.attributes;
  }

  afterRender() {}

  /**
   * @param {String} event
   * @param {Function} callback
   *
   * @returns {EventEmitter}
   */
  onHook(event, callback) {
    return this.event.on(event, callback);
  }

  /**
   * returns boolean
   */
  previous() {
    return this.event.emit('previous');
  }

  /**
   * returns boolean
   */
  next() {
    return this.event.emit('next');
  }
}
