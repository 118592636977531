const invalidChar = /[^\d.,-]/g;
const invalidStartChar = /^[-,.0]/;
const dot = /\./;
const invalidDuplicatedValidChar = /([,-])+/g;
const numberGreaterThan200 = /([2-9]\d)\d/;
const threeDigitNumbersNotEndingWithZero = /(1\d)[1-9]/;
const numberBetween110And199 = /(1[1-9])\d/;
const sepPosition = /([^-]+-[^-]+)(-)/;
const numberSizeAfterComma = /(,\d{2})(\d)/;
const limitNumberSizeBeforeComma = /(\d{3})(\d)/;
const commaPosition = /(\d{1,3},\d{1,3})(,)/;
const invalidComma = /(100),/;
const lastCharInvalid = /[,-]$/;

function fixDiscountFieldValue($field) {
  const value = $field.val();
  if (value.match(lastCharInvalid)) {
    $field.val(value.slice(0, -1));
  }
}

function standardizeValue(value) {
  return value
    .replace(invalidChar, '')
    .replace(invalidStartChar, '')
    .replace(dot, ',')
    .replace(invalidDuplicatedValidChar, '$1')
    .replace(numberGreaterThan200, '$1')
    .replace(threeDigitNumbersNotEndingWithZero, '$1')
    .replace(numberBetween110And199, '$1')
    .replace(sepPosition, '$1')
    .replace(numberSizeAfterComma, '$1')
    .replace(limitNumberSizeBeforeComma, '$1')
    .replace(commaPosition, '$1')
    .replace(invalidComma, '$1');
}

function setupDiscountField($field) {
  $field.on('blur', () => fixDiscountFieldValue($field));
  $field.on('input', () => {
    const value = standardizeValue($field.val());
    $field.val(value);
  });
}

export { setupDiscountField, fixDiscountFieldValue };
