import {
  Step01Documents,
  Step02PaymentInfo,
  Step03Enrollment,
  Step04Review,
} from './steps';

const StepsNavigationSetup = {
  step01: {
    component: Step01Documents,
    position: 1,
    nextStepName: 'step02',
    title: 'Configure seu Quero Pago',
    sidebar: false,
  },
  step02: {
    component: Step02PaymentInfo,
    position: 2,
    prevStepName: 'step01',
    nextStepName: 'step03',
    title: 'Configure seu Quero Pago',
    sidebar: true,
  },
  step03: {
    component: Step03Enrollment,
    position: 2,
    prevStepName: 'step02',
    nextStepName: 'step04',
    title: 'Configure seu Quero Pago',
    sidebar: true,
  },
  step04: {
    component: Step04Review,
    position: 3,
    prevStepName: 'step03',
    title: 'Configure seu Quero Pago',
    sidebar: false,
  },
};

export { StepsNavigationSetup };
