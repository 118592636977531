<template>
  <div
    class="oc-batch-sheets-table-item"
    :class="{
      'oc-batch-sheets-table-item--is-loading': internalStatus === 'creating',
      'oc-batch-sheets-table-item--is-the-newest': newestSheet,
    }"
  >
    <div class="oc-batch-sheets-table-item__section oc-batch-sheets-table-item__section--name">
      <z-text
        class="oc-batch-sheets-table-item__text"
        color="info"
        size="medium"
        weight="bold"
      >
        {{ name }}
      </z-text>
    </div>

    <div class="oc-batch-sheets-table-item__section oc-batch-sheets-table-item__section--date">
      <z-text
        size="small"
        weight="bold"
        class="oc-batch-sheets-table-item__text"
      >
        {{ createdAt }}
      </z-text>

      <z-text
        size="micro"
        class="oc-batch-sheets-table-item__text"
        color="minor"
      >
        {{ author }}
      </z-text>
    </div>

    <div class="oc-batch-sheets-table-item__section oc-batch-sheets-table-item__section--status">
      <z-icon
        :icon="icon"
        class="oc-batch-sheets-table-item__icon"
        :class="{
          [`oc-batch-sheets-table-item__icon--${color}`] : color
        }"
      />

      <z-text
        size="small"
        class="oc-batch-sheets-table-item__text"
        weight="bold"
      >
        {{ statusParsed }}
      </z-text>
    </div>

    <div class="oc-batch-sheets-table-item__section oc-batch-sheets-table-item__section--action">
      <z-button
        @click="handleViewSheet"
        behavior="block"
        size="small"
        v-if="internalStatus === 'pending' || internalStatus === 'finished'"
        variant="tertiary"
      >
        Visualizar
      </z-button>

      <z-button
        @click="handleEditSheet"
        behavior="block"
        size="small"
        v-if="internalStatus === 'creating' || internalStatus === 'created'"
        :is-loading="internalStatus === 'creating'"
        :is-disabled="internalStatus === 'creating'"
      >
        Adicionar
      </z-button>

      <z-button
        @click="handleEditSheet"
        behavior="block"
        size="small"
        v-if="internalStatus === 'editing'"
        variant="secondary"
      >
        Continuar editando
      </z-button>
    </div>
  </div>
</template>

<script>
import { showOfferCreationSpreadsheet } from '@stock/services/show-offer-creation-spreadsheet';
import { updateStatusOfferCreationSpreadsheet } from '@stock/services/update-status-offer-creation-spreadsheet';
import { SheetModel } from './offer-creation-batch-models';

export default {
  props: {
    sheet: SheetModel,
    name: {
      type: String,
      required: true,
    },
    universityUserName: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
    sheetUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    statusDictionary() {
      return {
        creating: {
          color: 'info',
          icon: ['fal', 'spinner fa-spin'],
          text: 'Criando planilha',
        },
        created: {
          color: 'info',
          icon: ['fal', 'book-reader'],
          text: 'Adicione suas ofertas',
        },
        editing: {
          color: 'info',
          icon: ['fal', 'clock'],
          text: 'Em edição',
        },
        pending: {
          color: 'success',
          icon: ['fal', 'clock'],
          text: 'Ofertas sendo criadas',
        },
        finished: {
          color: 'success',
          icon: ['fal', 'check-circle'],
          text: 'Publicada',
        },
        error: {
          color: 'error',
          icon: ['fal', 'times'],
          text: 'Ocorreu um problema',
        },
        failed: {
          color: 'error',
          icon: ['fal', 'times'],
          text: 'Ocorreu um problema',
        },
      };
    },
    statusParsed() {
      return this.statusDictionary[this.internalStatus].text;
    },
    icon() {
      return this.statusDictionary[this.internalStatus].icon;
    },
    color() {
      return this.statusDictionary[this.internalStatus].color;
    },
    author() {
      return `por ${this.universityUserName}`;
    },
  },
  data() {
    return {
      internalStatus: null,
      internalSheetUrl: null,
      requestCount: 0,
      newestSheet: false,
    };
  },
  created() {
    this.internalStatus = this.status;
    this.internalSheetUrl = this.sheetUrl;
  },
  watch: {
    internalStatus(value) {
      if (value === 'creating') {
        this.timer = setInterval(this.handlerForCreatingStatus, 3000);
      } else if (this.timer) {
        clearInterval(this.timer);
        this.requestCount = 0;
      }
    },
  },
  methods: {
    _requestShowOfferCreationSpreadsheet(id) {
      return showOfferCreationSpreadsheet({ id })
        .then(({ offerCreationSheet }) => ({
          status: offerCreationSheet.status,
          sheetUrl: offerCreationSheet.sheetUrl,
        }));
    },
    _requestUpdateStatusOfferCreationSpreadsheet({ id, status }) {
      return updateStatusOfferCreationSpreadsheet({ id, status });
    },
    handlerForCreatingStatus() {
      this.requestCount = this.requestCount + 1;
      this._requestShowOfferCreationSpreadsheet(this.sheet.id)
        .then(({ status, sheetUrl }) => {
          if (status === 'created') {
            this.internalStatus = status;
            this.internalSheetUrl = sheetUrl;
            this.newestSheet = true;
            this.$emit('created-sheet');
          } else if (status === 'error') {
            this.internalStatus = status;
            this.$emit('create-sheet-error');
          } else if (this.timer && this.requestCount >= 4) {
            clearInterval(this.timer);
          }
        });
    },
    handlerForCreatedStatus() {
      this._requestUpdateStatusOfferCreationSpreadsheet({ id: this.sheet.id, status: 'editing' })
        .then(() => {
          this.newestSheet = false;
          this.internalStatus = 'editing';
        });
    },
    handleEditSheet() {
      if (this.internalSheetUrl) {
        this.$emit('edit-sheet', { ...this.sheet, sheetUrl: this.internalSheetUrl });
        if (this.internalStatus === 'created') this.handlerForCreatedStatus();
      }
    },
    handleViewSheet() {
      if (this.internalSheetUrl) {
        this.$emit('view-sheet', { ...this.sheet, sheetUrl: this.internalSheetUrl });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.oc-batch-sheets-table-item {
  border-bottom: var(--space-nano) solid var(--color-neutral-lighter-gray);
  display: flex;
  padding: var(--space-large);
  position: relative;

  &:hover {
    background-color: var(--color-background-major);
  }
}

.oc-batch-sheets-table-item--is-loading {
  background-color: var(--color-background-major);

  &::before {
    animation: is-passing 1s ease-in infinite;
    background: linear-gradient(
      to left,
      transparent,
      #fff 50%,
      transparent
    );
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 50%;
    z-index: 1;
  }
}

.oc-batch-sheets-table-item--is-the-newest {
  box-shadow: var(--card-high-elevation);
  background-color: var(--color-support-info-clear);
}

@keyframes is-passing {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(140%);
  }
}

.oc-batch-sheets-table-item__section {
  align-items: center;
  display: flex;
  margin-right: var(--space-large);
  &:last-child {
    margin-right: 0;
  }
}

.oc-batch-sheets-table-item__section--name {
  flex: 2;
}

.oc-batch-sheets-table-item__section--date {
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
}

.oc-batch-sheets-table-item__section--status {
  flex: 2;
}

.oc-batch-sheets-table-item__section--action {
  flex: 1;
  justify-content: flex-end;
}

.oc-batch-sheets-table-item__text {
  margin: 0;
}

.oc-batch-sheets-table-item__icon {
  margin-right: var(--space-small);
}

.oc-batch-sheets-table-item__icon--info {
  color: var(--color-support-info-pure);
}

.oc-batch-sheets-table-item__icon--success {
  color: var(--color-support-success-pure);
}

.oc-batch-sheets-table-item__icon--error {
  color: var(--color-support-danger-pure);
}
</style>
