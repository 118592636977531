export const OPTIONS = {
  behavior: {
    default: '',
    inline: 'z-input-field--inline',
  },
  inputVariant: {
    default: '',
    withIcon: 'z-input--with-icon',
  },
  size: {
    default: '',
    small: 'z-input--small',
    large: 'z-input--large',
  },
  state: {
    default: '',
    error: 'z-input--has-error',
  },
  disabled: {
    default: '',
    disabled: 'disabled',
  },
  type: {
    default: 'text',
    number: 'number',
    password: 'password',
  },
};

export const DEFAULT_OPTIONS = {
  behavior: OPTIONS.behavior.default,
  inputVariant: OPTIONS.inputVariant.default,
  size: OPTIONS.size.default,
  state: OPTIONS.state.default,
  disabled: OPTIONS.disabled.default,
  type: OPTIONS.type.default,
  placeholder: '',
  valueText: '',
  errorText: '',
  layoutClass: '',
  name: '',
  id: '',
  autofocus: false,
};
