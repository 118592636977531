<template>
  <z-input-field label="Email do Responsável">
    <z-autocomplete
      :internal-search="false"
      :multiple="false"
      :options="userList"
      :value="selectedUserId"
      @input="userInputHandle($event)"
      @remove="userRemoveHandle($event)"
      @select="userSelectHandle($event)"
      :hide-selected="true"
      label-by="text"
      v-model="value"
    />
  </z-input-field>
</template>

<script>
import { searchUniversityUsersEmail } from '@shared/services/autocompletes/university_users_email';

export default {
  props: {
    label: {
      type: String,
      default: 'Usuário',
    },
    value: {
      type: Object || String,
      required: true,
    },
  },
  data() {
    return {
      userList: [],
    };
  },
  computed: {
    selectedUserId() {
      return this.value;
    },
  },
  methods: {
    userInputHandle($event) {
      this.requestSearchUserEmail($event);
    },
    userSelectHandle($event) {
      if (!$event) return;

      const selectedEmail = $event.email;
      if (!this.isValidEmail(selectedEmail)) {
        this.value = null;
        return;
      }

      this.$emit('select', $event);
    },
    userRemoveHandle($event) {
      this.$emit('remove', $event);
    },
    requestSearchUserEmail(term) {
      searchUniversityUsersEmail(term).then(({ results }) => {
        this.userList = results;
      });
    },
    isValidEmail(selectedEmail) {
      const validEmails = this.userList.map(user => user.email);
      return validEmails.includes(selectedEmail);
    },
  },
  mounted() {
    this.requestSearchUserEmail('');
  },
};
</script>
