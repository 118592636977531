import { round } from 'lodash';
import parse from './number-parser';

export default class NumberFormat {
  constructor(config = {}) {
    this.decimalSeparator = config.decimalSeparator || ',';
    this.thousandsSeparator = config.thousandsSeparator || '.';
  }

  toPercentage(value, config = {}) {
    const sign = config.signed && value > 0 ? '+' : '';
    const precision = config.precision || 1;
    const newValue = round(value, precision);

    return `${sign}${newValue.toFixed(precision).toString().replace('.', this.decimalSeparator || config.decimalSeparator)}%`;
  }

  toPrice(value) {
    if (value === null) {
      return 'N/A';
    }
    const newValue = round(value, 2);
    return `R$ ${newValue.toFixed(2).replace('.', this.decimalSeparator)}`;
  }

  fromPrice(text, config) {
    return parse(text.replace(/\s/gm, '').replace(/^\w+\$/i, ''), config);
  }

  fromPercentage(text, config) {
    return parse(text.replace(/\s/gm, '').replace(/%/g, ''), config);
  }

  /*
   * Convert a given INTEGER number and how mutch digits is needed to a format with the following patterns:
   *
   *  525 -> 525
   *  1000 -> 1k
   *  1155 -> 1.1k
   *  2.230.000 -> 2.2m
   *
   */
  toPowerOfTen(num, digits) {
    const si = [
      { value: 1, symbol: '' },
      { value: 1E3, symbol: 'k' },
      { value: 1E6, symbol: 'm' },
      { value: 1E9, symbol: 'b' },
      { value: 1E12, symbol: 't' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i -= 1) {
      if (num >= si[i].value) {
        break;
      }
    }
    const result = (num / si[i].value)
      .toFixed(digits)
      .replace(rx, '$1')
      .replace('.', this.decimalSeparator);

    return result + si[i].symbol;
  }

  parse(value) {
    const nonstandard = /[^\d,.+-]/;
    const cleanValue = String(value)
      .replace(nonstandard, '')
      .replace(',', '.');
    return parseFloat(cleanValue);
  }
}

export function currencyFormat(value) {
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
}

export function decimalFormat(value) {
  return Intl.NumberFormat('pt-BR', {
    style: 'decimal',
  }).format(value);
}

export function percentFormat(value) {
  return Intl.NumberFormat('pt-BR', {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(value);
}
