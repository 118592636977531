import $ from 'jquery';
import { ZToast } from '@shared/legacy/components/zilla/z-toast';

const initializeAlerts = () => {
  // Initialize all pre-configured alerts
  const $pgcAlert = $('.js-pgc-alert-container');
  const parseTypeAlert = (type) => {
    if (type === 'error') return 'danger';
    if (type === 'default') return 'info';
    return type;
  };

  $pgcAlert.each((i, obj) => {
    const { type, text } = $(obj).data();
    if (type && text) {
      ZToast.showAlert(text, {
        type: parseTypeAlert(type),
      });
    }
  });
};

export { initializeAlerts };
