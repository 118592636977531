<template>
  <div
    :class="{
      'switch-button': true,
      'switch-button--checked': checked,
      'switch-button--disabled': disabled,
    }"
  >
    <z-checkbox
      v-if="type === 'checkbox'"
      :class="{
        'switch-button__label': true,
        'switch-button__label--disabled': disabled,
      }"
      :value="value"
      :checked="checked"
      @change="handlerComponentChanged"
      :disabled="disabled"
    >
      {{ label }}
    </z-checkbox>

    <z-radio
      v-if="type === 'radio'"
      :name="name"
      :class="{
        'switch-button__label': true,
        'switch-button__label--disabled': disabled,
      }"
      :label="label"
      :value="value"
      :checked="checked"
      @change="handlerComponentChanged"
    >
      {{ label }}
    </z-radio>
  </div>
</template>

<script>
import { shouldBeOneOf } from 'vue-prop-validation-helper';

export default {
  props: {
    type: {
      type: String,
      required: true,
      validator: shouldBeOneOf(['checkbox', 'radio']),
    },
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: 'switch-button',
    },
  },
  methods: {
    handlerComponentChanged(event) {
      this.$emit('change', event);
    },
  },
};
</script>

<style lang="scss">
.switch-button {
  border-radius: var(--size-border-radius-medium);
  border: 1px solid var(--color-gray-200);
  display: flex;
  margin-right: var(--space-small);
  padding: var(--space-small) var(--space-medium);
}

.switch-button--checked {
  background-color: var(--color-blue-000);
  border-color: var(--color-blue-300);
}

.switch-button--disabled {
  background-color: var(--color-gray-200);
}

.switch-button__label {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  & > .z-checkbox__check-wrapper {
    margin-right: var(--space-small);
  }
}

.switch-button__label--disabled {
  color: var(--color-gray-400);
}
</style>
