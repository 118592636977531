<template>
  <z-card>
    <z-title
      size="small"
      class="create-brg-form__title"
    >
      Criando uma nova configuração da negociação (BRG)
    </z-title>

    <form @submit.prevent="handleFormSubmit">
      <GridContainer class="create-brg-form__row">
        <GridItem :column="3">
          <z-input-field label="Nome *">
            <z-input v-model="name" />
          </z-input-field>
        </GridItem>

        <GridItem :column="3">
          <z-input-field label="Prioridade *">
            <z-input
              type="number"
              v-model="priority"
            />
          </z-input-field>
        </GridItem>

        <GridItem :column="3">
          <z-input-field label="Selecionar negociação *">
            <z-autocomplete
              :options="dealsList"
              :value="dealSelected"
              @clear="dealSelected = null"
              @select="dealSelected = $event"
              clear-button-aria-label="Limpar"
              hide-selected
              label-by="name"
            />
          </z-input-field>
        </GridItem>

        <GridItem :column="3">
          <z-input-field label="Esta configuração é piloto?">
            <z-toggle-switch
              v-model="pilot"
            />
          </z-input-field>
        </GridItem>
      </GridContainer>

      <GridContainer class="create-brg-form__row">
        <GridItem class="create-brg-form__action-section">
          <z-button
            tag="router-link"
            to="/brgs"
            variant="secondary"
            class="create-brg-form__action-section-button"
          >
            Voltar
          </z-button>

          <z-button
            class="create-brg-form__action-section-button"
            type="submit"
          >
            Criar configuração da negociação
          </z-button>
        </GridItem>
      </GridContainer>
    </form>

    <FeedbackModal
      :config="feedbackCardsConfig"
      :open="feedbackModalOpened"
      :closable="false"
      @success-click="handleSuccessClick"
      @error-click="handleErrorClick"
      :status="feedbackModalStatus"
    />
  </z-card>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { QueroDealsServices } from '@quero-turbo/services';
import { FeedbackModal } from '@stock/components/feedback-components';

export default {
  components: {
    FeedbackModal,
    GridContainer,
    GridItem,
  },
  data() {
    return {
      name: '',
      priority: null,
      dealSelected: null,
      dealsList: [],
      brg: null,
      pilot: false,
      feedbackModalOpened: false,
      feedbackModalStatus: 'loading',
      errorMessage: '',
    };
  },
  computed: {
    dealId() {
      return (this.dealSelected && this.dealSelected.id) || null;
    },
    brgId() {
      return (this.brg && this.brg.id) || null;
    },
    feedbackCardsConfig() {
      return {
        success: {
          title: 'BRG criada!',
          buttonText: 'Visualizar configuração',
        },
        error: {
          title: this.errorMessage,
          buttonText: 'Ok, entendi',
        },
        loading: {
          title: 'Aguarde, estamos analisando o formulário',
        },
      };
    },
  },
  methods: {
    handleFormSubmit() {
      const data = {
        dealId: this.dealId,
        name: this.name,
        priority: this.priority,
        pilot: this.pilot,
      };
      this.requestCreateBRG(data);
    },
    requestCreateBRG(params) {
      try {
        this.feedbackModalOpened = true;
        this.feedbackModalStatus = 'loading';
        this.isValidRequest();
        QueroDealsServices.V1.BRG.create(params).then(({ result }) => {
          this.brg = result.businessRuleGroup;
          this.feedbackModalStatus = 'success';
        });
      } catch (error) {
        this.errorMessage = error.message;
        this.feedbackModalStatus = 'error';
      }
    },
    isValidRequest() {
      if (this.dealId && this.name && this.priority) return true;
      throw new Error('Ops! Encontramos alguns erros no formulário');
    },
    requestGetAllDeals() {
      QueroDealsServices.V1.Deal.getAll().then(({ deals }) => {
        this.dealsList = deals.map(element => ({ ...element, name: `${element.id} - ${element.name}` }));
      });
    },
    handleSuccessClick() {
      this.feedbackModalOpened = false;
      this.$router.push(`/brgs/${this.brgId}`);
    },
    handleErrorClick() {
      this.feedbackModalOpened = false;
      this.feedbackModalStatus = 'loading';
      this.errorMessage = '';
    },
  },
  mounted() {
    this.requestGetAllDeals();
  },
};
</script>

<style>
.create-brg-form__title {
  margin-bottom: var(--space-medium);
}

.create-brg-form__row {
  --grid-padding: 0;
}

.create-brg-form__action-section {
  display: flex;
  justify-content: flex-end;
}

.create-brg-form__action-section-button {
  margin-left: var(--space-medium);
}

.z-input-field__label {
  display: block;
}
</style>
