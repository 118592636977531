<template>
  <UPAModalHeader
    :current-step-number="currentStepNumber"
    :max-step-number="maxStepNumber"
    :name="name"
    :subtitle="subtitle"
    :title="title"
  />
</template>

<script>
import { UPAModalHeader } from '@shared/components/upa-modal';

export default {
  components: {
    UPAModalHeader,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    currentStepNumber: {
      type: Number,
      default: 1,
    },
    maxStepNumber: {
      type: Number,
      default: null,
    },
  },
};
</script>
