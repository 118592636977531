import AmplitudeService from '@shared/utils/amplitude-service';
import { shouldBeOneOf } from '@stock/utils/validator';

const amplitude = AmplitudeService.getInstance();

const _logEvent = (operation, eventProps) => {
  const baseMessage = `Open Inline ${operation} Modal`;
  return amplitude.logEvent(baseMessage, eventProps);
};

const _validateSelectedWidgetValue = (selectedWidget) => {
  shouldBeOneOf(
    ['restockWidget', 'withoutWidget', 'optimizeOfferWidget'],
  )(selectedWidget);
};

const trackShowRestockModal = ({ source, selectedWidget, withRecommendedValue }) => {
  _validateSelectedWidgetValue(selectedWidget);
  _logEvent('Restock', { source, selectedWidget, withRecommendedValue });
};

const trackShowPricingModal = ({ source, selectedWidget }) => {
  _validateSelectedWidgetValue(selectedWidget);
  _logEvent('Pricing', { source, selectedWidget });
};

const trackShowDisablementModal = ({ source, selectedWidget }) => {
  _validateSelectedWidgetValue(selectedWidget);
  _logEvent('Disablement', { source, selectedWidget });
};

const trackShowDiscountChangeModal = ({ source, selectedWidget }) => {
  _validateSelectedWidgetValue(selectedWidget);
  _logEvent('Discount', { source, selectedWidget });
};

const trackShowStockActionModal = ({ source, target }) => {
  _logEvent('Detail', { source, target });
};

const trackShowOptimizeOfferModal = ({ source }) => {
  _logEvent('Optimize Offer', { source });
};

export const inlineOperationsTracking = {
  trackShowStockActionModal,
  trackShowDisablementModal,
  trackShowDiscountChangeModal,
  trackShowPricingModal,
  trackShowRestockModal,
  trackShowOptimizeOfferModal,
};
