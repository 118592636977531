import AmplitudeService from '@shared/utils/amplitude-service';

class PushNotification {
  constructor() {
    this.requestPermission();
  }

  requestPermission() {
    if (typeof Notification !== 'undefined') {
      Notification.requestPermission();
      this.onChangePermission();
    }
  }

  onChangePermission() {
    if (window.navigator && window.navigator.permissions) {
      window.navigator.permissions.query({ name: 'notifications' }).then((permission) => {
        // eslint-disable-next-line no-param-reassign
        permission.onchange = () => {
          AmplitudeService.getInstance().logEvent('notification request response', { permission: permission.state });
        };
      });
    }
  }
}

export default PushNotification;
