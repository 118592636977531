// THE ORDER IS IMPORTANT
import 'bootstrap-sass';

import jQuery from 'jquery';

import 'jquery-mask-plugin';
import 'jquery-ujs';

import moment from 'moment';

window.$ = jQuery;
window.jQuery = jQuery;

window.moment = moment;

export default {};
