<template>
  <grid-container>
    <grid-item>
      <HeaderBox title="Registro de atividades" />
    </grid-item>

    <grid-item>
      <OperationLogsFilter @submit-filters="submitForm" />
    </grid-item>

    <grid-item v-if="showOperationLogsTable">
      <OperationLogsTable
        :items="operationsLogs"
        @open-modal-operations-details="operationDetails"
      />
    </grid-item>

    <grid-item
      v-if="hasOperationLogs"
      class="pagination-buttons"
    >
      <z-button
        @click="previousPage"
        :is-disabled="!hasPrevious"
      >
        <i
          icon="far,angle-left"
          class="z-icon__svg far fa-angle-left"
          aria-hidden="true"
        />
        Página anterior
      </z-button>

      <z-button
        @click="nextPage"
        :is-disabled="!hasNext"
      >
        Proxíma página
        <i
          icon="far,angle-right"
          class="z-icon__svg far fa-angle-right"
          aria-hidden="true"
        />
      </z-button>
    </grid-item>

    <OperationLogDetails
      v-if="showStockOperationDetails"
      :stock-operation="stockOperationDetails"
      :open="showStockOperationDetails"
      @closed="closeModalOperationDetails"
      :error-details="operationHasErrors"
    />
  </grid-container>
</template>

<script>
import { HeaderBox } from '@shared/components/header-box';
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { OperationLogsFilter, OperationLogsTable, OperationLogDetails } from '@stock/modules/operation-logs';
import { getStockOperations, getStockOperationDetails } from '@shared/services/stock/get-operations/get-stock-operations';

export default {
  components: {
    HeaderBox,
    GridContainer,
    GridItem,
    OperationLogsFilter,
    OperationLogsTable,
    OperationLogDetails,
  },
  data() {
    return {
      response: null,
      operationsLogs: [],
      stockOperationDetails: null,
      operationHasErrors: null,
      filters: null,
      cursors: [''],
      currentPage: 0,
      showOperationLogsTable: false,
      showStockOperationDetails: false,
      visibleDetails: false,
    };
  },
  computed: {
    hasOperationLogs() {
      return this.operationsLogs.length > 0;
    },
    hasNext() {
      return this.currentPage < this.cursors.length - 1;
    },
    hasPrevious() {
      return this.currentPage > 0;
    },
  },
  methods: {
    submitForm(requestData) {
      const parsedRequestData = {
        ...requestData,
        status: this.parseStatus(requestData.status),
        name: this.parseActionType(requestData.name),
        university_user: requestData.university_user.text,
        cursor: this.cursors[this.currentPage],
      };
      getStockOperations(parsedRequestData).then((response) => {
        this.response = response;
        this.operationsLogs = this.response.operations;

        if (this.shouldAddCursor(response)) this.cursors.push(response.next_cursor);
      });
      this.showOperationLogsTable = true;
      this.filters = requestData;
    },
    shouldAddCursor(response) {
      const isLastPage = this.cursors.length - 1 <= this.currentPage;
      const hasNext = response.has_next;

      return hasNext && isLastPage;
    },
    nextPage() {
      this.currentPage += 1;
      this.submitForm(this.filters);
    },
    previousPage() {
      this.currentPage -= 1;
      this.submitForm(this.filters);
    },
    parseActionType(actionType) {
      const types = {
        'Alteração de quantidade de bolsa': 'seats_balance_update',
        'Alteração de desconto': 'offer_update',
        'Criação de oferta': 'entity_create',
        'Despublicação de oferta': 'offer_disable',
        'Alteração de curso': 'course_update',
        'Criação de campus': 'campus_create',
      };
      return types[actionType];
    },
    parseStatus(status) {
      const statuses = {
        Concluído: 'finished',
        Processando: 'in_progress',
        'Em espera': 'waiting',
      };
      return statuses[status];
    },
    operationDetails(uuid) {
      getStockOperationDetails(uuid).then((response) => {
        this.stockOperationDetails = response;
        this.operationHasErrors = this.stockOperationDetails.runtime_error_count > 0 || this.stockOperationDetails.validation_error_count > 0;
      }).catch(() => {
        this.stockOperationDetails = null;
      }).finally(() => {
        this.openModalOperationDetails();
      });
    },
    openModalOperationDetails() {
      this.showStockOperationDetails = true;
    },
    closeModalOperationDetails() {
      this.showStockOperationDetails = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
}
</style>
