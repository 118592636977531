module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="scholarship-panel__docs-modal modal fade" tabindex="-1" role="dialog"\n  aria-hidden="true">\n  <div class="modal-dialog" role="document">\n    <div class="modal-content">\n      <div class="scholarship-panel__docs-header">\n        <button type="button" class="scholarship-panel__docs-header-close" data-dismiss="modal">\n          <img src="/images/yellow/i-close.svg" aria-hidden="">\n          <span class="sr-only">Close</span>\n        </button>\n      </div>\n      <div class="modal-body scholarship-panel__docs-larger-preview">\n        <img src="' +
((__t = ( docPreviewUrl )) == null ? '' : __t) +
'">\n      </div>\n      <div class="modal-footer scholarship-panel__docs-footer">\n        <div class="row">\n          <div class="col-md-offset-2 col-md-4">\n            <button class="btn btn-secondary reverse scholarship-panel__docs-footer-close" data-dismiss="modal">Fechar</button>\n          </div>\n          <div class="col-md-4">\n            <a href="' +
((__t = ( docDownloadUrl )) == null ? '' : __t) +
'" target="_blank" class="btn btn-secondary reverse scholarship-panel__docs-footer-download">\n              Baixar Documento\n            </a>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n';

}
return __p
}