import HeaderDropdown from './header-dropdown';

const DEFAULT_VALUE = 'Todos';

export default class SKUFilter {
  constructor() {
    this.levelFilter = new HeaderDropdown('level', this.applyFilter.bind(this));
    this.kindFilter = new HeaderDropdown('kind', this.applyFilter.bind(this));
    this.shiftFilter = new HeaderDropdown('shift', this.applyFilter.bind(this));
  }

  applyFilter() {
    const locationWithoutQueryString = window.location.href.split('?')[0];
    const level = this.nullIfDefault(this.levelFilter.selectedValue);
    const kind = this.nullIfDefault(this.kindFilter.selectedValue);
    const shift = this.nullIfDefault(this.shiftFilter.selectedValue);

    window.location.href = `${locationWithoutQueryString}?level=${level}&kind=${kind}&shift=${shift}`;
  }

  nullIfDefault(element) {
    if (element !== DEFAULT_VALUE) {
      return element;
    }
    return null;
  }

  get filters() {
    return {
      level: this.nullIfDefault(this.levelFilter.selectedValue) || '',
      kind: this.nullIfDefault(this.kindFilter.selectedValue) || '',
      shift: this.nullIfDefault(this.shiftFilter.selectedValue) || '',
    };
  }
}
