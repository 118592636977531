import axios from 'axios';

const csrf = document.querySelector('meta[name=csrf-token]').content;

const httpClient = axios.create({
  baseURL: '/',
  timeout: 10000,
  headers: {
    'X-CSRF-TOKEN': csrf,
  },
});

const searchCanonicalCourse = params => httpClient.get(
  '/util/autocomplete/canonical_courses', { params },
);

const searchKind = params => httpClient.get(
  '/util/autocomplete/kinds', { params },
);

const searchLevel = params => httpClient.get(
  '/util/autocomplete/levels', { params },
);

const searchShift = params => httpClient.get(
  '/util/autocomplete/shifts', { params },
);

const searchEducationGroup = params => httpClient.get(
  '/util/autocomplete/education_groups', { params },
);

const searchUniversity = params => httpClient.get(
  '/util/autocomplete/universities', { params },
);

const searchCity = params => httpClient.get(
  '/util/autocomplete/city', { params },
);

const searchCampus = params => httpClient.get(
  '/util/autocomplete/campuses', { params },
);

const searchState = params => httpClient.get(
  '/util/autocomplete/states', { params },
);

const searchUniversityUsers = params => httpClient.get(
  '/util/autocomplete/university_users', { params },
);

const searchUniversityUsersEmail = params => httpClient.get(
  '/util/autocomplete/university_users_email', { params },
);

const getStockOperations = params => httpClient.get(
  'ui/estoque/search-stock-operations', { params },
);

const getStockOperationDetails = uuid => httpClient.get(
  `/ui/estoque/get-stock-operation/${uuid}`,
);

const getStockOperationErrorDetails = (uuid, cursor) => {
  const cursorParam = cursor ? `?cursor=${cursor}` : '';

  return httpClient.get(`/ui/estoque/get-stock-operation/${uuid}/errors${cursorParam}`);
};

const downloadStockOperationErrors = (uuid, totalErrors) => httpClient.get(
  `/ui/estoque/export-campuses-errors?uuid=${uuid}&total_errors=${totalErrors}`,
  { responseType: 'blob' },
);

const Autocompletes = {
  searchCampus,
  searchCanonicalCourse,
  searchCity,
  searchEducationGroup,
  searchKind,
  searchLevel,
  searchShift,
  searchState,
  searchUniversity,
  searchUniversityUsers,
  searchUniversityUsersEmail,
};


const getOAuth2Token = () => httpClient.get(
  '/api/shared/microsoft/get-token',
);

const Microsoft = {
  getOAuth2Token,
};

export const UniversityPanelSharedAPI = {
  Autocompletes,
  Microsoft,
  getStockOperations,
  getStockOperationDetails,
  getStockOperationErrorDetails,
  downloadStockOperationErrors,
};
