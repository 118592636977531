<template>
  <z-text
    class="oc-batch-menu-icon"
    size="micro"
    tag="span"
    weight="bold"
  >
    {{ number }}
  </z-text>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.oc-batch-menu-icon {
  align-items: center;
  background-color: var(--color-blue-500);
  border-radius: var(--size-border-radius-circle);
  color: var(--color-white);
  display: flex;
  justify-content: center;
  width: var(--size-medium);
  height: var(--size-medium);
  position: relative;
}
</style>
