class TextFormatter {
  static formatPlural(count, singular, plural) {
    return count === 1 ? '1'.concat(singular) : String(count).concat(plural);
  }

  static capitalize(value) {
    return value.toLowerCase().replace(/(^|\s)\S/g, a => a.toUpperCase());
  }
}

export default TextFormatter;
