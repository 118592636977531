import {
  ValidationProvider, ValidationObserver, extend, localize, validate,
} from 'vee-validate';
import {
  required, integer, numeric, min_value as minValue,
} from 'vee-validate/dist/rules';
import locale from 'vee-validate/dist/locale/pt_BR.json';

export const ValidatePlugin = {
  install(Vue) {
    ValidatePlugin.setupLocalize();
    ValidatePlugin.setupRules();
    ValidatePlugin.setupPrototype(Vue);
    ValidatePlugin.setupComponents(Vue);
  },
  setupLocalize() {
    localize({ pt_BR: locale });
    localize('pt_BR');
  },
  setupRules() {
    extend('required', required);
    extend('integer', integer);
    extend('min_value', minValue);
    extend('numeric', numeric);
  },
  setupComponents(Vue) {
    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);
  },
  setupPrototype(Vue) {
    Object.defineProperty(Vue.prototype, '$validate', { value: validate });
  },
};
