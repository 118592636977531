<template>
  <form>
    <z-title
      size="small"
      class="create-offer-filter-painel__title"
    >
      Criando um novo filtro de ofertas
    </z-title>

    <CreateOfferFilterFormInstitution
      :campuses-selected="campusesSelected"
      :education-groups-selected="educationGroupsSelected"
      :universities-selected="universitiesSelected"
      :university-ids="universityIds"
      @remove="handleAutocompleteRemove"
      @select="handleAutocompleteSelect"
    />

    <CreateOfferFilterFormLocation
      :cities-selected="citiesSelected"
      :states-selected="statesSelected"
      @remove="handleAutocompleteRemove"
      @select="handleAutocompleteSelect"
    />

    <CreateOfferFilterFormCourse
      :canonical-courses-selected="canonicalCoursesSelected"
      :courses-selected="coursesSelected"
      :enrollment-semester-selected="enrollmentSemesterSelected"
      :kinds-selected="kindsSelected"
      :levels-selected="levelsSelected"
      :max-payments-selected="maxPaymentsSelected"
      :origin="origin"
      :shifts-selected="shiftsSelected"
      @input-origin="origin = $event"
      @remove="handleAutocompleteRemove"
      @select="handleAutocompleteSelect"
    />

    <CreateOfferFilterFormConfig
      :offer-filter-enabled="offerFilterEnabled"
      @offer-filter-enabled-change="offerFilterEnabled = $event"
    />
  </form>
</template>

<script>
import CreateOfferFilterFormCourse from './create-offer-filter-painel-course';
import CreateOfferFilterFormConfig from './create-offer-filter-painel-config';
import CreateOfferFilterFormLocation from './create-offer-filter-painel-location';
import CreateOfferFilterFormInstitution from './create-offer-filter-painel-institution';


export default {
  components: {
    CreateOfferFilterFormCourse,
    CreateOfferFilterFormConfig,
    CreateOfferFilterFormLocation,
    CreateOfferFilterFormInstitution,
  },
  props: {
    brgId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      offerFilterEnabled: true,
      campusesSelected: [],
      canonicalCoursesSelected: [],
      educationGroupsSelected: [],
      kindsSelected: [],
      levelsSelected: [],
      universitiesSelected: [],
      shiftsSelected: [],
      statesSelected: [],
      citiesSelected: [],
      enrollmentSemesterSelected: [],
      maxPaymentsSelected: [],
      coursesSelected: [],
      origin: '',
    };
  },
  computed: {
    universityIds() {
      return this.universitiesSelected.map(campus => campus.id);
    },
  },
  methods: {
    getOfferFilterRawData() {
      return {
        universities: this.universitiesSelected,
        educationGroups: this.educationGroupsSelected,
        campuses: this.campusesSelected,
        canonicalCourses: this.canonicalCoursesSelected,
        courses: this.coursesSelected,
        kinds: this.kindsSelected,
        levels: this.levelsSelected,
        maxPayments: this.maxPaymentsSelected,
        origins: this.origin,
        shifts: this.shiftsSelected,
        enrollmentSemesters: this.enrollmentSemesterSelected,
        cities: this.citiesSelected,
        states: this.statesSelected,
        businessRuleGroupId: this.brgId,
        enabled: this.offerFilterEnabled,
      };
    },

    handleAutocompleteSelect({ event, name }) {
      this[name].push(event);

      if (name === 'universitiesSelected') {
        this.campusesSelected = this.campusesSelected.filter(element => (this.universityIds.includes(element.university_id)));
      }
    },
    handleAutocompleteRemove({ event, name, key = 'text' }) {
      if (typeof event === 'string') {
        this[name] = this[name].filter(element => element !== event);
      } else {
        this[name] = this[name].filter(element => element[key] !== event[key]);
      }

      if (name === 'universitiesSelected') {
        this.campusesSelected = this.campusesSelected.filter(element => (this.universityIds.includes(element.university_id)));
      }
    },
  },
};
</script>

<style>
.create-offer-filter-painel__title {
  margin-bottom: var(--space-medium);
}
</style>
