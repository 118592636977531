<template>
  <div class="create-admission-config-modal-custom-fields__container">
    <z-card>
      <z-text
        color="major"
        size="small"
        weight="bold"
      >
        Campos Customizados
      </z-text>

      <ul class="create-admission-config-modal-custom-fields__list">
        <li
          class="create-admission-config-modal-custom-fields__list-item"
          v-for="(customField, index) in customFields"
          :key="index"
        >
          <z-tag
            :value="getCustomFieldsText(customField.value)"
            :is-selected="customField.selected"
            :has-icon="true"
            @click="handlecustomFieldsSelect(index)"
          />
        </li>
      </ul>
    </z-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { AdmissionCustomFieldsBusiness } from '@quero-turbo/business';

export default {
  computed: {
    ...mapFields('CreateAdmissionConfigModalModule', [
      'customFields',
    ]),
  },
  methods: {
    ...mapActions('CreateAdmissionConfigModalModule', [
      'setCustomFields',
    ]),
    handlecustomFieldsSelect(index) {
      this.setCustomFields(index);
    },
    getCustomFieldsText(key) {
      return AdmissionCustomFieldsBusiness.getLabel(key);
    },
  },
};
</script>

<style lang="scss">
@import "@quero/zilla-core/src/utils/_index.scss";

.create-admission-config-modal-custom-fields__container {
  display: flex;
}

.create-admission-config-modal-custom-fields__list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-bottom: 0;
  margin-right: 0;
  max-height: 312px;
  overflow: auto;
  padding: 0;
}

.create-admission-config-modal-custom-fields__list-item {
  margin-bottom: var(--space-small);
  margin-right: var(--space-small);
}
</style>
