import { AdmissionDocumentBusiness } from '@quero-turbo/business';

const sidebarSectionsBuilder = (state) => {
  if (state.currentStepName === 'step01') {
    return [
      {
        title: 'Para configurarmos a admissão digital você deve encaminhar as seguintes informações:',
        values: [
          { text: '1 - Documentação exigida do aluno;' },
          { text: '2 - Nota de corte para o processo seletivo;' },
          { text: '3 - Contrato de matrícula dos alunos (Contrato de serviço da instituição para o aluno).' },
          { text: 'Quanto menos documentos solicitados menor o atrito para conclusão da matrícula' },
        ],
      },
    ];
  }
  return getDynamicSidebar(state);
};

function getDynamicSidebar(state) {
  const studentDocumentsFields = getDocumentsText(state.studentDocuments.filter(getSelectedFields));
  const graduateDocumentsFields = getDocumentsText(state.graduateDocuments.filter(getSelectedFields));
  const parentDocumentsFields = getDocumentsText(state.parentDocuments.filter(getSelectedFields));
  let sidebar = [];

  if (studentDocumentsFields.length > 0) {
    sidebar = [
      ...sidebar,
      {
        title: 'DOCUMENTAÇÃO OBRIGATÓRIA',
        values: studentDocumentsFields,
      },
    ];
  }
  if (graduateDocumentsFields.length > 0) {
    sidebar = [
      ...sidebar,
      {
        title: 'Pós-graduação',
        values: graduateDocumentsFields,
      },
    ];
  }
  if (parentDocumentsFields.length > 0) {
    sidebar = [
      ...sidebar,
      {
        title: 'Responsável',
        values: parentDocumentsFields,
      },
    ];
  }

  return sidebar;
}

function getDocumentsText(documents) {
  return documents.filter(getSelectedFields).map(document => ({ text: AdmissionDocumentBusiness.getLabel(document.value) }));
}

function getSelectedFields(field) { return field.selected; }

export { sidebarSectionsBuilder };
