import { isInteger } from '@stock/utils/is-integer';

const TOTAL_SEATS_RANGE = { min: 1, max: 999 };

function isValidPositiveSeats({ totalSeats }) {
  const totalSeatsParsed = Number(totalSeats);
  if (!totalSeatsParsed) return false;
  if (totalSeatsParsed > TOTAL_SEATS_RANGE.max) return false;
  if (totalSeatsParsed < TOTAL_SEATS_RANGE.min) return false;
  if (!isInteger(totalSeatsParsed)) return false;
  return true;
}

function percentageAvailabilitySeats({
  isLimited,
  isStockout,
  availableSeats,
  totalSeats,
}) {
  if (!isLimited) return 100;
  if (isStockout || availableSeats === 0) return 0;
  return (availableSeats / totalSeats) * 100;
}

export const seats = {
  isValidPositiveSeats,
  percentageAvailabilitySeats,
};
