import { DiscountChangeStepper } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/discountChange';
import { DisablementStepper } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/disablement';
import { PricingStepper } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/pricing';
import { RestockStepper } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/restock';
import { StockActionStepper } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/stock-action';
import { StepperModal } from '@stock/legacy/components/modals/stepper-modal';
import { inlineOperationsTracking } from '@stock/metrics';

const defaultEventProps = { source: 'inline-stock-actions' };

export class InlineOperations {
  static showOptimizeOfferModal(offer, { recommendedPrice }) {
    const eventProps = { ...defaultEventProps };
    inlineOperationsTracking.trackShowOptimizeOfferModal(eventProps);
    const stepper = new PricingStepper({ offer, optimizedOfferedPrice: recommendedPrice });
    return new StepperModal(stepper).show();
  }

  static showRestockModal(offer, { selectedWidget, recommendedValue } = {}) {
    const eventProps = { ...defaultEventProps, selectedWidget, withRecommendedValue: !!recommendedValue };
    inlineOperationsTracking.trackShowRestockModal(eventProps);
    const stepper = new RestockStepper({ offer, recommendedValue, source: defaultEventProps.source });
    return new StepperModal(stepper).show();
  }

  static showPricingModal(offer, { selectedWidget }) {
    const eventProps = { ...defaultEventProps, selectedWidget };
    inlineOperationsTracking.trackShowPricingModal(eventProps);
    const stepper = new PricingStepper({ offer });
    return new StepperModal(stepper).show();
  }

  static showDisablementModal(offer, { selectedWidget, universityOfferId }) {
    const eventProps = { ...defaultEventProps, selectedWidget };
    inlineOperationsTracking.trackShowDisablementModal(eventProps);
    const stepper = new DisablementStepper({ offer, universityOfferId });
    return new StepperModal(stepper).show();
  }

  static showDiscountChangeModal(offer, { selectedWidget }) {
    const eventProps = { ...defaultEventProps, selectedWidget };
    inlineOperationsTracking.trackShowDiscountChangeModal(eventProps);
    const stepper = new DiscountChangeStepper({ offer });
    return new StepperModal(stepper).show();
  }

  static showStockActionModal({ offerTableItem, permissions }, { target }) {
    const eventProps = { ...defaultEventProps, target };
    const { universityOfferId } = offerTableItem;
    inlineOperationsTracking.trackShowStockActionModal(eventProps);
    const stepper = new StockActionStepper({ offerTableItem, universityOfferId, permissions });
    return new StepperModal(stepper).show();
  }
}
