module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="modal modal-dark fade" tabindex="-1" role="dialog">\n  <div class="message-modal modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header modal-dark__header modal-dark__header--align-left modal-dark__header--bottom-m">\n        <button\n          type="button"\n          class="close modal-dark__close"\n          data-dismiss="modal"\n          aria-label="Close"\n        >\n          <span aria-hidden="true">&times;</span>\n        </button>\n        ' +
((__t = ( title )) == null ? '' : __t) +
'\n      </div>\n      <div class="modal-body modal-dark__body modal-dark__body--text-left">\n        ' +
((__t = ( content )) == null ? '' : __t) +
'\n      </div>\n      <div class="modal-footer modal-dark__footer">\n        <button\n          type="button"\n          class="btn btn-secondary reverse modal-dark__wide-button js-cancel-modal"\n          data-dismiss="modal"\n        >\n          Fechar\n        </button>\n        ';
 if (submitText) { ;
__p += '\n          <button\n            type="button"\n            class="btn btn-primary modal-dark__wide-button js-confirm-button"\n            data-dismiss="modal"\n          >\n            ' +
((__t = ( submitText )) == null ? '' : __t) +
'\n          </button>\n        ';
 } ;
__p += '\n        </div>\n    </div>\n  </div>\n';

}
return __p
}