/**
 * Parse data of offer filter
 *
 * @param {Object} params
 * @property {text} params.term The term to be searched
 * @property {Array[number]} params.universityIds Ids to be searched
 *
 * @typedef Success
 * @property {object} parsedData
 */
async function parseData(rawFilters) {
  const {
    universities,
    educationGroups,
    campuses,
    canonicalCourses,
    courses,
    kinds,
    levels,
    maxPayments,
    origins,
    shifts,
    enrollmentSemesters,
    cities,
    states,
    businessRuleGroupId,
    enabled,
  } = rawFilters;
  return {
    campusIds: getId(campuses),
    universityIds: getId(universities),
    educationGroupIds: getId(educationGroups),
    canonicalCourseIds: getId(canonicalCourses),
    courseIds: convertToNumber(courses),
    kindIds: getId(kinds),
    levelIds: getId(levels),
    maxPayments: convertToNumber(maxPayments),
    origins: parseTextToArray(origins),
    shiftIds: getId(shifts),
    enrollmentSemesters,
    cityIds: getId(cities),
    stateIds: getId(states),
    businessRuleGroupId,
    enabled,
    rawFilters,
  };
}

const getId = value => value.map(element => element.id);

const convertToNumber = value => value.map(element => Number(element));

const parseTextToArray = value => (value && [value]) || [];

export {
  parseData,
};
