var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"oc-table"},[_c('thead',[_c('tr',_vm._l((_vm.headers),function(header){return _c('th',{key:header.value,staticClass:"oc-table__header"},[_c('z-text',{attrs:{"tag":"span","align":"center","size":"micro","color":"minor","weight":"bold"}},[_vm._v("\n          "+_vm._s(header.text)+"\n        ")])],1)}),0)]),_vm._v(" "),_c('tbody',_vm._l((_vm.rows),function(row){return _c('tr',{key:row.key,staticClass:"oc-table__row"},_vm._l((_vm.headers),function(column){return _c('td',{key:column.value,staticClass:"oc-table__column"},[(column.type === 'text')?_c('z-text',{staticClass:"z-ovr-text--fw-medium",attrs:{"tag":"span","align":"center","size":"small"}},[_vm._v("\n          "+_vm._s(row[column.value])+"\n        ")]):_vm._e(),_vm._v(" "),(column.type === 'form')?_c('input-form-table',{staticClass:"oc-table__input",attrs:{"type":row[column.value].type,"step":row[column.value].step,"max":row[column.value].max,"min":row[column.value].min,"has-error":row[column.value].hasError,"value":row[column.value].value},on:{"inputValueChange":function (value) { return _vm.$emit('valueInput', {
            value: value,
            key: row.key,
            field: column.value
          }); }}}):_vm._e(),_vm._v(" "),(column.type === 'textWithIcon')?_c('span',{staticClass:"oc-table__text-with-icon"},[(row[column.value].icon)?_c('z-icon',{staticClass:"oc-table__icon",style:({
              'color': row[column.value].icon.color,
            }),attrs:{"icon":[row[column.value].icon.type, row[column.value].icon.name],"size":"medium"}}):_vm._e(),_vm._v(" "),_c('z-text',{class:{ 'z-ovr-text--fw-medium': !row[column.value].weight },style:(row[column.value].color && {
              'color': row[column.value].color,
            }),attrs:{"weight":row[column.value].weight,"tag":"span","align":"center","size":"small"}},[_vm._v("\n            "+_vm._s(row[column.value].text)+"\n          ")])],1):_vm._e()],1)}),0)}),0)])}
var staticRenderFns = []
export { render, staticRenderFns }