import Vue from 'vue';
import Vuex from 'vuex';

import {
  SessionModule,
  OfferCreationModule,
} from './modules';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    SessionModule,
    OfferCreationModule,
  },
  strict: process.env.NODE_ENV !== 'production',
});
