import ActiveFilters from '@stock/legacy/components/active-filters';
import BaseModal from '@shared/legacy/components/modals/base-modal';
import template from '@stock/legacy/components/modals/offer-list-actions/batch/disablement/confirmation-template.html.ejs';
import { offersCount } from '@stock/legacy/components/modals/offer-list-actions/batch/offers-service';
import activeFiltersTemplate
  from '@university-panel/legacy/components/templates/stock/active-filters/list-active-filters.html.ejs';
import AjaxRequest from '@shared/utils/ajax-request';
import { ZToast } from '@shared/legacy/components/zilla/z-toast';
import ResponseModal from '@stock/legacy/components/modals/modal-response';

const DISABLE_OFFER_URL = '/ofertas/desabilitar';

export default class DisableConfirmationModal extends BaseModal {
  constructor(searchForm, totalOffers, totalOffersTarget, requestParams) {
    super(
      {
        totalOffers,
        totalOffersBlocked: (totalOffers - totalOffersTarget),
        totalOffersTarget,
      },
      template,
    );
    this.activeFilters = searchForm.toObject();
    this.requestParams = requestParams;
    this.totalOffersTarget = totalOffersTarget;
    this.disablementRequest = this.setupRequest();
    this.showSuccessModal = this.showSuccessModal.bind(this);
  }

  setupRequest() {
    return new AjaxRequest(DISABLE_OFFER_URL)
      .on(
        'success',
        ({ data }) => {
          super.destroy();
          this.showSuccessModal(data);
        },
      )
      .on(
        'error',
        () => {
          super.destroy();
          ZToast.showError('Erro ao despublicar ofertas');
        },
      );
  }

  showSuccessModal(data) {
    const messageModal = new ResponseModal({
      iconClass: 'fal fa-check-circle fa-4x',
      title: 'Solicitação realizada com sucesso!',
      content: `
        Em breve sua alteração estará disponível na plataforma.
        Você também pode acompanhar suas alterações no Histórico de ações.
      `,
      submitText: 'Histórico de ações',
    });
    messageModal.show();
    messageModal.$container.on('click', '.js-confirm-button', () => {
      window.location.href = data.redirect;
    });
  }

  updateOffersCount() {
    offersCount(this.requestParams, (result) => {
      this.updateModalData(result.total_offers_count, result.total_offers_target_count);
    });
  }

  updateModalData(totalOffers, totalOffersTarget) {
    const totalOffersBlocked = totalOffers - totalOffersTarget;

    $('.js-batch-disablement-total-offers').text(totalOffers);
    $('.js-batch-disablement-total-offers-blocked').text(totalOffersBlocked);
    $('.js-batch-disablement-total-offers-target').text(totalOffersTarget);
  }

  onShow() {
    this.renderFilters();
    this.setupDisablementButton();
    this.setupRadioButton();
    this.setupSubmitButton();
  }

  setupRadioButton() {
    const $inputs = $('.js-typeaction-input');
    const $disableOffersWarning = $('.js-disable-offers-warning');

    $inputs.on('change', (event) => {
      const { value } = event.target;

      if (value === 'allDiscounts') {
        this.requestParams.additionalParameters = { with_unlisted_offers: true };
        $disableOffersWarning.removeClass('hidden');
      } else {
        this.requestParams.additionalParameters = { with_unlisted_offers: false };
        $disableOffersWarning.addClass('hidden');
      }

      this.updateOffersCount();
    });
  }

  setupSubmitButton() {
    if (this.totalOffersTarget === 0) {
      $('.js-disblement-confirmation').attr('disabled', true);
    }
  }

  renderFilters() {
    new ActiveFilters(
      $('.js-active-filters'),
      this.activeFilters,
      activeFiltersTemplate,
    );
  }

  setupDisablementButton() {
    $('.js-disblement-confirmation').click((event) => {
      event.preventDefault();
      this.destroy();
      const params = this.requestParams.toJson();
      this.disablementRequest.postJson(params);
    });
  }
}
