<template>
  <z-card class="one-click-restock-success-card">
    <z-icon
      :icon="['far','check-circle']"
      size="extra-large"
      color="success"
      class="one-click-restock-success-card__icon"
    />

    <z-title
      color="success"
      size="small"
      align="center"
      class="one-click-restock-success-card__title"
    >
      Solicitação realizada com sucesso!
    </z-title>

    <z-text
      color="minor"
      size="small"
      align="center"
      class="one-click-restock-success-card__subtitle"
    >
      Em breve sua reposição estará disponível na plataforma e você será avisado por e-mail.
    </z-text>

    <one-click-restock-offer-info
      :course-name="courseName"
      :campus="campus"
      :level="level"
      :kind="kind"
      :shift="shift"
      :available-seats="availableSeats"
      :offered-price="offeredPrice"
      :discount="discount"
      :enrollment-semester="enrollmentSemester"
    />
  </z-card>
</template>

<script>
import OneClickRestockOfferInfo from './one-click-restock-offer-info';

export default {
  components: {
    OneClickRestockOfferInfo,
  },
  props: {
    courseName: {
      type: String,
      required: true,
    },
    campus: {
      type: String,
      required: true,
    },
    level: {
      type: String,
      required: true,
    },
    kind: {
      type: String,
      required: true,
    },
    shift: {
      type: String,
      required: true,
    },
    availableSeats: {
      type: Number,
      required: true,
    },
    offeredPrice: {
      type: Number,
      required: true,
    },
    discount: {
      type: Number,
      required: true,
    },
    enrollmentSemester: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.one-click-restock-success-card {
  text-align: center;
  margin-top: 0;
}

.one-click-restock-success-card__icon {
  color: var(--color-support-success-deep);
  margin-bottom: var(--space-small);
}

.one-click-restock-success-card__title {
  margin-bottom: var(--space-small);
}

.one-click-restock-success-card__subtitle {
  margin-bottom: var(--space-large);
}
</style>
