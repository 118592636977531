import AmplitudeService from '@shared/utils/amplitude-service';

const amplitude = AmplitudeService.getInstance();

const _logEvent = (operation, eventProps) => {
  const baseMessage = `Open ${operation} Modal`;
  return amplitude.logEvent(baseMessage, eventProps);
};

const trackShowCreateWindowOffersModal = () => {
  _logEvent('Create Window Offers');
};

const trackShowDisableOffersModal = () => {
  _logEvent('Disable Offers');
};

const trackShowChangeEndOffersModal = () => {
  _logEvent('Change end offers');
};

const trackShowBatchDiscountChangeModal = () => {
  _logEvent('Batch Discount Change');
};

const trackShowBatchRestockModal = () => {
  _logEvent('Batch Restock');
};

export const batchOperationsTracking = {
  trackShowCreateWindowOffersModal,
  trackShowDisableOffersModal,
  trackShowChangeEndOffersModal,
  trackShowBatchDiscountChangeModal,
  trackShowBatchRestockModal,
};
