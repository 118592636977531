<template>
  <div class="index-brg-table">
    <div class="index-brg-table__item">
      <div class="index-brg-table__fragment">
        <z-title class="index-brg-table__fragment-header">
          Nome
        </z-title>
      </div>

      <div class="index-brg-table__fragment">
        <z-title class="index-brg-table__fragment-header">
          Situação
        </z-title>
      </div>

      <div class="index-brg-table__fragment">
        <z-title class="index-brg-table__fragment-header">
          Prioridade
        </z-title>
      </div>

      <div class="index-brg-table__fragment">
        <z-title class="index-brg-table__fragment-header">
          Criado em
        </z-title>
      </div>

      <div class="index-brg-table__fragment">
        <z-title class="index-brg-table__fragment-header">
          Válido desde
        </z-title>
      </div>

      <div class="index-brg-table__fragment">
        <z-title class="index-brg-table__fragment-header">
          Válido até
        </z-title>
      </div>
    </div>

    <div
      class="index-brg-table__item"
      v-for="(element, index) in items"
      :key="index"
    >
      <div class="index-brg-table__fragment">
        <z-link
          class="index-brg-table__fragment-title"
          tag="router-link"
          :to="`/brgs/${element.id}`"
        >
          {{ element.name }}
        </z-link>
      </div>

      <div class="index-brg-table__fragment">
        <z-text tag="span">
          <z-badge-text
            :variant="getStatusDictionary(element.status).variant"
            :value="getStatusDictionary(element.status).text"
          />
        </z-text>
      </div>

      <div class="index-brg-table__fragment">
        <z-text tag="span">
          {{ element.priority }}
        </z-text>
      </div>

      <div class="index-brg-table__fragment">
        <z-text tag="span">
          {{ dateParser(element.created_at) }}
        </z-text>
      </div>


      <div class="index-brg-table__fragment">
        <z-text tag="span">
          {{ dateParser(element.valid_from) }}
        </z-text>
      </div>

      <div class="index-brg-table__fragment">
        <z-text tag="span">
          {{ dateParser(element.valid_until) }}
        </z-text>
      </div>
    </div>

    <div
      v-if="!items.length"
      class="index-brg-table__item index-brg-table__item--centered"
    >
      <z-icon
        :icon="['fas', 'exclamation-circle']"
        class="index-brg-table__item-icon"
      />

      <z-title>
        Nenhuma configuração encontrada ou selecionada.
      </z-title>
    </div>
  </div>
</template>

<script>
import { BRGBusiness } from '@quero-turbo/business';
import { dateParser } from '@shared/utils/date/date-parser';

export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    ...BRGBusiness,
    dateParser,
  },
};
</script>

<style>
.index-brg-table__item {
  align-items: center;
  border-bottom: var(--size-border-width-small) solid var(--color-neutral-lighter-gray);
  display: flex;
  margin-left: calc(var(--space-large) * -1);
  margin-right: calc(var(--space-large) * -1);
  padding: var(--space-large);
}

.index-brg-table__item--centered {
  display: flex;
  justify-content: center;
}

.index-brg-table__item-icon {
  margin-right: var(--space-medium);
}

.index-brg-table__fragment-header,
.index-brg-table__fragment-title {
  font-weight: var(--font-weight-medium);
}

.index-brg-table__fragment {
  flex: 1;
}
</style>
