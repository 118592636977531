<template>
  <z-card class="commission-type-card">
    <GridContainer no-padding>
      <GridItem behavior="horizontal">
        <z-input-field
          label="Por qual aluno vamos cobrar?"
          class="commission-type-card__input-field"
        >
          <z-dropdown-input
            type="text"
            :value="commissionConfig.paymentTrigger"
            @change="$emit('change', {value: $event.target.value, key: 'paymentTrigger', id: commissionConfig.id})"
            :options="commissionPaymentTriggerOptions"
          />
        </z-input-field>
      </GridItem>

      <GridItem behavior="horizontal">
        <z-input-field
          label="Forma de pagamento"
          class="commission-type-card__input-field"
        >
          <z-dropdown-input
            type="text"
            :value="commissionConfig.paymentModel"
            @change="$emit('change', {value: $event.target.value, key: 'paymentModel', id: commissionConfig.id})"
            :options="commissionPaymentModelOptions"
          />
        </z-input-field>
      </GridItem>

      <GridItem :column="6">
        <z-input-field
          label="Selecionar conta"
          class="commission-type-card__input-field"
        >
          <z-autocomplete
            :options="accountList"
            :value="commissionConfig.accountSelected"
            @clear="$emit('change', {value: null, key: 'accountSelected', id: commissionConfig.id})"
            @select="$emit('change', {value: $event, key: 'accountSelected', id: commissionConfig.id})"
            clear-button-aria-label="Limpar"
            hide-selected
            label-by="name"
          />
        </z-input-field>
      </GridItem>

      <GridItem
        :column="6"
        behavior="horizontal"
      >
        <z-button
          :icon="['fas', 'plus']"
          variant="tertiary"
          @click="$emit('click-create-turbo-account')"
          class="commission-type-card__button"
        >
          Criar conta
        </z-button>
      </GridItem>

      <GridItem behavior="horizontal">
        <z-input-field
          label="Comissionado por:"
          class="commission-type-card__input-field"
        >
          <z-dropdown-input
            type="text"
            :value="commissionConfig.commissionFeeTypeLabel"
            @change="$emit('change', {
              value: $event.target.value,
              key: 'commissionFeeTypeLabel',
              id: commissionConfig.id
            })"
            :options="commissionFeeTypeOptions"
          />
        </z-input-field>


        <z-input-field
          label="Valor"
          class="commission-type-card__input-field"
        >
          <z-input
            type="number"
            :value="commissionConfig.commissionFee"
            @input="$emit('change', {value: $event, key: 'commissionFee', id: commissionConfig.id})"
          />
        </z-input-field>

        <z-input-field
          label="Subsídio"
          class="commission-type-card__input-field"
        >
          <z-input
            type="number"
            :value="commissionConfig.subsidy"
            @input="$emit('change', {value: $event, key: 'subsidy', id: commissionConfig.id})"
          />
        </z-input-field>

        <z-button
          icon="minus"
          variant="danger"
          @click.prevent="$emit('remove-commission-type', commissionConfig.id)"
          class="commission-type-card__button"
          v-if="!isLast"
        />

        <z-button
          icon="plus"
          variant="success"
          @click.prevent="$emit('add-commission-type')"
          class="commission-type-card__button"
          v-if="isLast"
        />
      </GridItem>
    </GridContainer>
  </z-card>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { CommissionRulesBusiness } from '@quero-turbo/business';

export default {
  components: {
    GridContainer, GridItem,
  },
  props: {
    commissionConfig: {
      type: Object,
      required: true,
    },
    isLast: {
      type: Boolean,
      required: true,
    },
    accountList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    CommissionRulesBusiness: () => CommissionRulesBusiness,
    commissionPaymentTriggerOptions() {
      return this.CommissionRulesBusiness.getPaymentTriggerTextList();
    },
    commissionPaymentModelOptions() {
      return this.CommissionRulesBusiness.getPaymentModelTextList();
    },
    commissionFeeTypeOptions() {
      return this.CommissionRulesBusiness.getCommissionFeeTypeTextList();
    },
  },
};
</script>

<style lang="scss">
.commission-type-card {
  display: flex;
  align-items: flex-end;
}

.commission-type-card__input-field {
  flex: 1 1 80px;
  margin-bottom: 0;
  margin-right: var(--space-medium);
  align-self: flex-end;

  &:first-child {
    flex: 1 1 220px;
    margin-right: var(--space-small);
  }
}

.commission-type-card__button {
  align-self: flex-end;
}
</style>
