module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div\n  class="modal fade"\n  tabindex="-1"\n  role="dialog"\n>\n  <div class="batch-stock-action-edit modal-dialog">\n    <section class="modal-content">\n      <div class="batch-stock-action-edit__body modal-body">\n        <header class="batch-stock-action-edit__header">\n          <h2 class="batch-stock-action-edit__title gq-title gq-title--left">' +
((__t = ( title )) == null ? '' : __t) +
'</h2>\n          <p class="batch-stock-action-edit__description gq-text gq-text--left">' +
((__t = ( description )) == null ? '' : __t) +
'</p>\n\n          <button\n            type="button"\n            class="batch-stock-action-edit__close close"\n            data-dismiss="modal"\n            aria-label="Close"\n            title="Fechar"\n          >\n            <i class="far fa-xs fa-times " aria-hidden></i>\n          </button>\n        </header>\n\n        <form ' +
((__t = ( attr('class', 'batch-stock-action-edit__form', formSelector) )) == null ? '' : __t) +
'>\n          <section class="batch-stock-action-edit__radios">\n            <label class="batch-stock-action-edit__radio-item radiobox">\n              <input type="radio" class="radiobox__input js-typeaction-input" name="t" checked="checked" value="+"/>\n              <span\n                class="radiobox__text"\n                aria-label="' +
((__t = ( radioinc_title )) == null ? '' : __t) +
'"\n                title="' +
((__t = ( radioinc_title )) == null ? '' : __t) +
'"\n              >\n                ' +
((__t = ( radioinc_label )) == null ? '' : __t) +
'\n              </span>\n            </label>\n\n            <label class="batch-stock-action-edit__radio-item radiobox">\n              <input type="radio" class="radiobox__input js-typeaction-input" name="t" value="-"/>\n              <span\n                class="radiobox__text"\n                aria-label="' +
((__t = ( radiodec_title )) == null ? '' : __t) +
'"\n                title="' +
((__t = ( radiodec_title )) == null ? '' : __t) +
'"\n              >\n                ' +
((__t = ( radiodec_label )) == null ? '' : __t) +
'\n              </span>\n            </label>\n\n            ';
 if(mode === 'pricing') { ;
__p += '\n              <label class="batch-stock-action-edit__radio-item radiobox">\n                <input type="radio" class="radiobox__input js-typeaction-input" name="t" value="="/>\n                <span\n                  class="radiobox__text"\n                  aria-label="Definir novo desconto para ofertas filtradas"\n                  title="Definir novo desconto para ofertas filtradas"\n                >\n                  Definir novo desconto\n                </span>\n              </label>\n            ';
 } ;
__p += '\n\n            ';
 if(mode === 'restock') { ;
__p += '\n              <label class="batch-stock-action-edit__radio-item radiobox">\n                <input type="radio" class="radiobox__input js-typeaction-input" name="t" value="="/>\n                <span\n                  class="radiobox__text"\n                  aria-label="' +
((__t = ( radioabs_title )) == null ? '' : __t) +
'"\n                  title="' +
((__t = ( radioabs_title )) == null ? '' : __t) +
'"\n                >\n                  ' +
((__t = ( radioabs_label )) == null ? '' : __t) +
'\n                </span>\n              </label>\n            ';
 } ;
__p += '\n          </section>\n\n          <p class="batch-stock-action-edit__disclaimer gq-text js-type-disclaimer">\n            ' +
((__t = ( disclaimerInc )) == null ? '' : __t) +
'\n          </p>\n\n          <section class="batch-stock-action-edit__main-target">\n            <label class="batch-stock-action-edit__form-group form-group">\n              <span class="control-label">' +
((__t = ( label )) == null ? '' : __t) +
'</span>\n              <div class="input-group">\n                <span class="input-group-addon js-type-action">+</span>\n                <input\n                  ' +
((__t = ( attr('class', 'form-control', input.selector) )) == null ? '' : __t) +
'\n                  ' +
((__t = ( attr('min', input.min) )) == null ? '' : __t) +
'\n                  ' +
((__t = ( attr('max', input.max) )) == null ? '' : __t) +
'\n                  ' +
((__t = ( attr('placeholder', input.placeholder) )) == null ? '' : __t) +
'\n                  ' +
((__t = ( attr('value', initialSeatsCount) )) == null ? '' : __t) +
'\n                  type="number"\n                />\n              </div>\n            </label>\n            ';
 if (mode === 'pricing') { ;
__p += '\n              <div class="batch-stock-action-edit__form-group">\n                <p class="gq-text gq-text--small gq-text--strong">Novo desconto*</p>\n                <strong class="gq-text gq-text--large gq-text--strong js-discount-result"></strong>\n              </div>\n            ';
 } ;
__p += '\n          </section>\n        </form>\n\n        <div class="batch-stock-action-edit__info">\n          <p class="gq-text js-feedback"></p>\n          <ul\n            class="batch-stock-action-edit__rules batch-stock-action-edit__rules--without-margin js-batch-edit-rules"\n          >\n            ';
 rules.forEach(function(rule) { ;
__p += '\n              <li class="batch-stock-action-edit__rule gq-text gq-text--small">' +
((__t = ( rule )) == null ? '' : __t) +
'</li>\n            ';
 }); ;
__p += '\n          </ul>\n        </div>\n      </div>\n\n      <footer class="batch-stock-action-edit__footer modal-footer">\n        <button\n          type="button"\n          class="batch-stock-action-edit__footer-action btn btn-secondary reverse js-cancel-modal"\n          data-dismiss="modal"\n        >\n          ' +
((__t = ( button_cancel )) == null ? '' : __t) +
'\n        </button>\n        <button\n          type="button"\n          class="batch-stock-action-edit__footer-action js-next-button btn btn-primary"\n          disabled>\n            ' +
((__t = ( button_confirm )) == null ? '' : __t) +
'\n        </button>\n      </footer>\n    </section>\n  </div>\n</div>\n';

}
return __p
}