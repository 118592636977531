import { StepsNavigationSetup, CreateBillingConfigModal } from '@quero-turbo/modules/billing-components';
import { getField, updateField, createHelpers } from 'vuex-map-fields';
import { QueroDealsServices } from '@quero-turbo/services';
import { ZToast } from '@shared/legacy/components/zilla/z-toast';
import { sidebarSectionsBuilder } from './sidebar-sections-builder';

const { getStep01Field, updateStep01Field } = createHelpers({
  getterType: 'getStep01Field',
  mutationType: 'updateStep01Field',
});

const { getStep02Field, updateStep02Field } = createHelpers({
  getterType: 'getStep02Field',
  mutationType: 'updateStep02Field',
});

const { getStep03Field, updateStep03Field } = createHelpers({
  getterType: 'getStep03Field',
  mutationType: 'updateStep03Field',
});

const { getStep04Field, updateStep04Field } = createHelpers({
  getterType: 'getStep04Field',
  mutationType: 'updateStep04Field',
});

const FIRST_STEP = 'step01';
const LAST_STEP = 'step04';

const defaultState = () => ({
  stepConfig: {
    status: null,
    currentStepName: null,
    validStep01: false,
    validStep02: false,
    validStep03: false,
  },
  businessRuleGroupId: null,
  bankAccountParams: {
    nameOfRecipient: null,
    document: null,
    bankSelected: null,
    accountName: null,
    agencyCode: null,
    agencyDigit: null,
    accountNumber: null,
    accountDigit: null,
    bankAccountType: null,
    transferDays: null,
  },
  splitRules: {
    transferQpPercentage: null,
    transferInstitutionPercentage: null,
  },
  contractCreationRule: {
    startRule: null,
    duration: null,
  },
  dueDayRule: {
    dueDays: null,
    studentCanChangeDueDay: false,
  },
  contractDischargeRules: {
    methods: [],
  },
  paymentMethodRules: {
    methods: [],
  },
  scholarshipLosingRule: {
    rule: null,
    lossDelay: null,
    lossDelayType: null,
    gracePeriod: null,
    canLoseScholarship: false,
  },
  subsidy: null,
  admissionPaymentRule: {
    amount: null,
    amountType: false,
    equivalentToFirstBill: false,
  },
});

const mutations = {
  updateField,
  updateStep01Field,
  updateStep02Field,
  updateStep03Field,
  updateStep04Field,
  setCurrentStepName(state, newName) {
    state.stepConfig = {
      ...state.stepConfig,
      currentStepName: newName,
    };
  },
  setBusinessRuleGroupId(state, newBusinessRuleGroupId) {
    state.businessRuleGroupId = newBusinessRuleGroupId;
  },
  setStepConfigStatus(state, newStatus) {
    const currentStepName = newStatus === 'editing' ? FIRST_STEP : LAST_STEP;

    state.stepConfig = {
      ...state.stepConfig,
      status: newStatus,
      currentStepName,
    };
  },
  resetState(state) {
    Object.assign(state, defaultState());
  },
  setInitialState(state, initialState) {
    Object.assign(state, initialState);
  },
};

const getters = {
  getField,
  getStep01Field,
  getStep02Field,
  getStep03Field,
  getStep04Field,
  currentStep(state) {
    return StepsNavigationSetup[state.stepConfig.currentStepName];
  },
  currentComponent(_state, getter) {
    return getter.currentStep.component;
  },
  currentStepTitle(_state, getter) {
    return getter.currentStep.title;
  },
  currentStepHasSidebar(_state, getter) {
    return getter.currentStep.sidebar;
  },
  rightButtonText(state) {
    if (state.stepConfig.currentStepName === LAST_STEP) {
      if (state.stepConfig.status === 'editing') return 'Criar';

      return 'Concluído';
    }
    return 'Avançar';
  },
  leftButtonText(state) {
    return state.stepConfig.currentStepName === FIRST_STEP ? 'Fechar' : 'Voltar';
  },
  canIAdvanceStep(state) {
    const { currentStepName } = state.stepConfig;
    const canIAdvance = {
      step01: state.stepConfig.validStep01,
      step02: state.stepConfig.validStep02,
      step03: state.stepConfig.validStep03,
      step04: true,
    };
    return canIAdvance[currentStepName];
  },
  sidebarSectionsItems(state) {
    const { stepConfig, ...rest } = state;

    return sidebarSectionsBuilder(stepConfig.currentStepName, rest);
  },
};

const actions = {
  showCreateActionErrorMessage(_context, message = 'Sua solicitação de alteração não pode ser concluída') {
    ZToast.showAlert(message, {
      type: 'danger',
    });
  },
  showCreateActionSuccessMessage() {
    ZToast.showAlert('Sua solicitação está sendo processada', {
      type: 'success',
    });
  },
  handleNext({
    commit,
    state,
    getters: getter,
    dispatch,
  }) {
    if (state.stepConfig.currentStepName !== LAST_STEP) {
      commit('setCurrentStepName', getter.currentStep.nextStepName);
    } else if (state.stepConfig.status === 'editing') {
      dispatch('handleRequesToCreate');
    } else {
      this._vm.$upaHelperModal.close();
    }
  },
  handlePrev({ commit, state, getters: getter }) {
    if (state.stepConfig.currentStepName === FIRST_STEP) {
      this._vm.$upaHelperModal.close();
    } else {
      commit('setCurrentStepName', getter.currentStep.prevStepName);
    }
  },
  async handleRequesToCreate({
    commit,
    state,
    dispatch,
  }) {
    try {
      const params = await QueroDealsServices.V1.BillingConfig.parseRequestData(state);
      await QueroDealsServices.V1.BillingConfig.create(params)
        .then(() => {
          commit('setStepConfigStatus', 'finished');
          dispatch('showCreateActionSuccessMessage');
        });
      await dispatch(
        'ShowBRGPageModule/requestFindBRGWithProductConfigs',
        { id: state.businessRuleGroupId },
        { root: true },
      )
        .then(() => {
          this._vm.$upaHelperModal.close();
        });
    } catch (error) {
      dispatch('showCreateActionErrorMessage', error.message);
    }
  },
  async handleOpenActivationModal({ commit }, { businessRuleGroupId }) {
    await commit('resetState');
    commit('setBusinessRuleGroupId', businessRuleGroupId);
    commit('setStepConfigStatus', 'editing');
    this._vm.$upaHelperModal.show({
      mode: 'custom',
      size: 'medium',
      options: { component: CreateBillingConfigModal },
    });

    await this._vm.$upaHelperModal.nextAction();

    this._vm.$upaHelperModal.close();
  },
  async handleOpenShowModal({ commit }, { data }) {
    await commit('resetState');
    const result = await QueroDealsServices.V1.BillingConfig.parseResponseData(data);
    commit('setInitialState', result);
    commit('setStepConfigStatus', 'finished');
    this._vm.$upaHelperModal.show({
      mode: 'custom',
      size: 'medium',
      options: { component: CreateBillingConfigModal },
    });

    await this._vm.$upaHelperModal.nextAction();

    this._vm.$upaHelperModal.close();
  },
};

const CreateBillingConfigModalModule = {
  namespaced: true,
  actions,
  state: defaultState,
  mutations,
  getters,
};

export { CreateBillingConfigModalModule };
