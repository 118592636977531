import ActiveFilters from '@stock/legacy/components/active-filters';
import select2ActiveFiltersTemplate
  from '@university-panel/legacy/components/templates/stock/active-filters/select2-active-filters.html.ejs';
import { BatchOperationModal, BatchOperationRequest } from '@stock/legacy/modules/batch-operation';
import template from '@university-panel/legacy/components/templates/batch-create-window-offers-modal.html.ejs';
import isInteger from '@shared/utils/validators/is-integer';
import between from '@shared/utils/validators/between';
import NumericInput from '@shared/utils/numeric-input';
import setupConfirmationStep from '@stock/legacy/components/modals/offer-list-actions/batch/create-window/confirmation';

const MAX_DISCOUNT_INCREMENT = 20;
const MAX_WIDTH = 600;

export default class BatchCreateWindowOffersModal extends BatchOperationModal {
  constructor(searchForm, alert, { totalOffersCount, totalOffersTargetCount }) {
    const config = {
      maxDiscountIncrement: MAX_DISCOUNT_INCREMENT,
      maxWidth: MAX_WIDTH,
      totalOffersCount,
      totalOffersTargetCount,
    };
    super(config, searchForm, alert, template);
    setupConfirmationStep(this, alert);
  }

  setupBatchOperation() {
    const extraParams = {
      amount_seats: this.seatsCount,
      discount_increment: this.discountIncrement.parsedValue,
      constraints: { regressive_discount: false },
    };
    const batchOperationRequest = new BatchOperationRequest(this.searchForm, extraParams);
    // bring only the best offer for each university offer without shared seats
    batchOperationRequest.filters.discount_groups = ['best'];
    batchOperationRequest.filters.shared_seats = false;
    return batchOperationRequest;
  }

  onShow() {
    this.cacheFormFields();
    this.initValidators();
    this.renderFilters();
    super.onShow();
  }

  cacheFormFields() {
    const $discountIncrement = this.$container.find('.js-window-offers-creation-discount-increment');
    this.discountIncrement = new NumericInput($discountIncrement);
    this.$seatsCount = this.$container.find('.js-window-offers-creation-seats-count');
  }

  initValidators() {
    this.applyValidator(this.discountIncrement.$input, [between(1, this.props.maxDiscountIncrement)]);
    this.applyValidator(this.$seatsCount, [isInteger, between(1, 5)]);
  }

  get seatsCount() {
    if (this.$seatsCount) {
      return parseInt(this.$seatsCount.val(), 10) || 0;
    }
    return null;
  }

  getConfirmationData() {
    return {
      offersCount: this.totalOffersTargetCount,
      seatsCount: this.seatsCount,
      discountIncrement: this.discountIncrement.value,
    };
  }

  renderFilters() {
    new ActiveFilters(
      $('.js-active-filters'),
      this.searchForm.toObject(),
      select2ActiveFiltersTemplate,
    );
  }

  requestSuccessHandler(result) {
    super.requestSuccessHandler(result);
    this.updateOfferCount(result.total_offers_target_count);
  }

  updateOfferCount(offerCount) {
    if (offerCount === 0) {
      $('.js-offer-count').addClass('batch-create-modal__offer-count--invalid');
    }
  }
}
