import $ from 'jquery';
import { CHART_COLORS } from '@shared/utils/chart-colors';
import DoughnutChart from '@shared/legacy/components/charts/doughnut-chart';
import '@shared/utils/select2';
import { SKUFilter } from '@stock/legacy/components/sku-filter';
import axios from 'axios';

const FETCH_DATA_URL = '/ui/estoque/dashboard-data';

const noChartData = message => `<div class="box__no-data"><span class="box__no-data__description">${message}</span></div>`;

class StockDashboard {
  constructor(page) {
    this.page = page;
    this.skuFilter = new SKUFilter();
    return this.fetchData();
  }

  async fetchData() {
    this.showLoader();

    return axios.get(FETCH_DATA_URL, { params: this.skuFilter.filters }).then(({ data }) => {
      this.hideLoader();
      this.render(data);
      this.configureStockoutCallToAction(
        data.base_metrics.soldout,
        '.js-stockout-call-to-action',
      );
      this.configureStockoutCallToAction(
        data.window_metrics.soldout,
        '.js-stockout-window-call-to-action',
      );
    });
  }

  render(data) {
    const WINDOW_ZERO_VALUE = 'N/A';

    $('.js-base-functional').text(data.base_metrics.saleable);
    $('.js-window-functional').text(
      data.window_metrics.saleable || WINDOW_ZERO_VALUE,
    );
    $('.js-base-soldout').text(data.base_metrics.soldout);
    $('.js-window-soldout').text(
      data.window_metrics.soldout || WINDOW_ZERO_VALUE,
    );
    $('.js-base-unsold').text(data.base_metrics.unsold);
    $('.js-window-unsold').text(
      data.window_metrics.unsold || WINDOW_ZERO_VALUE,
    );

    this.createStockByCategoryChart(
      data.base_metrics,
      '.js-available-percentage',
      '.js-stock-chart',
      '.js-chart-subtitle',
    );

    this.createStockByCategoryChart(
      data.window_metrics,
      '.js-window-available-percentage',
      '.js-window-stock-chart',
      '.js-window-chart-subtitle',
    );
  }

  mapDiscountsToCategories(discounts) {
    const result = {
      saleable: [],
      soldout: [],
      unsold: [],
    };

    // eslint-disable-next-line
    for (const discount in discounts) {
      result.saleable.push(discounts[discount].saleable);
      result.soldout.push(discounts[discount].soldout);
      result.unsold.push(discounts[discount].unsold);
    }
    return result;
  }

  hasAnyOffer(metrics) {
    return metrics.saleable > 0 || metrics.soldout > 0 || metrics.unsold > 0;
  }

  createStockByCategoryChart(
    metrics,
    percentageSelector,
    chartSelector,
    subtitleSelector,
  ) {
    if (!this.hasAnyOffer(metrics)) {
      const $chart = $(chartSelector);
      $chart.closest('.box').append(noChartData('Sem dados para exibição.'));
      $chart.closest('.js-chart-container').css('visibility', 'hidden');
      return null;
    }

    const data = {
      datasets: [
        {
          data: [metrics.soldout, metrics.unsold, metrics.saleable],
          backgroundColor: [CHART_COLORS.base.delta, CHART_COLORS.base.charlie, CHART_COLORS.base.alpha],
        },
      ],
      labels: [
        this.pluralizeLabel('Esgotado', metrics.soldout),
        this.pluralizeLabel('Sem venda', metrics.unsold),
        this.pluralizeLabel('Com venda', metrics.saleable),
      ],
    };

    const chartConfig = {
      data,
      showTotal: true,
    };

    const total = metrics.soldout + metrics.unsold + metrics.saleable;
    let functionalRatio = 0;
    if (total > 0) {
      functionalRatio = Math.round(metrics.saleable / (total / 100.0));
    }
    $(percentageSelector).text(`${functionalRatio}%`);
    return new DoughnutChart(chartSelector, subtitleSelector, chartConfig);
  }

  get currentFilter() {
    return $('#js-selected-filter');
  }

  pluralizeLabel(label, value) {
    return value > 1 ? `${label}s` : label;
  }

  configureStockoutCallToAction(soldOut, target) {
    const callToAction = $(target);

    if (soldOut) {
      callToAction.removeClass('hidden');
    } else {
      callToAction.addClass('hidden');
    }
  }

  showLoader() {
    $('.cog').show();
  }

  hideLoader() {
    $('.cog').hide();
  }
}

export default StockDashboard;
