<template>
  <section>
    <z-title
      size="extra-small"
      class="create-offer-filter-painel-location__title"
    >
      Dados de localização
    </z-title>

    <GridContainer class="create-offer-filter-painel-local__row">
      <GridItem :column="6">
        <StateAutocomplete
          @select="$emit('select', { event: $event, name: 'statesSelected', key: 'name' })"
          @remove="$emit('remove', { event: $event, name: 'statesSelected', key: 'name' });"
          :value="statesSelected"
        />
      </GridItem>

      <GridItem :column="6">
        <CityAutocomplete
          @select="$emit('select', { event: $event, name: 'citiesSelected' })"
          @remove="$emit('remove', { event: $event, name: 'citiesSelected' });"
          :value="citiesSelected"
        />
      </GridItem>
    </GridContainer>
  </section>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { CityAutocomplete } from '@shared/components/autocompletes/city-autocomplete';
import { StateAutocomplete } from '@shared/components/autocompletes/state-autocomplete';

export default {
  components: {
    StateAutocomplete,
    CityAutocomplete,
    GridContainer,
    GridItem,
  },
  props: {
    universityIds: {
      type: Array,
      default() {
        return [];
      },
    },
    statesSelected: {
      type: Array,
      required: true,
    },
    citiesSelected: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
.create-offer-filter-painel-location__title {
  margin-bottom: var(--space-medium);
}

.create-offer-filter-painel-local__row {
  --grid-padding: 0;
}
</style>
