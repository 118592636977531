import { UniversityPanelSharedAPI } from '@shared/api/university-panel-shared-api';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Get Microsoft Token
 *
 * @typedef Success
 * @property {string} message
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function getToken() {
  return UniversityPanelSharedAPI.Microsoft.getOAuth2Token()
    .then(({ data }) => data)
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[MicrosoftAPIOauth] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

export {
  getToken,
};
