import template from '@university-panel/legacy/components/templates/role-description.ejs';
import BaseModal from './base-modal';

export default class extends BaseModal {
  constructor(props = {}) {
    super(props, template);
  }

  onShow() {
    this.setupDropdown(this.$container.find('select'));
  }

  setupDropdown($select) {
    $select.select2({
      theme: 'bootstrap',
      language: 'pt-BR',
    }).on('change', () => this.onChange($select.val()));
  }

  onChange(newValue) {
    this
      .$container
      .find('.users-functions-modal__role-description')
      .hide()
      .filter(`[data-role="${newValue}"]`)
      .show();
  }
}
