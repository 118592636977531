<template>
  <FullscreenCard
    :open="open"
  >
    <template v-slot:header-right>
      <template v-if="editMode">
        <div class="oc-batch-fullscreen-sheet__header">
          <z-button
            @click="$emit('save-and-close')"
            class="oc-batch-fullscreen-sheet__header-button"
            size="small"
            variant="tertiary"
          >
            Salvar e sair
          </z-button>

          <z-button
            @click="$emit('submit')"
            class="oc-batch-fullscreen-sheet__header-button"
            size="small"
          >
            Publicar ofertas
          </z-button>
        </div>
      </template>

      <template v-else>
        <div class="oc-batch-fullscreen-sheet__header">
          <z-button
            @click="$emit('close-sheet-view')"
            class="oc-batch-fullscreen-sheet__header-button"
            size="small"
            variant="tertiary"
          >
            Sair
          </z-button>
        </div>
      </template>
    </template>

    <template>
      <iframe
        :src="sheetUrl"
        class="oc-batch-fullscreen-sheet__table"
        frameborder="0"
        loading="eager"
        title="SpreadSheet"
        v-if="open"
      />
    </template>
  </FullscreenCard>
</template>

<script>
import { FullscreenCard } from '@shared/components/fullscreen-card';

export default {
  components: {
    FullscreenCard,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    sheetUrl: {
      required: true,
      type: String,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
  },
};
</script>

<style>
.oc-batch-fullscreen-sheet__header {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
}

.oc-batch-fullscreen-sheet__header-button:first-child {
  margin-right: var(--space-small);
}

.oc-batch-fullscreen-sheet__table {
  --sheet-bottom-menu: 40px;
  position: absolute;
  width: 100%;
  height: calc(100% + var(--sheet-bottom-menu));
}
</style>
