var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GridContainer',{attrs:{"no-padding":""}},[_c('GridItem',{attrs:{"column":6}},[_c('GridContainer',{attrs:{"no-padding":""}},[_c('GridItem',[_c('z-input-field',{staticClass:"step03-enrollment__input-field",attrs:{"label":"A instituição cobra taxa de matrícula?*"}},[_c('z-dropdown-input',{attrs:{"placeholder":"Selecione o tipo de taxa","options":_vm.BillingConfigBusiness.getAmountTypeTextList()},model:{value:(_vm.amountType),callback:function ($$v) {_vm.amountType=$$v},expression:"amountType"}})],1)],1),_vm._v(" "),_c('GridItem',[_c('z-input-field',{staticClass:"step03-enrollment__input-field",class:{
            'step03-enrollment__input-field--disabled': _vm.cannotEditEnrollmentIsFirstPayment
          },attrs:{"label":"A taxa de matrícula equivale a primeira mensalidade do curso?*"}},[_c('z-toggle-switch',{attrs:{"has-label":true,"disabled":_vm.cannotEditEnrollmentIsFirstPayment},model:{value:(_vm.equivalentToFirstBill),callback:function ($$v) {_vm.equivalentToFirstBill=$$v},expression:"equivalentToFirstBill"}})],1)],1),_vm._v(" "),_c('GridItem',[_c('z-input-field',{staticClass:"step03-enrollment__input-field",class:{
            'step03-enrollment__input-field--disabled': _vm.institutionNotHasEnrollmentFee
          },attrs:{"label":"Qual é o valor da taxa de matrícula?*"}},[_c('z-input',{attrs:{"type":"number","placeholder":"Ex.: 00,00","is-disabled":_vm.institutionNotHasEnrollmentFee},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1)],1)],1)],1),_vm._v(" "),_c('GridItem',{attrs:{"column":6}},[_c('GridContainer',{attrs:{"no-padding":""}},[_c('GridItem',[_c('z-input-field',{staticClass:"step03-enrollment__input-field",attrs:{"label":"O aluno perde a bolsa em caso de atraso de mensalidade?"}},[_c('z-toggle-switch',{attrs:{"has-label":""},model:{value:(_vm.canLoseScholarship),callback:function ($$v) {_vm.canLoseScholarship=$$v},expression:"canLoseScholarship"}})],1)],1),_vm._v(" "),_c('GridItem',[_c('z-input-field',{staticClass:"step03-enrollment__input-field",class:{
            'step03-enrollment__input-field--disabled': _vm.cannotLoseScholarship
          },attrs:{"label":"Em quantos dias o aluno perde a bolsa?*"}},[_c('z-input',{attrs:{"type":"number","placeholder":"00","is-disabled":_vm.cannotLoseScholarship},model:{value:(_vm.lossDelay),callback:function ($$v) {_vm.lossDelay=$$v},expression:"lossDelay"}}),_vm._v(" "),_c('div',{staticClass:"step03-enrollment__input-field-wrapper"},_vm._l((_vm.BillingConfigBusiness.getLossDelayTypeTextList()),function(typeOfDay,index){return _c('z-radio',{key:index,staticClass:"step03-enrollment__radio-label",attrs:{"checked":typeOfDay === _vm.lossDelayType,"disabled":_vm.cannotLoseScholarship,"value":typeOfDay,"behavior":"inline"},on:{"change":function($event){_vm.lossDelayType = typeOfDay}}},[_vm._v("\n              "+_vm._s(typeOfDay)+"\n            ")])}),1)],1),_vm._v(" "),_c('z-input-field',{staticClass:"step03-enrollment__input-field",class:{
            'step03-enrollment__input-field--disabled': _vm.cannotLoseScholarship
          },attrs:{"label":"Regra de perda da bolsa*"}},[_c('z-dropdown-input',{attrs:{"placeholder":"Seleciona uma regra","options":_vm.BillingConfigBusiness.getRuleToLoseScholarshipTextList(),"disabled":_vm.cannotLoseScholarship},model:{value:(_vm.rule),callback:function ($$v) {_vm.rule=$$v},expression:"rule"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []
export { render, staticRenderFns }