<template>
  <div class="oc-introduction">
    <z-title
      size="large"
      class="oc-introduction__title"
    >
      Conheça a nova forma de criar ofertas!
    </z-title>

    <z-title
      size="small"
      color="minor"
      class="oc-introduction__subtitle"
    >
      Simples e direta, o controle está nas suas mãos.
    </z-title>

    <div class="oc-introduction__cards-wrapper">
      <div class="oc-introduction__card">
        <z-icon
          size="extra-jumbo"
          :icon="['fal', 'tasks']"
        />

        <z-title
          size="extra-small"
          class="oc-introduction__card-title"
        >
          Você no controle
        </z-title>

        <z-text
          size="medium"
          align="center"
        >
          Crie e faça alterações nas suas ofertas a qualquer momento. Você é quem comanda!
        </z-text>
      </div>

      <div class="oc-introduction__card">
        <z-icon
          size="extra-jumbo"
          :icon="['fal', 'head-side-brain']"
        />

        <z-title
          size="extra-small"
          class="oc-introduction__card-title"
        >
          Recomendações inteligentes
        </z-title>

        <z-text
          size="medium"
          align="center"
        >
          Receba as melhores recomendações com base no mercado para você tomar sempre a melhor decisão.
        </z-text>
      </div>
    </div>

    <z-button
      size="large"
      @click="next"
    >
      Criar ofertas
    </z-button>
  </div>
</template>

<script>
import { notifierMixin } from '@stock/utils/mixins/notifier';

export default {
  mixins: [notifierMixin],
  methods: {
    next() {
      if (navigator.onLine || navigator.onLine === undefined) {
        this.$emit('next');
      } else {
        this.notifyOfflineMessage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@quero/zilla-core/src/utils/_index.scss";

.oc-introduction {
  align-items: center;
  color: var(--body-color);
  display: flex;
  flex-direction: column;
}

.oc-introduction__title {
  margin-bottom: var(--space-medium);
}

.oc-introduction__subtitle {
  margin-bottom: var(--space-extra-jumbo);
}

.oc-introduction__cards-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-extra-colossal);

  @media (min-width: $screen-desktop) {
    flex-direction: row;
  }
}

.oc-introduction__card {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 325px;

  @media (min-width: $screen-desktop) {
    &:not(:last-child) {
      margin-right: var(--space-extra-giant);
    }
  }
}

.oc-introduction__card-title {
  margin-bottom: var(--space-medium);
  margin-top: var(--space-medium);
}
</style>
