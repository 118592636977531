<template>
  <footer class="upa-helper-modal-footer">
    <slot>
      <z-button
        @click="$emit('left-button-click')"
        :variant="leftButtonVariant"
        class="upa-helper-modal-footer__button upa-helper-modal-footer__button--left"
        v-if="hasLeftButton"
        :disabled="leftButtonDisabled"
      >
        {{ leftButtonText }}
      </z-button>

      <z-button
        :variant="rightButtonVariant"
        @click="$emit('right-button-click')"
        class="upa-helper-modal-footer__button upa-helper-modal-footer__button--right"
        v-if="hasRightButton"
        :disabled="rightButtonDisabled"
      >
        {{ rightButtonText }}
      </z-button>
    </slot>
  </footer>
</template>

<script>
export default {
  props: {
    hasLeftButton: {
      type: Boolean,
      default: true,
    },
    hasRightButton: {
      type: Boolean,
      default: true,
    },
    leftButtonText: {
      type: String,
      default: 'Não',
    },
    rightButtonText: {
      type: String,
      default: 'Sim',
    },
    leftButtonDisabled: {
      type: Boolean,
      default: false,
    },
    rightButtonDisabled: {
      type: Boolean,
      default: false,
    },
    leftButtonVariant: {
      type: String,
      default: 'secondary',
    },
    rightButtonVariant: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style>
.upa-helper-modal-footer {
  display: grid;
  grid-template-areas: 'left right';
  justify-content: space-between;
  margin-top: var(--space-jumbo);
}

.upa-helper-modal-footer__button--left {
  grid-area: left;
}

.upa-helper-modal-footer__button--right {
  grid-area: right;
}
</style>
