import $ from 'jquery';
import { UserForm } from '@university-panel/legacy/modules/user-form';
import Modal from '@shared/legacy/components/modals/role-description';
import { Stepper } from '@stock/legacy/components/stepper';
import rolesTemplates from '@university-panel/legacy/modules/roles-templates';

export default class UniversityUser {
  constructor() {
    this.setupRolesDescriptionsHandler();
    new UserForm($('#university_user_form'));
    rolesTemplates($('.js-user-roles-selector'));
  }

  setupRolesDescriptionsHandler() {
    $('.js-show-roles-descriptions').on('click', () => {
      new Modal().show();
    });
  }
}

const stepper = new Stepper();
window.stepper = stepper;
