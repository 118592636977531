import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@shared/utils/dirty_globals';
import 'phoenix_html';
import 'moment/locale/pt-br';
import $ from 'jquery';
import 'multiselect';

import { removePageLoader } from '@shared/utils/page-loader';
import { setupCurrentPage } from '@university-panel/boot/pages';
import { setupConfirm } from '@shared/utils/confirm';
import PushNotification from '@university-panel/legacy/modules/push-notification';
import AjaxSetup from '@university-panel/boot/ajax-setup';
import { initializeAlerts } from '@university-panel/boot/alerts';
import { initializePopovers } from '@university-panel/boot/popover';
import { initializeCookieConsent } from '@university-panel/boot/initialize-cookie-consent';

$(document).ready(async () => {
  new AjaxSetup().setup();

  await Promise.all(setupCurrentPage());
  removePageLoader();

  setupConfirm();

  new PushNotification();

  initializeAlerts();
  initializePopovers();
  initializeCookieConsent();
});
