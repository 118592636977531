<template>
  <div class="one-click-restock">
    <div
      class="one-click-restock__wrapper"
      :class="{'one-click-restock__wrapper--with-footer': isFooterVisible}"
    >
      <one-click-restock-card-error v-if="anErrorHasOccurred" />
      <one-click-restock-wizard
        v-else
        @update-seats-count-to-replace="updateSeatsCountToReplace"
        @one-click-restock="handleOneClickRestock"
        :seats-count-to-replace="seatsCountToReplace"
        :restock-done="restockDone"
        :course-name="courseName"
        :campus="campus"
        :level="level"
        :kind="kind"
        :shift="shift"
        :available-seats="availableSeats"
        :offered-price="offeredPrice"
        :discount="discount / 100"
        :total-seats="totalSeats"
        :is-invalid-seats-count-to-replace="isInvalidSeatsCountToReplace"
        :enrollment-semester="enrollmentSemester"
      />
      <one-click-restock-footer
        v-if="isFooterVisible"
        class="one-click-restock__footer"
        @one-click-restock="handleOneClickRestock"
        :is-invalid-seats-count-to-replace="isInvalidSeatsCountToReplace"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { oneClickTracking } from '@stock/metrics';
import { restockService } from '@stock/services/update-restock';
import OneClickRestockCardError from './one-click-restock-card-error.vue';
import OneClickRestockFooter from './one-click-restock-footer.vue';
import OneClickRestockWizard from './one-click-restock-wizard.vue';

export default Vue.extend({
  components: {
    OneClickRestockCardError,
    OneClickRestockFooter,
    OneClickRestockWizard,
  },
  props: {
    courseName: {
      type: String,
      default: undefined,
    },
    campus: {
      type: String,
      default: undefined,
    },
    level: {
      type: String,
      default: undefined,
    },
    kind: {
      type: String,
      default: undefined,
    },
    shift: {
      type: String,
      default: undefined,
    },
    enrollmentSemester: {
      type: String,
      default: undefined,
    },
    availableSeats: {
      type: Number,
      default: undefined,
    },
    offeredPrice: {
      type: Number,
      default: undefined,
    },
    discount: {
      type: Number,
      default: undefined,
    },
    totalSeats: {
      type: Number,
      default: undefined,
    },
    hasError: {
      type: Boolean,
      required: true,
    },
    errorFlag: {
      type: String,
      default: undefined,
    },
    offerId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      seatsCountToReplace: this.totalSeats,
      anErrorHasOccurred: this.hasError,
      restockDone: false,
    };
  },
  mounted() {
    hj('trigger', 'viewed-one-click-restock-page');
    oneClickTracking.viewedRestockPage({ offerId: this.offerId, errorFlag: this.errorFlag });
  },
  computed: {
    isFooterVisible() {
      return !this.anErrorHasOccurred && !this.restockDone;
    },
    isRecommendedValue() {
      return this.seatsCountToReplace === this.totalSeats;
    },
    isInvalidSeatsCountToReplace() {
      return this.seatsCountToReplace === 0;
    },
  },
  methods: {
    updateSeatsCountToReplace({ value }) {
      this.seatsCountToReplace = Number(value);
    },
    sendRestockRequest() {
      return restockService.updateRestock({
        offerIds: [this.offerId],
        amountSeats: this.seatsCountToReplace,
        withRecommendedValue: this.isRecommendedValue,
        source: 'one-click',
        restockType: '+',
      });
    },
    async handleOneClickRestock() {
      try {
        await this.sendRestockRequest();
        this.restockDone = true;
      } catch (error) {
        this.anErrorHasOccurred = true;
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '~@quero/zilla-core/src/utils/variables';

.one-click-restock {
  background-color: var(--color-neutral-lighter-gray);
  min-height: 100%;
}

.one-click-restock__wrapper {
  margin: 0 auto;
  max-width: $screen-sm;
  padding: var(--space-medium);
}

.one-click-restock__wrapper--with-footer {
  margin-bottom: var(--space-extra-colossal);

  @media(min-width: $screen-sm) {
    margin-bottom: 0;
  }
}

.one-click-restock__footer {
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;

  @media(min-width: $screen-sm) {
    display: none;
  }
}
</style>
