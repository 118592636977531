import $ from 'jquery';
import * as _ from 'lodash';
import AjaxRequest from '@shared/utils/ajax-request';
import ajaxLoadingAnimation from '@stock/legacy/components/ajax-loading-animation';
import KeyCodes from '@shared/utils/key-codes';
import BaseModal from '@shared/legacy/components/modals/base-modal';
import validator from '@shared/utils/validator';

export class BatchOperationRequest {
  constructor(searchForm, additionalParameters = {}) {
    this.filters = this.prepareFilters(searchForm);
    this.additionalParameters = additionalParameters;
  }

  prepareFilters(searchForm) {
    return searchForm.toDataTransferObject();
  }

  toJson() {
    return _.assign({ sl: this.filters }, this.additionalParameters);
  }
}

const COUNT_OFFERS_URL = '/ui/ofertas/count_list';

export class BatchOperationModal extends BaseModal {
  constructor(props, searchForm, alert, template) {
    super(props, template);
    this.alert = alert;
    this.searchForm = searchForm;
    this.request = new AjaxRequest(COUNT_OFFERS_URL)
      .on('success', result => this.requestSuccessHandler(result));
    this.validations = [];
    ajaxLoadingAnimation(this.request);
  }

  requestSuccessHandler({
    total_offers_count: totalOffersCount,
    total_offers_target_count: totalOffersTargetCount,
  }) {
    this.totalOffersCount = totalOffersCount;
    this.totalOffersTargetCount = totalOffersTargetCount;
    this.enableConfirmButton();
  }

  onShow() {
    this.hideWarnings();
    this.disableConfirmButton();
    this.preventSubmitWithEnter();
    this.setupConfirmation();
    this.countOffers();
    this.setupRadioButton();
  }

  hideWarnings() {
    this.alert.hide();
    this.searchForm.clearValidation('discount');
  }

  showWarnings(message, field) {
    this.hideWarnings();
    this.alert.show({
      type: 'warning',
      message,
    });
    this.searchForm.setWarning(field);
  }

  disableConfirmButton() {
    $('.js-next-button').prop('disabled', true);
  }

  enableConfirmButton() {
    if (this.inputIsValid && this.totalOffersTargetCount > 0) {
      $('.js-next-button').prop('disabled', false);
    }
  }

  setupRadioButton() {
    const $typeaction = $('.js-type-action');
    const $inputs = $('.js-typeaction-input');
    $inputs.on('change', (event) => {
      const action = event.target.value;
      $typeaction.text(action);
    });
    $('.js-typeaction-input:checked').trigger('change');
  }

  preventSubmitWithEnter() {
    $('form input').keydown((e) => {
      if (e.keyCode === KeyCodes.ENTER) {
        e.preventDefault();
        return false;
      }
      return true;
    });
  }

  getAppliedValueFormated(appliedValue) {
    const sign = this.radioSign;
    return sign === '-' ? Number(appliedValue * -1) : Number(appliedValue);
  }

  get radioSign() {
    return $('.js-typeaction-input:checked').val();
  }

  applyValidator(htmlElement, validators = []) {
    const currentPosition = this.validations.length;
    this.validations.push(false);
    validator(htmlElement, {
      validations: validators,
      whenValid: (el) => {
        this.validations[currentPosition] = true;
        this.updateInputValidation();
        this.enableConfirmButton();
        $(el).closest('.form-group').removeClass('has-error');
      },
      whenInvalid: (el) => {
        this.validations[currentPosition] = false;
        this.updateInputValidation();
        this.disableConfirmButton();
        $(el).closest('.form-group').addClass('has-error');
      },
    });
  }

  updateInputValidation() {
    this.inputIsValid = this.validations.every(e => e);
  }

  countOffers() {
    this.request.postJson(this.setupBatchOperation().toJson());
  }

  setupConfirmation() {
    $('.js-next-button').click((evt) => {
      evt.preventDefault();
      const confirmationModalData = this.getConfirmationData();
      const batchOperationRequest = this.setupBatchOperation();
      this.destroy();
      $(this).trigger('next', _.merge(
        {
          activeFilters: this.searchForm.toObject(),
          ...confirmationModalData,
        },
        { batchOperationRequest },
      ));
    });
  }
}
