import Vue from 'vue';
import { ValidatePlugin } from '@shared/utils/vue/plugins/validate-plugin';
import { ZillaPlugin } from '@shared/utils/vue/plugins/zilla-plugin';
import { ComponentsPlugin } from '@shared/utils/vue/plugins/components-plugin';
import { RollbarService } from '@shared/utils/rollbar-service';

export function basicZillaSetup({ selector }) {
  return new Promise(((resolve) => {
    try {
      Vue.use(ValidatePlugin);
      Vue.use(ZillaPlugin);
      Vue.use(ComponentsPlugin);
      return new Vue({
        el: selector,
        mounted() {
          resolve();
        },
      });
    } catch (error) {
      RollbarService.getInstance().notifyError('error when setting up Zilla');
      return resolve();
    }
  }));
}
