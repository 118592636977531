<template>
  <div class="feedback-card-error">
    <slot name="icon">
      <z-icon
        :icon="['fad', 'times-circle']"
        title="error"
        size="giant"
        color="danger"
        class="feedback-card-error__icon"
      />
    </slot>

    <slot>
      <div
        class="feedback-card-error__content"
        v-if="title || text"
      >
        <z-title
          class="feedback-card-error__text"
          size="medium"
          v-if="title"
        >
          {{ title }}
        </z-title>

        <z-text
          class="feedback-card-error__text"
          color="minor"
          size="large"
          v-if="text"
        >
          {{ text }}
        </z-text>
      </div>
    </slot>

    <slot name="action">
      <z-button
        class="feedback-card-error__button"
        v-if="buttonText"
        @click="$emit('click', 'error')"
      >
        {{ buttonText }}
      </z-button>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Erro na ação!',
    },
    text: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'Está bem',
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-card-error {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 288px;
}

.feedback-card-error__icon {
  margin-bottom: var(--space-large);
}

.feedback-card-error__text {
  width: 100%;
  margin-bottom: var(--space-medium);

  &:last-child {
    margin-bottom: var(--space-extra-large);
  }
}

.feedback-card-error__button {
  width: 100%;
}
</style>
