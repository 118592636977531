<template>
  <z-card
    class="describer-card"
  >
    <section class="describer-card__header">
      <z-icon
        v-if="icon"
        size="large"
        :icon="icon"
        class="describer-card__header-icon"
      />

      <div>
        <z-title
          size="extra-small"
          class="describer-card__header-title"
        >
          {{ title }}
        </z-title>

        <z-text color="minor">
          {{ description }}
        </z-text>
      </div>
    </section>

    <slot />
  </z-card>
</template>

<script>

export default {
  props: {
    icon: {
      type: [String, Array],
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.describer-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.describer-card__header {
  display: flex;

  &:not(:only-child) {
    margin-bottom: var(--space-medium);
  }
}

.describer-card__header-icon {
  margin-right: var(--space-small);
}

.describer-card__header-title {
  margin-bottom: var(--space-small);
}
</style>
