import { BankBusiness, BillingConfigBusiness } from '@quero-turbo/business';

/**
 * Parse data response of Billing config
 *
 * @param {Object} params
 * @property {text} params.term The term to be searched
 * @property {Array[number]} params.universityIds Ids to be searched
 *
 * @typedef Success
 * @property {object} parsedData
 */
async function parseResponseData({ payload }) {
  const unserializedPayload = _unserializeParams(payload);
  return unserializedPayload;
}

const _unserializeParams = (params) => {
  const payload = _dupParams(params);
  if (payload.admissionPaymentRule) {
    payload.admissionPaymentRule = {
      ...payload.admissionPaymentRule,
      amountType: BillingConfigBusiness.getAmountTypeTextByKey(payload.admissionPaymentRule.amountType),
    };
  } else {
    payload.admissionPaymentRule = {
      amount: null,
      amountType: false,
      equivalentToFirstBill: false,
    };
  }

  payload.bankAccountParams = {
    ...payload.bankAccountParams,
    bankSelected: BankBusiness.getBankByCode(payload.bankAccountParams.bankCode),
    bankAccountType: BankBusiness.getBankAccountTypeTextByKey(payload.bankAccountParams.bankAccountType),
    transferDays: payload.bankAccountParams.transferDays.join(', '),
  };
  payload.contractCreationRule = {
    ...payload.contractCreationRule,
    duration: Number(payload.contractCreationRule.duration),
    startRule: BillingConfigBusiness.getStartRuleTextByKey(payload.contractCreationRule.startRule),
  };
  payload.contractDischargeRules = {
    ...payload.contractDischargeRules,
    methods: payload.contractDischargeRules.methods.map(paymentMethod => BillingConfigBusiness.getPaymentMethodTextByKey(paymentMethod)),
  };
  payload.paymentMethodRules = {
    ...payload.paymentMethodRules,
    methods: payload.paymentMethodRules.methods.map(paymentMethod => BillingConfigBusiness.getPaymentMethodTextByKey(paymentMethod)),
  };
  payload.scholarshipLosingRule = {
    ...payload.scholarshipLosingRule,
    lossDelayType: BillingConfigBusiness.getLossDelayTypeTextByKey(payload.scholarshipLosingRule.lossDelayType),
    rule: BillingConfigBusiness.getRuleToLoseScholarshipTextByKey(payload.scholarshipLosingRule.rule),
  };
  payload.dueDayRule = {
    ...payload.dueDayRule,
    dueDays: payload.dueDayRule.dueDays.join(', '),
  };
  return payload;
};

const _dupParams = (payload) => {
  const payloadWithoutObservers = JSON.parse(JSON.stringify(payload));
  return Object.assign({}, payloadWithoutObservers);
};

export {
  parseResponseData,
};
