<template>
  <z-card>
    <z-title
      size="small"
      class="create-deal-form__title"
    >
      Criando uma nova negociação
    </z-title>

    <form @submit.prevent="handleFormSubmit">
      <GridContainer class="create-deal-form__row">
        <GridItem :column="3">
          <z-input-field label="Nome">
            <z-input v-model="dealName" />
          </z-input-field>
        </GridItem>

        <GridItem :column="3">
          <z-input-field label="Tipo de polo">
            <z-dropdown-input
              v-model="queroPoloTypeLabel"
              :options="queroPoloTypeOptions"
            />
          </z-input-field>
        </GridItem>
      </GridContainer>

      <GridContainer class="create-deal-form__row">
        <GridItem class="create-deal-form__action-section">
          <z-button
            tag="router-link"
            to="/"
            variant="secondary"
            class="create-deal-form__action-section-button"
          >
            Cancelar
          </z-button>

          <z-button
            class="create-deal-form__action-section-button"
          >
            Criar negociação
          </z-button>
        </GridItem>
      </GridContainer>
    </form>

    <FeedbackModal
      :config="feedbackCardsConfig"
      :open="feedbackModalOpened"
      :closable="false"
      @success-click="handleSuccessClick"
      @error-click="handleErrorClick"
      :status="feedbackModalStatus"
    />
  </z-card>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { QueroDealsServices } from '@quero-turbo/services';
import { FeedbackModal } from '@stock/components/feedback-components';
import { DealBusiness } from '@quero-turbo/business';

export default {
  components: {
    FeedbackModal,
    GridContainer,
    GridItem,
  },
  computed: {
    feedbackCardsConfig() {
      return {
        success: {
          title: 'Negociação criado!',
          buttonText: 'Ir para menu principal',
        },
        error: {
          title: 'Ops! Encontramos alguns erros no formulário',
          buttonText: 'Ok, entendi',
        },
        loading: {
          title: 'Aguarde, estamos analisando o formulário',
        },
      };
    },
    queroPoloTypeOptions() {
      return DealBusiness.getQueroPoloTypeList();
    },
  },
  data() {
    return {
      dealName: '',
      queroPoloTypeLabel: '',
      feedbackModalOpened: false,
      feedbackModalStatus: 'loading',
    };
  },
  methods: {
    handleFormSubmit() {
      const data = {
        name: this.dealName,
        queroPoloType: DealBusiness.getQueroPoloTypeLabelDictionary(this.queroPoloTypeLabel),
      };
      this.requestCreateDeal(data);
    },
    requestCreateDeal(params) {
      try {
        this.feedbackModalOpened = true;
        this.feedbackModalStatus = 'loading';
        this.isValidRequest();
        QueroDealsServices.V1.Deal.create(params).then(({ _result }) => {
          this.feedbackModalStatus = 'success';
        });
      } catch (error) {
        this.feedbackModalStatus = 'error';
      }
    },
    isValidRequest() {
      if (this.dealName) return true;
      throw new Error('Erro de validação');
    },
    handleSuccessClick() {
      this.feedbackModalOpened = false;
      this.$router.push('/');
    },
    handleErrorClick() {
      this.feedbackModalOpened = false;
      this.feedbackModalStatus = 'loading';
    },
  },
};
</script>

<style>
.create-deal-form__title {
  margin-bottom: var(--space-medium);
}

.create-deal-form__row {
  --grid-padding: 0;
}

.create-deal-form__action-section {
  display: flex;
  justify-content: flex-end;
}

.create-deal-form__action-section-button {
  margin-left: var(--space-medium);
}
</style>
