export const CHART_COLORS = {
  base: {
    alpha: '#0D2262',
    bravo: '#304FFE',
    charlie: '#D59206',
    delta: '#D5D9E0',
    echo: '#DA1E28',
    foxtrot: '#198038',
    golf: '#FA6400',
  },
  font: {
    default: '#9FA5AD',
  },
  grid: {
    default: '#F6F7F8',
  },
};
