import $ from 'jquery';
import formToJson from '@shared/utils/form-to-json';

const ValidationCls = {
  WARN: 'has-warning',
};

export default class {
  constructor(form) {
    this.$form = $(form);

    this.setupEvents();
  }

  get $fields() {
    return this.$form.find('[data-known-as]');
  }

  serializeArray() {
    return this.$form.serializeArray();
  }

  serialize() {
    return this.$form.serialize();
  }

  toDataTransferObject() {
    return formToJson(this);
  }

  toObject() {
    const result = {};

    this.$fields.each((_, el) => {
      const $el = $(el);
      const knownAs = $el.data('known-as');

      result[knownAs] = { name: knownAs, value: $el.val() };

      if ($el.is('select')) {
        result[knownAs].options = [];

        $el.find('option').each((i, e) => {
          const $e = $(e);
          result[knownAs].options.push({
            text: $e.text(),
            value: $e.attr('value'),
            selected: $e.is(':selected'),
          });
        });
      }
    });

    return result;
  }

  submit() {
    this.$form.submit();
  }

  val(label, value) {
    if (value !== undefined) {
      const $field = this.getFieldByLabel(label);
      if ($field.length) {
        return $field
          .val(value)
          .trigger('change'); // force select2 to show new value
      }
    }

    return this.getFieldByLabel(label).val();
  }

  setWarning(label) {
    this
      .getFieldContainerByLabel(label)
      .addClass(ValidationCls.WARN);
  }

  setFocus(label) {
    this
      .getFieldByLabel(label)
      .focus();
  }

  clearValidation(label) {
    if (!label) {
      return this.$fields.each((i, el) => {
        $(el).closest('.form-group').removeClass(ValidationCls.WARN);
      });
    }

    return this
      .getFieldContainerByLabel(label)
      .removeClass(ValidationCls.WARN);
  }

  getFieldByLabel(label) {
    return this
      .$form
      .find(`[data-known-as="${label}"]`);
  }

  getFieldContainerByLabel(label) {
    return this.getFieldByLabel(label).closest('.form-group');
  }

  setupEvents() {
    this.$form.submit(() => {
      $(this).trigger('submitting');
    });

    this.$form.on('ajax:success', (_, {
      html,
      total_pages: totalPages,
      entries_count: entriesCount,
      offers_count: offersCount,
    }) => {
      const submitSuccessEvent = $.Event('submit:success', {
        html,
        page: parseInt(this.val('page'), 10),
        pages: parseInt(totalPages, 10),
        entriesCount,
        offersCount,
      });
      $(this).trigger(submitSuccessEvent);
    });

    this.$form.on('ajax:error', () => {
      $(this).trigger($.Event('submit:error', { html: '' }));
    });

    this.$form.find('button[type="submit"]').on('click', () => {
      this.val('page', 1);
    });
  }
}
