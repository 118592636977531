import MessageModal from '@stock/legacy/components/modals/message-modal';
import ParagraphTemplate from '@university-panel/legacy/components/templates/paragraphs.html.ejs';

export const validationSharedSeatsModal = (isBatch) => {
  function getMessageText() {
    return isBatch
      ? `
        Todas as ofertas filtradas possuem vagas ilimitadas e/ou compartilhadas.
        Caso queira alterar a quantidade de bolsas para essas ofertas, fale conosco.
        `
      : `
        Esta oferta possui vagas ilimitadas e/ou divide vagas com outra(s).
        Caso queira alterar a quantidade de bolsas, fale conosco.
        `;
  }

  const paragraphs = [
    getMessageText(),
  ];

  const messageModal = new MessageModal({
    title: 'Alterar quantidade de bolsas',
    content: ParagraphTemplate({ paragraphs }),
    submitText: 'Fale conosco',
  });

  $(messageModal).on('submit', (event) => {
    event.preventDefault();
    messageModal.hide();
    window.open('mailto:suporteQP@queropago.com.br?subject=[QA%20-%20Outros]%20-%20Assunto&body=', '_blank');
  });

  return messageModal;
};
