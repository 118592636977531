import $ from 'jquery';
import 'confirm-bootstrap';

export function setupConfirm() {
  $(document).ready(() => {
    $('[data-confirm-modal=true]').each(function setupConfimModal() {
      const $this = $(this);

      $this.confirmModal({
        confirmTitle: $this.data('confirm-modal-title') || 'Você tem certeza?',
        confirmMessage: $this.data('confirm-modal-message') || 'Deseja prosseguir?',
        confirmOk: 'Sim',
        confirmCancel: 'Cancelar',
        confirmStyle: 'primary',
        confirmAutoOpen: false,
      });
    });
  });
}
