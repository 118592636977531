import Vue from 'vue';
import { ZillaPlugin } from '@shared/utils/vue/plugins/zilla-plugin';
import { ValidatePlugin } from '@shared/utils/vue/plugins/validate-plugin';
import { OneClickRestock } from '@stock/modules/one-click-restock';

export function OneClickRestockPage() {
  return new Promise((resolve) => {
    Vue.use(ValidatePlugin);
    Vue.use(ZillaPlugin);

    new Vue({
      el: '#js-one-click-restock-page',
      components: {
        OneClickRestock,
      },
      mounted() {
        resolve();
      },
    });
  });
}
