import ActiveFilters from '@stock/legacy/components/active-filters';
import AjaxRequest from '@shared/utils/ajax-request';
import ajaxLoadingAnimation from '@stock/legacy/components/ajax-loading-animation';
import BaseModal from '@shared/legacy/components/modals/base-modal';
import NumberFormat from '@shared/utils/number_format';
import activeFiltersTemplate
  from '@university-panel/legacy/components/templates/stock/active-filters/list-active-filters.html.ejs';
import ResponseModal from '@stock/legacy/components/modals/modal-response';

export default class BatchConfirmationModal extends BaseModal {
  constructor(serviceUrl, props, alert, template) {
    const extraProps = BatchConfirmationModal.buildExtraProps(props);
    super(extraProps, template);
    this.alert = alert;
    this.activeFilters = props.activeFilters;
    this.request = new AjaxRequest(serviceUrl)
      .on('success', this.showSuccessModal.bind(this))
      .on('validationError', (response) => {
        super.destroy();
        this.alert.showValidationError(response.messages);
      })
      .on('serverError', () => {
        super.destroy();
        this.alert.showDefaultErrorMessage();
      });
    ajaxLoadingAnimation(this.request);
  }

  static buildExtraProps(props) {
    const totalOffersBlocked = props.totalOffers - props.totalOffersTarget;
    return {
      ...props,
      formatter: new NumberFormat({ decimalSeparator: ',' }),
      totalOffersBlocked,
    };
  }

  onShow() {
    this.renderFilters();
  }

  onRequest(event, callback) {
    this.alert.hide();
    this.request.on(event, callback);
    return this;
  }

  onSubmit(evt) {
    super.onSubmit();
    evt.preventDefault();
    this.request.postJson(this.props.batchOperationRequest.toJson());
  }

  renderFilters() {
    new ActiveFilters(
      $('.js-active-filters'),
      this.activeFilters,
      activeFiltersTemplate,
    );
  }

  showSuccessModal(response) {
    super.destroy();
    const messageModal = new ResponseModal({
      iconClass: 'fal fa-check-circle fa-4x',
      title: 'Solicitação realizada com sucesso!',
      content: `
        Em breve sua alteração estará disponível na plataforma e você será avisado por e-mail.
        Você também pode acompanhar suas alterações no <strong>Histórico de ações.</strong>
      `,
      submitText: 'Histórico de ações',
    });
    messageModal.show();
    messageModal.$container.on('click', '.js-confirm-button', () => {
      window.location.href = response.data.redirect;
    });
  }
}
