<template>
  <div class="step-01-identify-config">
    <SectionConfigDeal class="step-01-identify-config__section" />

    <z-divider />

    <SectionConfigBRGName class="step-01-identify-config__section" />

    <z-divider />

    <SectionConfigBRGDescription class="step-01-identify-config__section" />

    <z-divider />

    <SectionConfigBRGContract class="step-01-identify-config__section" />
  </div>
</template>

<script>
import SectionConfigDeal from './section-config-deal.vue';
import SectionConfigBRGName from './section-config-brg-name.vue';
import SectionConfigBRGDescription from './section-config-brg-description.vue';
import SectionConfigBRGContract from './section-config-brg-contract.vue';

export default {
  components: {
    SectionConfigDeal,
    SectionConfigBRGName,
    SectionConfigBRGDescription,
    SectionConfigBRGContract,
  },
};
</script>

<style lang="scss">
.step-01-identify-config {
  display: flex;
  justify-content: space-around;
}

.step-01-identify-config__section {
  max-width: 230px;
  width: 100%;
}
</style>
