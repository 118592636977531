import { CommissionRulesBusiness } from '@quero-turbo/business';

/**
 * Parse data of Commission config
 *
 * @param {Object} params
 *
 * @typedef Success
 * @property {object} parsedData
 */
async function parseRequestData({ businessRuleGroupId, commissionConfigs }) {
  const data = JSON.parse(JSON.stringify(commissionConfigs));
  const payload = {
    businessRuleGroupId,
    commissionConfigRules: _serializeParams(data),
  };
  const params = {
    businessRuleGroupId,
    payload,
    configType: 'commission',
  };

  return params;
}

const _serializeParams = data => (
  data.map(commissionConfig => ({
    fee: commissionConfig.commissionFee,
    feeType: _parseCommissionFeeType(commissionConfig),
    paymentModel: _parsePaymentModel(commissionConfig),
    paymentTrigger: _parsePaymentTrigger(commissionConfig),
    subsidy: commissionConfig.subsidy,
    turboAccountId: _parseAccountSelected(commissionConfig),
  })));

const _parseCommissionFeeType = ({ commissionFeeTypeLabel }) => (commissionFeeTypeLabel && CommissionRulesBusiness.getCommissionFeeTypeKeyByText(commissionFeeTypeLabel)) || null;
const _parsePaymentModel = ({ paymentModel }) => (paymentModel && CommissionRulesBusiness.getPaymentModelKeyByText(paymentModel)) || null;
const _parsePaymentTrigger = ({ paymentTrigger }) => (paymentTrigger && CommissionRulesBusiness.getPaymentTriggerKeyByText(paymentTrigger)) || null;
const _parseAccountSelected = ({ accountSelected }) => (accountSelected && accountSelected.id) || null;

export {
  parseRequestData,
};
