import windowOfferConfirmationTemplate
  from '@university-panel/legacy/components/templates/stock/create-window-offers-confirmation-modal.html.ejs';
import ConfirmationModal from '@stock/legacy/components/modals/offer-list-actions/batch/confirmation';

const CREATE_WINDOW_OFFERS_URL = '/ofertas/vitrine';

export default (modal, alert) => {
  $(modal).on('next', (_evt, props) => {
    const confirmationModal = new ConfirmationModal(
      CREATE_WINDOW_OFFERS_URL,
      props,
      alert,
      windowOfferConfirmationTemplate,
    );
    confirmationModal.show();
  });
};
