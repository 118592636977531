<template>
  <UPAModalFooter
    v-bind="$props"
    @right-button-click="$emit('right-button-click')"
    @left-button-click="$emit('left-button-click')"
  />
</template>

<script>
import { UPAModalFooter } from '@shared/components/upa-modal';

export default {
  components: {
    UPAModalFooter,
  },
  props: {
    hasLeftButton: {
      type: Boolean,
      default: true,
    },
    hasRightButton: {
      type: Boolean,
      default: true,
    },
    leftButtonText: {
      type: String,
      default: 'Voltar',
    },
    rightButtonText: {
      type: String,
      default: 'Avançar',
    },
    leftButtonDisabled: {
      type: Boolean,
      default: false,
    },
    rightButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.quero-turbo-activation-modal-footer {
  display: grid;
  grid-template-areas: 'left right';
  justify-content: space-between;
  margin-top: var(--space-jumbo);
}

.quero-turbo-activation-modal-footer__button--left {
  grid-area: left;
}

.quero-turbo-activation-modal-footer__button--right {
  grid-area: right;
}
</style>
