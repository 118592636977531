<template>
  <span
    @mouseenter="addClearButton()"
    @mouseleave="removeClearButton()"
  >
    <z-input
      size="small"
      :value="value"
      :type="type"
      :step="step"
      :max="max"
      :min="min"
      :has-error="hasError"
      @input="emitInputChange"
      :clear-button-aria-label="clearButton"
    />
  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    step: {
      type: String,
      required: false,
      default: null,
    },
    max: {
      type: String,
      required: false,
      default: null,
    },
    min: {
      type: String,
      required: false,
      default: null,
    },
    hasError: {
      type: Boolean,
      required: false,
      default: null,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      clearButton: null,
    };
  },
  methods: {
    emitInputChange(value) {
      this.$emit('inputValueChange', value);
    },
    addClearButton() {
      this.clearButton = 'limpar';
    },
    removeClearButton() {
      this.clearButton = null;
    },
  },
};
</script>
