<template>
  <section>
    <z-input-field
      label="Nome da configuração (BRG)"
      class="section-config-brg-name__input"
    >
      <z-input
        v-model="name"
        size="small"
        placeholder="Crie um nome"
      />
    </z-input-field>

    <z-input-field
      label="Conta"
      class="section-config-brg-name__input"
    >
      <z-input
        type="number"
        v-model="accountId"
        size="small"
        placeholder="Conta"
      />
    </z-input-field>

    <z-input-field
      label="Prioridade"
      class="section-config-brg-name__input"
    >
      <z-input
        type="number"
        v-model="priority"
        size="small"
        placeholder="01"
      />
    </z-input-field>
  </section>
</template>

<script>
export default {
  computed: {
    name: {
      get() {
        return this.$store.state.QueroTurboActivationModalModule.businessRuleGroup.name;
      },
      set(value) {
        this.$store.commit('QueroTurboActivationModalModule/setBRGName', value);
      },
    },
    priority: {
      get() {
        return this.$store.state.QueroTurboActivationModalModule.businessRuleGroup.priority;
      },
      set(value) {
        this.$store.commit('QueroTurboActivationModalModule/setBRGPriority', value);
      },
    },
    accountId: {
      get() {
        return this.$store.state.QueroTurboActivationModalModule.commissionAccountRule.turboAccountId;
      },
      set(value) {
        this.$store.commit('QueroTurboActivationModalModule/setTurboAccountId', value);
      },
    },
  },
};
</script>

<style lang="scss">
.section-config-brg-name__input {
  margin-bottom: var(--space-medium);
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
