<template>
  <one-click-restock-card-success
    v-if="restockDone"
    :course-name="courseName"
    :campus="campus"
    :level="level"
    :kind="kind"
    :shift="shift"
    :available-seats="seatsCountToReplace"
    :offered-price="offeredPrice"
    :discount="discount"
    :total-seats="totalSeats"
    :enrollment-semester="enrollmentSemester"
  />
  <one-click-restock-card-action
    v-else
    @update-seats-count-to-replace="updateSeatsCountToReplace"
    @one-click-restock="handleOneClickRestock"
    :course-name="courseName"
    :campus="campus"
    :level="level"
    :kind="kind"
    :shift="shift"
    :available-seats="availableSeats"
    :offered-price="offeredPrice"
    :discount="discount"
    :total-seats="totalSeats"
    :is-invalid-seats-count-to-replace="isInvalidSeatsCountToReplace"
    :initial-seats-count-to-replace="seatsCountToReplace"
    :enrollment-semester="enrollmentSemester"
  />
</template>

<script>
import Vue from 'vue';
import OneClickRestockCardAction from './one-click-restock-card-action';
import OneClickRestockCardSuccess from './one-click-restock-card-success';

export default Vue.extend({
  components: {
    OneClickRestockCardAction,
    OneClickRestockCardSuccess,
  },
  props: {
    courseName: {
      type: String,
      default: undefined,
    },
    campus: {
      type: String,
      default: undefined,
    },
    level: {
      type: String,
      default: undefined,
    },
    kind: {
      type: String,
      default: undefined,
    },
    shift: {
      type: String,
      default: undefined,
    },
    availableSeats: {
      type: Number,
      default: undefined,
    },
    offeredPrice: {
      type: Number,
      default: undefined,
    },
    discount: {
      type: Number,
      default: undefined,
    },
    totalSeats: {
      type: Number,
      default: undefined,
    },
    restockDone: {
      type: Boolean,
      required: true,
    },
    isInvalidSeatsCountToReplace: {
      type: Boolean,
      required: true,
    },
    seatsCountToReplace: {
      type: Number,
      required: true,
    },
    enrollmentSemester: {
      type: String,
      required: true,
    },
  },
  methods: {
    updateSeatsCountToReplace({ value }) {
      this.$emit('update-seats-count-to-replace', { value });
    },
    handleOneClickRestock() {
      this.$emit('one-click-restock');
    },
  },
});
</script>
