import AmplitudeService from '@shared/utils/amplitude-service';

const amplitude = AmplitudeService.getInstance();

const _logEvent = (eventName, options) => {
  const eventFullName = `[offer_list] ${eventName}`;
  amplitude.logEvent(eventFullName, options);
};

const applyWidgetRestockFilter = () => {
  _logEvent('apply widget restock filter', {});
};

const applyWidgetOptimizeOfferFilter = () => {
  _logEvent('apply widget optimize offer filter', {});
};

const sentRequisitionToOptimizeOffer = () => {
  _logEvent('sent_requisition_to_optimize_offer', {});
};

const removeWidgetRestockFilter = () => {
  _logEvent('remove widget restock filter', {});
};

const removeWidgetOptimizeOfferFilter = () => {
  _logEvent('remove widget optimize offer filter', {});
};

export const offerListTracking = {
  applyWidgetOptimizeOfferFilter,
  applyWidgetRestockFilter,
  removeWidgetOptimizeOfferFilter,
  removeWidgetRestockFilter,
  sentRequisitionToOptimizeOffer,
};
