<template>
  <PowerBIDashboard
    class="student-attracting-dashboard"
    :base-u-r-l="baseURL"
    :report-id="reportId"
    :university-id="universityId"
    @load="$emit('load')"
  />
</template>

<script>
import { PowerBIDashboard } from '@university-panel/components';

export default {
  components: {
    PowerBIDashboard,
  },
  props: {
    universityId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    reportId() {
      return UPA.studentAttractingDashboardId;
    },
    baseURL() {
      return UPA.studentAttractingDashboardUrl;
    },
  },
};
</script>

<style scoped>
.student-attracting-dashboard {
  height: 3200px;
}
</style>
