<template>
  <z-modal
    :class="[
      'quero-turbo-activation-modal',
    ]"
    :open="open"
    @closed="$emit('close-modal')"
  >
    <div
      class="quero-turbo-activation-modal__content"
    >
      <div
        class="quero-turbo-activation-modal__body"
        :class="{
          'quero-turbo-activation-modal__body--with-sidebar': currentStepHasSidebar
        }"
      >
        <QueroTurboActivationModalHeader
          name="Configuração de QBTurbo"
          :title="currentStepTitle"
          :subtitle="currentStepSubtitle"
          :current-step-number="currentStepPosition"
          :max-step-number="maxStepNumber"
        />

        <component
          :is="currentStep.component"
          v-if="open"
        />

        <QueroTurboActivationModalFooter
          @left-button-click="handlePrev"
          @right-button-click="handleNext"
          :has-right-button="hasNextStep"
          :has-left-button="hasPrevStep"
          :right-button-disabled="!canIAdvanceStep"
        />
      </div>

      <div
        class="quero-turbo-activation-modal__sidebar"
        v-if="currentStepHasSidebar"
      >
        <QueroTurboActivationModalSidebar />
      </div>
    </div>
  </z-modal>
</template>

<script>
import { StepsNavigationSetup } from './steps-navigation-setup';
import QueroTurboActivationModalFooter from './quero-turbo-activation-modal-footer.vue';
import QueroTurboActivationModalHeader from './quero-turbo-activation-modal-header.vue';
import QueroTurboActivationModalSidebar from './quero-turbo-activation-modal-sidebar.vue';

export default {
  components: {
    QueroTurboActivationModalFooter,
    QueroTurboActivationModalHeader,
    QueroTurboActivationModalSidebar,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    maxStepNumber() {
      return 4;
    },
    currentStepName: {
      get() {
        return this.$store.state.QueroTurboActivationModalModule.currentStepName;
      },
      set(value) {
        this.$store.commit('QueroTurboActivationModalModule/setCurrentStepName', value);
      },
    },
    currentStep() {
      return StepsNavigationSetup[this.currentStepName];
    },
    currentStepPosition() {
      return this.currentStep.position;
    },
    currentStepTitle() {
      return this.currentStep.title;
    },
    currentStepSubtitle() {
      return this.currentStep.subtitle;
    },
    currentStepHasSidebar() {
      return this.currentStep.sidebar;
    },
    hasNextStep() {
      return Boolean(this.currentStep.nextStepName);
    },
    hasPrevStep() {
      return Boolean(this.currentStep.prevStepName);
    },
    canIAdvanceStep() {
      return this.$store.getters['QueroTurboActivationModalModule/canIAdvanceStep'];
    },
  },
  methods: {
    handleNext() {
      if (this.hasNextStep) this.currentStepName = this.currentStep.nextStepName;
    },
    handlePrev() {
      if (this.hasPrevStep) this.currentStepName = this.currentStep.prevStepName;
    },
  },
};
</script>

<style lang="scss">
@import "@quero/zilla-core/src/utils/_index.scss";
.quero-turbo-activation-modal {
  &.z-modal {
    z-index: 1001;
  }

  .z-modal__wrapper {
    @media (min-width: $screen-desktop) {
      --modal-width: 1216px;
      width: 100%; // TODO: Remove this when zilla updates to customize width
    }
  }
}

.quero-turbo-activation-modal__content {
  display: flex;
}

.quero-turbo-activation-modal__body {
  flex: 1;
}

.quero-turbo-activation-modal__body--with-sidebar {
  margin-right: var(--space-jumbo);
}

.quero-turbo-activation-modal__sidebar {
  display: flex;
  flex: 0 0 280px;
  margin-bottom: calc(-1 * var(--space-extra-large));
  margin-left: var(--space-extra-large);
  margin-right: calc(-1 * var(--space-extra-large));
  margin-top: calc(-1 * var(--space-extra-large));
  @media (min-width: $screen-desktop) {
    margin-top: calc(-1 * var(--space-jumbo));
    margin-right: calc(-1 * var(--space-jumbo));
    margin-bottom: calc(-1 * var(--space-jumbo));
    margin-left: var(--space-jumbo);
  }
}
</style>
