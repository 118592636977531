export const competitivenessMixin = {
  computed: {
    getCompetitiveColors() {
      const computedStyle = getComputedStyle(this.$refs.$formTable);
      const getVal = name => computedStyle.getPropertyValue(name);
      return {
        veryCompetitive: getVal('--very-competitive-icon-color'),
        uncompetitive: getVal('--uncompetitive-icon-color'),
        competitive: getVal('--competitive-icon-color'),
        withoutInformation: getVal('--without-competitive-information-color'),
      };
    },
    veryCompetitive() {
      return {
        storeKey: 'veryCompetitive',
        text: 'Muito competitivo',
        icon: {
          name: 'usd-square',
          type: 'fas',
          color: this.getCompetitiveColors.veryCompetitive,
        },
      };
    },
    uncompetitive() {
      return {
        storeKey: 'uncompetitive',
        text: 'Pouco competitivo',
        icon: {
          name: 'usd-square',
          type: 'fas',
          color: this.getCompetitiveColors.uncompetitive,
        },
      };
    },
    competitive() {
      return {
        storeKey: 'competitive',
        text: 'Competitivo',
        icon: {
          name: 'usd-square',
          type: 'fas',
          color: this.getCompetitiveColors.competitive,
        },
      };
    },
    withoutCompetitiveInformation() {
      return {
        storeKey: 'withoutCompetitiveInformation',
        text: 'Sem informações',
        color: this.getCompetitiveColors.withoutInformation,
        weight: 'normal',
      };
    },
  },
};
