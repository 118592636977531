<template>
  <z-input-field :label="label">
    <z-autocomplete
      :internal-search="false"
      :multiple="true"
      :options="shiftList"
      :value="shiftsSelected"
      @input="shiftInputHandle($event)"
      @remove="shiftRemoveHandle($event)"
      @select="shiftSelectHandle($event)"
      hide-selected
      label-by="text"
    />

    <input
      :value="JSON.stringify(shiftsSelected)"
      type="hidden"
      v-bind="$attrs"
    >
  </z-input-field>
</template>

<script>
import { searchShift } from '@shared/services/autocompletes/shift';

export default {
  props: {
    label: {
      type: String,
      default: 'Turno(s)',
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      shiftList: [],
    };
  },
  computed: {
    shiftsSelected() {
      return this.value;
    },
  },
  methods: {
    shiftInputHandle($event) {
      this.requestSearchShift($event);
    },
    shiftSelectHandle($event) {
      if (!$event) return;

      this.$emit('select', $event);
    },
    shiftRemoveHandle($event) {
      this.$emit('remove', $event);
    },
    requestSearchShift(term) {
      searchShift(term).then(({ results }) => {
        this.shiftList = results;
      });
    },
  },
  mounted() {
    this.requestSearchShift('');
  },
};
</script>
