import UPAHelperModalWrapper from '@shared/components/upa-helper-modal/upa-helper-modal-wrapper.vue';

const UPAHelperModalApi = (Render, globalOptions) => {
  const { InstanceLocal, tagId } = globalOptions;

  return {
    async fire(allOptions = {}) {
      await this.create();

      InstanceLocal.$upaHelperModalInstance.setupModal(allOptions);
      return InstanceLocal.$upaHelperModalInstance.fire();
    },
    async show(allOptions = {}) {
      await this.create();

      InstanceLocal.$upaHelperModalInstance.setupModal(allOptions);
      return InstanceLocal.$upaHelperModalInstance.show();
    },
    async close(allOptions = {}) {
      await this.create();

      InstanceLocal.$upaHelperModalInstance.setupModal(allOptions);
      return InstanceLocal.$upaHelperModalInstance.close();
    },
    async nextAction(allOptions) {
      await this.create();

      if (InstanceLocal.$upaHelperModalInstance && allOptions) InstanceLocal.$upaHelperModalInstance.setupModal(allOptions);

      return InstanceLocal.$upaHelperModalInstance.nextAction();
    },
    async create() {
      if (InstanceLocal.$upaHelperModalInstance) return false;
      this.setupContainer(tagId);

      const propsData = Render.observable({ ...globalOptions });

      const store = globalOptions.store || null;

      const ComponentClass = Render.extend(UPAHelperModalWrapper);
      InstanceLocal.$upaHelperModalInstance = new ComponentClass({
        el: `#${tagId}`,
        propsData,
        store,
      });
      return true;
    },
    async setupContainer(elementId) {
      this.parent = document.getElementById(elementId);

      if (!this.parent) {
        this.parent = document.createElement('div');
        this.parent.id = elementId;
      }

      await document.body.appendChild(this.parent);
    },

  };
};

export { UPAHelperModalApi };
