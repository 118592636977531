module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="s-popover js-popover" role="popover">\n  ' +
((__t = ( template )) == null ? '' : __t) +
'\n</div>\n';

}
return __p
}