<template>
  <header class="oc-offer-marketplace-form-table-header">
    <z-icon
      :icon="['far','bolt']"
      class="oc-offer-marketplace-form-table-header__item"
      size="medium"
    />

    <z-text
      class="oc-offer-marketplace-form-table-header__item"
      tag="span"
    >
      <strong>Desconto Inteligente</strong> aplicado às suas ofertas.
    </z-text>

    <offer-marketplace-competitiveness-popover
      class="oc-offer-marketplace-form-table-header__item"
    />
  </header>
</template>

<script>
import OfferMarketplaceCompetitivenessPopover from
  '@stock/modules/offer-creation/offer-marketplace-popover';

export default {
  components: {
    'offer-marketplace-competitiveness-popover': OfferMarketplaceCompetitivenessPopover,
  },
};
</script>

<style lang="scss" scoped>
.oc-offer-marketplace-form-table-header {
  align-items: center;
  background-color: var(--color-gray-000);
  border-collapse: collapse;
  border-top: var(--size-border-width-medium) solid var(--color-gray-050);
  display: flex;
  margin-bottom: 0;
  padding: var(--space-medium) var(--space-large);
}

.oc-offer-marketplace-form-table-header__item {
  margin-right: var(--space-small);

  &:last-child {
    margin-right: 0;
  }
}
</style>
