import AmplitudeService from '@shared/utils/amplitude-service';

const amplitude = AmplitudeService.getInstance();

const _logEvent = (eventName, options) => {
  const eventFullName = `[one_click] ${eventName}`;
  amplitude.logEvent(eventFullName, options);
};

const viewedRestockPage = ({ offerId, errorFlag }) => {
  _logEvent('Viewed restock page', { offerId, errorFlag });
};

export const oneClickTracking = {
  viewedRestockPage,
};
