export default class LoadingAnimation {
  constructor(config = {}) {
    this.target = config.target || '.js-page-content';
    this.createLoadingAnimation();
  }

  createLoadingAnimation() {
    const target = this.getTargetContainer();
    this.container = document.createElement('div');
    const animation = document.createElement('div');
    this.container.classList.add('cog');
    animation.classList.add('loading-image');
    this.container.appendChild(animation);
    target.appendChild(this.container);
  }

  getTargetContainer() {
    let target = document.querySelector(this.target);
    if (!target) {
      target = document.createElement('div');
      target.classList.add('js-page-content');
      document.body.appendChild(target);
    }
    return target;
  }

  show() {
    // #guilda-de-frontend Shame code, it could be better if use "hidden" class to hide and show,
    // but ".cog" is used in entire project and has "display: none"
    this.container.style.display = 'block';
  }

  hide() {
    // #guilda-de-frontend Shame code, it could be better if use "hidden" class to hide and show,
    // but ".cog" is used in entire project and has "display: none"
    this.container.style.display = 'none';
  }
}
