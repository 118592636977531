<template>
  <section>
    <z-title
      size="extra-small"
      class="create-offer-filter-painel-institution__title"
    >
      Dados da Universidade
    </z-title>

    <GridContainer class="create-offer-filter-painel-institution__row">
      <GridItem :column="3">
        <EducationGroupAutocomplete
          @select="$emit('select', { event: $event, name: 'educationGroupsSelected' })"
          @remove="$emit('remove', { event: $event, name: 'educationGroupsSelected' })"
          :value="educationGroupsSelected"
        />
      </GridItem>

      <GridItem :column="3">
        <UniversityAutocomplete
          @select="$emit('select', { event: $event, name: 'universitiesSelected' })"
          @remove="$emit('remove', { event: $event, name: 'universitiesSelected' })"
          :value="universitiesSelected"
        />
      </GridItem>

      <GridItem :column="6">
        <CampusAutocomplete
          :university-ids="universityIds"
          :value="campusesSelected"
          @select="$emit('select', { event: $event, name: 'campusesSelected' })"
          @remove="$emit('remove', { event: $event, name: 'campusesSelected' })"
        />
      </GridItem>
    </GridContainer>
  </section>
</template>

<script>
import { CampusAutocomplete } from '@shared/components/autocompletes/campus-autocomplete';
import { EducationGroupAutocomplete } from '@shared/components/autocompletes/education-group-autocomplete';
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { UniversityAutocomplete } from '@shared/components/autocompletes/university-autocomplete';

export default {
  components: {
    CampusAutocomplete,
    EducationGroupAutocomplete,
    GridContainer,
    GridItem,
    UniversityAutocomplete,
  },
  props: {
    campusesSelected: {
      type: Array,
      required: true,
    },
    universitiesSelected: {
      type: Array,
      required: true,
    },
    educationGroupsSelected: {
      type: Array,
      required: true,
    },
    universityIds: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
.create-offer-filter-painel-institution__title {
  margin-bottom: var(--space-medium);
}

.create-offer-filter-painel-institution__row {
  --grid-padding: 0;
}
</style>
