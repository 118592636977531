module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 showRegisterAction = function(followUp) { ;
__p += '\n  ';
 return !followUp.digital_admission && followUp.step !== "approved" && followUp.group_priority <= 2; ;
__p += '\n';
 }; ;
__p += '\n\n';
 showApplicationActions = function(followUp) { ;
__p += '\n  ';
 return !followUp.digital_admission && (followUp.step === "initiated" || followUp.step === "failed"); ;
__p += '\n';
 }; ;
__p += '\n\n';
 showEnrollmentAction = function(followUp) { ;
__p += '\n  ';
 return followUp.step !== "enrolled" && (
    !followUp.digital_admission ||
    followUp.step !== "pending_docs" &&
    followUp.group_priority >= 3) && !followUp.pending_enrollment_fee; ;
__p += '\n';
 }; ;
__p += '\n\n';
 showEventApproval = function(followUp) { ;
__p += '\n  ';
 return followUp.digital_admission &&
      (followUp.step == "partially_submitted_docs" ||
      followUp.step == "submitted_docs" ||
      followUp.step == "rejected_docs"); ;
__p += '\n';
 }; ;
__p += '\n\n';
 followUpInProcess = function(followUp) { ;
__p += '\n  ';
 return followUp.step !== "dropped_out" &&
      followUp.step !== "drop_out_confirmed" &&
      followUp.step !== "dropping_out" &&
      followUp.step !== "rejected_enrollment"; ;
__p += '\n';
 }; ;
__p += '\n\n';
 followUpSubmittedEventIsApproved = function(followUp) { ;
__p += '\n  ';
 return followUp.last_submitted_event_is_approved; ;
__p += '\n';
 }; ;
__p += '\n\n';
 followUpAwaitingEnrollment = function(followUp) { ;
__p += '\n  ';
 return followUp.step == "awaiting_enrollment"; ;
__p += '\n';
 }; ;
__p += '\n\n';
 followUps.forEach(function(followUp) { ;
__p += '\n  <tr class="pgc-table__row">\n    <td class="pgc-table__cell pgc-table__cell--nowrap actions">\n      <div class="dropdown js-dropdown">\n        <button\n          class="btn btn-actions dropdown-actions__button dropdown-actions__button--right dropdown-toggle js-dropdown-actions__button"\n          type="button"\n          id="dropdownActions' +
((__t = ( followUp.id )) == null ? '' : __t) +
'"\n          data-toggle="dropdown">\n          Ações\n          <i class="material-icons md-14 w-10 va-middle">keyboard_arrow_down</i>\n        </button>\n        <ul class="dropdown-menu dropdown-actions__menu js-dropdown-menu" aria-labelledby="dropdownActions' +
((__t = ( followUp.id )) == null ? '' : __t) +
'">\n          <li>\n            <a href="' +
((__t = ( followUp.link )) == null ? '' : __t) +
'" class="icon-menu icon-view dropdown-actions__link--padding" data-follow-up-id="' +
((__t = ( followUp.id )) == null ? '' : __t) +
'">\n              Visualizar Perfil\n            </a>\n          </li>\n\n          ';
 if (canUpdate && followUpInProcess(followUp) && !followUp.refunding) { ;
__p += '\n            <li>\n              <a href="/busca-pre-matriculas/' +
((__t = ( followUp.id )) == null ? '' : __t) +
'/download" target="_blank" class="icon-menu icon-download dropdown-actions__link--padding">\n                Baixar Comprovante\n              </a>\n            </li>\n\n            ';
 if (showApplicationActions(followUp)) { ;
__p += '\n              ';
 if (showRegisterAction(followUp)) { ;
__p += '\n                <li>\n                  <a href="#" class="js-follow-up-update-register icon-menu icon-calendar dropdown-actions__link--padding"\n                    data-follow-up-id="' +
((__t = ( followUp.id )) == null ? '' : __t) +
'">\n                    Vestibular Agendado\n                  </a>\n                </li>\n              ';
 } ;
__p += '\n\n              <li>\n                <a href="#" class="js-follow-up-update-approve icon-menu icon-validate dropdown-actions__link--padding" data-follow-up-id="' +
((__t = ( followUp.id )) == null ? '' : __t) +
'">\n                  Informar Aprovação\n                </a>\n              </li>\n\n              <li>\n                <a href="#" class="js-follow-up-update-disapprove icon-menu icon-close dropdown-actions__link--padding" data-follow-up-id="' +
((__t = ( followUp.id )) == null ? '' : __t) +
'">\n                  Informar Reprovação\n                </a>\n              </li>\n            ';
 } ;
__p += '\n\n            ';
 if (showEnrollmentAction(followUp)) { ;
__p += '\n              <li>\n              ';
 if (followUpAwaitingEnrollment(followUp)) { ;
__p += '\n                <a href="#" class="js-follow-up-update-enrollment icon-menu icon-star dropdown-actions__link--padding" data-follow-up-id="' +
((__t = ( followUp.id )) == null ? '' : __t) +
'">\n                  Informar Matrícula\n                </a>\n              ';
 } else if (followUp.digital_admission) { ;
__p += '\n                <a href="#" class="icon-menu icon-star dropdown-actions__link--padding"\n                  data-approve-docs-wizard="true"\n                  data-follow-up-id="' +
((__t = ( followUp.id )) == null ? '' : __t) +
'"\n                  data-follow-up-step="' +
((__t = ( followUp.step )) == null ? '' : __t) +
'"\n                  data-university-name="' +
((__t = ( followUp.university_name )) == null ? '' : __t) +
'"\n                  data-university-id="' +
((__t = ( followUp.university_id )) == null ? '' : __t) +
'">\n                  Informar Matrícula\n                </a>\n              ';
 } else { ;
__p += '\n                <a href="#" class="icon-menu icon-star dropdown-actions__link--padding"\n                  data-enrollment-wizard="true"\n                  data-follow-up-id="' +
((__t = ( followUp.id )) == null ? '' : __t) +
'"\n                  data-university-name="' +
((__t = ( followUp.university_name )) == null ? '' : __t) +
'"\n                  data-university-id="' +
((__t = ( followUp.university_id )) == null ? '' : __t) +
'">\n                  Informar Matrícula\n                </a>\n              ';
 } ;
__p += '\n            ';
 } ;
__p += '\n            </li>\n          ';
 } ;
__p += '\n        </ul>\n      </div>\n    </td>\n    <td class="pgc-table__cell pgc-table__cell--nowrap">\n      ' +
((__t = ( followUp.formatted_status )) == null ? '' : __t) +
'\n      ';
 if (showEventApproval(followUp)) { ;
__p += '\n        ';
 if (followUpSubmittedEventIsApproved(followUp) == null) { ;
__p += '\n        ';
 } else if (followUpSubmittedEventIsApproved(followUp)) { ;
__p += '\n          <svg xmlns="http://www.w3.org/2000/svg" class="svg--green" width="15" height="12" viewBox="0 0 15 12">\n            <path fill-rule="evenodd" d="M5 12L0 7l2-2 3 3 8-8 2 2" />\n          </svg>\n        ';
 } else { ;
__p += '\n          <svg xmlns="http://www.w3.org/2000/svg" class="svg--red" width="15" height="12" viewBox="0 0 15 12">\n            <path fill-rule="evenodd" d="M5 12L0 7l2-2 3 3 8-8 2 2" />\n          </svg>\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n    </td>\n    <td class="pgc-table__cell pgc-table__cell--nowrap"><a href="' +
((__t = ( followUp.link )) == null ? '' : __t) +
'">' +
((__t = ( followUp.user_name )) == null ? '' : __t) +
'</a></td>\n    <td class="pgc-table__cell pgc-table__cell--nowrap">' +
((__t = ( followUp.cpf )) == null ? '' : __t) +
'</td>\n    <td class="pgc-table__cell pgc-table__cell--nowrap">' +
((__t = ( followUp.course_name )) == null ? '' : __t) +
'</td>\n    <td class="pgc-table__cell pgc-table__cell--nowrap">' +
((__t = ( followUp.discount_percentage )) == null ? '' : __t) +
'</td>\n    <td class="pgc-table__cell pgc-table__cell--nowrap">' +
((__t = ( followUp.enrollment_semester )) == null ? '' : __t) +
'</td>\n    <td class="pgc-table__cell pgc-table__cell--nowrap">' +
((__t = ( followUp.course_shift )) == null ? '' : __t) +
'</td>\n    <td class="pgc-table__cell pgc-table__cell--nowrap">' +
((__t = ( followUp.course_kind )) == null ? '' : __t) +
'</td>\n    <td class="pgc-table__cell pgc-table__cell--nowrap">' +
((__t = ( followUp.course_level )) == null ? '' : __t) +
'</td>\n    <td class="pgc-table__cell pgc-table__cell--nowrap">' +
((__t = ( followUp.origin )) == null ? '' : __t) +
'</td>\n  </tr>\n';
 }) ;
__p += '\n';

}
return __p
}