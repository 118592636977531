import $ from 'jquery';
import { debounce } from 'lodash';
import '@shared/utils/select2';
import '@vendor/readmore';
import 'confirm-bootstrap';
import listingTemplate from '@university-panel/legacy/components/templates/college_reviews/reviews_list.html.ejs';
import editReplyCardTemplate from '@university-panel/legacy/components/templates/college_reviews/edit_reply_card.html.ejs';
import repliedCardTemplate from '@university-panel/legacy/components/templates/college_reviews/replied_card.html.ejs';
import paginationInfoTemplate from '@shared/legacy/components/templates/list_pagination.html.ejs';

class CollegeReviews {
  constructor(page) {
    this.page = page;
    this.loading = false;
    this.getUniversity();
    this.initCampusSelect();
    this.initCanonicalCourseSelect();
    this.initUniversitySelect();
    this.initPagination();
    this.initAjax();
    this.initForm();
    this.updateImages();
    this.initReplyForm();
    this.initReadMore();
    this.initDeleteReplyButton();
    this.initTooltip();
  }

  initReplyForm() {
    const csrf = document.querySelector('meta[name=csrf-token]').content;
    const that = this;

    $('.student-answers').on('click', '.edit-reply', function editReply(e) {
      e.preventDefault();
      $(this).closest('.university-reply').addClass('edit-reply-state');
      const originalText = $(this).closest('.university-reply').find('.edit-reply-card .original-text').val();
      $(this).closest('.university-reply').find('.edit-reply-card .reply-text').val(originalText);
      $(this).closest('.university-reply').find('.edit-reply-card .error-message').addClass('hidden');
    });

    $('.student-answers').on('click', '.btn-save-reply', function saveReply(e) {
      e.preventDefault();
      const form = $(this).closest('form');
      const universityReplyTag = form.closest('.university-reply');

      $.ajax({
        url: form.attr('action'),
        type: 'patch',
        data: form.serialize(),
        headers: {
          'X-CSRF-TOKEN': csrf,
        },
        dataType: 'json',
        success(data) {
          universityReplyTag.removeClass('edit-reply-state');
          universityReplyTag.addClass('replied-state');
          const { reply } = data;
          universityReplyTag.find('.edit-reply-card').html(editReplyCardTemplate({ reply }));
          universityReplyTag.find('.replied-card').html(repliedCardTemplate({ reply }));
          that.initReadMore();
          that.initDeleteReplyButton();
        },
        error() {
          form.find('.error-message').removeClass('hidden');
        },
      });
    });

    $('.student-answers').on('click', '.btn-new-reply', function newReply(e) {
      e.preventDefault();
      const form = $(this).closest('form');
      const universityReplyTag = form.closest('.university-reply');
      $.ajax({
        url: form.attr('action'),
        type: 'post',
        data: form.serialize(),
        headers: {
          'X-CSRF-TOKEN': csrf,
        },
        dataType: 'json',
        success(data) {
          universityReplyTag.removeClass('new-reply-state');
          universityReplyTag.addClass('replied-state');
          const { reply } = data;
          universityReplyTag.find('.edit-reply-card').html(editReplyCardTemplate({ reply }));
          universityReplyTag.find('.replied-card').html(repliedCardTemplate({ reply }));
          that.initReadMore();
          that.initDeleteReplyButton();
        },
        error() {
          form.find('.error-message').removeClass('hidden');
        },
      });
    });

    $('.student-answers').on('click', '.reply-button', function reply(e) {
      e.preventDefault();
      $(this).closest('.university-reply').addClass('new-reply-state');
      $(this).closest('.university-reply').find('.new-reply-card .reply-text').val('');
      $(this).closest('.university-reply').find('.new-reply-card .error-message').addClass('hidden');
      $(this).closest('.university-reply').find('.new-reply-card button[type=submit]').attr('disabled', true);
    });

    $('.student-answers').on('click', '.cancel-button', function cancel() {
      $(this).closest('.university-reply').removeClass('new-reply-state edit-reply-state');
      $(this).closest('.university-reply').find('.new-reply-card .reply-text').val('');
    });

    $('.student-answers').on('keyup', '.reply-text', function replyText() {
      const submitBtn = $(this).closest('form').find('button[type=submit]');
      if ($(this).val().trim() === '') {
        submitBtn.attr('disabled', true);
      } else {
        submitBtn.attr('disabled', false);
      }
    });
  }

  deleteReply(el) {
    const submitButton = el;
    const replyId = submitButton.attr('data-id');
    const csrf = document.querySelector('meta[name=csrf-token]').content;

    $.ajax({
      url: `/avaliacao-dos-alunos/replies/${replyId}`,
      type: 'delete',
      headers: {
        'X-CSRF-TOKEN': csrf,
      },
      dataType: 'json',
      success() {
        submitButton.closest('.university-reply').removeClass('replied-state');
      },
    });
  }

  initDeleteReplyButton() {
    $('.delete-reply').off();
    $('.delete-reply').confirmModal({
      confirmTitle: 'Atenção!',
      confirmMessage: 'Deseja excluir a resposta?',
      confirmOk: 'Excluir',
      confirmCancel: 'Cancelar',
      confirmStyle: 'primary',
      confirmCallback: this.deleteReply,
      confirmDismiss: true,
      confirmAutoOpen: false,
      classWrapper: this,
    });
  }

  initReadMore() {
    const numLines = 3;

    $('.replied-text .retr-reply-js').readmore({
      moreLink: '<a href="#">VER MAIS</a>',
      lessLink: '<a href="#">VER MENOS</a>',
      collapsedHeight: numLines * (parseInt($('.retr-reply-js').css('line-height'), 10) || 24),
    });
  }

  getUniversity() {
    this.university_id = [$('#reviews_university_id').val()];
  }

  initTooltip() {
    $('[data-toggle="tooltip"]').tooltip({
      placement: 'right',
      trigger: 'hover',
    });
  }

  initCampusSelect() {
    const $select = this.$('.campus-select');
    $select.select2({
      theme: 'bootstrap',
      placeholder: 'Digite e escolha o campus',
      allowClear: true,
      ajax: {
        url: '/util/autocomplete/campuses',
        delay: 250,
        data: params => ({
          term: params.term,
          university_ids: this.university_id,
        }),
      },
      minimumInputLength: 3,
      language: 'pt-BR',
    }).on('select2:select', (e) => {
      this.selectedCampuses = $(e.target).val() || [];
      $('#filter-form').trigger('submit', { keepPagination: false });
    }).on('select2:unselect', () => {
      this.selectedCampuses = [];
      $select.val('');
      $('#filter-form').trigger('submit', { keepPagination: false });
    });

    this.selectedCampuses = $select.val() || [];
    this.campusMapping = $select.data('campusMapping');
  }

  initCanonicalCourseSelect() {
    const $select = this.$('.course-select');
    $select.select2({
      theme: 'bootstrap',
      placeholder: 'Digite e escolha o curso',
      allowClear: true,
      ajax: {
        url: '/util/autocomplete/canonical_courses',
        delay: 250,
        data: params => ({
          term: params.term,
          university_ids: this.university_id,
        }),
      },
      minimumInputLength: 3,
      language: 'pt-BR',
    }).on('select2:select', (e) => {
      this.selectedCourses = $(e.target).val() || [];
      $('#filter-form').trigger('submit', { keepPagination: false });
    }).on('select2:unselect', () => {
      this.selectedCourses = [];
      $select.val('');
      $('#filter-form').trigger('submit', { keepPagination: false });
    });

    this.selectedCourses = $select.val() || [];
    this.coursesMapping = $select.data('coursesMapping');
  }

  initUniversitySelect() {
    const equivalentUniversityDiv = $('.university-select-wrapper div.select');
    equivalentUniversityDiv.on('click', function openSelect2() {
      $(this).parent().find('select').select2('open');
    });
    equivalentUniversityDiv.removeClass('hidden');
    const $select = this.$('.university-select');
    $select.select2({
      minimumInputLength: -1,
    }).on('change', () => {
      $('#university-form').submit();
    });
  }

  initForm() {
    const form = this.$('#filter-form');

    form.submit((event, { keepPagination } = {}) => {
      this.$('.cog').show();

      if (!keepPagination) {
        this.$('#reviews_page', form).val(1);
      }
    });

    form.on('ajax:success', (_event, response) => {
      const studentAnswers = response.entries;
      const pagination = response;
      this.$('.student-answers').html(listingTemplate({ studentAnswers }));
      this.$('.pagination-info .dynamic').html(paginationInfoTemplate({ pagination }));
      this.$('.pagination .current-page').html(pagination.page_number);
      this.paginationControl(pagination.page_number, pagination.total_pages);
      this.updateImages();
      this.loading = false;
      this.$('.cog').hide();
      $('html, body').animate({ scrollTop: 60 }, 'slow');
      this.initReadMore();
      this.initDeleteReplyButton();
    });

    form.on('reset', debounce(() => form.submit()));
  }

  updateImages() {
    $('.student-profile-image img').each(function showFacebookImages() {
      const uid = $(this).attr('data-uid');
      if (uid) {
        const url = `https://graph.facebook.com/v2.8/${uid}/picture?height=50&width=50`;
        const imageTag = $(this);
        $.get(url).done(() => {
          imageTag.attr('src', url);
        });
      }
    });
  }

  initAjax() {
    $('input').on('change', this, () => {
      if (this.loading === false) {
        this.loading = true;
        $('#filter-form').trigger('submit', { keepPagination: false });
      }
    });
  }

  initPagination() {
    const pagination = this.$('.pagination');
    const form = this.$('#filter-form');
    const pageInput = this.$('#reviews_page', form);

    function submitPage(sum) {
      const newPage = parseInt(pageInput.val(), 10) + sum;
      pageInput.val(newPage);

      form.trigger('submit', { keepPagination: true });
    }

    pagination.on('click', '.next:not(.disabled)', () => {
      if (this.loading === false) {
        this.loading = true;
        submitPage(1);
      }
    });

    pagination.on('click', '.prev:not(.disabled)', () => {
      if (this.loading === false) {
        this.loading = true;
        submitPage(-1);
      }
    });
  }

  paginationControl(currentPage, totalPages) {
    const pagination = this.$('.pagination');

    function disableButton(query) {
      const button = $(query, pagination);
      button.addClass('disabled');
    }

    function enabledButton(query) {
      const button = $(query, pagination);
      button.removeClass('disabled');
    }

    if (currentPage === 1) {
      disableButton('.prev');

      if (totalPages > 1) {
        enabledButton('.next');
      } else {
        disableButton('.next');
      }
    } else if (currentPage >= totalPages) {
      disableButton('.next');
      enabledButton('.prev');
    } else {
      enabledButton('.next, .prev');
    }
  }

  $(selector) {
    return $(selector, this.page);
  }
}

export default CollegeReviews;
