import { BankBusiness, BillingConfigBusiness } from '@quero-turbo/business';

/**
 * Parse data request of Billing config
 *
 * @param {Object} params
 *
 * @typedef Success
 * @property {object} parsedData
 */
async function parseRequestData({
  businessRuleGroupId,
  bankAccountParams,
  splitRules,
  contractCreationRule,
  contractDischargeRules,
  paymentMethodRules,
  scholarshipLosingRule,
  dueDayRule,
  subsidy,
  admissionPaymentRule,
}) {
  const data = {
    businessRuleGroupId,
    bankAccountParams,
    splitRules,
    contractCreationRule,
    contractDischargeRules,
    paymentMethodRules,
    scholarshipLosingRule,
    dueDayRule,
    subsidy,
    admissionPaymentRule,
  };
  const payload = _serializeParams(data);
  const params = {
    payload,
    configType: 'queropago',
    businessRuleGroupId,
  };
  return params;
}

function _isDayAcceptable(element) {
  return element > 0 && element <= 31;
}

const _serializeParams = (params) => {
  const payload = _dupParams(params);

  payload.bankAccountParams = {
    ...payload.bankAccountParams,
    bankAccountType: BankBusiness.getBankAccountTypeKeyByText(payload.bankAccountParams.bankAccountType),
    bankCode: payload.bankAccountParams.bankSelected.code,
    transferDays: payload.bankAccountParams.transferDays.replace(/\s/g, '').split(',').map(Number),
  };

  payload.admissionPaymentRule = {
    ...payload.admissionPaymentRule,
    amountType: BillingConfigBusiness
      .getAmountTypeKeyByText(payload.admissionPaymentRule.amountType),
  };

  payload.contractCreationRule = {
    ...payload.contractCreationRule,
    duration: Number(payload.contractCreationRule.duration),
    startRule: BillingConfigBusiness
      .getStartRuleKeyByText(payload.contractCreationRule.startRule),
  };

  payload.contractDischargeRules = {
    ...payload.contractDischargeRules,
    methods: payload.contractDischargeRules.methods.map(paymentMethod => BillingConfigBusiness
      .getPaymentMethodKeyByText(paymentMethod)),
  };

  payload.paymentMethodRules = {
    ...payload.paymentMethodRules,
    methods: payload.paymentMethodRules.methods.map(paymentMethod => BillingConfigBusiness
      .getPaymentMethodKeyByText(paymentMethod)),
  };

  payload.scholarshipLosingRule = {
    ...payload.scholarshipLosingRule,
    lossDelayType: BillingConfigBusiness.getLossDelayTypeKeyByText(payload.scholarshipLosingRule.lossDelayType),
    rule: BillingConfigBusiness.getRuleToLoseScholarshipKeyByText(payload.scholarshipLosingRule.rule),
    gracePeriod: 2,
  };

  payload.dueDayRule = {
    ...payload.dueDayRule,
    dueDays: payload.dueDayRule.dueDays.replace(/\s/g, '').split(',').map(Number),
  };

  if (!payload.bankAccountParams.transferDays.every(_isDayAcceptable)) throw new Error('Dia(s) de transferência incorreto(s)!');
  if (!payload.dueDayRule.dueDays.every(_isDayAcceptable)) throw new Error('Dia(s) de vencimento incorreto(s)!');

  if (payload.admissionPaymentRule.amountType === null) delete payload.admissionPaymentRule;
  delete payload.bankAccountParams.bankSelected;
  return payload;
};


const _dupParams = (payload) => {
  const payloadWithoutObservers = JSON.parse(JSON.stringify(payload));
  return Object.assign({}, payloadWithoutObservers);
};


export {
  parseRequestData,
};
