import $ from 'jquery';
import FollowUpUpdateActions from '@admission/legacy/modules/follow-up-update-actions';
import { ScholarshipList } from '@admission/legacy/modules/scholarship-list';
import { setupEnrollmentWizard } from '@admission/legacy/modules/enrollment-wizard';

export class ScholarshipSearchContainer {
  constructor(page) {
    page.each((_index, container) => {
      const $container = $(container);
      new FollowUpUpdateActions($container);
      new ScholarshipList($container);
      setupEnrollmentWizard();
    });
  }
}
