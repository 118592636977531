import amplitude from 'amplitude-js/amplitude';
import { RollbarService } from '@shared/utils/rollbar-service.js';

class AmplitudeService {
  static getInstance() {
    if (!AmplitudeService.instance) {
      AmplitudeService.instance = new AmplitudeService();
    }
    return AmplitudeService.instance;
  }

  constructor() {
    if (AmplitudeService.instance) throw new Error();
    this.amplitudeInstance = amplitude.getInstance();
  }

  logEvent(eventName, eventProperties = {}, callback = null) {
    const { amplitudeInstance } = this;
    if (!eventName) {
      RollbarService.getInstance().notifyError('Amplitude event must have a name');
      return undefined;
    }

    if (UPA.isDev) {
      /* eslint no-console: ["error", { allow: ["info"] }] */
      const eventText = `Event "${eventName}" will be tracked by Amplitude with the following params:`;
      console.info(eventText, eventProperties);
      if (callback) { setTimeout(callback, 100); }
    }

    if (UPA.isProd || UPA.isHomolog) {
      amplitudeInstance.init(UPA.amplitudeKey, null, null, () => {
        amplitudeInstance.logEvent(
          eventName,
          eventProperties,
          callback,
        );
        return true;
      });
    }

    return true;
  }
}

export default AmplitudeService;
