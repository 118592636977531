<template>
  <z-card
    class="payment-model-card"
    :class="{
      [`payment-model-card--selected`]: selected
    }"
    :shadow="isShadow"
    @mouseover.native="hover = true"
    @mouseleave.native="hover = false"
    @click.native="$emit('click')"
  >
    <z-icon
      :icon="icon"
      size="extra-large"
      class="payment-model-card__icon"
    />

    <z-title
      size="extra-small"
      class="payment-model-card__title"
    >
      {{ title }}
    </z-title>

    <z-text
      size="small"
      color="minor"
      class="payment-model-card__description"
    >
      {{ description }}
    </z-text>
  </z-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: Array,
      default() {
        return ['fas', 'check'];
      },
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { hover: false };
  },
  computed: {
    isShadow() {
      if (this.selected) return 'higher';
      return this.hover ? 'high' : null;
    },
  },
};
</script>

<style lang="scss">
.payment-model-card {
  cursor: pointer;

  &:hover {
    border: var(--size-border-width-small) var(--color-blue-200) solid;
  }

  &--selected {
    border: var(--size-border-width-small) var(--color-blue-500) solid;
  }
}

.payment-model-card__icon {
  margin-bottom: var(--space-large);
}

.payment-model-card__title {
  margin-bottom: var(--space-small);
}

.payment-model-card__description {
  margin-bottom: 0;
}
</style>
