<template>
  <div
    class="power-bi-dashboard"
  >
    <div
      ref="powerBIDashboard"
      class="power-bi-dashboard__iframe"
    />
  </div>
</template>

<script>
import { QueroAlunosServices } from '@university-panel/services';

export default {
  props: {
    baseURL: {
      type: String,
      required: true,
    },
    reportId: {
      type: String,
      required: true,
    },
    universityId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async requestReport() {
      const accessToken = await QueroAlunosServices.V1.Microsoft.getToken();

      const params = {
        embedContainer: this.$refs.powerBIDashboard,
        embedReportId: this.reportId,
        embedUrl: this.baseURL,
        universityId: this.universityId,
        accessToken,
        resolveOnLoad: true,
      };

      QueroAlunosServices.V1.Microsoft.embedPowerBIReport(params).then(() => {
        this.$emit('load');
      });
    },
  },
  mounted() {
    this.requestReport();
  },
};
</script>

<style>
.power-bi-dashboard {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.power-bi-dashboard__iframe, .power-bi-dashboard__iframe > iframe {
  border: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
</style>
