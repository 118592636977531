import $ from 'jquery';
import multiSelect from '@university-panel/legacy/components/multi-select';

const ROLES_PERMISSIONS = {
  manager: [
    'upa_basic_operations',
    'upa_education_group_reader',
    'upa_dashboard_reader',
    'upa_admission_reader',
    'upa_admission_updater',
    'upa_profile_reader',
    'upa_profile_updater',
    'upa_college_review_reader',
    'upa_college_review_updater',
    'upa_university_content_reader',
    'upa_university_content_editor',
    'upa_offers_portfolio_reader',
    'upa_offers_restock_manager',
    'upa_offers_portfolio_manager',
    'upa_course_information_manager',
  ],
  secretary: [
    'upa_basic_operations',
    'upa_dashboard_reader',
    'upa_admission_reader',
    'upa_admission_updater',
  ],
};

export default ($element) => {
  multiSelect($element, {
    selectableHeader: '<label class="control-label">Lista de permissões</label>',
    selectionHeader: '<label class="control-label">Permissões selecionadas</label>',
  });

  $('.js-select-role').on('click', (evt) => {
    evt.preventDefault();

    const role = $(evt.target).data('role');

    $element
      .multiSelect('deselect_all')
      .multiSelect('select', ROLES_PERMISSIONS[role]);
  });
};
