import BaseModal from '@shared/legacy/components/modals/base-modal';
import { scope } from '@shared/utils/jquery';
import template from './template.ejs';

const $ = scope('.js-stepper-modal-content');

export class StepperModal extends BaseModal {
  constructor(stepper) {
    super({}, template);
    this.stepper = stepper;
    this._setup();
  }

  onShow() {
    this._renderStep();
  }

  _setup() {
    this.stepper.on('step.changed', () => {
      this._renderStep();
    });
    this.stepper.on('finished', () => {
      this.destroy();
    });
  }

  _renderStep() {
    $('.js-stepper-modal-content').html(this.stepper.renderCurrentStep());
    this.stepper.getCurrentStep().afterRender();
  }
}
