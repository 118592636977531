import { setupEnrollmentWizard } from '@admission/legacy/modules/enrollment-wizard';
import BoxSeal from '@admission/legacy/modules/box-seal';
import RejectDocs from '@admission/legacy/modules/reject-docs';
import ScholarshipPanel from '@admission/legacy/modules/scholarship-panel';

/**
 * @typedef Module
 * @type {Object}
 * @property {Object} ClassComponent - optional module builder class
 * @property {string} selector - selector in the DOM that identifies the module
 */

/** @type Module[] */
const setupOptions = [
  {
    selector: '.js-box-seal',
    ClassComponent: BoxSeal,
  },
  {
    selector: '.js-scholarship-panel',
    ClassComponent: ScholarshipPanel,
  },
  {
    selector: '.js-scholarship-docs',
    ClassComponent: RejectDocs,
  },
];

/**
 * initialize all classes of the page
 * @param {jQuery} _page
 * @return {Void}
 */
export function ScholarshipPage(_page) {
  setupOptions.forEach(_setupPage);
  setupEnrollmentWizard();
}

// -- private

/**
 * @param {Module} module
 * @returns {Object} ClassComponent
 * @private
 */
function _setupPage({ selector, ClassComponent }) {
  const $target = $(selector);
  if ($target.length === 0) return null;
  return new ClassComponent($target);
}
