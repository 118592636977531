module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div\n  class="modal fade"\n  tabindex="-1"\n  role="dialog"\n>\n  <div class="batch-stock-action-confirmation modal-dialog">\n    <section class="modal-content">\n      <div class="batch-stock-action-confirmation__body modal-body">\n        <header class="batch-stock-action-confirmation__header">\n          <h2 class="batch-stock-action-confirmation__title gq-title">\n            Atualização de data de expiração das ofertas em lote\n          </h2>\n\n          <p class="gq-text js-batch-stock-action-confirmation__description">\n            Antes de continuar, verifique o resumo das ofertas que você quer atualizar\n          </p>\n          \n          <br>    \n          <input class="form-control js-new-end-date-input"\n            placeholder="Digite a nova data de expiração"\n            type="date"\n          />\n\n          <button\n            type="button"\n            class="batch-stock-action-confirmation__close close"\n            data-dismiss="modal"\n            aria-label="Close"\n          >\n            <i class="far fa-xs fa-times" aria-hidden></i>\n          </button>\n        </header>\n\n        <section>\n          <h3 class="batch-stock-action-confirmation__subtitle gq-title gq-title--micro gq-title--soft">\n            Ofertas filtradas\n          </h3>\n          <ul class="batch-stock-action-confirmation__offers key-value-list">\n            <li class="key-value-list__item">\n              <h4 class="key-value-list__key gq-text gq-text--small">Total</h4>\n              <p class="key-value-list__value gq-text gq-text--large js-batch-changeEndDate-total-offers">' +
((__t = ( totalOffers )) == null ? '' : __t) +
'</p>\n            </li>\n            <li class="key-value-list__item">\n              <h4 class="key-value-list__key gq-text gq-text--small">Bloqueadas*</h4>\n              <p class="key-value-list__value gq-text gq-text--large js-batch-changeEndDate-total-offers-blocked">' +
((__t = ( totalOffersBlocked )) == null ? '' : __t) +
'</p>\n            </li>\n            <li class="key-value-list__item">\n              <h4 class="key-value-list__key gq-text gq-text--small">Liberadas para ação em lote</h4>\n              <p class="key-value-list__value gq-text gq-text--large gq-text--bold js-batch-changeEndDate-total-offers-target">' +
((__t = ( totalOffersTarget )) == null ? '' : __t) +
'</p>\n            </li>\n          </ul>\n        </section>\n\n        <section class="batch-stock-action-confirmation__warning js-changeEndDate-offers-warning hidden">\n          <i class="batch-stock-action-confirmation__warning-icon fal fa-exclamation-circle"></i>\n        </section>\n      </div>\n\n      <footer class="batch-stock-action-confirmation__footer modal-footer">\n        <button\n          type="button"\n          class="batch-stock-action-confirmation__footer-action btn btn-secondary reverse js-cancel-modal"\n          data-dismiss="modal"\n        >\n          Voltar\n        </button>\n        <button\n          type="button"\n          class="js-changeEndDate-confirmation batch-stock-action-confirmation__footer-action btn btn-primary"\n        >\n          Atualizar ofertas\n        </button>\n      </footer>\n    </section>\n  </div>\n</div>\n';

}
return __p
}