const EducationDictionary = {
  universities: 'Universidades',
  campuses: 'Campuses',
  educationGroups: 'Grupos Educacionais',
  canonicalCourses: 'Cursos canonicos',
  courses: 'Cursos',
  kinds: 'Modalidade',
  levels: 'Nível',
  maxPayments: 'Máximo de Pagamentos',
  origins: 'Origens',
  shifts: 'Turnos',
  enrollmentSemesters: 'Semestres',
  cities: 'Cidades',
  states: 'Estados',
  businessRuleGroupId: 'BRG ID',
  enabled: 'Habilitado?',
};

const getEducationDictionaryByKey = key => EducationDictionary[key];

export const EducationBusiness = {
  getEducationDictionaryByKey,
};
