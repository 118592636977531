<template>
  <z-input-field :label="label">
    <z-autocomplete
      :internal-search="false"
      :multiple="true"
      :options="levelList"
      :value="levelsSelected"
      @input="levelInputHandle($event)"
      @remove="levelRemoveHandle($event)"
      @select="levelSelectHandle($event)"
      hide-selected
      label-by="text"
    />

    <input
      :value="JSON.stringify(levelsSelected)"
      type="hidden"
      v-bind="$attrs"
    >
  </z-input-field>
</template>

<script>
import { searchLevel } from '@shared/services/autocompletes/level';

export default {
  props: {
    label: {
      type: String,
      default: 'Nível',
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      levelList: [],
    };
  },
  computed: {
    levelsSelected() {
      return this.value;
    },
  },
  methods: {
    levelInputHandle($event) {
      this.requestSearchLevel($event);
    },
    levelSelectHandle($event) {
      if (!$event) return;

      this.$emit('select', $event);
    },
    levelRemoveHandle($event) {
      this.$emit('remove', $event);
    },
    requestSearchLevel(term) {
      searchLevel(term).then(({ results }) => {
        this.levelList = results;
      });
    },
  },
  mounted() {
    this.requestSearchLevel('');
  },
};
</script>
