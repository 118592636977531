import template from '@stock/legacy/components/modals/offer-list-actions/inline-steps/shared-steps/response/template.ejs';
import Step from '@stock/legacy/components/stepper/step';

const RESPONSE_ATTRIBUTES = {
  true: {
    iconClass: 'fal fa-check-circle fa-4x',
    title: 'Solicitação realizada com sucesso!',
    content: `Em breve sua alteração estará disponível na plataforma e você será avisado por e-mail.
    Você também pode acompanhar suas alterações no Histórico de ações.`,
    submitText: 'Histórico de ações',
  },
  false: {
    iconClass: 'fal fa-exclamation-circle fa-4x',
    title: 'Sua solicitação de alteração não pode ser concluída',
    content: `Ocorreu um erro durante o processamento da alteração solicitada.
    Por favor, tente novamente.`,
    submitText: '',
  },
};

export class ResponseStep extends Step {
  constructor() {
    super({ template });
  }

  render({ success, redirect }) {
    this.setAttribute({ redirect });
    return this.template({ ...RESPONSE_ATTRIBUTES[success] });
  }

  afterRender() {
    const { redirect } = this.getAttributes();
    $('.js-confirm-button').on('click', () => {
      window.location.replace(redirect);
    });
  }
}
