/**
 * THIS COMPONENT MANAGE PAGINATION CONTROLS
 */
import $ from 'jquery';
import controlsTemplate from '@shared/legacy/components/templates/pagination.html.ejs';

export default class Pagination {
  constructor(config) {
    this.controlsContainer = config.controlsContainer;
    this.infoContainer = config.infoContainer;
    this.onChangePage = config.onChangePage;
  }

  configureControls() {
    const controls = $(this.controlsContainer);
    controls.html(controlsTemplate);

    // PREVIEW BUTTON
    const buttonPrev = controls.find('.js-prev');

    if (this.isFirstPage()) {
      buttonPrev.addClass('disabled');
    } else {
      buttonPrev.on('click', () => this.prevPage());
    }

    // NEXT BUTTON
    const buttonNext = $('.js-next');

    if (this.hasMorePages()) {
      buttonNext.on('click', () => this.nextPage());
    } else {
      buttonNext.addClass('disabled');
    }

    // Configures click index on pagination
    controls.find('.js-go-page').each((i, element) => {
      const el = $(element);
      const beginsAt = this.beginsAt();
      el.text(`${beginsAt + i}`);

      if (this.currentPage - beginsAt === i) { // Check if element is an active item
        el.parent().addClass('active');
      } else if (this.totalPages - i < 1) { // Check if element is clickable
        el.addClass('hidden');
      } else { // So attach on click listener
        el.on('click', () => {
          const pageNumber = parseInt(el.text(), 10);

          if (this.currentPage !== pageNumber) { // Prevent table reload
            return this.goToPage(pageNumber);
          }
          return true;
        });
      }
    });
  }

  beginsAt() {
    let beginsAt = 0;

    if (this.currentPage < 3) { // If currentPage < 3 the index will not move
      beginsAt = 1;
    } else if (this.totalPages - this.currentPage < 3) { // If the currentPage is close to end, stop to move
      beginsAt = this.totalPages - 4;

      if (beginsAt < 1) { // Prevent negative page index
        beginsAt = 1;
      }
    } else { // if currentPage have index to move, move
      beginsAt = this.currentPage - 2;
    }

    return beginsAt;
  }

  isFirstPage() {
    return this.currentPage === 1;
  }

  hasMorePages() {
    return this.currentPage < this.totalPages;
  }

  configurePaginationInfo(pagination) {
    const info = $(this.infoContainer);
    info.html(pagination.total_entries);
  }

  setPagination(config) {
    this.currentPage = config.page_number;
    this.totalPages = config.total_pages;

    this.configureControls();
    this.configurePaginationInfo(config);
  }

  goToPage(pageNumber) {
    if (pageNumber <= this.totalPages && pageNumber > 0) {
      this.currentPage = pageNumber;

      this.onChangePage(this.currentPage);
    }
  }

  nextPage() {
    this.goToPage(this.currentPage + 1);
  }

  prevPage() {
    this.goToPage(this.currentPage - 1);
  }
}
