<template>
  <GridContainer>
    <GridItem>
      <HeaderBox title="Listagem de configurações da negociação" />
    </GridItem>

    <GridItem>
      <IndexBRGPainel />
    </GridItem>
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { HeaderBox } from '@shared/components/header-box';
import { IndexBRGPainel } from '@quero-turbo/modules/brg-components';

export default {
  components: {
    HeaderBox,
    GridContainer,
    GridItem,
    IndexBRGPainel,
  },
};
</script>
