<template>
  <transition-group
    class="oc-batch-sheets-table"
    name="oc-batch-sheets-table"
    tag="div"
  >
    <OfferCreationBatchSheetsTableItemHeader :key="0" />
    <OfferCreationBatchSheetsTableItem
      v-for="sheet in offerCreationSheets"
      :key="`${sheet.id}-${sheet.status}`"
      :sheet="sheet"
      :name="sheet.name"
      :created-at="sheet.createdAt"
      :status="sheet.status"
      :university-user-name="sheet.universityUserName"
      :sheet-url="sheet.sheetUrl"
      @edit-sheet="handleEditSheet"
      @view-sheet="handleViewSheet"
      @created-sheet="$emit('created-sheet')"
      @create-sheet-error="$emit('create-sheet-error')"
    />
  </transition-group>
</template>

<script>
import OfferCreationBatchSheetsTableItem from './offer-creation-batch-sheets-table-item';
import OfferCreationBatchSheetsTableItemHeader from './offer-creation-batch-sheets-table-item-header';
import { SheetsModel } from './offer-creation-batch-models';

export default {
  components: {
    OfferCreationBatchSheetsTableItem,
    OfferCreationBatchSheetsTableItemHeader,
  },
  props: {
    offerCreationSheets: SheetsModel,
  },
  methods: {
    handleEditSheet(sheet) {
      this.$emit('edit-sheet', sheet);
    },
    handleViewSheet(sheet) {
      this.$emit('view-sheet', sheet);
    },
  },
};
</script>

<style>
.oc-batch-sheets-table {
  display: flex;
  flex-direction: column;
  margin-left: calc(var(--space-large) * -1);
  margin-right: calc(var(--space-large) * -1);
}

.oc-batch-sheets-table-move {
  transition: transform 1s;
}
</style>
