<template>
  <GridContainer>
    <GridItem>
      <HeaderBox title="Criando nova configuração da negociação" />
    </GridItem>

    <GridItem>
      <CreateBRGPainel />
    </GridItem>
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { HeaderBox } from '@shared/components/header-box';
import { CreateBRGPainel } from '@quero-turbo/modules/brg-components';

export default {
  components: {
    GridContainer,
    GridItem,
    HeaderBox,
    CreateBRGPainel,
  },
};
</script>
