import $ from 'jquery';
import template from '@stock/legacy/components/modals/offer-list-actions/batch/edit-template.html.ejs';
import { BatchOperationModal, BatchOperationRequest } from '@stock/legacy/modules/batch-operation';
import { TEMPLATE_EDIT_PROPS } from '@stock/legacy/components/modals/offer-list-actions/batch/pricing/template-props';
import setupConfirmationStep from '@stock/legacy/components/modals/offer-list-actions/batch/pricing/confirmation';
import NumberFormatter from '@shared/utils/number_format';
import { batchOperationsTracking } from '@stock/metrics';

const MIN_DISCOUNT = 5;
const MAX_DISCOUNT = 80;
const TYPE_ACTION_INPUT = '.js-typeaction-input';

export default class BatchStockDiscountChangeModal extends BatchOperationModal {
  constructor(searchForm, alert) {
    super(TEMPLATE_EDIT_PROPS, searchForm, alert, template);
    this.numberFormatter = new NumberFormatter();
    setupConfirmationStep(this, alert);
  }

  onShow() {
    super.onShow();
    this.setupAppliedDiscount();
    batchOperationsTracking.trackShowBatchDiscountChangeModal();
  }

  setupBatchOperation() {
    return new BatchOperationRequest(this.searchForm, {
      edited_field: 'discountPercentage',
      discount_percentage: this.newDiscount,
      constraints: { regressive_discount: false },
    });
  }

  setupAppliedDiscount() {
    this.$appliedDiscount = $('.js-batch-new-discount');
    this.applyValidator(this.$appliedDiscount, [this.validateNewDiscount.bind(this)]);
    const $newDiscountTarget = $('.js-discount-result');
    this.$appliedDiscount.on('input', ({ target }) => {
      const nonstandard = /[^(\d.)]/g;
      const value = target.value.replace(nonstandard, '');
      this.$appliedDiscount.val(value);
      const formattedDiscount = this.numberFormatter.toPercentage(this.newDiscount, { precision: 2 });
      $newDiscountTarget.text(formattedDiscount);
    });
    if (this.$appliedDiscount.val() !== '') {
      this.$appliedDiscount.trigger('change');
      this.$appliedDiscount.trigger('input');
    }
    if (!$newDiscountTarget.text()) {
      const formattedDiscount = this.numberFormatter.toPercentage(this.oldDiscount, { precision: 2 });
      $newDiscountTarget.text(formattedDiscount);
    }
  }

  setupRadioButton() {
    super.setupRadioButton();
    const $inputs = $(`${TYPE_ACTION_INPUT}`);
    const $newDiscountTarget = $('.js-discount-result');
    const $disclaimer = $('.js-type-disclaimer');
    $inputs.on('change', (event) => {
      const action = event.target.value;
      const formattedDiscount = this.numberFormatter.toPercentage(this.newDiscount, { precision: 2 });
      $newDiscountTarget.text(formattedDiscount);
      this.$appliedDiscount.trigger('change');
      $disclaimer.empty();
      $disclaimer.append(this.getMessageDisclaimer(action));
    });
  }

  getMessageDisclaimer(action) {
    if (action === '+') {
      return TEMPLATE_EDIT_PROPS.disclaimerInc;
    }
    if (action === '-') {
      return TEMPLATE_EDIT_PROPS.disclaimerDec;
    }
    return TEMPLATE_EDIT_PROPS.disclaimerRep;
  }

  validateNewDiscount() {
    if (this.newDiscount < MIN_DISCOUNT) {
      return false;
    }
    if (this.newDiscount > MAX_DISCOUNT) {
      return false;
    }
    if (this.appliedDiscount <= 0) {
      return false;
    }
    return true;
  }

  get appliedDiscount() {
    if (this.$appliedDiscount) {
      return this.numberFormatter.parse(this.$appliedDiscount.val()) || 0;
    }
    return null;
  }

  get newDiscount() {
    return this.radioSign === '='
      ? this.appliedDiscount
      : this.getAppliedValueFormated(this.appliedDiscount) + this.oldDiscount;
  }

  get oldDiscount() {
    return this.numberFormatter.parse(this.searchForm.toObject().discount.value);
  }

  getConfirmationData() {
    return {
      appliedValue: this.getAppliedValueFormated(this.appliedDiscount),
      newDiscount: this.newDiscount,
      oldDiscount: this.oldDiscount,
      totalOffersCount: this.totalOffersCount,
      totalOffersTargetCount: this.totalOffersTargetCount,
      restockType: $(`${TYPE_ACTION_INPUT}:checked`).val(),
    };
  }
}
