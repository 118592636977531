<template>
  <component
    class="grid-item"
    :is="tag"
    :class="{
      [`grid-item--column-${column}`]: column,
      [`grid-item--row-${row}`]: row,
      [`grid-item--behavior-${behavior}`]: behavior,
    }"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    column: {
      type: Number,
      default: 12,
    },
    row: {
      type: Number,
      default: 1,
    },
    tag: {
      type: String,
      default: 'div',
    },
    behavior: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import "@quero/zilla-core/src/utils/_index.scss";

.grid-item {
  position: relative;
}

@for $i from 1 through 12 {
  .grid-item--column-#{$i} {
    grid-column: span #{$i};

    @media (max-width: #{$screen-desktop}) {
      @if $i >= 6 {
        grid-column: span 12;
      } @else {
        grid-column: span 6;
      }
    }
    @media (max-width: #{$screen-tablet}) {
      grid-column: span 12;
    }
  }
  .grid-item--row-#{$i} {
    grid-row: span #{$i};
  }
}

.grid-item--behavior-horizontal {
  align-items: flex-start;
  display: flex;
}

.grid-item--behavior-vertical {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
</style>
