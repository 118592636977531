<template>
  <div class="oc-offer-details__container">
    <div>
      <step-header
        name="Detalhes da oferta na instituição"
        :current-step-number="3"
        :last-step-number="5"
      />

      <z-card
        size="small"
        class="oc-offer-details__card"
      >
        <div class="oc-offer-details-card__header">
          <span class="header-logo">
            <img
              class="oc-offer-details-card__university-logo"
              :src="university.logoUrl"
            >
          </span>
          <span class="oc-offer-details-card__header-info">
            <z-text
              tag="span"
              size="small"
              class="oc-offer-details-card__header-text"
            >
              <strong>Desconto da faculdade</strong> é o desconto oferecido ao aluno quando este procura diretamente a instituição.
            </z-text>
          </span>
        </div>

        <div class="oc-offer-details-card__table">
          <form-table
            :headers="tableHeaders"
            :rows="tableRows"
            @valueInput="updateScopedUniversityOfferByShift"
          />
        </div>

        <div class="oc-offer-details-card__footer">
          <z-text
            tag="span"
            size="small"
            class="oc-offer-details-card__footer-text"
          >
            Mantenha os valores sempre atualizados.
          </z-text>
        </div>
      </z-card>
    </div>

    <div class="oc-offer-details__footer">
      <z-button
        @click="prev"
        variant="tertiary"
      >
        Voltar
      </z-button>

      <z-button
        @click="next"
        :is-disabled="isNextStepDisabled"
      >
        Próximo
      </z-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { currencyFormat } from '@shared/utils/number_format';
import { notifierMixin } from '@stock/utils/mixins/notifier';
import { offerCreationTracking } from '@stock/metrics';
import { price, shiftRules } from '@stock/business';
import FormTable from '../form-table';
import StepHeader from '../step-header';

export default {
  components: {
    StepHeader,
    FormTable,
  },
  mixins: [notifierMixin],
  data() {
    return {
      scopedUniversityOffersByShift: {},
      tableHeaders: [
        {
          text: 'Turno',
          value: 'shift',
          type: 'text',
          align: 'left',
        },
        {
          text: 'Valor cheio da mensalidade',
          value: 'fullPrice',
          type: 'form',
          align: 'left',
        },
        {
          text: 'Desconto da faculdade (%)',
          value: 'commercialDiscount',
          type: 'form',
          align: 'left',
        },
        {
          text: 'Valor com desconto da faculdade',
          value: 'commercialPrice',
          type: 'text',
          align: 'left',
        },
      ],
      tableRows: [],
    };
  },
  computed: {
    ...mapState('SessionModule', ['university']),
    ...mapState('OfferCreationModule', ['universityOffersByShift']),
    ...mapGetters('OfferCreationModule', ['shifts', 'isGraduate']),
    isNextStepDisabled() {
      return this.hasAnyInputError() || this.hasAnyInputEmpty();
    },
  },
  mounted() {
    this.setupScopedUniversityOffersByShift();
    this.setupTableHeaders();
    this.setupTableRows();
  },
  methods: {
    ...mapActions('OfferCreationModule', ['updateUniversityOfferData']),
    updateScopedUniversityOfferByShift(data) {
      this.scopedUniversityOffersByShift[data.key][data.field] = parseFloat(data.value);
      this.updateTableRow(data.key);
    },
    updateStoreUniversityOfferByShift() {
      this.shifts.forEach((shiftKey) => {
        this.updateUniversityOfferData({
          ...this.scopedUniversityOffersByShift[shiftKey],
          shift: shiftKey,
        });
      });
    },
    setupScopedUniversityOffersByShift() {
      this.shifts.forEach((shiftKey) => {
        const commonFields = {
          fullPrice: this.universityOffersByShift[shiftKey].fullPrice,
          commercialDiscount: this.universityOffersByShift[shiftKey].commercialDiscount,
        };

        if (this.isGraduate) {
          this.$set(this.scopedUniversityOffersByShift, shiftKey, {
            ...commonFields,
            maxPayments: this.universityOffersByShift[shiftKey].maxPayments,
          });
        } else {
          this.$set(this.scopedUniversityOffersByShift, shiftKey, commonFields);
        }
      });
    },
    setupTableHeaders() {
      if (this.isGraduate) {
        const maxPaymentsHeaderPosition = 3;

        this.tableHeaders.splice(maxPaymentsHeaderPosition, 0, {
          text: 'PARCELAS',
          value: 'maxPayments',
          type: 'form',
          align: 'left',
        });
      }
    },
    setupTableRows() {
      this.shifts.forEach((shiftKey) => {
        const baseFields = {
          key: shiftKey,
          shift: shiftRules.shiftTranslate(shiftKey),
          fullPrice: {
            value: String(this.scopedUniversityOffersByShift[shiftKey].fullPrice),
            hasError: false,
            type: 'number',
            step: '0.01',
            min: price.FULL_PRICE_RANGE.min.toString(),
            max: price.FULL_PRICE_RANGE.max.toString(),
          },
          commercialDiscount: {
            value: String(this.scopedUniversityOffersByShift[shiftKey].commercialDiscount),
            hasError: false,
            type: 'number',
            step: '0.1',
            min: price.COMMERCIAL_DISCOUNT_RANGE.min.toString(),
            max: price.COMMERCIAL_DISCOUNT_RANGE.max.toString(),
          },
          commercialPrice: String(this.getCurrencyCommercialPrice(this.scopedUniversityOffersByShift[shiftKey])),
        };

        if (this.isGraduate) {
          this.tableRows.push({
            ...baseFields,
            maxPayments: {
              value: this.scopedUniversityOffersByShift[shiftKey].maxPayments,
              hasError: false,
              type: 'number',
              min: price.MAX_PAYMENTS_RANGE.min.toString(),
              max: price.MAX_PAYMENTS_RANGE.max.toString(),
            },
          });
        } else {
          this.tableRows.push(baseFields);
        }
      });
    },
    updateTableRow(rowKey) {
      const rowIndex = this.tableRows.indexOf(this.tableRows.find(row => row.key === rowKey));
      const isInvalidFullPrice = this.isInvalidFullPrice(this.scopedUniversityOffersByShift[rowKey].fullPrice);
      const isInvalidCommercialDiscount = this.isInvalidCommercialDiscount(
        this.scopedUniversityOffersByShift[rowKey].commercialDiscount,
      );

      const commonFields = {
        ...this.tableRows[rowIndex],
        commercialPrice: String(this.getCurrencyCommercialPrice(this.scopedUniversityOffersByShift[rowKey])),
        commercialDiscount: {
          ...this.tableRows[rowIndex].commercialDiscount,
          value: String(this.scopedUniversityOffersByShift[rowKey].commercialDiscount),
          hasError: isInvalidCommercialDiscount,
        },
        fullPrice: {
          ...this.tableRows[rowIndex].fullPrice,
          value: String(this.scopedUniversityOffersByShift[rowKey].fullPrice),
          hasError: isInvalidFullPrice,
        },
      };

      if (this.isGraduate) {
        this.$set(this.tableRows, rowIndex, {
          ...commonFields,
          maxPayments: {
            ...this.tableRows[rowIndex].maxPayments,
            value: String(this.scopedUniversityOffersByShift[rowKey].maxPayments),
            hasError: this.isInvalidMaxPayments(this.scopedUniversityOffersByShift[rowKey].maxPayments),
          },
        });
      } else {
        this.$set(this.tableRows, rowIndex, commonFields);
      }
    },
    getCurrencyCommercialPrice(universityOffer) {
      if (this.hasValidUniversityOfferValues(universityOffer)) {
        return currencyFormat(price.calculateCommercialPrice(universityOffer));
      }
      return '';
    },
    isInvalidCommercialDiscount(commercialDiscount) {
      if (commercialDiscount === null) return false;
      return !price.isValidCommercialDiscount({ commercialDiscount });
    },
    isInvalidFullPrice(fullPrice) {
      if (fullPrice === null) return false;
      return !price.isValidFullPrice({ fullPrice });
    },
    isInvalidMaxPayments(maxPayments) {
      if (maxPayments === null) return false;
      return !price.isValidMaxPayments({ maxPayments });
    },
    hasValidUniversityOfferValues({ fullPrice, commercialDiscount }) {
      const result = price.isValidFullPrice({ fullPrice })
        && price.isValidCommercialDiscount({ commercialDiscount });

      return result;
    },
    hasAnyInputError() {
      return this.tableRows.some(this.hasAnyPriceError);
    },
    hasAnyPriceError(row) {
      return row.fullPrice.hasError || row.commercialDiscount.hasError;
    },
    hasAnyInputEmpty() {
      return Object.values(this.scopedUniversityOffersByShift).some(this.hasNoPriceFields);
    },
    hasNoPriceFields(universityOffer) {
      if (this.isGraduate) {
        return (
          !universityOffer.fullPrice
          || this.isTheCommercialDiscountEmpty(universityOffer)
          || !universityOffer.maxPayments
        );
      }
      return (
        !universityOffer.fullPrice
        || this.isTheCommercialDiscountEmpty(universityOffer)
      );
    },
    isTheCommercialDiscountEmpty(universityOffer) {
      return (
        !universityOffer.commercialDiscount
        && universityOffer.commercialDiscount !== 0
      );
    },
    prev() {
      this.$emit('prev');
    },
    next() {
      if (this.isNextStepDisabled) return;

      if (navigator.onLine || navigator.onLine === undefined) {
        offerCreationTracking.advancedOfferDetailStep();
        this.updateStoreUniversityOfferByShift();
        this.$emit('next');
      } else {
        this.notifyOfflineMessage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@quero/zilla-core/src/utils/_index.scss";

.oc-offer-details__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 616px;
  @media (min-width: $screen-desktop) {
    justify-content: space-between;
  }
}

.oc-offer-details__footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-medium);
  margin-top: var(--space-medium);
  @media (min-width: $screen-desktop) {
    margin-top: var(--space-jumbo);
    margin-bottom: 0;
  }
}

.oc-offer-details-card__university-logo {
  margin-right: var(--space-large);
  max-height: var(--size-jumbo);
  max-width: 104px;
}

.oc-offer-details-card__header {
  align-items: center;
  display: flex;
  margin-bottom: var(--space-medium);
  @media (min-width: $screen-desktop) {
    align-items: center;
    justify-content: space-between;
    margin-left: var(--space-small);
    margin-right: var(--space-small);
  }
}

.oc-offer-details-card__table {
  margin-left: calc(-1 * var(--space-medium));
  margin-right: calc(-1 * var(--space-medium));
}

.oc-offer-details-card__footer {
  margin-left: var(--space-small);
  margin-right: var(--space-small);
  margin-top: var(--space-medium);
}

.oc-offer-details-card__header-text {
  margin-left: var(--space-extra-small);
}

.oc-offer-details-card__header-info,
.oc-offer-details-card__footer {
  display: flex;
}
</style>
