import formatCurrency from '@shared/utils/vue/filters/formatCurrency';
import formatDate from '@shared/utils/vue/filters/formatDate';
import formatDateTime from '@shared/utils/vue/filters/formatDateTime';
import formatPercentage from '@shared/utils/vue/filters/formatPercentage';
import {
  currencyFormat,
  decimalFormat,
  percentFormat,
} from '@shared/utils/number_format';

export const FiltersPlugin = {
  install(Vue) {
    Vue.filter('currencyFormat', currencyFormat);
    Vue.filter('formatCurrency', formatCurrency);
    Vue.filter('decimalFormat', decimalFormat);
    Vue.filter('percentFormat', percentFormat);
    Vue.filter('formatDate', formatDate);
    Vue.filter('formatDateTime', formatDateTime);
    Vue.filter('formatPercentage', formatPercentage);
  },
};
