import $ from 'jquery';
import LoadingAnimation from '@shared/legacy/components/loading-animation';

export default class FollowUpUpdater {
  constructor(modal) {
    if (modal.length) {
      this.$modal = $(modal);
      this.$form = $('#js-follow-up-updater-form', this.$modal);
      this.$statusWrapper = $('#js-follow-up-updater-status', this.$modal);
      this.$statusText = $('.xlsx-upload-status__text', this.$statusWrapper);
      this.xlsxLoading = new LoadingAnimation({ target: `${modal} .modal-content` });
      this.formData = new window.FormData();

      this.setup();
    }
  }

  setup() {
    this.handleFileChange();
    this.handleFormSubmit();
    this.handleModalHide();
  }

  handleFileChange() {
    this.$form.on('change', ':file', (e) => {
      this.formData.append('sheet[file]', e.target.files[0]);

      this.$form.submit();
      this.cleanAndHideMessage();
    });
  }

  handleFormSubmit() {
    this.$form.submit((e) => {
      e.preventDefault();
      this.xlsxLoading.show();

      $.ajax({
        url: this.$form[0].action,
        data: this.formData,
        processData: false,
        contentType: false,
        type: this.$form[0].method,
      }).fail((jqXHR) => {
        this.showMessage(jqXHR.responseJSON.error, false);
      }).done(() => {
        this.showMessage(
          `Sua atualização está sendo processada, assim que ela
          terminar te enviaremos um email com o resultado.`,
          true,
        );
      }).always(() => {
        this.xlsxLoading.hide();
        this.resetForm();
      });
    });
  }

  handleModalHide() {
    this.$modal.on('hidden.bs.modal', () => {
      this.cleanAndHideMessage();
    });
  }

  showMessage(text, success) {
    const statusClass = success
      ? 'xlsx-upload-status__text--success'
      : 'xlsx-upload-status__text--error';

    this.$statusText.addClass(statusClass);
    this.$statusText.text(text);
    this.$statusWrapper.show();
  }

  cleanAndHideMessage() {
    this.$statusWrapper.hide();
    this.$statusText.text(null);
  }

  resetForm() {
    this.$form.trigger('reset');
  }
}
