module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="z-toast__item z-toast__item--is-open js-alert">\n  <div role="alert" aria-live="assertive" aria-atomic="true" class="z-toast__box z-toast__box--' +
((__t = ( type )) == null ? '' : __t) +
'">\n    <i class="z-toast__icon ' +
((__t = ( icon )) == null ? '' : __t) +
'"></i>\n\n    <p class="z-toast__text z-title z-title--extra-small">' +
((__t = ( message )) == null ? '' : __t) +
'</p>\n\n    <button\n      type="button"\n      aria-label="Fechar"\n      data-dismiss="z-toast"\n      class="z-toast__close-icon z-icon js-close-toast"\n      title="Fechar"\n    >\n      <i class="z-icon__svg far fa-times"></i>\n    </button>\n  </div>\n</div>\n';

}
return __p
}