import axios from 'axios';

const csrf = document.querySelector('meta[name=csrf-token]').content;

const httpClient = axios.create({
  baseURL: '/',
  timeout: 25000,
  headers: {
    'X-CSRF-TOKEN': csrf,
  },
});

const createInlineOffer = params => httpClient.post(
  '/ui/ofertas/create-offer/inline', params,
);

const updateRestockInline = params => httpClient.post(
  '/ui/ofertas/reposicao/single/repor', params,
);

const acceptsTermsFromUniversityUser = params => httpClient.post(
  '/university_users/accept_terms', params,
);

const createOfferCreationSpreadsheet = params => httpClient.post(
  'ui/ofertas/create-offer/offer-sheets', params,
);

const indexOfferCreationSpreadsheet = params => httpClient.get(
  'ui/ofertas/create-offer/offer-sheets', params,
);

const showOfferCreationSpreadsheet = id => httpClient.get(
  `ui/ofertas/create-offer/offer-sheets/${id}`,
);

const publishOfferCreationSpreadsheet = id => httpClient.post(
  `ui/ofertas/create-offer/offer-sheets/${id}`,
);

const updateOfferCreationSpreadsheet = params => httpClient.put(
  `ui/ofertas/create-offer/offer-sheets/${params.id}`, params,
);

const campusCreationInline = params => httpClient.post(
  '/campus/create_inline', params,
);

const createCampusBatch = params => httpClient.post(
  '/campus/create_batch',
  params,
  {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
);

export const queroAlunosAPI = {
  acceptsTermsFromUniversityUser,
  createInlineOffer,
  createOfferCreationSpreadsheet,
  indexOfferCreationSpreadsheet,
  showOfferCreationSpreadsheet,
  publishOfferCreationSpreadsheet,
  updateRestockInline,
  updateOfferCreationSpreadsheet,
  campusCreationInline,
  createCampusBatch,
};
