import { DateUtils } from '@stock/utils/date';

function lastSaleDateText(stockStatus, stockDates) {
  if (stockStatus === 'with_sales') return DateUtils.getDayMessage('Não vende há ', stockDates.lastSaleDate);
  if (stockStatus === 'stockout') return DateUtils.getDayMessage('Esgotado há ', stockDates.stockoutDate);
  if (stockStatus === 'no_sales') return DateUtils.getDayMessage('Não vende há ', stockDates.startDate);

  return '';
}

export const availability = { lastSaleDateText };
