import pipe from 'lodash/fp/pipe';
import EJSHelpers from '@shared/utils/templates/ejs';
import templateDefault from './z-toast.html.ejs';

const DOMHelpers = {
  appendToTarget: $target => (node) => {
    $target.append(node);
    return node;
  },
  addCloseTimeout: timeout => (node) => {
    if (timeout) {
      setTimeout(() => node.remove(), timeout);
    }
    return node;
  },
};

const defaultIconClass = {
  success: 'fas fa-check-circle',
  danger: 'fas fa-times-circle',
  warning: 'fas fa-exclamation-circle',
  info: 'fas fa-info-circle',
};

const ZToast = ({
  $target = $('.js-z-toast-target'),
  defaultTimeout = 6000,
  defaultType = 'info',
  iconClass = defaultIconClass,
  defaultErrorMessage = 'Desculpe, houve um erro com a sua requisição. Por favor, tente novamente.',
} = {}) => ({

  /**
   * @param {String} message
   * @param {Object} options
   *
   * @returns {void}
   */
  showAlert(message, { timeout = defaultTimeout, type = defaultType }) {
    const icon = iconClass[type];
    pipe(
      EJSHelpers.buildTemplate(templateDefault),
      DOMHelpers.appendToTarget($target),
      ([node]) => node,
      DOMHelpers.addCloseTimeout(timeout),
      this.bindCloseListener,
    )({ type, message, icon });
  },

  bindCloseListener: (node) => {
    const closeButton = node.querySelector('.js-close-toast');
    $(closeButton).on(
      'click',
      () => node.remove(),
    );
  },

  /**
   * @param {String} message
   * @param {Object} options
   *
   * @returns {void}
   */
  showSuccess(message, options = {}) {
    return this.showAlert(message, { ...options, type: 'success' });
  },

  /**
   * @param {String} message
   * @param {Object} options
   *
   * @returns {void}
   */
  showWarning(message, options = {}) {
    return this.showAlert(message, { ...options, type: 'warning' });
  },

  /**
   * @param {String} message
   * @param {Object} options
   *
   * @returns {void}
   */
  showError(message, options = {}) {
    const errorMessage = message || defaultErrorMessage;
    return this.showAlert(errorMessage, { ...options, type: 'danger' });
  },

  /**
   * @param {String} message
   * @param {Object} options
   *
   * @returns {void}
   */
  showInfo(message, options = {}) {
    return this.showAlert(message, { ...options, type: 'info' });
  },
});

const ZToastSingleton = ZToast();
export default ZToastSingleton;
