import axios from 'axios';

const csrf = document.querySelector('meta[name=csrf-token]').content;

const httpClientV1 = axios.create({
  baseURL: '/api/quero_turbo/v1',
  timeout: 10000,
  headers: {
    'X-CSRF-TOKEN': csrf,
  },
});

const indexBRG = params => httpClientV1.get(
  '/business_rule_groups', { params },
);

const createBRG = params => httpClientV1.post(
  '/business_rule_groups', params,
);

const showBRG = (id, params = {}) => httpClientV1.get(
  `/business_rule_groups/${id}`, params,
);

const updateBRG = (id, params = {}) => httpClientV1.put(
  `/business_rule_groups/${id}`, params,
);

const BRG = {
  createBRG,
  indexBRG,
  showBRG,
  updateBRG,
};

const createDeal = params => httpClientV1.post(
  '/deals', params,
);

const indexDeal = () => httpClientV1.get(
  '/deals',
);

const Deal = {
  createDeal,
  indexDeal,
};

const createOfferFilter = params => httpClientV1.post(
  '/offer_filters', params,
);

const OfferFilter = {
  createOfferFilter,
};

const createCommissionConfig = params => httpClientV1.post(
  '/product_configs', params,
);

const CommissionConfig = {
  createCommissionConfig,
};

const indexTurboAccount = () => httpClientV1.get(
  '/turbo_accounts',
);

const createTurboAccount = params => httpClientV1.post(
  '/turbo_accounts', params,
);

const TurboAccount = {
  indexTurboAccount,
  createTurboAccount,
};

const createAdmissionConfig = params => httpClientV1.post(
  '/product_configs', params,
);

const AdmissionConfig = {
  createAdmissionConfig,
};

const createBillingConfig = params => httpClientV1.post(
  '/product_configs', params,
);

const BillingConfig = {
  createBillingConfig,
};

const indexProductConfig = params => httpClientV1.get(
  '/product_configs', { params },
);

const updateProductConfig = (id, params = {}) => httpClientV1.put(
  `/product_configs/${id}`, params,
);

const ProductConfig = {
  indexProductConfig,
  updateProductConfig,
};

export const QueroDealsV1API = {
  AdmissionConfig,
  BRG,
  BillingConfig,
  CommissionConfig,
  ProductConfig,
  Deal,
  OfferFilter,
  TurboAccount,
};
