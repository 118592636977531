module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 studentAnswers.forEach(function(student_answer) { ;
__p += '\n  <div class="student-answer">\n    <div class="row">\n      <div class="col-sm-2">\n        <div class="student-info">\n          <div class="student-profile-image">\n            <img src="/images/icone-user.svg" width="50" data-uid="' +
((__t = ( student_answer.student_uid )) == null ? '' : __t) +
'">\n          </div>\n          <div class="student-name">\n            ' +
((__t = ( student_answer.student_name )) == null ? '' : __t) +
'\n          </div>\n          <div class="student-nps ' +
((__t = ( student_answer.nps.key )) == null ? '' : __t) +
'">\n            <span>' +
((__t = ( student_answer.nps.name )) == null ? '' : __t) +
'</span>\n          </div>\n          <div class="student-description">\n            <p><span class="description-icon"><img src="/images/icone-review-quero-bolsista.svg" /></span> <span class="description-text">Quero Bolsista</span></p>\n            <p><span class="description-icon"><img src="/images/icone-review-curso.svg" /></span> <span class="description-text">' +
((__t = ( student_answer.course_name )) == null ? '' : __t) +
'</span></p>\n            <p><span class="description-icon"><img src="/images/icone-review-calendario.svg"/></span> <span class="description-text">Iniciou em ' +
((__t = ( student_answer.semester )) == null ? '' : __t) +
'</span></p>\n            ';
 if (student_answer.local) { ;
__p += '\n              <p><span class="description-icon"><img src="/images/icone-review-local.svg" /></span> <span class="description-text">' +
((__t = ( student_answer.local )) == null ? '' : __t) +
'</span></p>\n            ';
 } ;
__p += '\n            <p><span class="description-icon"><img src="/images/icone-review-campus.svg"/></span> <span class="description-text">' +
((__t = ( student_answer.campus )) == null ? '' : __t) +
'</span></p>\n          </div>\n        </div>\n      </div>\n      <div class="col-md-9 col-sm-10">\n        <div class="student-answer-card">\n          <div class="answer-title">\n            "' +
((__t = ( student_answer.title )) == null ? '' : __t) +
'"\n          </div>\n          <div class="answer-rating-avg">\n            ';
 for(var i = 0; i < 5; i++) { ;
__p += '\n              ';
 if(i - student_answer.score_avg <= 0) { ;
__p += '\n                <img src="/images/star-enabled.svg" width="17" />\n              ';
 } else { ;
__p += '\n                <img src="/images/star-disabled.svg" width="17" />\n              ';
 } ;
__p += '\n            ';
 } ;
__p += '\n          </div>\n          <div class="answer-rating">\n            <div class="row">\n            ';
 for(keyword in student_answer.score_answers) { ;
__p += '\n              ';
 score_answer = student_answer.score_answers[keyword] ;
__p += '\n              <div class="score-rating-item">\n                <div class="item-title">' +
((__t = ( score_answer.title )) == null ? '' : __t) +
'</div>\n                ';
 for(var i = 0; i < 5; i++) { ;
__p += '\n                  ';
 if (i - score_answer.value <= 0) { ;
__p += '\n                    <img src="/images/star-enabled.svg" width="14" />\n                  ';
 } else { ;
__p += '\n                    <img src="/images/star-disabled.svg" width="14" />\n                  ';
 } ;
__p += '\n                ';
 } ;
__p += '\n              </div>\n            ';
 } ;
__p += '\n            </div>\n          </div>\n          <hr/>\n          <div class="answer-text">\n            <div class="positive-points">\n              <img src="/images/icone-review-pontos-positivos.svg" width="22" />\n              <p>' +
((__t = ( student_answer.positive_points )) == null ? '' : __t) +
'</p>\n            </div>\n            <div class="negative-points">\n              <img src="/images/icone-review-pontos-negativos.svg" width="22" />\n              <p>' +
((__t = ( student_answer.negative_points )) == null ? '' : __t) +
'</p>\n            </div>\n          </div>\n        </div>\n\n        <div class="university-reply ';
 if (student_answer.reply.id) {;
__p += ' replied-state ';
} ;
__p += '">\n\n          <div class="university-reply-card no-reply-card">\n            <a href="#" class="reply-button">Responder</a>\n          </div>\n\n          <div class="university-reply-card replied-card">\n            ';
 if (student_answer.reply.id) { ;
__p += '\n              <div class="heading-buttons">\n                <a href="#" class="edit-reply">Editar</a>\n                <a href="#" data-id="' +
((__t = ( student_answer.reply.id )) == null ? '' : __t) +
'" class="delete-reply">Excluir</a>\n              </div>\n              <div class="university-reply-card-title">\n                Resposta da ' +
((__t = ( student_answer.university_name )) == null ? '' : __t) +
'\n              </div>\n              <div class="replied-text">\n                <p class="retr-reply-js">\n                  ' +
__e( student_answer.reply.text ) +
'\n                </p>\n              </div>\n            ';
 } ;
__p += '\n          </div>\n\n          <div class="university-reply-card new-reply-card">\n            <form accept-charset="UTF-8" action="/avaliacao-dos-alunos/replies" data-remote="true" enctype="multipart/form-data" method="post">\n              <div class="form-group">\n                <label class="control-label" for="reply_text">RESPONDER</label>\n                <input id="reply_survey_form_id" name="reply[survey_form_id]" type="hidden" value="' +
((__t = ( student_answer.form_id )) == null ? '' : __t) +
'">\n                <textarea class="form-control reply-text" id="reply_text" name="reply[text]" placeholder="Escreva sua resposta..."></textarea>\n                <span class="error-message hidden">Erro ao enviar resposta. Tente novamente.</span>\n              </div>\n              <div class="footer-buttons">\n                <button type="button" class="btn btn-secondary reverse cancel-button">Cancelar</button>\n                <button type="submit" class="btn btn-primary btn-new-reply" disabled="true">Enviar resposta</button>\n              </div>\n            </form>\n          </div>\n\n          <div class="university-reply-card edit-reply-card">\n            ';
 if (student_answer.reply) { ;
__p += '\n              <form accept-charset="UTF-8" action="/avaliacao-dos-alunos/replies/' +
((__t = ( student_answer.reply.id )) == null ? '' : __t) +
'" data-remote="true" enctype="multipart/form-data" method="post">\n                <div class="form-group">\n                  <label class="control-label" for="reply_text">EDITAR A RESPOSTA</label>\n                  <input id="reply_survey_form_id" name="reply[survey_form_id]" type="hidden" value="' +
((__t = ( student_answer.form_id )) == null ? '' : __t) +
'">\n                  <input id="reply_reply_id" name="reply[reply_id]" type="hidden" value="' +
((__t = ( student_answer.reply.id )) == null ? '' : __t) +
'">\n                  <input type="hidden" class="original-text" value="' +
__e( student_answer.reply.text ) +
'">\n                  <textarea class="form-control reply-text" id="reply_text" name="reply[text]" placeholder="Escreva sua resposta...">' +
__e( student_answer.reply.text ) +
'</textarea>\n                  <span class="error-message hidden">Erro ao enviar resposta. Tente novamente.</span>\n                </div>\n                <div class="footer-buttons">\n                  <button type="button" class="btn btn-secondary reverse cancel-button">Cancelar</button>\n                  <button type="submit" class="btn btn-primary btn-save-reply" disabled="true">Salvar resposta</button>\n                </div>\n              </form>\n            ';
 } ;
__p += '\n          </div>\n        </div>\n\n      </div>\n    </div>\n  </div>\n';
 }) ;
__p += '\n';

}
return __p
}