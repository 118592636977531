module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-edit-step-wrapper">\n  <div class="inline-stock-action-edit__body modal-body">\n    <header class="inline-stock-action-edit__header">\n      <h2 class="inline-stock-action-edit__title gq-title gq-title--left">\n        Alteração de valor da oferta\n      </h2>\n      <p class="inline-stock-action-edit__description gq-text gq-text--left">\n        Informe novo valor com desconto para a oferta selecionada\n      </p>\n    </header>\n\n    <form class="inline-stock-action-edit__form inline-stock-action-edit__form--horizontal js-pricing-form">\n      <div>\n        <p class="inline-stock-action-edit__form-message gq-text gq-text--small gq-text--strong">\n          Valor atual da oferta (com desconto)\n        </p>\n        <p class="gq-text gq-text--large gq-text--strong">' +
((__t = ( offeredPrice )) == null ? '' : __t) +
'</p>\n      </div>\n\n      <section class="inline-stock-action-edit__main-target inline-stock-action-edit__main-target--right">\n        <label class="inline-stock-action-edit__form-group form-group">\n          <span class="control-label">Valor com desconto (R$)</span>\n          <input\n            class="form-control js-offered-price-input"\n            placeholder="Digite o valor"\n            type="number"\n          />\n        </label>\n      </section>\n    </form>\n\n    <ul class="inline-stock-action-edit__rules js-edit-rules">\n      <li class="inline-stock-action-edit__rule gq-text gq-text--small gq-title--strong">\n        <strong>O novo desconto deverá ficar entre 5% e 80%.</strong>\n        Caso queira dar um desconto maior, fale com seu key account.\n      </li>\n    </ul>\n  </div>\n\n  <footer class="inline-stock-action-edit__footer modal-footer">\n    <button\n      type="button"\n      class="inline-stock-action-edit__footer-action btn btn-secondary reverse js-back-button"\n    >\n      Voltar\n    </button>\n\n    <button\n      type="button"\n      class="inline-stock-action-edit__footer-action js-next-button btn btn-primary"\n      disabled>\n        Conferir prévia\n    </button>\n  </footer>\n</div>\n';

}
return __p
}