<template>
  <UPAModalSidebar
    :sections="sectionsItems"
    :title="sidebarTitle"
  />
</template>

<script>
import { UPAModalSidebar } from '@shared/components/upa-modal';

export default {
  components: {
    UPAModalSidebar,
  },
  computed: {
    sidebarTitle() {
      return 'Resumo';
    },
    sectionsItems() {
      return [
        {
          title: 'Campus',
          values: [
            { icon: 'check', text: 'Example' },
          ],
        },
      ];
    },
  },
};
</script>
