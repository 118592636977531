import { ConfirmationStep } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/restock/steps/confirmation';
import { ResponseStep } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/shared-steps/response';
import { EditStep } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/restock/steps/edit';
import { ValidateStep } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/restock/steps/validate';
import { Stepper } from '@stock/legacy/components/stepper';

export class RestockStepper extends Stepper {
  constructor({ offer, recommendedValue, source }) {
    super();
    this.offer = offer;
    this.recommendedValue = recommendedValue;
    this.source = source;
    this._validate();
    this._setup();
  }

  createSteps() {
    if (this._isInvalidOffer()) return [this._createValidateStep()];
    return [
      this._createEditStep(),
      this._createConfirmationStep(),
      this._createResponseStep(),
    ];
  }

  _validate() {
    if (!this.offer) throw Error('offer is a required parameter');
  }

  _setup() {
    return this.createSteps().forEach(step => this.addStep(step));
  }

  _createValidateStep() {
    const validateStep = new ValidateStep();
    return this._setupFirstStep(validateStep);
  }

  _createEditStep() {
    const editStep = new EditStep(this.offer, this.recommendedValue);
    this._setupFirstStep(editStep);
    editStep.onHook('next', () => this.next());
    return editStep;
  }

  _createConfirmationStep() {
    const confirmationStep = new ConfirmationStep(this.offer, this.recommendedValue, this.source);
    confirmationStep.onHook('previous', () => this.previous());
    confirmationStep.onHook('next', () => this.next());
    return confirmationStep;
  }

  _createResponseStep() { return new ResponseStep(); }

  _setupFirstStep(step) {
    step.onHook('previous', () => {
      if (step.previous) {
        return this.previous();
      }
      return this.event.emit('finished');
    });
    return step;
  }

  _isInvalidOffer() {
    return (this.offer.sharedSeats || !this.offer.limited);
  }
}
