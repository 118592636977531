module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="s-popover-availability">\n  <h3 class="s-popover-availability__title gq-title gq-title--nano">\n    Disponibilidade em estoque\n  </h3>\n  ';
 offers.map(function(offer) { ;
__p += '\n    <section class="s-popover-availability__offer">\n      <div class="s-popover-availability__status">\n        <span class="s-popover-availability__status-badge gq-text gq-text--micro">\n          <i class="fa-lg fas fa-usd-square s-popover-availability__icon ' +
((__t = ( getIconClassModifier(offer) )) == null ? '' : __t) +
'"></i>\n          ' +
((__t = ( getCompetitiveLabel(offer) )) == null ? '' : __t) +
'\n        </span>\n\n        <p class="gq-text gq-text--micro gq-text--bold gq-text--right">\n          ';
 if(offer.isStockout) { ;
__p += '\n            <strong class="s-popover-availability__stockout-text">Vagas esgotadas</strong>\n          ';
 } else { ;
__p += '\n            <strong>' +
((__t = ( getTotalSeatsMessage(offer) )) == null ? '' : __t) +
'</strong>\n          ';
 } ;
__p += '\n        </p>\n      </div>\n\n      ';
 if(offer.status == "visible") { ;
__p += '\n        <footer class="s-popover-availability__footer">\n          <div class="s-popover-availability__label">\n            <strong class="gq-text gq-text--nano gq-text--uppercase gq-text--single-line gq-text--bold gq-text--light">\n              Em venda\n            </strong>\n          </div>\n\n          <ul class="s-popover-availability__price">\n            <li class="s-popover-availability__price-item gq-text gq-text--bold gq-text--micro">\n              ' +
((__t = (offer.offeredPrice)) == null ? '' : __t) +
'\n            </li>\n\n            <li class="s-popover-availability__price-item gq-text gq-text--strikethrough gq-text--micro">\n              ' +
((__t = (offer.fullPrice)) == null ? '' : __t) +
'\n            </li>\n\n            <li class="s-popover-availability__price-item gq-text gq-text--micro">\n              (' +
((__t = (offer.discountPercentage)) == null ? '' : __t) +
')\n            </li>\n\n            <li class="s-popover-availability__price-item gq-text gq-text--bold gq-text--micro">\n              - ' +
((__t = ( offer.seatsBalance.limited ? getAvailabilitySeatsMessage(offer) : getPaidSeatsMessage(offer) )) == null ? '' : __t) +
'\n            </li>\n          </ul>\n        </footer>\n      ';
 } ;
__p += '\n    </section>\n  ';
 }) ;
__p += '\n  <button class="s-popover-availabitly__btn-more-details btn btn-secondary reverse js-course-data-section">\n    Ver mais detalhes\n  </button>\n</div>\n';

}
return __p
}