import NumberFormat from './number_format';

export default class NumericInput {
  constructor($input) {
    this.$input = $input;
    this.format = new NumberFormat();
    this.setupMask();
  }

  setupMask() {
    this.$input.on('input', () => {
      const nonstandard = /[^\d,.]/;
      const value = this.$input.val()
        .replace(nonstandard, '')
        .replace('.', ',');
      this.$input.val(value);
    });
  }

  get value() {
    return this.$input.val();
  }

  get parsedValue() {
    return this.format.parse(this.value);
  }
}
