<template>
  <z-input-field :label="label">
    <z-autocomplete
      :multiple="true"
      :options="coursesList"
      :show-no-result="false"
      :value="coursesSelected"
      @remove="coursesRemoveHandle($event)"
      @select="coursesSelectHandle($event)"
      hide-selected
    />

    <input
      :value="coursesSelected"
      v-bind="$attrs"
      type="hidden"
    >
  </z-input-field>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'ID do Curso',
    },
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    coursesSelected() {
      return this.value;
    },
    coursesList() {
      return [];
    },
  },
  methods: {
    coursesSelectHandle($event) {
      if (!$event) return;

      this.$emit('select', $event);
    },
    coursesRemoveHandle($event) {
      this.$emit('remove', $event);
    },
  },
};
</script>
