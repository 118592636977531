var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('z-popover',{attrs:{"position":"bottom-center"},on:{"click":_vm.trackKnowMoreAboutCompetitiveness},scopedSlots:_vm._u([{key:"target",fn:function(){return [_c('z-text',{attrs:{"tag":"span","color":"interactive"}},[_vm._v("\n      Veja como funciona\n    ")])]},proxy:true},{key:"content",fn:function(){return [_c('ul',{staticClass:"oc-offer-marketplace-popover-list"},[_c('z-title',{staticClass:"oc-offer-marketplace-popover-list__item",attrs:{"tag":"li"}},[_vm._v("\n        Como o Desconto Inteligente funciona?\n      ")]),_vm._v(" "),_c('z-dynamic-content',{class:[
          'oc-offer-marketplace-popover-list__item',
          'oc-offer-marketplace-popover-list__item-dynamic-text'
        ]},[_c('p',[_c('strong',[_vm._v("\n            Os descontos são recomendados a partir de uma análise no histórico de vendas do curso no marketplace e nos valores praticados por seus concorrentes.\n          ")])]),_vm._v(" "),_c('p',[_vm._v("\n          Quando percebemos que o ajuste de preço pode aumentar a atratividade de suas ofertas, iremos recomendá-lo. Você escolhe a faixa de preço disponível que preferir.\n        ")]),_vm._v(" "),_c('p',[_c('strong',[_vm._v("Exemplo:")])])]),_vm._v(" "),_c('z-text',{class:[
          'oc-offer-marketplace-popover-list__item',
          'oc-offer-marketplace-popover-list__item--centralized',
          'oc-offer-marketplace-popover-list__item-bar'
        ],attrs:{"tag":"li","size":"micro","color":"inverted"}},_vm._l((_vm.competitiveness),function(competitive){return _c('strong',{key:competitive.value,staticClass:"oc-offer-marketplace-popover-list__item-bar-label"},[_vm._v("\n          "+_vm._s(competitive.atractiveness)+"\n        ")])}),0),_vm._v(" "),_c('z-text',{class:[
          'oc-offer-marketplace-popover-list__item',
          'oc-offer-marketplace-popover-list__item--centralized'
        ],attrs:{"tag":"li","size":"small"}},_vm._l((_vm.competitiveness),function(competitive){return _c('strong',{key:competitive.value},[_c('z-icon',{class:("oc-offer-marketplace-popover-icon--" + (competitive.value)),attrs:{"icon":['fas','usd-square']}}),_vm._v("\n          "+_vm._s(competitive.label)+"\n        ")],1)}),0)],1)]},proxy:true}])})}
var staticRenderFns = []
export { render, staticRenderFns }