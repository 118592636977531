<template>
  <z-card>
    <z-title
      class="oc-batch-menu__title"
      size="extra-small"
    >
      Comece a criar suas ofertas em lote
    </z-title>

    <z-text
      color="minor"
      class="oc-batch-menu__subtitle"
      size="medium"
    >
      Confira as instruções abaixo, e veja como proceder.
    </z-text>

    <section class="oc-batch-menu__steps-container">
      <div class="oc-batch-menu__step">
        <div class="oc-batch-menu__step-icon-field">
          <OfferCreationBatchMenuIcon :number="1" />
        </div>

        <div>
          <z-title class="oc-batch-menu-step-title">
            Crie sua planilha
          </z-title>

          <z-text
            size="small"
            tag="span"
          >
            Clique no botão "Criar planilha" e construa ofertas dentro do nosso modelo de planilha, de forma fácil e prática.
          </z-text>
        </div>
      </div>

      <z-divider class="oc-batch-menu__step-divider" />

      <div class="oc-batch-menu__step">
        <div class="oc-batch-menu__step-icon-field">
          <OfferCreationBatchMenuIcon :number="2" />
        </div>

        <div>
          <z-title class="oc-batch-menu-step-title">
            Adicione suas ofertas em tempo real
          </z-title>

          <z-text
            size="small"
            tag="span"
          >
            Preencha a planilha com suas ofertas, e confira se está tudo certo para a publicar em tempo real no site Quero bolsa.
          </z-text>
        </div>
      </div>

      <z-divider class="oc-batch-menu__step-divider" />

      <div class="oc-batch-menu__step">
        <div class="oc-batch-menu__step-icon-field">
          <OfferCreationBatchMenuIcon :number="3" />
        </div>

        <div>
          <z-title class="oc-batch-menu-step-title">
            Publique quando quiser
          </z-title>

          <z-text
            size="small"
            tag="span"
          >
            Você pode construir várias planilhas e publicá-las ao concluir, ou depois de acordo com seu planejamento.
          </z-text>
        </div>
      </div>
    </section>

    <section class="oc-batch-menu__section-action">
      <z-button
        @click="$emit('create-sheet')"
        :is-disabled="false"
      >
        Criar planilha
      </z-button>
    </section>
  </z-card>
</template>

<script>
import OfferCreationBatchMenuIcon from './offer-creation-batch-menu-icon';

export default {
  components: {
    OfferCreationBatchMenuIcon,
  },
  props: {
    disable: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.oc-batch-menu__title {
  margin-bottom: var(--space-small);
}

.oc-batch-menu__subtitle {
  margin-bottom: var(--space-extra-large);
}

.oc-batch-menu__steps-container{
  display: flex;
  margin-bottom: var(--space-jumbo);
}

.oc-batch-menu__step {
  display: flex;
}

.oc-batch-menu__step-icon-field {
  display: flex;
  margin-right: var(--space-small);
  position: relative;
}

.oc-batch-menu-step-title {
  margin-bottom: var(--space-small);
}

.oc-batch-menu__step-divider {
  margin-left: var(--space-large);
  margin-right: var(--space-large);
}

.oc-batch-menu__section-action {
  display: flex;
  justify-content: flex-end;
}
</style>
