import $ from 'jquery';

export default class DropdownsPosition {
  static get Position() {
    return {
      BOTTOM: 'bottom',
      LEFT: 'left',
      RIGHT: 'right',
      TOP: 'top',
    };
  }

  constructor(tableClass) {
    this.element = $(tableClass);
    this.setupDropdowsPosition();
  }

  setupDropdowsPosition() {
    this.element.on('show.bs.dropdown', '.js-dropdown', (e) => {
      this.setupPosition($(e.target).find('.js-dropdown-menu'), $(e.relatedTarget));
    });

    const $setupScroll = $('.js-scroll-wrapper');

    $setupScroll.scroll(() => {
      const button = $('.js-dropdown-actions__button[aria-expanded=true]');
      const dropdownMenu = $(`[aria-labelledby=${button.prop('id')}]`);

      if (button.length > 0) {
        this.setupPosition(dropdownMenu, button);
      }
    });

    this.element.on('hide.bs.dropdown', '.js-dropdown', (e) => {
      const dropdownMenu = $(`[aria-labelledby=${e.relatedTarget.id}]`);
      $(e.currentTarget).append(dropdownMenu.detach());
      dropdownMenu.hide();
    });
  }

  setupPosition(dropdownMenu, button) {
    $('body').append(dropdownMenu.detach());

    const configuredPosition = this.inferDropdownPosition(button);

    const [x, y] = this.calcDropdownXY(
      configuredPosition,
      this.calcElemCoords(button),
      this.calcElemCoords(dropdownMenu),
    );

    dropdownMenu.css({
      display: 'block',
      position: 'absolute',
      left: x,
      top: y,
    });
  }

  calcDropdownXY(position, buttonCoords, dropdownCoords) {
    const coordsByPosition = {
      [DropdownsPosition.Position.BOTTOM]: () => {
        const x = buttonCoords.x - ((dropdownCoords.w - buttonCoords.w) / 2);
        const y = (buttonCoords.y + buttonCoords.h) + 1;

        return [x, y];
      },

      [DropdownsPosition.Position.LEFT]: () => {
        const x = buttonCoords.x - dropdownCoords.w - 15;
        const y = buttonCoords.y - (dropdownCoords.h / 2);

        return [x, y];
      },

      [DropdownsPosition.Position.RIGHT]: () => {
        const x = buttonCoords.x + buttonCoords.w + 15;
        const y = buttonCoords.y - (dropdownCoords.h / 2);

        return [x, y];
      },

      [DropdownsPosition.Position.TOP]: () => {
        // This "y" calc doesn't make much sense. I've arrived at
        // this expression by manual experimentation ¯\_(ツ)_/¯
        // The most correct answer would be:
        // y = buttonCoords.y - dropdownCoords.h - <adjustment>
        const x = buttonCoords.x - ((dropdownCoords.w - buttonCoords.w) / 2);
        const y = buttonCoords.y - (buttonCoords.h / 2) - dropdownCoords.h - 18;

        return [x, y];
      },
    };

    return coordsByPosition[position]();
  }

  inferDropdownPosition(button) {
    const cssClassByPosition = {
      [DropdownsPosition.Position.BOTTOM]: 'dropdown-actions__button--bottom',
      [DropdownsPosition.Position.LEFT]: 'dropdown-actions__button--left',
      [DropdownsPosition.Position.RIGHT]: 'dropdown-actions__button--right',
      [DropdownsPosition.Position.TOP]: 'dropdown-actions__button--top',
    };

    let position;
    $.each(cssClassByPosition, (pos, cssClass) => {
      if (button.hasClass(cssClass)) {
        position = pos;
        return false;
      }

      // It does nothing but make the linter happy
      return true;
    });

    return position || DropdownsPosition.Position.RIGHT;
  }

  calcElemCoords($elem) {
    return {
      x: $elem.offset().left,
      y: $elem.offset().top,
      w: $elem.outerWidth(),
      h: $elem.outerHeight(),
    };
  }
}
