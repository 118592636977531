<template>
  <z-card
    class="one-click-restock-footer"
    size="small"
  >
    <z-button
      class="one-click-restock-footer__button"
      behavior="block"
      @click="handleRestockClick"
      :is-disabled="isDisabled"
      :is-loading="isLoading"
    >
      Repor vagas
    </z-button>
  </z-card>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    isInvalidSeatsCountToReplace: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading || this.isInvalidSeatsCountToReplace;
    },
  },
  methods: {
    handleRestockClick() {
      if (this.isInvalidSeatsCountToReplace) return;
      this.isLoading = true;
      this.$emit('one-click-restock');
    },
  },
});
</script>

<style lang="scss" scoped>
.one-click-restock-footer {
  border-radius: 0;
  box-shadow: 0 0 1px 0 rgba(19,23,26,0.32), 0 -4px 8px -4px rgba(19,23,26,0.24);
}
</style>
