import { RollbarService } from '@shared/utils/rollbar-service';

class TrackerPlugin {
  static install(Vue) {
    const tracker = RollbarService.getInstance();
    const { errorHandler } = TrackerPlugin;
    Object.defineProperty(Vue.prototype, '$tracker', { value: tracker });
    Object.defineProperty(Vue.config, 'errorHandler', { value: errorHandler });
  }

  static errorHandler(error, vm) {
    vm.$tracker.notifyError(error);
    throw error;
  }
}

export { TrackerPlugin };
