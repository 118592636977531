<template>
  <div class="oc-course-selection">
    <Header
      name="Seleção de curso e campus"
      :current-step-number="1"
      :last-step-number="5"
    />

    <section class="oc-course-selection__section">
      <z-card
        size="large"
        class="oc-course-selection__card"
      >
        <div class="oc-course-selection__card-icon">
          <z-icon
            :icon="['fal','file-certificate']"
            size="jumbo"
          />
        </div>

        <z-title
          size="extra-small"
          class="oc-course-selection__card-title"
          tag="h4"
        >
          Tipo de formação
        </z-title>

        <z-text class="oc-course-selection__card-text">
          Selecione o tipo de formação que deseja criar oferta(s)
        </z-text>
        <z-input-field
          label="Tipo de Formação"
          class="oc-fields__wrapper"
        >
          <z-autocomplete
            :options="parentLevels"
            :internal-search="false"
            :icon="iconParentLevelStatus"
            :value="parentLevel"
            @select="handleLevelAutocompleteSelect"
          >
            <template
              v-slot:item="slotProps"
            >
              <div
                :class="{
                  'oc-fields__autocomplete-item': true,
                  'oc-fields__autocomplete-item--is-highlighted': slotProps.isHighlighted
                }"
              >
                <z-text
                  class="z-ovr-text--fw-medium oc-fields__autocomplete-item-text"
                  :color="slotProps.isHighlighted && 'inverted' || 'major'"
                >
                  {{ slotProps.item }}
                </z-text>
              </div>
            </template>
          </z-autocomplete>
        </z-input-field>
      </z-card>

      <z-card
        size="large"
        class="oc-course-selection__card"
      >
        <div class="oc-course-selection__card-icon">
          <z-icon
            :icon="['fal','book']"
            size="jumbo"
          />
        </div>

        <z-title
          size="extra-small"
          class="oc-course-selection__card-title"
          tag="h4"
        >
          Curso
        </z-title>

        <z-text class="oc-course-selection__card-text">
          Selecione o curso que deseja criar oferta(s)
        </z-text>

        <z-input-field
          label="Curso"
          class="oc-fields__wrapper"
        >
          <z-autocomplete
            :icon="iconCourseStatus"
            :internal-search="false"
            :is-disabled="autocompleteStatus"
            :options="coursesData"
            :show-no-result="autocompleteCourseShowNoResult"
            :value="courseInput"
            :clear-button-aria-label="autocompleteCourseHasArialLabel"
            :has-error="autocompleteCourseHasError"
            @clear="clearCourse"
            @input="handleCourseAutocompleteInput"
            @select="handleCourseAutocompleteSelect"
            ref="autocompleteCourse"
            label-by="name"
            placeholder="Ex.: Administração"
            class="oc-fields__autocomplete"
          >
            <template
              v-slot:item="slotProps"
            >
              <div
                :class="{
                  'oc-fields__autocomplete-item': true,
                  'oc-fields__autocomplete-item--small-vertical-padding': true,
                  'oc-fields__autocomplete-item--is-highlighted': slotProps.isHighlighted
                }"
              >
                <z-text
                  class="z-ovr-text--fw-medium oc-fields__autocomplete-item-text"
                  :color="slotProps.isHighlighted && 'inverted' || 'major'"
                >
                  {{ slotProps.item.name }}
                </z-text>

                <z-text
                  class="oc-fields__autocomplete-item-text"
                  size="small"
                  :color="slotProps.isHighlighted && 'inverted' || 'minor'"
                >
                  {{ slotProps.item.level }}
                </z-text>
              </div>
            </template>

            <template
              v-slot:noResult
            >
              <div
                class="oc-fields__autocomplete-item"
              >
                <z-text
                  class="z-ovr-text--fw-medium oc-fields__autocomplete-item-text"
                  color="major"
                >
                  Nenhum resultado encontrado
                </z-text>
              </div>
            </template>
          </z-autocomplete>
        </z-input-field>
      </z-card>

      <z-card
        size="large"
        class="oc-course-selection__card"
      >
        <div class="oc-course-selection__card-icon">
          <z-icon
            :icon="['fal','university']"
            size="jumbo"
          />
        </div>

        <z-title
          size="extra-small"
          class="oc-course-selection__card-title"
          tag="h4"
        >
          Nome do campus
        </z-title>

        <z-text class="oc-course-selection__card-text">
          Defina o campus para o qual a oferta será aplicada
        </z-text>

        <z-input-field
          label="Unidade(s)"
          class="oc-fields__wrapper"
        >
          <z-autocomplete
            :icon="iconCampusStatus"
            :internal-search="false"
            :is-disabled="autocompleteStatus"
            :options="campusesData"
            :show-no-result="autocompleteCampusShowNoResult"
            :value="campusInput"
            :clear-button-aria-label="autocompleteCampusHasArialLabel"
            :has-error="autocompleteCampusHasError"
            @clear="clearCampus"
            @input="handleCampusAutocompleteInput"
            @select="handleCampusAutocompleteSelect"
            ref="autocompleteCampus"
            label-by="text"
            placeholder="Ex.: Unidade São José"
            class="oc-fields__autocomplete"
          >
            <template
              v-slot:item="slotProps"
            >
              <div
                :class="{
                  'oc-fields__autocomplete-item': true,
                  'oc-fields__autocomplete-item--small-vertical-padding': true,
                  'oc-fields__autocomplete-item--is-highlighted': slotProps.isHighlighted
                }"
              >
                <z-text
                  class="z-ovr-text--fw-medium oc-fields__autocomplete-item-text"
                  :color="slotProps.isHighlighted && 'inverted' || 'major'"
                >
                  {{ slotProps.item.text }}
                </z-text>

                <z-text
                  class="oc-fields__autocomplete-item-text"
                  size="small"
                  :color="slotProps.isHighlighted && 'inverted' || 'minor'"
                >
                  {{ slotProps.item.hint }}
                </z-text>
              </div>
            </template>

            <template
              v-slot:noResult
            >
              <div
                class="oc-fields__autocomplete-item"
              >
                <z-text
                  class="z-ovr-text--fw-medium oc-fields__autocomplete-item-text"
                  color="major"
                >
                  Nenhum resultado encontrado
                </z-text>
              </div>
            </template>
          </z-autocomplete>
        </z-input-field>
      </z-card>
    </section>

    <footer class="oc-course-selection__footer">
      <z-button
        @click="next"
        :is-disabled="isNextStepDisabled"
      >
        Próximo
      </z-button>
    </footer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import { debounce } from 'lodash';
import { routes } from '@university-panel/utils/routes';
import { notifierMixin } from '@stock/utils/mixins/notifier';
import { offerCreationTracking } from '@stock/metrics';
import Header from '../step-header';

export default {
  components: {
    Header,
  },
  mixins: [notifierMixin],
  data() {
    return {
      campusAutocompleteHasFocus: false,
      campusesData: [],
      campusInput: null,
      campusSelected: null,
      courseAutocompleteHasFocus: false,
      courseInput: null,
      coursesData: [],
      courseSelected: null,
      parentLevel: null,
      parentLevels: ['Graduação', 'Pós-graduação'],
    };
  },
  computed: {
    ...mapState('SessionModule', ['university']),
    ...mapState('OfferCreationModule', ['sharedCampus', 'sharedCourse']),
    autocompleteStatus() {
      return Boolean(!(this.parentLevel && this.parentLevel.length > 0));
    },
    autocompleteCourseHasArialLabel() {
      return this.courseAutocompleteHasFocus ? 'Limpar busca por cursos' : null;
    },
    autocompleteCampusHasArialLabel() {
      return this.campusAutocompleteHasFocus ? 'Limpar busca por campus' : null;
    },
    autocompleteCourseHasError() {
      return Boolean(
        !this.isValidCourse
        && !this.courseAutocompleteHasFocus
        && this.courseInput
        && this.courseInput.length > 0,
      );
    },
    autocompleteCampusHasError() {
      return Boolean(
        !this.isValidCampus
        && !this.campusAutocompleteHasFocus
        && this.campusInput
        && this.campusInput.length > 0,
      );
    },
    autocompleteCampusShowNoResult() {
      return Boolean(this.campusInput && this.campusInput.length > 1 && this.campusesData);
    },
    autocompleteCourseShowNoResult() {
      return Boolean(this.courseInput && this.courseInput.length > 1 && this.coursesData);
    },
    iconParentLevelStatus() {
      return (this.parentLevel && this.parentLevels.includes(this.parentLevel)) ? ['fas fa-check-circle'] : '';
    },
    iconCourseStatus() {
      return this.isValidCourse ? ['fas fa-check-circle'] : '';
    },
    iconCampusStatus() {
      return this.isValidCampus ? ['fas fa-check-circle'] : '';
    },
    isValidCourse() {
      return Boolean(this.courseSelected && this.courseSelected.level && this.courseSelected.name);
    },
    isValidCampus() {
      return Boolean(this.campusSelected && this.campusSelected.id);
    },
    isNextStepDisabled() {
      return Boolean(!(this.isValidCourse && this.isValidCampus));
    },
  },
  methods: {
    ...mapActions('OfferCreationModule', ['setCourseSelectionData']),
    next() {
      if (this.isNextStepDisabled) return;

      if (navigator.onLine || navigator.onLine === undefined) {
        this.trackAdvance();
        this.updateStore();
        this.$emit('next');
      } else {
        this.notifyOfflineMessage();
      }
    },
    trackAdvance() {
      offerCreationTracking.advancedCourseSelectionStep({
        level: this.courseSelected.level,
      });
    },
    updateStore() {
      this.setCourseSelectionData({
        course: {
          name: this.courseSelected.name,
          level: this.courseSelected.level,
          parentLevel: this.parentLevel,
        },
        campus: {
          cityID: this.campusSelected.city_id,
          id: this.campusSelected.id,
          name: this.campusSelected.text,
          fullAddress: this.campusSelected.hint,
          virtual: this.campusSelected.virtual,
        },
      });
    },
    clearCourse() {
      this.courseInput = '';
      this.courseSelected = null;
      this.coursesData = [];
    },
    clearCampus() {
      this.campusInput = '';
      this.campusSelected = null;
      this.campusesData = [];
    },
    requestCourses: debounce(function requestCourses(courseName) {
      const params = {
        term: courseName,
        limit: 10,
        parent_level: this.parentLevel,
      };

      axios.get(routes.coursesDictionaryEntriesAutoCompletePath(), { params })
        .then(({ data }) => {
          this.coursesData = data.results;
        })
        .catch(props => console.log(props));
    }, 500),
    requestCampuses: debounce(function requestCampuses(campusName) {
      const params = {
        university_ids: [this.university.id],
        term: campusName,
        limit: 10,
        hint_type: 'full_address',
      };

      axios.get(routes.campusesAutoCompletePath(), { params })
        .then(({ data }) => {
          this.campusesData = data.results;
        })
        .catch(props => console.log(props));
    }, 500),
    handleCourseAutocompleteInput(courseName) {
      this.courseInput = courseName;
      if (courseName) {
        this.requestCourses(courseName);
      }
    },
    handleCampusAutocompleteInput(campusName) {
      this.campusInput = campusName;
      if (campusName) {
        this.requestCampuses(campusName);
      }
    },
    handleLevelAutocompleteSelect(value) {
      this.parentLevel = value;
      this.clearCourse();
      this.clearCampus();
    },
    handleCourseAutocompleteSelect(courseSelected) {
      this.courseSelected = courseSelected;
    },
    handleCampusAutocompleteSelect(campusSelected) {
      this.campusSelected = campusSelected;
    },
    handleCourseAutocompleteFocus(newValue) {
      this.courseAutocompleteHasFocus = newValue;
    },
    handleCampusAutocompleteFocus(newValue) {
      this.campusAutocompleteHasFocus = newValue;
    },
    parseCampus(campus) {
      return {
        text: campus.name,
        id: campus.id,
        hint: campus.fullAddress,
        city_id: campus.cityID,
      };
    },
    parseCourse(course) {
      return {
        name: course.name,
        level: course.level,
      };
    },
    setupCampus() {
      this.campusSelected = this.parseCampus(this.sharedCampus);
      this.campusInput = this.campusSelected.text;
      this.requestCampuses('');
    },
    setupCourse() {
      this.courseSelected = this.parseCourse(this.sharedCourse);
      this.courseInput = this.courseSelected.name;
    },
    setupParentLevel() {
      this.parentLevel = this.sharedCourse.parentLevel || 'Graduação';
    },
  },
  mounted() {
    this.setupCampus();
    this.setupCourse();
    this.setupParentLevel();
    this.$watch(
      '$refs.autocompleteCourse.hasFocus',
      this.handleCourseAutocompleteFocus,
    );
    this.$watch(
      '$refs.autocompleteCampus.hasFocus',
      this.handleCampusAutocompleteFocus,
    );
  },
};
</script>

<style lang="scss">
@import "@quero/zilla-core/src/utils/_index.scss";

.oc-course-selection__section {
  color: var(--color-font-major);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: var(--space-colossal);

  @media (min-width: $screen-desktop) {
    flex-direction: row;
  }
}

.oc-course-selection__card {
  margin-bottom: var(--space-extra-large);

  &:last-child {
    margin: 0;
  }

  @media (min-width: $screen-desktop) {
    margin-bottom: 0;
    margin-right: var(--space-extra-large);
    width: 352px;
  }
}

.oc-course-selection__card-icon {
  margin-bottom: var(--space-medium);
}

.oc-course-selection__card-title {
  margin-top: 0;
  margin-bottom: var(--space-medium);
}

.oc-course-selection__card-text {
  @media (min-width: $screen-desktop) {
    margin-bottom: var(--space-extra-large);
  }
}

.oc-fields__wrapper {
  margin: 0 !important;
}

// Todo: permitir mapear através do autocomplete
.z-icon__svg.fas.fa-check-circle{
  color: var(--color-support-success-deep);
}

.oc-fields__autocomplete {
  --autocomplete-result-height: 200px;
}

.oc-fields__autocomplete-item {
  padding: var(--space-medium);
  border-top: var(--size-nano) solid var(--color-gray-050);
}

.oc-fields__autocomplete-item--small-vertical-padding {
  padding-bottom: var(--space-small);
  padding-top: var(--space-small);
}

.oc-fields__autocomplete-item--is-highlighted{
  background-color: var(--color-font-interactive-normal);
}

.oc-fields__autocomplete-item-text {
  margin-bottom: 0;
}

.oc-course-selection__footer {
  display: flex;
  justify-content: flex-end;
}
</style>
