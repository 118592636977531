<template>
  <div class="create-admission-config-modal-review__container">
    <div>
      <z-text
        color="major"
        size="small"
        weight="bold"
      >
        DOCUMENTOS OBRIGATÓRIOS
      </z-text>

      <div class="create-admission-config-modal-review__document-content">
        <div
          class="create-admission-config-modal-review__student-documents-content"
          v-if="hasSelectedStudentDocuments"
        >
          <z-text
            color="major"
            size="small"
            weight="bold"
            tag="span"
          >
            Aluno
          </z-text>

          <ul class="create-admission-config-modal-review__list">
            <li
              class="create-admission-config-modal-review__list-item"
              v-for="(studentDocument, index) in selectedStudentDocuments"
              :key="index"
            >
              <z-text
                color="minor"
                size="small"
                tag="span"
              >
                {{ getDocumentText(studentDocument.value) }}
              </z-text>
            </li>
          </ul>
        </div>

        <div>
          <div v-if="hasSelectedGraduateDocuments">
            <z-text
              color="major"
              size="small"
              weight="bold"
              tag="span"
            >
              Pós-graduação
            </z-text>

            <ul class="create-admission-config-modal-review__list">
              <li
                class="create-admission-config-modal-review__list-item"
                v-for="(graduateDocument, index) in selectedGraduateDocuments"
                :key="index"
              >
                <z-text
                  color="minor"
                  size="small"
                  tag="span"
                >
                  {{ getDocumentText(graduateDocument.value) }}
                </z-text>
              </li>
            </ul>
          </div>

          <div v-if="hasSelectedParentDocuments">
            <z-text
              color="major"
              size="small"
              weight="bold"
              tag="span"
            >
              Responsável
            </z-text>

            <ul class="create-admission-config-modal-review__list">
              <li
                class="create-admission-config-modal-review__list-item"
                v-for="(parentDocument, index) in selectedParentDocuments"
                :key="index"
              >
                <z-text
                  color="minor"
                  size="small"
                  tag="span"
                >
                  {{ getDocumentText(parentDocument.value) }}
                </z-text>
              </li>
            </ul>
          </div>

          <div v-if="hasSelectedCustomFields">
            <z-text
              color="major"
              size="small"
              weight="bold"
              tag="span"
            >
              Campos Customizados
            </z-text>

            <ul class="create-admission-config-modal-review__list">
              <li
                class="create-admission-config-modal-review__list-item"
                v-for="(customField, index) in selectedCustomFields"
                :key="index"
              >
                <z-text
                  color="minor"
                  size="small"
                  tag="span"
                >
                  {{ getCustomFieldText(customField.value) }}
                </z-text>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <z-divider />

    <div class="create-admission-config-modal-review__cut-score-content">
      <z-text
        color="major"
        size="small"
        weight="bold"
      >
        NOTA DE CORTE
      </z-text>

      <div class="create-admission-config-modal-review__field">
        <z-text
          color="major"
          size="small"
          weight="bold"
          tag="span"
        >
          Nota de corte do ENEM
        </z-text>

        <z-text
          color="minor"
          size="small"
        >
          {{ enemCutScoreText }}
        </z-text>
      </div>

      <div class="create-admission-config-modal-review__field">
        <z-text
          color="major"
          size="small"
          weight="bold"
          tag="span"
        >
          Quantidade de anos para aceitação do ENEM
        </z-text>

        <z-text
          color="minor"
          size="small"
        >
          {{ enemCutYearText }}
        </z-text>
      </div>

      <div class="create-admission-config-modal-review__field">
        <z-text
          color="major"
          size="small"
          weight="bold"
          tag="span"
        >
          Nota de corte da redação
        </z-text>

        <z-text
          color="minor"
          size="small"
        >
          {{ digitalAdmissionCutScoreText }}
        </z-text>

        <div class="create-admission-config-modal-review__field">
          <z-text
            color="major"
            size="small"
            weight="bold"
            tag="span"
          >
            Quantidade de anos para aceitação de reaproveitamento da redação
          </z-text>

          <z-text
            color="minor"
            size="small"
          >
            {{ essayCutYearText }}
          </z-text>
        </div>
      </div>
    </div>

    <z-divider />

    <div class="create-admission-config-modal-review__contract-content">
      <z-text
        color="major"
        size="small"
        weight="bold"
      >
        Contrato e Matrícula Direta
      </z-text>

      <div class="create-admission-config-modal-review__field">
        <z-text
          color="major"
          size="small"
          weight="bold"
          tag="span"
        >
          Chave do Clicksign
        </z-text>

        <z-text
          color="minor"
          size="small"
        >
          {{ clicksignTemplateId }}
        </z-text>

        <div v-if="hasDirectEnrollment">
          <z-text
            color="major"
            size="small"
            weight="bold"
          >
            Matrícula Direta
          </z-text>

          <z-text
            v-if="directEnrollmentLabel"
            color="minor"
            size="small"
          >
            Identificador: <strong>{{ directEnrollmentLabel }}</strong>
          </z-text>

          <z-text
            color="minor"
            size="small"
          >
            Filtro de integração com Billing: <strong>{{ getBooleanText(directEnrollmentBillingIntegration) }}</strong>
          </z-text>

          <z-text
            color="minor"
            size="small"
          >
            Filtro de número mínimo de bolsas: <strong>{{ getBooleanText(directEnrollmentMinSeats) }}</strong>
          </z-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { AdmissionCustomFieldsBusiness, AdmissionDocumentBusiness } from '@quero-turbo/business';

export default {
  computed: {
    ...mapFields('CreateAdmissionConfigModalModule', [
      'enemCutScore',
      'enemCutYear',
      'essayCutYear',
      'digitalAdmissionCutScore',
      'clicksignTemplateId',
      'hasDirectEnrollment',
      'directEnrollmentBillingIntegration',
      'directEnrollmentMinSeats',
      'directEnrollmentLabel',
    ]),
    ...mapGetters('CreateAdmissionConfigModalModule', [
      'selectedStudentDocuments',
      'selectedParentDocuments',
      'selectedGraduateDocuments',
      'selectedCustomFields',
    ]),
    enemCutScoreText() {
      if (this.enemCutScore) return this.enemCutScore;
      return '-';
    },
    enemCutYearText() {
      if (this.enemCutYear) return this.enemCutYear;
      return '-';
    },
    essayCutYearText() {
      return this.essayCutYear || '-';
    },
    digitalAdmissionCutScoreText() {
      if (this.digitalAdmissionCutScore) return this.digitalAdmissionCutScore;
      return '-';
    },
    hasSelectedStudentDocuments() {
      return this.selectedStudentDocuments.length > 0;
    },
    hasSelectedParentDocuments() {
      return this.selectedParentDocuments.length > 0;
    },
    hasSelectedGraduateDocuments() {
      return this.selectedGraduateDocuments.length > 0;
    },
    hasSelectedCustomFields() {
      return this.selectedCustomFields.length > 0;
    },
  },
  methods: {
    getDocumentText(key) {
      return AdmissionDocumentBusiness.getLabel(key);
    },
    getCustomFieldText(key) {
      return AdmissionCustomFieldsBusiness.getLabel(key);
    },
    getBooleanText(boolean) {
      if (boolean) {
        return 'Sim';
      }
      return 'Não';
    },
  },
};
</script>

<style lang="scss">
@import "@quero/zilla-core/src/utils/_index.scss";

.create-admission-config-modal-review__container {
  display: flex;
  justify-content: space-between;
  max-height: 408px;
  overflow: auto;
  padding-right: var(--space-extra-jumbo);
}

.create-admission-config-modal-review__document-content {
  display: flex;
  justify-content: space-between;
}

.create-admission-config-modal-review__contract-content,
.create-admission-config-modal-review__cut-score-content {
  display: flex;
  flex-direction: column;
}

.create-admission-config-modal-review__list {
  list-style-type: none;
  max-width: 232px;
  padding: 0;
}

.create-admission-config-modal-review__student-documents-content {
  margin-right: var(--space-jumbo);
}

.create-admission-config-modal-review__list,
.create-admission-config-modal-review__field {
  margin-bottom: var(--space-medium);
}

.create-admission-config-modal-review__list-item {
  margin-bottom: var(--space-micro);
}
</style>
