import template
  from '@stock/legacy/components/modals/offer-list-actions/inline-steps/discountChange/steps/confirmation/template.ejs';
import Step from '@stock/legacy/components/stepper/step';
import AjaxRequest from '@shared/utils/ajax-request';
import NumberFormatter from '@shared/utils/number_format';

const CHANGE_DISCOUNT_URL = '/ui/ofertas/single/alterar-preco';
const numberFormatter = new NumberFormatter();

export class ConfirmationStep extends Step {
  constructor(offer) {
    super({
      template,
      attributes: { offer },
    });
  }

  afterRender() {
    $('.js-back-button').click(() => super.previous());
    this.setupForm();
  }

  setupForm() {
    const { offer: { id }, newDiscount } = this.getAttributes();
    $('.js-confirm-form').one('submit', (event) => {
      event.preventDefault();
      const request = new AjaxRequest(CHANGE_DISCOUNT_URL)
        .on('success', (response) => {
          const { redirect } = response.data;
          super.setAttribute({ success: true, redirect });
        })
        .on('error', () => super.setAttribute({ success: false }))
        .on('complete', () => super.next());

      request.postJson({
        sl: { offer_ids: [id] },
        edited_field: 'discountPercentage',
        discount_percentage: newDiscount,
      });
      return false;
    });
  }

  render(step = {}) {
    this.setAttribute({ newDiscount: step.newDiscount });
    const { discount, offeredPrice, fullPrice } = this.getAttributes().offer;
    const changesAttr = {
      currentDiscount: numberFormatter.toPercentage(discount, { precision: 2 }),
      newDiscount: numberFormatter.toPercentage(step.newDiscount, { precision: 2 }),
      currentOfferedPrice: numberFormatter.toPrice(offeredPrice),
      newOfferedPrice: numberFormatter.toPrice(fullPrice * (1 - (step.newDiscount / 100))),
    };
    return this.template({
      step,
      ...super.getAttributes(),
      ...changesAttr,
    });
  }
}
