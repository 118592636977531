<template>
  <div>
    <UPAModalHeader
      :name="offerFilterModalName"
      :title="offerFilterModalTitle"
    />

    <z-card
      tag="ul"
      class="show-brg-with-data-offer-filter-modal__card"
    >
      <li
        v-for="(filterItem, index) in filterItems"
        :key="index"
        class="show-brg-with-data-offer-filter-modal__card-item"
      >
        <z-title class="show-brg-with-data-offer-filter-modal__card-item-title">
          {{ EducationBusiness.getEducationDictionaryByKey(filterItem[0]) || filterItem[0] }}
        </z-title>

        <z-text
          class="show-brg-with-data-offer-filter-modal__card-item-description"
          tag="span"
        >
          {{ OfferFilterBusiness.parseValues(filterItem[1]) || filterItem[1] }}
        </z-text>
      </li>

      <pre
        v-if="!offerFilterHasRawFilters"
        class="show-brg-with-data-offer-filter-modal__card-item-code"
      >
        {{ prittier(offerFilter) }}
      </pre>
    </z-card>
  </div>
</template>

<script>
import { OfferFilterBusiness } from '@quero-turbo/business';
import { EducationBusiness } from '@shared/business';
import { UPAModalHeader } from '@shared/components/upa-modal';

export default {
  components: {
    UPAModalHeader,
  },
  props: {
    offerFilter: {
      type: Object,
      required: true,
    },
  },
  computed: {
    EducationBusiness: () => EducationBusiness,
    OfferFilterBusiness: () => OfferFilterBusiness,
    offerFilterModalName() {
      return 'Filtro de ofertas';
    },
    offerFilterModalTitle() {
      return this.OfferFilterBusiness.showMainDescription(this.offerFilter);
    },
    offerFilterHasRawFilters() {
      return Boolean(this.offerFilter.rawFilters);
    },
    onlyFilledFields() {
      if (!this.offerFilterHasRawFilters) return {};
      return this.OfferFilterBusiness.onlyFilledFields(this.offerFilter.rawFilters);
    },
    filterItems() {
      return Object.entries(this.onlyFilledFields);
    },
  },
  methods: {
    prittier(object) {
      return JSON.stringify(object, null, '\t');
    },
  },
};
</script>

<style lang="scss">
.show-brg-with-data-offer-filter-modal__card {
  list-style-type: none;
}

.show-brg-with-data-offer-filter-modal__card-item {
  display: flex;
  padding: var(--space-small) 0;

  &:not(:last-of-type) {
    border-bottom: var(--size-border-width-small) solid var(--color-gray-100);
  }
}

.show-brg-with-data-offer-filter-modal__card-item-description {
  text-align: right;
}

.show-brg-with-data-offer-filter-modal__card-item-title,
.show-brg-with-data-offer-filter-modal__card-item-description {
  flex: 1;
}

.show-brg-with-data-offer-filter-modal__card-item-code {
  max-height: 240px;
}
</style>
