<template>
  <GridContainer>
    <GridItem>
      <HeaderBox title="Painel da configuração" />
    </GridItem>

    <GridItem>
      <ShowBRGPainel />
    </GridItem>
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { HeaderBox } from '@shared/components/header-box';
import { ShowBRGPainel } from '@quero-turbo/modules/brg-components';

export default {
  components: {
    GridContainer,
    GridItem,
    HeaderBox,
    ShowBRGPainel,
  },
};
</script>
