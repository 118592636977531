import Vue from 'vue';
import VueTour from 'vue-tour';
import { offerListTracking } from '@stock/metrics';
import { notifierMixin } from '@stock/utils/mixins/notifier';
import { store } from '@stock/store';
import { mapActions } from 'vuex';
import { RollbarService } from '@shared/utils/rollbar-service';
import { ValidatePlugin } from '@shared/utils/vue/plugins/validate-plugin';
import { FiltersPlugin } from '@shared/utils/vue/plugins/filters-plugin';
import { ZillaPlugin } from '@shared/utils/vue/plugins/zilla-plugin';
import { ComponentsPlugin } from '@shared/utils/vue/plugins/components-plugin';
import { TrackerPlugin } from '@shared/utils/vue/plugins/tracker-plugin';
import { OfferCreationModal } from '@stock/modules/offer-creation';
import { LocalStorageStock } from '@stock/utils/local-storage-stock';
import { ZToast } from '@shared/legacy/components/zilla/z-toast';
import DisclaimerOffers from './disclaimer-offers';
import CardCreateOffers from './card-create-offers';

export function OfferListPage({ pageInstance }) {
  return new Promise((resolve) => {
    Vue.use(TrackerPlugin);
    Vue.use(ValidatePlugin);
    Vue.use(VueTour);
    Vue.use(FiltersPlugin);
    Vue.use(ZillaPlugin);
    Vue.use(ComponentsPlugin);

    new Vue({
      el: '#offer-list-page',
      store,
      components: {
        CardCreateOffers,
        DisclaimerOffers,
        OfferCreationModal,
      },
      mixins: [notifierMixin],
      data: {
        HAS_RECENT_RESTOCK_STORAGE: '[offer_restock_widget] has_recent_restock',
        forcedInProgressRestockState: false,
        visibilityOfTheOfferCreationModal: false,
        visibilityOfferRestockBatch: false,
        offersUuidFilter: [],
        widgetFilterType: null,
      },
      computed: {
        selectedWidget() {
          if (this.widgetFilterType === 'stockoutAndFewSeats' || this.widgetFilterType === 'fewSeats') {
            return 'restockWidget';
          }

          if (this.widgetFilterType === 'stockOptimized') return 'optimizeOfferWidget';

          return 'withoutWidget';
        },
        filteredAction() {
          switch (this.selectedWidget) {
            case 'optimizeOfferWidget':
              return 'optimize';
            case 'restockWidget':
              return 'restock';
            case 'withoutWidget':
              return null;
            default:
              RollbarService.getInstance().notifyError(
                `${this.selectedWidget} is an invalid widget value`,
              );
              return null;
          }
        },
        visibilityOfTheWidgetDisclaimer() {
          return this.filteredAction;
        },
      },
      mounted() {
        resolve();
        this.setup({
          universityId: this.$refs.$universityId.value,
          universityLogoUrl: this.$refs.$universityLogoUrl.value,
        });
        pageInstance.initialize();
        this.setupOfflineMode();
      },
      methods: {
        ...mapActions('SessionModule', ['setup']),
        openOfferCreationModal() {
          hj('trigger', 'opened-offer-creation-modal');
          this.visibilityOfTheOfferCreationModal = true;
        },
        openOfferCreationBatchPage() {
          window.open('ofertas/criacao-de-ofertas', '_self');
        },
        setupOfflineMode() {
          window.addEventListener('offline', (_event) => {
            this.notifyOfflineMessage();
          });
        },
        closeOfferCreationModal() {
          this.visibilityOfTheOfferCreationModal = false;
        },
        onSuccessOfferRestockBatch() {
          LocalStorageStock.set(this.HAS_RECENT_RESTOCK_STORAGE, true, 60000);
          this.forcedInProgressRestockState = true;
          this.closeOfferRestockBatch();
          this.showCreateActionSuccessMessage();
        },
        onErrorOfferRestockBatch() {
          this.closeOfferRestockBatch();
          this.showCreateActionErrorMessage();
        },
        showCreateActionErrorMessage() {
          ZToast.showAlert('Sua solicitação de alteração não pode ser concluída', {
            type: 'danger',
          });
        },
        showCreateActionSuccessMessage() {
          ZToast.showAlert('Sua solicitação está sendo processada', {
            type: 'success',
          });
        },
        closeOfferRestockBatch() {
          this.clearOffersUuidFilter();
          this.visibilityOfferRestockBatch = false;
        },
        clearFilter() {
          pageInstance.clearFilter();
          this.clearOffersUuidFilter();
          this.widgetFilterType = null;
        },
        trackDisclaimerClose() {
          if (this.selectedWidget === 'restockWidget') {
            return offerListTracking.removeWidgetRestockFilter();
          }

          if (this.selectedWidget === 'optimizeOfferWidget') {
            return offerListTracking.removeWidgetOptimizeOfferFilter();
          }

          return undefined;
        },
        async handleWidgetDisclaimerClick() {
          this.trackDisclaimerClose();
          this.clearFilter();
          await this.$nextTick();
          pageInstance.submitSearchForm();
        },
        async applyWidgetFilter(status) {
          pageInstance.clearFilter();
          this.widgetFilterType = status;
          await this.$nextTick();
          pageInstance.submitSearchForm();
        },
        clearOffersUuidFilter() {
          this.offersUuidFilter = [];
        },
        async handleRestockWidgetFilter() {
          offerListTracking.applyWidgetRestockFilter();
          hj('trigger', 'clicked-restock-widget');
          this.visibilityOfferRestockBatch = true;
        },
        async handleOptimizeWidgetFilter({ status }) {
          offerListTracking.applyWidgetOptimizeOfferFilter();
          hj('trigger', 'clicked-optimize-offer-widget');
          this.applyWidgetFilter(status);
        },
      },
    });
  });
}
