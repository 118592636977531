module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="batch-stock-action-confirmation__body modal-body">\n  <header class="batch-stock-action-confirmation__header">\n    <h2 class="batch-stock-action-confirmation__title gq-title">Despublicação de oferta</h2>\n  </header>\n\n  <section>\n    <h3 class="batch-stock-action-confirmation__subtitle gq-title gq-title--micro gq-title--strong">\n      Selecione o que você deseja despublicar:\n    </h3>\n\n    <section class="inline-stock-action-edit__radios">\n      <label class="inline-stock-action-edit__radio-item radiobox">\n        <input form="disablement-step-confirm" type="radio" class="radiobox__input js-disablement-mode" name="disablement-mode"\n          checked="checked" value="disableVisibleOffer" />\n        <span class="radiobox__text" aria-label="Desconto ativo" title="Desconto ativo">\n          Desconto selecionado\n        </span>\n      </label>\n\n      <label class="inline-stock-action-edit__radio-item radiobox">\n        <input form="disablement-step-confirm" type="radio" class="radiobox__input js-disablement-mode" name="disablement-mode"\n          value="disableAllOffers" />\n        <span class="radiobox__text" aria-label="Todos os Descontos para essa oferta"\n          title="Todos os Descontos para essa oferta">\n          Todos os descontos associados a esse curso\n        </span>\n      </label>\n    </section>\n\n    <h3 class="batch-stock-action-confirmation__subtitle gq-title gq-title--micro gq-title--strong">\n      Detalhes da oferta\n    </h3>\n\n    <ul class="batch-stock-action-confirmation__fiters--medium key-value-list key-value-list--column">\n      <li class="key-value-list__item">\n        <h4 class="key-value-list__key gq-text gq-text--bold">Curso</h4>\n        <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.courseName )) == null ? '' : __t) +
'</p>\n      </li>\n\n      <li class="key-value-list__item">\n        <h4 class="key-value-list__key gq-text gq-text--bold">Campus</h4>\n        <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.campus )) == null ? '' : __t) +
'</p>\n      </li>\n\n      <li class="key-value-list__item">\n        <h4 class="key-value-list__key gq-text gq-text--bold">Nível</h4>\n        <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.level )) == null ? '' : __t) +
'</p>\n      </li>\n\n      <li class="key-value-list__item">\n        <h4 class="key-value-list__key gq-text gq-text--bold">Modalidade</h4>\n        <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.kind )) == null ? '' : __t) +
'</p>\n      </li>\n\n      <li class="key-value-list__item">\n        <h4 class="key-value-list__key gq-text gq-text--bold">Turno</h4>\n        <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.shift )) == null ? '' : __t) +
'</p>\n      </li>\n\n      <li class="key-value-list__item">\n        <h4 class="key-value-list__key gq-text gq-text--bold">Captação</h4>\n        <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.enrollmentSemester )) == null ? '' : __t) +
'</p>\n      </li>\n\n      <li class="key-value-list__item">\n        <h4 class="key-value-list__key gq-text gq-text--bold">Desconto</h4>\n        <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( offer.discount )) == null ? '' : __t) +
'%</p>\n      </li>\n\n      <li class="key-value-list__item">\n        <h4 class="key-value-list__key gq-text gq-text--bold">Valor total</h4>\n        <p class="key-value-list__value gq-text gq-text--small">R$ ' +
((__t = ( offer.fullPrice )) == null ? '' : __t) +
'</p>\n      </li>\n\n      <li class="key-value-list__item">\n        <h4 class="key-value-list__key gq-text gq-text--bold">Valor com desconto</h4>\n        <p class="key-value-list__value gq-text gq-text--small">R$ ' +
((__t = ( offer.offeredPrice )) == null ? '' : __t) +
'</p>\n      </li>\n    </ul>\n  </section>\n</div>\n\n<div class="batch-stock-action-confirmation__alert-message js-alert-disablement hidden">\n  <i class="\n    far\n    fa-exclamation-circle\n    batch-stock-action-confirmation__alert-message-icon\n  "></i>\n\n  <p class="\n    gq-text\n    gq-text--small\n    gq-text--strong\n  ">Ao despublicar todos os descontos para este curso, você removerá todas as ofertas e elas não ficarão mais visíveis no Quero Bolsa.</p>\n</div>\n\n<footer class="batch-stock-action-confirmation__footer modal-footer">\n  <form id="disablement-step-confirm">\n    <button type="submit"\n      class="batch-stock-action-confirmation__footer-action js-back-button btn btn-secondary reverse">\n      Voltar\n    </button>\n    <button type="submit" class="batch-stock-action-confirmation__footer-action js-submit-button btn btn-primary">\n      Despublicar oferta\n    </button>\n  </form>\n</footer>\n';

}
return __p
}