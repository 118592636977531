import { queroAlunosAPI } from '@stock/api/quero-alunos-api.js';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Show offer creation spreadsheets from university
 *
 * @typedef Success
 * @property {object<OfferCreationSheet>[]} OfferCreationSheets
 *
 * @typedef OfferCreationSheet
 * @property {Object} OfferCreationSheet
 * @property {string} OfferCreationSheet.createdAt
 * @property {number} OfferCreationSheet.universityUserId
 * @property {string} OfferCreationSheet.universityUserName
 * @property {string} OfferCreationSheet.name
 * @property {string} OfferCreationSheet.status ['creating', 'created', 'editing', 'pending', 'finished', 'error', 'failed']
 * @property {string} OfferCreationSheet.uuid
 * @property {string} OfferCreationSheet.sheetUrl
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function showOfferCreationSpreadsheet({ id }) {
  return queroAlunosAPI.showOfferCreationSpreadsheet(id)
    .then(({ data }) => ({ offerCreationSheet: data.offerCreationSheet }))
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[createOffers] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

export {
  showOfferCreationSpreadsheet,
};
