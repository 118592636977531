import Step from '@stock/legacy/components/stepper/step';
import template from '@stock/legacy/components/modals/offer-list-actions/inline-steps/stock-action/steps/action-detail/template.ejs';
import { RestockStepper } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/restock';
import { DisablementStepper } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/disablement';
import { PricingStepper } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/pricing';
import { DiscountChangeStepper } from '@stock/legacy/components/modals/offer-list-actions/inline-steps/discountChange';
import { inlineOperationsTracking, commonsTracking } from '@stock/metrics';
import { competitiveness, ranking } from '@stock/business';
import { routes } from '@university-panel/utils/routes';
import { scope } from '@shared/utils/jquery';
import { findOfferByUuid } from '@stock/services/find-offer-by-uuid';
import jQuery from 'jquery';

const $ = scope('.js-action-detail-step');
const steppersMap = {
  restock: RestockStepper,
  'discount-change': DiscountChangeStepper,
  pricing: PricingStepper,
  disablement: DisablementStepper,
};

export class ActionDetailStep extends Step {
  constructor({ offerTableItem, permissions }) {
    super({ template });
    this.offerTableItem = offerTableItem;
    this.permissions = permissions;
    const selectedWidget = jQuery('.js-selected-widget').val();
    this.defaultEventProps = { source: 'action-details', selectedWidget };
  }

  render() {
    const helpers = {
      ranking,
      competitiveness,
      routes,
    };
    return this.template({
      ...this.offerTableItem,
      permissions: this.permissions,
      ...helpers,
    });
  }

  afterRender() {
    this._setupRestockButton();
    this._setupPricingButton();
    this._setupChangeDiscountButton();
    this._setupDisablementButton();
  }

  _setupRestockButton() {
    $('.js-restock-button').on('click', (event) => {
      const eventProps = { ...this.defaultEventProps, withRecommendedValue: false };
      inlineOperationsTracking.trackShowRestockModal(eventProps);

      this.handlerActionClick(event, 'restock', { source: this.defaultEventProps.source });
    });

    $('.js-view-qb-offer-search').on('click', () => {
      commonsTracking.trackShowCourseInQB();
    });
  }

  _setupPricingButton() {
    $('.js-change-pricing-button').on('click', (event) => {
      inlineOperationsTracking.trackShowPricingModal(this.defaultEventProps);
      this.handlerActionClick(event, 'pricing', {});
    });
  }

  _setupChangeDiscountButton() {
    $('.js-change-discount-button').on('click', (event) => {
      inlineOperationsTracking.trackShowDiscountChangeModal(this.defaultEventProps);
      this.handlerActionClick(event, 'discount-change', {});
    });
  }

  _setupDisablementButton() {
    $('.js-disablement-button').on('click', (event) => {
      inlineOperationsTracking.trackShowDisablementModal(this.defaultEventProps);
      this.handlerActionClick(event, 'disablement', {});
    });
  }

  async handlerActionClick(event, actionName, eventParams) {
    const stepperClass = steppersMap[actionName];
    if (stepperClass) {
      event.preventDefault();
      const $offerLine = $(event.target).parents('.js-offer-line');
      const uuid = $offerLine.data('offerUuid');
      const offer = await findOfferByUuid({ uuid });
      this.event.emit('step.action', { params: { ...eventParams, offer }, stepperClass });
    } else {
      throw new Error(`#handlerActionClick - The actionName: ${actionName} isn't mapped.`);
    }
  }
}
