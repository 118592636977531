import template
  from '@stock/legacy/components/modals/offer-list-actions/inline-steps/restock/steps/confirmation/template.ejs';
import Step from '@stock/legacy/components/stepper/step';
import AjaxRequest from '@shared/utils/ajax-request';
import { scope } from '@shared/utils/jquery';

const ADD_SEATS_URL = '/ui/ofertas/reposicao/single/repor';
const $ = scope('.js-confirmation-step-wrapper');

export class ConfirmationStep extends Step {
  constructor(offer, recommendedValue, source) {
    super({
      template,
      attributes: { offer },
    });
    this.request = this.setupRequest();
    this.recommendedValue = recommendedValue;
    this.source = source;
  }

  afterRender() {
    $('.js-back-button').click(() => super.previous());
    this.setupForm();
  }

  setupForm() {
    const { offer: { id }, seatsAmount } = this.getAttributes();
    $('.js-confirm-form').one('submit', (event) => {
      event.preventDefault();
      this.request.postJson({
        sl: { offer_ids: [id] },
        amount_seats: +seatsAmount,
        with_recommended_value: !!this.recommendedValue,
        source: this.source,
      });
      return false;
    });
  }

  setupRequest() {
    return new AjaxRequest(ADD_SEATS_URL)
      .on('success', (response) => {
        const { redirect } = response.data;
        super.setAttribute({ success: true, redirect });
      })
      .on('error', () => super.setAttribute({ success: false }))
      .on('complete', () => super.next());
  }

  render(step = {}) {
    this.setAttribute({ seatsAmount: step.seatsAmount });
    const { currentTotalSeats, currentAvailableSeats } = this.getAttributes().offer;
    const changesAttr = {
      currentAvailableSeats,
      currentTotalSeats,
      newVirtualSeats: +currentAvailableSeats + +step.seatsAmount,
      newTotalSeats: +currentTotalSeats + +step.seatsAmount,
    };
    return this.template({
      step,
      ...changesAttr,
      ...super.getAttributes(),
    });
  }
}
