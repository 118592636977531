module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="offers-table-more-details js-action-detail-step">\n  <header class="offers-table-more-details__header">\n    <div class="gq-title">' +
((__t = ( course.name )) == null ? '' : __t) +
'</div>\n  </header>\n\n  <div class="offers-table-more-details__rank-details">\n    <p class="offers-table-more-details__section-title gq-title gq-title--nano">\n      Posicionamento no marketplace\n    </p>\n\n    <ul class="offers-table-more-details__rank-data">\n      <li class="offers-table-more-details__rank-data-item gq-text gq-text--medium">\n        Página:\n        <strong>' +
((__t = ( ranking.getPageOfRanking(rank) )) == null ? '' : __t) +
'</strong>\n      </li>\n\n      <li class="offers-table-more-details__rank-data-item gq-text gq-text--medium">\n        Posição na Página:\n        <strong>' +
((__t = ( ranking.getPositionInRanking(rank) )) == null ? '' : __t) +
'</strong>\n        ';
 if(rank.position) { ;
__p += '\n          <i class="\n            fas fa-xs fa-' +
((__t = ( ranking.getIconOfRanking(rank) )) == null ? '' : __t) +
'\n            offers-table-more-details__rank-data-icon\n            offers-table-more-details__rank-data-icon--' +
((__t = ( ranking.getColorOfRanking(rank) )) == null ? '' : __t) +
'\n          "></i>\n        ';
 } ;
__p += '\n      </li>\n    </ul>\n\n    <div class="offers-table-more-details__rank-link">\n      ';
 if(qbSearchUrl) { ;
__p += '\n        <a\n          href="' +
((__t = ( qbSearchUrl )) == null ? '' : __t) +
'"\n          class="\n            offers-table-more-details__rank-link-item\n            gq-link\n            gq-link--small\n            js-view-qb-offer-search\n          "\n          target="_blank"\n          rel="noopener noreferrer"\n        >\n          <i class="offers-table-more-details__rank-link-icon fas fa-s fa-browser"></i>\n          Visualizar curso no Quero Bolsa\n        </a>\n      ';
 } ;
__p += '\n    </div>\n  </div>\n\n  <div class="offers-table-more-details__course-details">\n    <div class="offers-table-more-details__section-title gq-title gq-title--nano">\n      Detalhes\n    </div>\n\n    <ul class="key-value-list key-value-list--column">\n      <li class="key-value-list__item">\n        <div class="key-value-list__key gq-text gq-text--small gq-text--bold">Campus</div>\n        <div class="key-value-list__value gq-text gq-text--small">' +
((__t = ( campus.name )) == null ? '' : __t) +
'</div>\n      </li>\n\n      <li class="key-value-list__item">\n        <div class="key-value-list__key gq-text gq-text--small gq-text--bold">Nível</div>\n        <div class="key-value-list__value gq-text gq-text--small">' +
((__t = ( course.level )) == null ? '' : __t) +
'</div>\n      </li>\n\n      <li class="key-value-list__item">\n        <div class="key-value-list__key gq-text gq-text--small gq-text--bold">Modalidade</div>\n        <div class="key-value-list__value gq-text gq-text--small">' +
((__t = ( course.kind )) == null ? '' : __t) +
'</div>\n      </li>\n\n      <li class="key-value-list__item">\n        <div class="key-value-list__key gq-text gq-text--small gq-text--bold">Turno</div>\n        <div class="key-value-list__value gq-text gq-text--small">' +
((__t = ( course.shift )) == null ? '' : __t) +
'</div>\n      </li>\n\n      <li class="key-value-list__item">\n        <div class="key-value-list__key gq-text gq-text--small gq-text--bold">Captação</div>\n        <div class="key-value-list__value gq-text gq-text--small">' +
((__t = ( enrollmentSemester )) == null ? '' : __t) +
'</div>\n      </li>\n\n      <li class="key-value-list__item">\n        <div class="key-value-list__key gq-text gq-text--small gq-text--bold">Mensalidade</div>\n        <div class="key-value-list__value gq-text gq-text--small">' +
((__t = ( fullPrice )) == null ? '' : __t) +
'</div>\n      </li>\n\n    </ul>\n  </div>\n\n  <div class="offers-table-more-details__offer-details">\n    <div class="offers-table-more-details__section-title gq-title gq-title--nano">\n      Disponibilidade em estoque\n    </div>\n\n    <section class="s-offer-table s-offer-table--small s-offer-table--more-details-modal">\n      <ul class="s-offer-table__row s-offer-table__row--header">\n        <li class="s-offer-table__data s-offer-table__data--header">\n          <h6 class="gq-text gq-text--bold gq-text--small">\n            Valor\n          </h6>\n        </li>\n\n        <li class="s-offer-table__data s-offer-table__data--header">\n          <h6 class="gq-text gq-text--bold gq-text--small">\n            Vagas disponíveis\n          </h6>\n        </li>\n\n        <li class="s-offer-table__data s-offer-table__data--header">\n          <h6 class="gq-text gq-text--bold gq-text--small">\n            Ações\n          </h6>\n        </li>\n      </ul>\n\n      ';
 offers.forEach(function(offer){ ;
__p += '\n        <ul class="s-offer-table__row s-offer-table__row--small js-offer-line" data-offer-uuid="' +
((__t = ( offer.uuid )) == null ? '' : __t) +
'">\n          <li class="s-offer-table__data">\n            <div>\n              <span class="gq-text gq-text--small gq-text--dark-color-fix">\n                <strong>' +
((__t = ( offer.offeredPrice )) == null ? '' : __t) +
'</strong> (' +
((__t = ( offer.discountPercentage )) == null ? '' : __t) +
')\n              </span>\n            </div>\n          </li>\n\n          <li class="s-offer-table__data">\n            <div>\n              ';
 if(offer.isStockout) { ;
__p += '\n                <header class="s-availability-data__stockout-text gq-text gq-text--small">\n                  <strong>Vagas esgotadas</strong>\n                </header>\n              ';
 } else { ;
__p += '\n                <header class="gq-text gq-text--small gq-text--dark-color-fix">\n                  ';
 if(offer.seatsBalance.limited) { ;
__p += '\n                    <strong>\n                      ' +
((__t = ( offer.seatsBalance.availableSeats )) == null ? '' : __t) +
'\n                    </strong> de\n                    ' +
((__t = ( offer.seatsBalance.totalSeats )) == null ? '' : __t) +
'\n                    vagas\n                  ';
 } else { ;
__p += '\n                    <strong>Vagas ilimitadas</strong>\n                  ';
 } ;
__p += '\n                </header>\n\n                ';
 if(offer.status === 'visible') { ;
__p += '\n                  <div class="s-availability-data__bar">\n                    <i\n                      class="s-availability-data__bar-completed"\n                      style="width: ' +
((__t = ( offer.seatsBalance.availableStockPercentage )) == null ? '' : __t) +
'"\n                    />\n                  </div>\n                ';
 } ;
__p += '\n              ';
 } ;
__p += '\n\n              <footer class="s-availability-data__footer">\n                ';
 if(offer.status === 'visible') { ;
__p += '\n                  <span class="gq-text gq-text--micro gq-text--bold gq-text--dark-color-fix">\n                    Oferta em venda\n                  </span>\n                ';
 } else if(!offer.isStockout) { ;
__p += '\n                  <span class="gq-text gq-text--micro gq-text--bold gq-text--light-color-fix">\n                    Oferta em espera\n                  </span>\n                ';
 } ;
__p += '\n              </footer>\n            </div>\n          </li>\n          <li class="s-offer-table__data">\n            <span class="dropdown js-dropdown">\n              <button class="\n                btn\n                btn-actions\n                dropdown-actions__button\n                dropdown-toggle\n                js-dropdown-actions__button\n              "\n                data-toggle="dropdown"\n              >\n                Ações\n                <i class="fas fa-chevron-down fa-xs"></i>\n              </button>\n              <ul\n                class="dropdown-menu dropdown-actions__menu dropdown-actions__menu--left js-dropdown-menu"\n                aria-labelledby="dropdownActions"\n              >\n                <li>\n                  <a href="#" class="dropdown-actions__menu-item gq-text gq-text--small js-restock-button">\n                    Alterar quantidade de bolsas\n                    ';
 if(offer.seatsBalance.sharedSeats || !offer.seatsBalance.limited) { ;
__p += '\n                      <i class="dropdown-actions__menu-item-icon fal fa-exclamation-circle"></i>\n                    ';
 } ;
__p += '\n                  </a>\n                </li>\n\n                <li>\n                  <a href="#" class="dropdown-actions__menu-item gq-text gq-text--small js-change-discount-button">\n                    Alterar Desconto\n                    ';
 if(offer.regressive_discount) { ;
__p += '\n                      <i class="dropdown-actions__menu-item-icon fal fa-exclamation-circle"></i>\n                    ';
 } ;
__p += '\n                  </a>\n                </li>\n\n                <li>\n                  <a href="#" class="gq-text gq-text--small s-more-actions-data__menu-item js-change-pricing-button">\n                    Alterar valor da oferta\n                    ';
 if(offer.regressive_discount) { ;
__p += '\n                      <i class="dropdown-actions__menu-item-icon fal fa-exclamation-circle"></i>\n                    ';
 } ;
__p += '\n                  </a>\n                </li>\n\n                ';
 if(permissions.disablement) { ;
__p += '\n                  <li>\n                    <a href="#" class="\n                      dropdown-actions__menu-item\n                      gq-text\n                      gq-text--small\n                      js-disablement-button\n                    ">\n                      Despublicar oferta\n                    </a>\n                  </li>\n                ';
 } ;
__p += '\n              </ul>\n            </span>\n          </li>\n        </ul>\n      ';
 }); ;
__p += '\n    </section>\n  </div>\n</div>\n';

}
return __p
}