import { UniversityPanelSharedAPI } from '@shared/api/university-panel-shared-api';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Search campus by specific term
 *
 * @param {Object} params
 * @property {text} params.term The term to be searched
 * @property {Array[number]} params.universityIds Ids to be searched
 *
 * @typedef Success
 * @property {string} message
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function searchCampus(term, universityIds = []) {
  return UniversityPanelSharedAPI.Autocompletes.searchCampus({ term, university_ids: universityIds })
    .then(({ data }) => {
      const results = data.results.map(campus => ({ ...campus, text: `${campus.text || campus.name} - ${campus.id}` }));
      return { results };
    })
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[searchCampus] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

export {
  searchCampus,
};
