import uniqueId from 'lodash/uniqueId';
import { getters } from './getters';

const state = () => ({
  currentStepName: 'step01',
  dealSelected: null,
  dealName: null,
  dealType: null,
  businessRuleGroup: {
    name: null,
    priority: null,
    validFrom: null,
    validUntil: null,
    pilot: false,
    contractUrl: null,
  },
  commissionAccountRule: {
    turboAccountId: null,
  },
  commissionTypeRules: [
    {
      commissionFeeTypeLabel: null,
      commissionFee: null,
      subsidy: null,
      id: uniqueId(),
    },
  ],
  commissionModelRule: {
    paymentModel: null,
    paymentTrigger: null,
  },
  commissionLadderRule: {
    lowerLimitRule: null,
    step: null,
    turboCommissionRuleCountId: null,
    upperLimitRule: null,
  },
});

const mutations = {
  setCurrentStepName(state, newName) {
    state.currentStepName = newName;
  },
  setDealSelected(state, dealSelected) {
    state.dealSelected = dealSelected;
  },
  setDealName(state, dealName) {
    state.dealName = dealName;
  },
  setDealType(state, dealType) {
    state.dealType = dealType;
  },
  setBRGName(state, name) {
    state.businessRuleGroup = {
      ...state.businessRuleGroup,
      name,
    };
  },
  setBRGPriority(state, priority) {
    state.businessRuleGroup = {
      ...state.businessRuleGroup,
      priority,
    };
  },
  setTurboAccountId(state, turboAccountId) {
    state.commissionAccountRule = {
      ...state.turboAccountId,
      turboAccountId,
    };
  },
  setBRGValidFrom(state, validFrom) {
    state.businessRuleGroup = {
      ...state.businessRuleGroup,
      validFrom,
    };
  },
  setBRGValidUntil(state, validUntil) {
    state.businessRuleGroup = {
      ...state.businessRuleGroup,
      validUntil,
    };
  },
  setBRGPilot(state, pilot) {
    state.businessRuleGroup = {
      ...state.businessRuleGroup,
      pilot,
    };
  },
  setBRGContractUrl(state, contractUrl) {
    state.businessRuleGroup = {
      ...state.businessRuleGroup,
      contractUrl,
    };
  },
  setCommissionPaymentModel(state, paymentModel) {
    state.commissionModelRule = {
      ...state.commissionModelRule,
      paymentModel,
    };
  },
  setCommissionPaymentTrigger(state, paymentTrigger) {
    state.commissionModelRule = {
      ...state.commissionModelRule,
      paymentTrigger,
    };
  },
  addCommissionTypeRule(state) {
    state.commissionTypeRules.push({
      commissionFeeTypeLabel: null,
      commissionFee: null,
      subsidy: null,
      id: uniqueId(),
    });
  },
  removeCommissionTypeRule(state, id) {
    const index = state.commissionTypeRules.findIndex(element => (element.id === id));
    state.commissionTypeRules.splice(index, 1);
  },
  updateCommissionTypeRule(state, { id, key, value }) {
    const index = state.commissionTypeRules.findIndex(element => (element.id === id));
    state.commissionTypeRules[index][key] = value;
  },
};

const QueroTurboActivationModalModule = {
  namespaced: true,
  state,
  mutations,
  getters,
};

export { QueroTurboActivationModalModule };
