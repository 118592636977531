<template>
  <z-input-field :label="label">
    <z-autocomplete
      :internal-search="false"
      :multiple="true"
      :options="kindList"
      :value="kindsSelected"
      @input="kindInputHandle($event)"
      @remove="kindRemoveHandle($event)"
      @select="kindSelectHandle($event)"
      hide-selected
      label-by="text"
    />

    <input
      :value="JSON.stringify(kindsSelected)"
      type="hidden"
      v-bind="$attrs"
    >
  </z-input-field>
</template>

<script>
import { searchKind } from '@shared/services/autocompletes/kind';

export default {
  props: {
    label: {
      type: String,
      default: 'Modalidade(s)',
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      kindList: [],
    };
  },
  computed: {
    kindsSelected() {
      return this.value;
    },
  },
  methods: {
    kindInputHandle($event) {
      this.requestSearchKind($event);
    },
    kindSelectHandle($event) {
      if (!$event) return;

      this.$emit('select', $event);
    },
    kindRemoveHandle($event) {
      this.$emit('remove', $event);
    },
    requestSearchKind(term) {
      searchKind(term).then(({ results }) => {
        this.kindList = results;
      });
    },
  },
  mounted() {
    this.requestSearchKind('');
  },
};
</script>
