import Vue from 'vue';

import { hasOnlyValidKeys, shouldBeOneOf } from '@stock/utils/validator';
import { price } from '@stock/business';
import { updateState } from '@stock/utils/store';

const getDefaultState = () => ({
  sharedCampus: {
    id: null,
    name: null,
    fullAddress: null,
    cityID: null,
    virtual: null,
  },
  sharedCourse: {
    name: null,
    parentLevel: null,
    level: null,
    kind: null,
    periodKind: null,
    maxPeriods: null,
  },
  sharedOffer: {
    offerSpecialConditions: [],
    totalHours: '',
    enrollmentSemesters: [],
  },
  universityOffersByShift: {},
});

const state = getDefaultState();

const universityOffersByShiftItemTemplate = {
  discountPercentage: null,
  fullPrice: null,
  totalSeats: null,
  offeredPrice: null,
  commercialDiscount: null,
  maxPayments: null,
  competitiveness: null,
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  updateSharedCourse(state, params) {
    const validKeys = ['name', 'level', 'parentLevel', 'kind', 'periodKind', 'maxPeriods'];

    if (hasOnlyValidKeys(validKeys)(params)) {
      updateState(state.sharedCourse, params);
    }
  },
  updateSharedCampus(state, params) {
    const validKeys = ['id', 'name', 'cityID', 'fullAddress', 'virtual'];

    if (hasOnlyValidKeys(validKeys)(params)) {
      updateState(state.sharedCampus, params);
    }
  },
  updateSharedOffer(state, params) {
    const validKeys = ['offerSpecialConditions', 'totalHours', 'enrollmentSemesters'];

    if (hasOnlyValidKeys(validKeys)(params)) {
      updateState(state.sharedOffer, params);
    }
  },
  resetUniversityOfferByShift(state) {
    state.universityOffersByShift = {};
  },
  addNewShifts(state, shift) {
    const validShifts = ['morning', 'afternoon', 'night', 'fullTime', 'virtual'];

    if (shouldBeOneOf(validShifts)(shift)) {
      Vue.set(
        state.universityOffersByShift,
        shift,
        {
          ...universityOffersByShiftItemTemplate,
          maxPayments: getUniversityOfferMaxPayments(state.sharedCourse),
        },
      );
    }
  },
  updateUniversityOffersByShift(state, { shift, ...params }) {
    const validKeys = [
      'maxPayments',
      'fullPrice',
      'commercialDiscount',
      'totalSeats',
      'discountPercentage',
      'offeredPrice',
      'competitiveness',
    ];
    if (hasOnlyValidKeys(validKeys)(params)) {
      Object.keys(params).forEach((key) => {
        state.universityOffersByShift[shift][key] = params[key];
      });
    }
  },
};

const actions = {
  resetOfferCreationState({ commit }) {
    commit('resetState');
  },
  setCourseSelectionData({ commit }, { course, campus }) {
    commit('updateSharedCourse', course);
    commit('updateSharedCampus', campus);
  },
  updateCourseDetailsData({ commit }, params) {
    const {
      kind,
      maxPeriods,
      periodKind,
      shifts,
      totalHours,
    } = params;

    const courseParams = { kind, maxPeriods, periodKind };
    const offerParams = { totalHours };

    // TODO: Add the validation of params values here

    commit('updateSharedCourse', courseParams);
    commit('updateSharedOffer', offerParams);
    commit('resetUniversityOfferByShift');
    shifts.forEach((shift) => {
      commit('addNewShifts', shift);
    });
  },
  updateUniversityOfferData({ commit }, {
    shift,
    ...params
  }) {
    const filledParams = getOnlyFilledParams(params);
    commit('updateUniversityOffersByShift', { ...filledParams, shift });
  },
  updateSharedOffer({ commit }, { offerSpecialConditions, enrollmentSemesters }) {
    commit('updateSharedOffer', {
      offerSpecialConditions,
      enrollmentSemesters,
    });
  },
};

const getUniversityOfferMaxPayments = (course) => {
  if (isCourseGraduate(course)) {
    return null;
  }
  return price.calculateMaxPayments(course);
};

const isCourseGraduate = course => course.parentLevel === 'Pós-graduação';

const getOnlyFilledParams = params => Object.keys(params).reduce((acc, key) => {
  if (params[key] !== undefined) {
    return { ...acc, [key]: params[key] };
  }
  return acc;
}, {});

const getters = {
  shifts(state) {
    return Object.keys(state.universityOffersByShift);
  },
  isGraduate(state) {
    return isCourseGraduate(state.sharedCourse);
  },
  isUndergrad(state) {
    return state.sharedCourse.parentLevel === 'Graduação';
  },
};

export const OfferCreationModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
