import NumberFormatter from '@shared/utils/number_format';
import template
  from '@stock/legacy/components/modals/offer-list-actions/inline-steps/pricing/steps/confirmation-with-recommendation/template.ejs';
import Step from '@stock/legacy/components/stepper/step';
import AjaxRequest from '@shared/utils/ajax-request';
import { offerListTracking } from '@stock/metrics';

const CHANGE_DISCOUNT_URL = '/ui/ofertas/single/alterar-preco';

export class ConfirmationWithRecommendationStep extends Step {
  constructor(offer, optimizedOfferedPrice) {
    super({ template, attributes: { offer, optimizedOfferedPrice } });
  }

  afterRender() {
    $('.js-back-button').click(() => super.previous());
    this.setupForm();
  }

  setupForm() {
    const { offer } = super.getAttributes();

    $('.js-confirm-form').one('submit', (e) => {
      e.preventDefault();
      const request = new AjaxRequest(CHANGE_DISCOUNT_URL)
        .on('success', (response) => {
          const { redirect } = response.data;
          super.setAttribute({ success: true, redirect });
          offerListTracking.sentRequisitionToOptimizeOffer();
        })
        .on('error', () => super.setAttribute({ success: false }))
        .on('complete', () => super.next());
      request.postJson({
        sl: {
          offer_ids: [offer.id],
        },
        offered_price: super.getAttributes().optimizedOfferedPrice,
        edited_field: 'offeredPrice',
      });
    });
  }

  render(step = {}) {
    const numberFormatter = new NumberFormatter();
    const { discount, fullPrice, offeredPrice } = super.getAttributes().offer;
    const { optimizedOfferedPrice } = super.getAttributes();
    const newDiscount = (1 - (optimizedOfferedPrice / fullPrice)) * 100;
    super.setAttribute({
      currentDiscount: numberFormatter.toPercentage(discount, { precision: 2 }),
      newDiscount: numberFormatter.toPercentage(newDiscount, { precision: 2 }),
      currentOfferedPrice: numberFormatter.toPrice(offeredPrice),
      newOfferedPrice: numberFormatter.toPrice(optimizedOfferedPrice),
      rawNewOfferedPrice: optimizedOfferedPrice,
    });
    return super.render(step);
  }
}
