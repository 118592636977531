import 'eonasdan-bootstrap-datetimepicker';

import Search from './search';
import FollowUpUpdater from './follow-up-updater';

class ScholarshipList {
  constructor(page) {
    if (page.length) {
      new Search(page);
      new FollowUpUpdater('#js-follow-up-updater');
    }
  }
}

export { ScholarshipList };
