const queroPoloTypeDictionary = {
  undefined: null,
  'Alugado única marca': 'rent_singlebrand',
  'Próprio única marca': 'own_singlebrand',
  'Alugado multimarca': 'rent_multibrand',
  'Próprio multimarca': 'own_multibrand',
};

const getQueroPoloTypeLabelDictionary = value => queroPoloTypeDictionary[value] || queroPoloTypeDictionary.undefined;

const getQueroPoloTypeKeyDictionary = value => Object.keys(queroPoloTypeDictionary).find(key => queroPoloTypeDictionary[key] === value) || null;

const getQueroPoloTypeList = () => Object.keys(queroPoloTypeDictionary).map(element => (element === 'undefined' ? '' : element));

export const DealBusiness = {
  getQueroPoloTypeLabelDictionary,
  getQueroPoloTypeKeyDictionary,
  getQueroPoloTypeList,
};
