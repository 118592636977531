import AjaxRequest from '@shared/utils/ajax-request.js';
import ajaxLoadingAnimation from '@stock/legacy/components/ajax-loading-animation';

const COUNT_OFFERS_URL = '/ui/ofertas/count';
const SMALLEST_SEATS_URL = '/ui/ofertas/smallest-seats';

export function offersCount(requestParams, callback) {
  const request = new AjaxRequest(COUNT_OFFERS_URL)
    .on('success', callback);

  ajaxLoadingAnimation(request);

  return request.postJson(requestParams.toJson());
}

export function smallestSeats(requestParams, callback) {
  const request = new AjaxRequest(SMALLEST_SEATS_URL)
    .on('success', callback);

  ajaxLoadingAnimation(request);

  return request.postJson(requestParams.toJson());
}
