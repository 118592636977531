module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 sort_icon_for = function(name, sort_desc) { ;
__p += '\n  ';
 klass = sort_desc == "true" ? "glyphicon-menu-up desc" : "glyphicon-menu-down" ;
__p += '\n  ';
 return '<span class="glyphicon ' + klass + ' sort" data-sort=' + name + '></span>' ;
__p += '\n';
 };
__p += '\n\n<table class="table table-hover table-condensed">\n  <thead>\n    <tr>\n      <th>Nome ' +
((__t = ( sort_icon_for("name", sort_desc) )) == null ? '' : __t) +
'</th>\n      <th>Email ' +
((__t = ( sort_icon_for("email", sort_desc) )) == null ? '' : __t) +
'</th>\n      <th>Grupo Educacional</th>\n      <th>Universidades</th>\n      <th>Campi</th>\n      <th>Funções</th>\n      <th>Qnt. acessos ' +
((__t = ( sort_icon_for("count_login", sort_desc) )) == null ? '' : __t) +
'</th>\n      <th>Último acesso' +
((__t = ( sort_icon_for("last_login", sort_desc) )) == null ? '' : __t) +
'</th>\n      <th>Ativo</th>\n      <th colspan="3"></th>\n    </tr>\n  </thead>\n  <tbody>\n    ';
 university_users.forEach(function(university_user) { ;
__p += '\n      <tr>\n        <td>' +
((__t = ( university_user.username )) == null ? '' : __t) +
'</td>\n        <td>' +
((__t = ( university_user.email )) == null ? '' : __t) +
'</td>\n        <td>' +
((__t = ( university_user.education_groups )) == null ? '' : __t) +
'</td>\n        <td>' +
((__t = ( university_user.universities )) == null ? '' : __t) +
'</td>\n        <td>' +
((__t = ( university_user.campuses )) == null ? '' : __t) +
'</td>\n        <td>' +
((__t = ( university_user.roles )) == null ? '' : __t) +
'</td>\n        <td>' +
((__t = ( university_user.count_login )) == null ? '' : __t) +
'</td>\n        <td>' +
((__t = ( university_user.last_login )) == null ? '' : __t) +
'</td>\n        <td>' +
((__t = ( university_user.active ? "Sim" : "Não" )) == null ? '' : __t) +
'</td>\n\n        ';
 if (university_user.edit) { ;
__p += '\n          <td class="text-center">\n            <z-button\n              tag="a"\n              href="' +
((__t = ( university_user.edit )) == null ? '' : __t) +
'"\n              id="edit-university-user-' +
((__t = ( university_user.uuid )) == null ? '' : __t) +
'"\n              size="small"\n              variant="secondary"\n            >\n              Editar\n            </z-button>\n          </td>\n      ';
 } else { ;
__p += '\n          <td></td>\n        ';
 } ;
__p += '\n\n        ';
 if (university_user.activate_html_link) { ;
__p += '\n          <td class="text-center">\n            ' +
((__t = ( university_user.activate_html_link )) == null ? '' : __t) +
'\n          </td>\n        ';
 } else { ;
__p += '\n          <td></td>\n        ';
 } ;
__p += '\n\n        ';
 if (university_user.is_admin) { ;
__p += '\n          <td class="text-center">\n            <z-button\n              tag="a"\n              href="/university_users/' +
((__t = ( university_user.uuid )) == null ? '' : __t) +
'/impersonate"\n              size="small"\n              variant="secondary"\n            >\n              Impersonar\n            </z-button>\n          </td>\n        ';
 } else { ;
__p += '\n          <td></td>\n        ';
 } ;
__p += '\n\n\n        <td class="text-center">\n          <z-button\n            tag="a"\n            href="' +
((__t = ( university_user.view )) == null ? '' : __t) +
'"\n            size="small"\n            variant="secondary"\n          >\n            Exibir\n          </z-button>\n        </td>\n      </tr>\n    ';
 }) ;
__p += '\n  </tbody>\n</table>\n';

}
return __p
}