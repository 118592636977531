import * as PowerBI from 'powerbi-client';

async function embedPowerBIReport({
  embedContainer,
  embedReportId,
  embedUrl,
  universityId,
  accessToken,
  resolveOnLoad = true,
}) {
  return new Promise(((resolve, reject) => {
    const tokenType = PowerBI.models.TokenType.Aad;
    const permissions = PowerBI.models.Permissions.All;
    const filters = _createFilters(universityId);

    const config = {
      type: 'report',
      tokenType,
      accessToken,
      embedUrl,
      id: embedReportId,
      permissions,
      settings: {
        panes: {
          pageNavigation: {
            visible: false,
          },
        },
        layoutType: PowerBI.models.LayoutType.Custom,
        customLayout: {
          displayOption: PowerBI.models.DisplayOption.FitToWidth,
        },
      },
      filters,
    };

    const powerbi = new PowerBI.service.Service(
      PowerBI.factories.hpmFactory,
      PowerBI.factories.wpmpFactory,
      PowerBI.factories.routerFactory,
    );

    const report = powerbi.embed(embedContainer, config);

    report.off('error');
    report.on('error', (error) => {
      reject(error);
      report.off('error');
    });

    report.off('loaded');
    report.on('loaded', () => {
      if (resolveOnLoad) resolve(report);
      report.off('loaded');
    });

    report.off('rendered');
    report.on('rendered', () => {
      if (!resolveOnLoad) resolve(report);
      report.off('rendered');
    });
  }));
}


function _createFilters(universityId) {
  return [
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'funil_overview_qa',
        column: 'university_id',
      },
      operator: 'In',
      values: [universityId],
      filterType: PowerBI.models.FilterType.Basic,
      requireSingleSelection: true,
    },
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'competitors_overview_qa',
        column: 'university_id',
      },
      operator: 'In',
      values: [universityId],
      filterType: PowerBI.models.FilterType.Basic,
      requireSingleSelection: true,
    },
  ];
}

export { embedPowerBIReport };
