import VueRouter from 'vue-router';
import {
  CreateDealPage,
  QueroTurboMenu,
} from '@quero-turbo/pages';
import { brgRoutes } from './brg-routes';

const router = new VueRouter({
  mode: 'history',
  base: 'quero-turbo',
  routes: [
    {
      path: '/',
      name: 'quero-turbo-menu',
      component: QueroTurboMenu,
      meta: {
        breadcrumb: [
          { text: 'Quero Turbo' },
        ],
      },
    },
    {
      path: '/create-deal',
      name: 'create-deal',
      component: CreateDealPage,
      meta: {
        breadcrumb: [
          { text: 'Quero Turbo', url: '/quero-turbo' },
          { text: 'Criando nova negociação' },
        ],
      },
    },
    ...brgRoutes,
  ],
});

export { router };
