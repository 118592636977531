import { QueroDealsV1API } from '@quero-turbo/api/quero-deals-v1-api';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Search campus by specific term
 *
 * @param {Object} params
 * @property {text} params.term The term to be searched
 * @property {Array[number]} params.universityIds Ids to be searched
 *
 * @typedef Success
 * @property {string} message
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function create({ name, queroPoloType }) {
  return QueroDealsV1API.Deal.createDeal({ name, queroPoloType })
    .then(({ data }) => data)
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[createDeal] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

export {
  create,
};
