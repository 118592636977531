import { period } from '@stock/business/period';

const COMMERCIAL_DISCOUNT_RANGE = { min: 0, max: 90 };
const FULL_PRICE_RANGE = { min: 1, max: 10000 };
const MAX_PAYMENTS_RANGE = { min: 1, max: 180 };

const isValidCommercialDiscount = ({ commercialDiscount }) => {
  if (typeof commercialDiscount === 'number') {
    return commercialDiscount >= COMMERCIAL_DISCOUNT_RANGE.min
        && commercialDiscount <= COMMERCIAL_DISCOUNT_RANGE.max;
  }
  return false;
};

const isValidFullPrice = ({ fullPrice }) => {
  if (typeof fullPrice === 'number') {
    return fullPrice >= FULL_PRICE_RANGE.min
        && fullPrice <= FULL_PRICE_RANGE.max;
  }
  return false;
};

const isValidMaxPayments = ({ maxPayments }) => {
  if (typeof maxPayments === 'number') {
    return maxPayments >= MAX_PAYMENTS_RANGE.min
        && maxPayments <= MAX_PAYMENTS_RANGE.max;
  }
  return false;
};

const isValidOfferedPrice = ({ offeredPrice, fullPrice, commercialDiscount }) => {
  const maxValidOfferedPrice = () => (
    fullPrice - (fullPrice * ((commercialDiscount + 5) / 100))
  );
  const minValidOfferedPrice = () => (
    fullPrice - (fullPrice * 0.95)
  );

  if (!Number(offeredPrice)) return false;
  if (!isValidFullPrice({ fullPrice })) return false;
  if (!isValidCommercialDiscount({ commercialDiscount })) return false;
  if (offeredPrice > maxValidOfferedPrice()) return false;
  if (offeredPrice < minValidOfferedPrice()) return false;
  return true;
};

const isValidDiscountPercentage = ({ discountPercentage, commercialDiscount }) => {
  if (!Number(discountPercentage)) return false;
  if (!isValidCommercialDiscount({ commercialDiscount })) return false;
  if (discountPercentage < commercialDiscount + 5) return false;
  if (discountPercentage > 95) return false;
  return true;
};

const calculateDiscountPercentage = ({ fullPrice, offeredPrice, commercialDiscount }) => {
  if (!isValidOfferedPrice({ offeredPrice, fullPrice, commercialDiscount })) return null;
  const discountPercentage = ((fullPrice - offeredPrice) / fullPrice) * 100;
  if (!isValidDiscountPercentage({ discountPercentage, commercialDiscount })) return null;
  return discountPercentage.toFixed(2);
};

const calculateOfferedPrice = ({ fullPrice, discountPercentage, commercialDiscount }) => {
  if (!isValidDiscountPercentage({ discountPercentage, commercialDiscount })) return null;
  if (!isValidFullPrice({ fullPrice })) return null;
  const offeredPrice = fullPrice - (fullPrice * (discountPercentage / 100));
  if (!isValidOfferedPrice({ offeredPrice, fullPrice, commercialDiscount })) return null;
  return offeredPrice.toFixed(2);
};

const calculateCommercialPrice = ({ fullPrice, commercialDiscount }) => {
  if (isValidFullPrice({ fullPrice }) && isValidCommercialDiscount({ commercialDiscount })) {
    return fullPrice * (1 - (commercialDiscount / 100));
  }
  return undefined;
};

const calculateMaxPayments = ({ maxPeriods, periodKind }) => {
  const { periodKindsInMonths } = period.allPeriodKindsMap[periodKind];
  if (typeof maxPeriods === 'number' && typeof periodKindsInMonths === 'number') {
    return periodKindsInMonths * maxPeriods;
  }
  return undefined;
};

export const price = {
  COMMERCIAL_DISCOUNT_RANGE,
  FULL_PRICE_RANGE,
  MAX_PAYMENTS_RANGE,
  calculateCommercialPrice,
  calculateDiscountPercentage,
  calculateMaxPayments,
  calculateOfferedPrice,
  isValidCommercialDiscount,
  isValidDiscountPercentage,
  isValidFullPrice,
  isValidMaxPayments,
  isValidOfferedPrice,
};
