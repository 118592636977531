import $ from 'jquery';
import ShowDocumentationModal from '@admission/legacy/components/modals/show-documentation';
import FollowUpUpdateActions from '@admission/legacy/modules/follow-up-update-actions';

class ScholarshipPanel {
  constructor(page) {
    this.page = page;
    this.initDocumentationModal();
    this.initDocumentationButtons();
    this.followUpUpdateActionsSetup = new FollowUpUpdateActions(page);
  }

  initDocumentationModal() {
    $('.js-follow-up-docs').click(function initModal(event) {
      const check = $(event.target).closest('.js-docs-check-area');

      if (check.length === 0) {
        const props = {
          docPreviewUrl: $(this).data('docPreviewUrl'),
          docDownloadUrl: $(this).data('docDownloadUrl'),
        };

        const modal = new ShowDocumentationModal(props);
        modal.show();
      }
    });
  }

  initDocumentationButtons() {
    const setupButton = ({
      selector, path, title, buttonOk, message,
    }) => {
      const $elem = $(selector);

      $elem.confirmModal({
        confirmTitle: title,
        confirmMessage: message,
        confirmOk: buttonOk,
        confirmCancel: 'Cancelar',
        confirmStyle: 'primary',
        confirmDismiss: true,
        confirmAutoOpen: false,
        followUpId: $elem.data('followUpId'),
        confirmCallback() {
          const $loader = $('.cog');
          $loader.show();

          const $ajax = $.ajax({
            url: `/busca-pre-matriculas/${this.followUpId}/${path}`,
            method: 'POST',
            data: {},
          });

          $ajax.done(() => window.location.reload(true));
          $ajax.fail(() => $('#js-modal-message-error-update').modal('show'));
          $ajax.always(() => $loader.hide());
        },
      });
    };

    setupButton({
      selector: '#js-documentation-approval',
      path: 'enrollment',
      title: 'Deseja aprovar a documentação?',
      buttonOk: 'Aprovar Documentos',
      message: 'Ao aprovar a documentação, você confirma que todos os documentos necessários para a matrícula foram fornecidos pelo aluno e validados pela faculdade. Dessa forma, o aluno será considerado matriculado.',
    });

    setupButton({
      selector: '#js-approve-last-submitted-event',
      path: 'approve-submitted-event',
      title: 'Deseja aprovar estes documentos?',
      buttonOk: 'Verificar Documentos',
      message: 'Ao verificar a documentação, você confirma que analisou os documentos do aluno e conluiu que eles estão devidamente utilizáveis.',
    });

    setupButton({
      selector: '#js-reject-last-submitted-event',
      path: 'reject-submitted-event',
      title: 'Deseja rejeitar estes documentos?',
      buttonOk: 'Rejeitar Documentos',
      message: 'Ao rejeitar a documentação, você confirma que analisou os documentos do aluno e conluiu que eles não  estão devidamente utilizáveis.',
    });
  }
}

export default ScholarshipPanel;
