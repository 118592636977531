import {
  ZAutocomplete,
  ZButton,
  ZCard,
  ZCheckbox,
  ZDivider,
  ZDropdownInput,
  ZDynamicContent,
  ZIcon,
  ZInput,
  ZInputField,
  ZLink,
  ZLoading,
  ZModal,
  ZPopover,
  ZTag,
  ZText,
  ZTitle,
  ZTooltip,
  ZRadio,
  ZBadgeText,
  ZStepperBar,
  ZToast,
  ZToggleSwitch,
  ZAccordion,
  ZBreadcrumb,
  ZButtonGroup,
  ZBadgeOrdination,
  ZPagination,
} from '@quero/zilla-vue';

export const ZillaPlugin = {
  install(Vue) {
    this._installAtoms(Vue);
    this._installMolecules(Vue);
  },
  _installAtoms(Vue) {
    Vue.component('z-badge-text', ZBadgeText);
    Vue.component('z-button', ZButton);
    Vue.component('z-card', ZCard);
    Vue.component('z-divider', ZDivider);
    Vue.component('z-icon', ZIcon);
    Vue.component('z-input', ZInput);
    Vue.component('z-link', ZLink);
    Vue.component('z-loading', ZLoading);
    Vue.component('z-popover', ZPopover);
    Vue.component('z-tag', ZTag);
    Vue.component('z-text', ZText);
    Vue.component('z-title', ZTitle);
    Vue.component('z-toggle-switch', ZToggleSwitch);
    Vue.component('z-tooltip', ZTooltip);
    Vue.component('z-badge-ordination', ZBadgeOrdination);
  },
  _installMolecules(Vue) {
    Vue.component('z-accordion', ZAccordion);
    Vue.component('z-autocomplete', ZAutocomplete);
    Vue.component('z-checkbox', ZCheckbox);
    Vue.component('z-dropdown-input', ZDropdownInput);
    Vue.component('z-dynamic-content', ZDynamicContent);
    Vue.component('z-input-field', ZInputField);
    Vue.component('z-modal', ZModal);
    Vue.component('z-radio', ZRadio);
    Vue.component('z-stepper-bar', ZStepperBar);
    Vue.component('z-toast', ZToast);
    Vue.component('z-breadcrumb', ZBreadcrumb);
    Vue.component('z-button-group', ZButtonGroup);
    Vue.component('z-pagination', ZPagination);
  },
};
