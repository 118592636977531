import $ from 'jquery';
import Pagination from '@shared/legacy/components/pagination';
import Alert from '@shared/legacy/components/alert';
import SearchForm from '@shared/legacy/components/search-form';
import { CampusPage } from '@stock/pages';

const DECIMAL_BASE = 10;

class CampusInformationList {
  constructor(page) {
    this.page = page;
    this.loading = false;
    this.alert = new Alert();
    this.initForm();
    this.initPagination();
    this.pagination.setPagination({
      total_pages: parseInt($('.js-total-pages').val(), DECIMAL_BASE),
      page_number: parseInt($('#search-page').val(), DECIMAL_BASE),
    });

    this.searchForm.submit();
    return new CampusPage({ pageInstance: this });
  }

  showLoader() {
    $('.js-cog').show();
  }

  hideLoader() {
    this.loading = false;
    $('.js-cog').hide();
  }

  initPagination() {
    this.pagination = new Pagination({
      controlsContainer: '.js-controls-navigation',
      infoContainer: '.js-info-pagination',
      onChangePage: this.submitPage.bind(this),
    });
  }

  initForm() {
    this.searchForm = new SearchForm($('.js-campus-form'));

    $(this.searchForm).on('submitting', () => {
      $('.js-campus-table').html('');
      this.showLoader();
      this.alert.hide();
    });

    $(this.searchForm).on('submit:success', ({ html, page, pages }) => {
      this.hideLoader();

      $('.js-campus-table').html(html);
      this.pagination.setPagination({ page_number: page, total_pages: pages });
    });

    $(this.searchForm).on('submit:error', () => {
      this.hideLoader();
      $('.js-campus-table').html('');

      this.alert.showError('Campus não encontrados');
    });
  }

  submitPage(newPage) {
    this.searchForm.val('page', newPage);
    this.searchForm.submit();
  }

  // global jQuery and $
  $(selector) {
    return $(selector, this.page);
  }
}

export default CampusInformationList;
