<template>
  <GridContainer no-padding>
    <GridItem :column="6">
      <GridContainer no-padding>
        <GridItem>
          <z-input-field
            label="Porcentagem de repasse QP (0-100%)*"
            class="step02-payment__input-field"
          >
            <z-input
              type="number"
              placeholder="Ex.: 00"
              :has-error="hasErrorTransferQpPercentage"
              v-model="transferQpPercentage"
              @keydown="checkInteger"
            />
          </z-input-field>
        </GridItem>

        <GridItem>
          <z-input-field
            label="Subsídio (0-100%)*"
            class="step02-payment__input-field"
          >
            <z-input
              type="number"
              placeholder="Ex.: 00"
              :has-error="hasErrorSubsidy"
              v-model="subsidy"
            />
          </z-input-field>
        </GridItem>

        <GridItem>
          <z-input-field
            label="Melhores dias para as transferências dos valores de repasse?*"
            class="step02-payment__input-field"
          >
            <z-input
              type="text"
              placeholder="Ex.: 01, 05, 15"
              v-model="transferDays"
            />
          </z-input-field>
        </GridItem>

        <GridItem>
          <z-input-field
            label="Quais os dias disponíveis para vencimento das mensalidades dos alunos?*"
            class="step02-payment__input-field"
          >
            <z-input
              type="text"
              placeholder="Ex.: 01, 05, 15"
              v-model="dueDays"
            />
          </z-input-field>
        </GridItem>
      </GridContainer>
    </GridItem>

    <GridItem :column="6">
      <GridContainer no-padding>
        <GridItem>
          <z-input-field
            label="O aluno pode trocar a data de vencimento dos boletos?*"
            class="step02-payment__input-field"
          >
            <z-toggle-switch
              has-label
              v-model="studentCanChangeDueDay"
            />
          </z-input-field>
        </GridItem>

        <GridItem>
          <z-input-field
            label="Quais formas de pagamento serão aceitas para pagamento das mensalidades?*"
            class="step02-payment__input-field"
          >
            <div class="step02-payment__input-field-wrapper">
              <z-checkbox
                :checked="studentPaymentMethod.indexOf(paymentMethod) !== -1"
                :key="index"
                :value="paymentMethod"
                @change="handleStudentPaymentChange"
                behavior="inline"
                v-for="(paymentMethod, index) in BillingConfigBusiness.getPaymentMethodTextList()"
              >
                {{ paymentMethod }}
              </z-checkbox>
            </div>
          </z-input-field>
        </GridItem>

        <GridItem>
          <z-input-field
            label="Os alunos poderão quitar o curso em quais formas de pagamento?*"
            class="step02-payment__input-field"
          >
            <div class="step02-payment__input-field-wrapper">
              <z-checkbox
                :checked="studentDischargeRules.indexOf(paymentMethod) !== -1"
                :key="index"
                :value="paymentMethod"
                @change="handleStudentDischargeRulesChange"
                behavior="inline"
                v-for="(paymentMethod, index) in BillingConfigBusiness.getPaymentMethodTextList()"
              >
                {{ paymentMethod }}
              </z-checkbox>
            </div>
          </z-input-field>
        </GridItem>

        <GridItem>
          <z-input-field
            label="Início da cobrança dos alunos"
            class="step02-payment__input-field"
          >
            <z-dropdown-input
              :options="BillingConfigBusiness.getStartRuleTextList()"
              v-model="startRule"
              placeholder="Seleciona o ínicio da cobrança"
            />
          </z-input-field>
        </GridItem>

        <GridItem>
          <z-input-field
            label="Duração do contrato"
            class="step02-payment__input-field"
          >
            <z-dropdown-input
              :options="BillingConfigBusiness.getDurationTextList()"
              v-model="duration"
              placeholder="Seleciona a duração do contrato"
            />
          </z-input-field>
        </GridItem>
      </GridContainer>
    </GridItem>
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { createHelpers } from 'vuex-map-fields';
import { BillingConfigBusiness } from '@quero-turbo/business';

const { mapFields } = createHelpers({
  getterType: 'getStep02Field',
  mutationType: 'updateStep02Field',
});

export default {
  components: {
    GridContainer,
    GridItem,
  },
  computed: {
    BillingConfigBusiness: () => BillingConfigBusiness,
    ...mapFields('CreateBillingConfigModalModule', [
      'contractCreationRule.startRule',
      'contractCreationRule.duration',
      'dueDayRule.dueDays',
      'dueDayRule.studentCanChangeDueDay',
      'paymentMethodRules.methods',
      'splitRules.transferInstitutionPercentage',
      'splitRules.transferQpPercentage',
      'subsidy',
      'bankAccountParams.transferDays',
      'stepConfig.validStep02',
    ]),
    ...mapFields('CreateBillingConfigModalModule', {
      studentPaymentMethod: 'paymentMethodRules.methods',
      studentDischargeRules: 'contractDischargeRules.methods',
    }),
    hasErrorTransferQpPercentage() {
      return this.transferQpPercentage < 0 || this.transferQpPercentage > 100;
    },
    hasErrorSubsidy() {
      return this.subsidy < 0 || this.subsidy > 100;
    },
    canIAdvanceStep() {
      return Boolean(
        this.studentPaymentMethod.length
      && !this.hasErrorTransferQpPercentage
      && !this.hasErrorSubsidy
      && this.dueDays
      && this.transferDays
      && this.startRule
      && this.duration,
      );
    },
  },
  watch: {
    canIAdvanceStep(newValue) {
      this.validStep02 = newValue;
    },
    transferQpPercentage(newValue) {
      this.transferInstitutionPercentage = 100 - Number(newValue);
    },
  },
  methods: {
    handleStudentPaymentChange($event) {
      const { value } = $event.target;
      const index = this.studentPaymentMethod.indexOf(value);
      if (index > -1) {
        this.studentPaymentMethod = this.studentPaymentMethod.filter(element => element !== value);
      } else {
        this.studentPaymentMethod = this.studentPaymentMethod.concat(value);
      }
    },
    handleStudentDischargeRulesChange($event) {
      const { value } = $event.target;
      const index = this.studentDischargeRules.indexOf(value);
      if (index > -1) {
        this.studentDischargeRules = this.studentDischargeRules.filter(element => element !== value);
      } else {
        this.studentDischargeRules = this.studentDischargeRules.concat(value);
      }
    },
    checkInteger(event) {
      if (event.key.length === 1 && isNaN(Number(event.key))) { // eslint-disable-line no-restricted-globals
        event.preventDefault();
      }
    },
  },
};
</script>

<style>
.step02-payment__input-field {
  margin-bottom: 0;
}

.step02-payment__input-field-wrapper {
  display: flex;
  justify-content: space-between;
}
</style>
