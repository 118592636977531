<template>
  <GridContainer>
    <GridItem>
      <HeaderBox title="Novo filtro de ofertas" />
    </GridItem>

    <GridItem>
      <z-card>
        <GridContainer no-padding>
          <GridItem>
            <CreateOfferFilterPainel
              ref="offerFilterPainel"
              :brg-id="brgId"
            />
          </GridItem>

          <GridItem class="create-offer-filter-page__container">
            <z-button
              tag="router-link"
              :to="`/brgs/${brgId}`"
              variant="secondary"
              class="create-offer-filter-page__button"
            >
              Voltar
            </z-button>

            <z-button
              class="create-offer-filter-page__button"
              @click="handleSubmit"
            >
              Criar novo filtro de oferta
            </z-button>
          </GridItem>
        </GridContainer>
      </z-card>
    </GridItem>
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { HeaderBox } from '@shared/components/header-box';
import { CreateOfferFilterPainel } from '@quero-turbo/modules/offer-filter-components';
import { QueroDealsServices } from '@quero-turbo/services';

export default {
  components: {
    GridContainer,
    GridItem,
    HeaderBox,
    CreateOfferFilterPainel,
  },
  computed: {
    brgId() {
      return Number(this.$route.params.id);
    },
  },
  methods: {
    async handleSubmit() {
      const rawData = this.$refs.offerFilterPainel.getOfferFilterRawData();
      this.$upaHelperModal.show({ mode: 'loading' });
      QueroDealsServices.V1.OfferFilter.parseData(rawData)
        .then(this.requestCreateOfferFilter)
        .catch(this.handleError);
    },
    async requestCreateOfferFilter(params) {
      await QueroDealsServices.V1.OfferFilter.create(params)
        .then(this.handleSuccessRequest)
        .catch(this.handleError);
    },
    handleSuccessRequest() {
      this.$upaHelperModal.fire({ mode: 'success' }).then(() => {
        this.$router.push(`/brgs/${this.brgId}`);
      });
    },
    async handleError() {
      this.$upaHelperModal.fire({ mode: 'error' });
    },
  },
};
</script>

<style scoped>
.create-offer-filter-page__container {
  display: flex;
  justify-content: flex-end;
}

.create-offer-filter-page__button:not(:first-child) {
  margin-left: var(--space-medium);
}
</style>
