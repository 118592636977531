import {
  shape, custom,
} from 'vue-types';

function stringOrNull(value) {
  return typeof value === 'string' || value === null;
}

const FeedbackCardModel = shape({
  title: custom(stringOrNull),
  text: custom(stringOrNull),
  buttonText: custom(stringOrNull),
});

const FeedbackCardsModel = shape({
  success: FeedbackCardModel,
  error: FeedbackCardModel,
  loading: FeedbackCardModel,
});

export {
  FeedbackCardModel, FeedbackCardsModel,
};
