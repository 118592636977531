module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 paragraphs.forEach(function(paragraph){ ;
__p += '\n  <p class="gq-text">' +
((__t = ( paragraph )) == null ? '' : __t) +
'</p>\n';
 }) ;
__p += '\n';

}
return __p
}