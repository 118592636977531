import { create } from './create';
import { getAll } from './get-all';
import { findById } from './find-by-id';
import { update } from './update';

const BRG = {
  create,
  getAll,
  findById,
  update,
};

export { BRG };
