import AmplitudeService from '@shared/utils/amplitude-service';

const amplitude = AmplitudeService.getInstance();

const _logEvent = (eventName, options) => {
  const eventFullName = `[offer_creation] ${eventName}`;
  amplitude.logEvent(eventFullName, options);
};

const viewedModal = () => {
  _logEvent('viewed_modal');
};

const advancedCourseSelectionStep = ({ level }) => {
  _logEvent('advanced_course_selection_step', { level });
};

const advancedCourseDetailStep = ({ kind, shifts }) => {
  _logEvent('advanced_course_detail_step', { kind, shifts });
};

const advancedOfferDetailStep = () => {
  _logEvent('advanced_offer_detail_step');
};

const advancedOfferMarketplaceDetailStep = (options = {}) => {
  _logEvent('advanced_offer_marketplace_detail_step', {
    havePriceSuggestion: options.havePriceSuggestion,
    usedPriceSuggestion: options.usedPriceSuggestion,
  });
};

const sentRequisitionToCreateOffer = () => {
  _logEvent('sent_requisition_to_create_offer');
};

const knowMoreAboutCompetitiveness = () => {
  _logEvent('know_more_about_competitiveness');
};

const steppedBack = ({ current, returnedTo }) => {
  _logEvent('stepped_back', {
    current,
    returnedTo,
  });
};

export const offerCreationTracking = {
  advancedCourseDetailStep,
  advancedCourseSelectionStep,
  advancedOfferDetailStep,
  advancedOfferMarketplaceDetailStep,
  knowMoreAboutCompetitiveness,
  sentRequisitionToCreateOffer,
  steppedBack,
  viewedModal,
};
