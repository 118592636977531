import EventEmitter from 'events';
import baseTemplate from './template.ejs';

/**
  * @desc prepares the bootstrap popover with our base template
  * @param {Object} options - options available for popover
  *   to see the options object interface see the documentation:
  *   https://getbootstrap.com/docs/4.1/components/popovers/#options
  * @return {EventEmitter} - popover life cycle events
*/
export function setupPopover(options = {}) {
  validateOptions(options);
  const $targetPopover = $(options.targetPopover);
  buildPopover($targetPopover, options);
  setupClick($targetPopover, options);
  if (options.closeWhenClickOutside) setupCloseWhenClickOutside($targetPopover);
  return setupEvents($targetPopover);
}

function validateOptions(options) {
  if (!options.template) throw new Error('options template is required');
  if (!options.targetPopover) throw new Error('options targetPopover is required');
}

function buildPopover($targetPopover, options) {
  $targetPopover.popover({
    ...options,
    title: 'custom popover',
    html: true,
    template: baseTemplate({ template: options.template }),
  });
}

function setupClick($targetPopover) {
  $targetPopover.on('click', (event) => {
    event.preventDefault();
    event.stopPropagation();
  });
}

function setupCloseWhenClickOutside($targetPopover) {
  $(document).on('click', (event) => {
    if (hasElementOutsideOfPopover(event.target)) {
      $targetPopover.popover('hide');
    }
  });
  $targetPopover.on('hidden.bs.popover', (event) => {
    $(event.target).data('bs.popover').inState.click = false;
  });
}

function hasElementOutsideOfPopover($element) {
  const $popoverContent = $('.js-popover');
  return (
    !$popoverContent.is($element)
    && $popoverContent.has($element).length === 0
  );
}

function setupEvents($targetPopover) {
  const event = new EventEmitter();
  const options = { $targetPopover };
  [
    {
      bootstrapEvent: 'show.bs.popover',
      customEvent: 'before.showing',
    },
    {
      bootstrapEvent: 'shown.bs.popover',
      customEvent: 'after.showing',
    },
    {
      bootstrapEvent: 'hide.bs.popover',
      customEvent: 'before.hiding',
    },
    {
      bootstrapEvent: 'hidden.bs.popover',
      customEvent: 'after.hiding',
    },
    {
      bootstrapEvent: 'inserted.bs.popover',
      customEvent: 'created',
    },
  ].forEach(({ bootstrapEvent, customEvent }) => {
    $targetPopover.on(bootstrapEvent, () => {
      event.emit(customEvent, options);
    });
  });
  return event;
}
