import ConfirmationModal from '@stock/legacy/components/modals/offer-list-actions/batch/confirmation';
import { TEMPLATE_CONFIRMATION_PROPS }
  from '@stock/legacy/components/modals/offer-list-actions/batch/restock/template-props';
import template from '@stock/legacy/components/modals/offer-list-actions/batch/confirmation-template.html.ejs';
import { offersCount } from '@stock/legacy/components/modals/offer-list-actions/batch/offers-service';

const ADD_SEATS_URL = '/ui/ofertas/reposicao/batch/repor';

export default (modal, alert) => {
  $(modal).on('next', (_evt, restockData) => {
    offersCount(restockData.batchOperationRequest, (result) => {
      const confirmationModal = new ConfirmationModal(
        ADD_SEATS_URL,
        {
          ...TEMPLATE_CONFIRMATION_PROPS,
          ...restockData,
          newValue: Math.abs(restockData.seatsCount),
          previousValue: 0,
          filter: restockData.batchOperationRequest.filters,
          totalOffers: result.total_offers_count,
          totalOffersTarget: result.total_offers_target_count,
          sumOffersTarget: Math.abs(restockData.seatsCount * result.total_offers_target_count),
        },
        alert,
        template,
      );

      confirmationModal.show();
    });
  });
};
