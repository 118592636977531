module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<label ' +
((__t = ( attr('class', layoutClass, 'z-input-field', behavior, targetSelector) )) == null ? '' : __t) +
'>\n  <span class="z-text z-ovr-text--fw-medium">' +
((__t = ( labelText )) == null ? '' : __t) +
'</span>\n  <div ' +
((__t = ( attr('class', 'z-input', size, state, inputVariant, 'js-input') )) == null ? '' : __t) +
'>\n    <input\n      ' +
((__t = ( attr('id', id) )) == null ? '' : __t) +
'\n      ' +
((__t = ( attr('class', 'z-input__field', 'js-input-field') )) == null ? '' : __t) +
'\n      ' +
((__t = ( attr('value', valueText) )) == null ? '' : __t) +
'\n      ' +
((__t = ( attr('placeholder', placeholder) )) == null ? '' : __t) +
'\n      ' +
((__t = ( attr('name', name) )) == null ? '' : __t) +
'\n      ' +
((__t = ( attr('autofocus', autofocus) )) == null ? '' : __t) +
'\n      ' +
((__t = ( attr('disabled', disabled) )) == null ? '' : __t) +
'\n      ' +
((__t = ( attr('type', type) )) == null ? '' : __t) +
'\n    />\n  </div>\n  <span class="z-text z-text--small z-text--danger js-error-message"></span>\n</label>\n';

}
return __p
}