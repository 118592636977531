import ZInput from '@shared/legacy/components/zilla/z-input-field';

const createZInputPassword = (identifier, options) => new ZInput(identifier, {
  type: 'password',
  layoutClass: 'reset-password__field',
  ...options,
});

export const createPasswordInput = () => createZInputPassword('reset-password', {
  id: 'password_reset_password',
  labelText: 'Nova senha',
  name: 'password_reset[password]',
  placeholder: 'Digite sua nova senha',
  autofocus: true,
});

export const createConfirmPasswordInput = () => createZInputPassword('reset-confirm-password', {
  id: 'password_reset_confirm_password',
  labelText: 'Confirmar nova senha',
  name: 'password_reset[confirm_password]',
  placeholder: 'Repita sua nova senha',
});
