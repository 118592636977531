<template>
  <ul class="one-click-restock-offer-info">
    <li
      class="one-click-restock-offer-info__item"
      v-for="({ key, value, valueColor }, index) in items"
      :key="index"
    >
      <z-text
        class="one-click-restock-offer-info__key"
        tag="strong"
        color="minor"
        size="small"
      >
        {{ key }}
      </z-text>
      <z-text
        class="one-click-restock-offer-info__value"
        tag="span"
        :color="valueColor || 'minor'"
        size="small"
        weight="normal"
      >
        {{ value }}
      </z-text>
    </li>
  </ul>
</template>

<script>
import Vue from 'vue';
import {
  currencyFormat,
  percentFormat,
} from '@shared/utils/number_format';

export default Vue.extend({
  props: {
    courseName: {
      type: String,
      default: '',
    },
    campus: {
      type: String,
      required: true,
    },
    level: {
      type: String,
      required: true,
    },
    kind: {
      type: String,
      required: true,
    },
    shift: {
      type: String,
      required: true,
    },
    availableSeats: {
      type: Number,
      required: true,
    },
    offeredPrice: {
      type: Number,
      required: true,
    },
    discount: {
      type: Number,
      required: true,
    },
    enrollmentSemester: {
      type: String,
      required: true,
    },
  },
  computed: {
    items() {
      return [
        {
          key: 'Curso',
          value: this.courseName,
          hidden: this.courseName === '',
        },
        {
          key: 'Campus',
          value: this.campus,
        },
        {
          key: 'Nível',
          value: this.level,
        },
        {
          key: 'Modalidade',
          value: this.kind,
        },
        {
          key: 'Turno',
          value: this.shift,
        },
        {
          key: 'Semestre de matrícula',
          value: this.enrollmentSemester,
        },
        {
          key: 'Nº de vagas',
          value: this.availableSeats,
          valueColor: this.availableSeatsValueColor,
        },
        {
          key: 'Valor com desconto',
          value: currencyFormat(this.offeredPrice),
        },
        {
          key: 'Desconto',
          value: percentFormat(this.discount),
        },
      ].filter(({ hidden }) => !hidden);
    },
    availableSeatsValueColor() {
      return this.availableSeats === 0 ? 'danger' : 'success';
    },
  },
});
</script>

<style scoped lang="scss">
.one-click-restock-offer-info {
  border-radius: var(--size-small);
  border: 1px solid var(--color-neutral-lighter-gray);
  margin-bottom: 0;
  padding-left: 0;
}

.one-click-restock-offer-info__item {
  display: flex;
  padding: var(--space-small) var(--space-medium);

  &:not(:first-child) {
    border-top: 1px solid var(--color-neutral-lighter-gray);
  }
}

.one-click-restock-offer-info__key {
  flex: 0 0 var(--size-extra-colossal);
  font-weight: 500;
}

.one-click-restock-offer-info__value {
  flex-grow: 1;
  margin-left: var(--space-medium);
}
</style>
