import $ from 'jquery';
import AmplitudeService from '@shared/utils/amplitude-service';

class BoxSeal {
  constructor(page) {
    this.boxSealOnClick();
    this.page = page;
    this.amplitudeService = AmplitudeService.getInstance();
    this.amplitudeService.logEvent('[AD] Box Seal', { action: 'view' });
  }

  boxSealOnClick() {
    $('.js-box-seal__link', this.page).click(() => {
      $('#collapse-student-data').collapse('show');
      this.amplitudeService.logEvent('[AD] Box Seal', { action: 'open' });
    });
  }
}

export default BoxSeal;
