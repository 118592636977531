import Vue from 'vue';
import { ZillaPlugin } from '@shared/utils/vue/plugins/zilla-plugin';
import { ValidatePlugin } from '@shared/utils/vue/plugins/validate-plugin';
import OperationLogs from './operation-logs-page.vue';

export function OperationLogsPage() {
  return new Promise((resolve) => {
    Vue.use(ValidatePlugin);
    Vue.use(ZillaPlugin);

    new Vue({
      el: '#js-operation-logs-page',
      components: {
        OperationLogs,
      },
      mounted() {
        resolve();
      },
    });
  });
}
