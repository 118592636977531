import { getDictionaryKeyByText, getDictionaryTextByKey, getDictionaryTextList } from '@shared/utils/dictionary';

const paymentStartRuleDictionary = [
  {
    key: undefined,
    text: null,
  },
  {
    key: 'current_month',
    text: 'Mês atual',
  },
  {
    key: 'next_month',
    text: 'Próximo mês',
  },
  {
    key: 'next_month_if_15',
    text: 'Próximo mês se a matricula for posterior ao dia 15',
  },
  {
    key: 'next_semester',
    text: 'Próximo semestre',
  },
  {
    key: 'first_bill_after_three_days',
    text: 'Após três dias',
  },
];

const paymentDurationDictionary = [
  {
    key: undefined,
    text: null,
  },
  {
    key: 'all_course',
    text: 0,
  },
  {
    key: 'semester',
    text: 6,
  },
  {
    key: 'yearly',
    text: 12,
  },
];

const institutionEnrollmentFeeTypeDictionary = [
  {
    key: undefined,
    text: null,
  },
  {
    key: 'amount_with_discount',
    text: 'Valor com desconto',
  },
  {
    key: 'amount_without_discount',
    text: 'Valor sem desconto',
  },
  {
    key: 'percentage_of_amount_with_discount',
    text: 'Porcentagem com desconto',
  },
  {
    key: 'percentage_of_amount_without_discount',
    text: 'Porcentagem sem desconto',
  },
  {
    key: null,
    text: 'Sem matrícula',
  },
  {
    key: 'custom_amount',
    text: 'Valor fixo',
  },
];

const paymentMethodDictionary = [
  {
    key: undefined,
    text: null,
  },
  {
    key: 'credit_card',
    text: 'Cartão de crédito',
  },
  {
    key: 'pix',
    text: 'Pix',
  },
  {
    key: 'boleto',
    text: 'Boleto',
  },
];

const lossDelayTypeDictionary = [
  {
    key: undefined,
    text: null,
  },
  {
    key: 'working_days',
    text: 'Dias úteis',
  },
  {
    key: 'calendar_days',
    text: 'Dias corridos',
  },
];

const ruleToLoseScholarshipDictionary = [
  {
    key: undefined,
    text: null,
  },
  {
    key: 'only_overdue_bills',
    text: 'Parcelas em atraso',
  },
  {
    key: 'while_has_overdue_bills',
    text: 'Enquanto tiver parcelas em atraso',
  },
  {
    key: 'whole_course',
    text: 'Curso todo',
  },
];

const getStartRuleKeyByText = text => getDictionaryKeyByText(paymentStartRuleDictionary, text);
const getStartRuleTextList = () => getDictionaryTextList(paymentStartRuleDictionary);
const getStartRuleTextByKey = key => getDictionaryTextByKey(paymentStartRuleDictionary, key);

const getDurationTextList = () => getDictionaryTextList(paymentDurationDictionary);

const getAmountTypeKeyByText = text => getDictionaryKeyByText(institutionEnrollmentFeeTypeDictionary, text);
const getAmountTypeTextList = () => getDictionaryTextList(institutionEnrollmentFeeTypeDictionary);
const getAmountTypeTextByKey = key => getDictionaryTextByKey(institutionEnrollmentFeeTypeDictionary, key);

const getPaymentMethodKeyByText = text => getDictionaryKeyByText(paymentMethodDictionary, text);
const getPaymentMethodTextList = () => getDictionaryTextList(paymentMethodDictionary);
const getPaymentMethodTextByKey = key => getDictionaryTextByKey(paymentMethodDictionary, key);

const getLossDelayTypeKeyByText = text => getDictionaryKeyByText(lossDelayTypeDictionary, text);
const getLossDelayTypeTextList = () => getDictionaryTextList(lossDelayTypeDictionary);
const getLossDelayTypeTextByKey = key => getDictionaryTextByKey(lossDelayTypeDictionary, key);

const getRuleToLoseScholarshipKeyByText = text => getDictionaryKeyByText(ruleToLoseScholarshipDictionary, text);
const getRuleToLoseScholarshipTextList = () => getDictionaryTextList(ruleToLoseScholarshipDictionary);
const getRuleToLoseScholarshipTextByKey = key => getDictionaryTextByKey(ruleToLoseScholarshipDictionary, key);

export const BillingConfigBusiness = {
  getStartRuleKeyByText,
  getStartRuleTextList,
  getStartRuleTextByKey,
  getDurationTextList,
  getAmountTypeKeyByText,
  getAmountTypeTextList,
  getAmountTypeTextByKey,
  getPaymentMethodKeyByText,
  getPaymentMethodTextList,
  getPaymentMethodTextByKey,
  getLossDelayTypeKeyByText,
  getLossDelayTypeTextList,
  getLossDelayTypeTextByKey,
  getRuleToLoseScholarshipKeyByText,
  getRuleToLoseScholarshipTextList,
  getRuleToLoseScholarshipTextByKey,
};
