<template>
  <div class="create-admission-config-modal-contranct-and-essay__container">
    <div class="create-admission-config-modal-contranct-and-essay__essay-content">
      <z-input-field
        label="O processo seletivo é obrigatório?"
        class="create-admission-config-modal-contranct-and-essay__input-field create-admission-config-modal-contranct-and-essay__required_selection_process"
      >
        <z-toggle-switch
          v-model="hasVestibular"
          has-label
        />
      </z-input-field>

      <z-input-field
        label="Qual é a nota de corte do ENEM?"
        class="create-admission-config-modal-contranct-and-essay__input-field"
      >
        <z-input
          type="number"
          :has-error="hasErrorEnemCutScore"
          v-model="enemCutScore"
        />
      </z-input-field>

      <z-input-field
        label="Quantidade de anos para aceitação do ENEM?"
        class="create-admission-config-modal-contranct-and-essay__input-field"
      >
        <z-input
          type="number"
          :has-error="hasErrorEnemCutYear"
          v-model="enemCutYear"
        />
      </z-input-field>

      <z-input-field
        label="Qual é a nota de corte da redação?"
        class="create-admission-config-modal-contranct-and-essay__input-field"
      >
        <z-input
          type="number"
          :has-error="hasErrorDigitalAdmissionCutScore"
          v-model="digitalAdmissionCutScore"
        />
      </z-input-field>

      <z-input-field
        label="Quantidade de anos para aceitação de reaproveitamento da redação?"
        class="create-admission-config-modal-contranct-and-essay__input-field"
      >
        <z-input
          type="number"
          :has-error="hasErrorEssayCutYear"
          v-model="essayCutYear"
        />
      </z-input-field>
    </div>

    <z-divider />

    <div class="create-admission-config-modal-contranct-and-essay__contract-content">
      <z-input-field
        label="Qual é a chave do Clicksign?"
        class="create-admission-config-modal-contranct-and-essay__input-field"
      >
        <z-input
          type="text"
          v-model="clicksignTemplateId"
        />
      </z-input-field>

      <z-input-field
        label="Tem matrícula direta?"
        class="create-admission-config-modal-contranct-and-essay__input-field create-admission-config-modal-contranct-and-essay__has-direct-enrollment"
      >
        <z-toggle-switch
          v-model="hasDirectEnrollment"
          has-label
        />
      </z-input-field>

      <div v-if="hasDirectEnrollment">
        <z-input-field
          label="Qual identificador da matrícula direta?"
        >
          <z-input
            type="text"
            v-model="directEnrollmentLabel"
          />
        </z-input-field>

        <z-checkbox v-model="directEnrollmentBillingIntegration">
          <z-text
            size="medium"
            color="major"
          >
            Filtro de integração com Billing
          </z-text>
        </z-checkbox>

        <z-checkbox v-model="directEnrollmentMinSeats">
          <z-text
            size="medium"
            color="major"
          >
            Filtro de número mínimo de bolsas
          </z-text>
        </z-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  computed: {
    ...mapFields('CreateAdmissionConfigModalModule', [
      'enemCutScore',
      'enemCutYear',
      'essayCutYear',
      'digitalAdmissionCutScore',
      'clicksignTemplateId',
      'hasVestibular',
      'hasDirectEnrollment',
      'directEnrollmentBillingIntegration',
      'directEnrollmentMinSeats',
      'directEnrollmentLabel',
    ]),
    isValidStep() {
      return !this.hasErrorEnemCutScore && !this.hasErrorEnemCutYear && !this.hasErrorDigitalAdmissionCutScore && !this.hasErrorEssayCutYear;
    },
    hasErrorEnemCutScore() {
      return this.enemCutScore > 1000 || this.enemCutScore < 0;
    },
    hasErrorEnemCutYear() {
      return this.enemCutYear > 20 || this.enemCutYear < 0;
    },
    hasErrorEssayCutYear() {
      return this.essayCutYear && this.essayCutYear < 0;
    },
    hasErrorDigitalAdmissionCutScore() {
      return this.digitalAdmissionCutScore > 1000 || this.digitalAdmissionCutScore < 0;
    },

  },
  watch: {
    isValidStep(isValidStep) {
      if (isValidStep) {
        this.setDisableNextButtonStep03(false);
      } else {
        this.setDisableNextButtonStep03(true);
      }
    },
  },
  methods: {
    ...mapActions('CreateAdmissionConfigModalModule', [
      'setDisableNextButtonStep03',
    ]),
  },
};
</script>

<style lang="scss">
@import "@quero/zilla-core/src/utils/_index.scss";

.create-admission-config-modal-contranct-and-essay__container {
  display: flex;
  justify-content: space-between;
}

.create-admission-config-modal-contranct-and-essay__required_selection_process {
  width: 256px;
}

.create-admission-config-modal-contranct-and-essay__has-direct-enrollment {
  width: 232px;
}

.create-admission-config-modal-contranct-and-essay__essay-content,
.create-admission-config-modal-contranct-and-essay__contract-content {
  width: 384px;
}

.create-admission-config-modal-contranct-and-essay__input-field {
  margin-bottom: var(--space-large);
}
</style>
