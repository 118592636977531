import { InputField } from '@shared/legacy/components/form/input-field';
import greaterThanOrEqual from '@shared/utils/validators/greater-than-or-equal.js';
import isInteger from '@shared/utils/validators/is-integer';
import Step from '@stock/legacy/components/stepper/step';
import template from '@stock/legacy/components/modals/offer-list-actions/inline-steps/restock/steps/edit/template.ejs';
import validator from '@shared/utils/validator';
import { scope } from '@shared/utils/jquery';

const $ = scope('.js-edit-step-wrapper');

export class EditStep extends Step {
  constructor(offer, restockValue) {
    super({ template });
    this.offer = offer;
    this.initialRestockValue = restockValue;
  }

  afterRender() {
    this.$seatsCountInput = $('.js-restock-seats-count');
    this.$editRules = $('.js-edit-rules');
    this.$nextButton = $('.js-next-button');
    this.inputField = new InputField(this.$seatsCountInput);
    this.setupRadioButton();
    this.setupButtons();
    this.setupInput();
    this.hideRules();
    this.setupInitialInput();
    this.setupBackButton();
  }

  get seatsCount() {
    return parseInt(this.$seatsCountInput.val(), 10) || 0;
  }

  setupRadioButton() {
    const $typeaction = $('.js-type-action');
    const $inputs = $('.js-typeaction-input');

    $inputs.on('change', (event) => {
      this.action = event.target.value;
      $typeaction.text(this.action);
      this.setMessageDisclaimer();
      this.triggerSeatsValidation();
    });

    $('.js-typeaction-input:checked').trigger('change');
  }

  setMessageDisclaimer() {
    const $disclaimer = $('.js-type-disclaimer');
    const messageMap = {
      '+': 'Informe a quantidade que você quer <strong>somar</strong> ao estoque atual',
      '-': 'Informe a quantidade que você quer <strong>subtrair</strong> do estoque atual',
    };
    $disclaimer.html(messageMap[this.action]);
  }

  setupButtons() {
    this.$nextButton.click(() => super.next());
  }

  setupInput() {
    this.applyErrorValidator();

    this.$seatsCountInput.on(
      'change',
      ({ target: { value } }) => this.setStepAttribute({ seatsAmount: this.getAppliedValueFormated(value) }),
    );
    this.triggerSeatsValidation();
  }

  triggerSeatsValidation() {
    if (this.$seatsCountInput.val() !== '') {
      this.$seatsCountInput.trigger('change');
    }
  }

  applyErrorValidator() {
    validator(this.$seatsCountInput, {
      validations: [isInteger, greaterThanOrEqual(1), this.validateSeats.bind(this)],
      whenValid: () => this.onInputValid(),
      whenInvalid: () => this.onInputInvalid(),
    });
  }

  validateSeats() {
    if (this.action === '-' && this.seatsCount > this.offer.currentAvailableSeats) {
      return false;
    }
    return true;
  }

  onInputValid() {
    this.$nextButton.prop('disabled', false);
    this.inputField.removeError();
    this.hideRules();
  }

  onInputInvalid() {
    this.$nextButton.prop('disabled', true);
    this.inputField.showError();
    if (this.seatsCount) {
      this.showRules();
    }
  }

  showRules() {
    this.$editRules.removeClass('u-visibility-hidden');
  }

  hideRules() {
    this.$editRules.addClass('u-visibility-hidden');
  }

  setStepAttribute(attributes) {
    super.setAttribute(attributes);
  }

  getAppliedValueFormated(appliedValue) {
    const sign = $('.js-typeaction-input:checked').val();
    return sign === '-' ? Number(appliedValue * -1) : Number(appliedValue);
  }

  setupInitialInput() {
    this.$seatsCountInput.val(this.initialRestockValue);
    this.triggerSeatsValidation();
  }

  setupBackButton() {
    $('.js-back-button').on('click', () => {
      this.event.emit('previous');
    });
  }
}
