import { queroAlunosAPI } from '@stock/api/quero-alunos-api.js';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Create offer creation spreadsheet
 *
 * @typedef Success
 * @property {number} offerCreationSheetId
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function createOfferCreationSpreadsheet() {
  return queroAlunosAPI.createOfferCreationSpreadsheet()
    .then(({ data }) => ({ offerCreationSheetId: data.data.offerCreationSheetId }))
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[createOffers] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

export {
  createOfferCreationSpreadsheet,
};
