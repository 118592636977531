import moment from 'moment';

function getDateInterval(startDate, endDate) {
  const now = moment(startDate);
  const end = moment(endDate);
  const duration = moment.duration(now.diff(end));
  const days = duration.asDays();
  return Math.round(days);
}

function getDayMessage(message, date) {
  const daysInterval = this.getDateInterval(new Date(), date);
  if (daysInterval === 0) return '';
  if (daysInterval === 1) return `${message}${daysInterval} dia`;
  return `${message}${daysInterval} dias`;
}

export const DateUtils = { getDateInterval, getDayMessage };
