import {
  arrayOf, shape, integer, oneOf, string, custom,
} from 'vue-types';

function stringOrNull(value) {
  return typeof value === 'string' || value === null;
}

const offerCreationSpreadsheetStatus = [
  'creating',
  'created',
  'editing',
  'error',
];

const offerCreateRequestStatus = [
  'pending',
  'failed',
  'finished',
];

const SheetModel = shape({
  universityUserId: integer().isRequired,
  universityUserName: string().isRequired,
  createdAt: string().isRequired,
  name: string().isRequired,
  status: oneOf([...offerCreationSpreadsheetStatus, ...offerCreateRequestStatus]).isRequired,
  id: integer().isRequired,
  uuid: custom(stringOrNull),
  sheetUrl: custom(stringOrNull),
});

const SheetsModel = arrayOf(SheetModel);

export {
  SheetsModel,
  SheetModel,
};
