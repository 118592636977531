<template>
  <DescriberCard
    :title="'Filtro de ofertas'"
    :description="'Aqui você indica qual será o escopo dos seus benefícios.'"
    :icon="['fas', 'map-marker-alt']"
    class="offer-filter-list"
  >
    <template v-if="readyToEdit && !hasOfferFilter">
      <z-button
        @click="handleCreateOfferFilterPanel"
        behavior="block"
        :icon="['fas', 'plus']"
      >
        Criar novo filtro de oferta
      </z-button>
    </template>

    <template v-else-if="hasOfferFilter">
      <z-button-group behavior="vertical">
        <z-button
          @click="handleOpenOfferFilterModal(item)"
          v-for="(item, index) in offerFiltersItems"
          :key="index"
        >
          <div class="offer-filter-list__text">
            {{ OfferFilterBusiness.showMainDescription(item) }}
          </div>
        </z-button>
      </z-button-group>
    </template>
  </DescriberCard>
</template>

<script>
import { DescriberCard } from '@quero-turbo/components/describer-card';
import { OfferFilterBusiness } from '@quero-turbo/business';
import { ShowOfferFilterModal } from '@quero-turbo/modules/offer-filter-components';
import { mapFields } from 'vuex-map-fields';
import { mapGetters } from 'vuex';

export default {
  components: { DescriberCard },
  computed: {
    OfferFilterBusiness: () => OfferFilterBusiness,
    ...mapFields('ShowBRGPageModule', ['businessRuleGroup']),
    ...mapGetters('ShowBRGPageModule', [
      'offerFiltersItems',
      'readyToEdit',
      'hasOfferFilter',
    ]),
  },
  methods: {
    handleCreateOfferFilterPanel() {
      this.$router.push(`/brgs/${this.businessRuleGroup.id}/create-offer-filter`);
    },
    handleOpenOfferFilterModal($event) {
      this.$upaHelperModal.fire({
        mode: 'custom',
        options: { component: ShowOfferFilterModal, offerFilter: $event },
      });
    },
  },
};
</script>

<style>
.offer-filter-list {
  min-height: 100%;
}

.offer-filter-list__action-text,
.offer-filter-list__item-text {
  margin-left: var(--space-small);
}
</style>
