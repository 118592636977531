import { UPACan } from '@shared/components/upa-ability-components';
import { UPAAbilityAPI } from './upa-ability-api';

class UPAAbilityPlugin {
  static install(Vue, globalOptions = {}) {
    const API = UPAAbilityAPI(Vue, globalOptions);
    Object.defineProperty(Vue.prototype, '$upaAbility', { value: API });
    Object.defineProperty(Vue, '$upaAbility', { value: API });

    Vue.component('upa-can', UPACan);
  }
}

export { UPAAbilityPlugin };
