<template>
  <GridContainer>
    <GridItem>
      <HeaderBox title="Menu principal" />
    </GridItem>

    <GridItem
      :column="4"
      class="quero-turbo-menu__container-item"
    >
      <z-card class="quero-turbo-menu__cta-card">
        <z-title
          class="quero-turbo-menu__cta-card-title"
          size="small"
        >
          Negociação
        </z-title>

        <div>
          <z-button
            behavior="block"
            tag="router-link"
            to="/create-deal"
          >
            Criar configuração da negociação
          </z-button>
        </div>
      </z-card>
    </GridItem>

    <GridItem
      :column="4"
      class="quero-turbo-menu__container-item"
    >
      <z-card class="quero-turbo-menu__cta-card">
        <z-title
          class="quero-turbo-menu__cta-card-title"
          size="small"
        >
          Configurações da negociação
        </z-title>

        <div>
          <z-button
            behavior="block"
            tag="router-link"
            to="/brgs"
            class="quero-turbo-menu__button"
          >
            Listar configurações
          </z-button>
        </div>
      </z-card>
    </GridItem>

    <upa-can
      action="read"
      subject="new_features_quero_turbo"
      #default="{ permitted }"
    >
      <GridItem
        :column="4"
        class="quero-turbo-menu__container-item"
        v-if="permitted"
      >
        <z-card class="quero-turbo-menu__cta-card">
          <z-title
            class="quero-turbo-menu__cta-card-title"
            size="small"
          >
            Modal de ativação do Quero Turbo
          </z-title>

          <div>
            <z-button
              @click="handleOpenModal"
              behavior="block"
              class="quero-turbo-menu__button"
            >
              Abrir
            </z-button>
          </div>
        </z-card>

        <QueroTurboActivationModal
          v-if="permitted"
          :open="queroTurboActivationModalOpen"
          @close-modal="handleOpenModal"
        />
      </GridItem>
    </upa-can>
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { HeaderBox } from '@shared/components/header-box';
import { QueroTurboActivationModal } from '@quero-turbo/modules/quero-turbo-activation-modal';

export default {
  components: {
    GridContainer,
    GridItem,
    HeaderBox,
    QueroTurboActivationModal,
  },
  data() {
    return {
      queroTurboActivationModalOpen: false,
    };
  },
  methods: {
    handleOpenModal() {
      this.queroTurboActivationModalOpen = !this.queroTurboActivationModalOpen;
    },
  },
};
</script>

<style>
.quero-turbo-menu__container-item {
  display: flex;
}

.quero-turbo-menu__cta-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.quero-turbo-menu__cta-card-title {
  margin-bottom: var(--space-medium);
}

.quero-turbo-menu__button:not(:last-child) {
  margin-bottom: var(--space-medium);
}
</style>
