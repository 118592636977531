import { QueroDealsV1API } from '@quero-turbo/api/quero-deals-v1-api';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Get all Turbo Accounts
 *
 * @typedef Success
 * @property {string} message
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function getAll() {
  return QueroDealsV1API.TurboAccount.indexTurboAccount()
    .then(({ data }) => ({
      turboAccounts: parseResult(data),
    }))
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[indexTurboAccount] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

const parseResult = data => data.turboAccount.map(element => ({
  ...element,
  name: `${element.id} - ${element.name}`,
}));

export {
  getAll,
};
