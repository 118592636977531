<template>
  <component
    :is="tag"
    :class="{
      ['grid-container--padding']: !noPadding,
      ['grid-container--dense']: dense,
    }"
    class="grid-container"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style style="scss">
.grid-container {
  --grid-column-gap: var(--size-medium);
  --grid-row-gap: var(--size-medium);
  --grid-padding: 0;
  --grid-auto-flow: row;

  column-gap: var(--grid-column-gap);
  display: grid;
  grid-auto-flow: var(--grid-auto-flow);
  grid-template-columns: repeat(12, 1fr);
  padding: var(--grid-padding);
  row-gap: var(--grid-row-gap);
  width: 100%;
}

.grid-container--padding {
  --grid-padding: var(--size-medium);
}

.grid-container--dense {
  --grid-auto-flow: dense;
}
</style>
