<template>
  <z-popover
    position="bottom-center"
    @click="trackKnowMoreAboutCompetitiveness"
  >
    <template #target>
      <z-text
        tag="span"
        color="interactive"
      >
        Veja como funciona
      </z-text>
    </template>

    <template #content>
      <ul class="oc-offer-marketplace-popover-list">
        <z-title
          tag="li"
          class="oc-offer-marketplace-popover-list__item"
        >
          Como o Desconto Inteligente funciona?
        </z-title>

        <z-dynamic-content
          :class="[
            'oc-offer-marketplace-popover-list__item',
            'oc-offer-marketplace-popover-list__item-dynamic-text'
          ]"
        >
          <p>
            <strong>
              Os descontos são recomendados a partir de uma análise no histórico de vendas do curso no marketplace e nos valores praticados por seus concorrentes.
            </strong>
          </p>
          <p>
            Quando percebemos que o ajuste de preço pode aumentar a atratividade de suas ofertas, iremos recomendá-lo. Você escolhe a faixa de preço disponível que preferir.
          </p>
          <p><strong>Exemplo:</strong></p>
        </z-dynamic-content>

        <z-text
          :class="[
            'oc-offer-marketplace-popover-list__item',
            'oc-offer-marketplace-popover-list__item--centralized',
            'oc-offer-marketplace-popover-list__item-bar'
          ]"
          tag="li"
          size="micro"
          color="inverted"
        >
          <strong
            v-for="competitive in competitiveness"
            :key="competitive.value"
            class="oc-offer-marketplace-popover-list__item-bar-label"
          >
            {{ competitive.atractiveness }}
          </strong>
        </z-text>

        <z-text
          :class="[
            'oc-offer-marketplace-popover-list__item',
            'oc-offer-marketplace-popover-list__item--centralized'
          ]"
          tag="li"
          size="small"
        >
          <strong
            v-for="competitive in competitiveness"
            :key="competitive.value"
          >
            <z-icon
              :icon="['fas','usd-square']"
              :class="`oc-offer-marketplace-popover-icon--${competitive.value}`"
            />
            {{ competitive.label }}
          </strong>
        </z-text>
      </ul>
    </template>
  </z-popover>
</template>

<script>
import { offerCreationTracking } from '@stock/metrics';

export default {
  computed: {
    competitiveness() {
      return [
        { label: 'Pouco competitivo', value: 'low', atractiveness: 'Menos atratividade' },
        { label: 'Competitivo', value: 'medium', atractiveness: 'Mais atratividade' },
        { label: 'Muito competitivo', value: 'high', atractiveness: 'Muito mais atratividade' },
      ];
    },
  },
  methods: {
    trackKnowMoreAboutCompetitiveness() {
      offerCreationTracking.knowMoreAboutCompetitiveness();
    },
  },
};
</script>

<style lang="scss" scoped>
.oc-offer-marketplace-popover-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.oc-offer-marketplace-popover-list__item {
  margin-bottom: var(--space-medium);
  text-align: initial;

  &:nth-child(2) {
    margin-bottom: var(--space-large);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.oc-offer-marketplace-popover-list__item--centralized {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.oc-offer-marketplace-popover-list__item-dynamic-text {
  font-size: var(--size-font-small);
}

.oc-offer-marketplace-popover-list__item-bar {
  background: linear-gradient(
    -90deg,
    var(--color-green-400) 0%,
    var(--color-green-400) 22%,
    var(--color-cyan-500) 35%,
    var(--color-cyan-500) 66%,
    var(--color-orange-500) 77%,
    var(--color-orange-500) 100%
  );
  border-radius: var(--size-border-radius-large);
  min-height: var(--size-medium);
  padding: var(--space-extra-small) var(--space-medium);
  width: 516px;
}

.oc-offer-marketplace-popover-list__item-bar-label {
  flex: 1;

  &:not(:first-child):not(:last-child){
    text-align: center;
  }

  &:last-child {
    text-align: end;
  }
}

.oc-offer-marketplace-popover-icon--low {
  color: var(--color-orange-500);
}

.oc-offer-marketplace-popover-icon--medium {
  color: var(--color-cyan-500);
}

.oc-offer-marketplace-popover-icon--high {
  color: var(--color-green-400);
}
</style>
