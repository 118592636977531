<template>
  <GridContainer no-padding>
    <GridItem :column="6">
      <GridContainer no-padding>
        <GridItem>
          <z-input-field
            label="A instituição cobra taxa de matrícula?*"
            class="step03-enrollment__input-field"
          >
            <z-dropdown-input
              placeholder="Selecione o tipo de taxa"
              :options="BillingConfigBusiness.getAmountTypeTextList()"
              v-model="amountType"
            />
          </z-input-field>
        </GridItem>

        <GridItem>
          <z-input-field
            label="A taxa de matrícula equivale a primeira mensalidade do curso?*"
            class="step03-enrollment__input-field"
            :class="{
              'step03-enrollment__input-field--disabled': cannotEditEnrollmentIsFirstPayment
            }"
          >
            <z-toggle-switch
              :has-label="true"
              v-model="equivalentToFirstBill"
              :disabled="cannotEditEnrollmentIsFirstPayment"
            />
          </z-input-field>
        </GridItem>

        <GridItem>
          <z-input-field
            label="Qual é o valor da taxa de matrícula?*"
            class="step03-enrollment__input-field"
            :class="{
              'step03-enrollment__input-field--disabled': institutionNotHasEnrollmentFee
            }"
          >
            <z-input
              type="number"
              placeholder="Ex.: 00,00"
              v-model="amount"
              :is-disabled="institutionNotHasEnrollmentFee"
            />
          </z-input-field>
        </GridItem>
      </GridContainer>
    </GridItem>

    <GridItem :column="6">
      <GridContainer no-padding>
        <GridItem>
          <z-input-field
            label="O aluno perde a bolsa em caso de atraso de mensalidade?"
            class="step03-enrollment__input-field"
          >
            <z-toggle-switch
              has-label
              v-model="canLoseScholarship"
            />
          </z-input-field>
        </GridItem>

        <GridItem>
          <z-input-field
            label="Em quantos dias o aluno perde a bolsa?*"
            class="step03-enrollment__input-field"
            :class="{
              'step03-enrollment__input-field--disabled': cannotLoseScholarship
            }"
          >
            <z-input
              type="number"
              placeholder="00"
              v-model="lossDelay"
              :is-disabled="cannotLoseScholarship"
            />

            <div class="step03-enrollment__input-field-wrapper">
              <z-radio
                :checked="typeOfDay === lossDelayType"
                :disabled="cannotLoseScholarship"
                :key="index"
                :value="typeOfDay"
                @change="lossDelayType = typeOfDay"
                behavior="inline"
                class="step03-enrollment__radio-label"
                v-for="(typeOfDay, index) in BillingConfigBusiness.getLossDelayTypeTextList()"
              >
                {{ typeOfDay }}
              </z-radio>
            </div>
          </z-input-field>

          <z-input-field
            label="Regra de perda da bolsa*"
            class="step03-enrollment__input-field"
            :class="{
              'step03-enrollment__input-field--disabled': cannotLoseScholarship
            }"
          >
            <z-dropdown-input
              placeholder="Seleciona uma regra"
              :options="BillingConfigBusiness.getRuleToLoseScholarshipTextList()"
              v-model="rule"
              :disabled="cannotLoseScholarship"
            />
          </z-input-field>
        </GridItem>
      </GridContainer>
    </GridItem>
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { createHelpers } from 'vuex-map-fields';
import { BillingConfigBusiness } from '@quero-turbo/business';

const { mapFields } = createHelpers({
  getterType: 'getStep03Field',
  mutationType: 'updateStep03Field',
});

export default {
  components: {
    GridContainer,
    GridItem,
  },
  computed: {
    BillingConfigBusiness: () => BillingConfigBusiness,
    ...mapFields('CreateBillingConfigModalModule', [
      'admissionPaymentRule.amountType',
      'admissionPaymentRule.equivalentToFirstBill',
      'admissionPaymentRule.amount',
      'scholarshipLosingRule.lossDelayType',
      'scholarshipLosingRule.lossDelay',
      'scholarshipLosingRule.canLoseScholarship',
      'scholarshipLosingRule.rule',
      'stepConfig.validStep03',
    ]),
    cannotLoseScholarship() {
      return !this.canLoseScholarship;
    },
    cannotEditEnrollmentIsFirstPayment() {
      return this.amountType === 'Sem matrícula';
    },
    hasInstitutionEnrollmentFee() {
      return (['Porcentagem sem desconto', 'Porcentagem com desconto', 'Valor fixo']
        .includes(this.amountType));
    },
    institutionNotHasEnrollmentFee() {
      return (['Valor sem desconto', 'Valor com desconto', 'Sem matrícula']
        .includes(this.amountType)) || !this.amountType;
    },
    canIAdvanceStep() {
      const isValidEnrollment = Boolean(
        this.amountType
        && ((this.hasInstitutionEnrollmentFee && this.amount) || this.institutionNotHasEnrollmentFee),
      );

      const isValidLostScholarship = Boolean(
        (!this.canLoseScholarship)
          || (
            this.canLoseScholarship
            && this.rule
            && this.lossDelay
            && this.lossDelayType
          ),
      );
      return Boolean(
        isValidEnrollment
        && isValidLostScholarship,
      );
    },
  },
  watch: {
    amountType() {
      if (this.cannotEditEnrollmentIsFirstPayment) this.equivalentToFirstBill = false;
      if (this.institutionNotHasEnrollmentFee) this.amount = '';
    },
    canLoseScholarship(newValue) {
      if (!newValue) {
        this.lossDelay = '';
        this.lossDelayType = null;
        this.rule = null;
      }
    },
    canIAdvanceStep(newValue) {
      this.validStep03 = newValue;
    },
  },
};
</script>

<style>
.step03-enrollment__input-field {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.step03-enrollment__input-field-wrapper {
  display: flex;
  margin-top: var(--space-medium);
}

.step03-enrollment__input-field--disabled {
  cursor: not-allowed;
  pointer-events: all;
}

.step03-enrollment__radio-label:not(:last-child) {
  margin-right: var(--space-medium);
}
</style>
