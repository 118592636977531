<template>
  <div class="oc-review-offer">
    <Header
      name="Revisão de ofertas"
      :current-step-number="5"
      :last-step-number="5"
    />

    <ul class="oc-review-offer__category-list">
      <li class="oc-review-offer__category-item">
        <img
          class="oc-review-offer__logo"
          :src="university.logoUrl"
          alt="ies logo"
        >
      </li>

      <z-divider />

      <li class="oc-review-offer__category-item">
        <z-title
          class="oc-review-offer__category-title"
          tag="h4"
        >
          {{ sharedCourse.name }}
        </z-title>

        <ul class="oc-review-offer__info-course">
          <li
            class="oc-review-offer__info-course-item"
            v-if="sharedCourse.level"
          >
            <z-icon
              class="oc-review-offer__info-icon"
              :icon="['fal','file-certificate']"
              size="medium"
            />

            <z-text
              size="small"
              tag="span"
            >
              {{ sharedCourse.level }}
            </z-text>
          </li>

          <li
            class="oc-review-offer__info-course-item"
            v-if="sharedCourse.kind"
          >
            <z-icon
              class="oc-review-offer__info-icon"
              :icon="['fal','chalkboard-teacher']"
              size="medium"
            />

            <z-text
              size="small"
              tag="span"
            >
              {{ sharedCourse.kind }}
            </z-text>
          </li>

          <li
            class="oc-review-offer__info-course-item"
            v-if="maxPeriodsLabel"
          >
            <z-icon
              class="oc-review-offer__info-icon"
              :icon="['fal','hourglass-half']"
              size="medium"
            />

            <z-text
              size="small"
              tag="span"
            >
              {{ maxPeriodsLabel }}
            </z-text>
          </li>

          <li
            class="oc-review-offer__info-course-item"
            v-if="totalHoursLabel"
          >
            <z-icon
              class="oc-review-offer__info-icon"
              :icon="['fal','clock']"
              size="medium"
            />

            <z-text
              size="small"
              tag="span"
            >
              {{ totalHoursLabel }}
            </z-text>
          </li>
        </ul>
      </li>

      <z-divider />

      <li
        class="oc-review-offer__category-item"
        v-if="sharedCampus"
      >
        <z-title
          class="oc-review-offer__category-title"
          tag="h4"
          v-if="sharedCampus.name"
        >
          {{ sharedCampus.name }}
        </z-title>

        <div class="oc-review-offer__info-campus">
          <z-icon
            class="oc-review-offer__info-icon"
            :icon="['fal','map-marker-alt']"
            size="medium"
          />

          <z-text
            size="small"
            tag="span"
            v-if="sharedCampus.fullAddress"
          >
            {{ sharedCampus.fullAddress }}
          </z-text>

          <z-text
            size="small"
            tag="span"
            v-else
          >
            Virtual
          </z-text>
        </div>
      </li>
    </ul>

    <z-card class="oc-review-offer__card">
      <header class="oc-review-offer__card-marketplace">
        <img
          src="https://assets.guiadacarreira.com.br/wp-content/uploadedfiles/uploads/2019/01/Logo-QueroBolsa-Gradua%C3%A7%C3%A3o-RGB-Colorido_Positivo.png"
          width="150"
          alt="Quero Bolsa"
        >
      </header>

      <div
        class="oc-review-offer__card-description"
        ref="$formTable"
      >
        <form-table
          :headers="tableHeaders"
          :rows="tableRows"
          class="oc-review-offer__table"
        />
      </div>

      <footer class="oc-review-offer__card-footer">
        <ul
          class="oc-review-offer__footer-list"
          v-if="hasOfferSpecialConditions"
        >
          <z-text
            class="oc-review-offer__footer-list-item z-ovr-text--fw-medium"
            tag="li"
          >
            Benefícios adicionais para os alunos:
          </z-text>

          <li
            class="oc-review-offer__footer-list-item"
            v-for="offerSpecialConditionLabel in offerSpecialConditionsLabels"
            :key="offerSpecialConditionLabel"
          >
            <z-tag
              :value="offerSpecialConditionLabel"
              :is-selected="true"
            />
          </li>
        </ul>

        <ul class="oc-review-offer__footer-list">
          <z-text
            class="oc-review-offer__footer-list-item z-ovr-text--fw-medium"
            tag="li"
          >
            Oferta(s) válida(s) a partir de:
          </z-text>

          <li
            class="oc-review-offer__footer-list-item"
            v-for="enrollmentSemester in enrollmentSemestersLabel"
            :key="enrollmentSemester"
          >
            <z-tag
              :value="enrollmentSemester"
              :is-selected="true"
            />
          </li>
        </ul>
      </footer>
    </z-card>

    <footer class="oc-review-offer__footer">
      <z-button
        @click="prev"
        variant="tertiary"
      >
        Voltar
      </z-button>

      <z-button @click="next">
        Confirmar e publicar
      </z-button>
    </footer>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {
  enrollmentSemester,
  offerSpecialCondition,
  period,
  shiftRules,
} from '@stock/business';
import { currencyFormat, decimalFormat } from '@shared/utils/number_format';
import { offerCreationTracking } from '@stock/metrics';
import { notifierMixin } from '@stock/utils/mixins/notifier';
import FormTable from '../form-table';
import { competitivenessMixin } from '../mixins/competitiveness';
import Header from '../step-header';

export default {
  components: {
    FormTable,
    Header,
  },
  mixins: [competitivenessMixin, notifierMixin],
  data() {
    return {
      lastColumnStyle: {
        '--last-column-width': '168px',
      },
      tableHeaders: [
        {
          text: 'Turno',
          value: 'shift',
          type: 'text',
          align: 'left',
        },
        {
          text: 'Quantidades de vagas no QueroBolsa',
          value: 'totalSeats',
          type: 'text',
          align: 'left',
        },
        {
          text: 'Desconto do QueroBolsa (%)',
          value: 'discountPercentage',
          type: 'text',
          align: 'left',
        },
        {
          text: 'Valor com Desconto no QueroBolsa',
          value: 'offeredPrice',
          type: 'text',
          align: 'left',
        },
      ],
      tableRows: [],
    };
  },
  computed: {
    ...mapState('SessionModule', ['university']),
    ...mapState('OfferCreationModule', ['sharedCourse', 'sharedOffer', 'sharedCampus', 'universityOffersByShift']),
    ...mapGetters('OfferCreationModule', ['shifts']),
    maxPeriodsLabel() {
      return period.maxPeriodsLabel(this.sharedCourse);
    },
    enrollmentSemestersLabel() {
      return this.sharedOffer.enrollmentSemesters.map(semester => enrollmentSemester.getLabel(
        { enrollmentSemester: semester.semester },
      ));
    },
    totalHoursLabel() {
      if (!this.sharedOffer.totalHours) return null;
      const label = this.sharedOffer.totalHours === '1' ? 'hora' : 'horas';
      const decimalHours = decimalFormat(this.sharedOffer.totalHours);
      return `${decimalHours} ${label}`;
    },
    hasOfferSpecialConditions() {
      return this.sharedOffer.offerSpecialConditions
          && this.sharedOffer.offerSpecialConditions.length > 0;
    },
    offerSpecialConditionsLabels() {
      return this.sharedOffer.offerSpecialConditions.map(key => offerSpecialCondition.getLabel(key));
    },
  },
  mounted() {
    this.setupTableRows();
  },
  methods: {
    setupTableRows() {
      Object.entries(this.universityOffersByShift).map(([
        shiftKey,
        { totalSeats, discountPercentage, offeredPrice },
      ]) => this.tableRows.push({
        key: shiftKey,
        shift: shiftRules.shiftTranslate(shiftKey),
        totalSeats,
        discountPercentage: this.getFormattedDiscountPercentage(discountPercentage),
        offeredPrice: currencyFormat(offeredPrice),
      }));
    },
    getFormattedDiscountPercentage(discountPercentage) {
      return `${discountPercentage}%`;
    },
    prev() {
      this.$emit('prev');
    },
    next() {
      if (navigator.onLine || navigator.onLine === undefined) {
        offerCreationTracking.sentRequisitionToCreateOffer();
        this.$emit('next');
      } else {
        this.notifyOfflineMessage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@quero/zilla-core/src/utils/_index.scss";

.oc-review-offer {
  color: var(--color-font-major);
}

.oc-review-offer__category-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  @media (min-width: $screen-desktop) {
    flex-direction: row;
    min-height: var(--size-extra-jumbo);
    margin-bottom: var(--space-extra-large);
  }
}

.oc-review-offer__category-item {
  flex-basis: 600px;
  padding: var(--space-medium);
  position: relative;
  max-height: fit-content;

  @media (min-width: $screen-desktop) {
    min-height: 50px;
    padding: 0 var(--space-large);
  }

  &:first-child {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-basis: 112px;

    @media (min-width: $screen-desktop) {
      justify-content: flex-start;
      padding-left: 0;
    }
  }

  &:last-child{
    flex-basis: 400px;
  }
}

.oc-review-offer__category-title {
  margin-bottom: var(--space-small);
  margin-top: 0;
}

.oc-review-offer__info-campus {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.oc-review-offer__info-course {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
}

.oc-review-offer__info-course-item {
  margin-right: var(--space-medium);

  @media (min-width: $screen-desktop) {
    white-space: nowrap;
  }

  &:last-child{
    margin-right: 0;
  }
}

.oc-review-offer__info-icon {
  margin-right: var(--space-small);
}

.oc-review-offer__logo {
  max-height: 100%;
  max-width: 100%;
}

.oc-review-offer__card {
  margin-bottom: var(--space-jumbo);
  padding: 0;
}

.oc-review-offer__card-marketplace {
  padding: var(--space-large);
}

.oc-review-offer__card-description {
  --very-competitive-icon-color: var(--color-green-400);
  --uncompetitive-icon-color: var(--color-orange-500);
  --competitive-icon-color: var(--color-cyan-500);
  --without-competitive-information-color: var(--color-gray-300);
  background-color: var(--color-gray-000);
}

.oc-review-offer__card-footer {
  display: flex;
  flex-direction: column;
  padding: var(--space-large);
}

.oc-review-offer__footer-list {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--space-medium);
  padding: 0;
  list-style: none;

  &:last-child{
    margin: 0;
  }
}

.oc-review-offer__footer-list-item {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: var(--space-medium);
  }
}

.oc-review-offer__footer {
  display: flex;
  justify-content: space-between;
}
</style>
