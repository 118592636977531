<template>
  <section>
    <z-title
      size="extra-small"
      class="create-offer-filter-painel-deal__title"
    >
      Dados do curso
    </z-title>

    <GridContainer class="create-offer-filter-painel-deal__row">
      <GridItem :column="3">
        <CanonicalCourseAutocomplete
          @select="$emit('select', { event: $event, name: 'canonicalCoursesSelected' })"
          @remove="$emit('remove', { event: $event, name: 'canonicalCoursesSelected' })"
          :value="canonicalCoursesSelected"
        />
      </GridItem>

      <GridItem :column="3">
        <KindAutocomplete
          @select="$emit('select', { event: $event, name: 'kindsSelected' })"
          @remove="$emit('remove', { event: $event, name: 'kindsSelected' })"
          :value="kindsSelected"
        />
      </GridItem>

      <GridItem :column="3">
        <LevelAutocomplete
          @select="$emit('select', { event: $event, name: 'levelsSelected' })"
          @remove="$emit('remove', { event: $event, name: 'levelsSelected' })"
          :value="levelsSelected"
        />
      </GridItem>

      <GridItem :column="3">
        <ShiftAutocomplete
          @select="$emit('select', { event: $event, name: 'shiftsSelected' })"
          @remove="$emit('remove', { event: $event, name: 'shiftsSelected' })"
          :value="shiftsSelected"
        />
      </GridItem>

      <GridItem :column="3">
        <CourseIdsInput
          :value="coursesSelected"
          @select="$emit('select', { event: $event, name: 'coursesSelected' })"
          @remove="$emit('remove', { event: $event, name: 'coursesSelected' })"
        />
      </GridItem>

      <GridItem :column="3">
        <EnrollmentSemesterAutocomplete
          :value="enrollmentSemesterSelected"
          @select="$emit('select', { event: $event, name: 'enrollmentSemesterSelected' })"
          @remove="$emit('remove', { event: $event, name: 'enrollmentSemesterSelected' })"
        />
      </GridItem>

      <GridItem :column="3">
        <MaxPaymentsInput
          @select="$emit('select', { event: $event, name: 'maxPaymentsSelected' })"
          @remove="$emit('remove', { event: $event, name: 'maxPaymentsSelected' })"
          :value="maxPaymentsSelected"
        />
      </GridItem>

      <GridItem :column="3">
        <z-input-field label="Origem">
          <z-input
            :value="origin"
            @input="$emit('input-origin', $event)"
          />
        </z-input-field>
      </GridItem>
    </GridContainer>
  </section>
</template>

<script>
import { CanonicalCourseAutocomplete } from '@shared/components/autocompletes/canonical-course-autocomplete';
import { EnrollmentSemesterAutocomplete } from '@shared/components/autocompletes/enrollment-semester-autocomplete';
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { KindAutocomplete } from '@shared/components/autocompletes/kind-autocomplete';
import { LevelAutocomplete } from '@shared/components/autocompletes/level-autocomplete';
import { ShiftAutocomplete } from '@shared/components/autocompletes/shift-autocomplete';
import { MaxPaymentsInput } from '@quero-turbo/components/max-payments-input';
import { CourseIdsInput } from '@quero-turbo/components/course-ids-input';

export default {
  components: {
    CourseIdsInput,
    CanonicalCourseAutocomplete,
    EnrollmentSemesterAutocomplete,
    GridContainer,
    GridItem,
    KindAutocomplete,
    LevelAutocomplete,
    ShiftAutocomplete,
    MaxPaymentsInput,
  },
  props: {
    canonicalCoursesSelected: {
      type: Array,
      required: true,
    },
    kindsSelected: {
      type: Array,
      required: true,
    },
    levelsSelected: {
      type: Array,
      required: true,
    },
    shiftsSelected: {
      type: Array,
      required: true,
    },
    coursesSelected: {
      type: Array,
      required: true,
    },
    enrollmentSemesterSelected: {
      type: Array,
      required: true,
    },
    maxPaymentsSelected: {
      type: Array,
      required: true,
    },
    origin: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.create-offer-filter-painel-deal__title {
  margin-bottom: var(--space-medium);
}

.create-offer-filter-painel-deal__row {
  --grid-padding: 0;
}
</style>
