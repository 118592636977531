/* eslint-disable camelcase */
import $ from 'jquery';
import {
  defer, some, update, partial, union, forOwn, pull, forEach,
} from 'lodash';
import '@shared/utils/select2';
import { EmailInputField } from '@shared/legacy/components/form/email-input-field.js';

class UserForm {
  constructor(page) {
    if (page.length) {
      this.page = page;
      this.initMask();
      this.initUniversitySelect();
      this.initRolesSelect();
      this.initCampusSelect();
      this.initEducationGroupSelect();
      this.initKindSelect();
      this.initLevelSelect();
      this.initEmailValidation();
    }
  }

  initEmailValidation() {
    const $emailTextField = $('.js-email-validation');
    const inputField = new EmailInputField($emailTextField);
    const $submitButton = $('.js-submit-button');

    $(inputField).on('error', () => $submitButton.attr('disabled', true));
    $(inputField).on('valid', () => $submitButton.attr('disabled', false));
  }

  initMask() {
    const maskBehavior = val => (val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009');

    this.$('#university_user_phone').mask(maskBehavior, {
      onKeyPress: (val, e, field, options) => field.mask(maskBehavior(val), options),
    });
  }

  initRolesSelect() {
    const $select = this.rolesSelector;
    $select.select2({
      theme: 'bootstrap',
      minimumInputLength: 2,
      language: 'pt-BR',
      placeholder: 'Função',
    });
  }

  get rolesSelector() {
    return this.$('.js-user-roles-selector');
  }

  initUniversitySelect() {
    const $select = this.$('.universities-multi-select');
    $select.select2({
      theme: 'bootstrap',
      ajax: {
        url: '/util/autocomplete/universities',
        delay: 250,
      },
      minimumInputLength: 2,
      language: 'pt-BR',
      templateResult: UserForm.formatSelect2Result,
    }).on('change', (e) => {
      this.selectedUniversities = $(e.target).val() || [];
      this.fixCampuses();
    });

    defer(() => {
      this.selectedUniversities = $select.val() || [];
    });
  }

  initCampusSelect() {
    const $select = this.$('.js-campuses-multi-select');

    $select.select2({
      theme: 'bootstrap',
      ajax: {
        url: '/util/autocomplete/campuses',
        delay: 250,
        data: params => ({
          term: params.term,
          university_ids: this.selectedUniversities,
        }),
      },
      minimumInputLength: 3,
      language: 'pt-BR',
      templateResult: UserForm.formatSelect2Result,
    }).on('select2:select', (e) => {
      this.selectedCampuses = $(e.target).val() || [];
      this.addMapping(e.params.data);
    }).on('select2:unselect', (e) => {
      this.selectedCampuses = $(e.target).val() || [];
      this.dropMapping(e.params.data);
    });

    this.selectedCampuses = $select.val() || [];
    this.campusMapping = $select.data('campusMapping');
  }

  initEducationGroupSelect() {
    const $select = this.$('.education-group-select');
    $select.select2({
      theme: 'bootstrap',
      ajax: {
        url: '/util/autocomplete/education_groups',
        delay: 250,
      },
      minimumInputLength: 3,
      maximumSelectionLength: 1,
      language: 'pt-BR',
    }).on('change', () => {
      this.$('.universities-multi-select').val(null).trigger('change');
    });

    this.selectedUniversities = $select.val() || [];
  }

  initKindSelect() {
    const $select = this.$('.kinds-select');

    $select.select2({
      theme: 'bootstrap',
      language: 'pt-BR',
      placeholder: 'Todos',
    });
  }

  initLevelSelect() {
    const $select = this.$('.levels-select');

    $select.select2({
      theme: 'bootstrap',
      language: 'pt-BR',
      placeholder: 'Todos',
    });
  }

  userNeededByRole(roles) {
    return some(roles, (role) => {
      if (this.rolesSelector.val()) {
        return this.rolesSelector.val().indexOf(role) >= 0;
      }
      return false;
    });
  }

  addMapping({ university_id, id }) {
    const map = this.campusMapping;
    const key = String(university_id);
    const string_id = String(id);

    if (!map[key]) {
      map[key] = [string_id];
    } else {
      update(map, key, partial(union, _, [string_id]));
    }
  }

  dropMapping({ id }) {
    const map = this.campusMapping;
    const string_id = String(id);
    const empty = [];

    forOwn(map, (ids, uni_id) => {
      pull(ids, string_id);

      if (ids.length === 0) empty.push(uni_id);
    });

    forEach(empty, uni_id => delete map[uni_id]);
  }

  fixCampuses() {
    if (this.selectedCampuses.length === 0) {
      return;
    }

    const map = this.campusMapping;
    const empty = [];

    forOwn(map, (ids, uni_id) => {
      const included = this.selectedUniversities.some(e => e === uni_id);
      if (!included) {
        pull(this.selectedCampuses, ...ids);
        empty.push(uni_id);
      }
    });

    forEach(empty, uni_id => delete map[uni_id]);
    this.$('.js-campuses-multi-select').val(this.selectedCampuses).trigger('change');
  }


  $(selector) {
    return $(selector, this.page);
  }

  static formatSelect2Result(data) {
    if (!data.id) { return data.text; }
    let $result;
    if (data.text !== data.hint) {
      $result = $(`<span>${data.text}</span><span class=hint> - ${data.hint}</span>`);
    } else {
      $result = $(`<span>${data.text}</span>`);
    }
    return $result;
  }
}

export default UserForm;
