import $ from 'jquery';
import { createPasswordInput, createConfirmPasswordInput } from './input-factory';

class ResetPasswordForm {
  constructor(page) {
    this.$page = page;
    this.password = createPasswordInput();
    this.confirmPassword = createConfirmPasswordInput();
    this.$submit = $('button[type="submit"]', page);
    this.$messageError = $('.js-message-error');
    this.initPasswordValidation();
  }

  initPasswordValidation() {
    this.confirmPassword.on('keyup', () => {
      this.validatePassword();
    });
  }

  validatePassword() {
    const passwordLengthCheck = this.confirmPassword.value.length >= this.password.value.length;
    const passwordCheck = this.password.value !== this.confirmPassword.value;

    if (passwordCheck) {
      this.$submit.prop('disabled', true);
      if (passwordLengthCheck) {
        this.confirmPassword.showError('As senhas precisam ser iguais');
      } else {
        this.confirmPassword.removeError();
      }
    } else {
      this.$submit.prop('disabled', false);
      this.confirmPassword.removeError();
    }
  }
}

export default ResetPasswordForm;
