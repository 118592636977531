const sidebarSectionsBuilder = (currentStepName, rest) => {
  const firstSectionsItems = (currentStepName === 'step02' || currentStepName === 'step03')
    ? step01SectionBuilder(rest) : [];
  const secondSectionsItems = (currentStepName === 'step03')
    ? step02SectionBuilder(rest) : [];

  return [
    {
      title: 'DADOS',
      values: [
        ...firstSectionsItems,
        ...secondSectionsItems,
      ],
    },
  ];
};

const step01SectionBuilder = ({ bankAccountParams }) => {
  const {
    nameOfRecipient,
    accountName,
    bankSelected,
    agencyCode,
    agencyDigit,
    bankAccountType,
    accountNumber,
    accountDigit,
    document,
  } = bankAccountParams;

  return [
    {
      text: `${accountName}`,
    },
    {
      text: `${nameOfRecipient}
            CNPJ ${document}`,
    },
    {
      text: `${bankSelected.name}
            Agência ${agencyCode} ${agencyDigit ? '-'.concat(agencyDigit) : ''}
            C/${bankAccountType} ${accountNumber}-${accountDigit}`,
    },
  ];
};

const step02SectionBuilder = ({
  bankAccountParams,
  dueDayRule,
  contractDischargeRules,
  paymentMethodRules,
  contractCreationRule,
}) => [
  { text: `Repasses dias: ${bankAccountParams.transferDays}` },
  { text: `Vencimento das mensalidade dia: ${dueDayRule.dueDays}` },
  { text: `Troca de data de vencimento de boletos: ${dueDayRule.studentCanChangeDueDay ? 'SIM' : 'Não'}` },
  {
    text: `Forma de pagamento
            Mensalidades: ${contractDischargeRules.methods.join(', ')}
            Quitar curso: ${paymentMethodRules.methods.join(', ')}`,
  },
  { text: `Início da cobrança dos alunos: ${contractCreationRule.startRule}` },
  { text: `Duração do contrato: ${contractCreationRule.duration}` },
];


export { sidebarSectionsBuilder };
