const offerSpecialConditions = [
  { label: 'Isenção da 1ª mensalidade', key: '1_MENSALIDADE' },
  { label: 'Isenção da 2ª mensalidade', key: '2_MENSALIDADE' },
  { label: 'Isenção da taxa de vestibular', key: 'VESTIBULAR' },
];

const getLabel = key => offerSpecialConditions.find(
  offerSpecialCondition => offerSpecialCondition.key === key,
).label;

export const offerSpecialCondition = { getLabel };
