import { QueroDealsV1API } from '@quero-turbo/api/quero-deals-v1-api';
import { RollbarService } from '@shared/utils/rollbar-service';

/**
 * Create Commission config
 *
 * @param {Object} params
 * @property {Number} params.accountId
 * @property {Number} params.brgId
 * @property {String} params.commissionFeeType
 * @property {Number} params.commissionFee
 * @property {Number} params.subsidy
 * @property {Number} params.ladderLowerLimit
 * @property {Number} params.ladderUpperLimit
 * @property {Number} params.ladderStep
 * @property {Number} params.ladderTurboCommissionRuleCount
 * @property {String} params.paymentModel
 * @property {String} params.paymentTrigger
 *
 * @typedef Success
 * @property {string} message
 *
 * @typedef Error
 * @property {string} message
 *
 * @returns {Promise<Success|Error>}
 */
async function create(productConfig) {
  if (!productConfig.businessRuleGroupId) {
    throw new Error('Ops! Encontramos alguns erros no formulário');
  }
  productConfig.payload.commissionConfigRules.forEach((element) => {
    if (!isValidRequest(element)) {
      throw new Error('Ops! Encontramos alguns erros no formulário');
    }
  });

  return QueroDealsV1API.CommissionConfig.createCommissionConfig(productConfig)
    .then(({ data }) => data)
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[createCommissionRule] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
}

const isValidRequest = ({
  fee, feeType, subsidy, turboAccountId, paymentTrigger, paymentModel,
}) => (fee && feeType && subsidy && turboAccountId && paymentTrigger && paymentModel);

export {
  create,
};
