import $ from 'jquery';

class AjaxSetup {
  constructor() {
    this.setup();
  }

  setup() {
    $.ajaxSetup({
      headers: {
        'x-csrf-token': $('meta[name=csrf-token]').attr('content'),
      },
    });
  }
}

export default AjaxSetup;
