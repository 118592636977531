<template>
  <z-card class="card-create-campus">
    <section>
      <z-title
        class="card-create-campus__title"
        size="extra-small"
      >
        Criar Campus
      </z-title>
      <z-text
        class="card-create-campus__subtitle"
        size="small"
        color="minor"
      >
        Utilize os botões ao lado para criar campus individual ou em lote com
        importacao de planilha.
      </z-text>
    </section>

    <section class="card-create-campus__section-action">
      <z-button
        @click="campusInline()"
        id="saveButtonInline"
        class="card-create-campus__action-button"
        size="small"
        variant="secondary"
      >
        Campus Individual
      </z-button>

      <z-button
        @click="campusBatch()"
        id="saveButtonBatch"
        class="card-create-campus-batch__action-button"
        size="small"
      >
        Campus em lotes
      </z-button>
    </section>
  </z-card>
</template>

<script>
export default {
  name: 'CardCreateCampus',

  methods: {
    campusInline() {
      this.$emit('create-campus-inline-click');
    },

    campusBatch() {
      this.$emit('create-offer-batch-click');
    },
  },
};
</script>

<style lang="scss" scoped>
.card-create-campus {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-large);
}

.card-create-campus__title {
  margin-bottom: var(--space-small);
}

.card-create-campus__subtitle {
  margin: 0;
}

.card-create-campus__section-action {
  display: flex;
  height: fit-content;
}

.card-create-campus__action-button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    margin-right: var(--space-small);
  }
}

.card-create-campus-batch__action-button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    margin-right: var(--space-small);
  }
}
</style>
