<template>
  <z-card>
    <template v-if="businessRuleGroup">
      <ShowBRGWithData />
    </template>

    <template v-else-if="isLoading">
      <div class="show-brg-painel__loading-wrapper">
        <z-loading
          variant="spinner"
          class="show-brg-painel__loading-component"
        />
      </div>
    </template>

    <template v-else>
      <z-title
        size="small"
        class="show-brg-painel__title"
      >
        Nenhuma configuração de negociação encontrada
      </z-title>
    </template>
  </z-card>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import ShowBRGWithData from './show-brg-with-data.vue';

export default {
  components: {
    ShowBRGWithData,
  },
  computed: {
    ...mapFields('ShowBRGPageModule', [
      'status',
      'businessRuleGroup',
      'productConfigs',
    ]),
    isLoading() {
      return this.status === 'loading';
    },
  },
  methods: {
    ...mapActions('ShowBRGPageModule', [
      'requestFindBRGWithProductConfigs',
    ]),
  },
  mounted() {
    this.requestFindBRGWithProductConfigs({ id: Number(this.$route.params.id) });
  },
};
</script>

<style>
.show-brg-painel__title {
  display: inline-flex;
}

.show-brg-painel__title:not(:first-child) {
  margin-left: var(--space-medium);
}

.show-brg-painel__row {
  --grid-padding: 0;
}

.show-brg-painel__loading-wrapper {
  align-items: center;
  display: flex;
  height: 20vh;
  justify-content: center;
}

.show-brg-painel__loading-component {
  display: contents;
}
</style>
