import axios from 'axios';

const csrf = document.querySelector('meta[name=csrf-token]').content;

const httpClientV1 = axios.create({
  baseURL: '/api/core/v1',
  timeout: 10000,
  headers: {
    'X-CSRF-TOKEN': csrf,
  },
});

const destroySession = params => httpClientV1.delete(
  '/sessions', { params },
);

const setCurrentUniversity = params => httpClientV1.post(
  '/sessions/current_university', params,
);

const Session = {
  destroySession,
  setCurrentUniversity,
};

const getPermission = params => httpClientV1.get(
  '/permissions/verify', { params },
);

const Permission = {
  getPermission,
};

export const UniversityPanelCoreAPI = {
  Session,
  Permission,
};
