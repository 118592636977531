import { create } from './create';
import { parseRequestData } from './parse-request-data';
import { parseResponseData } from './parse-response-data';

const BillingConfig = {
  create,
  parseRequestData,
  parseResponseData,
};

export { BillingConfig };
