<template>
  <div class="create-billing-config-modal__content">
    <div
      class="create-billing-config-modal__body"
      :class="{
        'create-billing-config-modal__body--with-sidebar': currentStepHasSidebar
      }"
    >
      <UPAModalHeader
        :name="modalName"
        :title="currentStepTitle"
      />

      <component
        :is="currentComponent"
      />

      <UPAModalFooter
        @right-button-click="handleNext"
        @left-button-click="handlePrev"
        :right-button-text="rightButtonText"
        :left-button-text="leftButtonText"
        :right-button-disabled="disabledNextButton"
        :has-left-button="status === 'editing'"
      />
    </div>

    <div
      v-if="currentStepHasSidebar"
      class="create-billing-config-modal__sidebar"
    >
      <UPAModalSidebar
        :sections="sidebarSectionsItems"
        :title="sidebarTitle"
      />
    </div>
  </div>
</template>

<script>
import { UPAModalHeader, UPAModalFooter, UPAModalSidebar } from '@shared/components/upa-modal';
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  components: {
    UPAModalHeader,
    UPAModalFooter,
    UPAModalSidebar,
  },
  computed: {
    ...mapFields('CreateBillingConfigModalModule', [
      'stepConfig.status',
    ]),
    ...mapGetters('CreateBillingConfigModalModule', [
      'currentStep',
      'currentComponent',
      'currentStepTitle',
      'currentStepHasSidebar',
      'rightButtonText',
      'leftButtonText',
      'sidebarSectionsItems',
      'canIAdvanceStep',
    ]),
    disabledNextButton() {
      return !this.canIAdvanceStep;
    },
    modalName() {
      return 'Configuração de QB Turbo';
    },
    sidebarTitle() {
      return 'Resumo';
    },
  },
  methods: {
    ...mapActions('CreateBillingConfigModalModule', [
      'handleNext',
      'handlePrev',
    ]),
  },
};
</script>

<style lang="scss">
@import "@quero/zilla-core/src/utils/_index.scss";

.create-billing-config-modal__content {
  display: flex;
}

.create-billing-config-modal__body {
  flex: 1;
}

.create-billing-config-modal__body--with-sidebar {
  margin-right: var(--space-jumbo);
}

.create-billing-config-modal__sidebar {
  display: flex;
  flex: 0 0 280px;
  margin-bottom: calc(-1 * var(--space-extra-large));
  margin-left: var(--space-extra-large);
  margin-right: calc(-1 * var(--space-extra-large));
  margin-top: calc(-1 * var(--space-extra-large));
  @media (min-width: $screen-desktop) {
    margin-top: calc(-1 * var(--space-jumbo));
    margin-right: calc(-1 * var(--space-jumbo));
    margin-bottom: calc(-1 * var(--space-jumbo));
    margin-left: var(--space-jumbo);
  }
}
</style>
