function _normalizeEmail() {
  const $email = $('.js-form-email');
  const newValue = $email.val().toLocaleLowerCase().trim();
  $email.val(newValue);
}

function LoginPage() {
  $('.js-form-login').on('submit', _normalizeEmail);
}

export { LoginPage };
