<template>
  <div>
    <z-title
      class="show-brg-with-data-stepper__title"
      size="extra-small"
    >
      Etapas de configuração
    </z-title>

    <z-stepper-bar
      :max="maxStepCount"
      :value="activationStepCount"
      class="show-brg-with-data-stepper__stepper-wrapper"
    />

    <ul class="show-brg-with-data-stepper__list">
      <li class="show-brg-with-data-stepper__item">
        <z-icon
          :icon="['fas', 'check-circle']"
          class="show-brg-with-data-stepper__item-icon"
          :class="{
            ['show-brg-with-data-stepper__item-icon--success']: true
          }"
        />

        <z-text
          tag="span"
          class="show-brg-with-data-stepper__item-text"
        >
          BRG criada!
        </z-text>
      </li>

      <li class="show-brg-with-data-stepper__item">
        <z-icon
          :icon="['fas', 'check-circle']"
          class="show-brg-with-data-stepper__item-icon"
          :class="{
            ['show-brg-with-data-stepper__item-icon--success']: hasOfferFilter
          }"
        />

        <z-text
          tag="span"
          class="show-brg-with-data-stepper__item-text"
        >
          Filtro de oferta configurado
        </z-text>
      </li>

      <li class="show-brg-with-data-stepper__item">
        <z-icon
          :icon="['fas', 'check-circle']"
          class="show-brg-with-data-stepper__item-icon"
          :class="{
            ['show-brg-with-data-stepper__item-icon--success']: hasAnyBenefit
          }"
        />

        <z-text
          tag="span"
          class="show-brg-with-data-stepper__item-text"
        >
          Benefício configurado
        </z-text>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('ShowBRGPageModule', [
      'activationStepCount',
      'hasOfferFilter',
      'hasAnyBenefit',
    ]),
    maxStepCount() {
      return 3;
    },
  },
};
</script>

<style>
.show-brg-with-data-stepper__title {
  display: inline-flex;
  margin-bottom: var(--space-medium);
}

.show-brg-with-data-stepper__stepper-wrapper {
  width: 100%;
  margin-bottom: var(--space-medium);
}

.show-brg-with-data-stepper__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.show-brg-with-data-stepper__item {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-small);
}

.show-brg-with-data-stepper__item-text {
  font-weight: 500;
  margin-left: var(--space-small);
}

.show-brg-with-data-stepper__item-icon {
  color: var(--color-gray-100);
}

.show-brg-with-data-stepper__item-icon--success {
  color: var(--color-support-success-pure);
}
</style>
