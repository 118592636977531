import { round } from 'lodash';

const BASIC_INTEGER_REGEX = /^\d+([.,]0+)?$/;
const BASIC_DECIMALS_PORTUGUESE_REGEX = /^\d+,\d+?$/;
const BASIC_DECIMALS_AMERICAN_REGEX = /^\d+\.\d+?$/;

const AMERICAN_FORMAT_WITH_THOUSANDS_AND_DECIMALS_SEPARATOR_REGEX = /^\d{1,3}(,\d{3})*(\.\d{1,2})?$/;
const PORTUGUESE_FORMAT_WITH_THOUSANDS_AND_DECIMALS_SEPARATOR_REGEX = /^\d{1,3}(\.\d{3})*(,\d{1,2})?$/;

const AMERICAN_FORMAT_WITHOUT_THOUSANDS_WITH_DECIMALS_SEPARATOR_REGEX = /^\d+(\.\d{1,2})?$/;
const PORTUGUESE_FORMAT_WITHOUT_THOUSANDS_WITH_DECIMALS_SEPARATOR_REGEX = /^\d+(,\d{1,2})?$/;

const AMERICAN_FORMAT_WITH_THOUSANDS_WITHOUT_DECIMALS_SEPARATOR_REGEX = /^\d{1,3}(,\d{3})*$/;
const PORTUGUESE_FORMAT_WITH_THOUSANDS_WITHOUT_DECIMALS_SEPARATOR_REGEX = /^\d{1,3}(\.\d{3})*$/;

function parseAmerican(num) {
  return parseFloat(num.replace(/,/g, ''));
}

function parsePortuguese(num) {
  return parseFloat(num.replace(/\./g, '').replace(',', '.'));
}

const REGEX_PARSER_MAPPING = [
  [BASIC_DECIMALS_PORTUGUESE_REGEX, parsePortuguese],
  [BASIC_DECIMALS_AMERICAN_REGEX, parseAmerican],
  [BASIC_INTEGER_REGEX, parseInt],

  [PORTUGUESE_FORMAT_WITH_THOUSANDS_WITHOUT_DECIMALS_SEPARATOR_REGEX, parsePortuguese],
  [AMERICAN_FORMAT_WITH_THOUSANDS_WITHOUT_DECIMALS_SEPARATOR_REGEX, parseAmerican],

  [AMERICAN_FORMAT_WITH_THOUSANDS_AND_DECIMALS_SEPARATOR_REGEX, parseAmerican],
  [AMERICAN_FORMAT_WITHOUT_THOUSANDS_WITH_DECIMALS_SEPARATOR_REGEX, parseAmerican],

  [PORTUGUESE_FORMAT_WITH_THOUSANDS_AND_DECIMALS_SEPARATOR_REGEX, parsePortuguese],
  [PORTUGUESE_FORMAT_WITHOUT_THOUSANDS_WITH_DECIMALS_SEPARATOR_REGEX, parsePortuguese],
];

export default function parse(numberAsString, config = {}) {
  let value = NaN;
  const precision = config.precision || 10;

  REGEX_PARSER_MAPPING.every(([regex, parser]) => {
    if (regex.test(numberAsString)) {
      value = parser(numberAsString);
      return false; // break
    }
    return true;
  });

  return Number.isNaN(value) ? NaN : round(value, precision);
}
