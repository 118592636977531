import toastr from 'toastr';
import { isArray, assign, keys } from 'lodash';

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-top-center',
  preventDuplicates: false,
  onclick: null,
  showDuration: 200,
  hideDuration: 200,
  timeOut: 2500,
  extendedTimeOut: 1000,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

export default class Alert {
  show({
    type, message, permanent, placement,
  }) {
    const func = toastr[type];

    if (!func) {
      throw new Error(`${type} is not a valid type. Pick one of: info, success, warning, error`);
    }

    const messages = isArray(message) ? message : [message];

    messages.forEach(msg => func(msg, undefined, {
      timeOut: this._getTimeOut(permanent),
      positionClass: this._getPosition(placement),
    }));
  }

  showInfo(message, config) {
    this._showOfType('info', message, config);
  }

  showSuccess(message, config) {
    this._showOfType('success', message, config);
  }

  showWarning(message, config) {
    this._showOfType('warning', message, config);
  }

  showValidationError(messages, config) {
    if (messages) {
      this._showOfType('warning', messages[0], config);
    } else {
      this.showDefaultErrorMessage();
    }
  }

  showError(message, config) {
    this._showOfType('error', message, config);
  }

  showDefaultErrorMessage() {
    this.showError('Desculpe, houve um erro com a sua requisição. Por favor, tente novamente.');
  }

  hide() {
    toastr.remove();
  }

  _showOfType(type, message, config = {}) {
    this.show(assign({}, config, { type, message }));
  }

  _getPosition(placement = 'top-center') {
    const placementMapping = {
      'top-left': 'toast-top-left',
      'top-right': 'toast-top-right',
      'bottom-left': 'toast-bottom-left',
      'bottom-right': 'toast-bottom-right',
      'top-full': 'toast-top-full-width',
      'top-center': 'toast-top-center',
      'bottom-full': 'toast-bottom-full-width',
      'bottom-center': 'toast-bottom-center',
    };

    const position = placementMapping[placement];

    if (!position) {
      throw new Error(`Invalid placement ${placement}. Pick one of ${keys(placementMapping).join(', ')}`);
    }

    return position;
  }

  _getTimeOut(permanent = false) {
    return permanent ? 0 : toastr.options.timeOut;
  }
}
