import $ from 'jquery';
import ShowDocumentationModal from '@admission/legacy/components/modals/show-documentation';

class AnalyzeDocumentation {
  constructor(page) {
    this.page = page;
    this.initDocumentationModal();
    $('.js-analyze-docs-approve-all').click(this.checkAllToApproved);
  }

  initDocumentationModal() {
    $('.js-follow-up-docs').click(function openDocumentationModal() {
      const props = {
        docPreviewUrl: $(this).data('docPreviewUrl'),
        docDownloadUrl: $(this).data('docDownloadUrl'),
      };

      const modal = new ShowDocumentationModal(props);
      modal.show();
    });
  }

  checkAllToApproved(e) {
    e.preventDefault();
    $('.js-analyze-docs-approve-input').click();
  }
}

export default AnalyzeDocumentation;
