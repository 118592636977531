<template>
  <div class="box-header box-header--without-margin ">
    <div class="box-header__container">
      <h1 class="box-header__title">
        {{ title }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.box-header--without-margin {
  margin: 0;
}
</style>
