<template>
  <div>
    <UPAModalHeader
      :name="commissionModalName"
      :title="commissionModalTitle"
    />

    <z-card
      class="show-commission-config-modal__card"
      v-for="(comissionConfig, index) in commissionConfigRules"
      :key="index"
    >
      <li
        v-for="(commissionConfigItem, indexItem) in Object.entries(comissionConfig)"
        :key="indexItem"
        class="show-commission-config-modal__card-item"
      >
        <z-title class="show-commission-config-modal__card-item-title">
          {{ CommissionRulesBusiness.getCommissionDictionaryByKey(commissionConfigItem[0]) }}
        </z-title>

        <z-text
          class="show-commission-config-modal__card-item-description"
          tag="span"
        >
          {{ CommissionRulesBusiness.parseValues(commissionConfigItem[1]) }}
        </z-text>
      </li>
    </z-card>
  </div>
</template>

<script>
import { CommissionRulesBusiness } from '@quero-turbo/business';
import { UPAModalHeader } from '@shared/components/upa-modal';

export default {
  components: {
    UPAModalHeader,
  },
  props: {
    commissionConfigRules: {
      type: Array,
      required: true,
    },
  },
  computed: {
    CommissionRulesBusiness: () => CommissionRulesBusiness,
    commissionModalName() {
      return 'Configuração de comissionamento';
    },
    commissionModalTitle() {
      return 'Lista de configurações';
    },
  },
};
</script>

<style lang="scss">
.show-commission-config-modal__card {
  list-style-type: none;

  &:not(:last-of-type) {
    margin-bottom: var(--space-medium);
  }
}

.show-commission-config-modal__card-item {
  display: flex;
  padding: var(--space-small) 0;

  &:not(:last-of-type) {
    border-bottom: var(--size-border-width-small) solid var(--color-gray-100);
  }
}

.show-commission-config-modal__card-item-description {
  text-align: right;
}

.show-commission-config-modal__card-item-title,
.show-commission-config-modal__card-item-description {
  flex: 1;
}
</style>
