<template>
  <div class="campus-import-batch">
    <z-modal
      :open="open"
      size="auto"
      @closed="closed"
    >
      <div>
        <div class="campus-import__modal__header">
          <div class="campus-import__modal__header__title">
            <z-title color="minor">
              <z-icon
                :icon="['far', 'file-alt']"
                size="large"
              />
              Criação de Campus
            </z-title>

            <z-title
              size="medium"
              v-if="step === 1"
            >
              Selecione uma tabela para importar
            </z-title>
          </div>
        </div>

        <div>
          <div
            v-if="step === 1"
            class="campus-import__modal__content__step-1__content"
          >
            <z-card class="z-card">
              <div>
                <div class="campus-import__modal__content__step-1__content__item__content">
                  <label
                    ref="select-file-button"
                    v-if="!hasImportSpreadsheet"
                    for="simple-spreadsheet"
                    class="z-button z-button--secondary z-button--medium"
                  >
                    <span class="z-button__text">
                      Selecionar arquivo
                    </span>
                  </label>

                  <div
                    v-else
                    class="z-button z-button--secondary z-button--medium"
                  >
                    <div class="institution-import__modal__content__step-1__content__item__uploaded-file">
                      <z-text
                        ref="spreadsheet-name"
                        :title="spreadsheetImport.file.name"
                      >
                        {{ spreadsheetFileName }}
                      </z-text>

                      <div
                        class="z-caption z-caption--interactive z-caption--right"
                        @click="clearSimpleSpreadsheetInput"
                      >
                        Remover
                      </div>
                    </div>
                  </div>

                  <input
                    id="simple-spreadsheet"
                    ref="simpleSpreadsheet"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    @change="uploadFile"
                  >

                  <z-text
                    class="z-text"
                    size="small"
                    color="minor"
                  >
                    Tipo de arquivo aceito: XLSX
                  </z-text>

                  <z-link
                    class="z-link"
                    :href="simpleImportCampusURL"
                    target="_blank"
                  >
                    Visualizar modelo de importação
                  </z-link>
                </div>
              </div>
            </z-card>

            <div class="import__campus__actions">
              <z-button
                ref="import-button"
                :disabled="isButtonDisabled"
                @click="confirmImport"
                class="cc-campus-import"
                :is-loading="loading"
              >
                Importar
              </z-button>
            </div>
          </div>

          <div
            v-if="step === 2"
          >
            <div class="modal__error-feedback">
              <div class="modal__error">
                <z-icon
                  :icon="['fad', 'times-circle']"
                  title="error"
                  size="giant"
                  color="danger"
                  class="feedback-card-error__icon"
                />
                <z-title>
                  {{ formattedErrorMessage }}
                </z-title>
              </div>
              <z-text>
                Corrija as informações abaixo e importe a tabela novamente
              </z-text>
            </div>
            <z-card class="feedback-error">
              <div>
                <z-text
                  size="small"
                  weight="bold"
                  ref="error-message"
                  :key="index"
                  v-for="(errorItem, index) in errorMessages"
                >
                  {{ errorItem }}
                </z-text>
              </div>
            </z-card>
          </div>

          <div
            v-if="step === 3"
            class="institution-import__modal__content__step-3"
          >
            <div>
              <z-card>
                <div class="modal__content__sucess-feedback">
                  <z-icon
                    :icon="['fas', 'check-circle']"
                    title="Operação Finalizada"
                    size="giant"
                    color="success"
                    class="feedback-card-sucess__icon"
                  />
                  <z-text ref="success-message">
                    {{ this.sucessMessage }}
                  </z-text>
                </div>
              </z-card>
            </div>
          </div>
        </div>

        <div>
          <div class="institution-import__modal__footer__actions">
            <z-button
              v-if="step === 2"
              variant="secondary"
              :disabled="isButtonDisabled"
              @click="goToFirstStep"
              class="cc-fields__wrapper"
            >
              Voltar
            </z-button>

            <z-button
              v-if="step > 1"
              variant="secondary"
              :disabled="isButtonDisabled"
              @click="closed"
              class="cc-fields__wrapper"
            >
              Fechar
            </z-button>
          </div>
        </div>
      </div>
    </z-modal>
  </div>
</template>

<script>
import { notifierMixin } from '@stock/utils/mixins/notifier';
import { queroAlunosAPI } from '@stock/api/quero-alunos-api';

const simpleImportCampusURL = 'https://docs.google.com/spreadsheets/d/1DdG-oUSDBTIoiXwAQeaoq_EdMW4jnAlamMQCSJaKRYI/edit?usp=sharing';

export default {
  name: 'CampusCreationModalBatch',
  mixins: [notifierMixin],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      simpleImportCampusURL,
      spreadsheetErrors: [],
      step: 1,
      spreadsheetImport: {
        file: null,
      },
      errorMessages: [],
      loading: false,
    };
  },

  computed: {
    hasImportSpreadsheet() {
      return Boolean(this.spreadsheetImport.file);
    },
    spreadsheetFileName() {
      if (this.hasImportSpreadsheet) {
        return this.spreadsheetImport.file.name;
      }
      return '';
    },
    isButtonDisabled() {
      const importing = !this.spreadsheetImport.file;
      return importing;
    },
    formattedErrorMessage() {
      const countErrors = this.errorMessages.length;
      const formattedMessage = `Encontramos ${countErrors} problema(s) na sua tabela`;

      return formattedMessage;
    },
  },

  methods: {
    closed() {
      this.$emit('closed');
      this.importing = true;
      this.spreadsheetImport = { file: null };
      this.goToFirstStep();
    },
    clearSimpleSpreadsheetInput() {
      this.spreadsheetImport = { file: null };
    },

    uploadFile(event) {
      const { files } = event.target;
      const [file] = files;

      if (files && file) {
        this.spreadsheetImport.file = file;
      }
    },

    confirmImport() {
      this.loading = true;

      const formData = new FormData();
      formData.append('file', this.spreadsheetImport.file);

      queroAlunosAPI.createCampusBatch(formData)
        .then(() => {
          this.handleSuccessfullyRequest();
        })
        .catch((error) => {
          this.handleRequestFailure(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleSuccessfullyRequest() {
      this.sucessMessage = 'Operação enviada com sucesso. Verifique-a no registro de atividades.';
      this.goToSuccessStep();
    },

    handleRequestFailure(error) {
      const defaultErrorMessage = 'Ocorreu um erro inesperado na validação, contate o responsável.';
      this.errorMessages = error.response.data.messages ? error.response.data.messages : [defaultErrorMessage];

      this.goToFailureStep();
    },

    goToFirstStep() {
      this.step = 1;
    },

    goToFailureStep() {
      this.step = 2;
    },

    goToSuccessStep() {
      this.step = 3;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@quero/zilla-core/src/utils/variables.scss";

.campus-import__modal__header {
  flex-direction: column;
  margin-bottom: var(--space-extra-large);
  min-width: 500px;
}

.campus-import__modal__header__title {
  margin-bottom: var(--space-small);
  margin-top: 10px;
}

.campus-import__modal__content__step-1__content {
  display: flex;
  flex-direction: column;

  &>.z-card {
    width: 600px;
  }
}

.campus-import__modal__content__step-1__content__item__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;


  &>.z_button {
    display: flex;
    align-items: center;
  }

  input[type="file"] {
    display: none;
  }

  &>.z-text {
    margin-bottom: var(--space-extra-large);
  }
}

.institution-import__modal__footer {
  display: flex;
}

.import__campus__actions {
  margin-top: 20px;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
}

.cc-campus-import{
  flex-direction: row;
}

.institution-import__modal__footer__actions {
  margin-top: 20px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.cc-fields__wrapper {
  margin-bottom: 10px;
  flex-direction: row;
}

.modal__error {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal__error-feedback {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}

.feedback-card-error__icon {
  align-items: center;
  display: flex;
}

.feedback-error {
  height: 200px;
  overflow-y: scroll;
}

.modal__content__sucess-feedback {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.feedback-card-sucess__icon {
  margin-bottom: var(--space-large);
}
</style>
