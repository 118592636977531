import EJSHelpers from '@shared/utils/templates/ejs';

export const TEMPLATE_EDIT_PROPS = Object.freeze({
  title: 'Alteração de desconto em lote',
  description: `
    Informe se você quer aumentar, diminuir ou definir um novo
    percentual de desconto para todas as ofertas filtradas.
  `,
  radioinc_label: 'Aumentar',
  radioinc_title: 'Aumentar desconto para ofertas filtradas',
  radiodec_label: 'Diminuir',
  radiodec_title: 'Diminuir desconto para ofertas filtradas',
  formSelector: 'js-batch-stock-form',
  label: 'Desconto (%)',
  input: Object.freeze({
    selector: 'js-batch-new-discount',
    min: '1',
    max: '80',
    placeholder: 'Informe o desconto',
  }),
  disclaimerInc: 'Informe o percentual que você quer <strong>somar</strong> ao desconto atual',
  disclaimerDec: 'Informe o percentual que você quer <strong>subtrair</strong> do desconto atual',
  disclaimerRep: 'Informe o <strong>novo</strong> percentual de desconto que voce quer atribuir',
  rules: Object.freeze([
    `
      <strong>O novo desconto deverá ficar entre 5% e 80%.</strong>
      Caso queira atribuir um desconto acima de 80%, fale com seu Key Account.
    `,
  ]),
  attr: EJSHelpers.attr,
  initialSeatsCount: '',
  button_confirm: 'Conferir prévia',
  button_cancel: 'Cancelar',
  mode: 'pricing',
});

export const TEMPLATE_CONFIRMATION_PROPS = Object.freeze({
  title: 'Confirmar alteração de descontos em lote',
  description: 'Antes de confirmar, verifique o resumo das alteracões que você fará no seu estoque',
  newValue: '',
  newValueMessage: {
    '+': 'Desconto atual',
    '-': 'Desconto atual',
    '=': 'Desconto atual',
  },
  totalTargetMessage: {
    '+': 'Novo desconto',
    '-': 'Novo desconto',
    '=': 'Novo desconto',
  },
  button_confirm: 'Confirmar alteração',
  button_cancel: 'Voltar',
  rules: Object.freeze([
    '<strong>O novo desconto deverá ficar entre 5% e 80%</strong>. Caso queira atribuir um desconto acima de 80%, fale com seu Key Account.',
    'As ofertas com desconto regressivo serão bloqueadas por aqui, caso deseje alterar o desconto delas entre em contato conosco.',
  ]),
  canShowTotalTarget: () => true,
});
