<template>
  <div
    class="fullscreen-card"
    :class="{
      'fullscreen-card--open': open
    }"
  >
    <slot name="header">
      <header
        class="fullscreen-card__header"
      >
        <div class="fullscreen-card__header-left-slot">
          <slot name="header-left" />
        </div>

        <div class="fullscreen-card__header-center-slot">
          <slot name="header-center">
            <div class="fullscreen-card__logo">
              <a
                href="/"
                title="Quero Alunos"
              >
                <img
                  class="fullscreen-card__logo-img"
                  src="https://kong.quero.com/logotype-queroalunos-light.svg"
                  alt="Quero Alunos"
                >
              </a>
            </div>
          </slot>
        </div>

        <div class="fullscreen-card__header-right-slot">
          <slot name="header-right" />
        </div>
      </header>
    </slot>

    <div class="fullscreen-card__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style>
.fullscreen-card {
  --fullscreen-z-index: 1001;
  background-color: var(--color-white);
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  transition: opacity 250ms;
  width: 0;
  z-index: var(--fullscreen-z-index);
}

.fullscreen-card--open {
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  margin: 0;
  max-width: 100%;
  opacity: 1;
  right: 0;
  top: 0;
  width: 100%;
}

.fullscreen-card__header {
  border-bottom: var(--size-nano) solid var(--color-gray-050);
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-areas: "headerLeft headerCenter headerRight";
  height: var(--size-colossal);
  padding-right: var(--size-jumbo);
  padding-left: var(--size-jumbo);
  width: 100%;
}

.fullscreen-card__header-left-slot {
  grid-area: headerLeft;
}

.fullscreen-card__header-center-slot {
  grid-area: headerCenter;
}

.fullscreen-card__header-right-slot {
  grid-area: headerRight;
}

.fullscreen-card__logo {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.fullscreen-card__logo-img {
  height: var(--size-extra-large);
}

.fullscreen-card__content {
  display: flex;
  height: 100%;
  position: relative;
}
</style>
