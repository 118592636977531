<template>
  <div class="oc-course-details">
    <div class="oc-course-details__content">
      <Header
        name="Detalhes gerais"
        :current-step-number="2"
        :last-step-number="5"
      />

      <div class="oc-course-details__kind-section">
        <z-text
          size="medium"
          class="oc-course-details__input-label z-ovr-text--fw-medium"
        >
          Modalidade de ensino
        </z-text>

        <div class="oc-course-details__input-wrapper">
          <switch-button
            :checked="kind.value === selectedKind"
            :key="kind.value"
            :label="kind.label"
            :value="kind.value"
            @change="handlerKindInputChanged"
            type="radio"
            v-for="kind in kinds"
          />
        </div>
      </div>

      <div class="oc-course-details__shift-section">
        <z-text
          size="medium"
          class="oc-course-details__input-label z-ovr-text--fw-medium"
        >
          Turno(s)
        </z-text>

        <div class="oc-course-details__input-wrapper">
          <switch-button
            v-for="shift in shifts"
            :key="shift.value"
            :label="shift.label"
            :checked="shift.checked"
            :value="shift.value"
            :disabled="isShiftDisabled(shift.value)"
            type="checkbox"
            @change="handlerShiftInputChanged"
          />
        </div>
      </div>

      <div class="oc-course-details__period-section">
        <z-input-field
          label="Periodicidade"
          class="oc-course-details__period-input"
        >
          <z-dropdown-input
            :options="periodKinds"
            v-model="selectedPeriodKind"
          />
        </z-input-field>

        <z-input-field
          label="Duração do curso em quantidade de períodos"
          class="oc-course-details__period-input"
        >
          <z-input
            v-model="selectedMaxPeriods"
            type="number"
            placeholder="Ex.: 8"
            :has-error="hasMaxPeriodsError"
          />
        </z-input-field>
      </div>

      <div
        class="oc-course-details__total-hours-section"
        v-if="!isUndergrad"
      >
        <z-input-field label="Carga horária total (Opcional)">
          <z-input
            v-model="selectedTotalHours"
            @input="formatSelectedTotalHours"
            type="text"
            placeholder="Ex.: 3200"
          />
          <z-text
            color="minor"
            weight="normal"
          >
            Insira apenas números
          </z-text>
        </z-input-field>
      </div>
    </div>

    <div class="oc-course-details__footer">
      <z-button
        variant="tertiary"
        @click="prev"
      >
        Voltar
      </z-button>

      <z-button
        @click="next"
        :disabled="isNextStepDisabled"
      >
        Próximo
      </z-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { SwitchButton } from '@stock/components/switch-button';
import { notifierMixin } from '@stock/utils/mixins/notifier';
import { offerCreationTracking } from '@stock/metrics';
import { period } from '@stock/business/period';
import Header from '../step-header';

export default {
  components: {
    Header,
    SwitchButton,
  },
  mixins: [notifierMixin],
  data() {
    return {
      kinds: [
        {
          label: 'Presencial',
          value: 'Presencial',
        },
        {
          label: 'Semipresencial',
          value: 'Semipresencial',
        },
        {
          label: 'A distância',
          value: 'EaD',
        },
      ],
      shifts: [
        {
          label: 'Manhã',
          checked: false,
          value: 'morning',
        },
        {
          label: 'Tarde',
          checked: false,
          value: 'afternoon',
        },
        {
          label: 'Noite',
          checked: false,
          value: 'night',
        },
        {
          label: 'Integral',
          checked: false,
          value: 'fullTime',
        },
        {
          label: 'Virtual',
          checked: false,
          value: 'virtual',
        },
      ],
      periodKinds: [],
      selectedKind: null,
      selectedPeriodKind: null,
      selectedMaxPeriods: null,
      selectedTotalHours: '',
    };
  },
  computed: {
    ...mapGetters('OfferCreationModule', {
      shiftsFromStore: 'shifts',
      isUndergrad: 'isUndergrad',
    }),
    ...mapState('OfferCreationModule', ['sharedCourse', 'sharedOffer']),
    selectedShiftsValue() {
      return this.shifts.filter(shift => shift.checked).map(shift => shift.value);
    },
    hasMaxPeriodsError() {
      return Boolean(!(
        Number.isInteger(Number(this.selectedMaxPeriods)))
        && this.selectedMaxPeriods
        && this.selectedMaxPeriods.length > 0);
    },
    isValidSelectedKind() {
      return Boolean(this.selectedKind && this.selectedKind.length > 0);
    },
    isValidSelectedShifts() {
      return Boolean(this.shifts.find(shift => shift.checked));
    },
    isValidPeriodKind() {
      return Boolean(this.selectedPeriodKind && this.selectedPeriodKind.length > 0);
    },
    isValidMaxPeriods() {
      return Boolean(
        this.selectedMaxPeriods
        && this.selectedMaxPeriods > 0
        && !this.hasMaxPeriodsError,
      );
    },
    isNextStepDisabled() {
      return Boolean(!(
        this.isValidSelectedKind
        && this.isValidSelectedShifts
        && this.isValidPeriodKind
        && this.isValidMaxPeriods));
    },
    selectedShiftsLabel() {
      return this.shifts
        .filter(shift => shift.checked)
        .map(shift => shift.label);
    },
  },
  methods: {
    ...mapActions('OfferCreationModule', ['updateCourseDetailsData']),
    prev() {
      this.$emit('prev');
    },
    next() {
      if (this.isNextStepDisabled) return;

      if (navigator.onLine || navigator.onLine === undefined) {
        this.trackAdvance();
        this.updateStore();
        this.$emit('next');
      } else {
        this.notifyOfflineMessage();
      }
    },
    trackAdvance() {
      offerCreationTracking.advancedCourseDetailStep({
        kind: this.selectedKind,
        shifts: this.selectedShiftsLabel,
      });
    },
    updateStore() {
      this.updateCourseDetailsData({
        kind: this.selectedKind,
        maxPeriods: Number(this.selectedMaxPeriods),
        periodKind: this.selectedPeriodKind,
        shifts: this.selectedShiftsValue,
        totalHours: this.selectedTotalHours,
      });
    },
    handlerKindInputChanged({ target }) {
      if (!target.checked) return;
      this.selectedKind = target.value;
      this.updateShiftsStatus();
    },
    updateShiftsStatus() {
      if (this.selectedKind === 'EaD') {
        this.enableOnlyShiftVirtual();
      } else {
        this.disableShiftVirtual();
      }
    },
    enableOnlyShiftVirtual() {
      this.shifts.forEach((shift, index) => {
        this.$set(
          this.shifts,
          index,
          { ...shift, checked: shift.value === 'virtual' },
        );
      });
    },
    disableShiftVirtual() {
      this.shifts.forEach((shift, index) => {
        if (shift.value === 'virtual') {
          this.$set(
            this.shifts,
            index,
            { ...shift, checked: false },
          );
        }
      });
    },
    handlerShiftInputChanged({ target }) {
      const dataShift = this.shifts.find(shift => shift.value === target.value);
      dataShift.checked = target.checked;
    },
    isShiftDisabled(value) {
      if (this.selectedKind === 'EaD') {
        return value !== 'virtual';
      }

      if (!this.selectedKind) {
        return false;
      }

      return value === 'virtual';
    },
    formatSelectedTotalHours(value) {
      const matchNonNumericValues = /[^\d]+/g;
      this.selectedTotalHours = value.replace(matchNonNumericValues, '');
    },
    getDefaultPeriodKind({ periodKind }) {
      if (!periodKind) {
        return 'Semestre';
      }

      return periodKind;
    },
  },
  mounted() {
    this.shiftsFromStore.forEach((shiftValue) => {
      const dataShift = this.shifts.find(shift => shift.value === shiftValue);
      dataShift.checked = true;
    });
    this.periodKinds = period.getPeriodKindsList(this.sharedCourse);
    this.selectedKind = this.sharedCourse.kind;
    this.selectedPeriodKind = this.getDefaultPeriodKind(this.sharedCourse);
    this.selectedMaxPeriods = this.sharedCourse.maxPeriods ? String(this.sharedCourse.maxPeriods) : '';
    this.selectedTotalHours = this.sharedOffer.totalHours;
  },
};
</script>

<style lang="scss" scoped>
@import "~@quero/zilla-core/src/utils/_index.scss";
.oc-course-details {
  display: flex;
  flex-direction: column;
  min-height: 616px;
  justify-content: space-between;
}

.oc-course-details__kind-section {
  margin-bottom: var(--space-large);
}

.oc-course-details__shift-section {
  margin-bottom: var(--space-large);
}

.oc-course-details__period-section {
  display: flex;
}

.oc-course-details__total-hours-section {
  margin-bottom: var(--space-extra-colossal);
}

.oc-course-details__input-wrapper {
  display: flex;
}

.oc-course-details__input-label {
  margin-bottom: var(--space-extra-small);
}

.oc-course-details__period-input {
  flex-grow: 1;

  &:not(:last-child) {
    margin-right: var(--space-large);
  }
}

.oc-course-details__footer {
  display: flex;
  justify-content: space-between;
}
</style>
