import Vue from 'vue';
import { ZillaPlugin } from '@shared/utils/vue/plugins/zilla-plugin';
import { ValidatePlugin } from '@shared/utils/vue/plugins/validate-plugin';
import { router } from '@quero-turbo/routers';
import VueRouter from 'vue-router';
import { store } from '@quero-turbo/store';
import VueTheMask from 'vue-the-mask';
import { UPAHelperModalPlugin } from '@shared/plugins/upa-helper-modal-plugin';
import { UPAAbilityPlugin } from '@shared/plugins/upa-ability-plugin';
import QueroTurboApp from './quero-turbo-app.vue';

export function QueroTurboBoot() {
  return new Promise((resolve) => {
    Vue.use(ValidatePlugin);
    Vue.use(ZillaPlugin);
    Vue.use(UPAHelperModalPlugin, { store });
    Vue.use(UPAAbilityPlugin);
    Vue.use(VueRouter);
    Vue.use(VueTheMask);

    new Vue({
      el: '#js-quero-turbo-boot',
      router,
      store,
      components: {
        QueroTurboApp,
      },
      mounted() {
        resolve();
      },
    });
  });
}
