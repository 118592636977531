<template>
  <z-card>
    <z-title
      size="small"
      class="oc-batch-sheets__title"
    >
      Lista de planilhas
    </z-title>

    <z-text
      size="medium"
      color="minor"
    >
      Exibindo {{ numberOfSheets }} planilha(s)
    </z-text>

    <OfferCreationBatchSheetsTable
      :offer-creation-sheets="offerCreationSheets"
      @edit-sheet="handleEditSheet"
      @view-sheet="handleViewSheet"
      @created-sheet="$emit('created-sheet')"
      @create-sheet-error="$emit('create-sheet-error')"
    />
  </z-card>
</template>

<script>
import OfferCreationBatchSheetsTable from './offer-creation-batch-sheets-table';
import { SheetsModel } from './offer-creation-batch-models';

export default {
  components: {
    OfferCreationBatchSheetsTable,
  },
  props: {
    offerCreationSheets: SheetsModel,
  },
  computed: {
    numberOfSheets() {
      return this.offerCreationSheets.length;
    },
  },
  methods: {
    handleEditSheet(sheet) {
      this.$emit('edit-sheet', sheet);
    },
    handleViewSheet(sheet) {
      this.$emit('view-sheet', sheet);
    },
  },
};
</script>

<style lang="scss" scoped>
.oc-batch-sheets__title {
  margin-bottom: var(--space-small);
}
</style>
