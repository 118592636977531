<template>
  <section class="section-config-deal">
    <template v-if="isSelectMode">
      <z-input-field
        label="Selecione uma negociação existente"
        class="section-config-deal__input-field"
      >
        <z-autocomplete
          :options="dealsList"
          :value="dealSelected"
          @clear="dealSelected = null"
          @select="dealSelected = $event"
          :show-no-result="dealSelected === null"
          clear-button-aria-label="Limpar"
          hide-selected
          label-by="name"
          size="small"
          placeholder="Digite o nome da negociação"
        />
      </z-input-field>

      <z-text class="section-config-deal__input-field-description">
        ou <z-link
          tag="button"
          @click="handleChangeMode"
        >
          crie um nova negociação
        </z-link>
      </z-text>

      <z-input-field
        label="A negociação é para um polo?"
        class="section-config-deal__input-field"
        v-if="dealSelectedType"
      >
        <z-dropdown-input
          :value="dealSelectedType"
          :options="[dealSelectedType]"
          placeholder="Selecione o tipo de polo"
          disabled
        />
      </z-input-field>
    </template>

    <template v-else>
      <z-input-field
        label="Nome da nova negociação"
        class="section-config-deal__input-field"
      >
        <z-input
          type="text"
          size="small"
          placeholder="Crie um nome"
          v-model="dealName"
        />
      </z-input-field>

      <z-text class="section-config-deal__input-field-description">
        <z-link
          tag="button"
          @click="handleChangeMode"
        >
          Selecione uma negociação existente
        </z-link>
      </z-text>

      <z-input-field
        label="A negociação é para um polo?"
        class="section-config-deal__input-field"
      >
        <z-dropdown-input
          placeholder="Selecione o tipo de polo"
          :options="dealTypeList"
          v-model="dealType"
        />
      </z-input-field>
    </template>
  </section>
</template>

<script>
import { QueroDealsServices } from '@quero-turbo/services';
import { DealBusiness } from '@quero-turbo/business';

export default {
  data() {
    return {
      dealsList: [],
      isSelectMode: true,
    };
  },
  computed: {
    dealSelected: {
      get() {
        return this.$store.state.QueroTurboActivationModalModule.dealSelected;
      },
      set(value) {
        this.$store.commit('QueroTurboActivationModalModule/setDealSelected', value);
      },
    },
    dealSelectedType() {
      return this.dealSelected && DealBusiness.getQueroPoloTypeKeyDictionary(this.dealSelected.quero_polo_type);
    },
    dealName: {
      get() {
        return this.$store.state.QueroTurboActivationModalModule.dealName;
      },
      set(value) {
        this.$store.commit('QueroTurboActivationModalModule/setDealName', value);
      },
    },
    dealType: {
      get() {
        return DealBusiness.getQueroPoloTypeKeyDictionary(this.$store.state.QueroTurboActivationModalModule.dealType);
      },
      set(value) {
        this.$store.commit('QueroTurboActivationModalModule/setDealType', DealBusiness.getQueroPoloTypeLabelDictionary(value) || 'Selecione o tipo de polo');
      },
    },
    dealTypeList() {
      return DealBusiness.getQueroPoloTypeList().filter(element => !!element);
    },
  },
  methods: {
    requestGetAllDeals() {
      QueroDealsServices.V1.Deal.getAll().then(({ deals }) => {
        this.dealsList = deals.map(element => ({ ...element, name: `${element.id} - ${element.name}` }));
      });
    },
    handleChangeMode() {
      this.isSelectMode = !this.isSelectMode;
      this.dealName = null;
      this.dealSelected = null;
      this.dealType = null;
    },
  },
  mounted() {
    this.requestGetAllDeals();
  },
};
</script>

<style lang="scss">
.section-config-deal {
  display: flex;
  flex-direction: column;
}

.section-config-deal__input-field {
  margin-bottom: var(--space-small);
  &:last-child {
    margin-bottom: 0;
    margin-top: auto;
  }
}

.section-config-deal__input-field-description {
  white-space: nowrap;
}
</style>
