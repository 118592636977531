module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!-- this dumb mapping is neccesary to handle hifen -->\n';
 NO_VALUE = { value: null } ;
__p += '\n\n';
 course = data.course || NO_VALUE ;
__p += '\n';
 campus = data.campus || NO_VALUE ;
__p += '\n';
 kind = data.kind || NO_VALUE ;
__p += '\n';
 level = data.level || NO_VALUE ;
__p += '\n';
 shift = data.shift || NO_VALUE ;
__p += '\n';
 semester = data["enrollment-semester"] || NO_VALUE ;
__p += '\n';
 discount = data.discount || NO_VALUE ;
__p += '\n';
 stockStatus = data["stock-status"] || NO_VALUE ;
__p += '\n';
 discountGroups = data["discount-groups"] || NO_VALUE ;
__p += '\n';
 saleableSeats = data["saleable-seats"] || NO_VALUE ;
__p += '\n\n\n<ul class="key-value-list key-value-list--column">\n  ';
 if(data.isEmptyFilters) { ;
__p += '\n    <li class="key-value-list__item">\n      <p class="gq-text gq-text--small">\n        Você não utilizou filtros, <strong>todas as ofertas ativas</strong>\n        liberadas para ação em lote serão alteradas\n      </p>\n    </li>\n  ';
 } ;
__p += '\n  ';
 if(course.value !== null && course.value !== "") { ;
__p += '\n    <li class="key-value-list__item">\n      <h4 class="key-value-list__key gq-text gq-text--bold">Curso</h4>\n      <p class="key-value-list__value gq-text gq-text--small">' +
((__t = ( course.value )) == null ? '' : __t) +
'</p>\n    </li>\n  ';
 } ;
__p += '\n  ';
 if(campus.value !== null) { ;
__p += '\n    <li class="key-value-list__item">\n      <h4 class="key-value-list__key gq-text gq-text--bold">Campus</h4>\n      <ul class="key-value-list__list">\n        ';
 for (var i = 0; i < campus.options.length; i++) { ;
__p += '\n          ';
 var option = campus.options[i] ;
__p += '\n          ';
 if(option.selected) { ;
__p += '\n            <li class="key-value-list__list-value gq-text gq-text--small">' +
((__t = ( option.text )) == null ? '' : __t) +
'</li>\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      </ul>\n    </li>\n  ';
 } ;
__p += '\n  ';
 if(kind.value !== null) { ;
__p += '\n    <li class="key-value-list__item">\n      <h4 class="key-value-list__key gq-text gq-text--bold">Modalidade</h4>\n      <ul class="key-value-list__list">\n        ';
 for (var i = 0, len = kind.options.length; i < len; i++) { ;
__p += '\n          ';
 var option = kind.options[i] ;
__p += '\n          ';
 if(option.selected) { ;
__p += '\n            <li class="key-value-list__list-value gq-text gq-text--small">' +
((__t = ( option.text )) == null ? '' : __t) +
'</li>\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      </ul>\n    </li>\n  ';
 } ;
__p += '\n  ';
 if(level.value !== null) { ;
__p += '\n    <li class="key-value-list__item">\n      <h4 class="key-value-list__key gq-text gq-text--bold">Nível</h4>\n      <ul class="key-value-list__list">\n        ';
 for (var i = 0, len = level.options.length; i < len; i++) { ;
__p += '\n          ';
 var option = level.options[i] ;
__p += '\n          ';
 if(option.selected) { ;
__p += '\n            <li class="key-value-list__list-value gq-text gq-text--small">' +
((__t = ( option.text )) == null ? '' : __t) +
'</li>\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      </ul>\n    </li>\n  ';
 } ;
__p += '\n  ';
 if(shift.value !== null) { ;
__p += '\n    <li class="key-value-list__item">\n        <h4 class="key-value-list__key gq-text gq-text--bold">Turno</h4>\n        <ul class="key-value-list__list">\n        ';
 for (var i = 0, len = shift.options.length; i < len; i++) { ;
__p += '\n          ';
 var option = shift.options[i] ;
__p += '\n          ';
 if(option.selected) { ;
__p += '\n            <li class="key-value-list__list-value gq-text gq-text--small">' +
((__t = ( option.text )) == null ? '' : __t) +
'</li>\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n        </ul>\n      </li>\n  ';
 } ;
__p += '\n  ';
 if(semester.value !== null) { ;
__p += '\n    <li class="key-value-list__item">\n        <h4 class="key-value-list__key gq-text gq-text--bold">Captação</h4>\n        <ul class="key-value-list__list">\n          ';
 for (var i = 0, len = semester.options.length; i < len; i++) { ;
__p += '\n            ';
 var option = semester.options[i] ;
__p += '\n            ';
 if(option.selected) { ;
__p += '\n              <li class="key-value-list__list-value gq-text gq-text--small">' +
((__t = ( option.text )) == null ? '' : __t) +
'</li>\n            ';
 } ;
__p += '\n          ';
 } ;
__p += '\n        </ul>\n      </li>\n  ';
 } ;
__p += '\n  ';
 if(discount.value !== null && discount.value !== "") { ;
__p += '\n    <li class="key-value-list__item">\n      <h4 class="key-value-list__key gq-text gq-text--bold">Desconto</h4>\n      <ul class="key-value-list__list">\n        <li class="key-value-list__list-value gq-text gq-text--small">' +
((__t = ( discount.value )) == null ? '' : __t) +
'%</li>\n      </ul>\n    </li>\n  ';
 } ;
__p += '\n  ';
 if(stockStatus.value !== null) { ;
__p += '\n    <li class="key-value-list__item">\n      <h4 class="key-value-list__key gq-text gq-text--bold">Status</h4>\n      <ul class="key-value-list__list">\n          ';
 for (var i = 0, len = stockStatus.options.length; i < len; i++) { ;
__p += '\n            ';
 var option = stockStatus.options[i] ;
__p += '\n            ';
 if(option.selected) { ;
__p += '\n              <li class="key-value-list__list-value gq-text gq-text--small">' +
((__t = ( option.text )) == null ? '' : __t) +
'</li>\n            ';
 } ;
__p += '\n          ';
 } ;
__p += '\n      </ul>\n    </li>\n  ';
 } ;
__p += '\n  ';
 if(discountGroups.value !== null) { ;
__p += '\n    <li class="key-value-list__item">\n      <h4 class="key-value-list__key gq-text gq-text--bold">Grupo de desconto</h4>\n      <ul class="key-value-list__list">\n        ';
 for (var i = 0, len = discountGroups.options.length; i < len; i++) { ;
__p += '\n          ';
 var option = discountGroups.options[i] ;
__p += '\n          ';
 if(option.selected) { ;
__p += '\n            <li class="key-value-list__list-value gq-text gq-text--small">' +
((__t = ( option.text )) == null ? '' : __t) +
'</li>\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      </ul>\n    </li>\n  ';
 } ;
__p += '\n  ';
 if(saleableSeats.value !== null && saleableSeats.value !== "") { ;
__p += '\n    <li class="key-value-list__item">\n      <h4 class="key-value-list__key gq-text gq-text--bold">Bolsas Disponíveis</h4>\n      <ul class="key-value-list__list">\n        <li class="key-value-list__list-value gq-text gq-text--small">' +
((__t = ( saleableSeats.value )) == null ? '' : __t) +
'</li>\n      </ul>\n    </li>\n  ';
 } ;
__p += '\n</ul>\n';

}
return __p
}