import BaseModal from '@shared/legacy/components/modals/base-modal';
import template from './template.html.ejs';
import validate from './validate';

export default class MessageModal extends BaseModal {
  constructor({ title, content, submitText = '' }) {
    validate({ title, content, submitText });
    super({
      title,
      content,
      submitText,
    }, template);
  }
}
