<template>
  <UPAHelperModal
    v-if="options"
    :open="isOpen"
    :options="options"
    :mode="mode"
    :size="size"
    @closed="handleClose"
    @action="handleActionClick"
  />
</template>

<script>
import UPAHelperModal from './upa-helper-modal.vue';

export default {
  components: {
    UPAHelperModal,
  },
  data() {
    return {
      isOpen: false,
      closable: false,
      options: null,
      action: null,
      count: 0,
      watcher: null,
      size: null,
      mode: 'confirmation',
    };
  },
  methods: {
    fire() {
      this.show();
      return this.nextAction().then((result) => {
        const { action } = result;
        this.close();

        if (this.mode === 'confirmation') return action === 'accept';
        return action;
      });
    },
    show() {
      this.isOpen = true;
      return Promise.resolve();
    },
    close() {
      this.isOpen = false;
      return Promise.resolve();
    },
    async nextAction() {
      return new Promise((resolve) => {
        this.watching(resolve);
      });
    },
    watching(resolve) {
      if (this.watcher) this.watcher();

      this.watcher = this.$watch('$data.count', () => {
        resolve({ action: this.action });
      });
    },
    async setupModal({
      closable = true,
      options = {},
      action = null,
      mode = 'confirmation',
      size = null,
    }) {
      this.closable = closable;
      this.options = options;
      this.action = action;
      this.mode = mode;
      this.size = size;
    },
    handleClose() {
      this.action = 'close';
      this.count = this.count + 1;
    },
    handleActionClick($event) {
      this.action = $event;
      this.count = this.count + 1;
    },
  },
};
</script>
