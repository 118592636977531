const showMainDescription = (offerFilter) => {
  const { rawFilters } = offerFilter;
  if (!rawFilters) return `ID: ${offerFilter.id} - Filtro de oferta`;

  const typeOfEntity = mainEntity(rawFilters);
  if (typeOfEntity === 'educationGroup') return _parseEducationalGroup(rawFilters.educationGroups);
  if (typeOfEntity === 'university') return _parseUniversities(rawFilters.universities);
  if (typeOfEntity === 'campus') return _parseCampuses(rawFilters.campuses);
  return `ID: ${offerFilter.id} - Filtro de oferta`;
};

const _parseEducationalGroup = educationGroups => `Grupos Educacionais: ${_parseGeneric(educationGroups)}`;
const _parseUniversities = universities => `Universidades: ${_parseGeneric(universities)}`;
const _parseCampuses = campuses => `Campuses: ${_parseGeneric(campuses)}`;
const _parseGeneric = elements => `${elements.map(element => (element.text || element.name ? element.text || element.name : element)).join(' | ')}`;

const mainEntity = (rawFilters) => {
  if (rawFilters.educationGroups.length) return 'educationGroup';
  if (rawFilters.universities.length) return 'university';
  if (rawFilters.campuses.length) return 'campus';

  return null;
};

const onlyFilledFields = (fieldsObject) => {
  const newObject = {};
  Object.keys(fieldsObject).forEach((fieldKey) => {
    if (fieldsObject[fieldKey].length) newObject[fieldKey] = fieldsObject[fieldKey];
  });
  return newObject;
};

const parseValues = (values) => {
  if (Array.isArray(values)) return _parseGeneric(values);
  return values;
};

export const OfferFilterBusiness = {
  showMainDescription,
  mainEntity,
  onlyFilledFields,
  parseValues,
};
