import Step from '@stock/legacy/components/stepper/step';
import template from '@stock/legacy/components/modals/offer-list-actions/inline-steps/restock/steps/validate/template.ejs';
import { scope } from '@shared/utils/jquery';

const $ = scope('.js-restock-step-validate');

export class ValidateStep extends Step {
  constructor() {
    super({ template });
  }

  afterRender() {
    $('.js-cta-button').on('click', (event) => {
      event.preventDefault();
      window.open('mailto:suporteQP@queropago.com.br?subject=[QA%20-%20Outros]%20-%20Assunto&body=', '_blank');
      this.event.emit('previous');
    });
    $('.js-back-button').on('click', (event) => {
      event.preventDefault();
      this.event.emit('previous');
    });
  }
}
