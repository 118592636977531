import $ from 'jquery';

const EJSHelpers = {
  buildTemplate: template => (options) => {
    const $template = $(template(options));
    return $template;
  },
  attr: (type, ...values) => {
    const formatedValues = values.reduce((acc, value) => {
      if (!acc) return value;
      return `${acc} ${value}`;
    }, '');
    return formatedValues ? `${type}="${formatedValues}"` : '';
  },
};

export default EJSHelpers;
