export class RollbarService {
  static getInstance() {
    if (!RollbarService.instance) {
      RollbarService.instance = new RollbarService();
    }
    return RollbarService.instance;
  }

  notifyError(error, { silence, extraArgs = {} } = {}) {
    if (silence) return console.error(error);

    if (UPA.isProd || UPA.isHomolog) {
      return Rollbar.error(new Error(error), extraArgs);
    }

    return console.error(error, extraArgs);
  }
}
