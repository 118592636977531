<template>
  <GridContainer
    class="show-brg-with-data__row"
  >
    <GridItem class="show-brg-with-data__header">
      <z-title
        size="medium"
        color="interactive"
      >
        {{ businessRuleGroup.name }}
      </z-title>

      <z-button
        tag="router-link"
        to="/brgs"
        :icon="['fas', 'arrow-left']"
        variant="tertiary"
      >
        Retornar a lista
      </z-button>
    </GridItem>

    <GridItem
      :column="4"
    >
      <ShowBRGWithDataDescription />
    </GridItem>

    <GridItem
      :column="4"
    >
      <ShowBRGWithDataStepper
        v-if="readyToEdit"
      />

      <ShowBRGWithDataBennefits
        v-if="showBennefits"
        class="show-brg-with-data-description__bennefits"
      />
    </GridItem>

    <GridItem
      :column="4"
    >
      <ShowBRGWithActionMenu />
    </GridItem>

    <template v-if="canShowProductConfigs">
      <GridItem>
        <z-divider variant="light" />
      </GridItem>

      <GridItem>
        <ShowBRGWithDataProductConfigs />
      </GridItem>
    </template>
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ShowBRGWithDataDescription from './show-brg-with-data-description.vue';
import ShowBRGWithActionMenu from './show-brg-with-data-action-menu.vue';
import ShowBRGWithDataStepper from './show-brg-with-data-stepper.vue';
import ShowBRGWithDataProductConfigs from './show-brg-with-data-product-configs.vue';
import ShowBRGWithDataBennefits from './show-brg-with-data-bennefits.vue';

export default {
  components: {
    GridContainer,
    GridItem,
    ShowBRGWithActionMenu,
    ShowBRGWithDataDescription,
    ShowBRGWithDataStepper,
    ShowBRGWithDataProductConfigs,
    ShowBRGWithDataBennefits,
  },
  computed: {
    ...mapFields('ShowBRGPageModule', ['businessRuleGroup']),
    ...mapGetters('ShowBRGPageModule', [
      'admissionConfigItems',
      'readyToEdit',
      'hasOfferFilter',
      'hasCommission',
      'hasBilling',
      'hasAdmission',
    ]),
    canShowProductConfigs() {
      return Boolean(this.hasOfferFilter || this.hasCommission || this.hasBilling || this.hasAdmission || this.readyToEdit);
    },
    showBennefits() {
      return !this.readyToEdit && this.hasCommission && this.hasAdmission && this.hasBilling;
    },
  },
};
</script>

<style>
.show-brg-with-data__row {
  --grid-padding: 0;
}

.show-brg-with-data__header {
  display: flex;
  justify-content: space-between;
}

.show-brg-with-data__loading-wrapper {
  align-items: center;
  display: flex;
  height: 20vh;
  justify-content: center;
}

.show-brg-with-data__loading-component {
  display: contents;
}
</style>
