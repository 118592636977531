module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += 'Página ' +
((__t = ( pagination.page_number )) == null ? '' : __t) +
' de ' +
((__t = ( pagination.total_pages )) == null ? '' : __t) +
'\n';

}
return __p
}