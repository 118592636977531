module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="inline-stock-action-edit__body modal-body">\n  <header class="inline-stock-action-edit__header">\n    <h2 class="inline-stock-action-edit__title gq-title gq-title--left">Alteração de desconto</h2>\n    <p class="inline-stock-action-edit__description gq-text gq-text--left">Informe o novo percentual de desconto para a oferta selecionada</p>\n  </header>\n\n  <form class="inline-stock-action-edit__form inline-stock-action-edit__form--horizontal js-inline-stock-form" %>\n    <div>\n      <p class="inline-stock-action-edit__form-message gq-text gq-text--small gq-text--strong">Percentual de desconto atual</p>\n      <p class="gq-text gq-text--large gq-text--strong">' +
((__t = ( currentValue )) == null ? '' : __t) +
'</p>\n    </div>\n\n    <div class="inline-stock-action-edit__form-group">\n      <label class="inline-stock-action-edit__form-group form-group">\n        <p class="inline-stock-action-edit__form-message gq-text gq-text--small gq-text--strong gq-text--bold"><strong>Novo percentual de desconto</strong></p>\n        <span class="control-label">DESCONTO (%)</span>\n        <div class="input-group form-group form-group form-group--full">\n          <input\n            class="form-control js-inline-new-discount"\n            min="5"\n            max="80"\n            placeholder="Digite o desconto"\n            type="number"\n          />\n        </div>\n      </label>\n    </div>\n  </form>\n\n  <ul class="inline-stock-action-edit__rules">\n    <li class="inline-stock-action-edit__rule gq-text gq-text--small gq-title--strong">\n      <strong>O novo desconto deverá ficar entre 5% e 80%.</strong>\n      Caso queira dar um desconto maior, fale com seu key account.\n    </li>\n  </ul>\n</div>\n\n<footer class="inline-stock-action-edit__footer modal-footer">\n  <button\n    type="button"\n    class="inline-stock-action-edit__footer-action btn btn-secondary reverse js-back-button"\n  >\n    Voltar\n  </button>\n\n  <button\n    type="button"\n    class="inline-stock-action-edit__footer-action js-next-button btn btn-primary"\n    disabled\n    >\n      Conferir prévia\n  </button>\n</footer>\n';

}
return __p
}