import ActiveFilters from '@stock/legacy/components/active-filters';
import BaseModal from '@shared/legacy/components/modals/base-modal';
import template from '@stock/legacy/components/modals/offer-list-actions/batch/change-end/confirmation-template.html.ejs';
import { offersCount } from '@stock/legacy/components/modals/offer-list-actions/batch/offers-service';
import activeFiltersTemplate
  from '@university-panel/legacy/components/templates/stock/active-filters/list-active-filters.html.ejs';
import AjaxRequest from '@shared/utils/ajax-request';
import { ZToast } from '@shared/legacy/components/zilla/z-toast';
import ResponseModal from '@stock/legacy/components/modals/modal-response';

const UPDATE_OFFERS = '/ofertas/atualizar-end-date';

export default class ChangeEndConfirmationModal extends BaseModal {
  constructor(searchForm, totalOffers, totalOffersTarget, requestParams) {
    super(
      {
        totalOffers,
        totalOffersBlocked: (totalOffers - totalOffersTarget),
        totalOffersTarget,
      },
      template,
    );

    this.activeFilters = searchForm.toObject();
    this.requestParams = requestParams;
    this.totalOffersTarget = totalOffersTarget;
    this.changeEndDateRequest = this.setupRequest();
    this.showSuccessModal = this.showSuccessModal.bind(this);
  }

  setupRequest() {
    return new AjaxRequest(UPDATE_OFFERS)
      .on(
        'success',
        ({ data }) => {
          super.destroy();
          this.showSuccessModal(data);
        },
      )
      .on(
        'error',
        () => {
          super.destroy();
          ZToast.showError('Erro ao atualizar ofertas');
        },
      );
  }

  showSuccessModal(data) {
    const messageModal = new ResponseModal({
      iconClass: 'fal fa-check-circle fa-4x',
      title: 'Solicitação realizada com sucesso!',
      content: `
        Em breve sua alteração estará disponível na plataforma.
        Você também pode acompanhar suas alterações no Histórico de ações.
      `,
      submitText: 'Histórico de ações',
    });
    messageModal.show();
    messageModal.$container.on('click', '.js-confirm-button', () => {
      window.location.href = data.redirect;
    });
  }

  updateOffersCount() {
    offersCount(this.requestParams, (result) => {
      this.updateModalData(result.total_offers_count, result.total_offers_target_count);
    });
  }

  updateModalData(totalOffers, totalOffersTarget) {
    const totalOffersBlocked = totalOffers - totalOffersTarget;

    $('.js-batch-changeEndDate-total-offers').text(totalOffers);
    $('.js-batch-changeEndDate-total-offers-blocked').text(totalOffersBlocked);
    $('.js-batch-changeEndDate-total-offers-target').text(totalOffersTarget);
  }

  onShow() {
    this.renderFilters();
    this.setupSubmitButton();
    this.setupChangeEndDateButton();
    this.setupRadioButton();
  }

  setupRadioButton() {
    const $input = $('.js-new-end-date-input');
    const $changeEndDateOffersWarning = $('.js-changeEndDate-offers-warning');

    $input.on('change', (event) => {
      const { value } = event.target;

      this.requestParams.additionalParameters = { newEndDateInput: value };
      $changeEndDateOffersWarning.removeClass('hidden');

      this.updateOffersCount();
    });
  }

  setupSubmitButton() {
    if (this.totalOffersTarget === 0) {
      $('.js-changeEndDate-confirmation').attr('disabled', true);
    }

    if (this.totalOffersTarget > 10000) {
      $('.js-batch-stock-action-confirmation__description')
        .text('Limite para atualização de ofertas atingido: 10000')
        .css('color', 'red');
      $('.js-changeEndDate-confirmation').attr('disabled', true);
    }
  }

  renderFilters() {
    const $input = $('.js-new-end-date-input');
    const today = (new Date()).toISOString().substring(0, 10);
    $input.attr({ min: today });

    new ActiveFilters(
      $('.js-active-filters'),
      this.activeFilters,
      activeFiltersTemplate,
    );
  }

  setupChangeEndDateButton() {
    $('.js-changeEndDate-confirmation').click((event) => {
      event.preventDefault();
      this.destroy();
      const params = this.requestParams.toJson();
      this.changeEndDateRequest.postJson(params);
    });
  }
}
