import $ from 'jquery';
import Alert from '@shared/legacy/components/alert';

class UniversityContent {
  constructor() {
    this.alert = new Alert();
    this.initDifferentialsInput();
    this.setDifferentialsPositions();
    this.setInputsValidation();
  }

  initDifferentialsInput() {
    $('.js-add-more-differentials').on('click', () => {
      const differentialInputs = $('.js-differential-input-area');
      if (differentialInputs.length < 4) {
        const input = $('.js-differential-input-area').first().clone();
        input.find('.js-differential-input-text').val('');
        $('.js-differentials-section').append(input);
        this.setDifferentialsPositions();
      } else {
        this.alert.showError('Máximo de 4 diferenciais atingido.');
      }
    });
  }

  setDifferentialsPositions() {
    $('.js-differential-index').each(function setPrositions(index) {
      $(this).text(`${++index}º`); // eslint-disable-line
    });
  }

  setInputsValidation() {
    const $about = $('.js-description-about');
    const $locationAndStructure = $('.js-description-location-and-structure');
    const $curiosities = $('.js-description-curiosities');
    const $differentialsAbstract = $('.js-description-differentials-abstract');

    const inputFields = [$about, $locationAndStructure, $curiosities, $differentialsAbstract];

    $('#university-content-form').submit((event) => {
      event.preventDefault();

      let valid = true;
      inputFields.forEach(($obj) => {
        const maxLength = parseInt($obj.prop('maxlength'), 10);
        if ($obj.val().length > maxLength) {
          const title = $(`label[for="${$obj.prop('id')}"]`).text().trim();
          valid = false;
          this.alert.showError(`O campo ${title} deve ter no máximo ${maxLength} caracteres.`);
        }
      });

      let differentialsValid = true;
      const maxLength = 120;
      $('.js-differential-input-text').each((i, obj) => {
        if ($(obj).val().length > maxLength) {
          differentialsValid = false;
        }
      });

      if (!differentialsValid) {
        valid = false;
        this.alert.showError(`Os campos de diferenciais devem ter no máximo ${maxLength} caracteres.`);
      }

      if (valid) {
        event.currentTarget.submit();
      }
    });
  }
}

export default UniversityContent;
