export class InputField {
  constructor($inputField) {
    this.$inputField = $inputField;
    this.$container = this.$inputField.closest('.js-form-group');
  }

  showError(message = null) {
    this.$container.addClass('has-error');
    if (!this.$container.find('.js-help-block').length && message) {
      this.$container.append(
        `<span class="js-help-block help-block">${message}</span>`,
      );
    }
  }

  removeError() {
    this.$container.removeClass('has-error');
    this.$container.find('.js-help-block').remove();
  }

  hasError() {
    return this.$container.hasClass('has-error');
  }
}
