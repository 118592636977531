import $ from 'jquery';
import {
  update, partial, forOwn, pull, forEach, debounce, isNil, union,
} from 'lodash';
import 'eonasdan-bootstrap-datetimepicker';
import '@shared/utils/select2';

import TextFormatter from '@shared/utils/text_formatter';
import listingTemplate from '@admission/legacy/components/templates/scholarship_list.html.ejs';
import paginationInfoTemplate from '@shared/legacy/components/templates/list_pagination.html.ejs';
import DropdownsPosition from '@admission/legacy/components/dropdowns-position';

class Search {
  constructor(page) {
    if (page.length) {
      this.page = page;
      this.loading = false;
      this.initUniversitySelect();
      this.initCampusSelect();
      this.prepopulateUniversitySelect();
      this.initLevelSelect();
      this.initKindSelect();
      this.initGradient();
      this.initMask();
      this.initForm();
      this.initDatepickers();
      this.initPagination();
      this.initAdvancedFilterControl();
      this.disableEmptyDropDowns();
      new DropdownsPosition('.js-scholarship-table');
    }
  }

  initUniversitySelect() {
    const $select = this.$('.universities-multi-select');
    $select.select2({
      theme: 'bootstrap',
      ajax: {
        url: '/util/autocomplete/universities',
        delay: 250,
      },
      minimumInputLength: 2,
      language: 'pt-BR',
      templateResult: this.formatSelect2Result,
      placeholder: 'Nome da universidade',
    }).on('change', () => {
      this.selectedUniversities = $select.val() || [];
      this.fixCampuses();
    });
    this.selectedUniversities = $select.val() || [];
  }

  initCampusSelect() {
    const $select = this.$('.js-campuses-multi-select');
    $select.select2({
      theme: 'bootstrap',
      ajax: {
        url: '/util/autocomplete/campuses',
        delay: 250,
        data: params => ({
          term: params.term,
          university_ids: this.selectedUniversities,
        }),
      },
      minimumInputLength: 3,
      language: 'pt-BR',
      templateResult: this.formatSelect2Result,
      placeholder: 'Nome do campus',
    }).on('select2:select', (e) => {
      this.selectedCampuses = $(e.target).val() || [];
      this.addMapping(e.params.data);
    }).on('select2:unselect', (e) => {
      this.selectedCampuses = $(e.target).val() || [];
      this.dropMapping(e.params.data);
    });

    this.selectedCampuses = $select.val() || [];
    this.campusMapping = $select.data('campusMapping');
  }

  prepopulateUniversitySelect() {
    const $select = this.$('.universities-multi-select');
    const $selectedUniversityId = this.$($select).data('selected-university-id');
    const $selectedUniversityName = this.$($select).data('selected-university-name');

    if ($selectedUniversityId && $selectedUniversityName && ([$selectedUniversityId.toString()] - $select.val()) !== 0) {
      const option = new window.Option($selectedUniversityName, $selectedUniversityId, true, true);
      $select.append(option).trigger('change');
      this.selectedUniversities = [$selectedUniversityId];
    }
  }

  initLevelSelect() {
    const $select = this.$('.js-levels-multi-select');
    $select.select2({
      theme: 'bootstrap',
      language: 'pt-BR',
      placeholder: 'Digite o grau do curso',
    }).on('change', () => {
      this.selectedLevels = $select.val() || [];
    });
    this.selectedLevels = $select.val() || [];
  }

  initKindSelect() {
    const $select = this.$('.js-kinds-multi-select');
    $select.select2({
      theme: 'bootstrap',
      language: 'pt-BR',
      placeholder: 'Digite o tipo de curso',
    }).on('change', () => {
      this.selectedKinds = $select.val() || [];
    });
    this.selectedKinds = $select.val() || [];
  }

  initGradient() {
    const $scrollWrapper = this.$('.js-scroll-wrapper');
    const scrollWrapper = $scrollWrapper[0];
    const $shadowStart = this.$('#js-gradient-start');
    const $shadowEnd = this.$('#js-gradient-end');

    function toggleShadow($el, maxScrollReached) {
      const shadowIsVisible = $el.is(':visible');
      const showShadow = !maxScrollReached && !shadowIsVisible;
      const hideShadow = maxScrollReached && shadowIsVisible;

      if (showShadow) {
        $el.show();
      } else if (hideShadow) {
        $el.hide();
      }
    }

    function handleGradientVisibility() {
      const maxScrollStartReached = scrollWrapper.scrollLeft <= 0;
      const maxScrollEndReached = scrollWrapper.scrollLeft >= scrollWrapper.scrollWidth - scrollWrapper.offsetWidth;

      toggleShadow($shadowStart, maxScrollStartReached);
      toggleShadow($shadowEnd, maxScrollEndReached);
    }

    $scrollWrapper.scroll(handleGradientVisibility);
  }


  addMapping({ universityId, id }) {
    const map = this.campusMapping;
    const key = String(universityId);
    const stringId = String(id);

    if (!map[key]) {
      map[key] = [];
    } else {
      update(map, key, partial(union, _, [stringId]));
    }
  }

  dropMapping({ id }) {
    const map = this.campusMapping;
    const stringId = String(id);
    const empty = [];

    forOwn(map, (ids, univId) => {
      pull(ids, stringId);

      if (ids.length === 0) empty.push(univId);
    });

    forEach(empty, univId => delete map[univId]);
  }

  fixCampuses() {
    if (this.selectedCampuses.length === 0) {
      return;
    }

    const map = this.campusMapping;
    const empty = [];

    forOwn(map, (ids, univId) => {
      const included = this.selectedUniversities.some(e => e === univId);
      if (!included) {
        pull(this.selectedCampuses, ...ids);
        empty.push(univId);
      }
    });

    forEach(empty, univId => delete map[univId]);
    this.$('.js-campuses-multi-select').val(this.selectedCampuses).trigger('change');
  }

  updateStatuses(currentStatuses) {
    const statusSelect = this.$('.js-follow-up-status');
    const selectedValue = statusSelect.val();

    const followUpStatuses = currentStatuses.map((statuses) => {
      const key = statuses[0];
      const value = statuses[1];
      const selected = value === selectedValue ? 'selected' : null;

      return `<option value="${value}" ${selected}>${key}</option>`;
    });

    statusSelect.html(followUpStatuses);
  }

  initMask() {
    this.$('#sl_cpf').mask('000.000.000-00');
  }

  initForm() {
    const form = this.$('.js-filter-form');
    const usedFiltersTempl = form.find('#used-filters-templ');

    form.submit((event, { keepPagination } = {}) => {
      this.$('.cog').show();

      if (!keepPagination) {
        this.$('#search-page', form).val(1);
      }

      this.pushHistory(event);
    });

    form.on('ajax:success', (_event, {
      followUps, usableStatuses, canUpdate, count, pagination,
    }) => {
      this.$('tbody').html(listingTemplate({ followUps, usableStatuses, canUpdate }));
      this.$('#js-pagination-info').html(paginationInfoTemplate({ pagination }));
      this.$('.pagination .current-page').html(pagination.page_number);
      this.$('#scholarship-heading-count').text(TextFormatter.formatPlural(pagination.total_entries, ' pré-matrícula', ' pré-matrículas'));
      this.$('#js-total-entries').text(count);
      this.paginationControl(pagination.page_number, pagination.total_pages);
      this.loading = false;
      this.updateStatuses(usableStatuses);
      this.$('.cog').hide();

      const table = this.$('.js-scholarship-table');

      if (followUps.length > 0) {
        if (table.hasClass('hidden')) {
          table.removeClass('hidden');
        }
      } else {
        table.addClass('hidden');
      }

      form.trigger('updateListen');
    });

    form.on('change dp.change', 'select, input', (event) => {
      // don't count the initial datetimepicker dp.change event
      if (event.type === 'dp' && event.oldDate === null) { return; }

      const usedFilter = event.currentTarget.name.replace(/sl\[(.*?)\](\[\])?/, '$1');

      // don't count events whose name is empty (coming from select2)
      if (usedFilter === '') { return; }

      usedFiltersTempl.clone()
        .val(usedFilter)
        .attr('id', null)
        .attr('disabled', null)
        .appendTo(this.$('.js-used-filters'));
    });

    form.on('reset', debounce(() => {
      this.$('.js-used-filters').empty();
      form.submit();
    }));

    this.$('#export-search').on('click', (event) => {
      event.preventDefault();
      window.location = `${event.currentTarget.href}?${form.serialize()}`;
    });
  }

  initPagination() {
    const pagination = this.$('.pagination');
    const form = this.$('.js-filter-form');
    const pageInput = this.$('#search-page', form);

    function submitPage(sum) {
      const newPage = parseInt(pageInput.val(), 10) + sum;
      pageInput.val(newPage);
      form.trigger('submit', { keepPagination: true });
    }

    pagination.on('click', '.next:not(.disabled)', () => {
      if (this.loading === false) {
        this.loading = true;
        submitPage(1);
      }
    });

    pagination.on('click', '.prev:not(.disabled)', () => {
      if (this.loading === false) {
        this.loading = true;
        submitPage(-1);
      }
    });
  }

  initDatepickers() {
    this.$('#search-date-from').datetimepicker({
      format: 'DD/MM/YYYY',
      widgetPositioning: {
        vertical: 'bottom',
      },
    }).on('dp.change', (e) => {
      this.$('#search-date-to').data('DateTimePicker').minDate(e.date);
    });

    this.$('#search-date-to').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      widgetPositioning: {
        vertical: 'bottom',
      },
    }).on('dp.change', (e) => {
      this.$('#search-date-from').data('DateTimePicker').maxDate(e.date);
    });
  }

  paginationControl(currentPage, totalPages) {
    const pagination = this.$('.pagination');

    function disableButton(query) {
      const button = $(query, pagination);
      button.addClass('disabled');
      button.parent().addClass('disabled');
    }

    function enabledButton(query) {
      const button = $(query, pagination);
      button.removeClass('disabled');
      button.parent().removeClass('disabled');
    }

    if (currentPage === 1) {
      disableButton('.prev');

      if (totalPages > 1) {
        enabledButton('.next');
      } else {
        disableButton('.next');
      }
    } else if (currentPage >= totalPages) {
      disableButton('.next');
      enabledButton('.prev');
    } else {
      enabledButton('.next, .prev');
    }
  }

  initAdvancedFilterControl() {
    const collapse = this.$('#js-filter-collapse');
    const advancedFilterInputs = collapse.find(':input');
    const collapseLink = this.$('.js-filter-collapse-link');

    if (this.$('#js-advanced-search').val() === 'true') {
      advancedFilterInputs.prop('disabled', false);
    }

    collapse.on('show.bs.collapse', () => {
      advancedFilterInputs.prop('disabled', false);
      collapseLink.text('Visualizar menos opções');
    });

    collapse.on('hidden.bs.collapse', () => {
      advancedFilterInputs.prop('disabled', true);
      collapseLink.text('Visualizar mais opções');
    });
  }

  disableEmptyDropDowns() {
    this.$('.actions .dropdown').each((i, el) => {
      const lis = $('li:not(.divider)', el);
      const button = $('.dropdown-toggle', el);

      if (lis.length === 0) {
        this.disableDropdown(button);
      }
    });
  }

  disableDropdown(dropdown) {
    dropdown.addClass('disabled');
  }

  formatSelect2Result(data) {
    if (!data.id) { return data.text; }
    if (data.text !== data.hint) {
      return $(`<span>${data.text}</span><span class=hint> - ${data.hint}</span>`);
    }

    return $(`<span>${data.text}</span>`);
  }

  $(selector) {
    return $(selector, this.page);
  }

  pushHistory(event) {
    const formArray = $(event.target).serializeArray();
    const formString = _(formArray)
      .reject(e => e.value === '' || isNil(e.value) || e.name === 'sl[partial]')
      .sortBy(e => !!e.name.match(/utf8|partial|continue/)) // No, I don't get it either
      .map(({ name, value }) => `${name}=${value}`)
      .join('&');

    const path = window.location.pathname;

    if (window.history && window.history.pushState) {
      window.history.pushState({}, '', encodeURI(`${path}?${formString}`));
    }
  }
}

export default Search;
