import { CHART_COLORS } from '@shared/utils/chart-colors';
import Chart from 'chart.js';
import FlexFont from '@shared/utils/flex-font';
import NoDataFeedback from '@shared/legacy/components/no-data-feedback';

export default class {
  constructor() {
    this.revenueText = new FlexFont({ class: 'js-flex-font', max: 30 });
    this.noData = new NoDataFeedback({ container: '.js-admission-card' });
    this.initDashboard();
  }

  initDashboard() {
    this.revenueText.init();
    this.initPerformanceChart();
  }

  initPerformanceChart() {
    const data = JSON.parse($('#graph_data').val()).graph_data;

    if (data.days.length > 0) {
      const config = {
        type: 'line',
        data: {
          labels: data.days,
          datasets: [{
            label: 'Matrículas',
            data: data.enrolleds,
            fill: false,
            borderColor: CHART_COLORS.base.charlie,
            pointBackgroundColor: 'white',
            pointHoverBackgroundColor: CHART_COLORS.base.charlie,
            pointHoverBorderWidth: 4,
            pointBorderWidth: 2,
            borderWidth: 2,
          }, {
            label: 'Vendas',
            fill: false,
            data: data.paids,
            borderColor: CHART_COLORS.base.bravo,
            pointBackgroundColor: 'white',
            pointHoverBackgroundColor: CHART_COLORS.base.bravo,
            pointHoverBorderWidth: 4,
            pointBorderWidth: 2,
            borderWidth: 2,
          }],
        },
        options: {
          maintainAspectRatio: false,
          legend: { display: false },
          tooltips: {
            mode: 'index',
          },
          scales: {
            yAxes: [{
              ticks: { beginAtZero: true },
            }],
          },
        },
      };
      const { global, scale } = Chart.defaults;
      global.defaultFontFamily = `'Red Hat Text', ${global.defaultFontFamily}`;
      global.defaultFontSize = 14;
      global.defaultFontColor = CHART_COLORS.font.default;
      scale.gridLines.color = CHART_COLORS.grid.default;

      new Chart($('#performance-graph'), config);
    } else {
      this.noData.show();
    }
  }
}
