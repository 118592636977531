import { ZToast } from '@shared/legacy/components/zilla/z-toast';

export const notifierMixin = {
  methods: {
    notifyOfflineMessage() {
      ZToast.showAlert('Não há conexão com a internet', {
        type: 'danger',
      });
    },
  },
};
