<template>
  <GridContainer class="step-04-commission-type">
    <GridItem>
      <CreateCommissionConfigPainel />
    </GridItem>
  </GridContainer>
</template>

<script>
import { CreateCommissionConfigPainel } from '@quero-turbo/modules/commission-components';

import { GridContainer, GridItem } from '@shared/components/grid-components';

export default {
  components: {
    CreateCommissionConfigPainel,
    GridContainer,
    GridItem,
  },
  computed: {
    commissionTypeRules() {
      return this.$store.state.QueroTurboActivationModalModule.commissionTypeRules;
    },
  },
  methods: {
    handleAddCommissionType() {
      this.$store.commit('QueroTurboActivationModalModule/addCommissionTypeRule');
    },
    handleRemoveCommissionType($event) {
      const id = $event;
      const index = this.commissionConfigs.findIndex(element => (element.id === id));
      this.commissionConfigs.commissionTypeRules.splice(index, 1);
    },
    handleChangeCommissionType($event) {
      const { id, key, value } = $event;
      const index = this.commissionConfigs.commissionTypeRules.findIndex(element => (element.id === id));
      this.commissionConfigs.commissionTypeRules[index][key] = value;
    },
  },
};
</script>

<style>
.step-04-commission-type {
  --grid-padding: 0;
  min-height: 300px;
  position: relative;
}
</style>
