<template>
  <GridContainer class="step-02-payment-model">
    <GridItem
      :column="4"
      v-for="(element, index) in items"
      :key="index"
    >
      <PaymentModelCard
        :description="element.description"
        :icon="element.icon"
        :title="element.title"
        :selected="paymentModelSelected === element.value"
        @click="paymentModelSelected = element.value"
        class="step-02-payment-model__card"
      />
    </GridItem>
  </GridContainer>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import PaymentModelCard from './payment-model-card.vue';

export default {
  components: {
    PaymentModelCard,
    GridContainer,
    GridItem,
  },
  computed: {
    items() {
      return [
        {
          title: 'Pré-pago',
          description: 'Aporte de saldo mínimo inicial, do qual será abatida a comissão do QB por aluno. É possível fazer novos aportes (recargas) e o saldo não utilizado será devolvido.',
          icon: ['fal', 'usd-circle'],
          value: 'pre_paid',
        },
        {
          title: 'Pós-pago',
          description: 'Valor a ser pago no mês subsequente da captação do mês anterior. Só é válido para 40 maiores IES da carteira QB.',
          icon: ['fal', 'exchange'],
          value: 'pos_paid',
        },
        {
          title: 'Quero Pago',
          description: 'Recebimento (recorrente) da mensalidade de cada aluno, pela plataforma da Quero, já descontando a porcentagem do QB.',
          icon: ['fal', 'calendar-day'],
          value: 'quero_pago',
        },
      ];
    },
    paymentModelSelected: {
      get() {
        return this.$store.state.QueroTurboActivationModalModule.commissionModelRule.paymentModel;
      },
      set(value) {
        this.$store.commit('QueroTurboActivationModalModule/setCommissionPaymentModel', value);
      },
    },
  },
};
</script>

<style>
.step-02-payment-model {
  --grid-padding: 0;
  margin-bottom: 144px;
}

.step-02-payment-model__card {
  max-height: 256px;
  height: 100%;
}
</style>
