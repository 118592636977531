/**
 * This function check valid email in according with the follow rules:
 * - @ need be present (invalid example: site.ourearth.com)
 * - TLD (Top Level domain) can not start with dot "." (invalid example: site@.com.my)
 * - @ can't be first character (invalid example: @you.me.net)
 * - TLD must contain more than one character (invalid example: site123@gmail.b)
 * - An email should not be start with "." (invalid example: .site@site.org)
 * - The regular expression only allows character, digit, underscore, and dash (invalid example: site()*@gmail.com)
 * - Double dots are not allowed (invalid example: site..1234@yahoo.com)
 */

export function emailValidation(email) {
  const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/;

  return Boolean(email.match(mailFormat));
}
