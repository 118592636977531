<template>
  <grid-container>
    <grid-item>
      <HeaderBox title="Visão Geral" />
    </grid-item>

    <grid-item>
      <template v-if="hasUniversityId">
        <UPASimpleSkeleton v-if="isLoading" />

        <upa-can
          action="read"
          subject="dashboard"
          @loaded="isLoading = $event"
          #default="{ permitted }"
        >
          <StudentAttractingDashboard
            v-if="permitted"
            :university-id="universityId"
            :class="{'home-overview-page__dashboard--is-loading': isLoading}"
            @load="handleLoad"
          />

          <z-card v-if="!isLoading && !permitted">
            <z-title
              size="small"
            >
              Seja bem-vindo!
            </z-title>
          </z-card>
        </upa-can>
      </template>

      <template v-else>
        <z-card>
          <z-title
            size="small"
            class="home-overview-page__title"
          >
            Seja bem-vindo!
          </z-title>

          <z-title
            size="extra-small"
            color="minor"
          >
            Selecione uma instituição para continuar.
          </z-title>
        </z-card>
      </template>
    </grid-item>
  </grid-container>
</template>

<script>
import { HeaderBox } from '@shared/components/header-box';
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { homeOverviewTracking } from '@university-panel/metrics';
import { UPASimpleSkeleton } from '@shared/components/upa-simple-skeleton';
import { StudentAttractingDashboard } from '@university-panel/modules/datastudio-dashboards';

export default {
  components: {
    HeaderBox,
    GridContainer,
    GridItem,
    UPASimpleSkeleton,
    StudentAttractingDashboard,
  },
  props: {
    universityId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    hasUniversityId() {
      return this.universityId && !UPA.isTest;
    },
  },
  data() {
    return {
      isLoading: Boolean(this.universityId),
    };
  },
  methods: {
    handleLoad() {
      this.isLoading = false;
      hj('trigger', 'viewed-home-overview-page-with-data-studio-dashboard');
      homeOverviewTracking.viewedPowerBIDashboard();
    },
  },
};
</script>

<style scoped>
.home-overview-page__dashboard--is-loading{
  display: none;
  height: 0;
}

.home-overview-page__title {
  margin-bottom: var(--space-small);
}
</style>
