<template>
  <z-button-group behavior="vertical">
    <z-button
      behavior="block"
      @click="handleBRGRequestConfigBenefits"
      :icon="['fas', 'plus']"
      v-if="readyToActiveBenefits"
    >
      Ativar BRG
    </z-button>

    <z-button
      behavior="block"
      @click="handleBRGRequestFinish"
      :icon="['fas', 'check-circle']"
      v-if="readyToFinishBRG"
    >
      Desativar BRG
    </z-button>

    <z-button
      behavior="block"
      @click="handleBRGRequestCancel"
      :icon="['fas', 'times-circle']"
      v-if="readyToCancelBRG"
    >
      Cancelar BRG (erro)!
    </z-button>
  </z-button-group>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('ShowBRGPageModule', [
      'readyToActiveBRG',
      'readyToActiveBenefits',
      'readyToFinishBRG',
      'readyToCancelBRG',
    ]),
  },
  methods: {
    ...mapActions('ShowBRGPageModule', [
      'handleBRGRequestStateChange',
    ]),
    handleBRGRequestConfigBenefits() {
      this.handleBRGRequestStateChange({ event: 'request_activation' });
    },
    handleBRGRequestFinish() {
      this.handleBRGRequestStateChange({ event: 'request_deactivation' });
    },
    handleBRGRequestCancel() {
      this.handleBRGRequestStateChange({ event: 'request_canceling' });
    },
  },
};
</script>
