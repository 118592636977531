<template>
  <z-input-field :label="label">
    <z-autocomplete
      :multiple="true"
      :options="maxPaymentsList"
      :show-no-result="false"
      :value="maxPaymentsSelected"
      @remove="maxPaymentsRemoveHandle($event)"
      @select="maxPaymentsSelectHandle($event)"
      hide-selected
    />

    <input
      :value="maxPaymentsSelected"
      v-bind="$attrs"
      type="hidden"
    >
  </z-input-field>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Número de parcelas do curso',
    },
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    maxPaymentsList() {
      return [];
    },
    maxPaymentsSelected() {
      return this.value;
    },
  },
  methods: {
    maxPaymentsSelectHandle($event) {
      if (!$event) return;

      this.$emit('select', $event);
    },
    maxPaymentsRemoveHandle($event) {
      this.$emit('remove', $event);
    },
  },
};
</script>
