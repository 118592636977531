module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div\n  class="modal fade"\n  tabindex="-1"\n  role="dialog"\n>\n  <div class="batch-stock-action-confirmation modal-dialog">\n    <section class="modal-content">\n      <div class="batch-stock-action-confirmation__body modal-body">\n        <header class="batch-stock-action-confirmation__header">\n          <h2 class="gq-title batch-stock-action-confirmation__title">' +
((__t = ( title )) == null ? '' : __t) +
'</h2>\n          <p class="gq-text batch-stock-action-confirmation__description">' +
((__t = ( description )) == null ? '' : __t) +
'</p>\n\n          <button\n            type="button"\n            class="batch-stock-action-confirmation__close close"\n            data-dismiss="modal"\n            aria-label="Close"\n          >\n            <i class="far fa-xs fa-times " aria-hidden></i>\n          </button>\n        </header>\n\n        <section>\n          <h3 class="batch-stock-action-confirmation__subtitle gq-title gq-title--micro gq-title--soft">\n            Ofertas filtradas\n          </h3>\n          <ul class="batch-stock-action-confirmation__offers key-value-list">\n            <li class="key-value-list__item">\n              <h4 class="key-value-list__key gq-text gq-text--small">Total</h4>\n              <p class="key-value-list__value gq-text gq-text--large">' +
((__t = ( totalOffers )) == null ? '' : __t) +
'</p>\n            </li>\n            <li class="key-value-list__item">\n              <h4 class="key-value-list__key gq-text gq-text--small">Bloqueadas*</h4>\n              <p class="key-value-list__value gq-text gq-text--large">' +
((__t = ( totalOffersBlocked )) == null ? '' : __t) +
'</p>\n            </li>\n            <li class="key-value-list__item">\n              <h4 class="key-value-list__key gq-text gq-text--small">Liberadas para ação em lote</h4>\n              <p class="key-value-list__value gq-text gq-text--large gq-text--bold">' +
((__t = ( totalOffersTarget )) == null ? '' : __t) +
'</p>\n            </li>\n          </ul>\n\n          <ul class="batch-stock-action-confirmation__rules">\n            ';
 rules.forEach(function(rule) { ;
__p += '\n              <li class="batch-stock-action-confirmation__rule gq-text gq-text--small">' +
((__t = ( rule )) == null ? '' : __t) +
'</li>\n            ';
 }); ;
__p += '\n          </ul>\n        </section>\n\n        <section>\n          <h3 class="batch-stock-action-confirmation__subtitle gq-title gq-title--micro gq-title--soft">\n            Filtros utilizados\n          </h3>\n          <div class="js-active-filters batch-stock-action-confirmation__filters"></div>\n        </section>\n\n        <section>\n          <h3 class="batch-stock-action-confirmation__subtitle gq-title gq-title--micro">\n            Resumo da alteração\n          </h3>\n          <ul class="key-value-list key-value-list--clean">\n            <li class="key-value-list__item">\n              <p class="key-value-list__key gq-text gq-text--small gq-text--strong">\n                Quantidade de ofertas liberadas para alteração em lote\n              </p>\n              <strong class="key-value-list__value gq-title gq-title--normal">' +
((__t = ( totalOffersTarget )) == null ? '' : __t) +
'</strong>\n            </li>\n            <li class="key-value-list__item">\n              <p class="key-value-list__key gq-text gq-text--small gq-text--strong gq-text--bold">\n                ' +
((__t = ( newValueMessage[restockType] )) == null ? '' : __t) +
'\n              </p>\n              <strong class="key-value-list__value gq-title">' +
((__t = ( newValue )) == null ? '' : __t) +
'</strong>\n            </li>\n            ';
 if (canShowTotalTarget(restockType)) { ;
__p += '\n              <li class="key-value-list__item">\n                <p class="key-value-list__key gq-text gq-text--small gq-text--strong gq-text--bold">\n                  ' +
((__t = ( totalTargetMessage[restockType] )) == null ? '' : __t) +
'\n                </p>\n                <strong class="key-value-list__value gq-title">' +
((__t = ( sumOffersTarget )) == null ? '' : __t) +
'</strong>\n              </li>\n            ';
 } ;
__p += '\n          </ul>\n        </section>\n      </div>\n\n      <footer class="batch-stock-action-confirmation__footer modal-footer">\n        <button\n          type="button"\n          class="batch-stock-action-confirmation__footer-action btn btn-secondary reverse js-cancel-modal"\n          data-dismiss="modal"\n        >\n          ' +
((__t = ( button_cancel )) == null ? '' : __t) +
'\n        </button>\n        <button\n          type="button"\n          class="batch-stock-action-confirmation__footer-action js-confirm-button btn btn-primary"\n        >\n          ' +
((__t = ( button_confirm )) == null ? '' : __t) +
'\n        </button>\n      </footer>\n    </section>\n  </div>\n</div>\n';

}
return __p
}