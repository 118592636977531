<template>
  <z-card>
    <z-title
      class="one-click-restock-card-action__title"
      size="small"
      tag="h2"
    >
      {{ courseName }}
    </z-title>
    <z-badge-text
      class="one-click-restock-card-action__stockout-info"
      value="Vagas Esgotadas"
      variant="danger"
      v-if="availableSeats === 0"
    />
    <one-click-restock-offer-info
      class="one-click-restock-card-action__offer-info"
      :campus="campus"
      :level="level"
      :kind="kind"
      :shift="shift"
      :available-seats="availableSeats"
      :offered-price="offeredPrice"
      :discount="discount"
      :enrollment-semester="enrollmentSemester"
    />
    <z-input-field
      class="one-click-restock-card-action__input"
      label="Quantidade de vagas para repor"
      :rules="inputValidatorRules"
      v-slot="{ hasError }"
    >
      <z-input
        v-model="seatsCountToReplace"
        :has-error="hasError"
        type="number"
      />
    </z-input-field>
    <z-text
      class="one-click-restock-card-action__sales-info"
      size="small"
      color="minor"
    >
      Você ja realizou
      <strong>{{ totalSeats }} vendas</strong>
      desta oferta.
    </z-text>

    <z-button
      class="one-click-restock-card-action__button"
      behavior="block"
      @click="handleRestockClick"
      :is-disabled="isDisabled"
      :is-loading="isLoading"
    >
      Repor vagas
    </z-button>
  </z-card>
</template>

<script>
import Vue from 'vue';
import { validate } from 'vee-validate';
import OneClickRestockOfferInfo from './one-click-restock-offer-info';

export default Vue.extend({
  components: {
    OneClickRestockOfferInfo,
  },
  props: {
    courseName: {
      type: String,
      required: true,
    },
    campus: {
      type: String,
      required: true,
    },
    level: {
      type: String,
      required: true,
    },
    kind: {
      type: String,
      required: true,
    },
    shift: {
      type: String,
      required: true,
    },
    availableSeats: {
      type: Number,
      required: true,
    },
    offeredPrice: {
      type: Number,
      required: true,
    },
    discount: {
      type: Number,
      required: true,
    },
    totalSeats: {
      type: Number,
      required: true,
      validator: value => value > 0,
    },
    isInvalidSeatsCountToReplace: {
      type: Boolean,
      required: true,
    },
    initialSeatsCountToReplace: {
      type: Number,
      required: true,
    },
    enrollmentSemester: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      seatsCountToReplace: String(this.initialSeatsCountToReplace),
      isLoading: false,
    };
  },
  computed: {
    inputValidatorRules() {
      return { numeric: true, required: true, min_value: { min: 1 } };
    },
    isDisabled() {
      return this.isLoading || this.isInvalidSeatsCountToReplace;
    },
  },
  watch: {
    async seatsCountToReplace(value) {
      const { valid } = await validate(value, this.inputValidatorRules);
      const valueToBeEmitted = valid ? value : '0';
      this.$emit('update-seats-count-to-replace', { value: valueToBeEmitted });
    },
  },
  methods: {
    handleRestockClick() {
      if (this.isInvalidSeatsCountToReplace) return;
      this.isLoading = true;
      this.$emit('one-click-restock');
    },
  },
});
</script>

<style scoped lang="scss">
@import '~@quero/zilla-core/src/utils/variables';

.one-click-restock-card-action__title {
  margin-bottom: 0;
  margin-top: 0;
}

.one-click-restock-card-action__stockout-info {
  margin-top: var(--space-small);
}

.one-click-restock-card-action__offer-info {
  margin-top: var(--space-large);
}

.one-click-restock-card-action__input {
  margin-bottom: 0;
  margin-top: var(--space-large);
}

.one-click-restock-card-action__sales-info {
  margin-bottom: 0;
  margin-top: var(--space-small);
}

.one-click-restock-card-action__button {
  display: none;

  @media(min-width: $screen-sm) {
    display: block;
    margin-top: var(--space-large);
  }
}
</style>
