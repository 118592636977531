import _ from 'lodash';
import $ from 'jquery';

export default function (element, opts) {
  const noop = () => { };
  const $el = $(element);
  const whenValid = opts.whenValid || noop;
  const whenInvalid = opts.whenInvalid || noop;
  const validationFuncs = opts.validations || [];

  const eventToWatch = 'change keyup';

  $el.on(eventToWatch, (evt) => {
    const allValid = _.every(validationFuncs, func => func(evt.target.value));
    return allValid ? whenValid(evt.target) : whenInvalid(evt.target);
  });
}

export function isInteger(value, opts) {
  const positiveValues = opts.positiveValues || false;
  return positiveValues ? /^[1-9]\d*$/.test(value) : /^-*\d+$/.test(value);
}
