import Step from '@stock/legacy/components/stepper/step';
import template
  from '@stock/legacy/components/modals/offer-list-actions/inline-steps/discountChange/steps/edit/template.ejs';
import validator from '@shared/utils/validator';
import between from '@shared/utils/validators/between';
import NumberFormatter from '@shared/utils/number_format';

const MIN_DISCOUNT = 5;
const MAX_DISCOUNT = 80;

export class EditStep extends Step {
  constructor({ discount }) {
    const numberFormatter = new NumberFormatter();
    super({
      template,
      attributes: {
        currentValue: numberFormatter.toPercentage(discount, { precision: 2 }),
      },
    });
  }

  afterRender() {
    this.setupButtons();
    this.setupInput();
    this.setupBackButton();
  }

  setupButtons() {
    $('.js-next-button').click(() => super.next());
  }

  setupInput() {
    const $newDiscount = $('.js-inline-new-discount');

    validator($newDiscount, {
      validations: [between(MIN_DISCOUNT, MAX_DISCOUNT)],
      whenValid: el => this.onInputValid(el),
      whenInvalid: el => this.onInputInvalid(el),
    });

    $newDiscount.on('input', ({ target: { value } }) => super.setAttribute({ newDiscount: value }));
  }

  onInputValid(el) {
    $('.js-next-button').prop('disabled', false);
    $(el).closest('.form-group').removeClass('has-error');
  }

  onInputInvalid(el) {
    $('.js-next-button').prop('disabled', true);
    $(el).closest('.form-group').addClass('has-error');
  }

  setupBackButton() {
    $('.js-back-button').on('click', () => {
      this.event.emit('previous');
    });
  }
}
