
const canIAdvanceStep = (state) => {
  switch (state.currentStepName) {
    case 'step01': {
      const isDealValid = Boolean((state.dealSelected && state.dealSelected.id) || state.dealName);
      const isBRGValid = Boolean(
        state.businessRuleGroup.name
        && state.businessRuleGroup.validFrom
        && state.businessRuleGroup.validUntil
        && state.businessRuleGroup.contractUrl,
      );
      const isTurboAccountIdValid = Boolean(state.commissionAccountRule.turboAccountId);

      return Boolean(
        isDealValid
        && isBRGValid
        && isTurboAccountIdValid,
      );
    }
    case 'step02': {
      const isValidCommissionModelRule = state.commissionModelRule.paymentModel;
      return Boolean(isValidCommissionModelRule);
    }
    case 'step03': {
      return true;
    }
    case 'step04': {
      return true;
    }
    default:
      return false;
  }
};


const getters = {
  canIAdvanceStep,
};

export { getters };
