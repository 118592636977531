import axios from 'axios';
import { RollbarService } from '@shared/utils/rollbar-service';

const httpClient = axios.create({
  baseURL: 'https://geolocator.quero.space',
  timeout: 5000,
});

export async function getAddressByCep(cep) {
  const body = { zipcodes: [cep] };
  const response = httpClient.post('/api/get-address-by-zipcode', body)
    .then(({ data }) => data[cep])
    .catch((error) => {
      let extraArgs = {};
      if (error.response) {
        extraArgs = { response: error.response };
      } else if (error.request) {
        extraArgs = { request: error.request };
      }
      const errMsg = `[geolocatorApi] message: ${error.message}`;
      RollbarService.getInstance().notifyError(errMsg, { extraArgs });
      return Promise.reject(error);
    });
  return response;
}
