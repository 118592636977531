<template>
  <z-card
    class="show-brg-with-data-description"
  >
    <section class="show-brg-with-data-description__header">
      <z-icon
        size="large"
        :icon="['fas', 'pager']"
        class="show-brg-with-data-description__header-icon"
      />

      <div>
        <z-title
          tag="strong"
          size="small"
          class="show-brg-with-data-description__title"
        >
          Descrição
        </z-title>

        <z-text color="minor">
          Aqui você encontra a descrição detalhada de sua configuração da negociação.
        </z-text>
      </div>
    </section>

    <z-card
      tag="ul"
      class="show-brg-with-data-description__list"
    >
      <li
        class="show-brg-with-data-description__item"
        v-if="businessRuleGroup.pilot || readyToActiveBRG"
      >
        <z-badge-text
          variant="neutral"
          value="Piloto"
          v-if="businessRuleGroup.pilot"
        />

        <z-loading
          variant="ellipsis"
          color="primary"
          v-if="readyToActiveBRG"
        />
      </li>

      <li class="show-brg-with-data-description__item">
        <z-title
          tag="strong"
          size="nano"
        >
          ID:
        </z-title>
        <z-title
          tag="strong"
          color="interactive"
          size="nano"
        >
          {{ businessRuleGroup.id }}
        </z-title>
      </li>

      <li class="show-brg-with-data-description__item">
        <z-title
          tag="strong"
          size="nano"
        >
          Prioridade:
        </z-title>
        <z-title
          tag="strong"
          color="interactive"
          size="nano"
        >
          {{ `${businessRuleGroup.priority}º` }}
        </z-title>
      </li>

      <li class="show-brg-with-data-description__item">
        <z-title
          tag="strong"
          size="nano"
        >
          Criado em:
        </z-title>
        <z-title
          tag="strong"
          color="interactive"
          size="nano"
        >
          {{ dateParser(businessRuleGroup.createdAt) }}
        </z-title>
      </li>

      <li class="show-brg-with-data-description__item">
        <z-title
          tag="strong"
          size="nano"
        >
          Válido desde:
        </z-title>
        <z-title
          tag="strong"
          color="interactive"
          size="nano"
        >
          {{ dateParser(businessRuleGroup.validFrom) }}
        </z-title>
      </li>

      <li class="show-brg-with-data-description__item">
        <z-title
          tag="strong"
          size="nano"
        >
          Válido até:
        </z-title>
        <z-title
          tag="strong"
          color="interactive"
          size="nano"
        >
          {{ dateParser(businessRuleGroup.validUntil) }}
        </z-title>
      </li>

      <li class="show-brg-with-data-description__item">
        <z-title
          tag="strong"
          size="nano"
        >
          Situação:
        </z-title>

        <z-badge-text
          :variant="BRGBusiness.getStatusDictionary(businessRuleGroup.status).variant"
          :value="BRGBusiness.getStatusDictionary(businessRuleGroup.status).text"
        />
      </li>
    </z-card>
  </z-card>
</template>

<script>
import { BRGBusiness } from '@quero-turbo/business';
import { dateParser } from '@shared/utils/date/date-parser';
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {

  computed: {
    BRGBusiness: () => BRGBusiness,
    ...mapFields('ShowBRGPageModule', ['businessRuleGroup']),
    ...mapGetters('ShowBRGPageModule', ['readyToActiveBRG']),
  },
  methods: {
    dateParser,
  },
};
</script>

<style lang="scss">
.show-brg-with-data-description {
  margin: 0;
}

.show-brg-with-data-description__bennefits {
  margin-bottom: var(--space-medium);
}

.show-brg-with-data-description__list {
  border: var(--size-nano) solid var(--color-blue-400);
}

.show-brg-with-data-description__item {
  align-items: center;
  border-bottom: var(--size-border-width-small) solid var(--color-gray-100);
  display: flex;
  justify-content: space-between;
  padding: var(--space-small) 0px;

  &:first-child {
    padding-top: 0;
  }
}

.show-brg-with-data-description__header {
  display: flex;
  margin-bottom: var(--space-medium);
}

.show-brg-with-data-description__header-icon {
  margin-right: var(--space-small);
}

.show-brg-with-data-description__title {
  display: inline-flex;
  margin-bottom: var(--space-small);
}
</style>
