const specialConditions = [
  {
    label: 'Isenção da 1ª mensalidade',
    key: '1_MENSALIDADE',
  },
  {
    label: 'Isenção da 2ª mensalidade',
    key: '2_MENSALIDADE',
  },
  {
    label: 'Isenção da taxa de vestibular',
    key: 'VESTIBULAR',
  },
];

export const offer = {
  specialConditions,
};
