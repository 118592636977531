<template>
  <section>
    <z-input-field
      label="Inicia em"
      class="section-config-brg-description__input-field"
    >
      <z-input
        type="date"
        v-model="validFrom"
        size="small"
      />
    </z-input-field>

    <z-input-field
      label="Termina em"
      class="section-config-brg-description__input-field"
    >
      <z-input
        type="date"
        v-model="validUntil"
        size="small"
      />
    </z-input-field>

    <z-checkbox
      v-model="pilot"
      class="section-config-brg-description__input-field"
    >
      <z-text
        tag="span"
        size="medium"
      >
        Piloto
      </z-text>
    </z-checkbox>
  </section>
</template>

<script>
export default {
  computed: {
    validFrom: {
      get() {
        return this.$store.state.QueroTurboActivationModalModule.businessRuleGroup.validFrom;
      },
      set(value) {
        this.$store.commit('QueroTurboActivationModalModule/setBRGValidFrom', value);
      },
    },
    validUntil: {
      get() {
        return this.$store.state.QueroTurboActivationModalModule.businessRuleGroup.validUntil;
      },
      set(value) {
        this.$store.commit('QueroTurboActivationModalModule/setBRGValidUntil', value);
      },
    },
    pilot: {
      get() {
        return this.$store.state.QueroTurboActivationModalModule.businessRuleGroup.pilot;
      },
      set(value) {
        this.$store.commit('QueroTurboActivationModalModule/setBRGPilot', value);
      },
    },
  },
};
</script>

<style lang="scss">
.section-config-brg-description__input-field {
  margin-bottom: var(--space-medium);
  &:last-child {
    margin-top: auto;
    margin-bottom: 0;
  }
}
</style>
