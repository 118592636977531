<template>
  <table class="oc-table">
    <thead>
      <tr>
        <th
          class="oc-table__header"
          v-for="header in headers"
          :key="header.value"
        >
          <z-text
            tag="span"
            align="center"
            size="micro"
            color="minor"
            weight="bold"
          >
            {{ header.text }}
          </z-text>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="oc-table__row"
        v-for="row in rows"
        :key="row.key"
      >
        <td
          class="oc-table__column"
          v-for="column in headers"
          :key="column.value"
        >
          <z-text
            class="z-ovr-text--fw-medium"
            v-if="column.type === 'text'"
            tag="span"
            align="center"
            size="small"
          >
            {{ row[column.value] }}
          </z-text>

          <input-form-table
            class="oc-table__input"
            v-if="column.type === 'form'"
            :type="row[column.value].type"
            :step="row[column.value].step"
            :max="row[column.value].max"
            :min="row[column.value].min"
            :has-error="row[column.value].hasError"
            :value="row[column.value].value"
            @inputValueChange="(value) => $emit('valueInput', {
              value: value,
              key: row.key,
              field: column.value
            })"
          />

          <span
            class="oc-table__text-with-icon"
            v-if="column.type === 'textWithIcon'"
          >
            <z-icon
              v-if="row[column.value].icon"
              :style="{
                'color': row[column.value].icon.color,
              }"
              :icon="[row[column.value].icon.type, row[column.value].icon.name]"
              size="medium"
              class="oc-table__icon"
            />

            <z-text
              :class="{ 'z-ovr-text--fw-medium': !row[column.value].weight }"
              :style="row[column.value].color && {
                'color': row[column.value].color,
              }"
              :weight="row[column.value].weight"
              tag="span"
              align="center"
              size="small"
            >
              {{ row[column.value].text }}
            </z-text>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import InputFormTable from './input-form-table';

export default {
  components: {
    InputFormTable,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.oc-review-offer__table {
  --last-column-width: 168px;
}

.oc-table {
  background-color: var(--color-gray-000);
  border-collapse: collapse;
  width: 100%;
}

.oc-table__header {
  border-bottom: var(--size-border-width-medium) solid var(--color-gray-050);
  border-top: var(--size-border-width-medium) solid var(--color-gray-050);
  min-width: 170px;
  padding-bottom: var(--space-small);
  padding-right: var(--space-large);
  padding-top: var(--space-small);
  text-align: left;
}

.oc-table__column {
  border-bottom: var(--size-border-width-medium) solid var(--color-gray-050);
  padding-bottom: var(--space-medium);
  padding-right: var(--space-large);
  padding-top: var(--space-medium);
  text-align: left;
}

.oc-table__header:first-child,
.oc-table__column:first-child {
  padding-left: var(--space-large);
}

.oc-table__header:last-child,
.oc-table__column:last-child {
  width: var(--last-column-width, 100%);
}

.oc-table__icon {
  margin-right: var(--space-small);
}

.oc-table__input {
  width: 144px;
}

.oc-table__text-with-icon {
  display: flex;
}
</style>
