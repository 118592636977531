import BatchRestockModal from '@stock/legacy/components/modals/offer-list-actions/batch/restock';
import BatchStockDiscountChangeModal from '@stock/legacy/components/modals/offer-list-actions/batch/pricing';
import DisableConfirmationModal from '@stock/legacy/components/modals/offer-list-actions/batch/disablement/confirmation.js';
import BatchCreateWindowOffersModal from '@stock/legacy/components/modals/offer-list-actions/batch/create-window/batch-create-window-offers-modal';
import ChangeEndConfirmationModal from '@stock/legacy/components/modals/offer-list-actions/batch/change-end/confirmation.js';
import { offersCount, smallestSeats } from '@stock/legacy/components/modals/offer-list-actions/batch/offers-service';
import { BatchOperationRequest } from '@stock/legacy/modules/batch-operation';
import { validationSharedSeatsModal }
  from '@stock/legacy/components/modals/offer-list-actions/inline-steps/restock/validation-shared-seats-modal';
import { validationRegressiveDiscountModal }
  from '@stock/legacy/components/modals/offer-list-actions/batch/pricing/validation-regressive-discount-modal';

export default class BatchStockActions {
  constructor(searchForm, alert) {
    this.searchForm = searchForm;
    this.alert = alert;
  }

  async showBatchRestockModal(seatsCount) {
    const requestParams = new BatchOperationRequest(this.searchForm, {
      constraints: { limited: true, shared_seats: false },
    });

    const [offersCountResult, smallestSeatsResult] = await Promise.all([
      this._offerCountRequest(requestParams),
      this._smallestSeatsRequest(requestParams),
    ]);

    const modal = offersCountResult.total_offers_target_count > 0
      ? new BatchRestockModal(
        seatsCount,
        this.searchForm,
        this.alert,
        smallestSeatsResult.smallest_seats,
      )
      : validationSharedSeatsModal(true);

    modal.show();
    return modal;
  }

  async showBatchStockDiscountChangeModal() {
    const requestParams = new BatchOperationRequest(this.searchForm, {
      constraints: { regressive_discount: false },
    });

    const offersCountResult = await this._offerCountRequest(requestParams);

    const modal = offersCountResult.total_offers_target_count > 0
      ? new BatchStockDiscountChangeModal(this.searchForm, this.alert)
      : validationRegressiveDiscountModal({ operationType: 'pricingChange' });

    modal.show();
    return modal;
  }

  async showBatchDisableOffersModal() {
    const requestParams = new BatchOperationRequest(this.searchForm);

    const offersCountResult = await this._offerCountRequest(requestParams);

    const modal = new DisableConfirmationModal(
      this.searchForm,
      offersCountResult.total_offers_count,
      offersCountResult.total_offers_target_count,
      requestParams,
    );

    modal.show();
    return modal;
  }

  async showBatchEndChangeModal() {
    const requestParams = new BatchOperationRequest(this.searchForm);

    const offersCountResult = await this._offerCountRequest(requestParams);

    const modal = new ChangeEndConfirmationModal(
      this.searchForm,
      offersCountResult.total_offers_count,
      offersCountResult.total_offers_target_count,
      requestParams,
    );

    modal.show();
    return modal;
  }

  async showBatchWindowOffersModal() {
    const requestParams = new BatchOperationRequest(this.searchForm, {
      constraints: { regressive_discount: false },
    });

    const offersCountResult = await this._offerCountRequest(requestParams);

    const modalParams = {
      totalOffersCount: offersCountResult.total_offers_count,
      totalOffersTargetCount: offersCountResult.total_offers_target_count,
    };

    const modal = offersCountResult.total_offers_target_count > 0
      ? new BatchCreateWindowOffersModal(this.searchForm, this.alert, modalParams)
      : validationRegressiveDiscountModal({ operationType: 'createWindowOffer' });

    modal.show();
  }

  _offerCountRequest(requestParams) {
    return new Promise((resolve, _reject) => {
      offersCount(requestParams, (result) => {
        resolve(result);
      });
    });
  }

  _smallestSeatsRequest(requestParams) {
    return new Promise((resolve, _reject) => {
      smallestSeats(requestParams, (result) => {
        resolve(result);
      });
    });
  }
}
