<template>
  <z-card>
    <z-title
      class="index-brg-painel__title"
      size="small"
    >
      Listagem de configurações da negociação
    </z-title>

    <GridContainer class="index-brg-painel__row">
      <GridItem :column="3">
        <z-input-field label="Selecionar negociação *">
          <z-autocomplete
            :options="dealsList"
            :value="dealSelected"
            @clear="dealSelected = null"
            @select="dealSelected = $event"
            clear-button-aria-label="Limpar"
            hide-selected
            label-by="name"
          />
        </z-input-field>
      </GridItem>

      <GridItem :column="3">
        <z-input-field label="Situação">
          <z-autocomplete
            :multiple="true"
            :options="statusList"
            :value="statusSelected"
            :disabled="!dealSelected"
            @clear="statusSelected = []"
            @remove="handleAutocompleteRemove($event)"
            @select="statusSelected.push($event)"
            clear-button-aria-label="Limpar"
            hide-selected
            label-by="name"
          />
        </z-input-field>
      </GridItem>
    </GridContainer>

    <IndexBRGTable :items="items" />

    <GridContainer class="index-brg-painel__row">
      <GridItem class="index-brg-painel__action-section">
        <z-button
          tag="router-link"
          to="/"
          variant="secondary"
          class="index-brg-painel__action-button "
        >
          Voltar
        </z-button>

        <z-button
          tag="router-link"
          to="/brgs/create"
          class="index-brg-painel__action-button "
        >
          Criar configuração da negociação
        </z-button>
      </GridItem>
    </GridContainer>
  </z-card>
</template>

<script>
import { GridContainer, GridItem } from '@shared/components/grid-components';
import { QueroDealsServices } from '@quero-turbo/services';
import IndexBRGTable from './index-brg-table.vue';

export default {
  components: {
    GridContainer,
    GridItem,
    IndexBRGTable,
  },

  computed: {
    dealSelected: {
      get() {
        return this.$store.state.ShowBRGPageModule.dealSelected;
      },
      set(value) {
        this.$store.commit('ShowBRGPageModule/setDealSelected', value);
      },
    },
  },
  data() {
    return {
      dealsList: [],
      statusSelected: [],
      statusList: [
        { name: 'Ativo', value: 'active' },
        { name: 'Configurando', value: 'pending' },
        { name: 'Editando', value: 'editing' },
        { name: 'Cancelado', value: 'cancelled' },
        { name: 'Finalizado', value: 'finished' },
        { name: 'Erro', value: 'failed' },
        { name: 'Desabilitando', value: 'deactivating' },
      ],
      items: [],
    };
  },
  methods: {
    requestGetAllBRGsFromDeal() {
      QueroDealsServices.V1.BRG.getAll({ dealId: this.dealSelected.id }).then(({ businessRuleGroups }) => {
        this.items = businessRuleGroups.filter(item => this.statusSelected.length === 0 || this.statusSelected.some(status => status.value === item.status));
      });
    },
    requestGetAllDeals() {
      QueroDealsServices.V1.Deal.getAll().then(({ deals }) => {
        this.dealsList = deals.map(element => ({ ...element, name: `${element.id} - ${element.name}` }));
      });
    },
    setDealSelectedByRoute() {
      if (this.$route.query.id) {
        this.dealSelected = {
          id: Number(this.$route.query.id),
          name: this.$route.query.name,
        };
      }
    },
    setStatusSelectedByRoute() {
      if (this.$route.query.status) {
        const statuses = this.$route.query.status.split(',');
        statuses.map(status => this.statusSelected.push(this.statusList.find(item => item.value === status)));
      }
    },
    handleAutocompleteRemove(item) {
      this.statusSelected = this.statusSelected.filter(status => status.name !== item.name);
    },
  },
  watch: {
    dealSelected() {
      const currentQuery = this.$route.query;
      const query = this.dealSelected ? {
        id: this.dealSelected.id,
        name: this.dealSelected.name,
        ...currentQuery,
      } : '';

      this.$router.push({ query });
      if (!this.dealSelected) {
        this.items = [];
        this.statusSelected = [];
      } else {
        if (this.statusSelected.length === 0 && this.statusSelected.filter(status => status.value === 'active').length === 0) {
          this.statusSelected.push({ name: 'Ativo', value: 'active' });
        }

        this.requestGetAllBRGsFromDeal();
      }
    },

    statusSelected() {
      const currentQuery = this.$route.query;
      const query = this.statusSelected ? {
        ...currentQuery,
        status: this.statusSelected.map(status => status.value).join(','),
      } : {
        ...currentQuery,
      };

      if (this.statusSelected.length === 0 || !this.dealSelected) {
        delete query.status;
      }

      this.$router.push({ query });

      if (this.dealSelected) {
        this.requestGetAllBRGsFromDeal();
      }
    },
  },
  mounted() {
    if (this.dealSelected) {
      if (this.statusSelected.length === 0 && this.statusSelected.filter(status => status.value === 'active').length === 0) {
        this.statusSelected.push({ name: 'Ativo', value: 'active' });
      }

      this.requestGetAllBRGsFromDeal();
    }

    this.requestGetAllDeals();
    this.setDealSelectedByRoute();
    this.setStatusSelectedByRoute();
  },
};
</script>

<style scoped>
.index-brg-painel__title {
  margin-bottom: var(--space-medium);
}

.index-brg-painel__row {
  --grid-padding: 0;
  margin-top: var(--space-medium);
}

.index-brg-painel__action-section {
  display: flex;
  justify-content: flex-end;
}

.index-brg-painel__action-button:not(:last-child) {
  margin-right: var(--space-medium);
}
</style>
