module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="modal modal-dark fade" tabindex="-1" role="dialog">\n  <div class="modal-response modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header modal-response__header">\n        <button\n          type="button"\n          class="close modal-response__close"\n          data-dismiss="modal"\n          aria-label="Close"\n        >\n          <span aria-hidden="true">&times;</span>\n        </button>\n\n        <span class="modal-response__icon">\n          <i class="' +
((__t = ( iconClass )) == null ? '' : __t) +
'"></i>\n        </span>\n\n        <h2 class="gq-title gq-title--center modal-response__title">\n          ' +
((__t = ( title )) == null ? '' : __t) +
'\n        </h2>\n      </div>\n\n      <div class="modal-body modal-response__body">\n        <p class="gq-text gq-text--center">' +
((__t = ( content )) == null ? '' : __t) +
'</p>\n      </div>\n\n      <div class="modal-footer modal-response__footer">\n        <button\n          type="button"\n          class="btn btn-secondary reverse js-cancel-modal"\n          data-dismiss="modal"\n        >\n          Fechar\n        </button>\n\n        ';
 if (submitText) { ;
__p += '\n          <button\n            type="button"\n            class="btn btn-primary modal-response__wide-button js-confirm-button"\n            data-dismiss="modal"\n          >\n            ' +
((__t = ( submitText )) == null ? '' : __t) +
'\n          </button>\n        ';
 } ;
__p += '\n        </div>\n    </div>\n  </div>\n</div>\n';

}
return __p
}