import { DEFAULT_OPTIONS } from '@shared/legacy/components/zilla/z-input-field/options';
import EJSHelpers from '@shared/utils/templates/ejs';
import template from './z-input-field.html.ejs';

export default (identifier, props) => {
  const getTargetCssClass = () => `js-${identifier}-field`;

  const getTarget = () => $(`.${getTargetCssClass()}`);

  const buildTemplate = options => $(template({ ...DEFAULT_OPTIONS, ...options }));

  const $target = getTarget();
  if ($target.length === 0) throw new Error('target not found');
  const targetSelector = getTargetCssClass();
  const $template = buildTemplate({ ...props, targetSelector, attr: EJSHelpers.attr });
  $target.replaceWith($template);
  return getTarget();
};
