import { shouldBeOneOf, allValuesMustBePresent } from '@stock/utils/validator';

const basePeriodKindsMap = {
  Semestre: {
    singular: 'semestre',
    plural: 'semestres',
    periodKindsInMonths: 6,
  },
  Mês: {
    singular: 'mês',
    plural: 'meses',
    periodKindsInMonths: 1,
  },
  Ano: {
    singular: 'ano',
    plural: 'anos',
    periodKindsInMonths: 12,
  },
};

const allPeriodKindsMap = {
  ...basePeriodKindsMap,
  Dia: {
    singular: 'dia',
    plural: 'dias',
    periodKindsInMonths: undefined,
  },
  Hora: {
    singular: 'hora',
    plural: 'horas',
    periodKindsInMonths: undefined,
  },
};

const allPeriodKindList = Object.keys(allPeriodKindsMap);

function getPeriodKindsList({ parentLevel }) {
  if (parentLevel === 'Graduação') {
    return Object.keys(basePeriodKindsMap);
  }

  return allPeriodKindList;
}

function maxPeriodsLabel({ maxPeriods, periodKind }) {
  if (!allValuesMustBePresent()([maxPeriods, periodKind])) return null;
  if (!shouldBeOneOf(allPeriodKindList)(periodKind)) return null;

  const period = allPeriodKindsMap[periodKind];
  return Number(maxPeriods) > 1
    ? `${maxPeriods} ${period.plural}`
    : `${maxPeriods} ${period.singular}`;
}

export const period = {
  maxPeriodsLabel,
  getPeriodKindsList,
  allPeriodKindsMap,
};
