import { getDictionaryKeyByText, getDictionaryTextByKey, getDictionaryTextList } from '@shared/utils/dictionary';

const paymentModelDictionary = [
  {
    key: undefined,
    text: null,
  },
  {
    text: 'Pós-pago',
    key: 'pos_paid',
  },
  {
    text: 'Pré-pago',
    key: 'pre_paid',
  },
  {
    text: 'Quero Pago',
    key: 'quero_pago',
  },
];


const paymentTriggerDictionary = [
  {
    key: undefined,
    text: null,
  },
  {
    key: 'pre_enrollment_fee_paid',
    text: 'Captado',
  },
  {
    key: 'pre_enrolled',
    text: 'Admitido',
  },
  {
    key: 'enrolled',
    text: 'Matriculado',
  },
  {
    key: 'reenrolled',
    text: 'Rematriculado',
  },
  {
    key: 'first_installment_paid',
    text: 'Primeira mensalidade',
  },
  {
    key: 'installment_paid',
    text: 'Mensalidade',
  },
];

const commissionFeeTypeDictionary = [
  {
    key: undefined,
    text: null,
  },
  {
    key: 'fee',
    text: 'Porcentagem',
  },
  {
    key: 'installment',
    text: 'Numero de mensalidades',
  },
  {
    key: 'fixed_value',
    text: 'Valor fixo',
  },
];

const getPaymentModelKeyByText = text => getDictionaryKeyByText(paymentModelDictionary, text);
const getPaymentModelTextList = () => getDictionaryTextList(paymentModelDictionary);
const getPaymentModelTextByKey = key => getDictionaryTextByKey(paymentModelDictionary, key);


const getPaymentTriggerKeyByText = text => getDictionaryKeyByText(paymentTriggerDictionary, text);
const getPaymentTriggerTextList = () => getDictionaryTextList(paymentTriggerDictionary);
const getPaymentTriggerTextByKey = key => getDictionaryTextByKey(paymentTriggerDictionary, key);

const getCommissionFeeTypeKeyByText = value => getDictionaryKeyByText(commissionFeeTypeDictionary, value);
const getCommissionFeeTypeTextList = () => getDictionaryTextList(commissionFeeTypeDictionary);
const getCommissionFeeTextByKey = key => getDictionaryTextByKey(commissionFeeTypeDictionary, key);

const commissionDictionary = {
  upperLimitRule: 'Limite Superior',
  turboCommissionRuleCountId: 'Contagem de ID',
  turboAccountId: 'Conta turbo',
  subsidy: 'Subsídio',
  step: 'Step',
  paymentTrigger: 'Por qual aluno vamos cobrar',
  paymentModel: 'Forma de pagamento',
  lowerLimitRule: 'Limite Inferior',
  feeType: 'Comissionado por',
  fee: 'Valor',
  businessRuleGroupId: 'BRG ID',
};

const getCommissionDictionaryByKey = key => commissionDictionary[key];

const parseValues = value => (
  ((typeof value === 'number') && value)
  || getPaymentModelTextByKey(value)
  || getCommissionFeeTextByKey(value)
  || getPaymentTriggerTextByKey(value)
);

const showMainDescription = commissionPayload => `${getPaymentModelTextByKey(commissionPayload.paymentModel)} - ${getPaymentTriggerTextByKey(commissionPayload.paymentTrigger)}`;

export const CommissionRulesBusiness = {
  getPaymentModelTextList,
  getPaymentModelKeyByText,
  getPaymentModelTextByKey,
  getPaymentTriggerTextList,
  getPaymentTriggerKeyByText,
  getCommissionFeeTypeKeyByText,
  getCommissionFeeTypeTextList,
  getCommissionDictionaryByKey,
  getPaymentTriggerTextByKey,
  getCommissionFeeTextByKey,
  showMainDescription,
  parseValues,
};
