module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="heading-buttons">\n  <a href="#" class="edit-reply">Editar</a>\n  <a href="#" data-id="' +
((__t = ( reply.id )) == null ? '' : __t) +
'" class="delete-reply">Excluir</a>\n</div>\n<div class="university-reply-card-title">\n  Resposta da ' +
((__t = ( reply.university_name )) == null ? '' : __t) +
'\n</div>\n<div class="replied-text">\n  <p class="retr-reply-js">\n    ' +
__e( reply.text ) +
'\n  </p>\n</div>\n';

}
return __p
}